//Angular Imports
import { Component, OnInit, Input } from "@angular/core";

//Third Party Imports
import { GridOptions } from "ag-grid-community";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

//Internal Imports
import { SelectableItem } from "../../../../../framework/models/selectable-item.model";
import { TestingService } from "../../services";
import { RuleEngineResult, RuleEngineLog } from "../../models";
import {
  coverageCpmElementId,
  lobCpmElementId,
} from "../../configs/execution-engine.config";

import "rxjs";
import { TestCase, TestExpectedResults } from "../../models";
import { forEach } from "lodash";

/**
 * Forms Component
 */
@Component({
  selector: "app-test-case-expected-result",
  templateUrl: "./test-case-expected-result.component.html",
  styleUrls: ["./test-case-expected-result.component.scss"],
})
export class TestCaseExpectedResult {
  @Input() public actionType: string;
  @Input() public testCase: TestCase;

  summaryGridOptions: GridOptions;
  mandatoryGridOptions: GridOptions;
  optionalGridOptions: GridOptions;

  optionalFormCount: number = 0;
  mandatoryFormCount: number = 0;

  mandatorySelectableItems: SelectableItem[];
  optionalSelectableItems: SelectableItem[];
  currentResults: TestExpectedResults;

  /**
   * Constructor
   * @ignore
   */
  constructor(
    public activeModal: NgbActiveModal,
    private testingService: TestingService,
    private _modal: NgbModal,
    private toastr: ToastrService
  ) {
    this.mandatorySelectableItems = [];
    this.optionalSelectableItems = [];
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
   */
  ngOnInit(): void {
    this.configureSummaryGrid();
    this.configureMandatoryGrid();
    this.configureOptionalGrid();
  }

  /**
   * NgOnDestroy
   * @ignore
   */
  ngOnDestroy(): void {}

  private createSelectableItemLists(expectedResult: TestExpectedResults): void {
    this.mandatoryFormCount = 0;
    this.optionalFormCount = 0;
    this.mandatorySelectableItems = [];
    this.optionalSelectableItems = [];
    this.testingService
      .getAllFormNumbersByLob(expectedResult.LOB)
      .subscribe((res) => {
        console.log(res);
        for (var form of res) {
          // Create Mandatory Items
          let mandatoryItem: SelectableItem = new SelectableItem();
          mandatoryItem.Code = form.InternalFormNumber;
          mandatoryItem.VisibleDescription = form.InternalFormNumber;
          if (
            expectedResult.MandatoryForms.find(
              (x) => x === form.InternalFormNumber
            )
          ) {
            mandatoryItem.Selected = true;
            this.mandatoryFormCount++;
          }
          this.mandatorySelectableItems.push(mandatoryItem);

          // Create Optional Items
          let optionalItem: SelectableItem = new SelectableItem();
          optionalItem.Code = form.InternalFormNumber;
          optionalItem.VisibleDescription = form.InternalFormNumber;
          if (
            expectedResult.OptionalForms.find(
              (x) => x === form.InternalFormNumber
            )
          ) {
            optionalItem.Selected = true;
            this.optionalFormCount++;
          }
          this.optionalSelectableItems.push(optionalItem);
        }
        this.sortSelectableItems(this.mandatorySelectableItems);
        this.mandatoryGridOptions.api.setRowData(this.mandatorySelectableItems);
        this.mandatoryGridOptions.columnApi.autoSizeAllColumns();

        this.sortSelectableItems(this.optionalSelectableItems);
        this.optionalGridOptions.api.setRowData(this.optionalSelectableItems);
        this.optionalGridOptions.columnApi.autoSizeAllColumns();
      });
  }

  private sortSelectableItems(items: SelectableItem[]) {
    items.sort((a, b) => {
      if (a.Selected == b.Selected) {
        return 0;
      } else if (a.Selected) {
        return -1;
      } else return 1;
    });
  }

  private configureSummaryGrid(): void {
    this.summaryGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: "normal",
      columnDefs: this.createSummaryColumnDef(),
      enableFilter: true,
      enableSorting: true,
      rowHeight: 30,
      enableColResize: true,
      onGridReady: () => {
        this.summaryGridOptions.api.setRowData(this.testCase.ExpectedResults);
        //this.summaryGridOptions.api.sizeColumnsToFit();
        this.summaryGridOptions.columnApi.autoSizeAllColumns();

        // Select the first row when grid loads and populate the form lists
        this.summaryGridOptions.api.forEachNode((node) =>
          node.rowIndex ? 0 : node.setSelected(true)
        );
        if (
          this.testCase &&
          this.testCase.ExpectedResults &&
          this.testCase.ExpectedResults.length
        ) {
          this.currentResults = this.testCase.ExpectedResults[0];
          this.createSelectableItemLists(this.currentResults);
        }
      },
      onRowClicked: (event) => {
        this.onSummaryRowClick(event);
      },
      onFilterChanged: (event) => {},
      onSortChanged: (event) => {},
      onFilterModified: function (event) {
        //console.log(event);
      },
    };
  }

  private createSummaryColumnDef(): any[] {
    return [
      {
        headerName: "QuoteOption",
        field: "QuoteOption",
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
      {
        headerName: "LOB",
        field: "LOB",
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
    ];
  }

  private onSummaryRowClick(event: any): void {
    if (event.event.target !== undefined) {
      //let data: TestExpectedResults = event.data;
      this.saveExpectedResult();
      this.currentResults = event.data;
      this.createSelectableItemLists(this.currentResults);
    }
  }

  public saveExpectedResult(): void {
    if (this.optionalSelectableItems && this.optionalSelectableItems.length) {
      let optionalForms: string[] = [];
      for (var optional of this.optionalSelectableItems) {
        if (optional.Selected) {
          optionalForms.push(optional.Code);
        }
      }
      this.currentResults.OptionalForms = optionalForms;
    }

    if (this.mandatorySelectableItems && this.mandatorySelectableItems.length) {
      let mandatoryForms: string[] = [];
      for (var mandatory of this.mandatorySelectableItems) {
        if (mandatory.Selected) {
          mandatoryForms.push(mandatory.Code);
        }
      }
      this.currentResults.MandatoryForms = mandatoryForms;
    }
  }

  private configureMandatoryGrid(): void {
    this.mandatoryGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: "normal",
      columnDefs: this.createMandatoryolumnDef(),
      enableFilter: true,
      enableSorting: true,
      rowHeight: 30,
      enableColResize: true,
      onGridReady: () => {
        this.mandatoryGridOptions.api.setRowData(this.mandatorySelectableItems);
        //this.summaryGridOptions.api.sizeColumnsToFit();
        this.mandatoryGridOptions.columnApi.autoSizeAllColumns();
      },
      onRowClicked: (event) => {
        this.onRowSelected(event);
        this.mandatoryGridOptions.api.refreshCells();
        this.mandatoryFormCount = this.mandatorySelectableItems.filter(
          (item) => item.Selected
        ).length;
      },
      onFilterChanged: (event) => {},
      onSortChanged: (event) => {},
      onFilterModified: function (event) {
        //console.log(event);
      },
    };
  }

  private onRowSelected(event: any): void {
    //event.data.Selected = event.node.selected;

    event.data.Selected = !event.data.Selected;

    //if (event.node.selected) {
    //  if (event.data.Selected) {
    //    event.data.Selected = false;
    //  } else {
    //    event.data.Selected = true;
    //  }
    //}
  }

  /**
   * configure forms grid columns
   */
  private createMandatoryolumnDef(): any[] {
    return [
      {
        headerName: "",
        field: "Selected",
        maxWidth: 40,
        suppressFilter: true,
        cellRenderer: (params) => {
          return `<input type='checkbox' ${
            params.value == true ? "checked" : ""
          } />`;
        },
      },
      {
        headerName: "Form",
        field: "VisibleDescription",
        filter: "agTextColumnFilter",
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
    ];
  }

  private configureOptionalGrid(): void {
    //this.initialRowDataLoad$ = [];
    this.optionalGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: "normal",
      columnDefs: this.createOptionalColumnDef(),
      enableFilter: true,
      enableSorting: true,
      rowHeight: 30,
      enableColResize: true,
      onGridReady: () => {
        this.optionalGridOptions.api.setRowData(this.optionalSelectableItems);
        //this.summaryGridOptions.api.sizeColumnsToFit();
        this.optionalGridOptions.columnApi.autoSizeAllColumns();
      },
      onRowClicked: (event) => {
        this.onRowSelected(event);
        this.optionalGridOptions.api.refreshCells();
        this.optionalFormCount = this.optionalSelectableItems.filter(
          (item) => item.Selected
        ).length;
      },
      onFilterChanged: (event) => {},
      onSortChanged: (event) => {},
      onFilterModified: function (event) {
        //console.log(event);
      },
    };
  }

  /**
   * configure forms grid columns
   */
  private createOptionalColumnDef(): any[] {
    return [
      {
        headerName: "",
        field: "Selected",
        maxWidth: 40,
        suppressFilter: true,
        cellRenderer: (params) => {
          return `<input type='checkbox' ${
            params.value == true ? "checked" : ""
          } />`;
        },
      },
      {
        headerName: "Form",
        field: "VisibleDescription",
        filter: "agTextColumnFilter",
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
    ];
  }
}
