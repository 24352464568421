//Angular Imports
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

//Third Party Imports
import { AgGridModule } from "ag-grid-angular";
import { NgSelectModule } from "@ng-select/ng-select";

//Internal Imports
import { FrameworkModule, BooleanFilterComponent } from "../../framework";

import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";
import { EmailComponent } from "./components/email.component";
import { EmailRoutingModule } from "./email.routing";
import { NgbModule, NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import { MatTableModule } from "@angular/material/table";
import { MatSelectModule } from "@angular/material/select";
import { MatOptionModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";

/**
  Forms Module
*/
@NgModule({
  declarations: [EmailComponent],
  imports: [
    EmailRoutingModule,
    CommonModule,
    FormsModule,
    NgSelectModule,
    FrameworkModule,
    NgbModule,
    AgGridModule.withComponents([BooleanFilterComponent]),
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatTableModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule
  ],
  providers: [],
})
export class EmailModule {}
