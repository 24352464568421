//Angular Imports
import { Injectable } from "@angular/core";

//Internal Imports
import { UserProfile } from '../models';

import { MsalService } from '../../msal/msal.service';
import { User } from "msal/";
import { BroadcastService } from '../../msal/broadcast.service';
import { Subscription } from "rxjs";
import jwt_decode from 'jwt-decode';
import { Observable } from "rxjs";
import { UserInformation } from "../models/user-setting-info";

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  private _userProfile: UserProfile;
  private successSubscription: Subscription;
  private failureSubscription: Subscription;

  constructor(private broadcastService: BroadcastService,
    private authService: MsalService) {

    this._userProfile = new UserProfile("", "", []);

    let user: User = this.authService.getUser();
    if (user && user.idToken) {
      let token: any = user.idToken;

      this._userProfile.AccountName = token.preferred_username;
      this._userProfile.UserName = token.name;
     // this._userProfile.UserInformation = this.getUserInfo(token.name);
      if (token.roles == undefined || token.roles.length == 0) {
        var customRoles: string[] = [];
        customRoles.push("MarkelUser");
        this._userProfile.AddRoles(customRoles);
      }
      else {
        this._userProfile.AddRoles(token.roles);
      }
      
    }

    this.failureSubscription = this.broadcastService.subscribe("msal:loginFailure", (payload) => {
      console.log("login failure " + JSON.stringify(payload));

    });

    this.successSubscription = this.broadcastService.subscribe("msal:loginSuccess", (payload) => {
      console.log("login success message received in UserProfileService " + JSON.stringify(payload));

      let decoded = jwt_decode(payload._token);
      console.log('Name: ' + decoded.name);
      console.log('Roles: ' + decoded.roles);

      //this._userProfile = new UserProfile(decoded.preferred_username, decoded.name, decoded.roles);
      this._userProfile.AccountName = decoded.preferred_username;
      this._userProfile.UserName = decoded.name;
      if (decoded.roles == undefined || decoded.roles.length == 0) {
        var customRoles: string[] = [];
        customRoles.push("MarkelUser");
        this._userProfile.AddRoles(customRoles);
      }
      else {
        this._userProfile.AddRoles(decoded.roles);
      }
      

    });

  }

  ngOnDestroy() {
    this.broadcastService.getMSALSubject().next(1);
    if (this.successSubscription) {
      this.successSubscription.unsubscribe();
    }

    this.broadcastService.getMSALSubject().next(1);
    if (this.failureSubscription) {
      this.failureSubscription.unsubscribe();
    }
  }

  //set UserProfile(value: UserProfile) {
  //  this._userProfile = value;
  //}

  get UserProfile(): UserProfile {
    return this._userProfile;
  }

  get UserName(): string {
    return this._userProfile.UserName;
  }

  get RoleString(): string {
    return this._userProfile.RoleString;
  }

  get LoggedIn(): boolean {
    if (this.authService.getUser()) {
      return true;
    }
    else {
      return false;
    }
  }

  /*getUserInfo(username: string): Observable<UserInformation> {
    let url = `api/RuleEngineLog/CPMStore?key=` + key;
    return this.http.get<any[]>(url).pipe(
      map(res => res)
    );
  }*/

  //public GetProfile(): UserProfile {
  //  try {
  //    console.log("In UserProfileService.GetProfile");
  //    return <UserProfile>JSON.parse(window.sessionStorage.UserProfile);
  //  } catch (e) {
  //    console.log("In UserProfileService.GetProfile - no profile");
  //    return null;
  //  }
  //}

  //public SetProfile(profile: UserProfile) {
  //  console.log("In UserProfileService.SetProfile");
  //  if (profile) {
  //    console.log("In UserProfileService.SetProfile - Writing profile");
  //    window.sessionStorage.UserProfile = JSON.stringify(profile);
  //  }
  //}

}
