//Angular Imports 
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

//Third Party Imports
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import * as _ from 'lodash';

//Internal Imports
import { EnvironmentService, SharedService } from "../../framework/services";
import { SelectableItem } from "../../framework/models";

import { AdminForm, FormNumber, AttributeSet, RuleSetup, RuleSet, Rule, FormDeploymentRecord } from '../models';
import { DeployPackage } from "../../deploy";
import { RuleEngineForm } from "../models/ruleEngineForm.model";

/**
  * Execution  Provider
*/
@Injectable({
  providedIn: 'root'
})
export class FormPageService {

  // Constants
  public readonly MasterAttributeScope: string = "Master";

  // Cached data
  private lobSelectableItemList: SelectableItem[];
  private formsList: FormNumber[];

  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient,
    private envService: EnvironmentService,
    private sharedService: SharedService)
  {

  }

  getLobSelectableItemList(): Observable<SelectableItem[]> {
    if (this.lobSelectableItemList) {
      return of(this.lobSelectableItemList)
    }
    else {
      let lobCpmElementId = "6e7e1116-8717-4a23-907e-d50d0f5b71a2"
      let url = `api/MasterData/SelectableItem/${lobCpmElementId}`;
      
      return this.http.get<any>(url).pipe(map(res => {
        this.lobSelectableItemList = res;
        return res;
      })
      );
    }
  }

  doesFormNumberExist(formNumber: string): boolean {
    let form: FormNumber = this.formsList.find(x => x.InternalFormNumber === formNumber);
    if (form) {
      return true;
    } else {
      return false;
    }
  }

  addFormNumberToLocalList(formNumber: string, id: string): void {
    let fn: FormNumber = new FormNumber();
    fn.id = id;
    fn.InternalFormNumber = formNumber;
    this.formsList.push(fn);
  }

  // Get all form numbers to populate the search list.
  //getAllFormNumbers(): Observable<FormNumber[]> {

  //  if (this.formsList) {
  //    return of(this.formsList);
  //  }
  //  else {
  //    let url = 'api/AdminForm/FormNumbers';
  //    return this.http.get<FormNumber[]>(url).pipe(
  //      map(res => res)
  //    );
  //  }   
  //}

  //Retrieves all form numbers for the search bar on the Form Builder page
  getAllFormNumbers(): Observable<FormNumber[]> {
    if (this.formsList) {
      return of(this.formsList);
    }
    else {
      let url = 'api/AdminForm/FormNumbers';
      return this.http.get<FormNumber[]>(url).pipe(map(res => {
        this.formsList = res;
        return res;
      }));
    }
  }

  //Retrieves an Admin form based on ID
  getAdminForm(id: string): Observable<AdminForm> {
    let url = 'api/AdminForm/Id/' + id;
    return this.http.get<AdminForm>(url).pipe(
      map(res => res)
    );
  }

  //Finds all deployment history for a form by querying the PackageMetadata database and finding all packages that include this form
  getFormHistory(formid: string): Observable<FormDeploymentRecord[]> {
      let url = 'api/AdminForm/FormHistory/' + formid;
      return this.http.get<FormDeploymentRecord[]>(url).pipe(map(res => res));
  }

  //Adds a new Admin Form to the Workbench database
  addAdminForm(form: AdminForm): Observable<AdminForm> {
    let url = 'api/AdminForm';
    return this.http.post<AdminForm>(url, form).pipe(
      map(res => res)
    );
  }

  //Updates existing Admin Form
  updateAdminForm(form: AdminForm): Observable<AdminForm> {
    let url = 'api/AdminForm';
    return this.http.put<AdminForm>(url, form).pipe(
      map(res => res)
    );
  }

  getProducts(): Observable<any[]> {
    let url = `api/Configs/Products/GetProducts`;
    return this.http.get<any[]>(url).pipe(
      map(res => res));
  }

  //Queries the current environments' rule engine database to find all instances of the given form
  getRuleEngineForm(id: string, lob: string, env: string): Observable<RuleEngineForm[]> {
    var obj: {[k: string]: any} = {};
    obj.id = id;
    obj.lob = lob;
    obj.env = env;
    let url = 'api/AdminForm/RuleEngineSingleForm';
    return this.http.post<RuleEngineForm[]>(url, obj).pipe(map (res => res)
      );
  }

  getArchivedForm(formNumber: string): Observable<RuleEngineForm[]> {
    let url = `api/AdminForm/RuleEngineFormArchive/${formNumber}`;
    return this.http.get<RuleEngineForm[]>(url).pipe(map(res => res));
  }

  createNewForm(): AdminForm {
    let newForm: AdminForm = new AdminForm();
    newForm.id = this.sharedService.createGuid(); 

    let defaultRuleSetup: RuleSetup = new RuleSetup();
    defaultRuleSetup.IsDefault = true;
    defaultRuleSetup.RuleSet = new RuleSet();
    //defaultRuleSetup.RuleSet.Rules.push(new Rule());
    newForm.RuleSetups.push(defaultRuleSetup);

    let masterAttributeSet: AttributeSet = new AttributeSet();
    masterAttributeSet.Scope = this.MasterAttributeScope;
    newForm.AttributeSets.push(masterAttributeSet);

    return newForm;
  }

  copyForm(currentForm: AdminForm): AdminForm {
    let newForm: AdminForm = _.cloneDeep(currentForm);
    newForm.id = this.sharedService.createGuid();
    newForm.InternalFormNumber = "";
    newForm.ExternalFormNumber = "";

    return newForm;
  }

  updateFormWithLobSelections(form: AdminForm, lobSelections: SelectableItem[], isNewForm: boolean): void {
    // Filter to only selected items.
    var masterAttributeSet = form.AttributeSets.find(x => x.Scope === 'Master');

    var tempArray = lobSelections.filter(function (item) {
      if (item.Selected) {
        return true;
      }
    });
    lobSelections = tempArray;

    // Determine added LOBs, also add missing LOB's if not present
    let addedLOBs: string[] = [];
    for (const item of lobSelections) {
      if (!form.LOB.find(x => x === item.Code)) {
        addedLOBs.push(item.Code);
      }
    }

    // Determine removed LOBs
    let removedLOBs: string[] = [];
    for (const item of form.LOB) {
      if (!lobSelections.find(x => x.Code === item)) {
        removedLOBs.push(item);
      }
    }
    
    // Update the form's LOB array
    let lobs: string[] = [];
    for (var lob of lobSelections) {
      if (lob.Selected) {
        lobs.push(lob.Code);
      }
    }
    
    form.LOB = lobs;
    // Update AttributeSets; CR - copy LOB level attributes from master
    for (const item of addedLOBs) {
      let newAttributeSet: AttributeSet = new AttributeSet();
      newAttributeSet.Scope = item;
      newAttributeSet.EffectiveDate = masterAttributeSet.EffectiveDate;
      newAttributeSet.ExpirationDate = masterAttributeSet.ExpirationDate;
      newAttributeSet.SubmissionExpirationDate = masterAttributeSet.SubmissionExpirationDate;
      newAttributeSet.SortOrder = masterAttributeSet.SortOrder;
      newAttributeSet.FormType = masterAttributeSet.FormType;
      newAttributeSet.FormName = masterAttributeSet.FormName;
      form.AttributeSets.push(newAttributeSet);
    }
    //CR 4/27/20 Remove AttributeSets, not using splice because it was causing some odd behavior on save, instead assign array to object by pushing master and selected LOBS
    //CR 5/1/20 for new forms propogate Dates, Form Type, and Sort Order to all LOBs since they will most likely be the same
    
    let sets: AttributeSet[] = [];
    for (const item of form.AttributeSets) {
        if (item.Scope == 'Master' || form.LOB.indexOf(item.Scope) != -1) {
            if (isNewForm) {
                item.EffectiveDate = masterAttributeSet.EffectiveDate;
                item.ExpirationDate = masterAttributeSet.ExpirationDate;
                item.SubmissionExpirationDate = masterAttributeSet.SubmissionExpirationDate;
                item.SortOrder = masterAttributeSet.SortOrder;
                item.FormType = masterAttributeSet.FormType;
            }
            sets.push(item);
        }
        form.AttributeSets = sets;
    }
   
    // Update RuleSetups
    let defaultRuleSetup: RuleSetup = form.RuleSetups.find(x => x.IsDefault);
    if (isNewForm) {

      defaultRuleSetup.LOB = [];
      for (const item of lobSelections) {
        if (item.Selected) {
          defaultRuleSetup.LOB.push(item.Code);
        }
      
      }
      defaultRuleSetup.IsDefault = true;
    }
    else if (!defaultRuleSetup) {
      defaultRuleSetup = new RuleSetup();
      defaultRuleSetup.LOB = [];
      for (const item of lobSelections) {
        if (item.Selected) {
          defaultRuleSetup.LOB.push(item.Code);
        }
      
      }
      defaultRuleSetup.IsDefault = true;
      form.RuleSetups.push(defaultRuleSetup);
    }
    else {
      for (const item of addedLOBs) {
        defaultRuleSetup.LOB.push(item);
      }
    }
    
    
    // Clear any removed LOBs from the LOB list of any RuleSetup they are in
    for (const item of removedLOBs) {
      // Find the RuleSetup who's LOB list contains 'item'
      let setup: RuleSetup = form.RuleSetups.find(x => x.LOB.findIndex(y => y === item) !== -1);

      // Remove 'item' from the setup LOB list
      if (setup) {
        let index = setup.LOB.indexOf(item);
        setup.LOB.splice(index, 1);
      }

      // If a RuleSetup has an empty LOB list then remove it
      if (setup && setup.LOB.length === 0) {
        let index = form.RuleSetups.indexOf(setup);
        form.RuleSetups.splice(index, 1);
      }
    }
    if (form.RuleSetups.length == 1) {
      form.RuleSetups[0].IsDefault = true;
    }
  }
  
}


