import { formatDate } from "@angular/common";

export class StagingFile {
  id: string;
  filename: string; // Comes from summary data row
  partner: string;
  product: string;
  type: string;
  status: string;
  startTime: string;
  endTime: string;
  message: string;
  notes: any;
  cached: any;
  expired: boolean;

  constructor(objectData?: any, filename?: string) {
    if (objectData) {
      this.id = objectData.File[0].FileId || "";
      this.filename = filename || "";
      this.partner = objectData.File[0].Partner || "";
      this.product = objectData.File[0].Product || "";
      this.type = objectData.File[0].Type || "";
      this.status = objectData.File[0].Status || "";
      this.startTime = this.formatTimestamp(objectData.File[0].FileStartTime);
      this.endTime = this.formatTimestamp(objectData.File[0].FileEndTime);
      this.message = objectData.File[0].ErrorMessage || "";
      this.notes = this.parseNotes(objectData.Notes);
      this.cached = new Date().getTime();
      this.expired = false;
    }
  }

  formatTimestamp(dateValue: any): string {
    if (!dateValue) {
      return 'NA';
    }
    if (isNaN(Date.parse(dateValue))) {
      return (dateValue + " UTC");
    }
    //Remove any Z at the end of the date string so it won't be converted to local time.
    let staticDate = dateValue.replace(/Z$/, "");
    let date = new Date(Date.parse(staticDate));
    return (formatDate(date, 'MMM d, y h:mm:ss a', 'en-US') + " UTC");
  }

  parseNotes(rawNotes: any[]): any[] {
    let notes = [];
    if (rawNotes.length) {
      rawNotes.forEach((item: any) => {
        notes.push({
          fileUploadId: item.FileUploadId,
          status: item.Status,
          date: item.Date,
          user: item.User,
          type: item.Type,
          message: item["Note/Reason/Error"]
        });
      });
    }
    return notes;
  }

  setStatusAndExpire(status: string): void {
    this.status = status;
    this.expired = true;
  }

  appendToStatusAndExpire(action: string): void {
    this.status += " (" + action + ")";
    this.expired = true;
  }

  isCurrent(): boolean {
    if (this.expired) {
      return false;
    }
    let currentTime = new Date().getTime()
    if (currentTime - this.cached > 10800000) { // Check if older than 3 hours  
      this.expired = true;
      return false;
    }
    return true;
  }
}
