//Angular Imports 
import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';

//Third Party Imports
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

//Internal Imports 
import { SelectableItem } from '../../../../../framework/models/selectable-item.model';
import { UserProfileService } from '../../../../../framework/services/user-profile.service';

import 'rxjs';
import { TestHarnessService } from '../../services/testHarness.service';
import {TestHarnessTestGroup} from '../../models/testHarness-testGroup.model';


/**
 * Forms Component
*/
@Component({
  selector: 'app-testHarness-testGroup',
  templateUrl: './testHarness-testGroup.component.html',
  styleUrls: ['./testHarness-testGroup.component.scss']
})

export class TestHarnessTestGroupComponent {

  // Specifies the action type the screen was opened for (Add, Edit)
  @Input() public actionType: string;

  // The test group passed into the component when editing an existing component
  @Input() public testGroup: TestHarnessTestGroup;

  // Used for the Test Group Type dropdown
  public typeList: any[];
  public release: string;

  // Set to true when user clicks the Save button so validation messages will show
  public formSubmitted: boolean

  /** 
   * Constructor
   * @ignore
  */
  constructor(public activeModal: NgbActiveModal,
    private harnessService: TestHarnessService,
    private userProfileService: UserProfileService,
    private _modal: NgbModal,
    private toastr: ToastrService) {

  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
  */
  ngOnInit(): void {
    if (this.actionType === "Add") {
     this.testGroup = new TestHarnessTestGroup();
    } else if (this.actionType === "Edit") {
      this.release = this.testGroup.Release;
    } else {
      this.toastr.error("Invalid actionType: " + this.actionType);
    }

    this.createSelectableItemLists();
  }

  /**
   * NgOnDestroy
   * @ignore
  */
  ngOnDestroy(): void {

  }

  public saveTestGroup() {
   this.activeModal.close(this.testGroup);
  }

  private saveSelectableItems() {

  }



  private createSelectableItemLists(): void {

  }

  private sortSelectableItems(items: SelectableItem[]) {
    items.sort((a, b) => {
      if (a.Selected == b.Selected) {
        return 0;
      }
      else if (a.Selected) {
        return -1;
      }
      else
        return 1;
    });
  }
}
