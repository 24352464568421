import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { PolicyForm } from "../../../../ghostPortal/formsList/models/policyForm.model";
import * as xml2js from "xml2js";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { DocumentInfo } from "../../../testSuites/testHarness/models/testHarness-documentInfo.model";
import { Observable, map } from "rxjs";
import { TestHarnessTestCase } from "../../../testSuites/testHarness/models/testHarness-testCase.model";

@Injectable()

export class GenerateDocumentService {

    constructor(private http: HttpClient, private toastr: ToastrService) { }

    public packageNames: string[] = [];
    public policyFormList: PolicyForm[];
    lineOfBusinessList: DocumentInfo[] = [];
    envList = [
        { Name: "DEV1", ID: 0 },
        { Name: "DEV2", ID: 1 },
        { Name: "QA1", ID: 2 },
        { Name: "QA2", ID: 3 },
        { Name: "UAT1", ID: 4 },
        { Name: "UAT2", ID: 5 },
        { Name: "PXUAT", ID: 8 },
        { Name: "MIRROR", ID: 6 },
        { Name: "FIT", ID: 7 },
        { Name: "PROD", ID: 9 },
        { Name: "FAILOVER", ID: 10 },
        { Name: "LOCAL", ID: 11 }
    ];
    public customCPM: string;
    public dealNumber: string;

    public gdfError: boolean = false;
    public gdfErrorText: string = "";

    public selectedFormList: PolicyForm[] = [];
    public selectedEnvComponent: string;
    public TransactionId: string;

    public canCreateTest: boolean = false;

    public APIResponse: string;
    public Performance: string = "";
    public Errors: string;

    public testCase: TestHarnessTestCase = new TestHarnessTestCase();


    loadLog(result: any) {
        this.customCPM = result.XmlData;
        var date = Date.parse(result.TimeStamp);
        //this.formsTxt = result.Forms;
        //this.timestamp = new Date(date).toString();
        var dealNumber;
        //this.actionType = result.ActionType;
        if (this.customCPM.startsWith("<")) {
            var parser = new xml2js.Parser();
            //Parse CPM for certain log info
            parser.parseString(this.customCPM, function (err, result) {
                if (result.QuoteMessage != null) {
                    dealNumber = String(
                        result.QuoteMessage.Quote[0].QuoteOptions[0].QuoteOption[0]
                            .Policy[0].SubmissionNumber
                    );
                } else {
                    dealNumber = String(result.PolicyMessage.Policy[0].SubmissionNumber);
                }
            });
        } else {
            var s = this.customCPM;
            // preserve newlines, etc - use valid JSON
            s = s
                .replace(/\\n/g, "\\n")
                .replace(/\\'/g, "\\'")
                .replace(/\\"/g, '\\"')
                .replace(/\\&/g, "\\&")
                .replace(/\\r/g, "\\r")
                .replace(/\\t/g, "\\t")
                .replace(/\\b/g, "\\b")
                .replace(/\\f/g, "\\f");
            // remove non-printable and other non-valid JSON chars
            s = s.replace(/[\u0000-\u0019]+/g, "");
            var json = JSON.parse(s.trim());
            if (json.QuoteMessage != null) {
                dealNumber =
                    json.QuoteMessage.QuoteOptions[0].QuoteOption.Policy.PolicyNumber;
            } else {
                dealNumber = json.PolicyMessage.Policy.PolicyNumber;
            }
        }

        this.dealNumber = dealNumber;
        //Display log box if log has error (hasError is checked on log popup)
        if (result.hasError) {
            this.gdfError = true;
            this.gdfErrorText = result.ErrorMessage;
        } else {
            this.gdfError = false;
            this.gdfErrorText = "";
        }
        //this.logSelected = true;
        this.importForms();
    }

    importForms() {
        //If CPM Input exists initialize temp form list and parser to parse XML to JSON
        if (this.customCPM != null && this.customCPM.length > 1) {
            var tempFormList: any[] = [];
            //Check for JSON CPM vs XML
            if (this.customCPM.startsWith("{")) {
                var s = this.customCPM;
                // preserve newlines, etc - use valid JSON
                s = s
                    .replace(/\\n/g, "\\n")
                    .replace(/\\'/g, "\\'")
                    .replace(/\\"/g, '\\"')
                    .replace(/\\&/g, "\\&")
                    .replace(/\\r/g, "\\r")
                    .replace(/\\t/g, "\\t")
                    .replace(/\\b/g, "\\b")
                    .replace(/\\f/g, "\\f");
                // remove non-printable and other non-valid JSON chars
                s = s.replace(/[\u0000-\u0019]+/g, "");
                var json = JSON.parse(s.trim());
                console.log(json);
                var forms: any;
                if (json.QuoteMessage != null) {
                    forms = json.QuoteMessage.QuoteOptions[0].QuoteOption.Policy;
                } else {
                    forms = json.PolicyMessage.Policy.Form;
                }
                console.log(forms);
                for (var i = 0; i < forms.length; i++) {
                    var form = new PolicyForm();
                    form.FormName = forms[i].InternalFormNumber;
                    form.PackageName = "Imported";
                    tempFormList.push(form);
                }
            } else {
                var parser = new xml2js.Parser();
                parser.parseString(this.customCPM, function (err, result) {
                    var forms: any;
                    //Pull forms list into array var
                    if (result.QuoteMessage != null) {
                        forms =
                            result.QuoteMessage.Quote[0].QuoteOptions[0].QuoteOption[0]
                                .Policy[0].Forms[0].Form;
                    } else {
                        forms = result.PolicyMessage.Policy[0].Forms[0].Form;
                    }
                    //Create form object and populate fields used in grid, then push to temp list
                    for (var i = 0; i < forms.length; i++) {
                        var form = new PolicyForm();
                        form.FormName = forms[i].$.InternalFormNumber;
                        form.PackageName = "Imported";
                        tempFormList.push(form);
                    }
                });
            }

            //Create policy form object and iterate through keys to populate all fields with default value (needed to set global list since model does not have default constructor)
            var obj = new PolicyForm();
            let keys = Object.keys(obj);
            let def = keys.reduce((result, key) => {
                result[key] = "";
                return result;
            }, {});
            let result = tempFormList.map((item) => ({ ...def, ...item }));
            this.selectedFormList = result;
        } else {
            this.toastr.error("Data input required. (CPM/CCM/GBS)", "Missing Info", {
                positionClass: "toast-bottom-right",
            });
            return;
        }
    }

    onEnvChange(selectedValue) {
        this.selectedEnvComponent = selectedValue.Name;
        //this.logsErr = false;
        this.getJwt(this.selectedEnvComponent).subscribe((t: string) => {
            var env =
                this.selectedEnvComponent == "FAILOVER"
                    ? "PROD"
                    : this.selectedEnvComponent;
            this.getDocumentInfo(
                `{ 'Documents': null, 'Data': '', 'CallingSystem': 'GhostPortal', 'Environment': '${env}', 'TransactionId': null, 'CombineDocuments': false, 'IsPreview': false, 'DocumentDomain': null, 'DocumentFormat': null }`,
                this.selectedEnvComponent,
                t
            )
                .subscribe((res) => {
                    this.lineOfBusinessList = res;
                    this.packageNames = [... new Set(this.lineOfBusinessList.map(x => x.PackageName))];
                    console.log(this.packageNames);
                });
            this.canCreateTest = false;
        });
  } 

  openGhostDraftExtension(transactionId: string, environment: string) {
    let errors = [];
    if (transactionId == null) errors.push("Transaction ID required.");
    if (environment == null) errors.push("Environment required.");
    if (errors.length < 1) {
      environment = environment.toLowerCase();
      transactionId = transactionId.toUpperCase();
      var gurl = `https://extension-${environment}.azurewebsites.net/?transactionId=${transactionId}`;
      window.open(gurl, "_blank");
    }
    else {
      return "Errororor";
    }
  }

    mapAndAssembleMultiple() {
        let errors = [];
        if (this.customCPM == null)
            errors.push("Data input required. (CPM/CCM/GBS)");
        if (this.selectedEnvComponent == null) errors.push("Environment required.");
        if (this.selectedFormList.length < 1) errors.push("Forms required.");
        if (errors.length < 1) {
            var formNames: string = ``;
            this.selectedFormList.forEach((form) => {
                formNames += `"${form.FormName}",`;
            });
            //After compiling list of form names remove trailing comma
            formNames = formNames.substr(0, formNames.length - 1);
            this.mapAndAssemble(formNames, false);
        } else {
            errors.forEach((e) => {
                this.toastr.error(e, "Missing Info", {
                    positionClass: "toast-bottom-right",
                });
            });
        }
    }

    private mapAndAssemble(formNames: string, isPreview: boolean) {
        //If not a preview call and CPM Input box has data populate data variable to pass to service function
        this.APIResponse = "";
        this.Errors = "";
        var data: string = null;
        var cpmData: string = null;
        var envForBody =
            this.selectedEnvComponent == "FAILOVER"
                ? "PROD"
                : this.selectedEnvComponent;

        if (this.customCPM != null && this.customCPM.length > 0 && !isPreview) {
            cpmData = this.customCPM;
            cpmData = cpmData.replace(/"/g, '\\"');
            if (cpmData.startsWith("{")) {
                cpmData = cpmData.replace(/\\\\"/g, '\\\\\\"')
            }
            data = `{"Documents":[${formNames}],"Data":"${cpmData}","CallingSystem":"GhostPortal","Environment":"${envForBody}","TransactionId":null,"CombineDocuments":true,"IsPreview":false,"DocumentDomain":null,"DocumentFormat":"PDF"}`;
        } else if (isPreview) {
            cpmData = `<PolicyMessage xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xmlns:xsd=\"http://www.w3.org/2001/XMLSchema\" xmlns=\"http://markel.common.policy/2017/1\">
                                                            <MessageID>ED049355-0FBD-43C5-84C5-53CC72D0DA25</MessageID>
                                                            <SourceSystem>38</SourceSystem>
                                                            <SourceSystemName>e2</SourceSystemName>
                                                            <CallingSystem>38</CallingSystem>
                                                            <CallingSystemName>e2</CallingSystemName>
                                                            <TargetSystem>GhostDraft</TargetSystem>
                                                            <TargetSystemName>GhostDraft</TargetSystemName>
                                                            <ActionType>PrepareQuoteDocuments</ActionType>
                                                            <ActionReason xsi:nil=\"true\" />
                                                            <MessageDate>2019-06-20T06:37:27.2870331</MessageDate>
                                                            <ActionEffectiveDate>2019-06-20T00:00:00</ActionEffectiveDate>
                                                            <TransactionPremiumAmount>150937.5</TransactionPremiumAmount>
                                                            <Policy>
                                                            </Policy>
                                                          </PolicyMessage>`;
            cpmData = cpmData.replace(/"/g, '\\"');
            data = `{"Documents":["${formNames}"],"Data":"${cpmData}","CallingSystem":"GhostPortal","Environment":"${envForBody}","TransactionId":null,"DocumentDomain":null,"WithData":false,"IsDraft":true}`;
        } else {
            this.Errors = "Error: CPM Input required on Map and Assemble";
            return;
        }
        //this.APIRequest = data;
        let startFrom = performance.now();
        let env = this.selectedEnvComponent.toLowerCase();
        this.getJwt(env).subscribe((t) => {
            this.exportForms(data, isPreview, env, t).subscribe(
                (res) => {
                    let responseTime = ((performance.now() - startFrom) / 1000).toFixed(
                        4
                    );
                    this.Errors = "";
                    if (isPreview) {
                        this.Performance +=
                            `${this.selectedEnvComponent}, Preview, ${formNames}, ` +
                            responseTime +
                            " seconds\n";
                    } else {
                        this.Performance +=
                            `${this.selectedEnvComponent}, MapAndAssemble, ${formNames}, ` +
                            responseTime +
                            " seconds\n";
                    }
                    this.APIResponse = "Document generated successfully";
                    this.testCase.CPM = this.customCPM;
                    //this.testCase.ExpectedResults = res[0].Content;
                    this.testCase.Forms = [];
                    this.selectedFormList.forEach((form) => {
                        this.testCase.Forms.push(form.FormName);
                    });
                    this.canCreateTest = true;

                    const byteCharacters = atob(res[0].Content);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    var blob = new Blob([byteArray], { type: "application/pdf" });
                    if (window.navigator && window.navigator['msSaveOrOpenBlob']) {
                        window.navigator['msSaveOrOpenBlob'](blob, "Ghostportal_Form.pdf");
                    } else {
                        var downloadURL = window.URL.createObjectURL(blob);
                        if (isPreview) {
                            window.open(downloadURL, "_blank");
                        } else {
                            var link = document.createElement("a");
                            link.href = downloadURL;
                            link.download = "GhostPortal_Form.pdf";
                            link.click();
                        }
                    }
                },
                (err: HttpErrorResponse) => {
                    let responseTime = ((performance.now() - startFrom) / 1000).toFixed(
                        4
                    );
                    if (isPreview) {
                        this.Performance +=
                            `${this.selectedEnvComponent}, Preview, ${formNames}, ` +
                            responseTime +
                            " seconds\n";
                    } else {
                        this.Performance +=
                            `${this.selectedEnvComponent}, MapAndAssemble, ${formNames}, ` +
                            responseTime +
                            " seconds\n";
                    }
                    console.log(err);
                    this.Errors = err.message + "\n" + err.error;
                }
            );
        });
    }



    getJwt(env: string) {
        var gurl = `https://mkl-mgdapi-${env.toLowerCase()}.azurewebsites.net/api/v2/document/login?UserName=Specialty&Key=BD5065AF-2A62-4002-B08F-0EB91221FDF1`;
        if (env.toLowerCase() == "local") {
            gurl = `http://localhost:63161/api/v2/document/login?UserName=Specialty&Key=BD5065AF-2A62-4002-B08F-0EB91221FDF1`;
        }
        var headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        return this.http.get(gurl, { headers, responseType: 'text' }).pipe(map(res => res));
    }

    getDocumentInfo(metadata: string, env: string, token: string): Observable<any> {
        var gurl = `https://mkl-mgdapi-${env}.azurewebsites.net/api/v2/document/documentInfo`;
        if (env == "LOCAL") {
            gurl = `http://localhost:63161/api/v2/document/documentInfo`;
        }
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', `Bearer ${token}`);
        headers = headers.append('Content-Type', 'application/json');
        return this.http.post<any>(gurl, metadata, { headers }).pipe(map(res => res));
    }

    exportForms(testData: string, isPreview: boolean, env: string, token: string): Observable<any> {
        //If preview populate mock CPM data and call preview
        if (isPreview) {
            //var gurl = 'https://localhost:63161/api/v2/document/preview';
            var gurl = `https://mkl-mgdapi-${env}.azurewebsites.net/api/v2/document/preview`;
            if (env.toLowerCase() == "local") {
                gurl = `https://localhost:63161/api/v2/document/preview`;
            }
            var headers = new HttpHeaders();
            headers = headers.append('Accept', 'application/pdf');
            headers = headers.append('Authorization', `Bearer ${token}`);
            headers = headers.append('Content-Type', 'application/json');
            // var data = `{"Documents":["${formNames}"],"Data":"${testData}","CallingSystem":"GhostPortal","Environment":"${env}","TransactionId":null,"DocumentDomain":null,"WithData":false,"IsDraft":true}`;
            return this.http.post<any>(gurl, testData, { headers }).pipe(map(res => res));
        }
        //Format CPM data before sending to API
        var gurl = `https://mkl-mgdapi-${env}.azurewebsites.net/api/v2/document/mapandassemble`;
        if (env.toLowerCase() == "local") {
            gurl = `https://localhost:63161/api/v2/document/mapandassemble`;
        }
        //var gurl = 'https://localhost:63161/api/v2/document/mapandassemble';
        var headers = new HttpHeaders();
        headers = headers.append('Accept', 'application/pdf');
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${token}`);
        return this.http.post<any>(gurl, testData, { headers }).pipe(map(res => res));
    }
}

