//Angular Imports 
import { Component, HostBinding } from '@angular/core';

//Third Party Imports 
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


//Internal Imports 


import 'rxjs';
import { AppInsightsService, EnvironmentService, DateTimeUtcPipe, SharedService, ApplicationInsightsBaseComponent, CPMElement } from '../../../framework';
import { EXECUTION_ENGINE_LIST } from '../../../report/configs';
import { RulesService } from '../services/rules.service';
import { FormNumber } from '../models/formNumber.model';
import { MatTableDataSource } from '@angular/material/table';
import { PortalRule } from '../models/portalRule';
import { SystemRules } from '../models/systemRules';
import { isNull } from 'lodash';



/**
 * Forms Component
*/
@Component({
  selector: 'app-portalRules-settings',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss'],
  providers: [RulesService]
})

export class RulesComponent extends ApplicationInsightsBaseComponent {

  @HostBinding('style.width') width: string;

  initialRowDataLoad$;

  // Used to populate environment list on Unit Test and Run Test Groups tabs
  envList: any[];

  // Holds the environment name selected from the environment list on the Unit Test or Run Test Groups tab
  selectedEnv: string;

  systems: any[] = ['e2', 'Magic','Maverick'];
  selectedSystem: string;

  actions: any[] = ['Print', 'Onbase', 'Email'];
  selectedAction: string;

  public formsList: FormNumber[];

  public selectedSearchForm: string;

  public tableSource = new MatTableDataSource<PortalRule>();

  valid: any = {};

  public tableSchema: any[] = [{
    key: "product",
    type: "text",
    label: "Product"
  },
  {
    key: "folder",
    type: "text",
    label: "Folder"
    },
    {
      key: "isEdit",
      type: "isEdit",
      label: ""
    }];
  public displayedColumns: string[] = this.tableSchema.map((col) => col.key);
  public allrules: SystemRules[] = [];
  public sampleData: any[] = [];


  /** 
   * Constructor
   * @ignore
  */
  constructor(private _appInsightsService: AppInsightsService,
    private toastr: ToastrService,
    private _modal: NgbModal,
    private rulesService: RulesService,
    private sharedService: SharedService,
    private dateTimePipe: DateTimeUtcPipe,
    private environmentService: EnvironmentService) {
    super(_appInsightsService);
    this.width = "100%";
    this.envList = EXECUTION_ENGINE_LIST;
    this.selectedEnv = this.envList[0].Name;
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
  */
  ngOnInit(): void {
    this.rulesService.getPortalRules().subscribe(res => {
      this.allrules = res;
    });
  }

  /**
   * NgOnDestroy
   * @ignore
  */
  ngOnDestroy(): void {

  }

  onSystemChange(event) {
    this.selectedAction = "";
  }

  onActionChange(event) {
    var setup = this.allrules.find(x => x.System == this.selectedSystem && x.Action == this.selectedAction);
    if (isNull(setup.Items)) {
      this.sampleData = [];
    }
    else {
      this.sampleData = setup.Items;
    }
    
    this.tableSource.data = this.sampleData;
  }

  onSelectSearchForm(event) {
    // load new form into page
    

  }

  editRow(row: PortalRule) {
    if (row.id === 0) {

      var index = this.allrules.findIndex(i => i.System == this.selectedSystem && i.Action == this.selectedAction);
      this.allrules[index].Items = this.tableSource.data;
      console.log(this.allrules[index]);
      this.rulesService.updatePortalRule(this.allrules[index]).subscribe(() => (row.isEdit = false));
      row.isEdit = false;

    } else {

      var index = this.allrules.findIndex(i => i.System == this.selectedSystem && i.Action == this.selectedAction);
      this.allrules[index].Items = this.tableSource.data;
      console.log(this.allrules[index]);
      this.rulesService.updatePortalRule(this.allrules[index]).subscribe(() => (row.isEdit = false));
      row.isEdit = false;

    }
  }

  addRow() {
    const newRow: PortalRule = {
      folder: '',
      product: '',
      isEdit: true,
      isSelected: false,
    };
    this.tableSource.data = [newRow, ...this.tableSource.data];
  }

  removeRow(product: string, folder: string) {
    if (confirm('Delete the row?')) {
      this.tableSource.data = this.tableSource.data.filter(
        (u: PortalRule) => u.product !== product && u.folder !== folder
      );
      var index = this.allrules.findIndex(i => i.System == this.selectedSystem && i.Action == this.selectedAction);
      this.allrules[index].Items = this.tableSource.data;
      this.rulesService.updatePortalRule(this.allrules[index]).subscribe((res) => console.log(res));
    }
  }

  inputHandler(e: any, id: number, key: string) {
    if (!this.valid[id]) {
      this.valid[id] = {};
    }
    this.valid[id][key] = e.target.validity.valid;
  }

  disableSubmit(id: number) {
    if (this.valid[id]) {
      return Object.values(this.valid[id]).some((item) => item === false);
    }
    return false;
  }


}
