//Angular Imports 
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { QueueComponent } from './components/queue.component';

//Internal Imports

/**
  Form Routes
*/
export const routes: Routes = [
  {
    path: '', component: QueueComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})

export class QueueRoutingModule {
}
