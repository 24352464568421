export class PartnerHealthFilter {
  startDate: Date;
  endDate: Date;
  status: string[];
  partner: string;
  jobId: string;
  jobIdArray: string[];
  active: boolean = false;

  constructor(objectData?: any) {
    this.startDate = objectData.startDate || "";
    this.endDate = objectData.endDate || "";
    this.status = objectData.status || [];
    this.partner = objectData.partner || [];
    this.jobId = objectData.jobId || "";
    this.jobIdArray = objectData.jobIdArray || [];
    this.active = objectData.active || false;
    return this;
  }
}
