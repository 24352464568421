//Angular Imports 
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

//Third Party Imports
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import * as _ from 'lodash';

//Internal Imports
import { SkippableInterceptorHeader } from "../../../../framework/enums";
import { EnvironmentService, SharedService } from "../../../../framework/services";
import { SelectableItem } from "../../../../framework/models";
import { coverageCpmElementId, lobCpmElementId } from '../configs';
import {
  TestGroup, TestCaseResult, TestExecution, TestCase,
  TestExpectedResults, FormTestCase
} from '../models';
//import { TestCase } from '../models/test-case.model';

import {
  RuleEngineResult,  RuleResult,
  RuleEngineLog, TestRun
} from '../models';


@Injectable({
  providedIn: 'root'
})
export class TestingService {

  constructor(private http: HttpClient, private sharedService: SharedService) {
  }

  getPolicyForms(cpmString: string, environment: string): Observable<any[]> {
    let cpm = { "CpmString": cpmString, "Environment": environment };

    let url = `api/Testing/GetPolicyFormsClassic`;
    return this.http.post<any>(url, cpm).pipe(
      map(res => res)
    );
  }

  getPolicyFormsPrototype(cpmString: string, environment: string): Observable<any[]> {
    let cpm = { "CpmString": cpmString, "Environment": environment };

    let url = `api/Testing/GetPolicyForms`;
    return this.http.post<any>(url, cpm).pipe(
      map(res => res)
    );
  }

  executeTestRun(testRun: TestRun): Observable<any[]> {
    // set this header so the loading spinner doesn't display while this call runs
    let headers = new HttpHeaders().set(SkippableInterceptorHeader.ShowLoaderSkipHeader, 'true');

    let url = `api/Testing/ExecuteTestRun`;
    return this.http.post<any>(url, testRun, { headers: headers }).pipe(
      map(res => res)
    );
  }

  addTestCase(testCase: TestCase): Observable<TestCase> {
    let url = `api/TestCase`;
    return this.http.post<TestCase>(url, testCase).pipe(
      map(res => res)
    );
  }

  updateTestCase(testCase: TestCase): Observable<TestCase> {
    let url = `api/TestCase`;
    return this.http.put<TestCase>(url, testCase).pipe(
      map(res => res)
    );
  }

  deleteTestCase(testCaseId: string): Observable<any[]> {
    let url = `api/TestCase?id=` + testCaseId;
    return this.http.delete<any>(url).pipe(
      map(res => res)
    );
  }

  updateFormTestCases(formTestCases: FormTestCase[]): Observable<any> {
    let url = `api/TestCase/UpdateFormTestCases`;
    return this.http.post<FormTestCase[]>(url, formTestCases).pipe(
      map(res => res)
    );
  }

  getAllFormNumbersByLob(lob: string): Observable<any[]> {
    let items: string[] = [];

    let url = `api/Adminform/LOB/${lob}`;
    return this.http.get<any>(url).pipe(
      map(res => res)
    );
  }

  getSelectableCpmElementList(cpmElementId: string):Observable <any[]> {
    let items: SelectableItem[] = [];

    let url = `api/MasterData/SelectableItem/${cpmElementId}`;
    return this.http.get<any>(url).pipe(
      map(res => res)
    );
  }

  addTestGroup(testGroup: TestGroup): Observable<TestGroup> {
    let url = `api/TestGroup`;
    return this.http.post<TestGroup>(url, testGroup).pipe(
      map(res => res)
    );
  }

  updateTestGroup(testGroup: TestGroup): Observable<TestGroup> {
    let url = `api/TestGroup`;
    return this.http.put<TestGroup>(url, testGroup).pipe(
      map(res => res)
    );
  }

  deleteTestGroup(testGroupId: string): Observable<any[]> {
    let url = `api/TestGroup?id=` + testGroupId;
    return this.http.delete<any>(url).pipe(
      map(res => res)
    );
  }

  getTestGroupById(testGroupID: string): Observable<any[]> {
    let items: TestCase[] = [];
    let url = `api/TestGroup/GetById?id=` + testGroupID;
    return this.http.get<any>(url).pipe(
      map(res => res)
    );
  }

  getTestGroups(): Observable<any[]> {
    let items: TestGroup[] = [];

    let url = `api/TestGroup`;
    return this.http.get<any>(url).pipe(
      map(res => res)
    );
  }

  getTestCasesByTestGroupId(testGroupId: string): Observable<any[]> {
    let items: TestCase[] = [];
    let url = `api/TestCase/GetByTestGroupId?testGroupId=` + testGroupId;
    return this.http.get<any>(url).pipe(
      map(res => res)
    );
  }

  getTestCaseById(testCaseId: string): Observable<TestCase> {
    let items: TestCase[] = [];
    let url = `api/TestCase/GetById?id=` + testCaseId;
    return this.http.get<any>(url).pipe(
      map(res => res)
    );
  }

  getAllTestCaseSummary(): Observable<any[]> {
    let items: TestCase[] = [];
    let url = `api/TestCase/Summary`;
    return this.http.get<any>(url).pipe(
      map(res => res)
    );
  }

  getCpmDataForTestCase(cpmString: string): Observable<any> {
    let cpm = { "CpmString": cpmString };

    let url = `api/TestCase/CPMRuleData`;
    return this.http.post<any>(url, cpm).pipe(
      map(res => res)
    );
  }

  getFormTestCases(lob: string, formNumber: string): Observable<any[]> {
    let items: TestCase[] = [];
    let url = `api/TestCase/FormTestCases?lob=${lob}&formNumber=${formNumber}`;
    return this.http.get<any>(url).pipe(
      map(res => res)
    );
  }

}
