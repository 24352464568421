<div class="modal-header">
  <h4 class="modal-title">Log Search</h4>
</div>

<div
  class="col-md-12"
  id="lob-content-container"
  style="padding-top: 20px; padding-bottom: 20px"
>
  <div class="heading-container fast_admin">
    <label class="label_heading">Deal Number:</label>
    <label>
      <input
        id="txtDealNumber"
        class="vex-input search-input"
        [(ngModel)]="dealNumber"
      />
    </label>
    <span style="padding-left: 30px">
      <button
        type="button"
        class="mat_btn_hov_act"
        mat-raised-button
        (click)="searchLog()"
      >
        Search
      </button>
    </span>
  </div>
</div>

<div id="summary-grid-container" class="col-12">
  <ag-grid-angular
    class="ag-theme-balham fast_panel_table"
    [gridOptions]="resultsGridOptions"
    style="height: 400px"
  >
  </ag-grid-angular>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="mat_btn_hov_act"
    mat-raised-button
    (click)="createTestCase()"
  >
    Create Test Case
  </button>
  <button
    type="button"
    class="mat_btn_hov_act mrgrgt10 mrglt10"
    mat-raised-button
    (click)="selectLog()"
  >
    Select
  </button>
  <button
    type="button"
    class="mat_btn_hov_act"
    mat-raised-button
    (click)="activeModal.close()"
  >
    Close
  </button>
</div>
