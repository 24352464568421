import { Component, OnInit, Input } from '@angular/core';
import { ColDef, GridApi, GridOptions } from 'ag-grid-community';
import { ImportQueueService } from "../../services/import-queue.service";
import { JobIdCellComponent } from '../job-id-cell/job-id-cell.component';

@Component({
  selector: 'app-entity-log-detail-tab',
  templateUrl: './entity-log-detail-tab.component.html',
  styleUrls: ['./entity-log-detail-tab.component.scss']
})
export class EntityLogDetailTabComponent implements OnInit {
  @Input() idBundle: any;

  private actionHistoryGridApi: GridApi;
  frameworkComponents: any;
  details: any;
  processLogsColumnDefs: ColDef[];
  actionHistoryColumnDefs: ColDef[];
  pagination: boolean = true;
  paginationAutoPageSize: boolean = true;
  processLogsGridOptions: GridOptions;
  actionHistoryGridOptions: GridOptions;

  constructor(private queueService: ImportQueueService) {

    let self = this;
    this.processLogsGridOptions = {
      onFirstDataRendered: function () {
        self.processLogsGridOptions.api.sizeColumnsToFit();
      }
    };

    this.actionHistoryGridOptions = {
      onFirstDataRendered: function () {
        self.actionHistoryGridOptions.api.sizeColumnsToFit();
      }
    };

    this.processLogsColumnDefs = [
      { field: 'errorMessage', headerName: "Message", sortable: true, filter: true, resizable: true, width: 250 },
      { field: 'stepNumber', headerName: "Step Number", sortable: true, filter: true, resizable: true, width: 145 },
      { field: 'processingStep', headerName: "Processing Step", sortable: true, filter: true, resizable: true, width: 300 },
      { field: 'status', headerName: "Status", sortable: true, filter: true, resizable: true, width: 150 },
      { field: 'eventType', headerName: "Event Type", sortable: true, filter: true, resizable: true },    
      {
        field: 'startTime',
        headerName: "Start Time",
        sortable: true,
        comparator: this.queueService.dateComparator,
        sort: "desc",
        cellRenderer: params => {
          return this.queueService.formatTimestamp(params.value);
        },
        width: 250
      }
    ];

    this.actionHistoryColumnDefs = [
      { field: 'status', headerName: "Status", sortable: true, filter: true, resizable: true, width: 150 },
      {
        field: "date",
        headerName: "Date",
        sortable: true,
        comparator: this.queueService.dateComparator,
        sort: "desc",
        filter: true,
        resizable: true,
        cellRenderer: params => {
          return this.queueService.formatTimestamp(params.value);
        },
        width: 250
      },
      { field: "user", headerName: "User", sortable: true, filter: true, resizable: true, width: 150 },
      { field: "type", headerName: "Type", sortable: true, filter: true, resizable: true, width: 80 },
      { field: "note", headerName: "Note", sortable: true, filter: true, resizable: true, width: 400 },
      { field: "jobId", headerName: "Job ID", sortable: true, filter: true, resizable: true, width: 390, cellRenderer: 'jobIdCell'  }
    ];

    this.frameworkComponents = {
      jobIdCell: JobIdCellComponent
    };


  }

  ngOnInit(): void {
    this.details = this.idBundle.data;
  }

  resizeGrid(targetGrid: string) {
    if (targetGrid == 'processLogs') {
      if (this.actionHistoryGridOptions.api) {
        this.processLogsGridOptions.api.sizeColumnsToFit();
      }
    } else {
      if (this.processLogsGridOptions.api) {
        this.actionHistoryGridOptions.api.sizeColumnsToFit();
      }
    }
  }

}
