//Angular Imports
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

//Third Party Imports
import { AgGridModule } from "ag-grid-angular";
import { NgSelectModule } from "@ng-select/ng-select";
import { MatTableModule } from "@angular/material/table";

//Internal Imports
import { FrameworkModule, BooleanFilterComponent } from "../../framework";

import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { RulesComponent } from "./components/rules.component";
import { RulesRoutingModule } from "./rules.routing";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";

/**
  Forms Module
*/
@NgModule({
  declarations: [RulesComponent],
  imports: [
    RulesRoutingModule,
    CommonModule,
    FormsModule,
    NgSelectModule,
    FrameworkModule,
    AgGridModule.withComponents([BooleanFilterComponent]),
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatTableModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
  ],
  providers: [],
})
export class RulesModule {}
