import { Injectable } from "@angular/core";

@Injectable()
export class MockDataService {

  constructor() {}

  getPartnerHealthData(): any[] {
    return [
      {
        "partner": "SimplyBusiness",
        "partnerId": "SimplyBusiness",
        "jobs": [
          {
            "jobId": "FDF8C860-AB1E-4691-94B7-AA18B1A387B8",
            "jobDate": "2022-06-22T13:04:00.000Z",
            "totalTransactions": 100,
            "completedTransactions": 100,
            "unsuccessfulTransactions": 100
          },
          {
            "jobId": "FDF8C860-AB1E-4691-94B7-AA18B1A387B8",
            "jobDate": "2022-06-22T08:04:00.000Z",
            "totalTransactions": 100,
            "completedTransactions": 100,
            "unsuccessfulTransactions": 0
          },
          {
            "jobId": "FDF8C860-AB1E-4691-94B7-AA18B1A387B8",
            "jobDate": "2022-06-22T07:11:00.000Z",
            "totalTransactions": 100,
            "completedTransactions": 100,
            "unsuccessfulTransactions": 90
          },
          {
            "jobId": "FDF8C860-AB1E-4691-94B7-AA18B1A387B8",
            "jobDate": "2022-06-22T23:04:00.000Z",
            "totalTransactions": 200,
            "completedTransactions": 200,
            "unsuccessfulTransactions": 150
          },
          {
            "jobId": "FDF8C860-AB1E-4691-94B7-AA18B1A387B8",
            "jobDate": "2022-06-22T14:17:00.000Z",
            "totalTransactions": 200,
            "completedTransactions": 200,
            "unsuccessfulTransactions": 0
          },
          {
            "jobId": "FDF8C860-AB1E-4691-94B7-AA18B1A387B8",
            "jobDate": "2022-06-22T05:37:00.000Z",
            "totalTransactions": 300,
            "completedTransactions": 300,
            "unsuccessfulTransactions": 35
          },
          {
            "jobId": "FDF8C860-AB1E-4691-94B7-AA18B1A387B8",
            "jobDate": "2022-06-22T17:11:00.000Z",
            "totalTransactions": 300,
            "completedTransactions": 300,
            "unsuccessfulTransactions": 0
          },
          {
            "jobId": "FDF8C860-AB1E-4691-94B7-AA18B1A387B8",
            "jobDate": "2022-06-22T11:17:34.000Z",
            "totalTransactions": 300,
            "completedTransactions": 300,
            "unsuccessfulTransactions": 0
          },
          {
            "jobId": "9A6A3797-791C-4D90-B3B8-018796B08C62",
            "jobDate": "2022-05-31T04:23:34.000Z",
            "totalTransactions": 3,
            "completedTransactions": 3,
            "unsuccessfulTransactions": 3
          },
          {
            "jobId": "C1115A0E-7599-492C-9BFF-5616214C4D52",
            "jobDate": "2022-05-11T11:42:15.000Z",
            "totalTransactions": 3,
            "completedTransactions": 3,
            "unsuccessfulTransactions": 3
          }

        ]
      },

      {
        "partner": "Knott",
        "partnerId": "Knott",
        "jobs": [
          {
            "jobId": "077621024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-05-27T16:04:00.000Z",
            "totalTransactions": 100,
            "completedTransactions": 100,
            "unsuccessfulTransactions": 10
          },
          {
            "jobId": "1001024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-05-25T08:04:00.000Z",
            "totalTransactions": 100,
            "completedTransactions": 100,
            "unsuccessfulTransactions": 90
          },
          {
            "jobId": "2111024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-05-25T23:04:00.000Z",
            "totalTransactions": 0,
            "completedTransactions": 0,
            "unsuccessfulTransactions": 0
          },
          {
            "jobId": "3221024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-05-25T18:04:00.000Z",
            "totalTransactions": 200,
            "completedTransactions": 200,
            "unsuccessfulTransactions": 100
          },
          {
            "jobId": "4331024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-05-25T14:17:00.000Z",
            "totalTransactions": 200,
            "completedTransactions": 200,
            "unsuccessfulTransactions": 0
          },
          {
            "jobId": "5441024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-05-25T05:37:00.000Z",
            "totalTransactions": 300,
            "completedTransactions": 300,
            "unsuccessfulTransactions": 0
          },
          {
            "jobId": "6551024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-05-24T17:11:00.000Z",
            "totalTransactions": 300,
            "completedTransactions": 300,
            "unsuccessfulTransactions": 0
          },
          {
            "jobId": "7661024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-05-22T11:17:34.000Z",
            "totalTransactions": 300,
            "completedTransactions": 300,
            "unsuccessfulTransactions": 0
          },
          {
            "jobId": "8771024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-05-22T04:23:34.000Z",
            "totalTransactions": 300,
            "completedTransactions": 300,
            "unsuccessfulTransactions": 0
          },
          {
            "jobId": "9881024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-05-21T07:42:15.000Z",
            "totalTransactions": 300,
            "completedTransactions": 300,
            "unsuccessfulTransactions": 0
          }

        ]
      },


      {
        "partner": "Reel",
        "partnerId": "Reel",
        "jobs": [
          {
            "jobId": "077621024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-05-27T16:04:00.000Z",
            "totalTransactions": 100,
            "completedTransactions": 50,
            "unsuccessfulTransactions": 10
          },
          {
            "jobId": "1001024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-05-25T08:04:00.000Z",
            "totalTransactions": 100,
            "completedTransactions": 100,
            "unsuccessfulTransactions": 10
          },
          {
            "jobId": "2111024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-05-25T23:04:00.000Z",
            "totalTransactions": 100,
            "completedTransactions": 100,
            "unsuccessfulTransactions": 0
          },
          {
            "jobId": "3221024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-05-25T18:04:00.000Z",
            "totalTransactions": 200,
            "completedTransactions": 200,
            "unsuccessfulTransactions": 0
          },
          {
            "jobId": "4331024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-05-25T14:17:00.000Z",
            "totalTransactions": 200,
            "completedTransactions": 200,
            "unsuccessfulTransactions": 150
          },
          {
            "jobId": "5441024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-05-25T05:37:00.000Z",
            "totalTransactions": 300,
            "completedTransactions": 300,
            "unsuccessfulTransactions": 0
          },
          {
            "jobId": "6551024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-05-24T17:11:00.000Z",
            "totalTransactions": 300,
            "completedTransactions": 300,
            "unsuccessfulTransactions": 35
          },
          {
            "jobId": "7661024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-05-22T11:17:34.000Z",
            "totalTransactions": 300,
            "completedTransactions": 300,
            "unsuccessfulTransactions": 200
          },
          {
            "jobId": "8771024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-05-22T04:23:34.000Z",
            "totalTransactions": 300,
            "completedTransactions": 300,
            "unsuccessfulTransactions": 0
          },
          {
            "jobId": "9881024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-05-21T07:42:15.000Z",
            "totalTransactions": 300,
            "completedTransactions": 300,
            "unsuccessfulTransactions": 0
          }

        ]
      },

      {
        "partner": "Daytah",
        "partnerId": "Daytah",
        "jobs": [
          {
            "jobId": "077621024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-06-06T16:04:00.000Z",
            "totalTransactions": 100,
            "completedTransactions": 100,
            "unsuccessfulTransactions": 0
          },
          {
            "jobId": "1001024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-06-06T08:04:00.000Z",
            "totalTransactions": 100,
            "completedTransactions": 100,
            "unsuccessfulTransactions": 10
          },
          {
            "jobId": "2111024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-06-05T23:04:00.000Z",
            "totalTransactions": 100,
            "completedTransactions": 100,
            "unsuccessfulTransactions": 100
          },
          {
            "jobId": "3221024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-06-05T18:04:00.000Z",
            "totalTransactions": 200,
            "completedTransactions": 200,
            "unsuccessfulTransactions": 0
          },
          {
            "jobId": "4331024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-06-04T14:17:00.000Z",
            "totalTransactions": 200,
            "completedTransactions": 200,
            "unsuccessfulTransactions": 150
          },
          {
            "jobId": "5441024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-06-04T05:37:00.000Z",
            "totalTransactions": 300,
            "completedTransactions": 300,
            "unsuccessfulTransactions": 35
          },
          {
            "jobId": "6551024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-06-03T17:11:00.000Z",
            "totalTransactions": 300,
            "completedTransactions": 300,
            "unsuccessfulTransactions": 35
          },
          {
            "jobId": "7661024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-06-03T11:17:34.000Z",
            "totalTransactions": 300,
            "completedTransactions": 300,
            "unsuccessfulTransactions": 0
          },
          {
            "jobId": "8771024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-06-02T04:23:34.000Z",
            "totalTransactions": 300,
            "completedTransactions": 300,
            "unsuccessfulTransactions": 100
          },
          {
            "jobId": "9881024-1595-4738-9243-2184ED1E8154",
            "jobDate": "2022-06-01T07:42:15.000Z",
            "totalTransactions": 300,
            "completedTransactions": 300,
            "unsuccessfulTransactions": 65
          }

        ]
      }


    ]
  }



  getChartData(): any {
    return {
      ptpmData: this.getPolicyTransactionsPerMonth(),
      ctpmData: this.getClaimTransactionsPerMonth(),
      pppData: this.getPremiumPerPartner()
    }
  }

  getPolicyTransactionsPerMonth(): any[] {
    return [
      {
        partner: "SimplyBusiness",
        transactions: [
          { month: "Jan", count: 100 },
          { month: "Feb", count: 210 },
          { month: "Mar", count: 300 },
          { month: "Apr", count: 500 },
          { month: "May", count: 430 },
          { month: "Jun", count: 420 },
          { month: "Jul", count: 200 },
          { month: "Aug", count: 510 },
          { month: "Sep", count: 600 },
          { month: "Oct", count: 550 },
          { month: "Nov", count: 700 },
          { month: "Dec", count: 710 }
        ]
      },
      {
        partner: "Knott",
        transactions: [
          { month: "Mar", count: 230 },
          { month: "Apr", count: 300 },
          { month: "May", count: 330 },
          { month: "Jun", count: 520 },
          { month: "Jul", count: 400 },
          { month: "Aug", count: 510 },
          { month: "Sep", count: 630 },
          { month: "Oct", count: 350 },
          { month: "Nov", count: 800 },
          { month: "Dec", count: 410 }
        ]
      },
      {
        partner: "Reel",
        transactions: [
          { month: "Jan", count: 80 },
          { month: "Feb", count: 240 },
          { month: "Mar", count: 340 },
          { month: "Apr", count: 400 },
          { month: "May", count: 440 },
          { month: "Jun", count: 470 },
          { month: "Jul", count: 260 },
          { month: "Aug", count: 530 },
          { month: "Sep", count: 620 },
          { month: "Oct", count: 350 },
          { month: "Nov", count: 530 },
          { month: "Dec", count: 750 }
        ]
      },
      {
        partner: "Daytah",
        transactions: [
          { month: "May", count: 30 },
          { month: "Jun", count: 90 },
          { month: "Jul", count: 140 },
          { month: "Aug", count: 230 },
          { month: "Sep", count: 100 },
          { month: "Oct", count: 95 },
          { month: "Nov", count: 170 },
          { month: "Dec", count: 300 }
        ]
      }
     ]
  }

  getClaimTransactionsPerMonth(): any[] {
    return [
      {
        partner: "SimplyBusiness",
        transactions: [
          { month: "Jan", count: 15 },
          { month: "Feb", count: 30 },
          { month: "Mar", count: 20 },
          { month: "Apr", count: 50 },
          { month: "May", count: 70 },
          { month: "Jun", count: 40 },
          { month: "Jul", count: 10 },
          { month: "Aug", count: 51 },
          { month: "Sep", count: 60 },
          { month: "Oct", count: 15 },
          { month: "Nov", count: 20 },
          { month: "Dec", count: 90 }
        ]
      },
      {
        partner: "Knott",
        transactions: [
          { month: "Mar", count: 20 },
          { month: "Apr", count: 60 },
          { month: "May", count: 30 },
          { month: "Jun", count: 25 },
          { month: "Jul", count: 75 },
          { month: "Aug", count: 100 },
          { month: "Sep", count: 85 },
          { month: "Oct", count: 130 },
          { month: "Nov", count: 40 },
          { month: "Dec", count: 50 }
        ]
      },
      {
        partner: "Reel",
        transactions: [
          { month: "Jan", count: 50 },
          { month: "Feb", count: 25 },
          { month: "Mar", count: 90 },
          { month: "Apr", count: 40 },
          { month: "May", count: 60 },
          { month: "Jun", count: 110 },
          { month: "Jul", count: 50 },
          { month: "Aug", count: 190 },
          { month: "Sep", count: 200 },
          { month: "Oct", count: 80 },
          { month: "Nov", count: 150 },
          { month: "Dec", count: 180 }
        ]
      },
      {
        partner: "Daytah",
        transactions: [
          { month: "May", count: 10 },
          { month: "Jun", count: 40 },
          { month: "Jul", count: 15 },
          { month: "Aug", count: 25 },
          { month: "Sep", count: 75 },
          { month: "Oct", count: 35 },
          { month: "Nov", count: 100 },
          { month: "Dec", count: 20 }
        ]
      }
    ]
  }

  getPremiumPerPartner(): any[] {
    return [
      {
        partner: "SimplyBusiness",
        transactions: [
          { month: "Jan", count: 40 },
          { month: "Feb", count: 110 },
          { month: "Mar", count: 170 },
          { month: "Apr", count: 230 },
          { month: "May", count: 330 },
          { month: "Jun", count: 420 },
          { month: "Jul", count: 460 },
          { month: "Aug", count: 510 },
          { month: "Sep", count: 590 },
          { month: "Oct", count: 650 },
          { month: "Nov", count: 700 },
          { month: "Dec", count: 890 }
        ]
      },
      {
        partner: "Knott",
        transactions: [
          { month: "Mar", count: 130 },
          { month: "Apr", count: 300 },
          { month: "May", count: 330 },
          { month: "Jun", count: 520 },
          { month: "Jul", count: 600 },
          { month: "Aug", count: 710 },
          { month: "Sep", count: 780 },
          { month: "Oct", count: 850 },
          { month: "Nov", count: 900 },
          { month: "Dec", count: 1020 }
        ]
      },
      {
        partner: "Reel",
        transactions: [
          { month: "Jan", count: 80 },
          { month: "Feb", count: 240 },
          { month: "Mar", count: 340 },
          { month: "Apr", count: 400 },
          { month: "May", count: 440 },
          { month: "Jun", count: 470 },
          { month: "Jul", count: 560 },
          { month: "Aug", count: 630 },
          { month: "Sep", count: 720 },
          { month: "Oct", count: 850 },
          { month: "Nov", count: 930 },
          { month: "Dec", count: 1150 }
        ]
      },
      {
        partner: "Daytah",
        transactions: [
          { month: "May", count: 30 },
          { month: "Jun", count: 190 },
          { month: "Jul", count: 340 },
          { month: "Aug", count: 430 },
          { month: "Sep", count: 600 },
          { month: "Oct", count: 795 },
          { month: "Nov", count: 870 },
          { month: "Dec", count: 920 }
        ]
      }
     ]
  }
}
