import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ImportQueueService } from "../../services/import-queue.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss']
})
export class UploadFilesComponent implements OnInit {

  uploadTitle: string;
  isLoading: boolean = true;
  isReady: boolean = false;
  inProgress: boolean = false;
  validationErrors: string[] = [];
  uploadErrorMsgs: string[] = [];
  uploadSuccessMsg: string = '';
  hasUploadFailures: false;
  uploadOptions: any;
  selectedFile: File;
  uploadPayload: FormData;
  selectedFilename: string = '';
  readyToUpload: boolean = false;
  uploadInProgress: boolean = false;
  uploadSuccess: boolean = false;

  //Job/staging file file upload variables
  currentDestinationOptions: string[] = ['Staging', 'Job Queue'];
  currentProductOptions: any[] = [];
  currentAllowedFilenames: string[] = [];
  currentAllowedFileExtensions: string[] = [];
  extensionList: string = "";

  subs: Subscription[] = [];

  uploadJobFileForm = new FormGroup({
    destination: new FormControl<string>('', [Validators.required]),
    partner: new FormControl<string>('', [Validators.required]),
    product: new FormControl<string>('', [Validators.required]),
  });

  @Input() uploadContext: string;
  @ViewChild('uploadFilesModal') uploadFilesModal: any;

  constructor(private queueService: ImportQueueService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.startListeners();
  }

  startListeners(): void {
    let partnerChangeSub = this.uploadJobFileForm.get("partner").valueChanges.subscribe(val => {
      this.updateCurrentProductOptions(val);
    });
    let productChangeSub = this.uploadJobFileForm.get("product").valueChanges.subscribe(val => {
      this.updateCurrentAllowedFilenames(val);
      this.updateCurrentAllowedFileExtensions(val);
    });
    this.subs.push(partnerChangeSub);
    this.subs.push(productChangeSub);
  }

  ngOnDestroy(): void {
    this.subs.forEach((s: Subscription) => {
      s.unsubscribe();
    });
  }

  openModal(uploadContext: string): void {
    this.uploadContext = uploadContext;
    this.uploadTitle = "Upload File";   
    this.performReset(uploadContext);
    this.modalService.open(this.uploadFilesModal, { backdrop: 'static', size: 'lg' });
    this.populateUploadOptions(uploadContext);
  }

  performReset(uploadContext: string) {
    this.inProgress = false;
    this.uploadErrorMsgs = [];
    this.validationErrors = [];
    this.readyToUpload = false;
    this.uploadInProgress = false;
    this.uploadSuccess = false;
    if (uploadContext == "Job" || uploadContext == "Staging") {
      if (uploadContext == "Job") {
        this.uploadJobFileForm.controls.destination.setValue("Job Queue");
      } else {
        this.uploadJobFileForm.controls.destination.setValue("Staging");
      }
      this.uploadJobFileForm.controls.partner.reset('');
      this.updateCurrentProductOptions('');
      this.uploadJobFileForm.controls.product.reset('');
      this.currentProductOptions = [];
      this.currentAllowedFilenames = [];
    }

  }

  dismissModal(): void {
    this.hasUploadFailures = false;
    this.modalService.dismissAll();
  }

  retryPopulateOptions(uploadContext: string): void {
    event.preventDefault();
    this.isLoading = true;
    this.isReady = false;
    this.populateUploadOptions(uploadContext);
  }

  populateUploadOptions(uploadContext: string): void {
    if (uploadContext == 'Job' || uploadContext == 'Staging') {
      this.queueService.getJobUploadOptions()
        .subscribe(
          // Handle successful response with filter option data
          uploadOptions => {
            this.uploadOptions = uploadOptions;
            this.isReady = true;
            this.isLoading = false;
          },
          // Handle an error response
          error => {
            this.isReady = false;
            this.isLoading = false;
          }
        );
    }
    
  }

  clearFileSelectionData(): void {
    this.selectedFilename = '';
    this.uploadErrorMsgs = [];
    this.validationErrors = [];
    this.uploadSuccess = false;
    this.uploadInProgress = false;
    this.readyToUpload = false;
  }

  onFileSelected(event: any) {
    
    if (this.uploadContext == 'Job' || this.uploadContext == 'Staging') {
      this.selectedFile = event.target.files[0];
      this.validateSelectedFile(this.selectedFile);
      if (!this.showErrors()) {
        this.readyToUpload = true;
      }
    }
  }

  performUpload(): void {
    this.uploadErrorMsgs = [];
    this.uploadInProgress = true;
    this.uploadPayload = new FormData();
    if (this.uploadContext == 'Job' || this.uploadContext == 'Staging') {
      this.uploadPayload.append("file", this.selectedFile);
      this.uploadPayload.append("filename", this.selectedFilename);
      this.uploadPayload.append("partner", this.uploadJobFileForm.controls.partner.value);
      this.uploadPayload.append("product", this.uploadJobFileForm.controls.product.value);
      // As part of a refactor of the upload API, we add the "uploadToStage" property to send the file to staging
      if (this.uploadJobFileForm.controls.destination.value == 'Staging') {
        this.uploadPayload.append("uploadToStage", "true");
      }

      this.queueService.uploadJobFile(this.uploadPayload)
        .subscribe(
          // Handle success response
          uploadResponse => {
            this.uploadSuccessMsg = uploadResponse.message ? uploadResponse.message : "Upload successful.";
            this.uploadSuccess = true;
            this.uploadInProgress = false;
          },
          // Handle error response
          err => { 
            this.uploadSuccess = false;
            if (err.error && err.error.errors) {
              for (let e = 0; e < err.error.errors.length; e++) {
                this.uploadErrorMsgs.push(err.error.errors[e].message ? err.error.errors[e].message : "An unspecified error occurred.");
              }
            } else {
              this.uploadErrorMsgs.push("An error occurred during the upload process. Please try again.");
            }
            this.uploadInProgress = false;
          }
        );

    }
  }

  showErrors(): boolean {
    return this.uploadErrorMsgs.length > 0 || this.validationErrors.length > 0;
  }

  //Job upload-specific functions
  updateCurrentProductOptions(partner: string): void {
    this.uploadJobFileForm.controls.product.reset('');
    this.selectedFilename = '';
    this.uploadErrorMsgs = [];
    this.validationErrors = [];
    if (partner) {
      this.currentProductOptions = this.uploadOptions[partner].products;
    } else {
      this.currentProductOptions = [];
    }
    // Set option if only one
    if (this.currentProductOptions.length == 1) {
      this.uploadJobFileForm.controls.product.setValue(this.currentProductOptions[0].productId);
    }
  }

  updateCurrentAllowedFilenames(product: string): void {
    this.selectedFilename = '';
    this.uploadErrorMsgs = [];
    this.validationErrors = [];
    if (product) {
      let partner = this.uploadJobFileForm.controls.partner.value;
      this.currentAllowedFilenames = this.uploadOptions[partner][product].filenames;
    } else {
      this.currentAllowedFilenames = [];
    }
  }

  updateCurrentAllowedFileExtensions(product: string): void {
    this.uploadErrorMsgs = [];
    this.validationErrors = [];
    if (product) {
      let partner = this.uploadJobFileForm.controls.partner.value;
      this.currentAllowedFileExtensions = [ ...this.uploadOptions[partner][product].fileExtensions ];
      this.currentAllowedFileExtensions.push(".zip");
    } else {
      this.currentAllowedFileExtensions = [".zip"];     
    }
    
    this.extensionList = this.currentAllowedFileExtensions.join(", ");
  }

  validateSelectedFile(selectedFile: any): void {
    this.selectedFilename = selectedFile.name;
    let nameFragments = this.selectedFilename.split('.');
    let fileExtension = "." + nameFragments[(nameFragments.length - 1)];
    if (this.currentAllowedFileExtensions.indexOf(fileExtension) == -1) {
      
      this.validationErrors.push("The file must have one of the following file extensions: " + this.extensionList);
    }
    if (this.currentAllowedFilenames.length) { // Some partner files don't have filename rules
      let filenamePrefixValid = false;
      for (let fp = 0; fp < this.currentAllowedFilenames.length; fp++) {
        if (this.selectedFilename.startsWith(this.currentAllowedFilenames[fp])) {
          filenamePrefixValid = true;
        }
      }
      if (!filenamePrefixValid) {
        this.validationErrors.push("The filename must begin with one of the prefixes listed below.");
      }
    }
  }

}
