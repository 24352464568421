export class ReportExpression {

  public ElementName: string;
  public Operator: string;
  public IncludedList: string[];
  public MandatoryList: string[];
  public IncludedDescriptions: string[];
  public MandatoryDescriptions: string[];

}
