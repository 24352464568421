//Angular Imports 
import { HttpClient, HttpContext, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { saveAs } from "file-saver";
import { map, Observable } from "rxjs";
import { BYPASS_URL_APPEND } from "../../../framework";
import { PDFConvertDocs } from "../models/pdfConvertDocs";

/**
  * Policy Forms Provider
*/
@Injectable()
export class ConvertService {
  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient) {
  }

  //calls api that does the comparision
  convertDocs(files: File[]): Observable<any> {
    const formData: FormData = new FormData();
    for (var file = 0; file < files.length; file++) {
      formData.append(files[file].name, files[file]);
    }
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/zip');
    var cleanUrl = 'uploadForm';
    return this.http.post<any>(cleanUrl, formData, {headers: ( headers )}).pipe(map(res => res));
  }
}
