
<p id="request-button">
  <button mat-raised-button class="portal-button" (click)="executeFilter()">Get Matching Queue Data</button>
</p>

<mat-accordion class="filter-options" multi>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Date Range
      </mat-panel-title>
      <mat-panel-description>(Required)</mat-panel-description>
    </mat-expansion-panel-header>

    <mat-form-field appearance="fill">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [formGroup]="filterInputs" [rangePicker]="picker">
        <input matStartDate formControlName="startDate" placeholder="Start date">
        <input matEndDate formControlName="endDate" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Request UUID
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-form-field class="uuid-text-field" appearance="fill" [formGroup]="filterInputs">
      <mat-label>UUID:</mat-label>
      <input matInput id="jobId-input" formControlName="uuid">
    </mat-form-field>
    </mat-expansion-panel>

  <mat-expansion-panel [expanded]="statusList.length" [disabled]="!statusList.length">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Status
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ul>
      <li *ngFor="let status of statusList">
        <mat-checkbox [(ngModel)]="status.selected" color="primary">{{status.name}}</mat-checkbox>
      </li>
    </ul>

    <app-checkbox-group-controls [checkboxArray]="statusList" class="checkbox-controls"></app-checkbox-group-controls>
  </mat-expansion-panel>

  <mat-expansion-panel [expanded]="sourceSystemList.length" [disabled]="!sourceSystemList.length">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Source System
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ul>
      <li *ngFor="let sourcesystem of sourceSystemList">
        <mat-checkbox [(ngModel)]="sourcesystem.selected" color="primary">{{sourcesystem.name}}</mat-checkbox>
      </li>
    </ul>

    <app-checkbox-group-controls [checkboxArray]="sourceSystemList" class="checkbox-controls"></app-checkbox-group-controls>

  </mat-expansion-panel>

</mat-accordion>

