//Angular Imports 
import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';

//Third Party Imports
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

//Internal Imports 
import { SelectableItem } from '../../../../../framework/models/selectable-item.model';
import { UserProfileService } from '../../../../../framework';
import { TestingService } from '../../services';
import { RuleEngineResult, RuleEngineLog, TestExpectedResults } from '../../models';
import { coverageCpmElementId, lobCpmElementId, TEST_GROUP_TYPES } from '../../configs/execution-engine.config';

import 'rxjs';
import { TestCase, TestGroup } from '../../models';
import { TestCaseExpectedResult } from '../test-case-expected-result';


/**
 * Forms Component
*/
@Component({
  selector: 'app-test-group-detail',
  templateUrl: './test-group-detail.component.html',
  styleUrls: ['./test-group-detail.component.scss']
})

export class TestGroupDetail {

  // Specifies the action type the screen was opened for (Add, Edit)
  @Input() public actionType: string;

  // The test group passed into the component when editing an existing component
  @Input() public testGroup: TestGroup;

  // Used for the Test Group Type dropdown
  public typeList: any[];
  public selectedType: string;

  // Used to populate the Line of Business grid
  lobGridOptions: GridOptions;
  lobSelectableItems: SelectableItem[];

  // Set to true when user clicks the Save button so validation messages will show
  public formSubmitted: boolean

  /** 
   * Constructor
   * @ignore
  */
  constructor(public activeModal: NgbActiveModal,
    private testingService: TestingService,
    private userProfileService: UserProfileService,
    private _modal: NgbModal,
    private toastr: ToastrService) {

    this.lobSelectableItems = [];
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
  */
  ngOnInit(): void {
    this.configureLobGrid();

    if (this.actionType === "Add") {
      this.testGroup = new TestGroup();
      this.testGroup.LOBs = [];
    } else if (this.actionType === "Edit") {
      this.selectedType = this.testGroup.TestGroupType;
    } else {
      this.toastr.error("Invalid actionType: " + this.actionType);
    }

    this.typeList = TEST_GROUP_TYPES;
    this.createSelectableItemLists();
  }

  /**
   * NgOnDestroy
   * @ignore
  */
  ngOnDestroy(): void {

  }

  public saveTestGroup() {
    this.formSubmitted = true;
    this.testGroup.TestGroupType = this.selectedType;

    if (this.validateForm()) {
      if (this.actionType == "Add") {
        // Add a new TestGroup
        this.testGroup.DocumentType = "TestGroup";
        this.testGroup.CreatedByName = this.userProfileService.UserProfile.UserName;
        this.testGroup.Id = "00000000-0000-0000-0000-000000000000";
        this.testGroup.CreationDate = moment().unix();
        this.saveSelectableItems();

        this.testingService.addTestGroup(this.testGroup).subscribe(res => {
          this.testGroup = res;

          // Close the popup after saving
          this.activeModal.close(this.testGroup);
          this.toastr.success("Test Group saved successfully.");
        },
          err => {
            this.toastr.error("Error while saving Test Group.");
          });
      } else {
        // Update an existing TestCase
        this.testGroup.ModifiedDate = moment().unix();
        this.testGroup.ModifiedByName = this.userProfileService.UserName;
        this.saveSelectableItems();

        this.testingService.updateTestGroup(this.testGroup).subscribe(res => {
          this.testGroup = res;

          // Close the popup after saving
          this.activeModal.close(this.testGroup);
          this.toastr.success("Test Group saved successfully.");
        },
          err => {
            this.toastr.error("Error while saving Test Group.");
          });
      }
    } else {
      this.toastr.error("Fix validation errors.");
    }  
  }

  private validateForm(): boolean {
    if (!this.testGroup.Name) {
      return false;
    }
    if (!this.testGroup.TestGroupType) {
      return false;
    }

    return true;
  }

  private saveSelectableItems() {
    let lobs: string[] = [];
    for (var lob of this.lobSelectableItems) {
      if (lob.Selected) {
        lobs.push(lob.Code);
      }
    }
    this.testGroup.LOBs = lobs;
  }



  private createSelectableItemLists(): void {
    // Load LOBs
    this.testingService.getSelectableCpmElementList(lobCpmElementId).subscribe(res => {
      // foreach lob in test group, set selected item to selected
      for (var lob of this.testGroup.LOBs) {
        var item = res.find(x => x.Code === lob);
        if (item) {
          item.Selected = true;
        }
      }

      this.lobSelectableItems = res;
      this.sortSelectableItems(this.lobSelectableItems);
      this.lobGridOptions.api.setRowData(this.lobSelectableItems);
      this.lobGridOptions.columnApi.autoSizeAllColumns();
    });

  }

  private sortSelectableItems(items: SelectableItem[]) {
    items.sort((a, b) => {
      if (a.Selected == b.Selected) {
        return 0;
      }
      else if (a.Selected) {
        return -1;
      }
      else
        return 1;
    });
  }

  private configureLobGrid(): void {
    //this.initialRowDataLoad$ = [];
    this.lobGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: 'normal',
      columnDefs: this.createLobColumnDef(),
      enableFilter: true,
      enableSorting: true,
      rowHeight: 30,
      enableColResize: true,
      onGridReady: () => {
        this.lobGridOptions.api.setRowData(this.lobSelectableItems);
        //this.summaryGridOptions.api.sizeColumnsToFit();
        this.lobGridOptions.columnApi.autoSizeAllColumns();
      },
      onRowClicked: (event) => {
        this.onRowSelected(event);
        this.lobGridOptions.api.refreshCells();
      },
      onFilterChanged: (event) => {
      },
      onSortChanged: (event) => {
      },
      onFilterModified: function (event) {
        //console.log(event);
      }
    };
  }

  /**
  * configure forms grid columns
  */
  private createLobColumnDef(): any[] {
    return [
      {
        headerName: '', field: 'Selected', maxWidth: 40, suppressFilter: true, cellRenderer: params => {
          return `<input type='checkbox' ${params.value == true ? 'checked' : ''} />`;
        }
      },
      { headerName: 'LOB', field: 'VisibleDescription', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },

    ]
  }

  private onRowSelected(event: any): void {
    //event.data.Selected = event.node.selected;

    if (event.node.selected) {
      if (event.data.Selected) {
        event.data.Selected = false;
      } else {
        event.data.Selected = true;
      }
    }
  }
}
