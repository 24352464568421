//Angular Imports 
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

//Third Party Imports
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

//Internal Imports
import { DeployPackage } from '../../models/deployPackage.model';
import { DeployService } from '../../services';
import { UserProfileService } from '../../../framework';


/**
 * copy Confirmation Component
*/
@Component({
  selector: 'copy-confirmation',
  templateUrl: './copy-confirmation.component.html',
  styleUrls: ['./copy-confirmation.component.scss']
})
export class CopyConfirmationComponent implements OnInit {

  //Public Variables
  /**
   * formGroup
   */
  formGroup: UntypedFormGroup;

  /**
   * packageToBeCopied
   */
  packageToBeCopied: DeployPackage;

  action: string = "Copy";

  /**
   * Constructor
   * @ignore
  */
  constructor(public activeModal: NgbActiveModal, private fb: UntypedFormBuilder, private deployService: DeployService
    , private toastr: ToastrService, private userProfileService: UserProfileService) {

  }

  //Angular Lifecycles
  /**
   * On Init
   * @ignore
  */
  ngOnInit() {
    this.createFormBuilder();
    this.fillFormBuilder();
  }

  // Public Methods
  /**
   * To deploy a package
   * */
  public copyPackage(): void {
    Object.keys(this.formGroup.controls).forEach(key => {
      this.formGroup.get(key).markAsDirty();
    });

    let packageName: string;
    if (this.formGroup.valid) {

      this.packageToBeCopied.DeployedBy = this.formGroup.controls["copiedBy"].value;
      this.packageToBeCopied.DeployedDate = moment().unix();
      this.packageToBeCopied.PackageName = this.formGroup.controls["targetPackageName"].value;
      this.packageToBeCopied.Release = this.formGroup.controls["releaseTag"].value;

      if (this.action == "Update") {
        this.deployService.updateDeployPackages(this.packageToBeCopied).subscribe(result => {
        if (result == null) {
          this.toastr.error('Error occurred while adding data.', "Package Name " + this.packageToBeCopied.PackageName);
        }
        else {
          this.toastr.success('Deploy Packages Data Saved Successfully.', "Package Name- " + this.packageToBeCopied.PackageName);
        }

        this.activeModal.close(result);
        });
      }
      else {
        this.deployService.adminCopyDeployPackages(this.packageToBeCopied).subscribe(result => {
        if (result == null) {
          this.toastr.error('Error occurred while adding data.', "Package Name " + this.packageToBeCopied.PackageName);
        }
        else {
          this.toastr.success('Deploy Packages Data Saved Successfully.', "Package Name- " + this.packageToBeCopied.PackageName);
        }

        this.activeModal.close(result);
        });
      }
      
    }
  }



  // Private methods
  /**
   * To create form with form builder
   * */
  private createFormBuilder(): void {
    this.formGroup = this.fb.group({
      packageName: [{ value: '' }, [Validators.required]],
      targetPackageName: [{ value: '' }, [Validators.required]],
      releaseTag:[{value: ''}],
      copiedBy: [{ value: '' }, [Validators.required]],
      copyDate: [{ value: '' }]

    });
  }

  /**
   * Set initial values of form elements
   * */
  private fillFormBuilder(): void {
    let todayDate = new Date();
    let date = moment({ year: todayDate.getFullYear(), month: todayDate.getMonth(), day: todayDate.getDate() });
    let unixValue = date.unix();
    let packageNames = this.packageToBeCopied.PackageName;


    this.formGroup.patchValue({
      packageName: packageNames,
      targetPackageName: packageNames,
      releaseTag: 'N/A',
      copiedBy: this.userProfileService.UserProfile.UserName,
      copyDate: unixValue,
    });
  }

}
