import { Component, OnInit } from "@angular/core";
import { PopoverRef } from "../popover/popover-ref";

export interface MegaMenuFeature {
  icon: string;
  label: string;
  route: string;
}

export interface MegaMenuPage {
  label: string;
  route: string;
  icon: string;
  set?: string;
}

@Component({
  selector: "vex-mega-menu",
  templateUrl: "./mega-menu.component.html",
  styleUrls: [`mega-menu.component.scss`],
})
export class MegaMenuComponent implements OnInit {
  attachPages: MegaMenuPage[] = [
    {
      label: "Form Builder",
      route: "/fast/formBuilder",
      // icon: "mat:description",
      icon: "Paper_Pen_IconWO.png",
      set: "mat",
    },
    {
      label: "LOB Form View",
      route: "/fast/forms",
      // icon: "mat:visibility",
      icon: "Paper_Bar Chart_Stats_IconWO.png",
      set: "mat",
    },
    {
      label: "Print Order Setup",
      route: "/fast/printOrder",
      // icon: "mat:print",
      icon: "Paper_Shield_Safety_Verified_Check_IconWO.png",
      set: "mat",
    },
    {
      label: "CPM Elements",
      route: "/fast/cpm",
      // icon: "mat:rate_review",
      icon: "Building Blocks_Children_Toys_Kids_IconWO.png",
      set: "mat",
    },
    {
      label: "Create Packages",
      route: "/fast/package",
      // icon: "mat:create_new_folder",
      icon: "Digital_Computer_Code_Gear_IconWO.png",
      set: "mat",
    },
    {
      label: "Deploy Packages",
      route: "/fast/deploy",
      // icon: "mat:cloud_upload",
      icon: "Digital_Computer_Globe_MyMarkel_IconWO.png",
      set: "mat",
    },
    {
      label: "Test Suite",
      route: "/fast/execution",
      // icon: "mat:assignment_late",
      icon: "Paper_Risk_Solution_Checkmark_Shield_IconWO.png",
      set: "mat",
    },
    {
      label: "Form Reports",
      route: "/fast/report",
      // icon: "mat:receipt",
      icon: "Paper_Checkmark_Circle_IconWO.png",
      set: "mat",
    },
    {
      label: "Product Definitions",
      route: "/fast/products",
      // icon: "mat:ballot",
      icon: "Digital_Website_Webpage_Computer_Code_Edit_Pencil_IconWO.png",
      set: "mat",
    },
  ];

  genPages: MegaMenuPage[] = [
    {
      label: "Form Library",
      route: "/ghostPortal/forms",
      // icon: "mat:library_books",
      icon: "Paper_3 pages_Stack_IconWO.png",
      set: "mat",
    },
    {
      label: "Test Harness",
      route: "/ghostPortal/testHarness",
      // icon: "mat:assignment",
      icon: "Insurance_Healthcare_Clipboard_paper_cross_IconWO.png",
      set: "mat",
    },
    {
      label: "Deploy Utility",
      route: "/ghostPortal/deployUtility",
      // icon: "mat:pageview",
      icon: "Digital_Globe_World_Code_IconWO.png",
      set: "mat",
    },
    // { no longer in use
    //   label: "ImageRight Rules",
    //   route: "/ghostPortal/imageRight",
    //   // icon: "mat:image",
    //   icon: "Fine Arts_Painting_Frame_Art_IconWO.png",
    //   set: "mat",
    // }, 
    {
      label: "Folder Rules",
      route: "/ghostPortal/rules",
      // icon: "mat:image_aspect_ratio",
      icon: "Folder_Checkmark_IconWO.png",
      set: "mat",
    },
    {
      label: "Email Settings",
      route: "/ghostPortal/email",
      // icon: "mat:settings_applications",
      icon: "Gear_shield_checkmark_IconWO.png",
      set: "mat",
    },
    {
      label: "Document Queue",
      route: "/ghostPortal/queue",
      // icon: "mat:featured_play_list",
      icon: "Book_Stack_Shelf1_IconWO.png",
      set: "mat",
    },
    {
      label: "Compare Documents",
      route: "/ghostPortal/compare",
      // icon: "mat:compare_arrows",
      icon: "Money_Transfer_Cash_USD_IconWO.png",
      set: "mat",
    },
    {
      label: "Reports (Power BI)",
      route: "/ghostPortal/reports",
      // icon: "mat:list_alt",
      icon: "Paper_Shield_Page_IconWO.png",
      set: "mat",
    },
    {
      label: "Convert Documents",
      route: "/ghostPortal/convert",
      // icon: "mat:list_alt",
      icon: "Chat_Conversation1_IconWO.png",
      set: "mat",
    }
  ];

  mDataPages: MegaMenuPage[] = [
    {
      label: "Insured Identity Mgmt",
      route: "/data-management/insured-identity",
      // icon: "mat:featured_play_list",
      icon: "Insured_Person_Circle_IconWO.png",
      set: "mat",
    },
    {
      label: "Import Queue",
      route: "/mdo/import-queue",
      // icon: "mat:import_export",
      icon: "Construction_Architecture_Plan_Pencil_Paper_IconWO.png",
      set: "mat",
    },
    {
      label: "AMS Data Mgmt",
      route: "/data-management/ams",
      // icon: "mat:import_export",
      icon: "Person_People_Group5_Standing_Gear_IconWO.png",
      set: "mat",
    },
    {
      label: "Oden Logs",
      route: "/data-management/oden",
      // icon: "mat:import_export",
      icon: "Book_Stack_Shelf2_IconWO.png",
      set: "mat",
    }

  ];

  constructor(private popoverRef: PopoverRef<MegaMenuComponent>) { }

  ngOnInit() { }

  close() {
    this.popoverRef.close();
  }
}
