<div class="modal-header">
  <h4 class="modal-title">Selected Log Error</h4>
</div>

<div class="modal-body col-12" style="max-height: 500px; overflow-y: scroll">
  <div
    class="row"
    style="word-break: break-word; font-family: var(--font-vex-form)"
  >
    {{ this.errorText }}
  </div>
</div>
<div class="modal-footer">
  <!--<button
    mat-raised-button
    color="primary"
    style="background-color: var(--color-red)"
    (click)="this.triggerRetry()"
  >
    Retry
  </button>-->
  <button
    mat-raised-button
    class="mat_btn_hov_act mrglt10"
    (click)="this.copyErrorText()"
  >
    Copy to Clipboard
  </button>
  <button
    mat-raised-button
    class="mat_btn_hov_act mrglt10"
    (click)="activeModal.close()"
  >
    Close
  </button>
</div>
