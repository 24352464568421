//Angular Imports
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

//Third Party Imports
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { AgGridModule } from "ag-grid-angular";
import { MyDatePickerModule } from "mydatepicker";
//Internal Imports
import { PrintOrderComponent, AddPrintOrderComponent } from "./components";
import { PrintOrderRoutingModule } from "./print-order.routing";
import { PrintOrderService } from "./services";
import {
  FrameworkModule,
  DateFilterComponent,
  BooleanFilterComponent,
} from "../framework";
import { UserNotesModule } from "../user-notes/user-notes.module";
import { MatButtonModule } from "@angular/material/button";

/**
  Forms Module
*/
@NgModule({
  declarations: [PrintOrderComponent, AddPrintOrderComponent],
  imports: [
    MyDatePickerModule,
    CommonModule,
    PrintOrderRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    FrameworkModule,
    UserNotesModule,
    MatButtonModule,
    AgGridModule.withComponents([DateFilterComponent, BooleanFilterComponent]),
  ],
  providers: [PrintOrderService],
})
export class PrintOrderModule {}
