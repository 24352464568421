//Angular Imports
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

//RxJS Imports
import { Observable, of } from 'rxjs';

//Internal Imports
import { EnvironmentService, SharedService } from '../services';
import { EnvironmentSpecifics } from '../models';

/**
  * Environment Resolver
  * Resolves the request for environment.json
*/
@Injectable()

export class EnvironmentResolver implements Resolve<EnvironmentSpecifics> {

  /**
   * Constructor
   * @ignore
  */
  constructor(private _environmentService: EnvironmentService, private _sharedService: SharedService) { }

  /**
   * Resolves the request for environment.json
   * @returns {Observable<EnvironmentSpecifics>} EnvironmentSpecifics Observable
  */
  resolve(): Observable<EnvironmentSpecifics> {

    //Check if already has environment details else get throught http 
    if (this._environmentService.environmentDetails) {
      return of(this._environmentService.environmentDetails);
    }
    else {
      return this._environmentService.loadEnvironment();
    }
  }
}
