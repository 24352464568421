<div class="row">
  <div class="col-md-9">
    <h2 class="dash-item-title">{{chart.title}}</h2>
  </div>
  <div class="col-md-3">
    <div *ngIf="isLoadingChartingData">
      <mat-progress-spinner mode="indeterminate" diameter="30"></mat-progress-spinner>
    </div>
  </div>
</div>

<div *ngIf="!isLoadingChartingData && showChartingError">
  <p class="alert mdo-alert-danger">Could not load chart data, please <a class="mdo-alert-danger-link" href="" (click)="fixChartData()">click here</a> to try again.</p>
</div>

<div *ngIf="!isLoadingChartingData && chart.hasError">
  <p class="alert mdo-alert-danger">{{chart.errorMessage}}</p>
</div>

<div *ngIf="!isLoadingChartingData && !showChartingError && !chart.hasError">
  <ag-charts-angular [options]="chart.instructions"></ag-charts-angular>
 </div>
