<div [class.container]="isVerticalLayout$ | async" class="toolbar tw-w-full px-gutter tw-flex tw-items-center">
  <!--<button (click)="openSidenav()" [class.hidden]="!mobileQuery" mat-icon-button type="button">
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>-->

  <a *ngIf="isVerticalLayout$ | async"
     [routerLink]="['/']"
     class="ltr:tw-mr-4 rtl:tw-ml-4 tw-block tw-flex tw-items-center">
    <img alt="Logo" class="tw-w-8 tw-select-none" src="assets/img/demo/logo.svg" />
    <h1 [class.hidden]="!mobileQuery" class="title ltr:tw-pl-4 rtl:tw-pr-4 tw-m-0 tw-select-none">VEX</h1>
  </a>

  <mat-menu #addNewMenu="matMenu" [overlapTrigger]="false" xPosition="after" yPosition="below">
    <button mat-menu-item>
      <mat-icon svgIcon="mat:person_add"></mat-icon>
      <span>Add Contact</span>
    </button>

    <button mat-menu-item>
      <mat-icon svgIcon="mat:done_all"></mat-icon>
      <span>Add Task</span>
    </button>

    <button mat-menu-item>
      <mat-icon svgIcon="mat:assignment_turned_in"></mat-icon>
      <span>Add Project</span>
    </button>

    <button [matMenuTriggerFor]="documentMenu" mat-menu-item>
      <mat-icon svgIcon="mat:ballot"></mat-icon>
      <span>Add Document</span>
    </button>
  </mat-menu>

  <mat-menu #documentMenu="matMenu">
    <button mat-menu-item>
      <mat-icon svgIcon="mat:description"></mat-icon>
      <span>Add Quote</span>
    </button>

    <button mat-menu-item>
      <mat-icon svgIcon="mat:assignment"></mat-icon>
      <span>Add Invoice</span>
    </button>

    <button mat-menu-item>
      <mat-icon svgIcon="mat:receipt"></mat-icon>
      <span>Add Receipt</span>
    </button>
  </mat-menu>

  <div #megaMenuOriginRef class="ltr:tw-ml-2 rtl:tw-mr-2">
    <button *ngIf="isHorizontalLayout$ | async"
            [class.hidden]="mobileQuery"
            (click)="openMegaMenu(megaMenuOriginRef)"
            [ngClass]="{ 'bg-primary/10': megaMenuOpen$ | async }"
            color="white"
            mat-button
            type="button" style="font: 16px var(--font);">
      <span>Directory</span>
      <mat-icon class="icon-sm" svgIcon="mat:arrow_drop_down"></mat-icon>
    </button>
  </div>


  <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
       [class.hidden]="mobileQuery"
       class="px-gutter tw-flex-none tw-flex tw-items-center">
    <vex-navigation-item *ngFor="let item of navigationItems" [item]="item"></vex-navigation-item>
  </div>

  <span class="tw-flex-1"></span>

  <div class="tw--mx-1 tw-flex tw-items-center">
    <!--<div class="tw-px-1">
    <button (click)="openSearch()" mat-icon-button type="button">
      <mat-icon color="primary" svgIcon="mat:search"></mat-icon>
    </button>
  </div>

  <div class="tw-px-1">
    <vex-toolbar-notifications></vex-toolbar-notifications>
  </div>

  <div class="tw-px-1">
    <button (click)="openQuickpanel()" mat-icon-button type="button">
      <mat-icon color="primary" svgIcon="mat:bookmarks"></mat-icon>
    </button>
  </div>-->

    <div class="tw-px-1">
      <vex-toolbar-user></vex-toolbar-user>
    </div>

  </div>
</div>

<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
                [class.hidden]="mobileQuery"></vex-navigation>
