
export class UserNote {
  public NoteId: string;
  public NoteText: string;
  public CreatedByName: string;
  public CreationDate: number;
  public LinkedForms: string[] = [];
  public LinkedRules: string[] = [];
  public Category: string;
  public FastKey: string;
}

