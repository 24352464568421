//Angular Imports 
import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';

//Third Party Imports
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Clipboard } from '@angular/cdk/clipboard';
import { QueueService } from '../../services/queue.service';

/**
 * Forms Component
*/
@Component({
  selector: 'queueError',
  templateUrl: 'queueError.component.html',
  styleUrls: ['queueError.component.scss']
})

export class QueueError {

  @Input() errorText: string;
  @Input() env: string;
  @Input() id: string;
  @Input() callingSystem: string;



  constructor(public activeModal: NgbActiveModal,
    private _modal: NgbModal,
    private toastr: ToastrService,
    private clipboard: Clipboard,
    private queueService: QueueService) {
  }

  ngOnInit(): void {
    console.log(this.errorText);
  }

  /**
   * NgOnDestroy
   * @ignore
  */
  ngOnDestroy(): void {

  }

  public copyErrorText() {
    this.clipboard.copy(this.errorText);
  }

  public triggerRetry() {
    this.queueService.initiateRetry(this.env, this.id, this.callingSystem).subscribe(res => {
      this.toastr.info(res);
      this.activeModal.close();
    },
      err => {
        this.toastr.error(err.error);
        this.activeModal.close();
      });
    
  }

}
