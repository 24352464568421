//Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiagnosticsMainComponent } from './components/diagnostics-main.component';
import { DiagnositcsMainRoutingModule } from './diagnostics-main.routing';
import { DiagnosticsMainService } from './services';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { BooleanFilterComponent, FrameworkModule } from '../../framework';
import { AgGridModule } from 'ag-grid-angular';
import { QueueModule } from '../../ghostPortal/queue';
import { AttachLogDetail } from './components/attach-log-detail/attach-log-detail.component';
import { GenLogDetail } from './components/gen-log-detail';
import { DGMaterialComponentModule } from '../dgMaterial.module';
import { DefaultLogDetail } from './components/default-log-detail';
import { CompareModule } from '../../ghostPortal/compare/compare.module';


//Internal Imports

/**
  Main Layout Module
*/
@NgModule({
    declarations: [
        DiagnosticsMainComponent,
        AttachLogDetail,
        GenLogDetail,
        DefaultLogDetail
    ],
    imports: [
        CommonModule,
        DiagnositcsMainRoutingModule,
        MatButtonToggleModule,
        FormsModule,
        NgSelectModule,
        FrameworkModule,
        QueueModule,
        DGMaterialComponentModule,
        CompareModule,
        AgGridModule.withComponents([BooleanFilterComponent]),
    ],
    providers: [DiagnosticsMainService]
})
export class DiagnosticsMainModule { }
