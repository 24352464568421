//Angular Imports 
import { Injectable } from '@angular/core';

//rxjs Imports 
import { Observable, Subject } from 'rxjs';

//Internal Imports
import { DiagnosticsMessage } from '../models/dg-message.model';

/**
  * Provides methods to send message to Menu  
*/
@Injectable({ providedIn: 'root' })

export class DiagnosticsService {

  //Private Variables
  /**
   * Message Subject for communication between components
  */
  private subject;

  /**
   * Constructor
   * @ignore
  */
  constructor() {
    this.subject = new Subject<DiagnosticsMessage>();
  };

  //Public Methods
  /**
   * Send Message
   * @param {DiagnosticsMessage} message
   * @returns {void} 
  */
  sendDiagnosticsMessage(message: DiagnosticsMessage): void {
    this.subject.next(message);
  }

  /**
   * Get Message 
   * @returns {Observable<DiagnosticsMessage>} Message Observable
  */
  getDiagnosticsMessage(): Observable<DiagnosticsMessage> {
    return this.subject.asObservable();
  }
}
