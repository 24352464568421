/**
  List of execution engine available 
*/
export const EXECUTION_ENGINE_LIST: any[] = [
  { Name: "LOCAL", URL: "http://localhost:16449" },
  { Name: "DEV1", URL: "https://mkl-fast-dev1-api.azurewebsites.net" },
  { Name: "DEV2", URL: "https://mkl-fast-dev2-api.azurewebsites.net" },
  { Name: "QA1", URL: "https://mkl-fast-qa1-api.azurewebsites.net" },
  { Name: "QA2", URL: "https://mkl-fast-qa2-api.azurewebsites.net" },
  { Name: "UAT1", URL: "https://mkl-fast-uat1-api.azurewebsites.net" },
  { Name: "UAT2", URL: "https://mkl-fast-uat2-api.azurewebsites.net" },
  { Name: "PXUAT", URL: "https://mkl-fast-pxuat-api.azurewebsites.net" },
  { Name: "FIT", URL: "https://mkl-fast-fit-api.azurewebsites.net" },
  { Name: "MIRROR", URL: "https://mkl-fast-mirror-api.azurewebsites.net" },
  { Name: "PROD", URL: "https://mkl-fast-prod-api.azurewebsites.net" },
  { Name: "FAILOVER", URL: "https://mkl-fast-failover-api.azurewebsites.net" }
]

export const TEST_GROUP_TYPES: any[] = [
  { Name: "Smoke Test" },
  { Name: "LOB Tests" },
  { Name: "Archived Tests"}
]

// CPM Element ID's from the database used to get master data for specific CPM Elements
export const coverageCpmElementId: string = "94dfb32a-69e7-4878-bf00-d23d6b9e2a11";
export const lobCpmElementId: string = "6e7e1116-8717-4a23-907e-d50d0f5b71a2";


