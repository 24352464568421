<p id="oden-search-request-button">
  <button id="matching-queue-data-btn" mat-raised-button class="dm-standard-button" (click)="triggerFilter()">Perform Oden Log Search</button>
</p>

<mat-accordion #filterSections class="filter-options" multi>

  <mat-expansion-panel #dateSection [expanded]="true">
    <mat-expansion-panel-header class="dm-mat-panel-header">
      <mat-panel-title>
        Date Range
      </mat-panel-title>
      <mat-panel-description>(Required)</mat-panel-description>
    </mat-expansion-panel-header>

    
    <mat-form-field appearance="fill" class="dm-panel-content-top-margin">
      <mat-label>Enter a date range</mat-label>


      <mat-date-range-input [formGroup]="filterInputs" [rangePicker]="picker">
        <input id="start-date-input" matStartDate formControlName="startDate" placeholder="Start date">
        <input id="end-date-input" matEndDate formControlName="endDate" placeholder="End date">
      </mat-date-range-input>

      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>



    </mat-form-field>
      

  </mat-expansion-panel>

  <mat-expansion-panel #idSection [expanded]="isReady" [disabled]="!isReady" [formGroup]="filterInputs">
    <mat-expansion-panel-header class="dm-mat-panel-header">
      <mat-panel-title>
        Identifiers
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-form-field class="id-text-field dm-panel-content-top-margin" appearance="fill">
      <mat-label>Policy Number:</mat-label>
      <input matInput id="policy-number-input" formControlName="policyNumber">
    </mat-form-field>

  </mat-expansion-panel>

  <mat-expansion-panel #microserviceSection [expanded]="isReady" [disabled]="!isReady">
    <mat-expansion-panel-header class="dm-mat-panel-header">
      <mat-panel-title>
        Microservices
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ul class="dm-panel-content-top-margin">
      <li *ngFor="let ms of microserviceList" class="dm-checkbox-set">
        <mat-checkbox [ngClass]="'microservice_checkbox'" [(ngModel)]="ms.selected">{{ms.label}}</mat-checkbox>
      </li>
    </ul>
    <app-checkbox-group-controls [checkboxArray]="microserviceList" class="checkbox-controls"></app-checkbox-group-controls>

  </mat-expansion-panel>

</mat-accordion>
