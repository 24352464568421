//Angular Imports 
import { Component, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, startWith } from 'rxjs';
import { SidebarComponent } from '../../../../@vex/components/sidebar/sidebar.component';
import { ConfigService } from '../../../../@vex/config/config.service';
import { LayoutService } from '../../../../@vex/services/layout.service';
import { checkRouterChildsData } from '../../../../@vex/utils/check-router-childs-data';

//Internal Imports 
import { SharedService, AppInsightsService, EnvironmentService,  EnvironmentSpecifics } from '../../../framework';


/**
  Main Layout Component
*/
@Component({
    selector: 'ghostPortal',
  templateUrl: './ghostPortal.component.html',
  styleUrls: ['./ghostPortal.component.scss']
})

export class GhostPortalComponent {

  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isFooterVisible$ = this.configService.config$.pipe(map(config => config.footer.visible));
  isDesktop$ = this.layoutService.isDesktop$;

  toolbarShadowEnabled$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.toolbarShadowEnabled))
  );

  @ViewChild('configpanel', { static: true }) configpanel: SidebarComponent;

  /**
   * Constructor
   * @ignore
  */
  constructor(private sharedService: SharedService, private environmentService: EnvironmentService,
    private appInsightsService: AppInsightsService, private layoutService: LayoutService, private router: Router, private configService: ConfigService) {
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
  */
  ngOnInit(): void {

    //Hide the preloader on init 
    this.sharedService.hidePreloader();
    this.appInsightsService.init(this.environmentService.environmentDetails.APP_INSIGHTS_KEY);
    window["appInsights"] = appInsights;
    
    appInsights.queue.push(() => {
      //Set the telemetry settings
      appInsights.context.addTelemetryInitializer((envelop) => {
        let telemetryItem = envelop.data.baseData;
        //In future might need to get information from authentication service and set authorization information
        appInsights.setAuthenticatedUserContext("FASTAdminWeb");
        appInsights.context.user.id = "FASTAdminWeb";

        // To set custom properties:
        telemetryItem.properties = telemetryItem.properties || {};
        telemetryItem.properties["AppModule"] = this.environmentService.environmentDetails.TELEMETRY_PROPERTY_APP_MODULE;

      });

    });
  }

  /**
   * NgDestroy
   * @ignore
  */
  ngOnDestroy(): void {
  }

}
