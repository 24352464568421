import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportService } from './services/report-service';

import { SingleReport } from './components/single-report/single-report.component';

@NgModule({
  declarations: [
    SingleReport
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SingleReport
  ],
  providers: [
    ReportService
  ]
})
export class EmbeddedReportsModule { }
