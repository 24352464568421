import { Component, OnInit } from '@angular/core';
import { Report } from '../../../../shared-modules/embedded-reports/models/report';

@Component({
  selector: 'app-main-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.scss']
})
export class MainViewComponent implements OnInit {
  mainViewTitle: string = "AMS";

  report: Report;
  constructor() { }

  ngOnInit(): void {
    // Define the instances of the report
    this.report = new Report("AMS Sync Report");

    this.report.addInstances(
      ["local","dev1","dev2","qa1","qa2"],
      "https://va1-psplsql223.markelcorp.markelna.com/Reports/report/Specialty%20IT/D365%20to%20mAgency%20Control%20Report%20-Beta?rs:Command=Render&rs:embed=true"
    );

    this.report.addInstances(
      ["uat1","uat2","fit"],
      "https://va1-psplsql223.markelcorp.markelna.com/Reports/report/Specialty%20IT/D365%20to%20mAgency%20Control%20Report%20-UAT?rs:Command=Render&rs:embed=true"
    )

    this.report.addInstance(
      "default",  // failover, mirror, prod
      "https://va1-tsplapp248.markelcorp.markelna.com/Reports/report/Specialty%20IT/D365%20to%20mAgency%20Control%20Report?rs:Command=Render&rs:embed=true"
    )

  }

}
