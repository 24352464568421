import { Component, EventEmitter, Output } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular'
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-detail-trigger',
  template: `
    <span>
      <a class="transaction-detail-link mdo-standard-link" href="" (click)="showDetail($event)">{{ idBundle["productTypeId"] }}</a>
    </span>
    <app-copy-cell-value [cellValue]="idBundle['productTypeId']"></app-copy-cell-value>
  `
})
export class DetailTriggerComponent implements AgRendererComponent {

  @Output() triggerModal = new EventEmitter<any>();
  idBundle: any = {};
  params: any;

  constructor() { }

  // Called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.idBundle = this.setIdBundle(params);
    this.params = params;
  }

  // Called whenever the user gets the cell to refresh
  refresh(params: ICellRendererParams): boolean {
    this.idBundle = this.setIdBundle(params);
    return true;
  }

  setIdBundle(params: ICellRendererParams): any {
    return {
      bundleLabel: params.data.productType,
      bundleValue: params.valueFormatted ? params.valueFormatted : params.value,
      productType: params.data.productType,  //TODO: see if we can remove
      productTypeId: params.valueFormatted ? params.valueFormatted : params.value,  //TODO: see if we can remove
      transactionId: params.data.transactionId,
      context: "Transaction"
    }
  } 

  showDetail(event: any) {
    event.preventDefault();
    // To prevent the row selection event from taking place and inadvertently toggling the selection checkbox
    event.stopPropagation();
    // Calls parent component (MasterViewComponent) method to create new tab.
    this.params.context.componentParent.addTab(
      this.idBundle
    );
  }

}
