//Angular Imports 
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

//Internal Imports
import { ConvertComponent } from './components/convert.component';

/**
  Form Routes
*/
export const routes: Routes = [
  {
    path: '', component: ConvertComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})

export class ConvertRoutingModule {
}
