//Angular Imports 
import { Component, HostBinding, Input, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';

//Third Party Imports 
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

//Internal Imports 
import {
  ApplicationInsightsBaseComponent, AppInsightsService, DateFilterComponent, BooleanFilterComponent,
  DateTimePipe, SharedService, EnvironmentService, AuthorizationService, CPMElement, CPMField
} from '../../../framework';
import { AdminForm, RuleSetup, Rule, ElementExpression, RuleSet } from '../../models';
//import { ExecutionService, TestingService } from '../../services';


import 'rxjs';
import { saveAs } from 'file-saver';
import { FilterConfig } from '../../models/filter-config.model';
import { filter } from 'rxjs';
import { cloneDeep, forEach } from 'lodash';

//import { RuleEngineResult, SingleFormResult, RuleEngineLog, TestExecution, TestGroup, TestRun } from '../../models';



/**
 * Forms Component
*/
@Component({
  selector: 'app-form-page-rule-setup',
  templateUrl: './form-page-rule-setup.component.html',
  styleUrls: ['./form-page-rule-setup.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class FormPageRuleSetupComponent extends ApplicationInsightsBaseComponent {

  @HostBinding('style.width') width: string;

  @Input() public currentForm: AdminForm;

  @Input() public lobList: string[];

  // Used to track the current form and determine if the actual form has changed in the OnChanges event
  private currentFormNumber: string;

  // Used to pass discard event up to page-container when Discard button is clicked
  @Output() discardChanges = new EventEmitter();

  @Output() saveForm = new EventEmitter();

  @Output() changeRuleSetup = new EventEmitter();

  ruleSetupList: RuleSetup[];
  currentRuleSetup: RuleSetup;

  public lobString: string;

  //Logic for displaying LOB dates, dont display the div if rule setup has more than one LOB
  //public multipleLOBS: boolean = false;
  public effectiveDate: number = 0;
  public expirationDate: number = 0;
  public submissionExpirationDate: number = 0;

  //WC list for filter
  public writingCompanyList: any[] = [];
  public selectedWC: string = 'ZZ';
  public elementList: CPMElement[];
  public selectedElementName: string;
  public selectedElement: CPMElement;

  public elementFilters: FilterConfig[] = [];
  public clearedFilters: boolean = true;



  /** 
   * Constructor
   * @ignore
  */
  constructor(private _appInsightsService: AppInsightsService,
    private authService: AuthorizationService,
    private sharedService: SharedService,
    private changeDetector: ChangeDetectorRef) {
    super(_appInsightsService);
    this.width = "100%";
  }

  get FormsWritePermission(): boolean {
    return this.authService.FormsWrite();
  }

  ngOnInit(): void {
    console.log(this.lobList);
    this.sharedService.getAllCpmElements().subscribe(el => {
      this.elementList = el;
      let paperList = el.filter(a => a.CPMDataElementName == "Writing Company")[0];
      this.writingCompanyList = paperList.CPMFieldValues;
      let wcConfig = new FilterConfig();
      wcConfig.ElementName = "Writing Company";
      wcConfig.ElementValues = this.writingCompanyList;
      let blankFieldValue = new CPMField();
      blankFieldValue.Code = "None"
      blankFieldValue.Description = "Rule does not have this element";
      wcConfig.ElementValues.push(blankFieldValue);
      for (var i = 0; i < wcConfig.ElementValues.length; i++) {
        wcConfig.ElementValues[i].e2Description = wcConfig.ElementValues[i].Code + ' - ' + wcConfig.ElementValues[i].Description;
      }
      this.elementFilters.push(wcConfig);
      console.log('elementFilters:', this.elementFilters);
      this.changeDetector.detectChanges();
    });
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {

  }

  ngOnChanges(): void {
    this.effectiveDate = 0;
    this.expirationDate = 0;
    this.submissionExpirationDate = 0;
    this.setRuleSetupNames(this.currentForm.RuleSetups);
    this.ruleSetupList = this.currentForm.RuleSetups;

    // Only execute if a different form has been selected.  We don't want to execute due to changes in
    // the same selected form.
    if (this.currentForm.InternalFormNumber !== this.currentFormNumber) {
      this.currentFormNumber = this.currentForm.InternalFormNumber;     
      var blankRuleSetup = new RuleSetup();
      blankRuleSetup.Name = "";
      blankRuleSetup.LOB = [];
      blankRuleSetup.IsDefault = false;
      blankRuleSetup.RuleSet = new RuleSet();
      this.currentRuleSetup = blankRuleSetup;
    }
  }

  setRuleSetupNames(setups: RuleSetup[]): void {
    for (let setup of setups) {
      var name = "";
      if (setup.LOB.length == 1) {
        name = this.lobList.find(item => item.startsWith(setup.LOB[0] + ' - '));
      }
      else {
        name = setup.LOB.join(' & ');
      }
      if (setup.IsDefault) {
        setup.Name = "Default: " + name;
      } else {
        setup.Name = name;
      }   
    }
  }

  onElementChanged(event): void {
    console.log(event);
    let newFilter = new FilterConfig();
    let selectedCpmElement = this.elementList.filter(a => a.CPMDataElementName == event.CPMDataElementName)[0];
    newFilter.ElementValues = selectedCpmElement.CPMFieldValues;
    newFilter.ElementName = selectedCpmElement.CPMDataElementName;
    //Add new cpm field here for blank?
    let blankFieldValue = new CPMField();
    blankFieldValue.Code = "None"
    blankFieldValue.Description = "Rule does not have this element";
    newFilter.ElementValues.push(blankFieldValue);

    for (var i = 0; i < newFilter.ElementValues.length; i++) {
      newFilter.ElementValues[i].e2Description = newFilter.ElementValues[i].Code + ' - ' + newFilter.ElementValues[i].Description;
    }

    this.elementFilters.push(newFilter)
    this.changeDetector.detectChanges();

  }

  onFilterElementChange(event): void {
    console.log(this.elementFilters);
    this.clearedFilters = false;

  }

  removeFilter(filter) {
    const index = this.elementFilters.indexOf(filter);
    if (index > -1) {
      this.elementFilters.splice(index, 1);
    }
    // You might also want to handle additional logic like updating other states or making API calls here.
  }

  onChangeRuleSetup(event: RuleSetup): void {
    this.selectedWC = 'ZZ';
    this.currentRuleSetup = event;
    this.effectiveDate = 0;
    this.expirationDate = 0;
    this.submissionExpirationDate = 0;
    if (this.currentRuleSetup.LOB.length == 1) {
      var lobEff = this.currentForm.AttributeSets.filter(item => this.currentRuleSetup.LOB.indexOf(item.Scope) != -1)[0].EffectiveDate
      var lobExp = this.currentForm.AttributeSets.filter(item => this.currentRuleSetup.LOB.indexOf(item.Scope) != -1)[0].ExpirationDate;
      var lobSubExp = this.currentForm.AttributeSets.filter(item => this.currentRuleSetup.LOB.indexOf(item.Scope) != -1)[0].SubmissionExpirationDate;
      if (lobEff != null) {
        this.effectiveDate = lobEff;
      }
      if (lobExp != null) {
        this.expirationDate = lobExp;
      }
      if (lobSubExp != null) {
        this.submissionExpirationDate = lobSubExp;
      } 
    }
    this.changeRuleSetup.emit(this.currentRuleSetup);
    
  }

  onChangeWritingCompany(event: string): void {

  }

  clearAll(): void {
    this.clearedFilters = true;
    
    if (this.elementFilters.length > 0) {
      this.elementFilters[0].SelectedElementValue = null;
    }

    // Remove all but the first element
    if (this.elementFilters.length > 1) {
      this.elementFilters.splice(1);
    }
  }

  ruleFilterPasses(event: Rule) {
    this.changeDetector.markForCheck();
    for (var i = 0; i < this.elementFilters.length; i++) {
      if (this.elementFilters[i].SelectedElementValue != null) {
        if (this.elementFilters[i].ElementName == "Writing Company") {
          if (this.elementFilters[i].SelectedElementValue == "None") {
            if (event.Expressions.findIndex(ex => (ex.ElementName == "Writing Company" || ex.ElementName == "WritingCompany")) == -1) {
              return true;
            }
            else {
              return false;
            }
          }
          else {
            let index = event.Expressions.findIndex(ex => (ex.ElementName == "Writing Company" || ex.ElementName == "WritingCompany") && (ex.IncludedList.includes("ZZ") || ex.MandatoryList.includes("ZZ") || ex.IncludedList.includes(this.elementFilters[i].SelectedElementValue) || ex.MandatoryList.includes(this.elementFilters[i].SelectedElementValue)));
            if (index == -1) {
              return false;
            }
          }
          
        }
        else {
          if (this.elementFilters[i].SelectedElementValue == "None") {
            if (event.Expressions.findIndex(ex => (ex.ElementName == this.elementFilters[i].ElementName)) == -1) {
              return true;
            }
            else {
              return false;
            }
          }
          else {
            let index = event.Expressions.findIndex(ex => (ex.ElementName == this.elementFilters[i].ElementName || ex.ElementName == this.elementFilters[i].ElementName) && (ex.IncludedList.includes("ZZ") || ex.MandatoryList.includes("ZZ") || ex.IncludedList.includes(this.elementFilters[i].SelectedElementValue) || ex.MandatoryList.includes(this.elementFilters[i].SelectedElementValue)));
            if (index == -1) {
              return false;
            }
          }
          
        }
        
      }
    }
    return true;
  }

  onDeleteRule(event: Rule): void {
    let index = this.currentRuleSetup.RuleSet.Rules.indexOf(event);
    if (index >= 0) {
      this.currentRuleSetup.RuleSet.Rules.splice(index, 1);
    }
  }

  onCloneRule(event: Rule): void {
    let index = this.currentRuleSetup.RuleSet.Rules.indexOf(event);
    if (index >= 0) {
      let rule = new Rule();
      rule = _.cloneDeep(this.currentRuleSetup.RuleSet.Rules[index]);
      this.currentRuleSetup.RuleSet.Rules.push(rule);
    }
  }

  onSaveRule(event: Rule): void {
    this.currentRuleSetup.RuleSet.Rules[this.currentRuleSetup.RuleSet.Rules.indexOf(event)] = event;
    var index = _.findIndex(this.currentForm.RuleSetups, {Name: `${this.currentRuleSetup.Name}`});
    this.currentForm.RuleSetups[index] = this.currentRuleSetup;
  }

  onSaveRuleSetup(): void {
    // Fire event up to page-container to save the form
      //Set IsDefault variable based on default rule setup, shouldn't be neccessary after data is properly scripted
    for (var item of this.currentForm.RuleSetups) {
      if (item.Name.includes("Default") || item.IsDefault) {
        item.IsDefault = true;
      }
      else {
        item.IsDefault = false;
      }
    }
    console.log(this.currentForm);
    this.saveForm.emit(this.currentForm);
  }

  //Remove rule setup from form
  deleteRuleSetup() {
    //Do not delete default
    if (!this.currentRuleSetup.IsDefault) {
      if(confirm("This will delete the current rule setup and add their LOBS to the default, continue?")){
      //Find default index and add LOBS that will be removed, then splice deleted setup
      var index = this.currentForm.RuleSetups.findIndex(x => x.Name === this.currentRuleSetup.Name);
      var defaultIndex = this.currentForm.RuleSetups.findIndex(x=> x.IsDefault);
      for (var i = 0; i < this.currentRuleSetup.LOB.length; i++) {
        this.currentForm.RuleSetups[defaultIndex].LOB.push(this.currentRuleSetup.LOB[i]);
      }
      this.currentForm.RuleSetups.splice(index, 1);
      this.onSaveRuleSetup();
      this.ngOnChanges();
      this.onChangeRuleSetup(this.currentForm.RuleSetups[defaultIndex]);
      }
    }
  }
  //When adding a new rule default setup for attach rule is 3 expressions, Writing Company, State, and Coverage
  //Remove rule default is just Coverage
  onAddRule(template: string): void {
    this.selectedWC = 'ZZ';
    let newRule: Rule = new Rule();
    newRule.Expressions = [];

    if (template === 'basic_remove') {     
      newRule.Action = "Remove";

      let expression: ElementExpression = new ElementExpression();
      expression.ElementName = "Coverage";
      expression.Operator = "in";
      expression.IncludedList = [];
      expression.MandatoryList = [];
      newRule.Expressions.push(expression);

    } else if (template === 'basic_attach') {
      newRule.Action = "Attach";
      
      let expression: ElementExpression = new ElementExpression();
      expression.ElementName = "Writing Company";
      expression.Operator = "in";
      expression.IncludedList = [];
      expression.MandatoryList = [];
      newRule.Expressions.push(expression);

      expression = new ElementExpression();
      expression.ElementName = "State";
      expression.Operator = "in";
      expression.IncludedList = [];
      expression.MandatoryList = [];
      newRule.Expressions.push(expression);

      expression = new ElementExpression();
      expression.ElementName = "Coverage";
      expression.Operator = "in";
      expression.IncludedList = [];
      expression.MandatoryList = [];
      newRule.Expressions.push(expression);

    }

    this.currentRuleSetup.RuleSet.Rules.push(newRule);
  }
}
