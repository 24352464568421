import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular'
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-transaction-id-cell',
  template: `
    <span>{{params.data.transactionId}}</span>
    <app-copy-cell-value [cellValue]="params.data.transactionId"></app-copy-cell-value>
  `
})
export class TransactionIdCellComponent implements AgRendererComponent {

  params: any;

  constructor() { }

  // Called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  // Called whenever the user gets the cell to refresh
  refresh(params: ICellRendererParams): boolean {
    return true;
  }

}
