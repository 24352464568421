import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { FilterChoice } from "../../models/filter-choice";
import { FilterData } from "../../models/filter-data";
import { ImportQueueService } from "../../services/import-queue.service";

@Component({
  selector: 'app-staging-file-request-filter',
  templateUrl: './staging-file-request-filter.component.html',
  styleUrls: ['./staging-file-request-filter.component.scss']
})
export class StagingFileRequestFilterComponent implements OnInit {
  
  @Input() errorStatuses: string[] = [];
  @Input() initialStartDate: any;
  @Input() initialEndDate: any;

  @Output() refreshStagingGrid = new EventEmitter();
  @Output() toggleStagingFilterError = new EventEmitter();

  isReady: boolean = false;
  showProductTypes: boolean = false;

  statusList: FilterChoice[] = [];
  productTypeList: FilterChoice[] = [];
  partnerList: FilterChoice[] = [];
  filterContext: string = "Staging";

  filterInputs = new FormGroup({
    startDate: new FormControl<string>(''),
    endDate: new FormControl<string>('')
  });

  constructor(private queueService: ImportQueueService) { }

  ngOnInit(): void {
    this.filterInputs.controls.startDate.setValue(this.initialStartDate);
    this.filterInputs.controls.endDate.setValue(this.initialEndDate);
    this.populateJobFilterOptions();
  }

  populateJobFilterOptions(): void {
    this.queueService.getFilterOptions(this.filterContext)
      .subscribe(
        // Handle successful response with filter option data
        filterOptionsResponse => {
          this.setStagingFilterOptions(filterOptionsResponse);
          this.isReady = true;
        },
        // Handle an error response
        error => {
          this.toggleStagingFilterError.emit(true);
          this.isReady = false;
        }
      );
  }

  setStagingFilterOptions(filterOptions: any): void {
    this.statusList = [];
    this.partnerList = [];
    this.productTypeList = [];
    filterOptions.statuses.forEach((status: string) => {
      this.statusList.push({ label: status, value: status, selected: (this.errorStatuses.indexOf(status) > -1) })
    });

    filterOptions.partners.forEach((partner: string) => {
      this.partnerList.push({ label: partner, value: partner, selected: false });
    });

    filterOptions.productTypes.forEach((productType: string) => {
      this.productTypeList.push({ label: productType, value: productType, selected: false })
    });

  }

  reInit(): void {
    this.queueService.getFilterOptions(this.filterContext)
      .subscribe(
        // Handle successful response
        filterOptionsResponse => {
          this.setStagingFilterOptions(filterOptionsResponse);
          this.toggleStagingFilterError.emit(false);
          this.isReady = true;
        },
        // Handle an error response
        error => {
          this.toggleStagingFilterError.emit(true);
          this.isReady = false;
        }
      );
  }

  executeFilter(): void {
    let filterData: FilterData = this.configureFilterParams();
    this.refreshStagingGrid.emit(filterData);
  }

  configureFilterParams(): FilterData {
    let statusArray: string[] = [];
    let productTypeArray: string[] = [];
    let partnerArray: string[] = [];

    let rawStartDate = this.filterInputs.controls.startDate.value;
    let rawEndDate = this.filterInputs.controls.endDate.value;

    this.statusList.forEach(status => {
      if (status.selected) {
        statusArray.push(status.value);
      }
    });
    this.productTypeList.forEach(type => {
      if (type.selected) {
        productTypeArray.push(type.value);
      }
    });
    this.partnerList.forEach(partner => {
      if (partner.selected) {
        partnerArray.push(partner.value);
      }
    });

    let filterData = new FilterData({
      startDate: rawStartDate,
      endDate: rawEndDate,
      status: statusArray,
      partner: partnerArray,
      productType: productTypeArray
    });

    return filterData;
  }


}
