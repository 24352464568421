//Angular Imports 
import { Component, HostBinding, Input } from '@angular/core';

//Third Party Imports 
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

//Internal Imports 
import {
  ApplicationInsightsBaseComponent, AppInsightsService, DateFilterComponent, BooleanFilterComponent,
  DateTimePipe, SharedService, DateTimeUtcPipe
} from '../../../../../framework';
import { ReportService } from '../../../../../framework/services/report.service'
import { ExecutionService } from '../../services';
import { EXECUTION_ENGINE_LIST } from '../../configs';


import 'rxjs';
import { saveAs } from 'file-saver';

import { RuleResult, DateResult, FormResult, ExpressionResult } from '../../models';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-execute-results-container',
  templateUrl: './exec-results-container.component.html',
  styleUrls: ['./exec-results-container.component.scss']
})

export class ExecuteResultsContainerComponent extends ApplicationInsightsBaseComponent {

  //Public Variables
  /**
   * Host binding for width 
  */
    @HostBinding('style.width') width: string;

    @Input() ruleResults: RuleResult[];
  expressionResults: ExpressionResult[] = [];
  writingCompines: any[] = [{ ID: 'ZZ', Name: 'All' }, { ID: '4', Name: '4 - Evanston Insurance Company' }, { ID: '5', Name: '5 - Markel American Insurance Company' },
    { ID: '6', Name: '6 - Markel Insurance Company' }, { ID: '15', Name: '15 - Markel Bermuda Limited' }, { ID: '23', Name: '23 - Alterra America' }, { ID: '69', Name: '69 - State National' }];
  selectedWC: string = "ZZ";

  /**
  * executeRulesGridOptions
 */
  executeRulesGridOptions: GridOptions;

  /**
  * initialRowDataLoad$
 */
  initialRowDataLoad$;


 

  /** 
   * Constructor
   * @ignore
  */
  constructor(private _appInsightsService: AppInsightsService,
    public activeModal: NgbActiveModal,
    private executionService: ExecutionService,
    private toastr: ToastrService,
    private _modal: NgbModal,
    private reportService: ReportService,
    private dateTimePipe: DateTimeUtcPipe,
    private sharedService: SharedService) {
    super(_appInsightsService);
    this.width = "100%";
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
  */
  ngOnInit(): void {
    this.configureGrid();
    console.log("RULE RESULTS");
    console.log(this.ruleResults);
 
  }

  ngOnChanges() {

  }

  /**
   * NgOnDestroy
   * @ignore
  */
  ngOnDestroy(): void {

  }
  //Iterate through rule results and display individual expression results
  setRowData() {
    this.expressionResults = [];
    var ruleName: string = "";
    var ruleEffectiveDate: string = "";
    var ruleExpirationDate: string = "";
    var attachRules: number = 1;
    var removeRules: number = 1;
    var boolCount: boolean = true; //Sets background color on rows
    //Since we are displaying expressions in the grid set the associated rule via a counter
    for (var i = 0; i < this.ruleResults.length; i++) {
      boolCount = !boolCount;
      if (this.ruleResults[i].Rule.Action == "Attach") {
        ruleName = "Attach Rule " + attachRules;
        attachRules++;
      }
      else{
        ruleName = "Remove Rule " + removeRules;
        removeRules++;
      }
      if (this.ruleResults[i].Rule.EffectiveDate != null && this.ruleResults[i].Rule.EffectiveDate == 0) {
        ruleEffectiveDate = new Date(this.ruleResults[i].Rule.EffectiveDate).toLocaleDateString();
      }
      if (this.ruleResults[i].Rule.ExpirationDate != null && this.ruleResults[i].Rule.ExpirationDate == 0) {
        ruleExpirationDate = new Date(this.ruleResults[i].Rule.ExpirationDate).toLocaleDateString();
      }
      
      for (var j = 0; j < this.ruleResults[i].ExpressionResults.length; j++) {
        var finalExpression = this.ruleResults[i].ExpressionResults[j];
        finalExpression.RuleName = ruleName;
        finalExpression.RuleEffectiveDate = ruleEffectiveDate;
        finalExpression.RuleExpirationDate = ruleExpirationDate;
        finalExpression.CpmString = finalExpression.CpmValues.map(x => x).join(",");
        finalExpression.IncludedString = finalExpression.Expression.IncludedList.map(x => x).join(",");
        finalExpression.MandatoryString = finalExpression.Expression.MandatoryList.map(x => x).join(",");
        finalExpression.ruleColor = boolCount;
        this.expressionResults.push(finalExpression);
      }
    }
    this.executeRulesGridOptions.api.setRowData(this.expressionResults);
  }

  //Filter displayed rules by writing company. Since rows are not grouped by rule, store the names of matching rules to filter
  onWCChange(): void {
    if (this.selectedWC == "ZZ") {
      this.setRowData();
    }
    else {
      let boolCount: boolean = true;
      let filteredResults: ExpressionResult[] = [];
      let matchingRuleNames: string[] = [];
      this.expressionResults.forEach(row => {
        if (row.Expression.ElementName == "WritingCompany" || row.Expression.ElementName == "Writing Company") {
          if (row.Expression.IncludedList.includes(this.selectedWC) || row.Expression.MandatoryList.includes(this.selectedWC)) {
            matchingRuleNames.push(row.RuleName);
          }
        }
      });
      filteredResults = this.expressionResults.filter(row => matchingRuleNames.indexOf(row.RuleName) != -1);
      this.executeRulesGridOptions.api.setRowData(filteredResults);
    }
  }

  private configureGrid(): void {
    this.initialRowDataLoad$ = [];
    this.executeRulesGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: 'autoHeight',
      columnDefs: this.createColumnDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      paginationPageSize: 10,
      rowHeight: 50,
      enableColResize: true,
      getRowStyle: params => {
        if (params.data.ruleColor) {
          return {background: '#EAEAEA'}
        }
      },
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent
      },
      onGridReady: () => {
        this.setRowData();
        this.executeRulesGridOptions.api.sizeColumnsToFit();
      },
      onRowClicked: (event) => {
        this.onResultsRowClick(event);
      },
      onFilterChanged: (event) => {
      },
      onSortChanged: (event) => {
      },
      onFilterModified: function (event) {
        //console.log(event);
      }
    };

  }

  onResultsRowClick(event: any) {
 
  }

  createColumnDef(): any[] {
    return [
      { headerName: 'Rule', field: 'RuleName', resizable:true, width: 160, comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      {
        headerName: 'Element Name', resizable: true, field: 'Expression.ElementName'
      },
      {
        headerName: 'Operator', field: 'Expression.Operator', width: 140
      },
      {
        headerName: 'Optional Element Values', resizable: true, field: 'IncludedString'
      },
      {
        headerName: 'Mandatory Element Values', resizable: true, field: 'MandatoryString'
      },
      {
        headerName: 'Values in CPM', resizable: true, field: 'CpmString'
      },
      {
        headerName: 'Result', field: 'Result', width: 100
      }
    ]
  }
}
