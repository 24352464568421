//Angular Imports 
import { Component, OnInit, Input, ViewChild } from '@angular/core';

//Third Party Imports 
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

//Internal Imports 
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms'
import { PrintOrderService } from '../../services';
import { PrintOrderMasterViewModel } from '../../models';
import { SharedService, ConfirmationModalComponent, UserProfileService } from '../../../framework';
import { AddNoteComponent } from '../../../user-notes/components'
import { error } from 'util';

/**
 * Add Form Component
*/
@Component({
  selector: 'app-add-print-order',
  templateUrl: './add-print-order.component.html',
  styleUrls: ['./add-print-order.component.scss']
})

export class AddPrintOrderComponent implements OnInit {

  //Public Variables
  /**
   * Host binding for width 
  */
  //@HostBinding('style.width') width: string;

  @ViewChild(AddNoteComponent, { static: false }) addNote: AddNoteComponent;

  /**
* formGroup
*/
  formGroup: UntypedFormGroup;

  /**
* current print order
*/
  currentPrintOrder: PrintOrderMasterViewModel;

  /**
* formTypes
*/
  formTypes: any[];

  /**
 * readonly fields
 */
  public readonly: boolean = false;

  /**
* lineOfBusinessList
*/
  lineOfBusinessList: any[];

  /**
 * actionType
 */
  actionType: string;

  /**
* notesIdType
*/
  notesIdType: string = "PrintOrder";

  printOrderId: string;

  selectedLOB: string;

  /**
   *
   * 
   * @param activeModal
   * @param _modal
   * @param fb
   * @param printOrderService
   * @param sharedService
   * @param toastr
   * @param userProfileService
   */
  /** Constructor
  * @ignore
 */
  constructor(public activeModal: NgbActiveModal, private _modal: NgbModal,
    private fb: UntypedFormBuilder, private printOrderService: PrintOrderService,
    private sharedService: SharedService, private toastr: ToastrService,
    private userProfileService: UserProfileService) {
    this.formTypes = [];
    this.lineOfBusinessList = [];
  }


  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
  */
  ngOnInit() {

    this.createFormBuilder();
    this.sharedService.getMasterData().subscribe(el => {
      this.formTypes = el.FormType;
      this.lineOfBusinessList = el.LineOfBusinessList;
      this.createFormBuilder();
      //this.addControlInFormGroup();
      this.fillFormBuilder();
    }, error => { this.toastr.error('Error occurred while fetching master data.', 'Print Order'); });
  }


  /**
   * NgDestroy
   * @ignore
  */
  ngOnDestroy(): void {
  }


  /**
   * Create form for builder
   **/
  private createFormBuilder(): void {

    if (this.actionType == "Copy" || this.actionType == "Add") {
      this.formGroup = this.fb.group({
        lineOfBusiness: [{ value: '', disabled: this.readonly }],
        formType: [{ value: '', disabled: this.readonly }, [Validators.required]],
        sortOrder: [{ value: '', disabled: this.readonly }, [Validators.required]],
        description: [{ value: '', disabled: this.readonly }, [Validators.required]],
        note: [{ value: '', disabled: this.readonly }],
      });
    }
    else if (this.actionType == "Edit") {
      this.formGroup = this.fb.group({
        lineOfBusiness: [{ value: '', disabled: this.readonly }],
        formType: [{ value: '', disabled: this.readonly }, [Validators.required]],
        sortOrder: [{ value: '', disabled: this.readonly }, [Validators.required]],
        description: [{ value: '', disabled: this.readonly }, [Validators.required]],
        note: [{ value: '', disabled: this.readonly }],
      });
    }

  }

  /**
   * Fill Form Builder
   * */
  private fillFormBuilder(): void {
    if (this.actionType == "Edit" || this.actionType == "Copy") {

      this.formGroup.patchValue({
        lineOfBusiness: this.selectedLOB,
        formType: this.currentPrintOrder.FormType,
        sortOrder: this.currentPrintOrder.PrintOrder,
        description: this.currentPrintOrder.Description
      });
      this.printOrderId = this.currentPrintOrder.PrintOrderId;
    }
    else {
      this.currentPrintOrder = new PrintOrderMasterViewModel();
      this.formGroup.patchValue({
        lineOfBusiness: this.selectedLOB
      });

    }
  }


  /**
  * Save Rule Type
  **/
  saveprintOrder() {

    Object.keys(this.formGroup.controls).forEach(key => {
      this.formGroup.get(key).markAsDirty();
    });
    if (this.formGroup.valid) {
      if (this.actionType == "Edit") {
        this.currentPrintOrder.PrintOrderId = this.printOrderId;
        this.currentPrintOrder.Id = this.printOrderId;
      }
      else {
        this.currentPrintOrder.Id = "00000000-0000-0000-0000-000000000000";
        this.currentPrintOrder.PrintOrderId = "00000000-0000-0000-0000-000000000000";
      }

      this.currentPrintOrder.IsActive = true;
      this.currentPrintOrder.LineOfBusiness = this.selectedLOB;
      this.currentPrintOrder.FormType = this.formGroup.controls["formType"].value;
      this.currentPrintOrder.PrintOrder = this.formGroup.controls["sortOrder"].value;
      this.currentPrintOrder.Description = this.formGroup.controls["description"].value;
      this.currentPrintOrder.CreatedByName = this.userProfileService.UserProfile.UserName;
      this.currentPrintOrder.CreationDate = moment().unix();

      this.printOrderService.addUpdatePrintOrder(this.currentPrintOrder).subscribe(result => {
        // Show dialog to enter note.
        //const modalRef = this._modal.open(AddNoteComponent);
        //modalRef.componentInstance.linkedForms = [result.FormId];
        //this.addNote.saveNote([result.FormId], []);

        this.activeModal.close(result);
        this.toastr.success('Print Order Data Saved Successfully', 'Print order');
      }, error => { this.toastr.error('Error occurred while adding Print order.', 'Print order'); });
    }

  }
}


