//Angular Imports 
import { Component, OnInit, OnDestroy } from "@angular/core";
import {DatePipe} from '@angular/common';

//Third Party Imports
import * as moment from "moment";
import { IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams, RowNode } from "ag-grid-community";
import { IFilterAngularComp } from "ag-grid-angular";

/**
 * Date Filter Component
 */
@Component({
  selector: 'app-date-filter',
  template: `
        <div class="ag-filter-body-wrapper">
          <div>
            <select class="ag-filter-select" id="filterType" [(ngModel)]="filterType" (ngModelChange)="onChange()">
                    <option value="equal">Equals</option>
                    <option value="greaterThan">Greater than</option>
                    <option value="greaterThanEqual">Greater than equal</option>
                    <option value="lessThan">Less than</option>
                    <option value="lessThanEqual">Less than Equal</option>
                    <option value="notEqual">Not equal</option>
                    <option value="inRange">In range</option>
            </select>
          </div><div class="ag-filter-body">
            <span>Date(mm/dd/yyyy)</span>
            <input class="ag-filter-filter" id="fromDate" type="text" [(ngModel)]="fromDate" (ngModelChange)="onChange()">
          </div>
          <div class="ag-filter-body" *ngIf="filterType == 'inRange'">
            <span>Date(mm/dd/yyyy)</span>
            <input class="ag-filter-filter" id="toDate" type="text" [(ngModel)]="toDate" (ngModelChange)="onChange()">
          </div>
        </div>`,
  styles:['input {height:30px;}','span {padding:5px}']
})
export class DateFilterComponent implements IFilterAngularComp, OnInit, OnDestroy {

  // Private Variables
  /**
   * Params
   */
  private _params: IFilterParams;

  /**
   * ValueGetter
   */
  private _valueGetter: (rowNode: RowNode) => any;

  // Public Variables
  /**
   * From Date
   */
  public fromDate: string;
  /**
   * To Date
   */
  public toDate: string;

  /**
   * Filter Type
   */
  public filterType: string;

  // Constructor
 /**
  * @ignore
  * */
  constructor() {
  }

  // Angular Lifecycles
  /**
   * @ignore
   * */
  ngOnInit(): void {

  }
  
  /**
   * @ignore
   * */
  ngOnDestroy(): void {
  }

  // Public Methods
  /**
   * AG Init
   * @param params Grid filter params
   */
  agInit(params: IFilterParams): void {
    this.fromDate = null;
    this.toDate = null;
    this.filterType = 'equal';
    this._params = params;
    this._valueGetter = params.valueGetter.apply;
  }

  /**
   * Is Filter Active (Method called by the grid to check if the filter is active)
   * */
  isFilterActive(): boolean {
    return this.fromDate != null;
  }

  /**
   * Does Filter Pass (Method called by the grid to check if the data passes the filter)
   * @param params Grid Params
   */
    //CR 1/2/2020 - changed filter so it compares in utc and ignores time since we are only comparing days
  doesFilterPass(params: IDoesFilterPassParams): boolean {
    let returnValue = true;
    //let date = moment(this.fromDate).utc().unix();
    var filteredDate = this.fromDate.split("/");
    let date = new Date(filteredDate[2] + "-" + filteredDate[0] + "-" + filteredDate[1]).toISOString();
    //let nextDate = moment(this.fromDate).add(1, 'd').unix();
    let nextDate = new Date(filteredDate[2] + "-" + filteredDate[0] + "-" + filteredDate[1]).toISOString();
    if (this.filterType == "inRange") {
      var filteredNextDate = this.toDate.split("/");
      nextDate = new Date(filteredNextDate[2] + "-" + filteredNextDate[0] + "-" + filteredNextDate[1]).toISOString();
      //let nextDate = moment(this.toDate).unix();
    }

    //let value = +this._valueGetter(params.node);
    let value = new Date(+this._valueGetter(params.node) * 1000).toISOString();
    switch (this.filterType) {
      case 'lessThan':
        returnValue = value < date;
        break;
      case 'greaterThan':
        returnValue = value >= nextDate;
        break;
      case 'lessThanEqual':
        returnValue = value <= date;
        break;
      case 'greaterThanEqual':
        returnValue = value >= date;
        break;
      case 'notEqual':
        returnValue = value < date || value > nextDate;
        break;
      default:
        returnValue = value >= date && value <= nextDate;
        break;
    }
    
    return returnValue;
  }

  /**
   * Get Model
   * */
  getModel(): any {
    return { value: this.fromDate };
  }

  /**
   * Set Model
   * @param model Model
   */
  setModel(model: any): void {
    this.fromDate = model ? model.value : null;
  }

  /**
   * On Change
   * */
  onChange(): void {
    if (this.fromDate == '') {
      this.fromDate = null;
    }
    if (this.toDate == '') {
      this.toDate = null;
    }

    if (((moment(this.fromDate).isValid() || this.fromDate == null) && this.filterType != 'inRange')
      || ((moment(this.fromDate).isValid() || this.fromDate == null)
        && (moment(this.toDate).isValid() || this.toDate == null)
        && this.filterType == 'inRange')) {
      this._params.filterChangedCallback();
    }
  }
  
  /**
   * Clear Filter
   * */
  clearFilter(): void {
    this.toDate = null;
    this.fromDate = null;
    this._params.filterChangedCallback();
  }

  /**
   * Apply Filter
   * */
  applyFilter(): void {
    this.onChange();
  }
}
