//Internal Imports
import { ElementExpression } from "./";

export class Rule {
  public Action: string;
  public IsMandatory: boolean;
  public Comment: string;
  public EffectiveDate: number;
  public ExpirationDate: number;
  public SubmissionExpirationDate: number;
  public Expressions: ElementExpression[] = [];
}
