<div class="container-fluid">

  <div class="row align-items-start">
    <div class="col-md-12">
      <br />

      <mat-accordion class="detail-blocks" multi>

        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header class="mdo-mat-panel-header">
            <mat-panel-title>
              <h5>Entity Log Information</h5>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row">

            <div class="col-md-4">
              <mat-list>
                <mat-list-item>
                  <p matLine class="detail-label">Transaction ID</p>
                  <p matLine>{{details.transactionId}}</p>
                </mat-list-item>
                <mat-list-item>
                  <p matLine class="detail-label">Transaction Type</p>
                  <p matLine>{{details.transactionType}}</p>
                </mat-list-item>
                <mat-list-item>
                  <p matLine class="detail-label">Job ID</p>
                  <p matLine>{{details.jobId}}</p>
                </mat-list-item>
              </mat-list>
            </div>

            <div class="col-md-4">
              <mat-list>
                <mat-list-item>
                  <p matLine class="detail-label">Entity Type</p>
                  <p matLine>{{details.entityType}}</p>
                </mat-list-item>
                <mat-list-item>
                  <p matLine class="detail-label">Policy Number</p>
                  <p matLine>{{details.policyNumber}}</p>
                </mat-list-item>
                <mat-list-item>
                  <p matLine class="detail-label">Claim Number</p>
                  <p matLine>{{details.claimNumber}}</p>
                </mat-list-item>
              </mat-list>
            </div>

              <div class="col-md-4">
                <mat-list>
                  <mat-list-item>
                    <p matLine class="detail-label">Partner</p>
                    <p matLine>{{details.partner}}</p>
                  </mat-list-item>
                  <mat-list-item>
                    <p matLine class="detail-label">Status</p>
                    <p matLine>{{details.status}}</p>
                  </mat-list-item>
                  <mat-list-item>
                    <p matLine class="detail-label">Timestamp</p>
                    <p matLine>{{details.timeStamp}}</p>
                  </mat-list-item>
                </mat-list>
              </div>

            </div>

</mat-expansion-panel>

        <mat-expansion-panel [expanded]="true" (opened)="resizeGrid('processLogs')">
          <mat-expansion-panel-header class="mdo-mat-panel-header">
            <mat-panel-title>
              <h5>Process Logs</h5>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ag-grid-angular id="processLogsGrid"
                           enableCellTextSelection="true"
                           style="width: 100%; height: 300px;"
                           class="ag-theme-balham mdo-standard-grid mdo-panel-content-top-margin"
                           [frameworkComponents]="frameworkComponents"
                           [rowData]="details.errorLogs"
                           [columnDefs]="processLogsColumnDefs"
                           [pagination]="pagination"
                           [paginationAutoPageSize]="paginationAutoPageSize"
                           [gridOptions]="processLogsGridOptions">
          </ag-grid-angular>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true" (opened)="resizeGrid('actionHistory')">
          <mat-expansion-panel-header class="mdo-mat-panel-header">
            <mat-panel-title>
              <h5>
                Notes
              </h5>
            </mat-panel-title>
          </mat-expansion-panel-header>
          
          <ag-grid-angular id="actionHistoryGrid"
                           enableCellTextSelection="true"
                           style="width: 100%; height: 300px;"
                           class="ag-theme-balham mdo-standard-grid mdo-panel-content-top-margin"
                           [frameworkComponents]="frameworkComponents"
                           [rowData]="details.actionHistory"
                           [columnDefs]="actionHistoryColumnDefs"
                           [pagination]="pagination"
                           [paginationAutoPageSize]="paginationAutoPageSize"
                           [gridOptions]="actionHistoryGridOptions">
          </ag-grid-angular>
        </mat-expansion-panel>


      </mat-accordion>
    </div>
  </div>

</div>
