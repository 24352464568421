//Angular Imports
import { Component, OnInit } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";

//Third Party Imports
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { GridOptions } from "ag-grid-community";

//Internal Imports
import { DeployPackage } from "../../models/deployPackage.model";
import { DeployService } from "../../services";
import {
  UserProfileService,
  SelectableItem,
  SharedService,
} from "../../../framework";
import {
  TestingService,
  ExecutionService,
  TestRun,
} from "../../../diagnostics/testSuites/execution";
import { error } from "console";

/**
 * Deploy Confirmation Component
 */
@Component({
  selector: "deploy-confirmation",
  templateUrl: "./deploy-confirmation.component.html",
  styleUrls: ["./deploy-confirmation.component.scss"],
})
export class DeployConfirmationComponent implements OnInit {
  //Public Variables
  /**
   * formGroup
   */
  formGroup: UntypedFormGroup;

  /**
   * packageToBeDeployed
   */
  packageToBeDeployed: DeployPackage;

  productionDeployment: boolean = false;

  testGroupGridOptions: GridOptions;
  testGroupSelectableItems: SelectableItem[];

  public envList: any[];
  public selectedEnv: string;

  /**
   * Constructor
   * @ignore
   */
  constructor(
    public activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private deployService: DeployService,
    private toastr: ToastrService,
    private userProfileService: UserProfileService,
    private testingService: TestingService,
    private executionService: ExecutionService,
    private sharedService: SharedService
  ) {
    this.testGroupSelectableItems = [];
  }

  //Angular Lifecycles
  /**
   * On Init
   * @ignore
   */
  ngOnInit() {

    this.createFormBuilder();
    this.fillFormBuilder();

    if (
      this.formGroup.controls["environmentsDeployTo"].value.indexOf("PROD") !==
      -1
    ) {
      this.productionDeployment = true;
    }

    this.configureTestGroupGrid();
    this.populateEnvList();

    /*this.deployService.getLOBTests(this.packageToBeDeployed.LOB).subscribe(res => {
      if (res.length > 0) {
          let testRun: TestRun = {
            Environment: this.selectedEnv,
            TestGroupIds: [res[0].Id],
            TestCases: null
        };
        this.testingService.executeTestRun(testRun).subscribe(res2 => {
          for (var i = 0; i < res2.length; i++) {
            if (!res2[i].Passed) {
              this.toastr.error(res2[i].TestCase.Name, "Error validating deployment, test cases failed");
            }
          }
        });
      }
    },
      error => {
        this.toastr.error(error, "Error When Validating Content")
    });*/
  }

  private populateEnvList(): void {
    this.envList =
      this.formGroup.controls["environmentsDeployTo"].value.split(",");
    this.envList.push({ Name: "LOCAL" });
    this.selectedEnv = this.envList[0];
  }

  private createSelectableItemLists(): void {
    // Load TestGroups
    this.testingService.getTestGroups().subscribe((res) => {
      for (var testGroup of res) {
        let item: SelectableItem = new SelectableItem();
        item.Code = testGroup.Id;
        item.VisibleDescription = testGroup.Name;
        this.testGroupSelectableItems.push(item);
      }
      //this.sortSelectableItems(this.testGroupSelectableItems);
      this.testGroupGridOptions.api.setRowData(this.testGroupSelectableItems);
      this.testGroupGridOptions.columnApi.autoSizeAllColumns();
    });
  }

  // Public Methods
  /**
   * To deploy a package
   * */
  public deployPackage(): void {
    var testSelected: boolean = false;
    for (var i = 0; i < this.testGroupSelectableItems.length; i++) {
      if (this.testGroupSelectableItems[i].Selected) {
        testSelected = true;
      }
    }
    if (testSelected) {
      Object.keys(this.formGroup.controls).forEach((key) => {
        this.formGroup.get(key).markAsDirty();
      });

      let packageName: string;
      if (this.formGroup.valid) {
        this.packageToBeDeployed.DeployedBy =
          this.formGroup.controls["deployedBy"].value;
        this.packageToBeDeployed.DeployedDate = moment().unix();
        console.log(this.packageToBeDeployed);
        this.deployService
          .adminAddDeployPackages([this.packageToBeDeployed])
          .subscribe(
            (result) => {
              let envToDeploy =
                this.formGroup.controls["environmentsDeployTo"].value.split(
                  ","
                );
              _.forEach(envToDeploy, (e) => {
                let index = _.findLastIndex(
                  result[0].DeploymentHistories,
                  function (o) {
                    return o.Target == e;
                  }
                );
                if (index != null && index >= 0) {
                  let history = result[0].DeploymentHistories[index];
                  if (history.Result == "Failed") {
                    this.toastr.error(
                      "Error occurred while adding data.",
                      "Package Name " +
                        this.packageToBeDeployed.PackageName +
                        " Environment " +
                        history.Target +
                        " is " +
                        history.Result
                    );
                  } else {
                    this.toastr.success(
                      "Deploy Packages Data Saved Successfully.",
                      "Package Name- " +
                        this.packageToBeDeployed.PackageName +
                        " Environment- " +
                        history.Target
                    );
                  }
                }
              });

              this.activeModal.close(result[0]);

              // Execute Tests
              for (let testGroup of this.testGroupSelectableItems) {
                if (testGroup.Selected) {
                  //this.runTests(testGroup.Code, envToDeploy[0]);
                  this.runTests(testGroup, this.selectedEnv);
                }
              }
            },
            (error) => {
              this.toastr.error("Error occurred while adding data.", "Error");
            }
          );
      }
    } else {
      this.toastr.error(
        "Select at least one test group before deploying content",
        "Error",
        { positionClass: "toast-top-center" }
      );
    }
  }

  runTests(testGroup: SelectableItem, env: string): void {
    this.sharedService.globalTestRunStatus = "Test Run In Progress";

    let testRun: TestRun = {
      Environment: env,
      TestGroupIds: [testGroup.Code],
      TestCases: null,
    };

    this.testingService.executeTestRun(testRun).subscribe(
      (res) => {
        var passed: boolean = true;

        console.log(res);
        this.sharedService.incrementCallTracker();
        this.sharedService.globalTestResults = res;
        for (var i = 0; i < res.length; i++) {
          if (!res[i].Passed) {
            passed = false;
          }
        }
        if (passed) {
          this.toastr.success(`TestGroup executed successfully.`);
          this.sharedService.globalTestRunStatus = "Test Run Complete";
        } else {
          this.toastr.error("Error while executing tests.", "Error");
          this.sharedService.globalTestRunStatus =
            "ERROR: One or More Test Cases Failed, please review on the Run Test Groups tab of the Execute Rules page";
        }
      },
      (err) => {
        this.toastr.error("Error while executing tests.", "Error");
      }
    );
  }

  //runTests(testGroup: SelectableItem, env: string): void {
  //  this.sharedService.globalTestRunStatus = "Test Run In Progress";

  //  this.testingService.getTestCasesByTestGroupId(testGroup.Code).subscribe((res: TestCase[]) => {
  //    let testCases: TestCase[] = res;

  //    this.executionService.getToken(env).subscribe(tokenRes => {
  //      if (tokenRes) {
  //        this.executionService.executeTests(env, testCases).subscribe(res => {
  //          this.sharedService.incrementCallTracker();

  //          this.sharedService.globalTestResults = res;
  //          this.toastr.success(`TestGroup ${testGroup.VisibleDescription} executed successfully.`);
  //          this.sharedService.globalTestRunStatus = "Test Run Complete";

  //        },
  //          err => {
  //            this.toastr.error('Error while executing tests.', 'Error');
  //          });

  //      } else {
  //        this.toastr.error('Error while fetching token', 'Error');
  //      }
  //    });

  //  }, error => { this.toastr.error('Error occurred while fetching TestCases.'); }
  //  );
  //}

  // Private methods
  /**
   * To create form with form builder
   * */
  private createFormBuilder(): void {
    this.formGroup = this.fb.group({
      packageName: [{ value: "" }, [Validators.required]],
      environmentsDeployTo: [{ value: "" }, [Validators.required]],
      deployedBy: [{ value: "" }, [Validators.required]],
      deployDate: [{ value: "" }],
    });
  }

  /**
   * Set initial values of form elements
   * */
  private fillFormBuilder(): void {
    let todayDate = new Date();
    let date = moment({
      year: todayDate.getFullYear(),
      month: todayDate.getMonth(),
      day: todayDate.getDate(),
    });
    let unixValue = moment().unix();
    let packageNames = this.packageToBeDeployed.PackageName;
    let deployedTo: string = "";

    for (let key in this.packageToBeDeployed.EnvDeploymentStates) {
      let value = this.packageToBeDeployed.EnvDeploymentStates[key];
      if (value["ToBeDeployed"]) {
        deployedTo += key + ",";
      }
    }

    this.formGroup.patchValue({
      packageName: packageNames,
      environmentsDeployTo: deployedTo.slice(0, -1),
      deployedBy: this.userProfileService.UserProfile.UserName,
      deployDate: unixValue,
    });
  }

  private configureTestGroupGrid(): void {
    //this.initialRowDataLoad$ = [];
    this.testGroupGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: "normal",
      columnDefs: this.createTestGroupColumnDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: false,
      //paginationPageSize: 10,
      rowHeight: 30,
      enableColResize: true,
      onGridReady: () => {
        this.testGroupGridOptions.api.setRowData([]);
        //this.resultsGridOptions.api.setRowData(this.allResults);
        this.testGroupGridOptions.api.sizeColumnsToFit();
        this.createSelectableItemLists();
        //this.resultsGridOptions.columnApi.autoSizeAllColumns();
      },
      onRowClicked: (event) => {
        this.onRowSelected(event);
      },
      onFilterChanged: (event) => {},
      onSortChanged: (event) => {},
      onFilterModified: function (event) {
        //console.log(event);
      },
    };
  }

  private createTestGroupColumnDef(): any[] {
    return [
      {
        headerName: "",
        field: "Selected",
        maxWidth: 40,
        suppressFilter: true,
        cellRenderer: (params) => {
          //return `<input type='checkbox' ${params.value == true ? 'checked' : ''} />`;
          return `<input type='checkbox' data-action-type="Edit"  ${
            params.value ? "checked" : ""
          } />`;
        },
      },
      {
        headerName: "Test Group",
        field: "VisibleDescription",
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
    ];
  }

  private onRowSelected(event: any): void {
    //event.data.Selected = event.node.selected;

    if (event.node.selected) {
      if (event.data.Selected) {
        event.data.Selected = false;
      } else {
        event.data.Selected = true;
      }
      this.testGroupGridOptions.api.refreshCells();
    }
  }
}
