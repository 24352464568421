import { PolicyFormFillin } from "../../../policy-forms";

/**
 * CPM Form
 * */
export class CPMForm {

  public InternalFormNumber: string;

  public LineOfBusinessID: string;

  public FormName: string;

  public Edition: string;

  public ExternalFormNumber: string;

  public SortOrder: string;

  public AttachmentReason: string;

  public FormFillIns: PolicyFormFillin[];

}
