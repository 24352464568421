//Angular Imports 
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

//Internal Imports
import { MGDReportsComponent } from './components/MGDReports.component';

/**
  Form Routes
*/
export const routes: Routes = [
  {
    path: '', component: MGDReportsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})

export class MGDReportsRoutingModule {
}
