//Angular Imports
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

//Third Party Imports
import { GridOptions } from "ag-grid-community";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

//Internal Imports
import {} from "../../../framework";
import { forEach } from "lodash";
import { EXECUTION_ENGINE_LIST } from "../../../report/configs";
import { TestHarnessLog } from "../../../diagnostics/testSuites/testHarness/models/testHarness-log.model";
import { TestHarnessService } from "../../../diagnostics/testSuites/testHarness/services/testHarness.service";

@Component({
  providers: [TestHarnessService],
  selector: "testHarness-logs",
  templateUrl: "./testHarness-logs.component.html",
  styleUrls: ["./testHarness-logs.component.scss"],
})
export class TestHarnessLogs {
  resultsGridOptions: GridOptions;
  public dealNumber: string;
  public logList: TestHarnessLog[] = [];
  public selectedResult: TestHarnessLog;
  public envList: any[];
  public env: string;
  public callingSystem: string;

  /**
   * Constructor
   * @ignore
   */
  constructor(
    public activeModal: NgbActiveModal,
    private harnessService: TestHarnessService,
    private _modal: NgbModal,
    private toastr: ToastrService
  ) {
    this.envList = EXECUTION_ENGINE_LIST;
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
   */
  ngOnInit(): void {
    this.configureResultsGrid();
    this.env = "DEV1";
    this.callingSystem = "e2";
  }

  /**
   * NgDestroy
   * @ignore
   */
  ngOnDestroy(): void {}

  searchLog() {
    if (this.dealNumber != null && this.dealNumber != "") {
      this.harnessService.getJwt(this.env).subscribe((t) => {
        this.harnessService
          .searchLogs(this.dealNumber.trim(), this.env, this.callingSystem, t)
          .subscribe((res) => {
            this.logList = res;
            this.setRowData();
          });
      });
    }
  }

  selectLog() {
    if (this.selectedResult != null) {
      this.activeModal.close(this.selectedResult);
    }
  }

  onSelectCallingSystem(callingSystem: string) {
    this.callingSystem = callingSystem;
    document.getElementById("logIdLabel").innerHTML = `${this.GetLogIdBySystem(
      callingSystem
    )}: `;
  }

  private GetLogIdBySystem(callingSystem: string) {
    let logIdName = "";
    switch (callingSystem) {
      case "e2":
        logIdName = "Deal Number";
        break;
      case "MOL1":
        logIdName = "Submission Number";
        break;
      case "MPOL":
        logIdName = "Submission Number";
        break;
      case "Claims":
        logIdName = "Submission Number:";
        break;
      case "GhostPortal":
        logIdName = "Policy Number";
        break;
      case "Magic":
        logIdName = "Policy Number";
        break;
      case "Maverick":
        logIdName = "Policy Number";
        break;
    }
    return logIdName;
  }

  private configureResultsGrid(): void {
    //this.initialRowDataLoad$ = [];
    this.resultsGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: "normal",
      columnDefs: this.createResultsColumDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      paginationPageSize: 10,
      rowHeight: 30,
      enableColResize: true,
      onGridReady: () => {
        this.resultsGridOptions.api.setRowData([]);
        //this.resultsGridOptions.api.setRowData(this.allResults);
        this.resultsGridOptions.api.sizeColumnsToFit();
        //this.resultsGridOptions.columnApi.autoSizeAllColumns();
      },
      onRowClicked: (event) => {
        this.onResultsRowClick(event);
      },
      onFilterChanged: (event) => {},
      onSortChanged: (event) => {},
      onFilterModified: function (event) {
        console.log(event);
      },
    };
  }

  private onResultsRowClick(event: any): void {
    if (event.event.target !== undefined) {
      this.selectedResult = event.data;
    }
  }

  private createResultsColumDef(): any[] {
    return [
      {
        headerName: "Timestamp",
        field: "TimeStamp",
        sort: "desc",
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          let newDateA = new Date(valueA).getTime();
          let newDateB = new Date(valueB).getTime();
          return newDateA - newDateB;
        },
        cellRenderer: (data) => {
          return data.value ? new Date(data.value).toLocaleString() : "";
        },
      },
      {
        headerName: "Action Type",
        field: "ActionType",
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
      {
        headerName: "Has Error",
        field: "hasError",
        suppressSorting: true,
        filter: "booleanFilterComponent",
        cellRenderer: (params) => {
          return `<input type='checkbox' ${
            params.value ? "checked" : ""
          } disabled/>`;
        },
      },
    ];
  }

  private setRowData() {
    let cpmLogList: TestHarnessLog[] = [];
    for (var i = 0; i < this.logList.length; i++) {
      let json: TestHarnessLog = this.logList[i];
      cpmLogList.push(json);
    }
    console.log(cpmLogList);
    this.resultsGridOptions.api.setRowData(cpmLogList);
  }
}
