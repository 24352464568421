import { Component, HostBinding } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse, HttpHeaders } from '@angular/common/http'
import { ApplicationInsightsBaseComponent, AppInsightsService, DateFilterComponent, BooleanFilterComponent, DateTimePipe, SkippableInterceptorHeader } from '../../../framework';
import { ToastrService } from 'ngx-toastr';
import { ImportService } from '../../services';
import { EmailSetting } from '../../../ghostPortal/email/models/emailSetting.model';

@Component({
  selector: 'import-component',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent extends ApplicationInsightsBaseComponent {

  @HostBinding('style.width') width: string;


  public progress: number;
  public message: string;
  public columnData: EmailSetting[];
  constructor(private _appInsightsService: AppInsightsService, private importService: ImportService
    , private toastr: ToastrService, private http: HttpClient) {
    super(_appInsightsService);
    this.width = "100%";
  }


}

