<div class="component-container">
  <div class="component-heading-container">
    <div class="col-md-12">
      <div class="row" style="padding-left: 10px">
        <h3 class="component-heading-text" style="margin-top: 9px">
          ImageRight Folders
        </h3>
      </div>
    </div>
  </div>
  <div class="container-fluid" style="padding: 10px">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-5 fast_admin">
        <label class="label_heading">System:</label>
        <div class="selectdiv select_wrapinline">
          <label>
            <ng-select
              class="custom-select"
              [items]="systemList"
              [virtualScroll]="true"
              autofocus
              [(ngModel)]="selectedSystem"
              [clearable]="true"
              (change)="onSelectSystem($event)"
            >
            </ng-select>
          </label>
        </div>
      </div>
      <div class="col-md-5 fast_admin" *ngIf="this.systemIsSelected">
        <label class="label_heading">Folder:</label>
        <div class="selectdiv select_wrapinline">
          <label>
            <ng-select
              class="custom-select"
              [items]="systemFolderList"
              [virtualScroll]="true"
              bindLabel="FolderName"
              autofocus
              [(ngModel)]="selectedFolder"
              [clearable]="true"
              (change)="onSelectFolder($event)"
            >
            </ng-select>
          </label>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <input
          [(ngModel)]="newFolderName"
          placeholder="New Folder Name"
          class="vex-input"
          style="height: 30px; width: 250px; margin-top: 9px; margin-left: 80px"
          *ngIf="this.systemIsSelected"
        />

        <div
          class="btn_wrapper"
          style="padding-left: 10px; padding-top: 15px"
          *ngIf="this.systemIsSelected"
        >
          <button
            mat-raised-button
            class="btn-teal"
            (click)="addFolder($event)"
          >
            Add Folder For System
          </button>
        </div>
      </div>
      <div class="col-md-4">
        <div
          class="btn_wrapper"
          style="float: left; padding-top: 15px"
          *ngIf="this.systemIsSelected"
        >
          <button
            type="button"
            mat-raised-button
            class="btn-teal"
            (click)="saveFolder($event)"
          >
            Save Folder
          </button>
        </div>
        <div
          class="btn_wrapper"
          style="padding: 15px 30px; float: left"
          *ngIf="this.systemIsSelected"
        >
          <button
            type="button"
            mat-raised-button
            class="btn-clear-red"
            (click)="deleteFolder($event)"
          >
            Delete Folder
          </button>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
  <div class="container-fluid" style="padding: 10px" *ngIf="selectedFolder">
    <div ngbDropdown class="d-inline-block mrgrgt10">
      <button
        class="btn"
        style="background-color: var(--color-teal); border: none; color: #fff"
        id="dropdownBasic1"
        ngbDropdownToggle
      >
        Add Rule
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button class="dropdown-item" (click)="onAddRule('basic_attach')">
          Basic Attach
        </button>
        <button class="dropdown-item" (click)="onAddRule('basic_remove')">
          Basic Remove
        </button>
      </div>
    </div>
    <div id="policy-form-container" class="row">
      <!--<hr style="height:3px;background-color:lightgrey;width:100%" *ngIf="logSelected" />-->
      <div class="col-md-12">
        <div>
          <div *ngFor="let rule of selectedFolder.RuleSet.Rules">
            <app-form-page-rule
              [currentRule]="rule"
              (deleteRule)="onDeleteRule($event)"
              (saveRule)="onSaveRule($event)"
            ></app-form-page-rule>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
