//Angular Imports
import {
  Component,
  HostBinding,
  Input,
  EventEmitter,
  Output,
} from "@angular/core";

//Third Party Imports
import * as _ from "lodash";

//Internal Imports
import {
  ApplicationInsightsBaseComponent,
  AppInsightsService,
} from "../../../framework";
import { AdminForm, RuleSetup } from "../../models";
//import { ExecutionService, TestingService } from '../../services';

import "rxjs";
import { FormPageService } from "../..";
import { RuleEngineForm } from "../../models/ruleEngineForm.model";

/**
 * Forms Component
 */
@Component({
  selector: "app-form-page-rules-engine",
  templateUrl: "./form-page-rules-engine.component.html",
  styleUrls: ["./form-page-rules-engine.component.scss"],
})
export class FormPageRulesEngine extends ApplicationInsightsBaseComponent {
  @HostBinding("style.width") width: string;

  @Input() currentForm: AdminForm;

  public currentRuleSetup: RuleSetup;

  public selectedRuleSetup: RuleSetup;

  public ruleSetups: RuleSetup[] = [];

  public selectedForm: RuleEngineForm;

  public envList: string[] = [
    "DEV1",
    "DEV2",
    "QA1",
    "QA2",
    "UAT1",
    "UAT2",
    "PXUAT",
    "FIT",
    "MIRROR",
    "PROD",
  ];

  public formLOBS: string[];

  public selectedEnv: string;

  public formReady: boolean = false;

  public effectiveDate: number = 0;

  public expirationDate: number = 0;

  public submissionExpirationDate: number = 0;
  /**
   * Constructor
   * @ignore
   */
  constructor(
    private _appInsightsService: AppInsightsService,
    private formPageService: FormPageService
  ) {
    super(_appInsightsService);
    this.width = "100%";
  }

  ngOnInit(): void {
    var blankRuleSetup: RuleSetup;
    blankRuleSetup = new RuleSetup();
    blankRuleSetup.Name = "";
    this.ruleSetups = [];
    this.ruleSetups.push(blankRuleSetup);
    for (var i = 0; i < this.currentForm.RuleSetups.length; i++) {
      this.ruleSetups.push(this.currentForm.RuleSetups[i]);
    }
  }

  ngOnDestroy(): void {}

  ngOnChanges(): void {
    this.currentRuleSetup = this.currentForm.RuleSetups[0];
    this.formReady = false;
    this.selectedEnv = "";
    var blankRuleSetup: RuleSetup;
    blankRuleSetup = new RuleSetup();
    blankRuleSetup.Name = "";
    this.ruleSetups = [];
    this.ruleSetups.push(blankRuleSetup);
    for (var i = 0; i < this.currentForm.RuleSetups.length; i++) {
      this.ruleSetups.push(this.currentForm.RuleSetups[i]);
    }
    console.log(
      "Rules-Tab: ngOnChanges: FormNumber = " +
        this.currentForm.InternalFormNumber
    );
  }

  onChangeEnv(): void {
    this.formReady = false;
    this.selectedRuleSetup = this.ruleSetups[0];
  }

  onChangeRuleSetup(e: any): void {
    this.formReady = false;
    this.formPageService
      .getRuleEngineForm(
        this.currentForm.id,
        this.selectedRuleSetup.LOB[0],
        this.selectedEnv
      )
      .subscribe((res) => {
        console.log(res);
        this.selectedForm = res[0];
        this.effectiveDate = this.selectedForm.EffectiveDate;
        this.expirationDate = this.selectedForm.ExpirationDate;
        this.submissionExpirationDate =
          this.selectedForm.SubmissionExpirationDate;
        this.formReady = true;
      });
  }

  // Event raised from form-page-rule-setup-list, which passes the concatinated list of LOBs as the name
  onRuleSetupChanged(ruleSetupName: string) {
    this.currentRuleSetup = this.currentForm.RuleSetups.find(
      (x) => x.LOB.join(" - ") === ruleSetupName
    );
  }
}
