//Angular Imports 
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { MsalGuard } from '../msal/msal-guard.service';

//Internal Imports
import { AuthenticationResolver } from '../framework';
import { AuthGuard } from '../framework/guards/auth.guard';
import { DiagnosticsComponent } from './components/diagnosticsComponent/diagnostics.component';
import { DiagnosticsMainModule } from './diagnostics-main';
import { ExecutionModule } from './testSuites/execution/execution.module';
import { TestHarnessModule } from './testSuites/testHarness/testHarness.module';
import { MigrationModule } from './migration/migration.module';

export const routes: Routes = [
  {
    path: '', component: DiagnosticsComponent,
    resolve: {
      token: AuthenticationResolver
    },
    children: [
      {
        // No AuthGuard on forms path because we assume anyone can access this page in at least read-only mode
        path: 'main', canActivate: [MsalGuard],
        loadChildren: () => DiagnosticsMainModule
      },
      {
        path: 'docAttachTesting', canActivate: [MsalGuard],
        loadChildren: () => ExecutionModule
      },
      {
        path: 'docGenTesting', canActivate: [MsalGuard],
        loadChildren: () => TestHarnessModule
      },
      {
        path: 'migration',
        loadChildren: () => MigrationModule
      },
      {
        path: '**',
        redirectTo: 'main',
        pathMatch: 'full'
      }
    ]
  }
];

/**
  Main Layout Routing Module
*/
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthenticationResolver]
})

export class DiagnosticsRoutingModule {
}
