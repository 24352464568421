//Angular Imports
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { CompareRoutingModule } from "./compare.routing";
import { CompareService } from "./services/compare.service";
import { MatCardModule } from "@angular/material/card";
import { MatDividerModule } from "@angular/material/divider";
import { CompareComponent } from "./components/compare.component";
import { MatIconModule } from "@angular/material/icon";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatInputModule } from "@angular/material/input";
import { LayoutModule } from "../../../@vex/layout/layout.module";
import { ToolbarModule } from "../../../@vex/layout/toolbar/toolbar.module";
import { SidenavModule } from "../../../@vex/layout/sidenav/sidenav.module";
import { FooterModule } from "../../../@vex/layout/footer/footer.module";
import { ConfigPanelModule } from "../../../@vex/components/config-panel/config-panel.module";
import { SidebarModule } from "../../../@vex/components/sidebar/sidebar.module";
import { QuickpanelModule } from "../../../@vex/layout/quickpanel/quickpanel.module";

/**
  Forms Module
*/
@NgModule({
  declarations: [CompareComponent],
  exports : [CompareComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CompareRoutingModule,
    MatCardModule,
    MatDividerModule,
    MatIconModule,
    MatPaginatorModule,
    MatInputModule,
    LayoutModule,
    SidenavModule,
    ToolbarModule,
    FooterModule,
    ConfigPanelModule,
    SidebarModule,
    QuickpanelModule,
  ],
  providers: [CompareService],
})
export class CompareModule {}
