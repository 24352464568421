<div class="row" style="width: 100%; margin-left: 8px">
  <div class="col-7">
    <div class="card" style="height: 860px; box-shadow: 1px 1px 4px #000">
      <!-- Heading -->
      <div class="row">
        <div style="float: left">
          <h4
            class="card-title"
            style="
              text-align: left;
              margin-left: 30px;
              margin-top: 10px;
              font-size: 19px;
              border-bottom: 1px solid var(--color-orange);
            "
          >
            Master Form Attributes
          </h4>
        </div>

        <div
          class="btn_wrapper"
          style="
            margin-left: auto;
            margin-right: 0;
            margin-top: 10px;
            float: right;
          "
        >
          <button
            type="button"
            *ngIf="masterActionType === 'View' && FormsWritePermission"
            style="background-color: var(--color-teal)"
            mat-raised-button
            class="white-text btn-teal"
            (click)="onEditMaster()"
          >
            Edit
          </button>
          <button
            type="button"
            *ngIf="masterActionType === 'Edit' && FormsWritePermission"
            mat-raised-button
            class="white-text btn-teal mrgrgt10"
            (click)="onSaveMaster()"
          >
            Save
          </button>
          <button
            type="button"
            *ngIf="masterActionType === 'Edit'"
            style="background-color: var(--color-teal)"
            mat-raised-button
            class="white-text btn-teal"
            (click)="onDiscardMaster()"
          >
            Discard
          </button>
        </div>
      </div>

      <!-- Content -->
      <div class="container-fluid" style="margin-top: 20px; padding: 0">
        <div class="row" style="margin: 0">
          <div class="col-md-5" style="padding: 0">
            <div
              id="lob-grid-container"
              class="col-md-12"
              style="padding-bottom: 10px"
              *ngIf="masterActionType === 'Edit'"
            >
              <div
                style="
                  padding-bottom: 5px;
                  vertical-align: middle;
                  align-content: center;
                  align-items: center;
                  display: grid;
                "
              >
                <span class="error_msg">
                  <i class="error_icon"></i>Before removing a LOB, please expire
                  this form/LOB, see Confluence page
                  <a
                    target="_blank"
                    href="https://confluence.markelcorp.com/display/ITFO/Removing+LOBs+From+Forms"
                    >here for context</a
                  >
                </span>
                <b>Lines of Business</b>
              </div>

              <ag-grid-angular
                class="ag-theme-balham fast_panel_table grid-box"
                [gridOptions]="lobGridOptions"
              >
              </ag-grid-angular>
            </div>

            <div
              id="lob-list-container"
              class="col-12"
              style="padding-bottom: 10px"
              *ngIf="masterActionType !== 'Edit'"
            >
              <div style="padding-bottom: 5px"><b>Lines of Business</b></div>
              <ul class="list-group">
                <button
                  type="button"
                  class="list-group-item list-group-item-action"
                  *ngFor="let lob of lobList"
                >
                  {{ lob }}
                </button>
              </ul>
            </div>
          </div>

          <div class="col-md-7" style="padding: 0">
            <app-form-page-attributes-container
              [attributeSet]="masterAttributeSet"
              [currentForm]="currentForm"
              [formTypeList]="formTypeList"
              [actionType]="masterActionType"
              #masterAttributeSetComponent
            >
            </app-form-page-attributes-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- LOB Specific section-->
  <div class="col-5">
    <div class="card" style="height: 860px; box-shadow: 1px 1px 4px #000">
      <!-- Heading -->
      <div class="row">
        <h4
          class="card-title"
          style="
            text-align: Left;
            float: left;
            margin-left: 30px;
            margin-top: 10px;
            font-size: 19px;
            border-bottom: 1px solid var(--color-orange);
          "
        >
          LOB Attributes
        </h4>

        <div
          class="btn_wrapper"
          style="
            margin-left: auto;
            margin-right: 0;
            float: right;
            margin-top: 10px;
          "
        >
          <button
            type="button"
            *ngIf="
              lobActionType === 'View' && selectedLob && FormsWritePermission
            "
            mat-raised-button
            class="btn-teal"
            (click)="onEditLOB()"
          >
            Edit
          </button>
          <button
            type="button"
            *ngIf="lobActionType === 'Edit' && FormsWritePermission"
            mat-raised-button
            class="btn-teal mrgrgt10"
            (click)="onSaveLOB()"
          >
            Save
          </button>
          <button
            type="button"
            *ngIf="lobActionType === 'Edit' && FormsWritePermission"
            mat-raised-button
            class="btn-teal"
            (click)="onDiscardLOB()"
          >
            Discard
          </button>
        </div>
      </div>

      <!-- LOB List -->
      <div class="row" style="margin-top: 20px">
        <div class="mrgbtm10 fast_admin" style="width: 100%; margin-left: 36px">
          <label
            style="font-weight: bold; font-size: 15px"
            class="label_heading"
            >Line of Business:</label
          >
          <div class="selectdiv select_wrapinline">
            <label>
              <ng-select
                id="activeLobList"
                class="custom-select"
                style="width: 100%"
                [items]="lobList"
                bindLabel=""
                bindValue=""
                [(ngModel)]="selectedLob"
                (change)="onSelectLob()"
              >
              </ng-select>
            </label>
          </div>
        </div>
      </div>

      <!-- Content -->

      <div class="row">
        <div style="width: 100%; margin-left: 25px; margin-top: 20px">
          <app-form-page-attributes-container
            [attributeSet]="currentLobAttributeSet"
            [currentForm]="currentForm"
            [formTypeList]="formTypeList"
            [actionType]="lobActionType"
            #lobAttributeSetComponent
          >
          </app-form-page-attributes-container>
        </div>
      </div>
    </div>
  </div>
</div>
