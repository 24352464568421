<div class="col-md-12">
  <div class="row">
    <div
      id="coverage-grid-container"
      class="col-12"
      style="padding-bottom: 10px"
    >
      <div style="padding-bottom: 5px"><b>Expected Results</b></div>
      <ag-grid-angular
        class="ag-theme-balham fast_panel_table"
        [gridOptions]="summaryGridOptions"
        style="height: 125px; width: 490px"
      >
      </ag-grid-angular>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <div id="coverage-grid-container">
        <div style="padding-bottom: 5px">
          <b>Mandatory Forms: {{ this.mandatoryFormCount }}</b>
        </div>
        <ag-grid-angular
          class="ag-theme-balham fast_panel_table"
          [gridOptions]="mandatoryGridOptions"
          style="height: 290px; width: 230px"
        >
        </ag-grid-angular>
      </div>
    </div>

    <div class="col-md-6">
      <div id="coverage-grid-container" style="padding-left: 30px">
        <div style="padding-bottom: 5px">
          <b>Optional Forms: {{ this.optionalFormCount }}</b>
        </div>
        <ag-grid-angular
          class="ag-theme-balham fast_panel_table"
          [gridOptions]="optionalGridOptions"
          style="height: 290px; width: 230px"
        >
        </ag-grid-angular>
      </div>
    </div>
  </div>
</div>
