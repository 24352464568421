//Angular Imports
import { Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, ActivatedRoute } from '@angular/router';

//Internal Imports 
import { SharedService, EnvironmentService, EnvironmentSpecifics } from './framework';
import { MainLayoutService, MainLayoutMessage, MenuItemSelection } from './main-layout';
import { MainLayoutMessageTypeEnums } from './main-layout/enums';
//import { environment } from '../environment';

import { MsalService } from "./msal";
import { GhostPortalMessage, GhostPortalService } from './ghostPortal';
import { GhostPortalMessageTypeEnums } from './ghostPortal/enums';
import { DiagnosticsMessage, DiagnosticsService } from './diagnostics';
import { DiagnosticsMessageTypeEnums } from './diagnostics/enums';
import { MatIconRegistry, SafeResourceUrlWithIconOptions } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { VexConfigName } from '../@vex/config/config-name.model';
import { ColorSchemeName } from '../@vex/config/colorSchemeName';
import { ConfigService } from '../@vex/config/config.service';
import { ColorVariable, colorVariables } from '../@vex/components/config-panel/color-variables';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { NavigationService } from '../@vex/services/navigation.service';
import { Settings } from 'luxon';


/**
  App Component
*/
@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})

export class AppComponent {

  /**
   * Constructor
   * @ignore
  */
  constructor(private router: Router, private sharedService: SharedService,
    private mainLayoutService: MainLayoutService,
    private ghostPortalService: GhostPortalService,
    private diagnosticsService: DiagnosticsService,
    private envService: EnvironmentService,
    private msalService: MsalService,
    private route: ActivatedRoute,
    private configService: ConfigService,
    private splashScreenService: SplashScreenService,
    private navigationService: NavigationService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer) {
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }
    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case 'logo':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/logos/${name}.svg`
            );

          case 'flag':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );
        }
      }
    );
    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.subscribe(queryParamMap => {
      if (queryParamMap.has('layout')) {
        this.configService.setConfig(queryParamMap.get('layout') as VexConfigName);
      }

      if (queryParamMap.has('style')) {
        this.configService.updateConfig({
          style: {
            colorScheme: queryParamMap.get('style') as ColorSchemeName
          }
        });
      }

      if (queryParamMap.has('primaryColor')) {
        const color: ColorVariable = colorVariables[queryParamMap.get('primaryColor')];

        if (color) {
          this.configService.updateConfig({
            style: {
              colors: {
                primary: color
              }
            }
          });
        }
      }

      if (queryParamMap.has('rtl')) {
        this.configService.updateConfig({
          direction: coerceBooleanProperty(queryParamMap.get('rtl')) ? 'rtl' : 'ltr'
        });
      }
    });
    this.navigationService.items = [
      {
        type: 'subheading',
        label: 'Dashboards',
        children: [
          {
            type: 'link',
            label: 'Home',
            route: '/splash/main',
            icon: 'mat:home',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: 'Document Attachment',
            route: '/fast/formBuilder',
            icon: 'mat:straighten',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: 'Document Generation',
            route: '/ghostPortal/forms',
            icon: 'mat:layers',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: 'mData Import Queue',
            route: '/mdo/import-queue',
            icon: 'mat:insert_chart',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: 'Insured Identity Mgmt',
            route: '/data-management/insured-identity',
            icon: 'mat:account_circle',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: 'Insight Hub AI',
            route: '/splash/search',
            icon: 'mat:psychology',
            routerLinkActiveOptions: { exact: true }
          }
        ]
      }
    ];
  }

  

  //Angular Lifecycles
  /**
   * ngOnInit
   * @ignore
  */
  ngOnInit(): void {
    this.listenToRouterEvents();
  }
  
  /**
   * ngOnDestroy
   * @ignore
  */
  ngOnDestroy(): void {
    this.msalService.logout();
  }


  //Private Methods
  /**
  * Listens to router events 
  * 
  * @returns void
  */
  private listenToRouterEvents(): void {
    
    //Use the router to subscribe to route change and show the loader 
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.sharedService.showLoader();
      }
      if (event instanceof NavigationEnd) {
        console.log(event);
        if (event.url.includes('ghostPortal')) {
          let menuItemSelection: MenuItemSelection = { Route: event.urlAfterRedirects, NavigateToRoute: false };
          let message: GhostPortalMessage = { Type: GhostPortalMessageTypeEnums.MenuItemSelection, Data: menuItemSelection };
          this.ghostPortalService.sendGhostPortalMessage(message);
          this.sharedService.hideLoader();
        }
        else if (event.url.includes('diagnostics')) {
          let menuItemSelection: MenuItemSelection = { Route: event.urlAfterRedirects, NavigateToRoute: false };
          let message: DiagnosticsMessage = { Type: DiagnosticsMessageTypeEnums.MenuItemSelection, Data: menuItemSelection };
          this.diagnosticsService.sendDiagnosticsMessage(message);
          this.sharedService.hideLoader();
        }
        else {
          let menuItemSelection: MenuItemSelection = { Route: event.urlAfterRedirects, NavigateToRoute: false };
          let message: MainLayoutMessage = { Type: MainLayoutMessageTypeEnums.MenuItemSelection, Data: menuItemSelection };
          this.mainLayoutService.sendMainLayoutMessage(message);
          this.sharedService.hideLoader();
        }

      }
      if (event instanceof NavigationCancel) {
        this.sharedService.hideLoader();
      }
      if (event instanceof NavigationError) {
        this.sharedService.hideLoader();
      }
    });


  }

  

}
