//Angular Imports 
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { ImportComponent } from './components';


//Internal Imports

/**
  Deploy Routes
*/
export const importRoutes: Routes = [
  {
    path: '', component: ImportComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(importRoutes)],
  exports: [RouterModule],
  providers: []
})

export class ImportRoutingModule {
}
