import { Observable, of, throwError, BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { catchError, map } from 'rxjs/operators';

import { FilterData } from "../models/filter-data";
import { Transaction } from "../models/transaction";
import { Job } from "../models/job";
import { UpdatePayload } from "../models/update-payload";
import { JobUpdatePayload } from '../models/job-update-payload';
import { PartnerHealthFilter } from "../models/partner-health-filter"

import { formatDate } from "@angular/common";

import { SkippableInterceptorHeader } from "../../framework/enums/skippable-interceptor-header.enum";
import { UserProfileService, EnvironmentService, ApplicationRole } from '../../framework';
import { Authorization } from '../models/authorization';
import { MockDataService } from "./mock-data.service";
import { StagingFile } from "../models/staging-file";
import { StagingFileUpdatePayload } from "../models/staging-file-update-payload";
import { PartnerProductAudit } from "../models/partner-product-audit";
import { PartnerProductUpdatePayload } from "../models/partner-product-update-payload";

@Injectable()
export class ImportQueueService {

  firstRun: boolean = true;
  stateData: any = {
    authorizations: {
      requestAction: new Authorization([ApplicationRole.MDataEdit, ApplicationRole.MDataAdmin]),
      addNote: new Authorization([ApplicationRole.MDataEdit, ApplicationRole.MDataAdmin]),
      uploadJobFiles: new Authorization([ApplicationRole.MDataEdit, ApplicationRole.MDataAdmin])
    },
    filterOptions: {
      Transaction: {},
      Job: {},
      Staging: {}
    },
    jobUploadOptions: {},
    transactionRecords: [],
    jobRecords: [],
    stagingFileRecords: [],
    transactions: {},
    jobs: {},
    stagingFiles: {},
    jobIdArrayFilter: []
  };

  authorizedRoles: ApplicationRole[] = [ApplicationRole.MDataEdit, ApplicationRole.MDataAdmin];

  private partnerHealthFilter: BehaviorSubject<PartnerHealthFilter> = new BehaviorSubject<PartnerHealthFilter>(new PartnerHealthFilter({ active: false }));

  constructor(private http: HttpClient, private mockDataService: MockDataService, private userProfileService: UserProfileService, private environmentService: EnvironmentService ) { }

  isInitialQueueLoad(): boolean {
    return this.firstRun
  }

  isAuthorized(context: string): boolean {
    if (this.isDevelopmentEnvironment()) {
      return true;
    }

    if (!this.stateData.authorizations[context]) {
      console.log("Error reported: authorization configuration for '" + context + "' context does not exist.");
      return false;
    }
    let authorization = this.stateData.authorizations[context];
    if (authorization.checked) {
      return authorization.isAuthorized;
    } else {
      authorization.setAuthorization(this.userProfileService.UserProfile.Roles);
      return authorization.isAuthorized;
    }

  }

  getCurrentEnvironment(): string {
    return this.environmentService.environmentDetails.Environment;
  }

  isDevelopmentEnvironment(): boolean {
    let env = this.environmentService.environmentDetails.Environment;
    return ([ "USDEVLOCAL", "LOCAL", "DEV1", "DEV2", "QA1", "QA2"].indexOf( env ) > -1 )
  }

  isNonProdEnvironment(): boolean {
    let env = this.environmentService.environmentDetails.Environment;
    return (["USDEVLOCAL", "LOCAL", "DEV1", "DEV2", "QA1", "QA2", "UAT1", "UAT2", "FIT"].indexOf(env) > -1)
  }

  formatTimestamp(dateValue: any): string {
    if (!dateValue) {
      return 'NA';
    }
    if (isNaN(Date.parse(dateValue))) {
      return (dateValue + " UTC");
    }
    //Remove any Z at the end of the date string so it won't be converted to local time.
    let staticDate = dateValue.replace(/Z$/, "");
    let date = new Date(Date.parse(staticDate));
    return (formatDate(date, 'MMM d, y h:mm:ss a', 'en-US') + " UTC");
  }

  dateComparator(date1, date2): number {
    // Converts parseable dates to milliseconds from 1/1/1970 for sorting
    let msDate1 = Date.parse(date1.replace(/\sUTC/, ""));
    let msDate2 = Date.parse(date2.replace(/\sUTC/, ""));

    if (isNaN(msDate1) && isNaN(msDate2)) {
      return 0;
    }

    if (isNaN(msDate1)) {
      return -1
    }

    if (isNaN(msDate2)) {
      return 1
    }

    return msDate1 - msDate2;
  }

  handleHTTPError(error: HttpErrorResponse) {
    return throwError(error);
  }

  generateRequestHeaders( mockHeaderValue?: string ): HttpHeaders {
    let headers = new HttpHeaders();
    // Because HttpHeaders is immutable, any action to add or alter a header returns a new instance that has to be reassigned to the variable
    headers = headers.set(SkippableInterceptorHeader.ShowLoaderSkipHeader, "true");
    if (mockHeaderValue) {
      headers = headers.set("x-mock-response", mockHeaderValue);
    }
    return headers;
  }

  getFilterOptions(filterContext: string): Observable<any> {
    // Only perform an HTTP call if we don't already have the filter options
    if (Object.keys(this.stateData.filterOptions[filterContext]).length == 0) {
      let getUrl = 'api/queue/filter-options?FilterBy=' + filterContext;
      // The x-mock-response header can be used to request a simulated response for testing purposes
      return this.http.get<any>(getUrl, { observe: "response", headers: this.generateRequestHeaders() })
        .pipe(
          map(res => {
            // Handle a success response
            this.stateData.filterOptions[filterContext] = res.body;
            return res.body;
          }),
          // Handle an error response
          catchError(this.handleHTTPError)
        );
    } else {
      // Return an observable of the cached filter option data
      return of(this.stateData.filterOptions[filterContext]);
    }
    
  }

  getTransactionData(filterData: FilterData): Observable<any> {
    let postUrl = 'api/queue/transaction';
    return this.http.post<any>(postUrl, filterData, { observe: "response", headers: this.generateRequestHeaders() })
      .pipe(
        map(res => {
          // Handle a success response.
          res.body.records = this.performTransactionDataResponseFiltering(res.body.records);
          this.stateData.transactionRecords = res.body.records
          return res;
        }),
        // Handle a error response
        catchError(this.handleHTTPError)
      );
  }

  getJobData(filterData: FilterData): Observable<any> {
    let postUrl = 'api/queue/job';
    return this.http.post<any>(postUrl, filterData, { observe: "response", headers: this.generateRequestHeaders() })
      .pipe(
        map(res => {
          // Handle a success response.
          this.stateData.jobRecords = res.body.records;
          return res;
        }),
        // Handle a error response
        catchError(this.handleHTTPError)
      );
  }

  performTransactionDataResponseFiltering(records: any[]) {
    if ( !this.stateData.jobIdArrayFilter.length ) {
      return records;
    }

    let filteredRecords = [];
    records.forEach((record: any) => {
      if (this.stateData.jobIdArrayFilter.indexOf( record.jobId ) > -1) {
        filteredRecords.push(record);
      }
    });
    this.stateData.jobIdArrayFilter = []; // Must be cleared each time 
    return filteredRecords;
  }

  getTransactionDetails(transactionId: string): Observable<any> {
    if (this.stateData.transactions[transactionId] && this.stateData.transactions[transactionId].isCurrent()) {
      return of(this.stateData.transactions[transactionId]);
    } else {
      let getUrl = 'api/queue/transaction/' + transactionId;
      return this.http.get<any>(getUrl, { observe: "response", headers: this.generateRequestHeaders() })
        .pipe(
          map(res => {
            // Handle a success response
            this.stateData.transactions[transactionId] = new Transaction(res.body);
            return new Transaction(res.body);
          }),
          // Handle an error response
          catchError(this.handleHTTPError)
        );
    }
  }

  getJobDetails(jobId: string): Observable<any> {
    if (this.stateData.jobs[jobId] && this.stateData.jobs[jobId].isCurrent()) {
      return of(this.stateData.jobs[jobId]);
    } else {
      let getUrl = 'api/queue/job/' + jobId;
      return this.http.get<any>(getUrl, { observe: "response", headers: this.generateRequestHeaders() })
        .pipe(
          map(res => {
            // Handle a success response
            this.stateData.jobs[jobId] = new Job(res.body);
            return new Job(res.body);
          }),
          // Handle an error response
          catchError(this.handleHTTPError)
        );
    }
  }

  getJobFiles(jobId: string): Observable<any> {
    let getUrl = 'api/queue/job/' + jobId + '/files';
    return this.http.get(getUrl, { observe: "response", headers: this.generateRequestHeaders() })
      .pipe(
        map(res => {
          // Handle a success response
          return res;
        }),
        // Handle an error response
        catchError(this.handleHTTPError)
      );
  }

  generateDownloadedZipFile(filename: string, byteData: any): boolean {
    // Bit of a hack to determine if the download attempt failed based on byte size
    if (byteData.length < 400) {
      return false;
    }

    let byteCharacters = atob(byteData);
    let byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let blobObj = new Blob([byteArray], { type: "application/zip" });

    let a = document.createElement('a');
    a.download = filename;
    let URL = window.URL.createObjectURL(blobObj);
    a.href = URL;
    a.click();
    window.URL.revokeObjectURL(URL);

    return true;
  }

  getTransactionModel(transactionId: string): Observable<any> {
    let getUrl = 'api/queue/transaction/' + transactionId + '/model';
    return this.http.get<any>(getUrl, { observe: "response", headers: this.generateRequestHeaders() })
      .pipe(
        map(res => {
          // Handle a success response
          let modelArray = res.body instanceof Array ? res.body : [res.body];
          this.stateData.transactions[transactionId].setModel(modelArray);
          return modelArray;
        }),
        // Handle an error response
        catchError(this.handleHTTPError)
      );
  }

  updateTransactions(updatePayload: UpdatePayload, context: string): Observable<any> {
    let postUrl: string;
    if (this.isAuthorized('requestAction')) {
      postUrl = 'api/queue/update-transactions';
    } else {
      // In the unlikely event someone has hacked the UI to allow them to execute this method, this will block their effort.
      postUrl = '';
    }

    return this.http.post<any>(postUrl, updatePayload, { observe: "response", headers: this.generateRequestHeaders() })
      .pipe(
        map(res => {
          this.updateTransactionStateData(updatePayload);
          return res;
        }),
        // Handle a error response
        catchError(this.handleHTTPError)
      );
    
  }

  updateJobs(jobUpdatePayload: JobUpdatePayload, context: string): Observable<any> {
    let postUrl: string;
    if (this.isAuthorized('requestAction')) {
      postUrl = 'api/queue/update-jobs';
    } else {
      // In the unlikely event someone has hacked the UI to allow them to execute this method, this will block their effort.
      postUrl = '';
    }

    return this.http.post<any>(postUrl, jobUpdatePayload, { observe: "response", headers: this.generateRequestHeaders() })
      .pipe(
        map(res => {
          this.updateJobStateData(jobUpdatePayload);
          return res;
        }),
        // Handle a error response
        catchError(this.handleHTTPError)
      );
   
  }

  getJobUploadOptions(): Observable<any> {
    if (Object.keys(this.stateData.jobUploadOptions).length ) {
      return of(this.stateData.jobUploadOptions);
    } else {
      let getUrl = 'api/queue/job/upload-options';
      return this.http.get<any>(getUrl, { observe: "response", headers: this.generateRequestHeaders() })
        .pipe(
          map(res => {
            // Handle a success response
            this.stateData.jobUploadOptions = this.parseJobUploadOptions(res.body);
            return this.stateData.jobUploadOptions;
          }),
          // Handle an error response
          catchError(this.handleHTTPError)
        );
    }
  }

  // We perform the work of transforming the data here in the service because we cache the result
  parseJobUploadOptions(products: any): any {
    let jobOptionData = { partners: [] };
    for (let p = 0; p < products.length; p++) {
      let prod = products[p];
      if (!jobOptionData[prod.partnerId]) {
        jobOptionData.partners.push(prod.partnerId);
        jobOptionData[prod.partnerId] = {
          products: []
        }
      }
      if (jobOptionData[prod.partnerId].products.indexOf(prod.productId) == -1) {
        jobOptionData[prod.partnerId].products.push({
          productId: prod.productId,
          productName: prod.productName
        });
        jobOptionData[prod.partnerId][prod.productId] = {
          name: prod.productName,
          filenames: prod.fileNamePatterns.sort(),
          fileExtensions: prod.fileExtensions.sort()
        }

        jobOptionData[prod.partnerId].products.sort(function (a, b) {
          return a.productName.toUpperCase() < b.productName.toUpperCase() ? -1 : 1;
        });

      }       
    }

    jobOptionData.partners.sort();
    return jobOptionData;
  }

  uploadJobFile(uploadPayload: FormData ): Observable<any> {
    let postUrl: string;
    if (this.isAuthorized('uploadJobFiles')) {

      if (uploadPayload.get("uploadToStage")) {
        postUrl = 'api/queue/mdo-fileupload-staging-file';
      } else {
        postUrl = 'api/queue/mdo-fileupload-job';
      }
 
    } else {
      // In the unlikely event someone has hacked the UI to allow them to execute this method, this will block their effort.
      postUrl = '';
    }

    //Add username
    uploadPayload.append("userName", this.userProfileService.UserName );

    return this.http.post<any>(postUrl, uploadPayload, { observe: "response", headers: this.generateRequestHeaders() })
      .pipe(
        map(res => {
          return res.body;
        }),
        // Handle a error response
        catchError(this.handleHTTPError)
      );

  }


  addActionHistory(updatePayload: UpdatePayload, jobId: string): any {
    return {
      status: updatePayload.action || "Note",
      date: new Date().toISOString(), // Returns ISO UTC timestamp
      user: updatePayload.user,
      jobId: jobId.toUpperCase(),
      type: "UserAction",
      note: updatePayload.notes
    }
  }

  addJobActionHistory(jobUpdatePayload: JobUpdatePayload, jobId: string): any {
    return {
      status: jobUpdatePayload.action || "Note",
      date: new Date().toISOString(), // Returns ISO UTC timestamp
      user: jobUpdatePayload.user,
      type: "UserAction",
      note: jobUpdatePayload.notes
    }
  }

  

  updateTransactionStateData(updatePayload: UpdatePayload): void {
   
    // Even if we are only updating a single transaction via the detail page, we still need to update the corresponding record in the summary data
    for (let t = 0; t < this.stateData.transactionRecords.length; t++) {
      let transaction = this.stateData.transactionRecords[t];
      if (updatePayload.transactionIds.indexOf(transaction.transactionId) > -1) {
        if (updatePayload.action) {
          let cleanStatus = transaction.status.replace(/\s?\([A-Za-z\s]*\)/, "");
          transaction.status = cleanStatus + " (" + updatePayload.action + ")";
        }       
        if (this.stateData.transactions[transaction.transactionId]) {
          this.stateData.transactions[transaction.transactionId].actionHistory.push(this.addActionHistory(updatePayload, this.stateData.transactions[transaction.transactionId].jobId ) );
          this.stateData.transactions[transaction.transactionId].appendToStatusAndExpire(updatePayload.action);
        }
      }
    }
        
  }

  updateJobStateData(jobUpdatePayload: JobUpdatePayload): void {
    // Even if we are only updating a single job via the detail page, we still need to update the corresponding record in the summary data
    for (let j = 0; j < this.stateData.jobRecords.length; j++) {
      let job = this.stateData.jobRecords[j];    
      if (jobUpdatePayload.jobIds.indexOf(job.JobId) > -1) {
        if (jobUpdatePayload.action) {
          let cleanStatus = job.Status.replace(/\s?\([A-Za-z\s]*\)/, "");
          job.Status = cleanStatus + " (" + jobUpdatePayload.action + ")";
        }
        if (this.stateData.jobs[job.JobId]) {
          this.stateData.jobs[job.JobId].actionHistory.push(this.addJobActionHistory(jobUpdatePayload, job.JobId));
          this.stateData.jobs[job.JobId].appendToStatusAndExpire(jobUpdatePayload.action);
        }
      }
    }

  }

  returnCachedTransactionRecords(): any[] {
    return this.stateData.transactionRecords;
  }

  returnCachedJobRecords(): any[] {
    return this.stateData.jobRecords;
  }

  returnCachedStagingFileRecords(): any[] {
    return this.stateData.stagingFileRecords;
  }

  getHealthMeterData(): Observable<any> {
    let getUrl = 'api/queue/health-metrics';
    return this.http.get<any>(getUrl, { observe: "response", headers: this.generateRequestHeaders() })
      .pipe(
        map(res => {
          // Handle a success response
          return res.body.Table;
        }),
        // Handle an error response
        catchError(this.handleHTTPError)
      );
  }

  getProblemStatuses(): string[] {
    return ["Data Error", "Failed", "On Hold", "Send Error"]
  }


  getChartData(): Observable<any> {
    let getUrl = 'api/queue/processing-metrics';
    return this.http.get<any>(getUrl, { observe: "response", headers: this.generateRequestHeaders() })
      .pipe(
        map(res => {
          // Handle a success response
          return this.sanitizeChartData(res.body);
        }),
        // Handle an error response
        catchError(this.handleHTTPError)
      );
  }

  // Sort the month-based data to make sure the months are in order
  sanitizeChartData( responsePayload: any ): any {
      
    let orderedMonths = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];
    let monthlyChartList = ['policyTransactionsPerMonth', 'claimTransactionsPerMonth', 'premiumPerPartnerYTD', 'amountpaidPerPartnerYTD'];

    for (let chart in monthlyChartList) {
      
      let key = monthlyChartList[chart];
      
      for (let partner in responsePayload[key]) {
        // The (upper/lower) case of the month property name is inconsistent, so check
        let monthProperty = responsePayload[key][partner]["DATA"][0].MONTH ? "MONTH" : "month";
        responsePayload[key][partner]["DATA"].sort(function (a, b) {
          return orderedMonths.indexOf(a[monthProperty].toLowerCase()) - orderedMonths.indexOf(b[monthProperty].toLowerCase());
        });
      }
    }

    return responsePayload;
  }

  getPartnerStats(): Observable<any> {
    let getUrl = 'api/queue/partner-metrics';
    return this.http.get<any>(getUrl, { observe: "response", headers: this.generateRequestHeaders() })
      .pipe(
        map(res => {
          // Handle a success response
          return res.body;
        }),
        // Handle an error response
        catchError(this.handleHTTPError)
      );
  }

  getPartnerProductAuditData(): Observable<any> {
    let getUrl = 'api/queue/partner-product-audit';
    return this.http.get<any>(getUrl, { observe: "response", headers: this.generateRequestHeaders() })
      .pipe(
        map(res => {
          // Handle a success response
          return res.body;
        }),
        // Handle an error response
        catchError(this.handleHTTPError)
      );
  }

  updatePartnerProduct(pp: PartnerProductAudit, username: string): Observable<any> {
    let postUrl: string;
    let postPayload: PartnerProductUpdatePayload = new PartnerProductUpdatePayload(pp);

    if (this.isAuthorized("uploadJobFiles")) {
      postUrl = 'api/queue/update-partner-product';
      postPayload.Key = "PauseJob";
      postPayload.Value = pp.pauseStatus == "Active" ? "true" : "false";
      postPayload.UpdatedBy = username
    } else {
      postUrl = "";
    }

    return this.http.post<any>(postUrl, postPayload, { observe: "response", headers: this.generateRequestHeaders() })
      .pipe(
        map(res => {
          pp.updateStatus(username);
          return {};
        }),
        // Handle a error response
        catchError(this.handleHTTPError)
      );
  }

  getEntityLogData(entityId: string): Observable<any> {
    let getUrl = 'api/queue/policy-claim-details/' + entityId;
    return this.http.get<any>(getUrl, { observe: "response", headers: this.generateRequestHeaders() })
      .pipe(
        map(res => {
          // Handle a success response
          return res.body;
        }),
        // Handle an error response
        catchError(this.handleHTTPError)
      );
  }

  getStagingFileData(filterData: FilterData): Observable<any> {
    let postUrl = 'api/queue/staging-files';
    return this.http.post<any>(postUrl, filterData, { observe: "response", headers: this.generateRequestHeaders() })
      .pipe(
        map(res => {
          // Handle a success response.
          this.stateData.stagingFileRecords = res.body.records;
          return res;
        }),
        // Handle a error response
        catchError(this.handleHTTPError)
      );

  }

  getStagingFileDetails(fileId: string, filename: string): Observable<any> {
    if (this.stateData.stagingFiles[fileId] && this.stateData.stagingFiles[fileId].isCurrent()) {
      return of(this.stateData.stagingFiles[fileId]);
    } else {
      let getUrl = 'api/queue/staging-file/' + fileId;
      return this.http.get<any>(getUrl, { observe: "response", headers: this.generateRequestHeaders() })
        .pipe(
          map(res => {
            // Handle a success response
            this.stateData.stagingFiles[fileId] = new StagingFile(res.body, filename);
            return new StagingFile(res.body, filename);
          }),
          // Handle an error response
          catchError(this.handleHTTPError)
        );
    }
  }

  updateStagingFiles(fileUpdatePayload: StagingFileUpdatePayload, context: string): Observable<any> {
    let postUrl: string;
    if (this.isAuthorized('requestAction')) {
      postUrl = 'api/queue/update-staging-files';
    } else {
      // In the unlikely event someone has hacked the UI to allow them to execute this method, this will block their effort.
      postUrl = '';
    }

    return this.http.post<any>(postUrl, fileUpdatePayload, { observe: "response", headers: this.generateRequestHeaders() })
      .pipe(
        map(res => {
          this.updateStagingFilesStateData(fileUpdatePayload);
          return res;
        }),
        // Handle a error response
        catchError(this.handleHTTPError)
      );

  }

  updateStagingFilesStateData(fileUpdatePayload: StagingFileUpdatePayload): void {
    // Even if we are only updating a single file via the detail page, we still need to update the corresponding record in the summary data
    for (let f = 0; f < this.stateData.stagingFileRecords.length; f++) {
      let stagingFile = this.stateData.stagingFileRecords[f];
      if (fileUpdatePayload.fileIds.indexOf(stagingFile.Id) > -1) {
        if (fileUpdatePayload.action) {
          let cleanStatus = stagingFile.Status.replace(/\s?\([A-Za-z\s]*\)/, "");
          stagingFile.Status = cleanStatus + " (" + fileUpdatePayload.action + ")";
        }
        if (this.stateData.stagingFiles[stagingFile.Id]) {
          this.stateData.stagingFiles[stagingFile.Id].notes.push(this.addStagingFileNoteHistory(fileUpdatePayload, stagingFile.FileId));
          this.stateData.stagingFiles[stagingFile.Id].appendToStatusAndExpire(fileUpdatePayload.action);
        }
      }
    }
  }

  addStagingFileNoteHistory(stagingFileUpdatePayload: StagingFileUpdatePayload, FileId: string): any {
    return {
      status: stagingFileUpdatePayload.action || "Success",
      date: new Date().toISOString(), // Returns ISO UTC timestamp
      user: stagingFileUpdatePayload.user,
      type: stagingFileUpdatePayload.action ? "UserAction" : "Notes",
      message: stagingFileUpdatePayload.notes
    }
  }

  getStagingFiles(fileId: string): Observable<any> {
    let getUrl = 'api/queue/staging-file/' + fileId + '/files';
    return this.http.get(getUrl, { observe: "response", headers: this.generateRequestHeaders() })
      .pipe(
        map(res => {
          // Handle a success response
          return res;
        }),
        // Handle an error response
        catchError(this.handleHTTPError)
      );
  }

  //Behavior bridges
  emitPartnerHealthFilter(partnerFilter: PartnerHealthFilter) {
    this.partnerHealthFilter.next(partnerFilter);
    this.stateData.jobIdArrayFilter = partnerFilter.jobIdArray;
  }

  partnerHealthFilterListener(): Observable<any> {  
    return this.partnerHealthFilter.asObservable();
  }
  
}
