import { formatDate } from "@angular/common";

export class Job {
  jobId: string;
  status: string;
  partner: string;
  product: string;
  productType: string;
  startTime: string;
  endTime: string;
  errorMessage: string;
  processLogs: any;
  actionHistory: any;
  cached: any;
  expired: boolean;

  constructor(objectData?: any) {
    if (objectData) {
      this.jobId = objectData.job[0].JobId || "";
      this.status = objectData.job[0].Status || "";
      this.partner = objectData.job[0].Partner || "";
      this.product = objectData.job[0].Product || "";
      this.productType = objectData.job[0].Type || "";
      this.startTime = this.formatTimestamp(objectData.job[0].JobStartTime);
      this.endTime = this.formatTimestamp(objectData.job[0].JobEndTime);
      this.processLogs = this.parseProcessLogs(objectData.logging);
      this.actionHistory = objectData.notes ? this.parseActionHistory(objectData.notes) : [];
      this.cached = new Date().getTime();
      this.expired = false;
    }
  }

  formatTimestamp(dateValue: any): string {
    if (!dateValue) {
      return 'NA';
    }
    if (isNaN(Date.parse(dateValue))) {
      return (dateValue + " UTC");
    }
    //Remove any Z at the end of the date string so it won't be converted to local time.
    let staticDate = dateValue.replace(/Z$/, "");
    let date = new Date(Date.parse(staticDate));
    return (formatDate(date, 'MMM d, y h:mm:ss a', 'en-US') + " UTC");
  }

  parseProcessLogs(logs: any[]): any[] {
    let processLogs = [];
    if (logs.length) {
      logs.forEach((item: any) => {
        processLogs.push({
          stepNumber: item.StepNumber,
          processingStep: item.processingStep,
          status: item.status,
          failureType: item.failureType,
          message: item.message,
          startTime: item.starttime
        });
      })
    }
    return processLogs;
  }

  parseActionHistory(rawNotes: any[]): any[] {
    let actionHistory = [];
    if (rawNotes.length) {
      rawNotes.forEach((item: any) => {
        actionHistory.push({
          status: item.Status,
          date: item.Date,
          user: item.User,
          type: item.Type,
          note: item["Note/Reason/Error"]
        });
      });
    }
    return actionHistory;
  }

  setStatusAndExpire(status: string): void {
    this.status = status;
    this.expired = true;
  }

  appendToStatusAndExpire(action: string): void {
    this.status += " (" + action + ")";
    this.expired = true;
  }

  isCurrent(): boolean {
    if (this.expired) {
      return false;
    }
    let currentTime = new Date().getTime()
    if (currentTime - this.cached > 10800000) { // Check if older than 3 hours  
      this.expired = true;
      return false;
    }
    return true;
  }
}
