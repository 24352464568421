//Angular Imports 
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

//Third Party Imports
import { Observable, Subscriber } from "rxjs";
import { map } from "rxjs/operators";

//Internal Imports
import { PrintOrderMasterViewModel } from "../models";

/**
  * Policy Forms Provider
*/
@Injectable()
export class PrintOrderService {
  
  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient) {
  }


  /**
   * Add or update print order in database
   * @param printOrderDetail
   */
  addUpdatePrintOrder(printOrderMasterViewModel: PrintOrderMasterViewModel): Observable<boolean> {
    let url = `api/PrintOrder`;
    return this.http.post<boolean>(url, printOrderMasterViewModel).pipe(
      map(res => res)
    );
  }

  /**
  * get print order from database
  * @param PrintOrder
  */
  getPrintOrders( lobName: string): Observable<PrintOrderMasterViewModel[]> {
    let url = `api/PrintOrder?lob=` + lobName;
    return this.http.get<PrintOrderMasterViewModel[]>(url).pipe(
      map(res => res)
    );
  }
}
