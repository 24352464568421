//Angular Imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

//Third Party Imports
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridModule } from "ag-grid-angular";
import { MyDatePickerModule } from 'mydatepicker';
//Internal Imports 
import { PolicyFormsRoutingModule } from './policy-forms.routing';
import { FrameworkModule, DateFilterComponent, BooleanFilterComponent } from '../framework';
import { UserNotesModule } from '../user-notes/user-notes.module';
import { FormsListService } from '../ghostPortal/formsList/services';
import { PackageService } from '../package/services';
import { PolicyFormsComponent } from './components/policy-forms-component';

/**
  Forms Module
*/
@NgModule({
  declarations: [PolicyFormsComponent],
  imports: [
    MyDatePickerModule,
    CommonModule,
    PolicyFormsRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    FrameworkModule,
    UserNotesModule,
    AgGridModule.withComponents([DateFilterComponent, BooleanFilterComponent])
  ],
  providers: [PackageService]
})
export class PolicyFormsModule { }
