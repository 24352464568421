import { ApplicationRole } from '../../framework';

export class Authorization {
  checked: boolean = false;
  authorizedRoles: ApplicationRole[];
  isAuthorized: false;

  constructor(authorizedRoles: ApplicationRole[]) {
    this.authorizedRoles = authorizedRoles;
  }

  setAuthorization(userProfileRoles: ApplicationRole[]): void {
    let authorized = false;
    this.authorizedRoles.forEach((appRole: ApplicationRole) => {
      if (userProfileRoles.indexOf(appRole) > -1) {
        authorized = true;
      }
    });
    this.checked = true;
    this.isAuthorized = authorized;
  }
}
