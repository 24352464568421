<div class="component-container">
  <div class="component-heading-container">
    <h3 class="component-heading-text">View Forms By LOB</h3>
  </div>
  <div class="component-content-view">
    <div class="container-fluid">
      <div id="policy-form-container" class="row">
        <div class="col-md-12">
          <div
            class="heading-container fast_admin"
            id="policy-form-heading-container"
          >
            <label class="label_heading">Line Of Business:</label>
            <div class="selectdiv select_wrapinline" style="width: 400px">
              <label>
                <ng-select
                  id="lineOfBusiness"
                  class="custom-select"
                  [items]="lineOfBusinessList"
                  bindLabel="Name"
                  bindValue="ID"
                  (change)="onLOBChange($event)"
                  [(ngModel)]="selectedLOB"
                >
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{ item.Name }}">{{ item.Name }}</div>
                  </ng-template>
                </ng-select>
              </label>
            </div>
            <label class="label_heading">Coverage:</label>
            <div class="selectdiv select_wrapinline" style="width: 400px">
              <label>
                <ng-select
                  id="lineOfBusiness"
                  class="custom-select"
                  [items]="coverageList"
                  bindLabel="Name"
                  bindValue="ID"
                  (change)="onCoverageChange($event)"
                  [(ngModel)]="selectedCoverage"
                >
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{ item.Name }}">
                      {{ item.ID }}: {{ item.Name }}
                    </div>
                  </ng-template>
                </ng-select>
              </label>
            </div>
            <div class="btn_wrapper" style="margin-left: auto; margin-right: 0">
              <button class="btn primary_btn btnlign" (click)="runQuery()">
                Export info to Spreadsheet
              </button>
            </div>
          </div>
        </div>
        <div id="policy-form-content-container" class="col-md-12 mrgbtm10">
          <ag-grid-angular
            class="ag-theme-balham fast_panel_table"
            [gridOptions]="formsGridOptions"
          >
          </ag-grid-angular>
        </div>
      </div>
    </div>
  </div>
</div>
