//Angular Imports 
import { Component, HostBinding } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

//Third Party Imports 
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxFileDropEntry } from 'ngx-file-drop';



//Internal Imports 

import { AppInsightsService, EnvironmentService, DateTimeUtcPipe, SharedService, ApplicationInsightsBaseComponent, CPMElement } from '../../../framework';
import { CompareService } from '../services/compare.service';
import { tap } from 'rxjs';
import { PDFCompareDocs } from '../models/pdfCompareDocs';


/**
 * Forms Component
*/
@Component({
  selector: 'app-compare',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.scss'],
  providers: [CompareService]
})

export class CompareComponent {

  @HostBinding('style.width') width: string;

  initialRowDataLoad$;
  http: any;
  datalocalURL: SafeResourceUrl;
  public pdfs: FormData = new FormData();
  public pdfDocStrings: PDFCompareDocs = new PDFCompareDocs();
  public files: NgxFileDropEntry[] = [];




  /** 
   * Constructor
   * @ignore
  */
  constructor(private toastr: ToastrService,
    private _modal: NgbModal,
    private compareService: CompareService,
    private sharedService: SharedService,
    private dateTimePipe: DateTimeUtcPipe,
    private environmentService: EnvironmentService,
    private domSanitizer: DomSanitizer
  ) {
    this.width = "100%";
  }

  showPDfboolean = false;

  getPdfFile(): void {
    //console.log(this.pdfs);
    this.compareService.compareDocs(this.pdfs).pipe(tap(byteArray => {
      console.log(byteArray.body);
      var r = new FileReader();
      r.readAsArrayBuffer(byteArray.body);
      r.onload = () => {
        const pdfBlob = new Blob([r.result], { type: 'application/pdf' });
        this.datalocalURL = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(pdfBlob));
        console.log(this.datalocalURL);
        this.showPDfboolean = true;
      }

    })).subscribe();
  }

  public results() {
    setTimeout(function () {
      document.querySelector("iframe").src;
    }, 100);
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
  */
  ngOnInit(): void {
  }

  /**
   * NgOnDestroy
   * @ignore
  */
  ngOnDestroy(): void {

  }

  onFileChangeA(event: any) {
    console.log(event);
    for (var file of event.target.files) {
      console.log(file);
      var fileName = (file.name);
      if (fileName.endsWith(".pdf")) {

        this.pdfs.append("files", file, fileName);

      }
      else {
        this.toastr.error("Invalid File Extension", "Error Uploading File", { positionClass: 'toast-bottom-right' });
      }
    }

  }

  onFileChangeB(event: any) {
    for (var file of event.target.files) {
      console.log(file);
      var fileName = String(file.name);
      if (fileName.endsWith(".pdf")) {

        this.pdfs.append("files", file, fileName);
      }
      else {
        this.toastr.error("Invalid File Extension", "Error Uploading File", { positionClass: 'toast-bottom-right' });
      }
    }

  }

  fileLeaveA(event) {
    console.log(event);
  }

  fileLeaveB(event) {
    console.log(event);
  }
}
