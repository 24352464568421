//Angular Imports 
import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';

//Third Party Imports
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

/**
 * Forms Component
*/
@Component({
  selector: 'errorLog',
  templateUrl: 'errorLog.component.html',
  styleUrls: ['errorLog.component.scss']
})

export class ErrorLog {

  @Input() errorText: string;



  constructor(public activeModal: NgbActiveModal,
    private _modal: NgbModal,
    private toastr: ToastrService) {
  }

  ngOnInit(): void {
    console.log(this.errorText);
  }

  /**
   * NgOnDestroy
   * @ignore
  */
  ngOnDestroy(): void {

  }

}
