export class TestHarnessTestGroup {
  public id: string;
  public Name: string;
  public Description: string;
  public Release: string;
  public CreationDate: number;
  public CreatedByName: string;
  public ModifiedDate: number;
  public ModifiedByName: string;
}
