//Angular Imports 
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

//Third Party Imports
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

/**
  * Forms Rules Mapping  Provider
*/
@Injectable()
export class ImportService {


  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient) {
  }


  /**
  * Add Deploy package forms in database
  * @param deployPackages
  */
  uploadFile(formdata: any): Observable<any> {
    let headers = new HttpHeaders();//.set(SkippableInterceptorHeader.RequestSkipHeader, 'true').set("Content-Type","multipart/form-data");
    //.set(SkippableInterceptorHeader.JWTSkipHeader, 'true');

    let url = `api/Import/UploadFiles`;
    return this.http.post<any>(url, formdata, { headers: headers, reportProgress: true }).pipe(
      map(res => {
        return res;
      }));
  }

}
