<div class="modal-header">
  <h4 class="modal-title">Log Info</h4>
</div>

<div class="modal-body col-12">
  <!-- Fields -->
  <div class="row">
    <div class="col-md-12 mrgbtm10">
      <label style="font-weight: bold">Note</label>
      <input
        id="txtName"
        class="vex-input"
        style="width: 100%"
        [(ngModel)]="Row.Note"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mrgbtm10">
      <label style="font-weight: bold">Folder</label>
      <input
        id="txtDescription"
        class="vex-input"
        style="width: 100%"
        [(ngModel)]="Row.Folder"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mrgbtm10">
      <label style="font-weight: bold">JIRA</label>
      <input
        id="txtDescription"
        class="vex-input"
        style="width: 100%"
        [(ngModel)]="Row.Jira"
      />
    </div>
  </div>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="mat_btn_hov_act mrgrgt10"
    mat-raised-button
    (click)="activeModal.close('Cancel')"
  >
    Cancel
  </button>
  <button
    type="button"
    mat-raised-button
    class="btn-teal"
    (click)="updateLog()"
  >
    Update
  </button>
</div>
