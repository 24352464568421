export class Transaction {
  transactionId: string;
  jobId: string;
  status: string;
  partner: string;
  productType: string;
  productTypeId: string;
  entityModelAvailable: boolean;
  errorMessage: string;
  model: any;
  logging: any;
  actionHistory: any;
  cached: any;
  expired: boolean;


  constructor(objectData?: any) {
    if (objectData) {
      this.transactionId = objectData.transaction[0].transactionId || "";
      this.jobId = objectData.transaction[0].jobId || "";
      this.status = objectData.transaction[0].status || "";
      this.partner = objectData.transaction[0].partner || "";
      this.productType = objectData.transaction[0].productType || "";
      this.productTypeId = objectData.transaction[0].productTypeId || "";
      this.entityModelAvailable = objectData.transaction[0].entityModelAvailable || false;
      this.model = objectData.transaction[0].model || "";
      this.logging = objectData.logging || {};
      this.actionHistory = this.parseActionHistory( objectData.actionHistory );
      this.cached = new Date().getTime();
      this.expired = false;
    }    
  }

  parseActionHistory( rawActionHistory: any[]): any[] {
    let actionHistory = [];
    if (rawActionHistory.length) {
      rawActionHistory.forEach((item: any) => {
        actionHistory.push({
          status: item.Status,
          date: item.Date,
          user: item.User,
          type: item.Type,
          jobId: item["Job ID"].toUpperCase(),
          transactionId: item["TransactionID"].toUpperCase(),
          note: item[ "Note/Reason/Error" ]
        });
      });
    }
    return actionHistory;
  }

  getModelType(): string {
    return this.productType == "Policy" ? "CPM" : "CCM(s)";
  }

  getModelAvailablity(): string {
    return this.entityModelAvailable ? "Available" : "Unavailable";
  }

  setModel(model: any): void {
    this.model = model;
  }

  isModelSet(): boolean {
    return this.model == "" ? false : true;
  }

  setStatusAndExpire(status: string): void {
    this.status = status;
    this.expired = true;
  }

  appendToStatusAndExpire(action: string): void {
    this.status += " (" + action + ")";
    this.expired = true;
  }

  isCurrent(): boolean {
    if (this.expired) {
      return false;
    }
    let currentTime = new Date().getTime()
    if (currentTime - this.cached > 10800000) { // Check if older than 3 hours  
      this.expired = true;
      return false;
    }
    return true;
  }

}
