import { Component, OnInit, Input } from '@angular/core';
import { Report } from '../../models/report';
import { ReportService } from '../../services/report-service'

@Component({
  selector: 'app-single-embedded-report',
  templateUrl: './single-report.component.html',
  styleUrls: ['./single-report.component.scss']
})

export class SingleReport implements OnInit {

  @Input() report: Report;
  environment: string;

  constructor( private reportService: ReportService ) { }

  ngOnInit(): void {
    this.environment = this.reportService.getCurrentEnvironment();
    this.report.setTargetInstance(this.environment.toLowerCase());
    if (this.report.targetInstance) {
      this.report.targetInstance.sanitizedUrl = this.reportService.generateSafeResourceUrl(this.report.targetInstance.url);
    }
  }

}
