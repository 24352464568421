<button id="update-job-action-btn" mat-raised-button class="mdo-standard-button" [matMenuTriggerFor]="updateJobActionMenu" [disabled]="!isAuthorized()">
  <span *ngIf="singleJob">
    Update <i class="fa fa-fw fa-caret-down"></i>
  </span>
  <span *ngIf="!singleJob">
    Update {{selectedJobCount}} Jobs(s) <i class="fa fa-fw fa-caret-down"></i>
  </span>
</button>
<mat-menu class="mdo-standard-button-dropdown" #updateJobActionMenu="matMenu">
  <button mat-menu-item (click)="performAction( 'note' )">Add note</button>
  <button mat-menu-item (click)="performAction( 'requestAction' )">Request action</button>
</mat-menu>

