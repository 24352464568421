
<div class="modal-header">
  <h4 class="modal-title">Failed Test Details - {{testCaseName}}</h4>
</div>

<div class="modal-body col-12">
  <div class="row">
    <div style="margin-right:200px">
      <h4>Missing Optional Forms</h4>
      <ul>
        <li *ngFor="let form of this.missingOptional">{{form}}</li>
      </ul>
    </div>
    <div>
      <h4>Missing Mandatory Forms</h4>
      <ul>
        <li *ngFor="let form of this.missingMandatory">{{form}}</li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div style="margin-right:200px">
      <h4>Extra Optional Forms</h4>
      <ul>
        <li *ngFor="let form of this.extraOptional">{{form}}</li>
      </ul>
    </div>
    <div>
      <h4>Extra Mandatory Forms</h4>
      <ul>
        <li *ngFor="let form of this.extraMandatory">{{form}}</li>
      </ul>
    </div>
  </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn primary_btn" (click)="activeModal.close('Update')">Sync Test Case Results</button>
  <button type="button" class="btn secondary_btn" (click)="activeModal.close()">Close</button>
</div>

