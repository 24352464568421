//Angular Imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

//Third Party Imports
import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridModule } from "ag-grid-angular";
import { MyDatePickerModule } from 'mydatepicker';
//Internal Imports 
import { FrameworkModule, DateFilterComponent, BooleanFilterComponent } from '../framework';
import { UserNotesModule } from '../user-notes/user-notes.module';
import { PackageService } from '../package/services';
import { OdenRoutingModule } from './oden.routing';
import { OdenComponent } from './components/oden-component';
import { OdenService } from './services';

/**
  Forms Module
*/
@NgModule({
  declarations: [OdenComponent],
  imports: [
    MyDatePickerModule,
    CommonModule,
    OdenRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    FrameworkModule,
    UserNotesModule,
    AgGridModule.withComponents([DateFilterComponent, BooleanFilterComponent])
  ],
  providers: [OdenService]
})
export class OdenModule { }
