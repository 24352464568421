<div class="component-container">
  <div class="component-heading-container">
    <div class="col-md-12">
      <div class="row" style="padding-left: 10px">
        <h3 class="component-heading-text">Folder Settings</h3>
      </div>
    </div>
  </div>
  <div class="container" style="padding: 10px">
    <div id="policy-form-container" class="row">
      <!--<hr style="height:3px;background-color:lightgrey;width:100%" *ngIf="logSelected" />-->
      <div class="col-md-5">
        <div
          class="heading-container fast_admin"
          id="policy-form-heading-container"
        >
          <label class="label_heading">System:</label>
          <div class="selectdiv select_wrapinline" style="width: 80%">
            <label>
              <ng-select
                class="custom-select"
                [items]="systems"
                (change)="onSystemChange($event)"
                [(ngModel)]="selectedSystem"
              >
              </ng-select>
            </label>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div
          class="heading-container fast_admin"
          id="policy-form-heading-container"
        >
          <label class="label_heading">Action:</label>
          <div class="selectdiv select_wrapinline" style="width: 80%">
            <label>
              <ng-select
                class="custom-select"
                [items]="actions"
                (change)="onActionChange($event)"
                [(ngModel)]="selectedAction"
              >
              </ng-select>
            </label>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <button
          type="button"
          mat-raised-button
          class="btn-teal"
          (click)="addRow()"
        >
          Add Row
        </button>
      </div>
    </div>
    <div class="row">
      <table mat-table [dataSource]="this.tableSource" style="width: 90%">
        <ng-container [matColumnDef]="col.key" *ngFor="let col of tableSchema">
          <th mat-header-cell *matHeaderCellDef style="width: 33%">
            {{ col.label }}
          </th>
          <td mat-cell *matCellDef="let element">
            <div [ngSwitch]="col.type" *ngIf="!element.isEdit">
              <ng-container *ngSwitchCase="'isSelected'">
                <mat-checkbox
                  (change)="element.isSelected = $event.checked"
                ></mat-checkbox>
              </ng-container>
              <div class="btn-edit" *ngSwitchCase="'isEdit'">
                <button
                  type="button"
                  mat-raised-button
                  class="btn-teal btn-sm"
                  (click)="element.isEdit = !element.isEdit"
                  style="margin: 10px"
                >
                  Edit
                </button>
                <button
                  type="button"
                  mat-raised-button
                  class="btn-teal btn-sm"
                  style="margin: 10px"
                  (click)="removeRow(element.product, element.folder)"
                >
                  Delete
                </button>
              </div>
              <span *ngSwitchDefault>
                {{ element[col.key] }}
              </span>
            </div>
            <div [ngSwitch]="col.type" *ngIf="element.isEdit">
              <div *ngSwitchCase="'isSelected'"></div>
              <div class="btn-edit" *ngSwitchCase="'isEdit'">
                <button
                  type="button"
                  mat-raised-button
                  class="btn-teal btn-sm"
                  (click)="element.isEdit = false; editRow(element)"
                  [disabled]="disableSubmit(element.id)"
                  style="margin: 10px 20px"
                >
                  Done
                </button>
              </div>
              <mat-form-field class="form-input" *ngSwitchDefault>
                <input
                  matInput
                  [required]="col.required"
                  [pattern]="col.pattern"
                  [type]="col.type"
                  [(ngModel)]="element[col.key]"
                  (change)="inputHandler($event, element.id, col.key)"
                />
              </mat-form-field>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</div>
