//Angular Imports
import { Component, HostBinding } from "@angular/core";

//Third Party Imports
import { GridOptions } from "ag-grid-community";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

//Internal Imports

import "rxjs";
import {
  AppInsightsService,
  EnvironmentService,
  DateTimeUtcPipe,
  SharedService,
  ApplicationInsightsBaseComponent,
  CPMElement,
} from "../../../framework";
import { EXECUTION_ENGINE_LIST } from "../../../report/configs";
import { MatTableDataSource } from "@angular/material/table";
import { isNull } from "lodash";
import { EmailService } from "../services/email.service";
import { EmailSetting } from "../models/emailSetting.model";
import { SystemRules } from "../../rules/models/systemRules";
import { FormBuilder, FormControl } from "@angular/forms";
import { debounceTime, forkJoin } from "rxjs";

/**
 * Forms Component
 */
@Component({
  selector: "app-email-settings",
  templateUrl: "./email.component.html",
  styleUrls: ["./email.component.scss"],
  providers: [EmailService],
})
export class EmailComponent extends ApplicationInsightsBaseComponent {
  @HostBinding("style.width") width: string;

  initialRowDataLoad$;

  systems: any[] = ["e2", "Magic", "Maverick"];
  lobs: string[] = [];
  selectedSystem: string;
  selectedLob: string;

  valid: any = {};
  isRowAdded: boolean = false;
  templateControl = new FormControl('');

  public isProd = true;

  public tableSource = new MatTableDataSource<EmailSetting>();

  public maxTableRows: number = 0;

  public tableSchema: any[] = [
    {
      key: "id",
      type: "text",
      label: "No."
    },
    {
      key: "ParentProducerNumber",
      type: "text",
      label: "Parent Producer Number",
    },
    {
      key: "Template",
      type: "text",
      label: "Template ID",
    },
    {
      key: "TemplateName",
      type: "text",
      label: "Template Name",
    },
    {
      key: "LineOfBusiness",
      type: "select",
      label: "Line Of Business",
    },
    {
      key: "ParentProducer",
      type: "text",
      label: "Parent Producer Name",
    },
    {
      key: "Producer",
      type: "text",
      label: "Producer Name",
    },
    {
      key: "ProducerNumber",
      type: "text",
      label: "Producer Number",
    },
    {
      key: "ProducerEmail",
      type: "text",
      label: "Alternate Routing Email",
    },
    {
      key: "CustomerServiceEmail",
      type: "text",
      label: "Customer Service Email",
    },
    {
      key: "FromEmail",
      type: "text",
      label: "From Email Address",
    },
    {
      key: "Subject",
      type: "text",
      label: "Subject",
    },
    {
      key: "Url",
      type: "text",
      label: "Url",
    },
    {
      key: "SendToInsured",
      type: "checkbox",
      label: "Send To Insured",
    },
    {
      key: "SendToProducer",
      type: "checkbox",
      label: "Send To Producer",
    },
    {
      key: "SendToCustomerService",
      type: "checkbox",
      label: "Send To Customer Service",
    },
    {
      key: "AttachDocsToEmail",
      type: "checkbox",
      label: "Attach Policy Docs To Email",
    },
    {
      key: "isEdit",
      type: "isEdit",
      label: "",
    },
  ];
  public displayedColumns: string[] = this.tableSchema.map((col) => col.key);
  public allrules: SystemRules[] = [];
  public sampleData: EmailSetting[] = [];
  public selected: string = "";

  /**
   * Constructor
   * @ignore
   */
  constructor(
    private _appInsightsService: AppInsightsService,
    private toastr: ToastrService,
    private _modal: NgbModal,
    private emailService: EmailService,
    private sharedService: SharedService,
    private dateTimePipe: DateTimeUtcPipe,
    private environmentService: EnvironmentService,
    private formBuilder: FormBuilder
  ) {
    super(_appInsightsService);
    this.width = "100%";
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
   */
  ngOnInit(): void {

    this.isProd = this.environmentService.environmentDetails.Environment == "PROD" ? true : false;
    console.log(this.isProd)
    this.templateControl.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.onFilterChange();
    });

    forkJoin([this.emailService.getEmailSettings(), this.sharedService.getAllCpmElements()]).subscribe(
      ([emailSettingRes, cmpElementRes]) => {
        this.allrules = emailSettingRes;
        this.lobs = cmpElementRes
          ?.filter(x => x.CPMDataElementName === 'LOB')[0]
          ?.CPMFieldValues?.map(x => x.Description)
          .sort((a, b) => a.localeCompare(b));
      }
    );

  }

  /**
   * NgOnDestroy
   * @ignore
   */
  ngOnDestroy(): void { }

  onFilterChange(clearFilters = false) {
    if(clearFilters) {
      this.selectedLob = '';
      this.templateControl.setValue('', {onlySelf : true, emitEvent : false});
    }
    const selectedTemplate = this.templateControl.value?.toLowerCase() || '';
    this.maxTableRows = 0;
    var setup = this.allrules.find(
      (x) => x.System == this.selectedSystem
    );
    if (!setup?.Items) {
      this.sampleData = [];
    } else {
      this.sampleData = setup.Items;
      if (this.selectedLob) {
        this.sampleData = this.sampleData.filter(x => x.LineOfBusiness === this.selectedLob);
      } if(selectedTemplate) {
        this.sampleData = this.sampleData.filter(x => x['TemplateName']?.toLowerCase()?.includes(selectedTemplate));
      } 
    }
    console.log(this.sampleData);
    for (var i = 0; i < this.sampleData.length; i++) {
      this.sampleData[i].id = this.maxTableRows;
      this.maxTableRows++;
      this.sampleData[i].TemplateArray = this.sampleData[i].Template.split(",");
    }
    this.tableSource.data = this.sampleData;
  }
  

  editRow(row: any) {
    if (row.id === 0) {
      var index = this.allrules.findIndex(
        (i) => i.System == this.selectedSystem
      );
      this.allrules[index].Items = this.tableSource.data;
      console.log(this.allrules[index]);
      this.emailService
        .updateEmailSetting(this.allrules[index])
        .subscribe(() => {
          row.isEdit = false;
          this.emailService.clearCache().subscribe((res) => {
            console.log(res);
          });
        });
      row.isEdit = false;
    } else {
      var index = this.allrules.findIndex(
        (i) => i.System == this.selectedSystem
      );
      this.allrules[index].Items = this.tableSource.data;
      console.log(this.allrules[index]);
      this.emailService
        .updateEmailSetting(this.allrules[index])
        .subscribe(() => {
          row.isEdit = false;
          this.emailService.clearCache().subscribe((res) => {
            console.log(res);
          });
        });
      row.isEdit = false;
    }
  }

  addRow() {
    this.isRowAdded = true;
    const newRow: any = {
      Producer: "",
      ProducerEmail: "",
      Template: "",
      Url: "",
      SendToInsured: false,
      SendToProducer: false,
      AttachDocsToEmail: false,
      isEdit: true,
      isSelected: false,
      id: this.maxTableRows
    };
    this.maxTableRows++;
    this.tableSource.data = [newRow, ...this.tableSource.data];
  }

  removeRow(id: number) {
    if (confirm("Delete the row?")) {
      this.tableSource.data = this.tableSource.data.filter(
        (u: EmailSetting) => u.id !== id
      );
      var index = this.allrules.findIndex(
        (i) => i.System == this.selectedSystem
      );
      this.allrules[index].Items = this.tableSource.data;
      this.emailService
        .updateEmailSetting(this.allrules[index])
        .subscribe((res) => {
          this.emailService.clearCache().subscribe((res) => {
            console.log(res);
          });
        });
    }
  }

  inputHandler(e: any, id: number, key: string) {
    if (!this.valid[id]) {
      this.valid[id] = {};
    }
    this.valid[id][key] = e?.target?.validity?.valid;
  }

  disableSubmit(id: number) {
    if (this.valid[id]) {
      return Object.values(this.valid[id]).some((item) => item === false);
    }
    return false;
  }

  editRow1() {

  }

  deleteRow(){
    
  }
}
