import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MasterViewComponent } from "./components/master-view/master-view.component";

const routes: Routes = [
  {
    path: '', component: MasterViewComponent 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class MdocumentOrchestrationQueueRoutingModule { }
