import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ImportQueueService } from '../../services/import-queue.service';

@Component({
  selector: 'app-update-jobs-menu',
  templateUrl: './update-jobs-menu.component.html'
})
export class UpdateJobsMenuComponent implements OnInit {
  @Input() singleJob: boolean = false;
  @Input() selectedJobCount: number = 1;
  @Input() context: string = "job";

  @Output() triggerJobUpdateModal = new EventEmitter();
  constructor(public queueService: ImportQueueService) { }

  ngOnInit(): void { }

  isAuthorized(): boolean {
    return this.queueService.isAuthorized('requestAction');
  }

  performAction(action: string) {
    this.triggerJobUpdateModal.emit({ context: this.context, action: action });
  }

}
