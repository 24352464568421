//Angular Imports
import { Injectable } from '@angular/core'
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpEventType, HttpErrorResponse } from '@angular/common/http';

//RxJS imports
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

//Internal Imports
import { SharedService } from "../services";
import { SkippableInterceptorHeader } from '../enums';

/**
  * Intercepts and all HTTP responses to hide loader
*/
@Injectable()

export class HideLoaderInterceptor implements HttpInterceptor {

  /**
   * Constructor
   * @ignore
  */
  constructor(private sharedService: SharedService) { }

  /**
   * Intercepts all the HTTP requests and hides the loader
   * @param {HttpRequest} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>} Http Event Observable
  */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(
        tap(event => {
          if (event.type === HttpEventType.Response) {
            //hide the loader
            this.sharedService.hideLoader();
          }

        },
          (error: HttpErrorResponse) => {

            //hide the loader
            this.sharedService.hideLoader();
          })
      );
    
  }
}
