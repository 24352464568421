//Angular Imports 
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';


//Internal Imports
import { FormPageContainerComponent } from './components';

/**
  form-page Routes
*/
export const routes: Routes = [
  {
    path: '', component: FormPageContainerComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})

export class FormPageRoutingModule {
}
