//Angular Imports 
import { Component, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LayoutService } from '../../../@vex/services/layout.service';
import { ConfigService } from '../../../@vex/config/config.service';
import { SidebarComponent } from '../../../@vex/components/sidebar/sidebar.component';
import { checkRouterChildsData } from '../../../@vex/utils/check-router-childs-data';
import { filter, map, startWith } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

//Internal Imports 
import { SharedService, AppInsightsService, EnvironmentService, EnvironmentSpecifics, UserProfileService } from '../../framework';


/**
  Main Layout Component
*/
@UntilDestroy()
@Component({
  selector: 'splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})

export class SplashComponent {
  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isFooterVisible$ = this.configService.config$.pipe(map(config => config.footer.visible));
  isDesktop$ = this.layoutService.isDesktop$;

  toolbarShadowEnabled$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.toolbarShadowEnabled))
  );

  @ViewChild('configpanel', { static: true }) configpanel: SidebarComponent;


  //public mdoImportHealth: string = "Loading...";
  //public healthData: PartnerHealth[] = [];
 // public healthScore: number = -1;


  /**
   * Constructor
   * @ignore
  */
  constructor(private sharedService: SharedService, private environmentService: EnvironmentService,
    private appInsightsService: AppInsightsService, public userProfileService: UserProfileService,
    private router: Router,
    private layoutService: LayoutService,
    private configService: ConfigService) {

  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
  */
  ngOnInit(): void {
    this.sharedService.hideLoader();
    this.sharedService.hidePreloader();
    this.appInsightsService.init(this.environmentService.environmentDetails.APP_INSIGHTS_KEY);
    window["appInsights"] = appInsights;

    appInsights.queue.push(() => {
      //Set the telemetry settings
      appInsights.context.addTelemetryInitializer((envelop) => {
        let telemetryItem = envelop.data.baseData;
        //In future might need to get information from authentication service and set authorization information
        appInsights.setAuthenticatedUserContext("FASTAdminWeb");
        appInsights.context.user.id = "FASTAdminWeb";

        // To set custom properties:
        telemetryItem.properties = telemetryItem.properties || {};
        telemetryItem.properties["AppModule"] = this.environmentService.environmentDetails.TELEMETRY_PROPERTY_APP_MODULE;

      });

    });
    console.log(this.environmentService.environmentDetails);
    this.layoutService.configpanelOpen$.pipe(
      untilDestroyed(this)
    ).subscribe(open => open ? this.configpanel?.open() : this.configpanel?.close());
  }

  /**
   * NgDestroy
   * @ignore
  */
  ngOnDestroy(): void {
  }

}
