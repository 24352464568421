import { Component, OnInit } from '@angular/core';
import { Report } from '../../../../shared-modules/embedded-reports/models/report';

@Component({
  selector: 'app-main-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.scss']
})
export class MainViewComponent implements OnInit {
  mainViewTitle: string = "Insured Identity";

  report: Report;

  constructor() { }

  ngOnInit(): void {
    // Define the instances of the report
    this.report = new Report("CIC Report");
    // TODO:  update with the non-prod URLs once available
    this.report.addInstance(
      "prod",
      "https://app.powerbi.com/reportEmbed?reportId=b7f51bce-13da-4cf2-ae2d-ec80f7fc73b6&autoAuth=true&ctid=28e5a145-70c6-44e3-ba17-7b09d54fe531"
    );
    this.report.addInstance(
      "default", // mirrors prod for now
      "https://app.powerbi.com/reportEmbed?reportId=b7f51bce-13da-4cf2-ae2d-ec80f7fc73b6&autoAuth=true&ctid=28e5a145-70c6-44e3-ba17-7b09d54fe531"
    );
  }


}
