import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DataManagementRoutingModule } from './data-management-routing.module';
import { LayoutComponent } from './components/layout/layout.component'

//Vex modules
import { PopoverService } from '../../@vex/components/popover/popover.service';
import { LayoutModule } from '../../@vex/layout/layout.module';
import { SidenavModule } from '../../@vex/layout/sidenav/sidenav.module';
import { ToolbarModule } from '../../@vex/layout/toolbar/toolbar.module';
import { ConfigPanelModule } from '../../@vex/components/config-panel/config-panel.module';
import { SidebarModule } from '../../@vex/components/sidebar/sidebar.module';
import { QuickpanelModule } from '../../@vex/layout/quickpanel/quickpanel.module';

@NgModule({
  declarations: [
    LayoutComponent
  ],
  imports: [
    CommonModule,
    DataManagementRoutingModule,
    LayoutModule,
    SidenavModule,
    ToolbarModule,
    ConfigPanelModule,
    SidebarModule,
    QuickpanelModule
  ],
  providers: [PopoverService]
})
export class DataManagementModule { }
