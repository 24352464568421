//Angular Imports
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

//Third Party Imports
import { NgSelectModule } from "@ng-select/ng-select";
import { AgGridModule } from "ag-grid-angular";
import { MyDatePickerModule } from "mydatepicker";

//Internal Imports
import { ProductsRoutingModule } from "./products.routing";
import {
  FrameworkModule,
  DateFilterComponent,
  BooleanFilterComponent,
} from "../framework";
import { ProductsComponent } from "./components/products.component";
import { ProductsService } from "./services/products.service";
import { AddProductComponent } from "./components/add-product/add-product.component";
import { MatButtonModule } from "@angular/material/button";

/**
  Forms Module
*/
@NgModule({
  declarations: [ProductsComponent, AddProductComponent],
  imports: [
    MyDatePickerModule,
    CommonModule,
    ProductsRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    FrameworkModule,
    MatButtonModule,
    AgGridModule.withComponents([DateFilterComponent, BooleanFilterComponent]),
  ],
  providers: [ProductsService],
})
export class ProductsModule {}
