<div class="component-container" style="background-color: #dfe1e0;" id="grid_container">
  <div class="component-content-view">
    <div class="container-fluid">
      <div id="policy-form-container" class="row">
        <div class="col-md-12" style="
            border-radius: 4px;
            margin-top: 10px;
            padding-right: 4px;
            padding-left: 4px;
            padding-bottom: 4px;
          ">
          <div>
              <!-- <select class="dropdown" [(ngModel)]="selectedInput">
                <option value="formnum">Form #</option>
                <option value="formname">Form Name</option>
              </select> -->
            <!-- <div class="wrapper">
              <input placeholder="Enter search info" [disabled]="!this.selectedCov" [(ngModel)]="searchInput" (keyup)="onSearchKey($event)" />
              <button class="btn_gdf_searchbar" [disabled]="!this.selectedCov" (click)="externalFilterChanged($event, 'search')"></button>
            </div> -->
            
            <div style="display: inline-block; float: right">
              <button type="button" mat-raised-button (click)="showHideIC()" style="
                  float: right;
                  
                  color: black;
                  font-weight: normal;
                  font-size: 14px;
                " *ngIf="(this.isInternationalCasulty || this.isMINT)&& this.issuanceLOB">
                Show/Hide Issuance
              </button>

              <button type="button" class="mat_btn_hov_act" mat-raised-button 
              [matMenuTriggerFor]="menu"
              (click)="toggleDropdown()" style="
                  float: right;
                  margin-right: 5px;
                  font-size: 14px;
                  color: white;
                  background-color: var(--color-teal);
                ">
                Actions
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="viewSelected()">View Selected Forms({{ this.selectedFormCount }})</button>
                <button mat-menu-item (click)="uncheckAll()">Uncheck Selected</button>
                <button mat-menu-item *ngIf="isExistingTransaction" (click)="clearTransaction()">Clear Transaction</button>
                <button mat-menu-item (click)="downloadPolicy(true)">Preview Policy Packet</button>
                <button mat-menu-item (click)="downloadPolicy(false)">Download Policy Packet</button>
                <button mat-menu-item (click)="exportInfo()">Export Info to CSV</button>
              </mat-menu>
       

              <!-- <button type="button" class="mat_btn_hov_act" mat-raised-button (click)="uncheckAll()" style="
                  float: right;
                  
                  margin-right: 3px;
                  font-size: 13px;
                  color: white;
                  background-color: var(--color-teal);
                ">
                Uncheck Selected
              </button>
              <button type="button" class="mat_btn_hov_act" mat-raised-button (click)="viewSelected()" style="
                  float: right; 
                  
                  margin-right: 3px;
                  font-size: 13px;
                  background-color: var(--color-teal);
                  color: white;
                ">
                View Selected Forms({{ this.selectedFormCount }})
              </button>
              <button type="button" mat-raised-button class="mat_btn_hov_act" (click)="clearTransaction()" style="
                  float: right;
                  
                  margin-right: 6px;
                  background-color: var(--color-off-white);
                " *ngIf="isExistingTransaction">
                Clear Transaction
              </button> -->
            </div>
          </div>

          <!-- <div
            class="heading-container fast_admin"
            id="policy-form-heading-container"
            style="display: flex"
          >
            <label class="label_heading">Line Of Business:</label>
            <div class="selectdiv select_wrapinline">
              <label>
                <ng-select
                  id="lineOfBusiness"
                  class="custom-select"
                  [items]="lineOfBusinessList"
                  bindLabel="Name"
                  bindValue="ID"
                  (change)="onLOBChange($event)"
                  [(ngModel)]="selectedLOB"
                  [clearable]="false"
                >
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{ item.Name }}">{{ item.Name }}</div>
                  </ng-template>
                </ng-select>
              </label>
            </div>
            <label class="label_heading">Coverage:</label>
            <div class="selectdiv select_wrapinline">
              <label>
                <ng-select
                  id="selectedCoverage"
                  class="custom-select"
                  [items]="selectedCovList"
                  bindLabel="Name"
                  bindValue="ID"
                  (change)="onCovChange($event)"
                  [(ngModel)]="selectedCov"
                  [clearable]="false"
                >
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{ item.Name }}">{{ item.Name }}</div>
                  </ng-template>
                </ng-select>
              </label>
            </div>
            <label class="label_heading" *ngIf="this.selectedCov != 'INTC'"
              >Primary/Excess:</label
            >
            <div
              class="selectdiv select_wrapinline"
              style="width: 10% !important"
              *ngIf="this.selectedCov != 'INTC'"
            >
              <label>
                <ng-select
                  id="selectedRule"
                  class="custom-select"
                  [items]="covTypeList"
                  bindLabel="Name"
                  bindValue="Name"
                  (change)="onCovTypeChange()"
                  [(ngModel)]="selectedCovType"
                  [clearable]="false"
                >
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{ item.Name }}">{{ item.Name }}</div>
                  </ng-template>
                </ng-select>
              </label>
            </div>
            <label class="label_heading" *ngIf="this.selectedCov != 'INTC'"
              >State:</label
            >
            <div
              class="selectdiv select_wrapinline"
              style="width: 8% !important"
              *ngIf="this.selectedCov != 'INTC'"
            >
              <label>
                <ng-select
                  id="selectedRule"
                  class="custom-select"
                  [items]="stateRuleList"
                  bindLabel="Code"
                  bindValue="Code"
                  (change)="onStateChange()"
                  [(ngModel)]="selectedState"
                  [clearable]="false"
                >
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{ item.Code }}">{{ item.Code }}</div>
                  </ng-template>
                </ng-select>
              </label>
            </div>
            <label class="label_heading">Paper:</label>
            <div class="selectdiv select_wrapinline">
              <label>
                <ng-select
                  id="selectedRule"
                  class="custom-select"
                  [items]="paperRuleList"
                  bindLabel="Description"
                  bindValue="Code"
                  (change)="onPaperChange()"
                  [(ngModel)]="selectedPaper"
                  [clearable]="false"
                >
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{ item.Description }}">
                      {{ item.Description }}
                    </div>
                  </ng-template>
                </ng-select>
              </label>
            </div>
            <label class="label_heading" *ngIf="this.selectedCov == 'INTC'"
              >Dec Page:</label
            >
            <div
              class="selectdiv select_wrapinline"
              *ngIf="this.selectedCov == 'INTC'"
            >
              <label>
                <ng-select
                  id="selectedRule"
                  class="custom-select"
                  [items]="decPageList"
                  bindLabel="Description"
                  bindValue="Code"
                  (change)="onDecPageChange()"
                  [(ngModel)]="selectedDecPage"
                  [clearable]="false"
                >
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{ item.Description }}">
                      {{ item.Description }}
                    </div>
                  </ng-template>
                </ng-select>
              </label>
            </div>
          </div> -->
        </div>
        <ng-template #dialogContent>
          <!-- <h4 mat-dialog-title>Issuance</h4> -->
          <mat-dialog-content>
            <div class="col-md-12" [ngClass]="{'table-custom': (this.isInternationalCasulty || this.isMINT) && this.isExpanded}">
              <div class="row" style="
                        padding-bottom: 5px;
                        padding-left: 5px;
                        height: auto;
                        align-items: baseline;
                      " *ngIf="(this.isInternationalCasulty || this.isMINT) && this.isExpanded">
                <table style="width: 100%">
                  <tr>
                    <td>
                      <label class="label_heading">Producer Name</label>
                    </td>
                    <td>
                      <input placeholder="Producer Name" class="policy-form-input" [(ngModel)]="producerName"
                        (change)="producerName = producerName.trim()" />
                    </td>
                    <td>
                      <label class="label_heading">Insured Name</label>
                    </td>
                    <td>
                      <input placeholder="Insured Name" class="policy-form-input" [(ngModel)]="insuredName"
                        (change)="insuredName = insuredName.trim()" />
                    </td>
                    <td>
                      <label class="label_heading">Insured DBA</label>
                    </td>
                    <td>
                      <input placeholder="Insured DBA" class="policy-form-input" [(ngModel)]="insuredDBA"
                        (change)="insuredDBA = insuredDBA.trim()" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label class="label_heading">Insured Address</label>
                    </td>
                    <td>
                      <input placeholder="Insured Address" class="policy-form-input" [(ngModel)]="insuredAddress"
                        (change)="insuredAddress = insuredAddress.trim()" />
                    </td>
                    <td>
                      <label class="label_heading">Insured Address 2P</label>
                    </td>
                    <td>
                      <input placeholder="Insured Address 2P" class="policy-form-input" [(ngModel)]="insuredAddress2"
                        (change)="insuredAddress2 = insuredAddress2.trim()" />
                    </td>
                    <td>
                      <label class="label_heading">Insured Address 3P</label>
                    </td>
                    <td>
                      <input placeholder="Insured Address 3P" class="policy-form-input" [(ngModel)]="insuredAddress3"
                        (change)="insuredAddress3 = insuredAddress3.trim()" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label class="label_heading">Policy Number</label>
                    </td>
                    <td>
                      <input placeholder="Policy Number" class="policy-form-input" [(ngModel)]="policyNumber"
                        (change)="policyNumber = policyNumber.trim()" />
                    </td>
                    <td>
                      <label class="label_heading">Policy Effective Date</label>
                    </td>
                    <td>
                      <input id="polEffDate" type="date" name="policyEffectiveDate" placeholder="Policy Effective Date"
                        class="policy-form-input" [(ngModel)]="policyEffectiveDate" />
                    </td>
                    <td>
                      <label class="label_heading">Policy Expiration Date</label>
                    </td>
                    <td>
                      <input id="polExpDate" type="date" name="policyExpirationDate" placeholder="Policy Expiration Date"
                        class="policy-form-input" [(ngModel)]="policyExpirationDate" />
                    </td>
                  </tr>
                  <tr>
          
                    <td>
                      <label class="label_heading">Deal Number</label>
                    </td>
                    <td>
                      <input id="dealNum" name="dealNumber" placeholder="Deal Number" class="policy-form-input"
                        [(ngModel)]="dealNumber" />
                    </td>
          
                    <td>
                      <label class="label_heading" *ngIf="(this.isInternationalCasulty || this.isMINT) && this.isExpanded">Auth.
                        Representative</label>
                    </td>
                    <td>
                      <ng-select id="selectedRep" [items]="underwriters" (change)="onAuthRepChange($event)"
                        [(ngModel)]="selectedUnderwriter" [clearable]="false" [searchable]="false"
                        placeholder="{{ authRepPlaceholder }}" class="policy-form-input" *ngIf="this.selectedCov != 'MINT'">
                        <ng-template ng-option-tmp let-item="item">
                          <div title="Underwriter">
                            {{ item.Name }} {{ item.Surname }}, {{ item.Title }}
                          </div>
                        </ng-template>
                      </ng-select>
                      <ng-select id="selectedRep" [items]="mintUnderwriters" (change)="onAuthRepChange($event)"
                        [(ngModel)]="selectedUnderwriter" [clearable]="false" [searchable]="false"
                        placeholder="{{ authRepPlaceholder }}" class="policy-form-input" *ngIf="this.selectedCov == 'MINT'">
                        <ng-template ng-option-tmp let-item="item">
                          <div title="Underwriter">
                            {{ item.Name }} {{ item.Surname }}, {{ item.Title }}
                          </div>
                        </ng-template>
                      </ng-select>
                    </td>
                  </tr>
                </table>
          
                <table style="width: 100%"></table>
              </div>
            </div>
          </mat-dialog-content>
          <mat-dialog-actions align="end">
            <div class="row"
                [ngClass]="{'issuance-table-custom': (this.isInternationalCasulty || this.isMINT) && this.isExpanded}" style="
                        padding-bottom: 5px;
                        padding-left: 5px;
                        height: auto;
                        align-items: flex-start;
                      ">
                <button type="button" mat-raised-button class="btn-teal" (click)="openExtensionIframe(false)"
                  *ngIf="(this.isInternationalCasulty || this.isMINT) && this.isExpanded">
                  Issue Policy
                </button>
                <button type="button" mat-raised-button class="btn-teal" (click)="openTransactions()" 
                style="margin-left: 5px" *ngIf="(this.isInternationalCasulty || this.isMINT) && this.isExpanded">
                  View Transactions
                </button>
                <button type="button" mat-raised-button class="mat_btn_hov_act" (click)="openExtensionIframe(true)"
                  style="margin-left: 5px" *ngIf="this.isETaskLOB && this.isExpanded && this.secretKeyCode">
                  Test Cache Reload
                </button>
                <button type="button" mat-raised-button class="mat_btn_hov_act" (click)="closeDialog()"
                  style="margin-left: 5px" *ngIf="true">
                  Close
                </button>
                
              </div>
          </mat-dialog-actions>
        </ng-template>

        <div class="additional-filters">
          <div class="additional-filters-header">
            <span class="filter-heading">Filters</span>
            <span class="reset-button" (click)="clearAll()">Reset</span>
          </div>
        
          <!-- <div class="search"><i class="bi bi-search"></i>
            <input id="searchInput" type="text" placeholder="Search form..." [(ngModel)]="searchText">
          </div> -->

          <div class="wrapper" *ngIf="this.selectedCov">
            <button class="btn_gdf_searchbar" (click)="externalFilterChanged($event, 'search')"></button>
            <input placeholder="Enter search info" [(ngModel)]="searchInput" (keyup)="onSearchKey($event)" />
          </div>
          
          <div class="fast_admin filter-content" id="policy-form-heading-container" 
            style="display: flex; flex-direction: column; height: calc(100vh - 233px);overflow-y: scroll;">
            <div class="toggle-group-custom">
              <!-- <mat-button-toggle-group style="
                  margin: 5px 0px 15px 2px;
                  box-shadow: 0px 2px 3px #000;
                  height: 34px;
                  font-size: 13px;
                  max-width: 98%;
                " [(ngModel)]="isMandatory">
                <mat-button-toggle value="All" class="mat_btn_hov_act"
                  (change)="onMandatoryOptional()">All</mat-button-toggle>
                <mat-button-toggle value="Mandatory" class="mat_btn_hov_act"
                  (change)="onMandatoryOptional()">Mandatory</mat-button-toggle>
                <mat-button-toggle value="Optional" class="mat_btn_hov_act"
                  (change)="onMandatoryOptional()">Optional</mat-button-toggle>
              </mat-button-toggle-group> -->
            </div>

            <!-- <div class="filter-container"> -->
              <label class="label_heading">Line Of Business<span style="color: red;">*</span></label>
              <div class="selectdiv selectdiv-custom select_wrapinline">
                <label>
                  <ng-select id="lineOfBusiness" class="custom-select" [items]="lineOfBusinessList" bindLabel="Name"
                    bindValue="ID" (change)="onLOBChange($event)" [(ngModel)]="selectedLOB" [clearable]="false">
                    <ng-template ng-option-tmp let-item="item">
                      <div title="{{ item.Name }}">{{ item.Name }}</div>
                    </ng-template>
                  </ng-select>
                </label>
              </div>
            <!-- </div> -->

            <!-- <div class="filter-container"> -->
              <label class="label_heading">Coverage<span style="color: red;">*</span></label>
              <div class="selectdiv selectdiv-custom select_wrapinline">
                <label>
                  <ng-select id="selectedCoverage" class="custom-select" [items]="selectedCovList" bindLabel="Name"
                    bindValue="ID" (change)="onCovChange($event)" [(ngModel)]="selectedCov" [clearable]="false">
                    <ng-template ng-option-tmp let-item="item">
                      <div title="{{ item.Name }}">{{ item.Name }}</div>
                    </ng-template>
                  </ng-select>
                </label>
              </div>
            <!-- </div> -->
            <!-- <div class="filter-container"> -->
              <label class="label_heading">Is Mandatory</label>
              <div class="selectdiv selectdiv-custom select_wrapinline">
                <label>
                  <ng-select id="isMandatory" class="custom-select" [items]="isMandatoryList"
                     (change)="onMandatoryOptional()" [(ngModel)]="isMandatory" [clearable]="false">
                    <!-- <ng-template ng-option-tmp let-item="item">
                      <div title="{{ item.Name }}">{{ item.Name }}</div>
                    </ng-template> -->
                  </ng-select>
                </label>
              </div>
            <!-- </div> -->

            <!-- <div class="filter-container"> -->
            <div *ngIf="this.selectedCov">
              <label class="label_heading" *ngIf="this.selectedCov != 'INTC'">Primary/Excess</label>
              <div class="selectdiv selectdiv-custom select_wrapinline" *ngIf="this.selectedCov != 'INTC'">
                <label>
                  <ng-select id="selectedRule" class="custom-select" [items]="covTypeList" bindLabel="Name"
                    bindValue="Name" (change)="onCovTypeChange()" [(ngModel)]="selectedCovType" [clearable]="false">
                    <ng-template ng-option-tmp let-item="item">
                      <div title="{{ item.Name }}">{{ item.Name }}</div>
                    </ng-template>
                  </ng-select>
                </label>
              </div>
            <!-- </div> -->

            <!-- <div class="filter-container"> -->
              <label class="label_heading" *ngIf="this.selectedCov != 'INTC'">State</label>
              <div class="selectdiv selectdiv-custom select_wrapinline" *ngIf="this.selectedCov != 'INTC'">
                <label>
                  <ng-select id="selectedRule" class="custom-select" appendTo="body" [items]="stateRuleList" bindLabel="Code"
                    bindValue="Code" (change)="onStateChange()" [(ngModel)]="selectedState" [clearable]="false">
                    <ng-template ng-option-tmp let-item="item">
                      <div title="{{ item.Code }}">{{ item.Code }}</div>
                    </ng-template>
                  </ng-select>
                </label>
              </div>
            <!-- </div> -->

            <!-- <div class="filter-container"> -->
              <label class="label_heading">Paper</label>
              <div class="selectdiv selectdiv-custom select_wrapinline">
                <label>
                  <ng-select id="selectedRule" class="custom-select" appendTo="body" [items]="paperRuleList" bindLabel="Description"
                    bindValue="Code" (change)="onPaperChange()" [(ngModel)]="selectedPaper" [clearable]="false">
                    <ng-template ng-option-tmp let-item="item">
                      <div title="{{ item.Description }}">
                        {{ item.Description }}
                      </div>
                    </ng-template>
                  </ng-select>
                </label>
              </div>
            <!-- </div> -->

            <!-- <div class="filter-container"> -->
              <label class="label_heading" *ngIf="this.selectedCov == 'INTC'">Dec Page</label>
              <div class="selectdiv selectdiv-custom select_wrapinline" *ngIf="this.selectedCov == 'INTC'">
                <label>
                  <ng-select id="selectedRule" class="custom-select" appendTo="body" [items]="decPageList" bindLabel="Description"
                    bindValue="Code" (change)="onDecPageChange()" [(ngModel)]="selectedDecPage" [clearable]="false">
                    <ng-template ng-option-tmp let-item="item">
                      <div title="{{ item.Description }}">
                        {{ item.Description }}
                      </div>
                    </ng-template>
                  </ng-select>
                </label>
              </div>
            </div>
            <!-- </div> -->
          </div>
        </div>


        <div id="grid-container" class="col-md-10">
          <div>
            <ag-grid-angular  class="ghostPortal ag-theme-balham" [gridOptions]="formsGridOptions"
              style="margin-top: 5px;height: calc(100vh - 132px) !important;overflow-x: hidden !important;">
            </ag-grid-angular>
          </div>
          <!-- <div style="padding: 5px">
            <button type="button" mat-raised-button class="mat_btn_hov_act" (click)="downloadPolicy(true)">
              Preview Policy Packet
            </button>
            <button type="button" mat-raised-button (click)="downloadPolicy(false)" style="
                margin-left: 20px;
                background-color: var(--color-teal);
                color: #fff;
                font-weight: normal;
              " class="mat_btn_hov_act">
              Download Policy Packet
            </button>
            
            <button type="button" mat-raised-button (click)="exportInfo()" style="
                float: right;
                background-color: var(--color-teal);
                color: #fff;
                font-weight: normal;
              " class="mat_btn_hov_act">
              Export Info to CSV
            </button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
