//Angular Imports
import { Component, HostBinding } from "@angular/core";

//Third Party Imports
import { GridOptions } from "ag-grid-community";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";

//Internal Imports
import {
  ApplicationInsightsBaseComponent,
  AppInsightsService,
  DateFilterComponent,
  BooleanFilterComponent,
  DateTimePipe,
  EnvironmentService,
  DISPLAY_ENVIRONMENTS,
  AuthorizationService,
} from "../../../framework";
import { DeployService } from "../../services";
import { DeployButtonComponent } from "../deploy-button";
import { CopyButtonComponent } from "../copy-button";
import { DeploymentHistory, DeployPackage } from "../../models";
import { UpdateButtonComponent } from "../update-button/update-button.component";
import { PackageReportComponent } from "../package-report/package-report.component";
import { isNullOrUndefined } from "util";
import { DeployConfirmationComponent } from "../deploy-confirmation/deploy-confirmation.component";
import { ToastrService } from "ngx-toastr";
import { RuleEngineForm } from "../../../form-page/models/ruleEngineForm.model";
import { FormDiscrepancies } from "../../models/formDiscrepancies";
import { forkJoin, map, observable } from "rxjs";
import * as moment from 'moment';

713582;
/**
 * Deploy Component
 */
@Component({
  selector: "app-deploy",
  templateUrl: "./deploy.component.html",
  styleUrls: ["./deploy.component.scss"],
})
export class DeployComponent extends ApplicationInsightsBaseComponent {
  //Public Variables
  /**
   * Host binding for width
   */
  @HostBinding("style.width") width: string;

  /**
   * deployGridOptions
   */
  public deployGridOptions: GridOptions;

  /**
   * formsGridOptions
   */
  public formsGridOptions: GridOptions;

  public historyGridOptions: GridOptions;

  /**
   * initialRowDataLoad$
   */
  initialRowDataLoad$;

  /**
   * DeployList
   */
  deployList: DeployPackage[];

  /**
   * Environment List
   */
  environmentList: any[];

  /**
   * Constructor
   * @ignore
   */
  constructor(
    private _appInsightsService: AppInsightsService,
    private deployService: DeployService,
    private dateTimePipe: DateTimePipe,
    private envService: EnvironmentService,
    private authService: AuthorizationService,
    private toastr: ToastrService,
    private _modal: NgbModal
  ) {
    super(_appInsightsService);
    this.width = "100%";
    let environment = this.envService.environmentDetails.Environment;
    this.environmentList = DISPLAY_ENVIRONMENTS[environment];
  }

  get FormsWritePermission(): boolean {
    return this.authService.FormsWrite();
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
   */
  ngOnInit(): void {
    //Configure the grid
    this.configureGrid();

    //Configure the forms grid
    this.configureFormsGrid();

    this.configureHistoryGrid();
    //Fetch the packages
  }

  /**
   * NgOnDestroy
   * @ignore
   */
  ngOnDestroy(): void {}

  //Public Methods
  /**
   *this method is used to fetch packages
   */
  getPackages(): void {
    this.deployService.getPackages().subscribe((el) => {
      this.deployList = el;
      this.setRowData();
    });
  }

  packageReport(): void {
    const modalRef = this._modal.open(PackageReportComponent, {
      size: "xl",
      backdrop: false,
    });
    modalRef.componentInstance.packageList = this.deployList;
  }

  //Private Methods
  /**
   *   Deploy list set data
   */
  private setRowData(): void {
    if (this.deployList != null) {
      if (this.authService.MPLUserOnly()) {
        this.deployList = this.deployList.filter((x) => x.LOB == "NFB||DF");
        this.deployGridOptions.api.setRowData(this.deployList);
      } else {
        this.deployGridOptions.api.setRowData(this.deployList);
      }
    }
  }

  /**
   *  configure forms grid
   */
  private configureGrid(): void {
    this.initialRowDataLoad$ = [];
    this.deployGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: "autoHeight",
      columnDefs: this.createColumDef(),
      getRowStyle: this.setRowColor,
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      paginationPageSize: 8,
      rowHeight: 50,
      enableColResize: true,
      context: { componentParent: this },
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent,
        deployButtonComponent: DeployButtonComponent,
        copyButtonComponent: CopyButtonComponent,
        updateButtonComponent: UpdateButtonComponent,
      },
      onGridReady: () => {
        this.deployGridOptions.api.setRowData([]);
        this.deployGridOptions.api.sizeColumnsToFit();
        this.deployGridOptions.columnApi
          .getColumn("CreatedDate")
          .setSort("desc");
        this.getPackages();
      },
      onRowClicked: (event) => {
        this.onRowClick(event);
      },
      onFilterChanged: (event) => {},
      onSortChanged: (event) => {},
      onFilterModified: function (event) {
        //console.log(event);
      },
    };
  }

  configureHistoryGrid(): void {
    this.initialRowDataLoad$ = [];
    this.historyGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: "autoHeight",
      columnDefs: this.createHistoryColumnDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      paginationPageSize: 1,
      rowHeight: 50,
      enableColResize: true,
      context: { componentParent: this },
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent,
      },
      onGridReady: () => {
        this.historyGridOptions.api.setRowData([]);
        this.historyGridOptions.api.sizeColumnsToFit();
      },
      onRowClicked: (event) => {},
      onFilterChanged: (event) => {},
      onSortChanged: (event) => {},
      onFilterModified: function (event) {
        //console.log(event);
      },
    };
  }

  setRowColor(params) {
    var flaggedEnvs = ["QA1", "QA2", "UAT1", "UAT2", "PXUAT", "FIT"];
    var histories: DeploymentHistory[] = [];
    histories = params.data.DeploymentHistories;
    if (histories.findIndex(h => h.Target == "PROD") == -1) {
      for (var i = 0; i < histories.length; i++) {
        if (flaggedEnvs.indexOf(histories[i].Target) != -1) {
          var unixNow = moment().unix();
          //1296000 is 15 days in unix time
          if (unixNow - histories[i].DeployedDate >= 1296000) {
            return { "background-color": "lightyellow" };
          }
        }
      }
    }
    return null;
    
  }


  /**
   *  Row Click event method
   */
  private onRowClick(e: any): void {
    let actionType = e.event.target.getAttribute("data-action-type");
    console.log(actionType);
    if (actionType == "Deploy") {
      let discrepantPackages: FormDiscrepancies[] = [];
      let sameForms: RuleEngineForm[] = [];
      this.deployService.adminGetPackageForms(e.data.PackageId, e.data.LOB).subscribe(forms => {
        let packageForms: RuleEngineForm[] = forms;
        let observables = [];

        for (var i = 0; i < this.deployList.length; i++) {
          if (this.deployList[i].CreatedDate > e.data.CreatedDate) {
            const laterPackage = this.deployList[i];
            observables.push(
              this.deployService.adminGetPackageForms(this.deployList[i].PackageId, this.deployList[i].LOB).pipe(
                map(res => ({
                  comparePackageForms: res,
                  laterPackage: laterPackage
                }))
              )
            );
          }
        }
        if (observables.length > 0) {
          forkJoin(observables).subscribe(results => {
            results.forEach(result => {
              console.log(result);
              sameForms = result.comparePackageForms.filter(x => packageForms.some(y => y.InternalFormNumber === x.InternalFormNumber && y.LOB === x.LOB));
              if (sameForms.length > 0) {
                let newDiscrepancy = new FormDiscrepancies();
                newDiscrepancy.sameForms = sameForms;
                newDiscrepancy.laterPackage = result.laterPackage;
                discrepantPackages.push(newDiscrepancy);
              }
            });
            console.log(discrepantPackages);
            if (discrepantPackages.length > 0) {
              let formattedMessage: string = `The following forms for LOB ${e.data.LOB} are also in one or more packages that have been created at a later date. Deploying this package may overwrite later changes. Continue?\n`;
              //\n${sameForms.map(y => y.InternalFormNumber).join(",")}`
              for (var i = 0; i < discrepantPackages.length; i++) {
                formattedMessage += `\n\u2022${discrepantPackages[i].sameForms.map(x => x.InternalFormNumber).join(",")} - ${discrepantPackages[i].laterPackage.PackageName} | ${discrepantPackages[i].laterPackage.Release}`
                if (discrepantPackages[i].laterPackage.EnvDeploymentStates.DEV2.IsDeployed) {
                  formattedMessage += ` (DEPLOYED TO PROD)`;
                }
              }
              if (confirm(formattedMessage)) {
                if (!e.data.IsDeployDisabled) {
                  const modalRef = this._modal.open(DeployConfirmationComponent, {
                    backdrop: "static",
                  });
                  modalRef.componentInstance.packageToBeDeployed = _.cloneDeep(e.data);
                  modalRef.result.then((result: any) => {
                    if (result) {
                      this.getPackages();
                    }
                  });
                } else {
                  this.toastr.warning(
                    "Please select environments to which the package has to be deployed.",
                    "Warning"
                  );
                }
              }
            }
            else {
              console.log(e.data);
              if (!e.data.IsDeployDisabled) {
                const modalRef = this._modal.open(DeployConfirmationComponent, {
                  backdrop: "static",
                });
                modalRef.componentInstance.packageToBeDeployed = _.cloneDeep(e.data);
                modalRef.result.then((result: any) => {
                  if (result) {
                    this.getPackages();
                  }
                });
              } else {
                this.toastr.warning(
                  "Please select environments to which the package has to be deployed.",
                  "Warning"
                );
              }
            }
          });
        }
        else {
          if (!e.data.IsDeployDisabled) {
            const modalRef = this._modal.open(DeployConfirmationComponent, {
              backdrop: "static",
            });
            modalRef.componentInstance.packageToBeDeployed = _.cloneDeep(e.data);
            modalRef.result.then((result: any) => {
              if (result) {
                this.getPackages();
              }
            });
          } else {
            this.toastr.warning(
              "Please select environments to which the package has to be deployed.",
              "Warning"
            );
          }
        }


      });
    }
    else {
      this.deployService
        .adminGetPackageForms(e.data.PackageId, e.data.LOB)
        .subscribe((result) => {
          console.log(result);
          if (this.formsGridOptions) {
            //Reset all the filters
            this.formsGridOptions.api.setFilterModel(null);

            //Reset all the sorts
            this.formsGridOptions.columnApi.resetColumnState();

            //Set the grid row data
            this.formsGridOptions.api.setRowData(result);
          }
        });
      this.historyGridOptions.api.setRowData([e.data]);

      switch (actionType) {
        case "Selection":
          this.selectDeselectEnvironment(e);
          break;
      }
    }
    
  }

  /**
   * Select/ Deselect the environment
   * @param e
   */
  private selectDeselectEnvironment(e: any): void {
    if (e.event.target.checked) {
      e.data.EnvDeploymentStates[e.event.target.name]["ToBeDeployed"] = true;
    } else {
      e.data.EnvDeploymentStates[e.event.target.name]["ToBeDeployed"] = false;
    }

    e.data.IsDeployDisabled = !this.deployService.checkPackageDirty(e.data);
  }

  /**
   * configure forms grid columns
   */
  private createColumDef(): any[] {
    let columns = [
      {
        headerName: "Package Name",
        field: "PackageName",
        resizable: true,
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          return `<span> ${params.value} </span>`;
        },
      },
      {
        headerName: "LOB",
        field: "LOB",
        resizable: true,
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Release Tag",
        field: "Release",
        resizable: true,
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Created Date",
        field: "CreatedDate",
        resizable: true,
        filter: "dateFilterComponent",
        sortable: true,
        sort: "desc",
        cellRenderer: (params) => {
          return this.dateTimePipe.transform(
            params.value,
            "string",
            "MM/DD/YYYY h:mm A"
          );
        },
      },
      {
        headerName: "Published By",
        field: "CreatedBy",
        resizable: true,
        filter: "agTextColumnFilter",
      },
    ];

    columns = this.addEnvironmentColumns(columns);
    columns = this.addDeployButtonColumn(columns);
    return columns;
  }

  private createHistoryColumnDef(): any[] {
    let columns = [
      {
        headerName: "Package Name",
        field: "PackageName",
        resizable: true,
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          return `<span> ${params.value} </span>`;
        },
      },
      {
        headerName: "LOB",
        field: "LOB",
        resizable: true,
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Release Tag",
        field: "Release",
        resizable: true,
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Created Date",
        field: "CreatedDate",
        resizable: true,
        filter: "dateFilterComponent",
        sortable: true,
        sort: "desc",
        cellRenderer: (params) => {
          return this.dateTimePipe.transform(
            params.value,
            "string",
            "MM/DD/YYYY h:mm A"
          );
        },
      },
      {
        headerName: "Published By",
        field: "CreatedBy",
        resizable: true,
        filter: "agTextColumnFilter",
      },
    ];

    columns = this.addEnvironmentHistoryColumns(columns);
    return columns;
  }

  private addEnvironmentHistoryColumns(columns: any[]): any[] {
    for (let env of this.environmentList) {
      let colDef = {
        headerName: env["Name"],
        field: env["Value"],
        suppressSorting: true,
        filter: "booleanFilterComponent",
        valueGetter: (params) => {
          let isDeployed = this.isDeployedChecked(params.data, env["Value"]);
          let isChecked = this.isToBeDeployedChecked(params.data, env["Value"]);
          return isDeployed || isChecked ? "true" : "false";
        },
        cellRenderer: (params) => {
          let isDeployed = this.isDeployedChecked(params.data, env["Value"]);
          if (!isDeployed) {
            return "No Deployments";
          } else {
            return this.deployedBy(params.data, env["Value"]);
          }
        },
      };

      columns.push(colDef);
    }

    return columns;
  }

  /**
   * Add the environment columns
   * @param columns
   */
  private addEnvironmentColumns(columns: any[]): any[] {
    for (let env of this.environmentList) {
      let colDef = {
        headerName: env["Name"],
        field: env["Value"],
        suppressSorting: true,
        filter: "booleanFilterComponent",
        volatile: true,
        valueGetter: (params) => {
          let isDeployed = this.isDeployedChecked(params.data, env["Value"]);
          let isChecked = this.isToBeDeployedChecked(params.data, env["Value"]);
          return isDeployed || isChecked ? "true" : "false";
        },
        cellStyle: (params) => {
          if (this.isDeployedChecked(params.data, env["Value"])) {
            return { backgroundColor: "lightgreen" };
          }
        },
        cellRenderer: (params) => {
          let isChecked = this.isDeployedChecked(params.data, env["Value"]);
          let isSelected = this.isToBeDeployedChecked(
            params.data,
            env["Value"]
          );
          let deployDate = this.deployedDate(params.data, env["Value"]);
          if (isNullOrUndefined(deployDate)) {
            deployDate = "";
          }

          if (env["IsDev"] === "true") {
            // is this a dev environment
            if (this.authService.PackageDeployAny()) {
              return (
                `<input type='checkbox' ${isSelected ? "checked" : ""} name="${
                  env["Value"]
                }" data-action-type="Selection"/>` + deployDate
              );
            } else {
              return (
                `<input type='checkbox' ${isSelected ? "checked" : ""} name="${
                  env["Value"]
                }" data-action-type="Selection" disabled/>` + deployDate
              );
            }
          } else if (env["IsTest"] === "true") {
            // is this a test environment
            if (this.authService.PackageDeployAny()) {
              return (
                `<input type='checkbox' ${isSelected ? "checked" : ""} name="${
                  env["Value"]
                }" data-action-type="Selection"/>` + deployDate
              );
            } else {
              return (
                `<input type='checkbox' ${isSelected ? "checked" : ""} name="${
                  env["Value"]
                }" data-action-type="Selection" disabled/>` + deployDate
              );
            }
          } else if (env["IsProd"] === "true") {
            // is this the prod environment
            if (this.authService.PackageDeployAny()) {
              return (
                `<input type='checkbox' ${isSelected ? "checked" : ""} name="${
                  env["Value"]
                }" data-action-type="Selection"/>` + deployDate
              );
            } else {
              return (
                `<input type='checkbox' ${isSelected ? "checked" : ""} name="${
                  env["Value"]
                }" data-action-type="Selection" disabled/>` + deployDate
              );
            }
          } else {
            // bad configuration
            throw "Configuration of deploy environments is bad.";
          }
        },
      };

      columns.push(colDef);
    }

    return columns;
  }

  /**
   * Add deploy button column
   * @param columns
   */
  private addDeployButtonColumn(columns: any[]): any[] {
    let copyColDef = {
      headerName: "",
      field: "IsDeployDisabled",
      suppressSorting: true,
      suppressMenu: true,
      cellRenderer: "copyButtonComponent",
    };
    let colDef = {
      headerName: "",
      field: "IsDeployDisabled",
      suppressSorting: true,
      suppressMenu: true,
      cellRenderer: (params) => {
        return `<div style="text-align:left">
    <button
      mat-raised-button
      class="mat-focus-indicator btn-teal mat-raised-button mat-button-base"
      data-action-type="Deploy"
      style="min-width: auto;font-size:12px"
      [disabled]="packageData.IsDeployDisabled"
    >
      Deploy
    </button>
  </div>`
      } ,
    };

    let updateColDef = {
      headerName: "",
      field: "IsDeployDisabled",
      suppressSorting: true,
      suppressMenu: true,
      cellRenderer: "updateButtonComponent",
    };
    if (this.authService.PackageDeployAny()) {
      columns.push(copyColDef);
      columns.push(colDef);
      columns.push(updateColDef);
    }

    return columns;
  }

  /**
   * To check if packages are deployed or not
   * @param packageData PackageData
   * @param environment Environment
   * */
  private isDeployedChecked(
    packageData: DeployPackage,
    environment: string
  ): boolean {
    let isSelected: boolean = false;
    if (
      packageData.EnvDeploymentStates[environment] &&
      packageData.EnvDeploymentStates[environment].IsDeployed
    ) {
      isSelected = true;
    }
    return isSelected;
  }

  /**
   * To check if packages are deployed or not
   * @param packageData PackageData
   * @param environment Environment
   * */
  private isToBeDeployedChecked(
    packageData: DeployPackage,
    environment: string
  ): boolean {
    let isSelected: boolean = false;
    if (
      packageData.EnvDeploymentStates[environment] &&
      packageData.EnvDeploymentStates[environment].ToBeDeployed
    ) {
      isSelected = true;
    }
    return isSelected;
  }

  /**
   * To get deployed by Name
   * @param packageData Package Data
   * */
  private deployedBy(packageData: DeployPackage, environment: string): string {
    let deployedBy: string;
    _.forEach(packageData.DeploymentHistories, (env) => {
      if (env.Target == environment) {
        deployedBy =
          env.DeployedBy +
          "<br/>" +
          this.dateTimePipe.transform(env.DeployedDate);
      }
    });
    return deployedBy;
  }

  private deployedDate(
    packageData: DeployPackage,
    environment: string
  ): string {
    let deployedBy: string;
    _.forEach(packageData.DeploymentHistories, (env) => {
      if (env.Target == environment) {
        deployedBy = "<br/>" + this.dateTimePipe.transform(env.DeployedDate);
      }
    });

    return deployedBy;
  }

  /**
   *  configure forms grid
   */
  private configureFormsGrid(): void {
    this.initialRowDataLoad$ = [];
    this.formsGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: "autoHeight",
      columnDefs: this.createFormsColumDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      paginationPageSize: 10,
      rowHeight: 30,
      enableColResize: true,
      context: { componentParent: this },
      onGridReady: () => {
        this.formsGridOptions.api.setRowData([]);
        this.formsGridOptions.api.sizeColumnsToFit();
      },
      onRowClicked: (event) => {
        this.onRowClick(event);
      },
      onFilterChanged: (event) => {},
      onSortChanged: (event) => {},
      onFilterModified: function (event) {
        //console.log(event);
      },
    };
  }

  /**
   * configure forms grid columns
   */
  private createFormsColumDef(): any[] {
    let columns = [
      {
        headerName: "Form Number",
        field: "InternalFormNumber",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Form Name",
        field: "FormName",
        filter: "agTextColumnFilter",
      },
      { headerName: "LOB", field: "LOB", filter: "agTextColumnFilter" },
      {
        headerName: "Effective Date",
        field: "EffectiveDate",
        filter: "dateFilterComponent",
        sort: "desc",
        cellRenderer: (params) => {
          return this.dateTimePipe.transform(
            params.value,
            "string",
            "MM/DD/YYYY"
          );
        },
      },
      {
        headerName: "Expiration Date",
        field: "ExpirationDate",
        filter: "dateFilterComponent",
        sort: "desc",
        cellRenderer: (params) => {
          return this.dateTimePipe.transform(
            params.value,
            "string",
            "MM/DD/YYYY"
          );
        },
      },
      {
        headerName: "Submission Expiration Date",
        field: "SubmissionExpirationDate",
        filter: "dateFilterComponent",
        sort: "desc",
        cellRenderer: (params) => {
          return this.dateTimePipe.transform(
            params.value,
            "string",
            "MM/DD/YYYY"
          );
        },
      },
      {
        headerName: "Modified Date",
        field: "ModifiedDate",
        filter: "dateFilterComponent",
        sort: "desc",
        cellRenderer: (params) => {
          return this.dateTimePipe.transform(
            params.value,
            "string",
            "MM/DD/YYYY h:mm A"
          );
        },
      },
      {
        headerName: "Modified By",
        field: "ModifiedByName",
        filter: "agTextColumnFilter",
      },
    ];

    return columns;
  }
}
