export class TestGroup {
  public Id: string;
  public DocumentType: string;
  public Name: string;
  public Description: string;
  public LOBs: string[];
  public TestGroupType: string;
  public FastKey: string;
  public CreationDate: number;
  public CreatedByName: string;
  public ModifiedDate: number;
  public ModifiedByName: string;
}
