
export class TestHarnessTestCase {
  public id: string;
  public DocumentType: string;
  public Name: string;
  public Description: string;
  public TestGroups: string[] = [];  // TestGroup Id's
  public Forms: string[] = [];
  public CPM: string;
  public CreationDate: any;
  public CreatedByName: string;
  public ModifiedDate: number;
  public ModifiedByName: string;
  public IsSelected: boolean;
}
