//Angular Imports 
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { MsalGuard } from '../msal/msal-guard.service';

//Internal Imports
import { MainLayoutComponent } from './components';
import { AuthenticationResolver } from '../framework';
import { AuthGuard } from '../framework/guards/auth.guard';
import { FormPageModule } from '../form-page';
import { PolicyFormsModule } from '../policy-forms';
import { PrintOrderModule } from '../printorder';
import { PackageModule } from '../package';
import { DeployModule } from '../deploy';
import { ExecutionModule } from '../../app/diagnostics/testSuites/execution/execution.module';
import { CPMElementsModule } from '../cpmMapping';
import { ReportModule } from '../report/report.module';
import { ProductsModule } from '../products/products.module';
import { ImportModule } from '../import';

/**
  Main Layout Routes
*/
//export const routes: Routes = [
//    {
//    path: '', component: MainLayoutComponent,
//    resolve: {
//      token: AuthenticationResolver
//    },
//    children: [
//      {
//        path: 'mapping', canActivate: [AuthGuard],
//        loadChildren: '../forms-rules-mapping/forms-rules-mapping.module#FormsRulesMappingModule'
//      },
//      {
//        // No AuthGuard on forms path because we assume anyone can access this page in at least read-only mode
//        path: 'forms', canActivate: [MsalGuard],d
//        loadChildren: '../policy-forms/policy-forms.module#PolicyFormsModule'
//      },
//      {
//        path: 'rules', canActivate: [AuthGuard],
//        loadChildren: '../rules/rules.module#RulesModule'
//      },
//      {
//        path: 'package', canActivate: [AuthGuard],
//        loadChildren: '../package/package.module#PackageModule'
//      },
//      {
//        path: 'deploy', canActivate: [AuthGuard],
//        loadChildren: '../deploy/deploy.module#DeployModule'
//      },
//      {
//        path: 'execution', canActivate: [AuthGuard],
//        loadChildren: '../execution/execution.module#ExecutionModule'
//      },
//      {
//        path: '**',
//        redirectTo: 'forms',
//        pathMatch: 'full'
//      }
//        ]
//    }
//];

export const routes: Routes = [
  {
    path: '', component: MainLayoutComponent,
    resolve: {
      token: AuthenticationResolver
    },
    children: [
      {
        path: 'formBuilder', canActivate: [MsalGuard, AuthGuard],
        loadChildren: () => FormPageModule
      },
      {
        // No AuthGuard on forms path because we assume anyone can access this page in at least read-only mode
        path: 'forms', canActivate: [MsalGuard],
        loadChildren: () => PolicyFormsModule
      },
      {
        path: 'printOrder', canActivate: [MsalGuard, AuthGuard],
        loadChildren: () => PrintOrderModule
      },
      {
        path: 'package', canActivate: [MsalGuard, AuthGuard],
        loadChildren:() => PackageModule
      },
      {
        path: 'deploy', canActivate: [MsalGuard, AuthGuard],
        loadChildren: () => DeployModule
      },
      {
        path: 'execution', canActivate: [MsalGuard, AuthGuard],
        loadChildren: () => ExecutionModule
      },
      {
        path: 'cpm', canActivate: [MsalGuard, AuthGuard],
        loadChildren: () => CPMElementsModule
      },
      {
        path: 'report', canActivate: [MsalGuard],
        loadChildren: () => ReportModule
      },
      {
        path: 'products', canActivate: [MsalGuard],
        loadChildren: () => ProductsModule
      },
      {
        path: 'import', canActivate: [MsalGuard],
        loadChildren: () => ImportModule
      },
      {
        path: '**',
        redirectTo: 'formBuilder',
        pathMatch: 'full'
      }
    ]
  }
];

/**
  Main Layout Routing Module
*/
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthenticationResolver]
})

export class MainLayoutRoutingModule {
}
