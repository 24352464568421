//Angular Imports
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

//RxJS imports
import { Observable } from 'rxjs';

//Internal Imports
import { SharedService } from '../services';
import { SkippableInterceptorHeader } from '../enums';


/**
  * Intercepts all Http Requests to display the loader 
*/
@Injectable()

export class ShowLoaderInterceptor implements HttpInterceptor {

  /**
   * Constructor
   * @ignore
  */
  constructor(private sharedService: SharedService) { }

  /**
   * Intercepts all the HTTP requests and shows thw loader
   * @param {HttpRequest} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>} Http Event Observable
  */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let headers = request.headers;

    // Show the loader for all http requests except skippable ones 
    if (!headers || !headers.get(SkippableInterceptorHeader.ShowLoaderSkipHeader)) {
      this.sharedService.showLoader();
    }
    else {
      //delete the header if set

      request = request.clone({
        headers: headers.delete(SkippableInterceptorHeader.ShowLoaderSkipHeader),
        url: request.url
      });
    }

    //Pass to next interceptor
    return next.handle(request);
  }
}
