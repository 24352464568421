//Angular Imports
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

//RxJS Imports
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";

//Internal Imports
import { EnvironmentService } from "./environment.service";
import { SkippableInterceptorHeader } from "../enums";

/**
  * Handles authentication related calls
*/
@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {

  //Private Variables
  /**
   * Stores the JWT token 
  */
  private _token: string;

  //Public Variables 
  /**
   * Setter for token 
  */
  set token(value: string) {
    this._token = value;
  }
  /**
   * Getter for token 
  */
  get token(): string {
    return this._token;
  }


  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient, private environmentService: EnvironmentService) {
  }

  //Public Methods
  /**
   * Authenticates and initializes app insights telemetry 
   * @returns {Observable<string>} Bearer Token Observable
  */
  authenticate(): Observable<string> {

    let headers = new HttpHeaders().set(SkippableInterceptorHeader.JWTSkipHeader, 'true')
      .set(SkippableInterceptorHeader.AppInsightsSkipHeader, 'true')
      .set('Content-Type', 'application/json');

    //set the key from environment details 
    let url = 'api/Token?apiKey=' + this.environmentService.environmentDetails.API_KEY;
    
    return this.http.get<any>(url, { headers: headers }).pipe(
      map(res => {
        //Result is an object and token is sent in token property 
        this._token = <string>res.token;
        return this._token;
      }));
  }
}
