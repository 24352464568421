import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DuplicateService } from './duplicate.service';

/**
 * Deploy Confirmation Component
*/
@Component({
  selector: 'duplicate-checker',
  templateUrl: './duplicate.component.html',
  styleUrls: ['./duplicate.component.scss']
})

export class DuplicateComponent implements OnInit {

  constructor(private duplicateService: DuplicateService, private toastr: ToastrService) { }

  ngOnInit() {
  }

  public checkDuplicates(selectedEnv: string) {
    this.duplicateService.getJwt(selectedEnv).subscribe((t) => {
      this.duplicateService.checkDuplicate(selectedEnv, t).subscribe(
        duplicateResult => {
          console.log(duplicateResult);
          if (duplicateResult.length) {
            var errors = duplicateResult.map((dupe, i) => {
              return `${i + 1}. ${dupe.DocumentName} in ${dupe.PackageName}`
            }).join("</br>");
            this.toastr.error(`There are duplicates found in ${selectedEnv}:</br>${errors}`, 'Error',
              {
                positionClass: "toast-top-right",
                disableTimeOut: true,
                enableHtml: true
              }
            );
          }
        },
        err => {
          console.log(err);
        });
    });
  }
}
