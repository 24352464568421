import { Component, OnInit, ViewChild } from '@angular/core';
import { ColDef, SelectionChangedEvent } from 'ag-grid-community';
import { GridOptions } from "ag-grid-community";
import { QueueService } from "../../services/queue.service";
import { DetailTriggerComponent } from "../detail-trigger/detail-trigger.component";
import { MatSnackBar } from '@angular/material/snack-bar';
import { FilterData } from '../../models/filter-data';


@Component({
  selector: 'app-master-view',
  templateUrl: './master-view.component.html',
  styleUrls: ['./master-view.component.scss']
})
export class MasterViewComponent implements OnInit {

  activeTab: number = 0;
  detailTabs: string[] = [];
  isSuccess: boolean = false;
  isFetchingData: boolean = false;
  showTableError: boolean = false;
  showFilterError: boolean = false;
  selectedCount: number = 0;
  environment: string = '';
  lastFilter: FilterData;
  currentDate: any = new Date();
  pastDate: any = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), (this.currentDate.getDate() - 7), 0, 0, 0, 0);

  private gridApi: any;
  gridOptions: any;
  frameworkComponents: any;
  columnDefs: ColDef[];
  rowSelection: string = 'multiple';
  pagination: true;
  paginationPageSize: 10;
  paginationAutoPageSize: true;
  paginateChildRows: true;
  
  rowData: any[] = [];

  @ViewChild('filterOptions') filterOptions: any;

  constructor(private queueService: QueueService, private snackBar: MatSnackBar) {
    let self = this;
    this.gridOptions = <GridOptions>{
      // This setting allows the grid (and any renderers in the grid like "detail-trigger') to reference this component
      context: { componentParent: this },
      EnablePagination: true,
      pagination: true,
      paginationAutoPageSize: true,
      paginationPageSize: 18,
      paginateChildRows: true,
      isRowSelectable: (rowNode) => {
        return rowNode.data ? rowNode.data.requestStatus == 'ERROR' : false;
      },
      onFirstDataRendered: function () {
        self.gridOptions.api.sizeColumnsToFit();       
      }
    }

    // Creates a reference to the "detail-trigger" component
    this.frameworkComponents = {
      detailTriggerRenderer: DetailTriggerComponent
    };

    //Defines the columns for the AG-Grid
    this.columnDefs = [
      {
        field: 'orchestrationRequestId', headerName: "Request ID", sortable: true, filter: true, resizable: true,
        checkboxSelection: true, // Adds the checkboxes in the column},
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
      },
      {
        field: 'orchestrationUUID', headerName: "UUID", sortable: true, filter: true, resizable: true, width: 300,
        cellRenderer: 'detailTriggerRenderer' // Directs AG-Grid to use the "detail-trigger" component for the UI content in this column
      },
      {
        field: 'requestStatus', headerName: "Request Status", sortable: true, filter: true, resizable: true
      },
      {
        field: 'lastupdated', headerName: "Last Updated", sortable: true, sort: "desc", filter: true, resizable: true,
        cellRenderer: params => {
          return this.queueService.formatTimestamp(params.value)
        }        
      },
      { field: 'SourceSystem', headerName: "Source System", sortable: true, filter: true, resizable: true },
      { field: 'retrycount', headerName: "Retry Count", sortable: true, filter: true, resizable: true }
    ];
    
  }

  ngOnInit(): void {
    let initialFilter = new FilterData({
      fromDate: this.queueService.formatFilterRequestDate( this.pastDate, "start" ),
      toDate: this.queueService.formatFilterRequestDate( this.currentDate, "end" ),
      requestStatus: this.queueService.getProblemStatuses()
    });
    this.getSummaryData(initialFilter);
    this.environment = this.getEnvironment();
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
    // If currently fetching data, show the grid "Loading" message
    if (this.isFetchingData) {
      this.toggleGridLoadStatus(true);
    }    
  }

  toggleGridLoadStatus(loading: boolean): void {
    if (this.gridApi) {
      loading ? this.gridApi.showLoadingOverlay() : this.gridApi.hideOverlay();
    }
  }

  getSummaryData(filterData: FilterData) {
    this.lastFilter = new FilterData(filterData);
    this.selectedCount = 0;
    this.isFetchingData = true;
    this.showTableError = false;
    this.toggleGridLoadStatus(true);
    this.queueService.getSummaryData(filterData)
      .subscribe(
        (response: any) => {
          this.isFetchingData = false;
          this.rowData = response;
          this.toggleGridLoadStatus(false);
        },
        // Handle an error response
        error => {
          this.showTableError = true;
          this.isFetchingData = false;
          this.toggleGridLoadStatus(false);
        }
      );
    
  }


  changeActiveTab(tabIndex: number) {
    this.activeTab = tabIndex;
  }

  addTab(idBundle: any) {
    this.detailTabs.push(idBundle);
    this.activeTab = (this.detailTabs.length + 1 ) - 1
  }

  closeTab(idx: number) {
    event.preventDefault();
    this.detailTabs.splice(idx, 1);
    if (!this.detailTabs.length) {
      this.activeTab = 0;
    }
  }

  onSelectionChanged(event: SelectionChangedEvent) {
    let sCount = event.api.getSelectedNodes().length;
    this.selectedCount = sCount ? sCount : 0;
  }

  canPerformReset(): boolean {
    return ( this.selectedCount > 0 && this.queueService.isAuthorized('resetRequest') );
  }

  resetTransactions(s): any {
    let requestIDs = [];
    let selections: any = this.gridApi.getSelectedNodes();
    this.isSuccess = true;
    selections.forEach((node: any) => {
      requestIDs.push(node.data.orchestrationRequestId)
    })
    
    this.queueService.resetStatus(requestIDs).subscribe(
      res => {
        this.snackBar.open("Reset(s) successful", "Close", { duration: 1000, panelClass: ['reset-message-bar'] });
        this.selectedCount = 0;
        this.getSummaryData( this.lastFilter );
      },
      error => {
        this.snackBar.open("An error occurred during the reset process. Please try again.", "Close", { duration: 3000, panelClass: ['reset-message-bar'] });
      }
    );
    
  }
  getEnvironment(): any {
    this.queueService.getEnvironment().subscribe(
      res => {
        // Pull the environment from the URL (prod will not have one)
        let envSplit = res.split("-");
        if (envSplit.length == 2) {
          let env = envSplit[1].split(".")[0]
          this.environment = env.charAt(0).toUpperCase() + env.slice(1);
        } else {
          this.environment = "";
        }
      },
      error => {
        this.environment = 'Environment data unavailable'
      },
    )
  }

  toggleFilterErrorState(filterError: boolean): void {
    this.showFilterError = filterError;
  }

  retryFilterOptions(): void {
    event.preventDefault();
    this.filterOptions.reInit();
  }

}
