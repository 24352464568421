//Angular Imports
import { Component, HostBinding } from "@angular/core";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";

//Third Party Imports
import { GridOptions } from "ag-grid-community";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";

//Internal Imports
import {
  ApplicationInsightsBaseComponent,
  AppInsightsService,
  SharedService,
  DateFilterComponent,
  BooleanFilterComponent,
  DateTimePipe,
  AuthorizationService,
} from "../../../framework";
import { PrintOrderMasterViewModel } from "../../models";
import { PrintOrderService } from "../../services";
import { AddPrintOrderComponent } from "../add-print-order";

/**
 * Forms Component
 */
@Component({
  selector: "print-orders",
  templateUrl: "./print-order.component.html",
  styleUrls: ["./print-order.component.scss"],
})
export class PrintOrderComponent extends ApplicationInsightsBaseComponent {
  //Public Variables
  /**
   * Host binding for width
   */
  @HostBinding("style.width") width: string;
  public printOrderGridOptions: GridOptions;
  public printOrderList: PrintOrderMasterViewModel[];
  public currentPrintOrder: PrintOrderMasterViewModel;
  lineOfBusinessList: any[];
  public selectedLOB: string;
  initialRowDataLoad$;

  /**
   * Constructor
   * @ignore
   */
  constructor(
    private _appInsightsService: AppInsightsService,
    private toastr: ToastrService,
    private printOrderService: PrintOrderService,
    private _modal: NgbModal,
    private sharedService: SharedService,
    private authService: AuthorizationService,
    private dateTimePipe: DateTimePipe
  ) {
    super(_appInsightsService);
    this.width = "100%";
  }

  get PrintOrderWritePermission(): boolean {
    return this.authService.FormsWrite();
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
   */
  ngOnInit(): void {
    //console.log("In policy-forms component Init - FormsWritePermission: " + this.authService.FormsWrite());

    this.sharedService.getMasterData().subscribe((el) => {
      this.lineOfBusinessList = _.clone(el.LineOfBusinessList);

      if (this.lineOfBusinessList && this.lineOfBusinessList.length > 0) {
        this.lineOfBusinessList.sort((a: any, b: any) => {
          var nameA = a.Name.toLowerCase();
          var nameB = b.Name.toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
        // var index = this.lineOfBusinessList.filter(x=> x.Name == "All").Name;
        this.selectedLOB = this.lineOfBusinessList[1].Name;
        this.getPrintOrderMasterList(this.selectedLOB);
      }
    });

    this.configureGrid();
  }

  /**
   * NgDestroy
   * @ignore
   */
  ngOnDestroy(): void {}

  //Public Methods
  onLOBChange(selectedvalue) {
    this.getPrintOrderMasterList(selectedvalue.Name);
  }

  //Private Methods
  /**
   * configure grid
   */
  private configureGrid(): void {
    this.initialRowDataLoad$ = [];
    this.printOrderGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: "autoHeight",
      columnDefs: this.createColumDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      enableColResize: true,
      paginationPageSize: 20,
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent,
      },
      onGridReady: () => {
        this.printOrderGridOptions.api.setRowData([]);
        this.printOrderGridOptions.api.sizeColumnsToFit();
        // this.gridOptions.api.softRefreshView();
      },
      onRowClicked: (event) => {
        this.onRowClicked(event);
      },
      onFilterChanged: (event) => {
        //Api call to get the filter data
        //console.log(event);
      },
      onSortChanged: (event) => {},
      onFilterModified: function (event) {
        //console.log(event);
      },
    };
  }

  /**
   * Configure Columns
   */
  private createColumDef(): any[] {
    let result: any[] = [
      //{
      //  headerName: 'Line of Business', field: 'LineOfBusiness', filter: 'agTextColumnFilter', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); },
      //  cellRenderer: params => {
      //    return this.lineOfBusinessList.find(l => l.Name == this.selectedLOB).Name;
      //  }
      //},
      {
        headerName: "Form Type",
        field: "FormType",
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
      { headerName: "Print Order", field: "PrintOrder" },
      { headerName: "Description", field: "Description" },
      ,
      {
        headerName: "Actions",
        suppressMenu: true,
        width: 80,
        cellStyle: { display: "flex", "justify-content": "center" },
        suppressSorting: true,
        hide: !this.PrintOrderWritePermission, // Hide column if user does not have write permission
        template: `
         <img src="/assets/images/edit_icon.png" data-action-type="Edit" class="cursor_pointer mrgrgt10" title="Edit" style="width: 20px;">
         <img src="/assets/images/copy_icon.png" data-action-type="Copy" class="cursor_pointer" title="Copy" style="width: 20px;">
           `,
      },
    ];

    return result;
  }

  /**
   * On row click to edit form data
   * @param e
   */
  private onRowClicked(e) {
    if (e.event.target !== undefined) {
      let data = e.data;
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "Edit":
          return this.editPrintOrder("Edit", data);
        case "Copy":
          return this.editPrintOrder("Copy", data);
      }
    }
  }

  /**
   * Get Master list of Policy Forms based on lob name
   * @param lobName
   */
  private getPrintOrderMasterList(lobName: string) {
    /*Check if lob name contains '&' symbol then encode with %26.This will fix value truncating issue with querstring parameter */
    if (lobName.indexOf("&") > -1) {
      lobName = lobName.replace("&", "%26");
    }

    this.printOrderService.getPrintOrders(lobName).subscribe(
      (res: PrintOrderMasterViewModel[]) => {
        this.printOrderList = res;
        this.setRowData();
      },
      (error) => {
        this.toastr.error(
          "Error occurred while fetching print order data.",
          "Print Order"
        );
      }
    );
  }

  /**
   *Set data to grid
   * */
  private setRowData() {
    if (this.printOrderList != null) {
      this.printOrderList.sort((a: any, b: any) => {
        var nameA = a.FormType.toLowerCase();
        var nameB = b.FormType.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      this.printOrderGridOptions.api.setRowData(this.printOrderList);
    }
  }

  /**
   * To edit print order form data
   * @param string actionType
   * @param any printOrder
   */
  private editPrintOrder(actionType: string, printOrder: any = null) {
    this.openPrintOrder(actionType, printOrder);
  }

  /**
   * Opens Add-Policy-Form Modal
   * @param string actionType
   * @param any policyForm
   * @returns number index
   */
  public openPrintOrder(actionType: string, printOrder: any = null) {
    const modalRef = this._modal.open(AddPrintOrderComponent, {
      backdrop: "static",
      size: "lg",
    });
    modalRef.componentInstance.actionType = actionType;
    modalRef.componentInstance.currentPrintOrder = printOrder;
    modalRef.componentInstance.selectedLOB = this.selectedLOB;
    modalRef.result.then((result: any) => {
      if (result) {
        this.getPrintOrderMasterList(this.selectedLOB);
        this.currentPrintOrder = result;
      }
    });
  }
}
