//Angular Imports 
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SystemRules } from "../../rules/models/systemRules";



/**
  * Policy Forms Provider
*/
@Injectable()
export class EmailService {
  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient) {
  }

  getEmailSettings(): Observable<SystemRules[]> {
    let url = 'api/Configs/GetEmailSettings';
    return this.http.get<SystemRules[]>(url).pipe(map(res => {
      return res;
    }));
  }

  updateEmailSetting(rule: SystemRules): Observable<any> {
    let url = 'api/Configs/UpdateEmailSettings';
    return this.http.post<SystemRules>(url, rule).pipe(map(res => {
      return res;
    }));
  }

  clearCache() {
    var url = `https://mkl-doc-queue-dev2.azurewebsites.net/api/cache/clear`;
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'text/plain');
    return this.http.get(url, { headers, responseType: 'text' }).pipe(map(res => res));
  }

}
