export class DiagnosticLog {
  public Timestamp: string;
  public SourceSystem: string;
  public Environment: string;
  public HasError: string;
  public Error: string;
  public InternalID: string;
  public ActionType: string;
  public CallingSystem: string;
}

export class TestHarnessLogWithEnv {
  public XmlData: string;
  public TimeStamp: string;
  public ActionType: string;
  public hasError: boolean;
  public ErrorMessage: string;
  public Forms: string[];
  public Environment: string;
  public InternalID: string;
  public CallingSystem: string;
  public TransactionId: string;
  public PolicyNumber: string;
}

export class IssuanceLog {
  public Id: number;
  public Date: string;
  public Thread: string;
  public Level: string;
  public Logger: string;
  public Message: string;
  public Exception: string;
  public HostName: string;
  public AppDomain: string;
  public SessionID: string;
  public MessageType: string;
  public Originator: string;
  public ClientBrowser: string;
  public ScreenSize: string;
  public ClientIP: string;
  public ClientOS: string;
  public UserName: string;
  public DealID: string;
  public DealComponentID: string;
  public DealComponentGUID: string;
}
