//Angular Imports 
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { DiagnosticsMainComponent } from './components/diagnostics-main.component';
import { AuthenticationResolver } from '../../framework';

//Internal Imports

/**
  Form Routes
*/
export const routes: Routes = [
  {
    path: '', component: DiagnosticsMainComponent,
    resolve: {
      token: AuthenticationResolver
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthenticationResolver]
})

export class DiagnositcsMainRoutingModule {
}
