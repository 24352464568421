//Angular Imports 
import { Injectable } from '@angular/core';

//rxjs Imports 
import { Observable, Subject } from 'rxjs';

//Internal Imports
import { GhostPortalMessage } from '../models/gp-message.model';

/**
  * Provides methods to send message to Menu  
*/
@Injectable({ providedIn: 'root' })

export class GhostPortalService {

  //Private Variables
  /**
   * Message Subject for communication between components
  */
  private subject;

  /**
   * Constructor
   * @ignore
  */
  constructor() {
    this.subject = new Subject<GhostPortalMessage>();
  };

  //Public Methods
  /**
   * Send Message
   * @param {GhostPortalMessage} message
   * @returns {void} 
  */
  sendGhostPortalMessage(message: GhostPortalMessage): void {
    this.subject.next(message);
  }

  /**
   * Get Message 
   * @returns {Observable<GhostPortalMessage>} Message Observable
  */
  getGhostPortalMessage(): Observable<GhostPortalMessage> {
    return this.subject.asObservable();
  }
}
