//Angular Imports
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { Clipboard } from "@angular/cdk/clipboard";

//Third Party Imports
import { GridOptions } from "ag-grid-community";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";

//Internal Imports
import { UserProfileService, SelectableItem } from "../../../../framework";
import {
  RuleEngineResult,
  RuleEngineLog,
  TestExpectedResults,
} from "../../../testSuites/execution/models";
import {
  coverageCpmElementId,
  lobCpmElementId,
} from "../../../configs/execution-engine.config";

import "rxjs";
import { DiagnosticsMainService } from "../../services";
import {
  AttachExpectedResults,
  AttachTestLog,
} from "../../../models/attachLogDetailModel";

/**
 * Forms Component
 */
@Component({
  selector: "app-attach-log-detail",
  templateUrl: "./attach-log-detail.component.html",
  styleUrls: ["./attach-log-detail.component.scss"],
})
export class AttachLogDetail {
  // Specifies the action type the screen was opened for (Add, Edit)
  @Input() public actionType: string;

  // The log item
  @Input() public logItem: RuleEngineLog;

  public testLog: AttachTestLog = new AttachTestLog();
  public testResults: AttachExpectedResults[] = [];

  // Policy Effective Date of the CPM
  public policyEffectiveDate: string;

  optionalFormCount: number = 0;
  mandatoryFormCount: number = 0;

  currentResults: TestExpectedResults;

  public cpmIsXML: boolean = false;

  public policyEffectiveString: string = "";
  public optionalFormResults: any[] = [];
  public mandatoryFormResults: any[] = [];
  public lobResults: string = "";
  public coverageResults: string = "";
  public selectedLOB: string = "";
  public jsonStringcopy: any;
  public rawJSON: string = "";
  firstTimeExpanded = true;
  isFirstPanelExpanded = true;
  isSecondPanelExpanded = false;
  /**
   * Constructor
   * @ignore
   */
  constructor(
    public activeModal: NgbActiveModal,
    private userProfileService: UserProfileService,
    private diagnosticService: DiagnosticsMainService,
    private _modal: NgbModal,
    private toastr: ToastrService,
    private clipboard: Clipboard
  ) {}

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
   */
  ngOnInit(): void {
    this.populateCpmData();
    this.createExpectedResults();
  }

  /**
   * NgOnDestroy
   * @ignore
   */
  ngOnDestroy(): void {}

  //Populate mandatory and optional form data
  private createExpectedResults() {
    let expectedResults: AttachExpectedResults[] = [];

    if (this.logItem && this.logItem.RuleEngineResults) {
      let ruleEngineResults: RuleEngineResult[] = JSON.parse(
        this.logItem.RuleEngineResults
      );
      if (ruleEngineResults && ruleEngineResults.length) {
        for (var result of ruleEngineResults) {
          let newResult: AttachExpectedResults = new AttachExpectedResults();
          newResult.QuoteOption = result.QuoteOption;
          newResult.LOB = result.LOB;
          expectedResults.push(newResult);

          for (var form of result.FinalResults) {
            if (form.AttachmentReason === "Mandatory") {
              newResult.MandatoryForms.push(form.InternalFormNumber);
            } else {
              newResult.OptionalForms.push(form.InternalFormNumber);
            }
          } // end each form
        }
      }
    }

    //Once expected results are populated we can set the data to display
    this.testLog.ExpectedResults = expectedResults;
    this.selectedLOB = this.testLog.ExpectedResults[0].LOB;
    this.currentResults = this.testLog.ExpectedResults[0];
    this.createSummaryGridResults(this.currentResults);
  }

  //Parse relevant transaction data from CPM, reaches out to FAST API to get easy-to-parse CPM data
  private populateCpmData(): void {
    this.diagnosticService
      .getCpmDataForTestCase(this.logItem.CPM)
      .subscribe((res) => {
        this.testLog.LOBs = res.ElementValues.LineOfBusiness;
        this.testLog.Coverages = res.ElementValues.Coverage;
        this.testLog.WritingCompany = res.ElementValues.WritingCompany[0];
        this.testLog.State = res.ElementValues.State[0];
        this.testLog.PolicyEffectiveDate = res.EffectiveDate;
        this.policyEffectiveString = new Date(
          this.testLog.PolicyEffectiveDate
        ).toDateString();
        this.testLog.CPM = this.logItem.CPM;

        // JSON
        if (!library) var library: any = {};

        library.json = {
          replacer: function (match, pIndent, pKey, pVal, pEnd) {
            var key = "<span class=json-key>";
            var val = "<span class=json-value>";
            var str = "<span class=json-string>";
            var r = pIndent || "";
            if (pKey) r = r + key + pKey.replace(/[": ]/g, "") + "</span>: ";
            if (pVal) r = r + (pVal[0] == '"' ? str : val) + pVal + "</span>";
            return r + (pEnd || "");
          },
          prettyPrint: function (obj) {
            var jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/gm;
            return JSON.stringify(obj, null, 3)
              .replace(/&/g, "&amp;")
              .replace(/\\"/g, "&quot;")
              .replace(/</g, "&lt;")
              .replace(/>/g, "&gt;")
              .replace(jsonLine, library.json.replacer);
          },
        };

        if (this.testLog.CPM.startsWith("<")) {
          this.cpmIsXML = true;
        } else {
          this.jsonStringcopy = JSON.parse(JSON.stringify(this.logItem.CPM));
          this.testLog.CPM = JSON.parse(this.logItem.CPM);
          this.testLog.CPM = library.json.prettyPrint(this.testLog.CPM);
          document.getElementById("JSONtestLogCPM").innerHTML =
            this.testLog.CPM;
          this.cpmIsXML = false;
        }

        this.lobResults = "";
        this.coverageResults = "";
        for (var lob of this.testLog.LOBs) {
          this.lobResults = this.lobResults + lob + ", ";
        }
        this.lobResults = this.lobResults.trim();
        this.lobResults = this.lobResults.substr(0, this.lobResults.length - 1);

        for (var coverage of this.testLog.Coverages) {
          this.coverageResults = this.coverageResults + coverage + ", ";
        }
        this.coverageResults = this.coverageResults.trim();
        this.coverageResults = this.coverageResults.substr(
          0,
          this.coverageResults.length - 1
        );
      });
  }

  //Generate Expected Results
  createSummaryGridResults(expectedResult: AttachExpectedResults) {
    this.mandatoryFormCount = 0;
    this.optionalFormCount = 0;
    this.mandatoryFormResults = [];
    this.optionalFormResults = [];
    this.diagnosticService
      .getAllFormNumbersByLob(expectedResult.LOB)
      .subscribe((res) => {
        console.log(res);
        for (var form of res) {
          // Create Mandatory Items
          let mandatoryItem: SelectableItem = new SelectableItem();
          mandatoryItem.Code = form.InternalFormNumber;
          mandatoryItem.VisibleDescription = form.InternalFormNumber;
          if (
            expectedResult.MandatoryForms.find(
              (x) => x === form.InternalFormNumber
            )
          ) {
            mandatoryItem.Selected = true;
            this.mandatoryFormCount++;
            this.mandatoryFormResults.push(form.InternalFormNumber);
          }

          // Create Optional Items
          let optionalItem: SelectableItem = new SelectableItem();
          optionalItem.Code = form.InternalFormNumber;
          optionalItem.VisibleDescription = form.InternalFormNumber;
          if (
            expectedResult.OptionalForms.find(
              (x) => x === form.InternalFormNumber
            )
          ) {
            optionalItem.Selected = true;
            this.optionalFormCount++;
            this.optionalFormResults.push(form.InternalFormNumber);
          }
        }
      });
  }

  copyErrorText(): void {
    if (this.cpmIsXML) {
      this.clipboard.copy(this.testLog.CPM);
    }
    else {
      this.clipboard.copy(this.jsonStringcopy);
    }
    
  }

  openTestSuite(): void {
    this.activeModal.close("Test");
  }

  //changes expected forms results for the selected LOB
  onLOBChange(event: any): void {
    if (event.event.target !== undefined) {
      //let data: TestExpectedResults = event.data;
      this.currentResults = event.data;
      this.createSummaryGridResults(this.currentResults);
    }
  }

  panelOpened(panel: string) {
    if (panel === 'first' && !this.firstTimeExpanded) {
      this.isFirstPanelExpanded = !this.isFirstPanelExpanded;
      if (this.isFirstPanelExpanded && this.isSecondPanelExpanded) {
        this.isSecondPanelExpanded = false; // Collapse the second panel if the first one is expanded
      }
    } else if (panel === 'second') {
      this.isSecondPanelExpanded = !this.isSecondPanelExpanded;
      if (this.isSecondPanelExpanded && this.isFirstPanelExpanded) {
        this.isFirstPanelExpanded = false; // Collapse the first panel if the second one is expanded
      }
    } else {
      this.firstTimeExpanded = false;
    }
  }
}
