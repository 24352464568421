<div>
  <!-- Heading row showing Name and LOBs -->
  <a href="https://markel.atlassian.net/wiki/spaces/ITFO/pages/33194254/FAST+Guidelines+for+Lob+s" target="_blank">Shared LOB Documentation</a>
  <div class="row" style="margin-bottom: 20px">
    <!--
    <div class="col-md-6">
      <div style="margin-left: 50px; float: left; font-weight: bold; padding-right: 15px; padding-bottom: 15px">
        Name:
      </div>
      <div style="margin-left: 15px">
        {{currentRuleSetup.Name}}
      </div>
    </div>
        -->

    <div class="col-md-4 fast_admin">
      <label style="font-weight: bold" class="label_heading">Rule Setup</label>
      <div class="selectdiv select_wrapinline" style="width: 100%">
        <label>
          <ng-select id="ruleSetupList"
                     class="custom-select"
                     style="width: 100%"
                     [items]="currentForm.RuleSetups"
                     bindLabel="Name"
                     [(ngModel)]="currentRuleSetup"
                     (change)="onChangeRuleSetup($event)">
          </ng-select>
        </label>
      </div>
    </div>

    <div class="btn_wrapper col-md-8" style="margin-top: 10px;padding-right: 0;">
      <!-- Add new rule dropdown -->
      <div class="row" style="margin-left: 0;">
        <div>
          <b *ngIf="this.effectiveDate != 0">Effective Date: {{ this.effectiveDate | dateTimeUtcPipe }}</b>
          <b *ngIf="this.expirationDate != 0" style="padding-left: 10px">Expiration Date: {{ this.expirationDate | dateTimeUtcPipe }}</b>
          <b *ngIf="this.submissionExpirationDate != 0" style="padding-left: 10px">
            Submission Expiration Date:
            {{ this.submissionExpirationDate | dateTimeUtcPipe }}
          </b>
        </div>
        <div class="col-md-3" style="margin-top: 35px">
          <div ngbDropdown class="d-inline-block" class="mrgrgt10">
            <button class="btn btn-outline-primary"
                    id="dropdownBasic1"
                    ngbDropdownToggle
                    style="
                border: none;
                width: 75%;
                box-shadow: 0 0 3px #000;
                color: #000;
              ">
              Add Rule
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button class="dropdown-item" (click)="onAddRule('basic_attach')">
                Basic Attach
              </button>
              <button class="dropdown-item" (click)="onAddRule('basic_remove')">
                Basic Remove
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-4" style="margin-top: 35px; padding: 0;">
          <button type="button"
                  mat-raised-button
                  class="btn-teal mrgrgt10"
                  (click)="onSaveRuleSetup()"
                  *ngIf="FormsWritePermission">
            Save
          </button>
          <button type="button"
                  mat-raised-button
                  class="btn-teal"
                  (click)="deleteRuleSetup()"
                  *ngIf="FormsWritePermission"
                  style="float: right;">
            Delete Rule Setup
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Heading row -->
  <!-- Content section -->


  <div class="additional-filters" style=" width:20%; display: inline-block; float: left; ">

    <div class="additional-filters" style="display: flex; flex-direction: column; height: calc(100vh - 233px); overflow-y: scroll;">
      <div class="additional-filters-header">
        <span class="filter-heading">Filters</span>
        <span class="reset-button" (click)="clearAll()">Reset</span>
      </div>
      <div class="select_default expression_element"
           style="display: inline-block">
        <label style="display:block">
          <ng-select id="elementList"
                     style="min-width: 200px"
                     [items]="elementList"
                     bindLabel="CPMDataElementName"
                     bindValue="CPMDataElementName"
                     [(ngModel)]="selectedElementName"
                     (change)="onElementChanged($event)"
                     [clearable]="true"
                     placeholder="Select elements to filter"></ng-select>
        </label>
      </div>
      <span style="border-top:1px solid #000; width:auto; "></span>
      <div class="select_default expression_element"
           style="display: inline-block; padding:5px;" *ngFor="let ef of this.elementFilters">
        <label class="label_heading">{{ef.ElementName}}</label>
        <button (click)="removeFilter(ef)"
                style="background: none; border: none; font-size: 16px; margin-left: 5px; cursor: pointer;" *ngIf="ef.ElementName != 'Writing Company'">
          &times;
        </button>
        <label style="display:block">
          <ng-select id="elementList"
                     style="min-width: 200px"
                     [items]="ef.ElementValues"
                     bindLabel="e2Description"
                     bindValue="Code"
                     [(ngModel)]="ef.SelectedElementValue"
                     (change)="onFilterElementChange($event)"
                     [clearable]="true"
                     placeholder="Select value to filter"></ng-select>
        </label>
      </div>
      <!--<label class="label_heading">Line Of Business<span style="color: red;">*</span></label>
      <div class="selectdiv selectdiv-custom select_wrapinline">
        <label>
          <ng-select id="lineOfBusiness" class="custom-select" [items]="lineOfBusinessList" bindLabel="Name"
                     bindValue="ID" (change)="onLOBChange($event)" [(ngModel)]="selectedLOB" [clearable]="false">
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.Name }}">{{ item.Name }}</div>
            </ng-template>
          </ng-select>
        </label>
      </div>-->
    </div>
  </div>

  <div style=" width: 80%; display: inline-block; float: right; ">
    <div *ngFor="let rule of currentRuleSetup.RuleSet.Rules">
      <app-form-page-rule [currentRule]="rule"
                          (deleteRule)="onDeleteRule($event)"
                          (saveRule)="onSaveRule($event)"
                          (cloneRule)="onCloneRule($event)"
                          *ngIf="this.clearedFilters || ruleFilterPasses(rule)"></app-form-page-rule>
    </div>
  </div>

  <div class="row">
    <div style="width: 900px; overflow: auto">
      <div id="blocklyDiv"
           style="height: 800px; width: 900px; float: left"></div>
    </div>
  </div>

  <!-- Show raw data for RuleSetup
  <pre>{{this.topBlocks | json}}</pre>
  <pre>{{currentRuleSetup | json}}</pre>
  <div>{{currentRuleSetup.blocksXml}}</div>

  <textarea id="formName"   style="width:100%" rows="10"
            required [(ngModel)]="currentRuleSetup.blocksXml"  #txtName="ngModel"></textarea>
       -->
</div>
