//Angular Imports
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HTTP_INTERCEPTORS, JsonpInterceptor } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { CurrencyPipe, DatePipe, DecimalPipe, CommonModule } from '@angular/common';

//Third Party Imports
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MyDatePickerModule } from 'mydatepicker';
import { AgGridModule } from "ag-grid-angular";
//Internal Imports
import {
  HttpRequestInterceptor, AppInsightsResponseInterceptor,
  AppInsightsInterceptor, JWTInterceptor, ShowLoaderInterceptor,
  HideLoaderInterceptor} from './interceptors';
import {
  ApplicationInsightsBaseComponent, DatePickerAdapter,
  DatePickerFormatter, DatePickerComponent, ConfirmationModalComponent,
  DateFilterComponent, BooleanFilterComponent, CheckboxFilterComponent, 
  DatePickerFilterComponent
} from './components';
import { DateTimePipe, DateTimeUtcPipe, RuleTypePipe } from './pipes';
import { XmlPipe } from './pipes/xml.pipe';
import { JSONPrettyPipe } from './pipes/json.pipe';
import { RadioFilterComponent } from './components/radio-filter.component';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { GPMaterialComponentModule } from '../ghostPortal/gpMaterial.module';


/**
  Providers
*/
const PROVIDERS = [
  CurrencyPipe,
  DatePipe,
  DecimalPipe,
  DateTimePipe,
  DateTimeUtcPipe,
 // JSONPrettyPipe,
 // XmlPipe,
  { provide: NgbDateAdapter, useClass: DatePickerAdapter },
  { provide: NgbDateParserFormatter, useClass: DatePickerFormatter }
];

/**
  Interceptors
*/
const INTERCEPTORS = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ShowLoaderInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpRequestInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JWTInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AppInsightsInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AppInsightsResponseInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HideLoaderInterceptor,
    multi: true
  }
];


/**
 * Framework Module
 * All Framework Related components, interceptors, etc are packaged in this module
*/
@NgModule({
    declarations: [
        ApplicationInsightsBaseComponent,
        DatePickerComponent,
        DateTimePipe,
        RuleTypePipe,
        ConfirmationModalComponent,
        DateFilterComponent,
        BooleanFilterComponent,
        CheckboxFilterComponent,
        DatePickerFilterComponent,
        RadioFilterComponent,
        DateTimeUtcPipe,
        JSONPrettyPipe,
        XmlPipe
    ],
    imports: [
        MyDatePickerModule,
        FormsModule,
        NgbModule,
      CommonModule,
      GPMaterialComponentModule,
      NgxMatDatetimePickerModule,
      NgxMatTimepickerModule,
      NgxMatNativeDateModule,
      AgGridModule.withComponents([DateFilterComponent, BooleanFilterComponent, CheckboxFilterComponent, RadioFilterComponent, DatePickerFilterComponent])
    ],
    providers: [PROVIDERS, INTERCEPTORS],
    exports: [
        ApplicationInsightsBaseComponent,
        DatePickerComponent,
        DateTimePipe,
        RuleTypePipe,
        ConfirmationModalComponent,
        DateFilterComponent,
        BooleanFilterComponent,
        DateTimeUtcPipe,
        JSONPrettyPipe,
        XmlPipe
    ]
})


export class FrameworkModule {
  //forRoot 
  static forRoot(): ModuleWithProviders<FrameworkModule> {
    return {
        ngModule: FrameworkModule,
        providers: [PROVIDERS]
    };
  }
}
