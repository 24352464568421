//Angular Imports
import { Component, HostBinding, OnInit, ViewChild } from "@angular/core";

//Third Party Imports
import { GridOptions } from "ag-grid-community";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";

//Internal Imports

import "rxjs";
import { saveAs } from "file-saver";
import { filter, forkJoin, map, startWith } from "rxjs";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import {
  AppInsightsService,
  ReportService,
  EnvironmentService,
  DateTimeUtcPipe,
  SharedService,
  ApplicationInsightsBaseComponent,
  CPMElement,
  UserProfileService,
  UserProfile,
} from "../../../framework";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { MigrationService } from "../services/migration.service";
import { MigrationLogs, MigrationSummaryLogs } from "../../models/migrationLogs";

/**
 * Forms Component
 */
@Component({
  selector: "app-document-migration",
  templateUrl: "./migration.component.html",
  styleUrls: ["./migration.component.scss"],
  providers: [],
})
export class MigrationComponent implements OnInit {



  public userProfile: UserProfile;

  public migrationLogs: MigrationLogs = new MigrationLogs();

  public displayList: boolean = false;


  constructor(
    public userProfileService: UserProfileService,
    public sharedService: SharedService,
    public migrationService: MigrationService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.userProfile = userProfileService.UserProfile;
  }

  ngOnInit(): void {
    /*
     Hide the preloader div on the index.html pages on init. If you don't do this, the UI of the home component
     will be hidden on page reload / hot reload
    */
    this.sharedService.hidePreloader();

    this.migrationService.getMigrationLogs().subscribe(res => {
      this.migrationLogs = new MigrationLogs();
      for (var i = 0; i < res.summaryLogs.length; i++) {
        var tempSummaryLog = new MigrationSummaryLogs();
        tempSummaryLog.FilesUploaded = res.summaryLogs[i]['Files Successfully Uploaded'];
        tempSummaryLog.FilesFailed = res.summaryLogs[i]['Files Unsuccessfully Uploaded'];
        tempSummaryLog.TimeToComplete = res.summaryLogs[i]['Time To Complete'];
        tempSummaryLog.TimeToComplete = parseFloat(tempSummaryLog.TimeToComplete.toFixed(2));
        console.log(tempSummaryLog);
        this.migrationLogs.summaryLogs.push(tempSummaryLog);
      }
      this.migrationLogs.errorLogs = res.errorLogs;
      console.log(this.migrationLogs.errorLogs);
      this.displayList = true;
    })

    

  }

}
