<div class="modal-header">
  <h4 class="modal-title">Selected Log Error</h4>
</div>

<div class="modal-body col-12" style="max-height: 500px; overflow-y: scroll">
  <div class="row" style="word-break: break-word">
    {{ this.errorText }}
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="mat_btn_hov_act mrgrgt10"
    mat-raised-button
    (click)="this.copyErrorText()"
  >
    Copy to Clipboard
  </button>
  <button
    type="button"
    class="mat_btn_hov_act"
    mat-raised-button
    (click)="activeModal.close()"
  >
    Close
  </button>
</div>
