<div class="component-container">
  <div class="component-heading-container">
    <h3 class="component-heading-text">Product Definitions</h3>
  </div>
  <div class="component-content-view">
    <div class="container-fluid">
      <div id="policy-form-container" class="row">
        <div class="col-md-12">
          <div class="heading-container fast_admin" style="width:35% !important">
            <label class="label_heading">Product:</label>
            <div class="selectdiv select_wrapinline">
              <label>
                <ng-select id="lineOfBusiness" class="custom-select" [items]="lineOfBusinessList" bindLabel="Name" bindValue="Name" (change)="onLOBChange($event)" [(ngModel)]="selectedLOB">
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{item.Name}}">{{item.Name}}</div>
                  </ng-template>
                </ng-select>
              </label>
            </div>
          </div>
            <div class="btn_wrapper" style="margin:10px !important">
              <button class="btn primary_btn btnlign" type="button" (click)="editProduct()" *ngIf="this.editVisible">
                Edit Selected Product
              </button>
              <button class="btn primary_btn btnlign" type="button" (click)="newProduct()" >
                Create New Product
              </button>
            </div>
        </div>
        <div id="policy-form-content-container" class="col-md-8">
          <ag-grid-angular class="ag-theme-balham fast_panel_table"
                           [gridOptions]="formsGridOptions">
          </ag-grid-angular>
        </div>
      </div>
    </div>

  </div>
</div>
