// Internal Imports
import { EnvDeploymentState } from "./envDeploymentState.model";
import { DeploymentHistory } from "./deploymentHistory.model";

/**
 * DeployPackage
 * */
export class DeployPackage {

  /**
   * PackageId
   */
  public PackageId: string;

  /**
   * id
   */
  public id: string;

  /**
   * PackageName
   */
  public PackageName: string;

  /**
   * LOB
   */
  public LOB: string;

  /**
   * Linked Forms
   */
  public LinkedForms: string[];

  /**
   * Note
   */
  public Note: string;

  /**
   * Release
   */
  public Release: string;

  /**
   * CreatedDate
   */
  public CreatedDate: number;

  /**
   * CreatedBy
   */
  public CreatedBy: string;

  /**
   * DeployedDate
   */
  public DeployedDate: number;

  /**
   * DeployedBy
   */
  public DeployedBy: string;

  /**
   * EnvDeploymentStates
   */  
  public EnvDeploymentStates: DictionaryItem | any;

  /**
   * DeploymentHistories
   */
  public DeploymentHistories: DeploymentHistory[];

  /**
   * IsDeployDisabled 
   */
  public IsDeployDisabled: boolean;

  /**
   * Script Changes Needed?
   */
  public ScriptNeeded: boolean;
}


/**
 * DictionaryItem
 * */
type DictionaryItem = [string, EnvDeploymentState];
