import { RuleEngineForm } from "../../form-page/models/ruleEngineForm.model";



export class AdminPackageForms {

  public PackageId: string;

  public Id: string;

  public LOB: string;

  public RuleEngineForm: RuleEngineForm;
}
