<div id="header-container" class="col-12">
  <div class="row">
    <div id="heading-container" class="col-6">
      <img id="markel-logo" alt="Markel Logo" src="/assets/images/siteLogo.png" class="sidebar-header-icon" title="Markel">
      <h5 class="heading-value">mDocument Orchestration Queue - {{environmentService.environmentDetails.Environment}}</h5>
      <a mat-button id="splash-nav-btn" class="heading-info-nav" href="" (click)="returnToMainMenu($event)">Return to main menu</a>

    </div>
    <div id="heading-container" class="col-6">
      <div class="row">
        <div class="col-12">
          <span class="heading-info-value" style="float:right">{{userProfileService.UserName}} [{{userProfileService.RoleString}}]</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <span class="heading-info-value" style="float:right; padding-top:0">{{sharedService.globalTestRunStatus}}</span>
        </div>
      </div>
    </div>
  </div>

</div>

<router-outlet></router-outlet>
 
