
export class AttachTestLog {
  public Id: string;
  public DocumentType: string;
  public IsActive: boolean;
  public Name: string;
  public Description: string;
  public TestGroups: string[] = [];  // TestGroup Id's
  public LOBs: string[] = [];
  public PolicyEffectiveDate: number;
  public WritingCompany: string;
  public State: string;
  public Coverages: string[] = [];
  public CPM: string;
  public ExpectedResults: AttachExpectedResults[];

  public FastKey: string;
  public CreationDate: number;
  public CreatedByName: string;
  public ModifiedDate: number;
  public ModifiedByName: string;
  public IsSelected: boolean; //used internally for sorting
}

export class AttachExpectedResults {
  public QuoteOption: string;
  public LOB: string;
  public OptionalForms: string[] = [];
  public MandatoryForms: string[] = [];
}
