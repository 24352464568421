import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { QueueService } from '../../services/queue.service';
import { FilterData } from '../../models/filter-data';
import { FilterChoice } from '../../models/filter-choice';


@Component({
  selector: 'app-request-filter',
  templateUrl: './request-filter.component.html',
  styleUrls: ['./request-filter.component.scss']
})

export class RequestFilterComponent implements OnInit {
  errorStatuses: string[] = [];
  statusList: FilterChoice[] = [];
  sourceSystemList: FilterChoice[] = [];

  currentDate: any = new Date();
  pastDate: any = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), (this.currentDate.getDate() - 7), 0, 0, 0, 0);

  filterInputs = new UntypedFormGroup({
    startDate: new UntypedFormControl( this.pastDate ),
    endDate: new UntypedFormControl( this.currentDate ),
    uuid: new UntypedFormControl('')
  });

  @Output() toggleLoad = new EventEmitter<boolean>();
  @Output() refreshGrid = new EventEmitter();
  @Output() toggleFilterError = new EventEmitter();


  constructor(private queueService: QueueService ) { }

  ngOnInit(): void {
    this.errorStatuses = this.queueService.getProblemStatuses();
    this.getStatus();
    this.getSourceSystems();
  }

  getSourceSystems(): void {
    this.queueService.getSourceSystem()
      .subscribe(
        sourceSystems => {
          sourceSystems.forEach(sourceSystem => {
            this.sourceSystemList.push({ name: sourceSystem, selected: false })
          })
        },
        error => {
          this.toggleFilterError.emit(true);
        }
      );
  }

  executeFilter(): void {
    let filterData = this.configureFilterParams();
    this.refreshGrid.emit(filterData);
  }

  configureFilterParams(): FilterData {
    let statusArray: string[] = [];
    let sourceSystemArray: string[] = [];
    
    this.statusList.forEach(status => {
      if (status.selected) {
        statusArray.push(status.name);
      }
    });

    this.sourceSystemList.forEach(sourcesystem => {
      if (sourcesystem.selected) {
        sourceSystemArray.push(sourcesystem.name);
      }
    });

    let filterData = new FilterData({
      fromDate: this.queueService.formatFilterRequestDate(this.filterInputs.controls["startDate"].value, "start" ),
      toDate: this.queueService.formatFilterRequestDate(this.filterInputs.controls["endDate"].value, "end"),
      requestStatus: statusArray,
      sourceSystem: sourceSystemArray,
      transactionId: this.filterInputs.controls["uuid"].value
    })

    return filterData;
  }
  getStatus(): void {
    this.queueService.getStatus()
      .subscribe(RequestStatuses => {
        RequestStatuses.forEach(requestStatus => {
          this.statusList.push({ name: requestStatus, selected: (this.errorStatuses.indexOf(requestStatus) > -1)  })
        })
      },
      error => {
        this.toggleFilterError.emit(true);
      });
  }

  reInit(): void {
    this.statusList = [];
    this.sourceSystemList = [];
    this.getStatus();
    this.getSourceSystems();
  }



}
