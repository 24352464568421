//Angular Imports 
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';


//Internal Imports
import { ExecuteRulesComponent } from './components';

/**
  Execution Routes
*/
export const executionRoutes: Routes = [
  {
    path: '', component: ExecuteRulesComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(executionRoutes)],
  exports: [RouterModule],
  providers: []
})

export class ExecutionRoutingModule {
}
