//Internal Imports
import { RuleSetup } from "./";
import { AttributeSet } from "./";

export class AdminForm {
  public id: string;
  public InternalFormNumber: string;
  public ExternalFormNumber: string;
  public MultiLOBIndicator: boolean;
  public FastKey: string;
  public LOB: string[] = [];
  public AttributeSets: AttributeSet[] = [];
  public RuleSetups: RuleSetup[] = [];
  public PreviousVersion: string;
  public PriorInternalFormNumber: string;

  public CreationDate: number;
  public CreatedByName: string;
  public ModifiedDate: number;
  public ModifiedByName: string;

  //Internal use only
  public LastModified: string;

  //MOL specific attributes, need to deploy for forms library
  public ImportFromMOL: boolean;
  public MolId: string;
  public MolDeptId: string;
  public MolNumber: string;
  public MolEditionDate: string;
  public MolEffectiveDate: string;
}
