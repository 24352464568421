import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MdocumentRoutingModule } from './mdocument-routing.module';
import { MdocumentLayoutComponent } from './components/mdocument-layout/mdocument-layout.component';

@NgModule({
  providers: [],
  declarations: [
    MdocumentLayoutComponent
  ],
  imports: [
    CommonModule,
    MdocumentRoutingModule
  ]
})
export class MdocumentModule { }