import { formatDate } from "@angular/common";

export class JobHealth {
  jobId: string;
  jobDate: any;
  jobDateString: string;
  totalTransactions: number;
  completedTransactions: number;
  unsuccessfulTransactions: number;
  problemPercentage: number = 0;
  jobHealthStatus: string = "success";
  completionNote: string;
  jobClass: string;

  warningMax: number = .20;

  /*
   * So for the filter:  just pass the jobId and jobHealthStatus, and let the filter or master-view fill
   * in the list of "problem" statuses?
   */

  constructor(objectData: any) {
    this.jobId = objectData.JobId || "";
    this.jobDate = this.fixStartTime(objectData.StartTime) || "NA";
    this.jobDateString = this.formatTimestamp(objectData.StartTime) || "NA";
    this.totalTransactions = objectData.totalTransactions || 0;
    this.completedTransactions = objectData.completedTransactions || 0;
    this.unsuccessfulTransactions = objectData.unsuccessfulTransactions || 0;
    this.problemPercentage = this.getProblemPercentage();
    this.jobHealthStatus = this.determineJobHealthStatus();
    this.completionNote = this.setCompletionNote();
    this.jobClass = this.setJobClass();
  }

  fixStartTime(startTime: string): string {
    return /\s/.test(startTime) ? startTime.replace(/\s/, "T") : startTime;
  }

  // The class logic to set the icon is too complicated to cleanly handle with UI directive logic
  setJobClass(): string {
    let jobClass: string = "fa fa-lg ";
    if (this.inProgress()) {
      jobClass += "fa-spinner fa-spin ";
    } else if (this.jobHealthStatus == 'success') {
      jobClass += "fa-check-circle ";
    } else {
      jobClass += "fa-exclamation-circle ";
    }

    switch (this.jobHealthStatus) {
      case "blocked":
        jobClass += "job-blocked";
        break;
      case "success":
        jobClass += "job-success";
        break;
      case "warning":
        jobClass += "job-warning";
        break;
      default:
        jobClass += "job-danger";
        break;
    }

    return jobClass;
  }

  setCompletionNote(): string {
    if (this.isBlocked()) {
      return "Issue with job - " + this.jobId + " - on " + this.jobDateString +  "; contact IT";
    } else if (this.inProgress()) {
      return this.completedTransactions + "/" + this.totalTransactions;
    } else {
      return this.jobDateString;
    }
  }

  // Use total transactions to be fair with in progress: total of 200 with 100 complete is then only yellow if 20 errors
  getProblemPercentage(): number {
    if (this.totalTransactions != 0 && this.completedTransactions != 0 && this.unsuccessfulTransactions != 0) {
      return parseFloat((this.unsuccessfulTransactions / this.totalTransactions).toPrecision(2));
    } else {
      return 0;
    }
  }

  inProgress(): boolean {
    return (!this.isBlocked() && ( this.totalTransactions != this.completedTransactions ) );
  }

  isBlocked(): boolean {
    return this.totalTransactions == 0;
  }

  determineJobHealthStatus(): string {
    if (this.totalTransactions == 0) {
      return "blocked"
    }
    if (this.unsuccessfulTransactions == 0) {
      return "success"
    }
    return (this.problemPercentage > this.warningMax) ? "danger" : "warning";
  }

  formatTimestamp(dateValue: any): string {
    if (!dateValue) {
      return 'NA';
    }
    if (isNaN(Date.parse(dateValue))) {
      return dateValue
    }
    let date = new Date(Date.parse(dateValue));
    return formatDate(date, 'yyyy-MM-dd', 'en-US');
  }
}
