export class ProductConfigWrapper {
  public id: string = "b4bf2bfc-8246-486e-89c1-6c929c0139d6";
  public Config: string = "Products";
  public Items: ProductDefinition[];
}

export class ProductDefinition {

  public Name: string;

  public ID: string;

  public Coverages: ProductCoverage[];

}

export class ProductCoverage {
  public Name: string;
  public ID: string;
}
