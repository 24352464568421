//Angular Imports 
import { Component, OnInit, Input, ViewChild } from '@angular/core';

//Third Party Imports 
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

//Internal Imports 
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms'
import { CPMService } from '../../services';
import { CPMElement } from '../../../framework/models/cpm-element.model';
import { CPMField } from '../../../framework/models/cpm-Field.model';
import { SharedService, ConfirmationModalComponent, UserProfileService } from '../../../framework';

import { error } from 'util';

/**
 * Add Form Component
*/
@Component({
  selector: 'app-add-cpm-field',
  templateUrl: './add-cpm-field.component.html',
  styleUrls: ['./add-cpm-field.component.scss']
})

export class AddCPMFieldComponent implements OnInit {

  /**
* formGroup
*/
  formGroup: UntypedFormGroup;

  /**
* readonly
*/
  public readonly: boolean = false;


  /**
* current CPMElement
*/
  currentCPMElement: CPMElement;


  /**
* current CPMElement
*/
  currentCpmField: CPMField;

  /**
* actionType
*/
  actionType: string;


  /**
* lineOfBusinessList
*/
  lineOfBusinessList: any[];


  /**
 * Constructor
 * @ignore
*/
  constructor(public activeModal: NgbActiveModal, private _modal: NgbModal,
    private fb: UntypedFormBuilder, private cpmService: CPMService,
    private sharedService: SharedService, private toastr: ToastrService,
    private userProfileService: UserProfileService) {
  }


  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
  */
  ngOnInit() {

    this.createFormBuilder();
    this.sharedService.getMasterData().subscribe(el => {

      //this.createFormBuilder();
      this.addControlInFormGroup();
      this.fillFormBuilder();
    }, error => { this.toastr.error('Error occurred while fetching master data.', 'CPM'); });
    ;
  }


  /**
   * NgDestroy
   * @ignore
  */
  ngOnDestroy(): void {
  }


  /**
   * Create form for builder
   **/
  private createFormBuilder(): void {

    if (this.actionType == "Add") {
      this.formGroup = this.fb.group({
        code: [{ value: '', disabled: this.readonly }, [Validators.required]],
        description: [{ value: '', disabled: this.readonly }, [Validators.required]],
        e2Description: [{ value: '', disabled: this.readonly }],
      });
    }
  }

  /**
   * Fill Form Builder
   * */
  private fillFormBuilder(): void {
    this.currentCpmField = new CPMField();
  }


  /**
   *Add LOB list as checkbox control in form group
   * */
  addControlInFormGroup() {
    if (this.formGroup == undefined) {
      this.createFormBuilder();
    }
  }


  /**
  * Save Rule Type
  **/
  saveCpmField() {

    this.addControlInFormGroup();

    if (this.formGroup.valid) {
     
      this.currentCpmField.Code = this.formGroup.controls["code"].value;
      this.currentCpmField.Description = this.formGroup.controls["description"].value;
      this.currentCpmField.e2Description = this.formGroup.controls["e2Description"].value;
      this.currentCPMElement.CPMFieldValues.push(this.currentCpmField);
      //modify the rule details date and user 
      this.currentCPMElement.ModifiedDate = moment().unix();
      this.currentCPMElement.ModifiedByName = this.userProfileService.UserProfile.UserName;
      this.activeModal.close(this.currentCPMElement);
    }
  }
}

