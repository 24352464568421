<div class="container-fluid">
  <div class="row align-items-center justify-content-center loading-row" *ngIf="isLoading">
    <div class="col-md-4">
      <div>
        <mat-progress-spinner mode="indeterminate" diameter="60" class="mx-auto">
        </mat-progress-spinner>
      </div>
    </div>
  </div>

  <div class="row align-items-start" *ngIf="!isLoading && !isReady">
    <p id="transaction-load-error-alert" class="col-md-12 alert alert-danger outcome-bar">There was an error with retrieving the transaction details.  Click <a class="alert-link" href="" (click)="retryTransactionDetails(rowData['uuid'])">here</a> to re-request the details.</p>
  </div>

  <div class="row align-items-start" *ngIf="!isLoading && isReady">
    <div class="col-md-12">

      <mat-accordion class="detail-blocks" multi>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h5>Basic Details</h5>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row">
            <div class="col-md-4">
              <div class="detail-label">ID</div>
              <div class="detail-value">{{rowData["id"]}}</div>
            </div>

            <div class="col-md-4">
              <div class="detail-label">UUID</div>
              <div class="detail-value">{{rowData["uuid"]}}</div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="detail-label">RequestStatus</div>
              <div class="detail-value">{{rowData["status"]}}</div>
            </div>

            <div class="col-md-4">
              <div class="detail-label">SourceSystem</div>
              <div class="detail-value">{{rowData["sourceSystem"]}}</div>
            </div>
          </div>

          <div *ngIf="basicDetails.status == 'ERROR'" class="row" style="margin-top:20px;">

            <div class="col-md-2">
              <button mat-raised-button class="portal-button" [disabled]="!isAuthorized()" (click)="resetStatus(basicDetails.id)">Reset transaction</button>
            </div>
            <div class="col-md-4">
              <div class="alert alert-success outcome-msg-block" *ngIf="isSuccess">
                Reset successful
              </div>
              <div class="alert alert-danger outcome-msg-block" *ngIf="isFailure">
                An error occurred during the reset process. Please try again.
              </div>
            </div>

          </div>

        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h5>Transaction Log Data</h5>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <!-- Data grid for the log data -->
          <ag-grid-angular id="logGrid"
                           style="width: 100%; height: 300px;"
                           class="ag-theme-balham"
                           [rowData]="details.logs"
                           [columnDefs]="columnDefs"
                           [pagination]="pagination"
                           [paginationAutoPageSize]="paginationAutoPageSize">
          </ag-grid-angular>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h5>Request Payload</h5>
            </mat-panel-title>
            <mat-panel-description><h6>{{basicDetails.requestPayloadAvailable}}</h6></mat-panel-description>
          </mat-expansion-panel-header>
         
          <div [ngSwitch]="basicDetails.requestPayloadAvailable">
            <div *ngSwitchCase="'Unavailable'">
              <p>The request payload is removed when a request has completed successfully.</p>
            </div>
            <br />
            <div *ngSwitchCase="'Available'">
              <p>
                The request payload is frequently too large to display on a web page, so it is provided as a download.
              </p>
              <div class="row">
                <div class="col-md-2">
                  <button mat-raised-button class="portal-button" (click)="downloadpayload(basicDetails.uuid)">
                    Download request payload
                    <i class="fa fa-fw fa-download"></i>
                  </button>
                </div>
                <div class="col-md-9">
                  <div [ngSwitch]="downloadStatus">
                    <div *ngSwitchCase="'inProgress'" id="download-spinner-box">
                      <mat-progress-spinner mode="indeterminate" diameter="20"></mat-progress-spinner>
                    </div>
                    <div *ngSwitchCase="'success'">
                      <div class="alert alert-success outcome-msg-block">
                        Download successful (your browser should display a download notification).
                      </div>
                    </div>
                    <div *ngSwitchCase="'error'">
                      <div class="alert alert-danger outcome-msg-block">
                        An error occurred with the download.  Please try again.
                      </div>
                    </div>
                  </div>
                </div>
              </div>             
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h5>Callback Payload</h5>
            </mat-panel-title>
            <mat-panel-description><h6>{{callbackResponseStatus}}</h6></mat-panel-description>
          </mat-expansion-panel-header>

          <div *ngIf="callbackResponseStatus == 'Unavailable'">
            <p>The callback response to the requesting system only occurs once the orchestration process succeeds or fails.</p>
          </div>
          <div *ngIf="callbackResponseStatus == 'Available'">
            <p>The payload sent back to the requestion system upon success or failure.</p>
            <p>
              <button mat-raised-button class="portal-button" [cdkCopyToClipboard]="rawResponse">
                Copy to clipboard
                <i class="fa fa-paste cell-clipboard-icon"></i>
              </button>
            </p>
            <pre id="jsonDisplayBlock" class="model-display" [innerHTML]="prettyResponseJSON"></pre>
          </div>
        </mat-expansion-panel>

      </mat-accordion>


    </div>
  </div>
</div>
