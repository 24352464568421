import { Component, OnInit, ViewChild } from '@angular/core';
import { ImportQueueService } from '../../services/import-queue.service';
import {
  AgCartesianSeriesTooltipRendererParams,
  AgChartOptions,
} from 'ag-charts-community';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  chartTitles: any = {
    policyTransactionsPerMonth: "Policy Transactions Per Month YTD",
    claimTransactionsPerMonth: "Claim Transactions Per Month YTD",
    premiumPerPartner: "Premium Per Partner YTD",
    amountPaidPartner: "Amount Paid Per Partner YTD"
  };

  months: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  dashboardItems: any[] = [];

  charts: any = {};
  
  isLoadingChartingData: boolean = true;
  isLoadingStatsData: boolean = true;
  showChartingError: boolean = false;
  showStatsError: boolean = false;
  gridDisplayed: boolean = false;

  @ViewChild('partnerStats') partnerStats: any;

  constructor( private queueService: ImportQueueService ) { }

  ngOnInit(): void {

    // Set dashboard configuration
    this.dashboardItems = [
      { dashId: 'healthMeter', name: 'Health Meter', default: true, subitem: false },
      { dashId: 'partnerProductAudit', name: 'Partner-Product Status', default: false, subitem: false },
      { dashId: 'partnerStats', name: 'Partner Statistics', default: false, subitem: false },
      { dashId: 'allCharts', name: 'Charts', default: false, subitem: false },
      { dashId: 'policyTransactionsPerMonth', name: this.chartTitles.policyTransactionsPerMonth, default: false, subitem: true },
      { dashId: 'claimTransactionsPerMonth', name: this.chartTitles.claimTransactionsPerMonth, default: false, subitem: true },
      { dashId: 'premiumPartner', name: this.chartTitles.premiumPerPartner, default: false, subitem: true },
      { dashId: 'amountPaidPartner', name: this.chartTitles.amountPaidPartner, default: false, subitem: true },
    ];

    // Create chart configurations   
    this.charts = {
      policyTransactionsPerMonth: {
        title: this.chartTitles.policyTransactionsPerMonth,
        hasError: false,
        errorMessage: "",
        chartDataProperty: "policyTransactionsPerMonth",
        chartType: "line",
        xDataProperty: "MONTH",
        yDataProperty: "COUNT",
        formatter: this.countFormatter,
        yAxisScale: "number",
        yAxisFormat: "~s",
        instructions: {}
      },
      claimTransactionsPerMonth: {
        title: this.chartTitles.claimTransactionsPerMonth,
        hasError: false,
        errorMessage: "",
        chartDataProperty: "claimTransactionsPerMonth",
        chartType: "line",
        xDataProperty: "MONTH",
        yDataProperty: "COUNT",
        formatter: this.countFormatter,
        yAxisScale: "number",
        yAxisFormat: "~s",
        instructions: {}
      },
      premiumPerPartnerYTD: {
        title: this.chartTitles.premiumPerPartner,
        hasError: false,
        errorMessage: "",
        chartDataProperty: "premiumPerPartnerYTD",
        chartType: "line",
        xDataProperty: "month",
        yDataProperty: "premium",
        formatter: this.moneyFormatter,
        yAxisScale: "number", // "log" would be nicer with large ranges but can't use with 0 amounts.
        yAxisFormat: "$~s",
        instructions: {}
      },
      amountPaidPartner: {
        title: this.chartTitles.amountPaidPartner,
        hasError: false,
        errorMessage: "",
        chartDataProperty: "amountpaidPerPartnerYTD",
        chartType: "line",
        xDataProperty: "month",
        yDataProperty: "paidamt",
        formatter: this.moneyFormatter,
        yAxisScale: "number", // "log" would be nicer with large ranges but can't use with 0 amounts.
        yAxisFormat: "$~s",
        instructions: {}
      }
    }

    this.buildCharts();
  }

  isVisible( selectedOptions: any[], dashId: string ) {
    let currentSelection = selectedOptions['selected'][0]?.value;
    // The first time we show the partner stats grid, we want to size the columns to fit
    if (!this.gridDisplayed && currentSelection == 'partnerStats' ) {
      if (this.partnerStats.autoSizeGridColumns()) {
        this.gridDisplayed = true;
      } 
    }
    let outcome = (currentSelection == 'allCharts' || currentSelection == dashId);
    return outcome;
  }

  countFormatter(params: AgCartesianSeriesTooltipRendererParams) {
    return {
      title: params.title,
      content: params.xValue + ": " + formatNumber(params.yValue, "en-US", '1.0-0'),
    };
  }

  moneyFormatter(params: AgCartesianSeriesTooltipRendererParams) {
    return {
      title: params.title,
      content: params.xValue + ": $" + formatNumber(params.yValue, "en-US", '1.0-0'),
    };
  }

  populateChartData(chartData: any): void  {
    this.createChartInstructions(chartData, "policyTransactionsPerMonth");
    this.createChartInstructions(chartData, "claimTransactionsPerMonth");
    this.createChartInstructions(chartData, "premiumPerPartnerYTD");
    this.createChartInstructions(chartData, "amountPaidPartner");
  }

  createMonthSeries(chartType: string, xDataProperty: string): any {
    let monthData = [];
    this.months.forEach((m: string) => {
      monthData.push(
        { [xDataProperty]: m, z: 0 }
      )
    });
    return {
      type: chartType,
      marker: { enabled: false },
      xKey: xDataProperty,
      yKey: "NULL",  // non-existent Y key means won't affect y scale
      title: "",
      showInLegend: false,
      strokeOpacity: 0,
      data: monthData
    }
  }

  createChartInstructions(chartData: any, chartId: string): void {

    let uiChart = this.charts[chartId];

    if (!chartData[uiChart.chartDataProperty]) {
      uiChart.hasError = true;
      uiChart.errorMessage = "The data for this chart is missing; please contact IT";
      return;
    }

    let chartSubjects = [];

    // Start with adding a data series that the user won't see but will ensure all months appear in the correct order
    chartSubjects.push(
      this.createMonthSeries( uiChart.chartType, uiChart.xDataProperty.toUpperCase() )
    )

    chartData[ uiChart.chartDataProperty].forEach(p => {

      chartSubjects.push(
        {
          type: uiChart.chartType,
          marker: { enabled: true },
          tooltip: { renderer: uiChart.formatter },
          xKey: uiChart.xDataProperty,
          yKey: uiChart.yDataProperty,
          title: p.Partner,
          data: p.DATA,
          highlightStyle: {
            series: {
              dimOpacity: 0.4,
              strokeWidth: 3,
            },
          }
        }
      )
    });

    uiChart.instructions = {
      height: 400,
      width: 800,
      title: { text: "" },
      series: chartSubjects,
      legend: { position: 'bottom' },
      axes: [
        {
          type: uiChart.yAxisScale,
          position: "left",
          tick: { count: 10 },
          label: {
            format: uiChart.yAxisFormat,
            formatter: (params: any) =>
              params.formatter!(params.value)
                .replace('k', 'K')
                .replace('G', 'B')
          }
        },
        {
          type: "category",
          position: "bottom"
        }
      ]
    }

  }

  buildCharts(): void {
    this.isLoadingChartingData = true;
    this.showChartingError = false;

    this.queueService.getChartData()
      .subscribe(
        // Handle successful retrieval of the health meter
        (response: any) => {
          this.populateChartData(response);
          this.isLoadingChartingData = false;
        },
        // Handle an error response
        error => {
          this.showChartingError = true;
          this.isLoadingChartingData = false;
        });
  }

  // Adds brief delay so spinner appears and user knows the retry attempt was made.
  retryCharts(): void {
    this.isLoadingChartingData = true;
    let _this = this;
    setTimeout(() => {
      _this.buildCharts();
    }, 500);
  }


}
