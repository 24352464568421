<div class="container-fluid outer-box">
  <div class="row">
    <div class="col-md-12">
      <div [formGroup]="searchInputs">
        <mat-form-field appearance="fill" id="entity-id-form-field">
          <mat-label>Enter a full or partial policy or claim number:</mat-label>
          <input matInput id="entityId-input" formControlName="entityId">
        </mat-form-field>
      </div>      
    </div>
  </div>

  <div class="row">
    <div class="col-md-1">
      <button id="search-btn" mat-raised-button class="mdo-standard-button" (click)="executeSearch()">Search</button>
    </div>
    <div class="col-md-5">
      <div id="search-result-msg" *ngIf="searchResultMsg">{{searchResultMsg}}</div>
      <div id="search-error-alert" class="alert mdo-alert-danger" *ngIf="showSearchError">
        There was an error with performing the search.  Please try again.
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">

      <ag-grid-angular [hidden]="showSearchError" id="searchResultsGridGrid"
                       style="width: 100%; height: 400px;"
                       class="ag-theme-balham mdo-standard-grid"
                       [rowData]="rowData"
                       [columnDefs]="columnDefs"
                       (gridReady)="onGridReady($event)"
                       [frameworkComponents]="frameworkComponents"
                       [gridOptions]="gridOptions"
                       [pagination]="pagination"
                       [paginationAutoPageSize]="paginationAutoPageSize">

      </ag-grid-angular>

    </div>
  </div>
</div>
