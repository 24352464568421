//Angular Imports 
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

//Third Party Imports
import { Observable, Subscriber } from "rxjs";
import { map } from "rxjs/operators";

//Internal Imports
import { AdminForm } from "../../form-page";
import { AdminPublishPackageForms } from "../models/publishAdminPackageForms.model";

/**
  * Policy Forms Provider
*/
@Injectable()
export class PackageService {

  private masterData: any;
  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient) {
  }


  /**
   * Add publish package forms in database
   * @param policyForm
   */

  addAdminPackageForms(adminPackageForms: AdminPublishPackageForms): Observable<AdminPublishPackageForms> {
      let url = `api/PackageForms/Admin`;
      return this.http.post<AdminPublishPackageForms>(url, adminPackageForms).pipe(
        map(res => res)
      );
  }


  getAdminFormsForLOB(lobName: string): Observable<AdminForm[]> {
    let url = `api/AdminForm/Lob/` + lobName;
    return this.http.get<AdminForm[]>(url).pipe(
      map(res => res)
    );
  }

  validatePackageName(input: string, lob: string): Observable<boolean> {
    let url = `api/PackageForms/ValidatePackageName?input=` + input + `&lob=` + lob;
    return this.http.get<boolean>(url).pipe(
      map(res => res)
    );
  }

}
