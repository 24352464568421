import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationResolver } from '../framework';
import { AuthGuard } from '../framework/guards/auth.guard';
import { MdoImportQueueModule } from '../mdo-import-queue/mdo-import-queue.module';

import { MdoLayoutComponent } from './components/mdo-layout/mdo-layout.component';

const routes: Routes = [
  {
    path: '',
    component: MdoLayoutComponent   
    ,resolve: {
      token: AuthenticationResolver
    },
    children: [
      {
        path: 'import-queue', canActivate: [AuthGuard],
        loadChildren: () => MdoImportQueueModule
      },
      {
        path: '**',
        redirectTo: 'import-queue',
        pathMatch: 'full'
      }
    ]   
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthenticationResolver]
})
export class MdoRoutingModule { }
