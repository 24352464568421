
  <div class="component-container">  

    <div class="component-content-view">
      <div class="row">
        <div class="col-12" id="forms-content-heading-container">
          <div class="heading-container fast_admin" id="policy-form-heading-container">
            <label class="label_heading">Filter Results By Writing Company:</label>
            <ng-select class="custom-select"
            [items]="writingCompines"
            bindLabel="Name"
            bindValue="ID"
            [(ngModel)]="selectedWC"
            (change)="onWCChange()"></ng-select>
          </div>
        </div>
        <div id="forms-content-container" class="col-12" style="overflow:visible;  min-height:100px;">
          <ag-grid-angular class="ag-theme-balham fast_panel_table"
                           [gridOptions]="executeRulesGridOptions">
          </ag-grid-angular>
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn secondary_btn" (click)="this.activeModal.close()">Exit</button>
    </div>
  </div>


