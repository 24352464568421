<div>
  <div class="row expression_row rounded-lg">
    <div
      class="select_default expression_element"
      style="display: inline-block"
    >
      <label>
        <ng-select
          id="elementList"
          style="min-width: 200px"
          [items]="elementList"
          bindLabel="CPMDataElementName"
          bindValue="CPMDataElementName"
          [(ngModel)]="selectedElementName"
          (change)="onElementChanged($event)"
          [clearable]="true"
          placeholder="Select element"
        ></ng-select>
      </label>
    </div>

    <div
      class="select_default expression_element"
      style="display: inline-block"
    >
      <label>
        <ng-select
          id="operatorList"
          style="min-width: 100px"
          [items]="operatorList"
          bindLabel="Name"
          bindValue="Name"
          [(ngModel)]="selectedOperator"
          (change)="onSelectOperator($event)"
          [clearable]="true"
          [ngClass]="{
            'select-red':
              selectedOperator === 'all except' || selectedOperator === '<>'
          }"
        ></ng-select>
      </label>
    </div>

    <div
      class="select_default expression_element"
      *ngIf="
        selectedOperator === 'in' ||
        selectedOperator === '=' ||
        selectedOperator === '<>' ||
        selectedOperator === 'all except'
      "
      style="display: inline-block; width: auto"
    >
      <label>
        <ng-select 
          id="includedList"
          [items]="valueList"
          bindLabel="Name"
          bindValue="Code"
          [(ngModel)]="includedList"
          (change)="onIncludeValues($event)"
          [clearable]="true"
          [multiple]="true"
          [closeOnSelect]="false"
          placeholder="Values"
        ></ng-select>
      </label>
    </div>

    <div
      class="select_default expression_element"
      *ngIf="
        selectedOperator === 'in' ||
        selectedOperator === '=' ||
        selectedOperator === '<>' ||
        selectedOperator === 'all except'
      "
      style="display: inline-block; "
    >
      <div>
        <label>
          <ng-select
            id="mandatoryList"
            [items]="valueList"
            bindLabel="Name"
            bindValue="Code"
            [(ngModel)]="mandatoryList"
            (change)="onMandatoryValues($event)"
            [clearable]="true"
            [multiple]="true"
            [closeOnSelect]="false"
            placeholder="Mandatory Values"
          ></ng-select>
        </label>
      </div>
    </div>

    <div
      class="select_default expression_element"
      *ngIf="
        selectedOperator === '>' ||
        selectedOperator === '<' ||
        selectedOperator === '>=' ||
        selectedOperator === '<=' ||
        selectedOperator === 'CountEqual' ||
        selectedOperator === 'CountNotEqual'
      "
      style="display: inline-block; width: auto; align-self: center"
    >
      <label>
        <input
          [(ngModel)]="includedList[0]"
          (change)="onIncludeValues($event)"
          placeholder="Enter Value"
          style="border: 1px solid lightgray; border-radius: 5px; height: 35px"
        />
      </label>
    </div>

    <div
      class="select_default expression_element"
      *ngIf="
        selectedOperator === '>' ||
        selectedOperator === '<' ||
        selectedOperator === '>=' ||
        selectedOperator === '<=' ||
        selectedOperator === 'CountEqual' ||
        selectedOperator === 'CountNotEqual'
      "
      style="display: inline-block; width: auto; align-self: center"
    >
      <label>
        <input
          [(ngModel)]="mandatoryList[0]"
          (change)="onMandatoryValues($event)"
          placeholder="Enter Mandatory Value"
          style="border: 1px solid lightgray; border-radius: 5px; height: 35px"
        />
      </label>
    </div>

    <!-- Delete button -->
    <div
      class="expression_element"
      style="display: inline-block; padding: 4px 5px 4px 5px; float: right"
    >
      <button
        type="button"
        mat-raised-button
        class="btn-teal"
        (click)="onDeleteExpression()"
      >
        Delete
      </button>
    </div>
  </div>
</div>
