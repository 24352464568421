//Angular Imports 
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

//Third Party Imports
import { Observable, Subscriber } from "rxjs";
import { map } from "rxjs/operators";
import { FormNumber } from "../../rules/models";
import { Folder } from "../models/folder.model";


/**
  * Policy Forms Provider
*/
@Injectable()
export class ImageRightService {

  private masterData: any;
  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient) {
  }

  getAllFolders(): Observable<Folder[]> {
    let url = 'api/Configs/ImageRight/GetFolders';
    return this.http.get<Folder[]>(url).pipe(map(res => {
      return res;
    }));
  }
  addFolder(folder: Folder): Observable<string> {
    let url = 'api/Configs/ImageRight/AddFolder';
    return this.http.post<string>(url, folder).pipe(map(res => {
      return res;
    }));
  }
  upsertFolder(folder: Folder): Observable<Folder> {
    let url = 'api/Configs/ImageRight/UpdateFolder';
    return this.http.post<Folder>(url, folder).pipe(map(res => {
      return res;
    }));
  }

  deleteFolder(id: string): Observable<boolean> {
    let url = `api/Configs/ImageRight/DeleteFolder/${id}`;
    return this.http.get<boolean>(url).pipe(map(res => {
      return res;
    }));
  }

}
