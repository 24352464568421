//Angular Imports
import { Component, Input, Output, EventEmitter } from "@angular/core";

//Third Party Imports
import {
  CellValueChangedEvent,
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowValueChangedEvent,
} from "ag-grid-community";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

//Internal Imports
import {
  DateFilterComponent,
  DateTimeUtcPipe,
  UserProfileService,
  AuthorizationService,
} from "../../../../framework";
import { forEach } from "lodash";
import { FormsListService } from "../../services";
import { CPMElement, CPMForm, TransactionResponse } from "../../models";
import { HttpErrorResponse } from "@angular/common/http";
import { AgGridDatePickerComponent } from "./edit-date.component";
import { isNullOrUndefined } from "util";
import { AdjustFormsGridComponent } from "../adjustFormsGrid/adjustFormsGrid.component";
import { RuleEngineForm } from "../../../../form-page/models/ruleEngineForm.model";

@Component({
  providers: [FormsListService],
  selector: "transactions",
  templateUrl: "./transactions.component.html",
  styleUrls: ["./transactions.component.scss"],
})
export class Transactions {
  @Input() userTransList: TransactionResponse[];
  @Input() lobForms: RuleEngineForm[];
  transactionsGridOptions: GridOptions;
  public currentUser: string;
  // public userTransactionsList: TransactionResponse[];
  public selectedTransaction: TransactionResponse;
  public saveRenewLabel: string = "Renew";
  public isRenew: boolean = false;
  public tList: TransactionResponse[] = [];
  // public isEditable: boolean = false;

  /**
   * Constructor
   * @ignore
   */
  constructor(
    public activeModal: NgbActiveModal,
    private formsService: FormsListService,
    private _modal: NgbModal,
    private toastr: ToastrService,
    private dateTimePipe: DateTimeUtcPipe,
    private userProfileService: UserProfileService,
    private authorizationService: AuthorizationService
  ) {}

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
   */
  ngOnInit(): void {
    this.configureGrid();
    this.currentUser = this.userProfileService.UserName;
  }

  /**
   * NgDestroy
   * @ignore
   */
  ngOnDestroy(): void {}

  private configureGrid(): void {
    //this.initialRowDataLoad$ = [];
    this.transactionsGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: "normal",
      columnDefs: this.createColumDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      paginationPageSize: 10,
      rowHeight: 40,
      enableColResize: true,
      onGridReady: () => {
        this.transactionsGridOptions.api.setRowData([]);
        //this.resultsGridOptions.api.setRowData(this.allResults);
        this.transactionsGridOptions.api.sizeColumnsToFit();
        //this.resultsGridOptions.columnApi.autoSizeAllColumns();
        this.setRowData();
      },
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
      },
      onRowClicked: (event) => {
        this.onResultsRowClick(event);
      },
      onFilterChanged: (event) => {},
      onSortChanged: (event) => {},
      onFilterModified: function (event) {
        console.log(event);
      },
      getRowStyle: (params) => {
        if (params.node.data.IssuedOn !== null) {
          return { background: "gainsboro" };
        }
      },
    };
  }

  private setRowData() {
    this.tList = [];
    for (var i = 0; i < this.userTransList.length; i++) {
      let json: TransactionResponse = this.userTransList[i];
      json.rowLabel = "Renew";
      this.tList.push(json);
    }
    console.log(this.tList);
    this.transactionsGridOptions.api.setRowData(this.tList);
  }

  private createColumDef(): any[] {
    var ICAdmin = this.authorizationService.ICAdministrator();
    return [
      {
        headerName: "Issued On",
        resizable: true,
        field: "IssuedOn",
        width: 70,
        editable: false,
        filter: "dateFilterComponent",
        sort: "asc",
        cellRenderer: (data) => {
          return data.value ? new Date(data.value + "Z").toLocaleString() : "";
        },
      },
      {
        headerName: "Created On",
        resizable: true,
        field: "CreatedOn",
        width: 80,
        filter: "dateFilterComponent",
        sort: "desc",
        editable: false,
        cellRenderer: (data) => {
          return data.value ? new Date(data.value + "Z").toLocaleString() : "";
        },
      },
      {
        headerName: "Created By",
        resizable: true,
        field: "UserName",
        width: 100,
        editable: false,
        filter: "agTextColumnFilter",
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
      {
        headerName: "Policy Number",
        resizable: true,
        field: "PolicyNumber",
        width: 140,
        editable: true,
        filter: "agTextColumnFilter",
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
        cellStyle: function (params) {
          if (params.data.rowLabel == "Save") {
            return {
              backgroundColor: "antiquewhite",
              height: "-webkit-fill-available",
            };
          }
        },
      },
      {
        headerName: "Insured Name",
        resizable: true,
        field: "NamedInsured",
        width: 140,
        editable: true,
        filter: "agTextColumnFilter",
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        }
      },
      {
        headerName: "Policy Effective Date",
        resizable: true,
        field: "PolicyEffectiveDate",
        width: 80,
        editable: true,
        cellEditorFramework: AgGridDatePickerComponent,
        filter: "dateFilterComponent",
        cellRenderer: (data) => {
          return data.value ? new Date(data.value).toLocaleDateString() : "";
        },
        cellStyle: (params) => {
          if (params.data.rowLabel == "Save") {
            return { backgroundColor: "antiquewhite" };
          }
        },
      },
      {
        headerName: "Policy Expiration Date",
        resizable: true,
        field: "PolicyExpirationDate",
        width: 80,
        editable: true,
        filter: "dateFilterComponent",
        cellEditorFramework: AgGridDatePickerComponent,
        cellRenderer: (data) => {
          return data.value ? new Date(data.value).toLocaleDateString() : "";
        },
        cellStyle: function (params) {
          if (params.data.rowLabel == "Save") {
            return { backgroundColor: "antiquewhite" };
          }
        },
      },
      {
        headerName: "Actions",
        suppressMenu: true,
        suppressSorting: true,
        editable: false,
        width: 60,
        // cellRenderer: BtnCellRenderer,
        cellRendererParams: {
          clicked: (field: any) => {
            alert(`${field} was clicked`);
          },
          label: this.saveRenewLabel,
        },
        cellRenderer: (params) => {
          if (params.data.IssuedOn) {
            if (ICAdmin) {
              if (params.data.rowLabel == "Renew") {
                return `<input data-action-type='editRecord' title="Renew" style="background: #fff; border-radius: 10px; border: none;" type='button'            
              mat-raised-button class='mat_btn_hov_act'  data-rowIndex=${params.rowIndex}  value="Renew" />
              <img src="/assets/images/Download.png" data-action-type="Download" title="Download">`;
              } else {
                return `<input data-action-type='editRecord' title="Save" style="background: #fff; border-radius: 10px; border: none;" type='button'              
              mat-raised-button class='mat_btn_hov_act'  data-rowIndex=${params.rowIndex}  value="Save" />
              <img src="/assets/images/Download.png" data-action-type="Download" title="Download">`;
              }
            } else {
              if (params.data.rowLabel == "Renew") {
                return `<input data-action-type='editRecord' title="Renew" style="background: #fff; border-radius: 10px; border: none;" type='button'
              
              mat-raised-button class='mat_btn_hov_act'  data-rowIndex=${params.rowIndex}  value="Renew" />`;
              } else {
                return `<input data-action-type='editRecord' title="Save" style="background: #fff; border-radius: 10px; border: none;" type='button'
              
              mat-raised-button class='mat_btn_hov_act'  data-rowIndex=${params.rowIndex}  value="Save" />`;
              }
            }
          } else {
            return `<img src="/assets/images/x_icon.png" data-action-type="Remove" title = "Delete Transaction" >`;
          }
        },
      },
    ];
  }

  public defaultColDef: ColDef = {
    flex: 1,
    editable: true,
  };

  //public editType: "fullRow" = "fullRow";

  private gridApi!: GridApi;

  onCellValueChanged(event: CellValueChangedEvent) {
    console.log(
      "onCellValueChanged: " + event.colDef.field + " = " + event.newValue
    );
  }

  onRowValueChanged(event: RowValueChangedEvent) {
    var data = event.data;
    console.log("onRowValueChanged: ");
    console.log(data);
  }

  onBtStopEditing() {
    this.gridApi.stopEditing();
  }

  onBtStartEditing(index) {
    this.gridApi.setFocusedCell(index, "PolicyNumber");
    this.gridApi.startEditingCell({
      rowIndex: index,
      colKey: "PolicyNumber",
    });
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  private onResultsRowClick(event: any): void {
    if (event.event.target !== undefined) {
      console.log(event);
      this.selectedTransaction = event.data;
      let actionType = event.event.target.getAttribute("data-action-type");
      let rowIndex = event.event.target.getAttribute("data-rowIndex");
      switch (actionType) {
        case "Remove":
          if (
            confirm(
              "Are you sure you want to permanently delete this transaction?"
            )
          ) {
            this.deleteTransaction(this.selectedTransaction);
            this.userTransList.splice(
              this.userTransList.indexOf(this.selectedTransaction),
              1
            );
            this.setRowData();
            this.selectedTransaction = null;
            break;
          } else {
            break;
          }
        case "Download":
          if (confirm("Are you sure you want to re download this policy?")) {
            this.formsService
              .regeneratePolicy(event.data.TransactionId)
              .subscribe(
                (res) => {
                  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(res, "Policy.pdf");
                  } else {
                    var downloadURL = window.URL.createObjectURL(res);
                    var link = document.createElement("a");
                    link.href = downloadURL;
                    link.download = "Policy.pdf";
                    link.click();
                  }
                },
                (error) => {
                  let err: HttpErrorResponse = error;
                  this.toastr.error(err.error, "Error Downloading Policy", {
                    positionClass: "toast-bottom-right",
                  });
                }
              );
          } else {
            break;
          }
        case "editRecord":
          if (!this.isRenew) {
            this.isRenew = true;
            rowIndex = +rowIndex;
            this.tList[
              this.tList.findIndex(
                (x) => x.TransactionId == event.data.TransactionId
              )
            ].rowLabel = "Save";
            this.transactionsGridOptions.api.setRowData(this.tList);
            this.onBtStartEditing(rowIndex);
          } else {
            let cpmElements: CPMElement[] = [];
            if (isNullOrUndefined(event.data.PolicyNumber)) {
              this.toastr.error("Policy Number is blank or undefined");
              break;
            }
            if (isNullOrUndefined(event.data.PolicyEffectiveDate)) {
              this.toastr.error("Policy Effective Date is blank or undefined");
              break;
            }
            if (isNullOrUndefined(event.data.PolicyExpirationDate)) {
              this.toastr.error("Policy Expiration Date is blank or undefined");
              break;
            }
            const modalRef = this._modal.open(AdjustFormsGridComponent, {
              windowClass: "transactions-modal",
            });
            modalRef.componentInstance.formsList = this.lobForms;
            modalRef.componentInstance.selectedTransactionForms = this.selectedTransaction.Forms;
            modalRef.result.then((result: RuleEngineForm[]) => {
              if (result.length > 0 &&
                confirm(
                  `You are about to renew this policy.  Please pay close attention as this renewal may not include all form fill-ins or updated forms.  Make adjustments as necessary.`
                )
              ) {
                console.log(event.data);
                var cpmForms = this.setCpmForms(result);
                this.isRenew = false;
                this.onBtStopEditing();
                //Build CPM Element object
                let pnElem: CPMElement = new CPMElement();
                pnElem.Name = "PolicyNumber";
                pnElem.Value = event.data.PolicyNumber;
                cpmElements.push(pnElem);

                let peffElem: CPMElement = new CPMElement();
                peffElem.Name = "PolicyEffectiveDate";
                peffElem.Value = event.data.PolicyEffectiveDate;
                cpmElements.push(peffElem);

                let pexpElem: CPMElement = new CPMElement();
                pexpElem.Name = "PolicyExpirationDate";
                pexpElem.Value = event.data.PolicyExpirationDate;
                cpmElements.push(pexpElem);

                this.tList[
                  this.tList.findIndex(
                    (x) => x.TransactionId == event.data.TransactionId
                  )
                ].rowLabel = "Renew";
                this.transactionsGridOptions.api.setRowData(this.tList);

                this.formsService.getExtensionJWT().subscribe((res) => {
                  //String response kept getting processed as JSON and caused an error so I decided to handle it in the error block instead. Every common solution on Stackoverflow did not work so if
                  //someone finds a solution here please fix this.
                  this.formsService
                    .renewTransaction(event.data.TransactionId, cpmElements,cpmForms, res)
                    .subscribe(
                      (newID) => {
                        this.toastr.success(
                          `Please wait for the issuance tool to load`,
                          `Renewal Successfully Created`
                        );
                        //Need to use TransactionResponse object due to modal only accepting a single return type on close
                        let modalResponse: TransactionResponse =
                          new TransactionResponse();
                        modalResponse.TransactionId = newID;
                        modalResponse.IsRenewal = true;
                        this.activeModal.close(modalResponse);
                      },
                      (error) => {
                        console.log(error);
                        if (error.status == 200) {
                          this.toastr.success(
                            `Please wait for the issuance tool to load`,
                            `Renewal Successfully Created`
                          );
                          //Need to use TransactionResponse object due to modal only accepting a single return type on close
                          let modalResponse: TransactionResponse =
                            new TransactionResponse();
                          modalResponse.TransactionId = error.error.text;
                          modalResponse.IsRenewal = true;
                          this.activeModal.close(modalResponse);
                        } else {
                          this.toastr.error(error, `Error Creating Renewal`);
                        }
                      }
                    );
                });
              }
            });

            
          }
          break;
      }
    }
  }

  selectTransaction(): void {
    if (this.selectedTransaction != null) {
      this.activeModal.close(this.selectedTransaction);
    }
  }

  deleteTransaction(transaction: TransactionResponse): void {
    var id = transaction.TransactionId;
    this.formsService.deleteTransactionData(id).subscribe((res) => {
      console.log(`Transaction ${id} deleted.`);
    });
  }

  setCpmForms(rulesForms: RuleEngineForm[]): CPMForm[] {
    var cpmForms: CPMForm[] = [];
    rulesForms.forEach((f) => {
      let cpmForm: CPMForm = new CPMForm();
      cpmForm.InternalFormNumber = f.InternalFormNumber;
      cpmForm.LineOfBusinessID = "1564"; //change this
      cpmForm.FormName = f.FormName;
      cpmForm.Edition = "0";
      cpmForm.ExternalFormNumber = f.ExternalFormNumber;
      cpmForm.SortOrder = f.SortOrder;
      cpmForm.AttachmentReason = "Mandatory";
      cpmForm.FormFillIns = null;
      cpmForms.push(cpmForm);
    });
    return cpmForms;
  }
}
