import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid-community';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EntityLogDetailTriggerComponent } from "../entity-log-detail-trigger/entity-log-detail-trigger.component";
import { JobIdCellComponent } from '../job-id-cell/job-id-cell.component';
import { ImportQueueService } from "../../services/import-queue.service";
import { EntityLogSummary } from '../../models/entity-log-summary';


@Component({
  selector: 'app-entity-log-search',
  templateUrl: './entity-log-search.component.html',
  styleUrls: ['./entity-log-search.component.scss']
})
export class EntityLogSearchComponent implements OnInit {

  showSearchError: boolean = false;
  isFetchingData: boolean = false;
  searchResultMsg: string = "";

  @Output() addLogDetailTab = new EventEmitter();

  private gridApi: any;
  gridOptions: any;
  frameworkComponents: any;
  columnDefs: ColDef[];
  pagination: boolean = true;
  paginationAutoPageSize: boolean = true;
  idBundle: any = {};


  rowData: EntityLogSummary[] = [];

  searchInputs= new FormGroup({
    entityId: new FormControl<string>('', [Validators.required])
  });

  constructor(private queueService: ImportQueueService) {

    this.gridOptions = <GridOptions>{
      context: { componentParent: this }
    }

    this.columnDefs = [
      {
        field: 'transactionId',
        headerName: "Transaction ID",
        sortable: true,
        filter: true,
        resizable: true,
        width: 390,
        cellRenderer: 'entityLogDetailTriggerRenderer'
      },
      {
        field: 'jobId',
        headerName: "Job ID",
        sortable: true,
        filter: true,
        resizable: true,
        width: 250,
        cellRenderer: 'jobIdCell'
      },
      {
        field: 'entityType',
        headerName: "Entity Type",
        sortable: true,
        filter: true,
        resizable: true,
        width: 130
      },
      {
        field: 'policyNumber',
        headerName: "Policy Number",
        sortable: true,
        filter: true,
        resizable: true,
        width: 190
      },
      {
        field: 'claimNumber',
        headerName: "Claim Number",
        sortable: true,
        filter: true,
        resizable: true,
        width: 190
      },
      {
        field: 'status',
        headerName: "Status",
        sortable: true,
        filter: true,
        resizable: true,
        width: 140
      },
      {
        field: 'partner',
        headerName: "Partner",
        sortable: true,
        filter: true,
        resizable: true,
        width: 140
      },
      {
        field: 'timeStamp',
        headerName: "Timestamp",
        sortable: true,
        comparator: this.queueService.dateComparator,
        sort: 'asc',
        filter: true,
        resizable: true,
        cellRenderer: params => {
          return this.queueService.formatTimestamp(params.value);
        },
        width: 250
      }

    ];

    this.frameworkComponents = {
      entityLogDetailTriggerRenderer: EntityLogDetailTriggerComponent,
      jobIdCell: JobIdCellComponent
    };

  }

  ngOnInit(): void {
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
    if (this.isFetchingData) {
      this.toggleSearchGridStatus(true);
    }
  }

  toggleSearchGridStatus(loading: boolean): void {
    if (this.gridApi) {
      loading ? this.gridApi.showLoadingOverlay() : this.gridApi.hideOverlay();
    }
  }

  executeSearch() {
    this.isFetchingData = true;
    this.showSearchError = false;
    this.searchResultMsg = "";
    this.toggleSearchGridStatus(true);
    this.queueService.getEntityLogData(this.searchInputs.controls.entityId.value)
      .subscribe(
        // Handle successful retrieval of the job records
        (response: any) => {
          this.isFetchingData = false;    
          this.rowData = this.parseResponseData(response);
          this.toggleSearchGridStatus(false);
        },
        // Handle an error response
        error => {
          if (error.error.status == "The id requested does not match any known claim/policy number") {
            this.searchResultMsg = error.error.status;
          } else {
            this.showSearchError = true;
          }

          this.rowData = [];
          this.isFetchingData = false;
          this.toggleSearchGridStatus(false);
        }
      )


  }

  parseResponseData(responseData: any): EntityLogSummary[] {
    let records = [];
    let catalog = {};
    this.searchResultMsg = responseData.searchResults.length + " matching records found.";

    for (let r in responseData.searchResults) {

      let summary = {
        transactionId: responseData.searchResults[r].transactionId,
        entityType: responseData.searchResults[r].entityType,
        policyNumber: responseData.searchResults[r].policyNumber,
        claimNumber: responseData.searchResults[r].claimNumber,
        jobId: responseData.searchResults[r].jobId,
        transactionType: responseData.searchResults[r].transactionType,
        status: responseData.searchResults[r].status,
        partner: responseData.searchResults[r].partner,
        timeStamp: this.queueService.formatTimestamp(responseData.searchResults[r].timeStamp),
        errorLogs: [],
        actionHistory: []
      };

      records.push(summary);
      //Capture the index
      catalog[responseData.searchResults[r].transactionId] = records.length - 1;
    }

    for (let e in responseData.errorLogs) {
      let recordIndex = catalog[responseData.errorLogs[e].transactionId];
      records[recordIndex].errorLogs.push(responseData.errorLogs[e]);
    }

    for (let ah in responseData.actionHistory) {
      let recordIndex = catalog[responseData.actionHistory[ah].transactionId];
      responseData.actionHistory[ah].note = responseData.actionHistory[ah]["note/reason/error"];
      responseData.actionHistory[ah].jobId = responseData.actionHistory[ah]["jobID"];
      records[recordIndex].actionHistory.push(responseData.actionHistory[ah]);
    }

    return records;
    
  }

  addEntityLogDetailTab(idBundle: any) {
    this.addLogDetailTab.emit(idBundle);
  }

}
