import { ReportInstance } from "./report-instance";

export class Report {
  title: string;
  instances: any = {};
  targetInstance: ReportInstance;
  
  constructor(title: string) {
    this.title = title;
  }

  addInstance(key: string, url: string, title?: string): void {
    this.instances[(key.toLowerCase())] = new ReportInstance(url, title); 
  }

  // Useful when displaying the same report across multiple specific UI environments
  addInstances(keys: string[], url: string, title?: string): void {
    keys.forEach(key => {
      this.addInstance(key, url, title);
    });
  }

  setTargetInstance(key: string) {
    this.targetInstance = Object.keys(this.instances).includes(key) ? this.instances[key] : this.instances["default"];
    if (this.targetInstance == undefined) {
      console.log("The targeted report instance '" + key + "' does not exist");
    }
  }

}
