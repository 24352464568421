//Angular Imports 
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { MsalGuard } from '../msal/msal-guard.service';

//Internal Imports
import { GhostPortalComponent } from '../ghostPortal/components/ghostPortalComponent';
import { AuthenticationResolver } from '../framework';
import { AuthGuard } from '../framework/guards/auth.guard';
import { FormsListModule } from './formsList';
import { DeployUtilityModule } from './deployUtility';
import { QueueModule } from './queue';
import { ImageRightModule } from './imageRight/imageRight.module';
import { RulesModule } from './rules/rules.module';
import { TestHarnessModule } from '../diagnostics/testSuites/testHarness/testHarness.module';
import { EmailModule } from './email/email.module';
import { CompareModule } from './compare/compare.module';
import { MGDReportsModule } from './reports/MGDReports.module';
import { ConvertModule } from './convert/convert.module';

export const routes: Routes = [
  {
    path: '', component: GhostPortalComponent,
    resolve: {
      token: AuthenticationResolver
    },
    children: [
      {
        // No AuthGuard on forms path because we assume anyone can access this page in at least read-only mode
        path: 'forms', canActivate: [MsalGuard],
        loadChildren: () => FormsListModule
      },
      {
        path: 'testHarness', canActivate: [MsalGuard, AuthGuard],
        loadChildren: () => TestHarnessModule
      },
      {
        path: 'deployUtility', canActivate: [MsalGuard, AuthGuard],
        loadChildren: () => DeployUtilityModule
      },
      {
        path: 'imageRight', canActivate: [MsalGuard, AuthGuard],
        loadChildren: () => ImageRightModule
      },
      {
        path: 'rules', canActivate: [MsalGuard, AuthGuard],
        loadChildren: () => RulesModule
      },
      {
        path: 'email', canActivate: [MsalGuard, AuthGuard],
        loadChildren: () => EmailModule
      },
      {
        path: 'queue', canActivate: [MsalGuard, AuthGuard],
        loadChildren: () => QueueModule
      },
      {
        path: 'compare', canActivate: [MsalGuard, AuthGuard],
        loadChildren: () => CompareModule
      },
      {
        path: 'convert', canActivate: [MsalGuard, AuthGuard],
        loadChildren: () => ConvertModule
      },
      {
        path: 'reports', canActivate: [MsalGuard, AuthGuard],
        loadChildren: () => MGDReportsModule
      },
      {
        path: '**',
        redirectTo: 'forms',
        pathMatch: 'full'
      }
    ]
  }
];

/**
  Main Layout Routing Module
*/
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthenticationResolver]
})

export class GhostPortalRoutingModule {
}
