<div class="component-container">
  <div class="component-heading-container">
    <div class="col-md-12">
      <div class="row" style="padding-left: 10px">
        <h3 class="component-heading-text">Convert Documents</h3>
      </div>
      <div style="white-space: pre">
        <a href="https://markel.atlassian.net/wiki/spaces/DOCSOL/pages/100697819/Word+Applications+to+Fillable+PDFs" target="_blank">&nbsp;&nbsp;&nbsp;Confluence Documentation</a>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="centered">
      <div class="dropzone"
           fileDragDrop
           (filesChangeEmiter)="onFileChangeA($event)">
        <div class="text-wrapper">
          <div class="centered">
            <input matInput
                   class="file-input"
                   type="file"
                   name="Document"
                   id="wordDoc"
                   #uploadWord
                   accept=".docx,.doc,.pdf"
                   (change)="onFileChangeA($event)"
                   multiple />
            <button type="button"
                    _ngcontent-fca-c383=""
                    mat-stroked-button=""
                    class="textLink btn-teal"
                    (click)="uploadWord.click()">
              <mat-icon style="height: 25px; padding-top: 2px"
                        class="tw-icon-3xl"
                        fontSet="fa"
                        fontIcon="fa-file"></mat-icon>
              Select your file
            </button>
            <label for="file"><span class="DropFileHere">or Drop it here!</span></label>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-5"></div>
      <div class="col-md-2">
        <div class="funcButton" id="convertButton" type="button">
          <button type="button"
                  _ngcontent-jvt-c197=""
                  mat-stroked-button=""
                  class="btn-teal mat-focus-indicator mat-raised-button mat-button-base"
                  (click)="getWordFile()">
            <mat-icon class="tw-flex-none"
                      svgIcon="mat:compare_arrows"></mat-icon>
            Convert
          </button>
        </div>
      </div>
      <div class="col-md-5"></div>
    </div>

    <div class="results" *ngif="this.datalocalURL != null">
      <label class="result">Result</label>
      <iframe class="heatmap" src="" type="application/zip"></iframe>
    </div>
  </div>
</div>
