<div class="add_copy_rule">
  <div class="modal-header">
    <h4 class="modal-title">Add/Edit Print Order</h4>
  </div>
  <form [formGroup]="formGroup">
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="row fast_admin">
            <div class="col-md-12 mrgbtm10">
              <label style="font-weight: bold">Line Of Business</label>
              <input
                id="txtLineOfBusiness"
                placeholder="Line of Business"
                formControlName="lineOfBusiness"
                class="vex-input"
                readonly
              />
            </div>
            <div class="col-md-12 mrgbtm10">
              <label style="font-weight: bold">Form Type</label>
              <div class="selectdiv">
                <label>
                  <ng-select
                    id="formType"
                    class="custom-select vex-input"
                    formControlName="formType"
                    [items]="formTypes"
                    bindLabel="Description"
                    bindValue="Description"
                  >
                  </ng-select>
                </label>
                <div
                  *ngIf="
                    formGroup.controls.formType.invalid &&
                    formGroup.controls.formType.dirty
                  "
                >
                  <span
                    [hidden]="!formGroup.controls['formType'].errors.required"
                    class="error_msg"
                  >
                    <i class="error_icon"></i>Form Type is required.
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-12 mrgbtm10">
              <label style="font-weight: bold">Sort Order</label>
              <input
                id="txtSortOrder"
                placeholder="Sort Order"
                formControlName="sortOrder"
                pattern="^[0-9]+$"
                class="vex-input"
              />
              <div
                *ngIf="
                  formGroup.controls.formType.invalid &&
                  formGroup.controls.formType.dirty
                "
              >
                <span
                  [hidden]="!formGroup.controls['sortOrder'].errors.required"
                  class="error_msg"
                >
                  <i class="error_icon"></i>Sort Order is required
                </span>
              </div>
              <div *ngIf="formGroup.controls['sortOrder'].errors?.pattern">
                <span class="error_msg">
                  <i class="error_icon"></i>Please Enter numbers only
                </span>
              </div>
            </div>
            <div class="col-md-12 mrgbtm10">
              <label style="font-weight: bold">Sort Order Description</label>
              <input
                id="txtDescription"
                placeholder="Description"
                formControlName="description"
                class="vex-input"
              />
              <div
                *ngIf="
                  formGroup.controls.description.invalid &&
                  formGroup.controls.description.dirty
                "
              >
                <span
                  [hidden]="!formGroup.controls['description'].errors.required"
                  class="error_msg"
                >
                  <i class="error_icon"></i>Sort Order Description is required
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="modal-footer">
    <button
      type="button"
      class="mat_btn_hov_act mrgrgt10"
      mat-raised-button
      (click)="activeModal.close()"
    >
      Cancel
    </button>
    <button
      type="button"
      mat-raised-button
      class="btn-teal"
      (click)="saveprintOrder()"
    >
      Continue
    </button>
  </div>
</div>
