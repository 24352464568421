import { Component, EventEmitter, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { LogMetric } from '../../models/log-metric';
import { OdenService } from '../../services/oden.service';
import { SearchFilterData } from '../../models/search-filter-data';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-log-search',
  templateUrl: './log-search.component.html',
  styleUrls: ['./log-search.component.scss']
})
export class LogSearchComponent implements OnInit {
  showSearchError: boolean = false;
  showFilterError: boolean = false;
  isFetchingData: boolean = false;
  searchResultMsg: string = "";

  private gridApi: any;
  gridOptions: any;
  frameworkComponents: any;
  columnDefs: ColDef[];
  pagination: boolean = true;
  paginationAutoPageSize: boolean = true;
  idBundle: any = {};

  rowData: LogMetric[] = [];
  subs: Subscription[] = [];

  @ViewChild('searchFilterOptions') searchFilterOptions: any;

  constructor(private odenService: OdenService) {
    this.gridOptions = <GridOptions>{
      context: { componentParent: this }
    }

    this.columnDefs = [
      { headerName: 'Timestamp', field: 'Timestmp', sortable: true, filter: true, resizable: true, width: 180 },
      { headerName: 'Message', field: 'FormattedMessage', sortable: true, filter: true, resizable: true, width: 390 },
      { headerName: 'Logger Name', field: 'LoggerName', sortable: true, filter: true, resizable: true, width: 390 },
      { headerName: 'Level', field: 'LevelString', sortable: true, filter: true, resizable: true, width: 100 },
      { headerName: 'Thread Name', field: 'ThreadName', sortable: true, filter: true, resizable: true, width: 150 },
      { headerName: 'Reference Flag', field: 'ReferenceFlag', sortable: true, filter: true, resizable: true, width: 100 },
      { headerName: 'Arg0', field: 'Arg0', sortable: true, filter: true, resizable: true, width: 100 },
      { headerName: 'Arg1', field: 'Arg1', sortable: true, filter: true, resizable: true, width: 100 },
      { headerName: 'Arg2', field: 'Arg2', sortable: true, filter: true, resizable: true, width: 100 },
      { headerName: 'Arg3', field: 'Arg3', sortable: true, filter: true, resizable: true, width: 100 },
      { headerName: 'Caller Filename', field: 'CallerFilename', sortable: true, filter: true, resizable: true, width: 300 },
      { headerName: 'Caller Class', field: 'CallerClass', sortable: true, filter: true, resizable: true, width: 300 },
      { headerName: 'Caller Method', field: 'CallerMethod', sortable: true, filter: true, resizable: true, width: 200 },
      { headerName: 'Caller Line', field: 'CallerLine', sortable: true, filter: true, resizable: true, width: 80 },
      { headerName: 'Event ID', field: 'EventId', sortable: true, filter: true, resizable: true, width: 100 }
    ];

  } 
  ngOnInit(): void {
    let filterDataPayload = this.odenService.currentSearchFilterData.subscribe((searchFilterData: SearchFilterData) => {
      if (searchFilterData) {
        this.performSearch(searchFilterData);
      }
    });
    this.subs.push(filterDataPayload);
  }

  ngOnDestroy(): void {
    this.subs.forEach((s: Subscription) => {
      s.unsubscribe();
    });
  }

  performSearch(searchFilterData: SearchFilterData): void {
    this.isFetchingData = true;
    this.showSearchError = false;
    this.searchResultMsg = "";
    this.toggleSearchGridStatus(true);
    // Call retrieveLogMetrics method from odenService
    this.odenService.retrieveLogMetrics(searchFilterData)
      .subscribe({
        next: logsResponse => {
          this.isFetchingData = false;
          this.rowData = this.parseResponseData(logsResponse);
          this.toggleSearchGridStatus(false);
        },
        error: error => {
          if (error.status == "The id requested does not match any known claim/policy number") {
            this.searchResultMsg = error.status;
          } else {
            this.showSearchError = true;
          }

          this.rowData = [];
          this.isFetchingData = false;
          this.toggleSearchGridStatus(false);
        }
      });
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
    if (this.isFetchingData) {
      this.toggleSearchGridStatus(true);
    }
  }

  toggleSearchGridStatus(loading: boolean): void {
    if (this.gridApi) {
      loading ? this.gridApi.showLoadingOverlay() : this.gridApi.hideOverlay();
    }
  }

  parseResponseData(responseData: any): LogMetric[] {
    let records = [];

    this.searchResultMsg = responseData.LogMetrics.length + " matching records retrieved. Click the column headers to sort and filter the retrieved data.";

    for (let r in responseData.LogMetrics) {
      // Create a new log object
      let log: LogMetric = {
        Timestmp: this.odenService.formatTimestamp(Number(responseData.LogMetrics[r].Timestmp)),
        FormattedMessage: responseData.LogMetrics[r].FormattedMessage,
        LoggerName: responseData.LogMetrics[r].LoggerName,
        LevelString: responseData.LogMetrics[r].LevelString,
        ThreadName: responseData.LogMetrics[r].ThreadName,
        ReferenceFlag: responseData.LogMetrics[r].ReferenceFlag,
        Arg0: responseData.LogMetrics[r].Arg0,
        Arg1: responseData.LogMetrics[r].Arg1,
        Arg2: responseData.LogMetrics[r].Arg2,
        Arg3: responseData.LogMetrics[r].Arg3,
        CallerFilename: responseData.LogMetrics[r].CallerFilename,
        CallerClass: responseData.LogMetrics[r].CallerClass,
        CallerMethod: responseData.LogMetrics[r].CallerMethod,
        CallerLine: responseData.LogMetrics[r].CallerLine,
        EventId: responseData.LogMetrics[r].EventId
      };

      records.push(log);
    }

    return records;
  }

  toggleFilterError(toggleValue: boolean): void {
    this.showFilterError = toggleValue;
  }

  retryFilterOptions(event: any): void {
    event.preventDefault();
    this.searchFilterOptions.retryOptionsRetrieval();
  }
}
