import { Component, OnInit, OnDestroy } from '@angular/core';
import { ImportQueueService } from '../../services/import-queue.service';
import { PartnerHealth } from '../../models/partner-health';
import { formatDate } from "@angular/common";
import { PartnerHealthFilter } from '../../models/partner-health-filter';
import { JobHealth } from '../../models/job-health';

@Component({
  selector: 'app-health-meter',
  templateUrl: './health-meter.component.html',
  styleUrls: ['./health-meter.component.scss']
})
export class HealthMeterComponent implements OnInit {

  isLoading: boolean = true;
  showError: boolean = false;
  lastUpdateDate: string;

  healthData: PartnerHealth[] = [];
  previousHealthData: PartnerHealth[] = [];

  constructor( private queueService: ImportQueueService ) { }

  ngOnInit(): void {
    this.setLastUpdateDate();
    this.populateHealthMeter();
  }

  ngOnDestroy(): void {
    /*
     Need to emit an inert PartnerHealthFilter so the UI does not attempt to run a partner filter when the user returns
     to the queue UI.
    */
    this.queueService.emitPartnerHealthFilter(new PartnerHealthFilter({
      active: false
    }));
  }

  setLastUpdateDate(): void {
    this.lastUpdateDate = formatDate(new Date(), 'M/d/y, h:mm:ss a', 'en-US');
  }

  refreshHealthMeter(event: any): void {
    event.preventDefault();
    this.populateHealthMeter();
  }

  populateHealthMeter(): void {
    this.isLoading = true;
    this.showError = false;
    this.previousHealthData = [...this.healthData];
    this.healthData = [];
    this.queueService.getHealthMeterData()
      .subscribe(
        // Handle successful retrieval of the health meter
        (response: any) => {
          this.parseHealthData(response);
          this.setLastUpdateDate();
          this.isLoading = false;
        },
        // Handle an error response
        error => {
          // Restore previous data so user can still work with it
          this.healthData = [...this.previousHealthData];
          this.showError = true;
          this.isLoading = false;
        });
  }

  parseHealthData(partnerData: any[]): void {
    partnerData.forEach(partner => {
      this.healthData.push(new PartnerHealth(partner));
    });
  }

  executeJobFilter(event: any, partnerId: string, jobId: string, jobDate: string, jobHealthStatus: string): void {
    event.preventDefault();
    // No action take if job is blocked (meaning job exists but no transaction records exist for it to examine)
    if (jobHealthStatus == "blocked") {
      return;
    }
    // Making sure bad date values are mitigated 
    let endDate = isNaN(Date.parse(jobDate)) ? this.getDateRangeDate(new Date(), 365) : this.getDateRangeDate(jobDate, 1);
    let startDate = isNaN(Date.parse(jobDate)) ? this.getDateRangeDate(new Date(), -365) : this.getDateRangeDate(jobDate, -1);
    this.queueService.emitPartnerHealthFilter(new PartnerHealthFilter({
      startDate: startDate,
      endDate: endDate,
      status: jobHealthStatus === 'success' ? [] : this.queueService.getProblemStatuses(),
      partner: partnerId,
      jobId: jobId,
      active: true
    }));
  }


  executePartnerFilter(event: any, partnerId: string, oldestJob: JobHealth, jobIdArray: string[] ): void {
    event.preventDefault();
    let endDate = new Date();
    this.queueService.emitPartnerHealthFilter(new PartnerHealthFilter({
      startDate: isNaN(Date.parse(oldestJob.jobDate)) ? this.getDateRangeDate(endDate, -14) : oldestJob.jobDate,
      endDate: endDate,
      partner: partnerId,
      jobIdArray: jobIdArray,
      active: true
    }));
  }

  getDateRangeDate(sourceDate: any, offset: number) {
    let dateObj = new Date(sourceDate);
    return new Date(dateObj.getFullYear(), dateObj.getMonth(), (dateObj.getDate() + offset), 0, 0, 0, 0);
  }
  

}
