//Angular Imports
import { Component, OnInit, OnDestroy } from "@angular/core";

//Third Party Imports
import * as moment from "moment";
import {
  IAfterGuiAttachedParams,
  IDoesFilterPassParams,
  IFilterParams,
  RowNode,
} from "ag-grid-community";
import { IFilterAngularComp } from "ag-grid-angular";

/**
 * Date Filter Component
 */
@Component({
  selector: "app-boolean-filter",
  template: ` <div class="ag-filter-body-wrapper">
    <div class="ag-filter-body">
      <input
        class="ag-filter-filter"
        id="isTrue"
        type="radio"
        value="true"
        [(ngModel)]="value"
        (ngModelChange)="onChange()"
      />
      <span>Is True</span>
    </div>
    <div class="ag-filter-body">
      <input
        class="ag-filter-filter"
        id="isFalse"
        type="radio"
        value="false"
        [(ngModel)]="value"
        (ngModelChange)="onChange()"
      />
      <span>Is False</span>
    </div>
    <div class="ag-filter-body">
      <input
        class="ag-filter-filter"
        id="isNeither"
        type="radio"
        value="neither"
        [(ngModel)]="value"
        (ngModelChange)="onChange()"
      />
      <span>Neither</span>
    </div>
  </div>`,
  styles: ["span {margin-right:5px}"],
})
export class BooleanFilterComponent
  implements IFilterAngularComp, OnInit, OnDestroy
{
  // Private Variables
  /**
   * Params
   */
  private _params: IFilterParams;

  /**
   * ValueGetter
   */
  private _valueGetter: (rowNode: RowNode) => any;

  // Public Variables
  /**
   * True or False
   */
  public value: string;

  // Constructor
  /**
   * @ignore
   * */
  constructor() {}

  // Angular Lifecycles
  /**
   * @ignore
   * */
  ngOnInit(): void {}

  /**
   * @ignore
   * */
  ngOnDestroy(): void {}

  // Public Methods
  /**
   * AG Init
   * @param params Grid filter params
   */
  agInit(params: IFilterParams): void {
    this.value = "neither";
    this._params = params;
    this._valueGetter = params.valueGetter.apply;
  }

  /**
   * Is Filter Active (Method called by the grid to check if the filter is active)
   * */
  isFilterActive(): boolean {
    return this.value != "neither";
  }

  /**
   * Does Filter Pass (Method called by the grid to check if the data passes the filter)
   * @param params Grid Params
   */
  doesFilterPass(params: IDoesFilterPassParams): boolean {
    let returnValue = true;
    if (this.value != null) {
      if (this.value == "true") {
        returnValue = this._valueGetter(params.node) ? true : false;
      } else if (this.value == "false") {
        returnValue = !this._valueGetter(params.node) ? true : false;
      }
    }
    return returnValue;
  }

  /**
   * Get Model
   * */
  getModel(): any {
    if (this.value == "neither") return { value: null };
    else if (this.value == "false") return { value: false };
    else return { value: true };
  }

  /**
   * Set Model
   * @param model Model
   */
  setModel(model: any): void {
    if (model && model.value) {
      this.value = "true";
    } else if (model && model.value == null) {
      this.value = "neither";
    } else if (model && !model.value) {
      this.value = "false";
    }
  }

  /**
   * On Change
   * */
  onChange(): void {
    this._params.filterChangedCallback();
  }

  /**
   * Clear Filter
   * */
  clearFilter(): void {
    this.value = "neither";
    this._params.filterChangedCallback();
  }
}
