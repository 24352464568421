//Angular Imports 
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

//Third Party Imports
import { Observable, Subscriber } from "rxjs";
import { map } from "rxjs/operators";
import { QueueLog } from "../models/queueLog";
import { FetchLogsRequest, Filter } from "../models/FetchLogsRequest";


/**
  * Policy Forms Provider
*/
@Injectable()
export class QueueService {

  private masterData: any;
  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient) {
  }

  getQueueStatus(env: string, transactionId: string): Observable<QueueLog> {
    var url = `https://mkl-doc-queue-dev2.azurewebsites.net/api/storage/status?transactionId=${transactionId}&environment=${env}`;
    return this.http.get<QueueLog>(url).pipe(map(res => res));
  }

  getSelectedRecordsForEnv(env: string, startPage: number, paginationSize: number, filterModel: any): Observable<QueueLog[]> {
    let requestBody = { Environment: env, StartPage: startPage, PageSize: paginationSize, Filters: filterModel };
    let requestBodyNew: FetchLogsRequest = this.transitionObject(requestBody);

    let url = `https://mkl-doc-queue-dev2.azurewebsites.net/api/storage/entries`;
    if (env.toUpperCase() == "PROD")
      url = `https://mkl-doc-queue-api.azurefd.net/api/storage/entries`;
    return this.http.post<QueueLog[]>(url, requestBodyNew).pipe(map(res => res));
  }

  transitionObject(input: { Environment: string; StartPage: number; PageSize: number; Filters: any;}): FetchLogsRequest {
    const filters: Filter[] = [];
    for (const key in input?.Filters) {
      const filterobj = input?.Filters[key];
      if (input.Filters?.hasOwnProperty(key)) {
        if(input?.Filters[key].type === 'dateRange') {
          filters.push({
            Name: key,
            Type: filterobj.type,
            Gte: filterobj?.values?.from,
            Lte: filterobj?.values?.to
          })
        } else if(input?.Filters[key]?.type === 'equals') {
          filters.push({
            Name: key,
            Type: filterobj.type,
            Value: filterobj.filter ?? ''
          })
        }
        else {
          const values = Object.keys(input.Filters[key]?.values)?.filter(k => input.Filters[key].values[k]);
          filters.push({
            Name: key,
            Type: filterobj.type,
            Values: values
          });
        }    
      }
    }

    return { Environment: input.Environment, StartPage: input.StartPage, PageSize: input.PageSize, Filters: filters };
  }

  getBaseCpm(isV2: boolean): Observable<string> {
    let cpmFileName = "";
    if (isV2) cpmFileName = "BaseCPMv2.xml"; else cpmFileName = "BaseCPM.xml";
    return this.http.get(`assets/files/${cpmFileName}`, { responseType: 'text' }).pipe(map(res => res));
  }

  getQueueMessage(env: string, dealNumber: string, callingSystem: string): Observable<any> {
    let url = `https://mkl-doc-queue-dev2.azurewebsites.net/api/storage/message?environment=${env}&dealNumber=${dealNumber}&callingSystem=${callingSystem}`;
    if (env.toUpperCase() == "PROD")
      url = `https://mkl-doc-queue-api.azurefd.net/api/storage/message?environment=${env}&dealNumber=${dealNumber}&callingSystem=${callingSystem}`;
    return this.http.get<any>(url).pipe(map(res => res));
  }

  initiateRetry(env: string, id: string, callingSystem: string): Observable<any> {
    let url = `https://mkl-doc-queue-dev2.azurewebsites.net/api/retry/message?env=${env}&id=${id}&callingSystem=${callingSystem}`;
    if (env.toUpperCase() == "PROD")
      url = `https://mkl-doc-queue-api.azurefd.net/api/retry/message?env=${env}&id=${id}&callingSystem=${callingSystem}`;
    return this.http.get(url, {responseType: 'text'}).pipe(map(res => res));
  }

}
