import { Component, OnInit } from '@angular/core';
import { formatNumber } from '@angular/common';
import { ColDef, FilterChangedEvent, FirstDataRenderedEvent, GridOptions } from 'ag-grid-community';
import { ImportQueueService } from '../../services/import-queue.service';
import { FooterCellComponent } from '../footer-cell/footer-cell.component'


@Component({
  selector: 'app-partner-statistics',
  templateUrl: './partner-statistics.component.html',
  styleUrls: ['./partner-statistics.component.scss']
})
export class PartnerStatisticsComponent implements OnInit {

  isLoading: boolean = true;
  hasError: boolean = false;

  private gridApi: any;
  gridOptions: GridOptions;
  frameworkComponents: any;
  columnDefs: ColDef[];
  pagination: boolean = false;

  partnerData: any[] = [];
  partnerTotals: any[] = [
    { partner: "TOTAL", policyCount: 0, claimCount: 0, premium: 0 }
  ];

  constructor(private queueService: ImportQueueService) {
    let self = this;
    this.gridOptions = {
      onFilterChanged(event: FilterChangedEvent) {
        self.updateTotals();
      }
    }

    this.columnDefs = [
      {
        field: "partner", headerName: "Partner", sortable: true, filter: true,
        cellRendererSelector: (params) => {
          if (params.node.rowPinned) {
            return {
              component: FooterCellComponent,
              params: {
                style: { "font-weight": "bold" }
              }
            };
          } else {
            return undefined;
          }
        }
      },
      {
        field: "policyCount", headerName: "Polices (YTD)", type: "numericColumn", sortable: true, filter: true,
        valueFormatter: function ( params ) {
          return formatNumber(params.value, "en-US", "1.0-0");
        },
        cellRendererSelector: (params) => {
          if (params.node.rowPinned) {
            return {
              component: FooterCellComponent,
              params: {
                style: { "font-weight": "bold" },
                value: (formatNumber(params.value, "en-US", "1.0-0"))
              }
            };
          } else {
            return undefined;
          }
        }
      },
      {
        field: "claimCount", headerName: "Claims (YTD)", type: "numericColumn", sortable: true, filter: true,
        valueFormatter: function (params) {
          return formatNumber(params.value, "en-US", "1.0-0");
        },
        cellRendererSelector: (params) => {
          if (params.node.rowPinned) {
            return {
              component: FooterCellComponent,
              params: {
                style: { "font-weight": "bold" },
                value: (formatNumber(params.value, "en-US", "1.0-0"))
              }
            };
          } else {
            return undefined;
          }
        }
      },
      {
        field: "premium", headerName: "Premium (YTD)", type: "numericColumn", sortable: true, filter: true,
        valueFormatter: function (params) {
          return ("$" + formatNumber(params.value, "en-US", "1.0-0"));
        },
        cellRendererSelector: (params) => {
          if (params.node.rowPinned) {
            return {
              component: FooterCellComponent,
              params: {
                style: { "font-weight": "bold" },
                value: ("$" + formatNumber(params.value, "en-US", "1.0-0"))
              }
            };
          } else {
            return undefined;
          }
        }
      }
    ]
  }

  ngOnInit(): void {
    this.getPartnerStatistics();
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;   
  }

  // Have to account for the fact that the grid API may not be ready yet.
  autoSizeGridColumns(): boolean {
    if (!this.gridApi) {
      return false;
    } else {
      this.gridOptions.api.sizeColumnsToFit();
      return true;
    }
  }

  getPartnerStatistics(): void {
    this.isLoading = true;
    this.hasError = false;
    this.queueService.getPartnerStats()
      .subscribe(
        // Handle successful retrieval 
        (response: any) => {
          this.parseData(response.ProductPartnerYTD, true);
          this.isLoading = false;
        },
        // Handle an error response
        error => {
          this.isLoading = false;
          this.hasError = true;
        });
  }

  parseData(data: any[], setTableData: boolean = false) {
    this.resetTotals();
    if (!setTableData) {
      data.forEach((p: any) => {
        this.partnerTotals[0].policyCount += !setTableData ? p.policyCount : p.YTDPolicyCount;
        this.partnerTotals[0].claimCount += !setTableData ? p.claimCount : p.YTDClaimCount;
        this.partnerTotals[0].premium += !setTableData ? p.premium : p.YTDPremiumTotal
      });
    } else {
      // Currently some partners have multiple stat records, so combining those records
      let partnerMap = {};
      data.forEach((p: any) => {
        if (!partnerMap[p.Partner]) {
          partnerMap[p.Partner] = {
            partner: p.Partner,
            policyCount: p.YTDPolicyCount,
            claimCount: p.YTDClaimCount,
            premium: p.YTDPremiumTotal
          }
        } else {
          partnerMap[p.Partner].policyCount += p.YTDPolicyCount;
          partnerMap[p.Partner].claimCount += p.YTDClaimCount;
          partnerMap[p.Partner].premium += p.YTDPremiumTotal;
        }

        this.partnerTotals[0].policyCount += !setTableData ? p.policyCount : p.YTDPolicyCount;
        this.partnerTotals[0].claimCount += !setTableData ? p.claimCount : p.YTDClaimCount;
        this.partnerTotals[0].premium += !setTableData ? p.premium : p.YTDPremiumTotal
      });

      Object.keys(partnerMap).forEach((pn: string) => {
        this.partnerData.push( partnerMap[pn] );
      });

      this.partnerData.sort((a, b): any => {
        return a.partner > b.partner;
      });
    
    }
    
  }

  resetTotals(): void {
    this.partnerTotals[0].policyCount = 0;
    this.partnerTotals[0].claimCount = 0;
    this.partnerTotals[0].premium = 0;
  }

  updateTotals(): void {
    let visibleRowData = [];
    this.gridApi.forEachNodeAfterFilter((node: any) => {
      visibleRowData.push(node.data);
    });
    this.parseData(visibleRowData);
    this.gridApi.setPinnedBottomRowData( this.partnerTotals );
  }

  // Adds brief delay so spinner appears and user knows the retry attempt was made.
  fixStatisticsData(): void {
    event.preventDefault();
    event.stopPropagation();
    this.isLoading = true;
    let _this = this;
    setTimeout(() => {
      _this.getPartnerStatistics();
    }, 500);
  }

}
