import { NgModule } from '@angular/core';
import { A11yModule } from '@angular/cdk/a11y';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  exports: [
    A11yModule,
    CdkAccordionModule,
    ClipboardModule,  
    MatButtonModule,  
    MatCheckboxModule,   
    MatDatepickerModule,
    MatInputModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatRippleModule,
    MatSnackBarModule
  ]
})
export class AngularMaterialModule { }
