<div class="container-fluid outer-box">
  <div class="row">

    <div class="col-md-3">
      <h4 class="queue-title">Job Filter Options</h4>
    </div>

    <div class="col-md-9">

      <p class="alert mdo-standard-info-block" *ngIf="!showJobFilterError">
        <span>Use the column headers to sort and filter the data in the table.</span>

        <button mat-raised-button class="mdo-standard-button job-upload-btn" (click)="openFileUploadModal()" [disabled]="!isAuthorizedToUpload">
          Upload
        </button> 

        <app-update-jobs-menu id="job-action-btn"
                              [singleJob]="false"
                              [selectedJobCount]="selectedJobCount"
                              [context]="'summary'"
                              (triggerJobUpdateModal)="openJobUpdateModal($event)">
        </app-update-jobs-menu>

      </p>
      <p id="job-filter-option-error-alert" class="alert mdo-alert-danger" *ngIf="showJobFilterError">
        There was an error with populating the filter options.  Click <a class="mdo-alert-danger-link" href="" (click)="retryJobFilterOptions($event)">here</a> to re-request the filter options.
      </p>
    </div>

  </div>

  <div class="row">
    <div id="job-filter-column" class="col-md-3">
      <app-job-request-filter #jobFilterOptions
                              [errorStatuses]="errorStatuses"
                              [initialStartDate]="initialStartDate"
                              [initialEndDate]="initialEndDate"
                              (toggleJobGridLoad)="toggleJobGridLoadStatus($event)"
                              (refreshJobGrid)="getJobData($event)"
                              (toggleJobFilterError)="toggleJobFilterErrorState($event)">

      </app-job-request-filter>
    </div>

    <div id="job-grid-column" class="col-md-9">
      <ag-grid-angular [hidden]="showJobTableError" id="jobGrid"
                       style="width: 100%; height: 700px;"
                       class="ag-theme-balham mdo-standard-grid"
                       [rowData]="rowData"
                       [columnDefs]="columnDefs"
                       (gridReady)="onGridReady($event)"
                       [frameworkComponents]="frameworkComponents"
                       [gridOptions]="gridOptions"
                       [rowSelection]="rowSelection"
                       [rowMultiSelectWithClick]="rowMultiSelectWithClick"
                       [pagination]="pagination"
                       [paginationAutoPageSize]="paginationAutoPageSize"
                       (selectionChanged)="onJobSelectionChanged($event)">

      </ag-grid-angular>
      <p id="job-table-retrieval-error-alert" class="alert mdo-alert-danger" [hidden]="!showJobTableError">
        There was an error with retrieving the job data.  Click the "Get Matching Queue Data" button to retry.
      </p>
    </div>
  </div>

</div>


<app-update-jobs #updateJobModal (triggerQueueUpdate)="refreshFromCache()"></app-update-jobs>

<app-upload-files #uploadFilesModal></app-upload-files>
