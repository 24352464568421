<div id="master-view-content-wrapper">
  <mat-tab-group id="import-queue-tabs" animationDuration="0ms" [selectedIndex]="activeTab" (selectedIndexChange)="changeActiveTab($event)">
    <mat-tab id="dashboard-tab" label="Dashboard">
      <app-dashboard></app-dashboard>
    </mat-tab>

    <mat-tab id="entity-log-search-tab" label="Log Search">
      <app-entity-log-search (addLogDetailTab)="addTab($event)">
      </app-entity-log-search>
    </mat-tab>

    <mat-tab id="transaction-queue-tab" label="Transaction Queue">

      <div class="container-fluid outer-box">
        <div class="row">

          <div class="col-md-3">
            <h4 class="queue-title">Filter Options</h4>
          </div>

          <div class="col-md-9">
            <p class="alert mdo-standard-info-block" *ngIf="!showFilterError">
              <span>Use the column headers to sort and filter the data in the table.</span>
              <app-update-transaction-menu id="summary-action-btn"
                                           [singleTransaction]="false"
                                           [selectedTransactionCount]="selectedCount"
                                           [context]="'summary'"
                                           (triggerUpdateModal)="openUpdateModal($event)">
              </app-update-transaction-menu>
            </p>
            <p id="filter-option-error-alert" class="alert mdo-alert-danger" *ngIf="showFilterError">There was an error with populating the filter options.  Click <a class="mdo-alert-danger-link" href="" (click)="retryFilterOptions($event)">here</a> to re-request the filter options.</p>
          </div>

        </div>
        <div class="row">

          <div id="filter-column" class="col-md-3">
            <app-request-filter #filterOptions
                                [errorStatuses]="errorStatuses"
                                [initialStartDate]="initialStartDate"
                                [initialEndDate]="initialEndDate"
                                (toggleLoad)="toggleGridLoadStatus($event)"
                                (refreshGrid)="getTransactionData($event)"
                                (toggleFilterError)="toggleFilterErrorState($event)">
            </app-request-filter>
          </div>

          <div id="grid-column" class="col-md-9">
            <ag-grid-angular [hidden]="showTableError" id="masterViewGrid"
                             style="width: 100%; height: 700px;"
                             class="ag-theme-balham mdo-standard-grid"
                             [rowData]="rowData"
                             [columnDefs]="columnDefs"
                             (gridReady)="onGridReady($event)"
                             [frameworkComponents]="frameworkComponents"
                             [gridOptions]="gridOptions"
                             [rowSelection]="rowSelection"
                             [rowMultiSelectWithClick]="rowMultiSelectWithClick"
                             [pagination]="pagination"
                             [paginationAutoPageSize]="paginationAutoPageSize"
                             (selectionChanged)="onSelectionChanged($event)">

            </ag-grid-angular>
            <p id="table-retrieval-error-alert" class="alert mdo-alert-danger" [hidden]="!showTableError">There was an error with retrieving the transaction data.  Click the "Get Matching Queue Data" button to retry.</p>
          </div>

        </div>
      </div>

    </mat-tab>

    <mat-tab id="job-queue-tab" label="Job Queue">
      <app-job-queue [initialStartDate]="initialStartDate"
                     [initialEndDate]="initialEndDate"
                     (addJobTab)="addTab($event)">
      </app-job-queue>
    </mat-tab>

    <mat-tab id="staging-file-queue-tab" label="Staging Queue">
      <app-staging-file-queue
                     [initialStartDate]="initialStartDate"
                     [initialEndDate]="initialEndDate"
                     (addStagingFileTab)="addTab($event)">
      </app-staging-file-queue>
    </mat-tab>

    <mat-tab id="job-report-tab" label="Reports">
      <app-reports></app-reports>
    </mat-tab>

    <mat-tab *ngFor="let detailTab of detailTabs; let index = index">

      <ng-template mat-tab-label>
        {{detailTab.bundleLabel}}: {{detailTab.bundleValue}} &nbsp;
        <a class="close-tab-link" href="" (click)="closeTab(index)">
          <i class="fa fa-times-circle close-tab-icon" title="Close"></i>
        </a>
      </ng-template>
      <div *ngIf="matchesTabContext('Transaction',detailTab.context)">
        <app-detail-tab [idBundle]="detailTab" (updateGrid)="refreshFromCache()"></app-detail-tab>
      </div>
      <div *ngIf="matchesTabContext('Job',detailTab.context)">
        <app-job-detail-tab [idBundle]="detailTab" (updateGrid)="refreshFromCache()"></app-job-detail-tab>
      </div>
      <div *ngIf="matchesTabContext('EntityLog',detailTab.context)">
        <app-entity-log-detail-tab [idBundle]="detailTab"></app-entity-log-detail-tab>
      </div>
      <div *ngIf="matchesTabContext('StagingFile',detailTab.context)">
        <app-staging-file-detail-tab [idBundle]="detailTab"></app-staging-file-detail-tab>
      </div>

    </mat-tab>

  </mat-tab-group>

  <app-update-transactions #updateModal (triggerQueueUpdate)="refreshFromCache()"></app-update-transactions>
</div>
