//Angular Imports 
import { Component, ElementRef, HostBinding, Renderer2, ViewChild } from '@angular/core';

//Third Party Imports 
import { ColDef, GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

//Internal Imports 


import 'rxjs';
import { AppInsightsService, EnvironmentService, DateTimeUtcPipe, SharedService, ApplicationInsightsBaseComponent, CPMElement, DateFilterComponent } from '../../../framework';
import { EXECUTION_ENGINE_LIST } from '../../../report/configs';
import { MGDReportsService } from '../services/MGDReports.service';
import { isNull } from 'lodash';


interface PremiumAudit {
  CreatedOn?: Date;
  IssuedOn?: Date;
  TransactionId?: string;
  FormId?: number;
  PremiumName?: string;
  FormPremiumAmount?: string;
  Change_Premium_From_e2?: string;
  DocumentNumber?: string;
  Lob?: string;
  CreatedBy?: string;
  ActionType?: string;
  PolicyNumber?: string;
  DealNumber?: string;
  ReviewedBy?: string;
  ReviewedOn?: Date;
}
/**
 * Forms Component
*/
@Component({
  selector: 'app-mgd-reports',
  templateUrl: './MGDReports.component.html',
  styleUrls: ['./MGDReports.component.scss'],
  providers: [MGDReportsService]
})

export class MGDReportsComponent extends ApplicationInsightsBaseComponent {

  @HostBinding('style.width') width: string;
  @ViewChild('audit', { static: true }) audit: ElementRef;

  public premiumGridOptions: GridOptions;

  initialRowDataLoad$;

  reportNames: string[] = ['Production', 'Errors', 'Audit'];
  selectedReport: string = "Production";
  rowData: PremiumAudit[];
  columnDefs: ColDef[];


  /** 
   * Constructor
   * @ignore
  */
  constructor(private _appInsightsService: AppInsightsService,
    private toastr: ToastrService,
    private _modal: NgbModal,
    private renderer: Renderer2,
    private reportsService: MGDReportsService,
    private sharedService: SharedService,
    private dateTimePipe: DateTimeUtcPipe,
    private environmentService: EnvironmentService) {
    super(_appInsightsService);
    this.width = "100%";
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
  */
  ngOnInit(): void {
    this.rowData = [
      {
        CreatedOn: new Date('2024-06-19T17:46:30.220Z'),
        IssuedOn: new Date('2024-01-05T20:33:36.100Z'),
        TransactionId: 'EAE248A9-8787-40EF-8677-4A9D7FA84825',
        FormId: 6605141,
        PremiumName: 'AdditionalPremiumAmount',
        FormPremiumAmount: '75',
        Change_Premium_From_e2: '1575',
        DocumentNumber: 'MAUB 1503 01 15',
        Lob: 'Wholesale_Environmental',
        CreatedBy: 'tara.taylor',
        ActionType: 'Issued',
        PolicyNumber: 'MPXS3006602',
        DealNumber: '5356633',
        ReviewedBy: 'Faniz Eliasvand',
        ReviewedOn: new Date('2024-08-14T11:51:40Z')
      },
      {
        CreatedOn: new Date('2024-06-19T17:48:08.637Z'),
        IssuedOn: new Date('2024-01-08T21:55:06.180Z'),
        TransactionId: '494BE0AC-534D-4A68-BA33-86A37F8422AD',
        FormId: 6614547,
        PremiumName: 'AdditionalPremiumAmount',
        FormPremiumAmount: '125',
        Change_Premium_From_e2: '2625',
        DocumentNumber: 'MAUB 1503 01 15',
        Lob: 'Wholesale_Environmental',
        CreatedBy: 'tara.taylor',
        ActionType: 'Issued',
        PolicyNumber: 'MPXS3006618',
        DealNumber: '5360753',
        ReviewedBy: 'Gwendolyn James',
        ReviewedOn: new Date('2024-08-14T11:51:40Z')
      },
      {
        CreatedOn: new Date('2024-02-19T19:26:04.000Z'),
        IssuedOn: new Date('2024-02-19T19:26:04.000Z'),
        TransactionId: '88EDB502-34D9-4B2A-A5B3-84BFE65C57DF',
        FormId: 6808321,
        PremiumName: 'AdditionalPremiumAmount',
        FormPremiumAmount: '2259',
        Change_Premium_From_e2: '16259',
        DocumentNumber: 'MAUB 1504 01 15',
        Lob: 'Wholesale_Environmental',
        CreatedBy: 'Alexi.Kicklighter',
        ActionType: 'Issued',
        PolicyNumber: 'MPXS3006617',
        DealNumber: '5371676',
        ReviewedBy: 'Octavia Rothburgers',
        ReviewedOn: new Date('2024-08-14T11:51:40Z')
      },
      {
        CreatedOn: new Date('2024-03-22T15:58:58.047Z'),
        IssuedOn: new Date('2024-03-22T15:58:58.047Z'),
        TransactionId: '356AC9B0-8A0F-4E04-9B93-C7B7BB67C137',
        FormId: 6959734,
        PremiumName: 'AdditionalPremiumAmount',
        FormPremiumAmount: '2121',
        Change_Premium_From_e2: '15421',
        DocumentNumber: 'MAUB 1503 01 15',
        Lob: 'Wholesale_Environmental',
        CreatedBy: 'joseph.pappanastos',
        ActionType: 'Issued',
        PolicyNumber: 'MRXS3000310',
        DealNumber: '5371705',
        ReviewedBy: null,
        ReviewedOn: null
      },
      {
        CreatedOn: new Date('2024-01-22T18:36:35.083Z'),
        IssuedOn: new Date('2024-01-22T18:36:35.083Z'),
        TransactionId: '079A7561-5F2E-4E6D-93AE-6C6C97739994',
        FormId: 6671827,
        PremiumName: 'AdditionalPremiumAmount',
        FormPremiumAmount: '600',
        Change_Premium_From_e2: '6400',
        DocumentNumber: 'MAUB 1503 01 15',
        Lob: 'Wholesale_Environmental',
        CreatedBy: 'Joseph.Fox',
        ActionType: 'Issued',
        PolicyNumber: 'MPXS3006651',
        DealNumber: '5371725',
        ReviewedBy: null,
        ReviewedOn: null
      },
      {
        CreatedOn: new Date('2024-06-14T22:43:46.413Z'),
        IssuedOn: new Date('2024-06-14T22:43:46.413Z'),
        TransactionId: '6DD8B873-EAA4-4304-A1B0-47ADF7CF0CD3',
        FormId: 7361365,
        PremiumName: 'AdditionalPremiumAmount',
        FormPremiumAmount: '188285',
        Change_Premium_From_e2: '1760762',
        DocumentNumber: 'MAUB 1504 01 15',
        Lob: 'Wholesale_Environmental',
        CreatedBy: 'sarah.heisler',
        ActionType: 'Issued',
        PolicyNumber: 'MKLV5EUE103277',
        DealNumber: '5375374',
        ReviewedBy: null,
        ReviewedOn: null
      },
      {
        CreatedOn: new Date('2024-02-20T21:44:27.863Z'),
        IssuedOn: new Date('2024-02-20T21:44:27.863Z'),
        TransactionId: 'D186D591-1758-4E75-9D4B-D3EB0F8D0F79',
        FormId: 6815483,
        PremiumName: 'AdditionalPremiumAmount',
        FormPremiumAmount: '750',
        Change_Premium_From_e2: '4650',
        DocumentNumber: 'MAUB 1503 01 15',
        Lob: 'Wholesale_Environmental',
        CreatedBy: 'michelle.spicer',
        ActionType: 'Issued',
        PolicyNumber: 'MPXS3006711',
        DealNumber: '5395896',
        ReviewedBy: null,
        ReviewedOn: null
      },
      {
        CreatedOn: new Date('2024-03-01T21:18:33.483Z'),
        IssuedOn: new Date('2024-03-01T21:18:33.483Z'),
        TransactionId: 'F7394F49-F590-45ED-A6E0-CD61CBDA6E21',
        FormId: 6868980,
        PremiumName: 'AdditionalPremiumAmount',
        FormPremiumAmount: '75',
        Change_Premium_From_e2: '-3',
        DocumentNumber: 'MAUB 1503 01 15',
        Lob: 'Wholesale_Environmental',
        CreatedBy: 'Hannah.Ryan',
        ActionType: 'Issued',
        PolicyNumber: 'MPXS3006783',
        DealNumber: '5412131',
        ReviewedBy: null,
        ReviewedOn: null
      },
      {
        CreatedOn: new Date('2024-03-12T18:59:24.827Z'),
        IssuedOn: new Date('2024-03-12T18:59:24.827Z'),
        TransactionId: '647B05A0-1ABB-4DC8-B5AD-F8C5A2E311BA',
        FormId: 6911681,
        PremiumName: 'AdditionalPremiumAmount',
        FormPremiumAmount: '75',
        Change_Premium_From_e2: '3825',
        DocumentNumber: 'MAUB 1504 01 15',
        Lob: 'Wholesale_Environmental',
        CreatedBy: 'Alexi.Kicklighter',
        ActionType: 'Issued',
        PolicyNumber: 'MPXS3006796',
        DealNumber: '5415541',
        ReviewedBy: null,
        ReviewedOn: null
      },
      {
        CreatedOn: new Date('2024-05-30T20:32:30.157Z'),
        IssuedOn: new Date('2024-05-30T20:32:30.157Z'),
        TransactionId: 'A381FDC0-2FA9-4D85-8978-7237195B912C',
        FormId: 7286811,
        PremiumName: 'AdditionalPremiumAmount',
        FormPremiumAmount: '6607',
        Change_Premium_From_e2: '6542',
        DocumentNumber: 'MAUB 1503 01 15',
        Lob: 'Wholesale_Environmental',
        CreatedBy: 'jen.hildebrandt',
        ActionType: 'Issued',
        PolicyNumber: 'MKLM1EUL102339',
        DealNumber: '5418567',
        ReviewedBy: null,
        ReviewedOn: null
      },
      {
        CreatedOn: new Date('2024-03-14T14:22:11.717Z'),
        IssuedOn: new Date('2024-03-14T14:22:11.717Z'),
        TransactionId: 'E9020847-9C16-4875-857C-EA2963D7C5AE',
        FormId: 6921657,
        PremiumName: 'ReturnPremiumAmount',
        FormPremiumAmount: '150',
        Change_Premium_From_e2: '2850',
        DocumentNumber: 'MAUB 1503 01 15',
        Lob: 'Wholesale_Environmental',
        CreatedBy: 'Maja.Pietrzak',
        ActionType: 'Issued',
        PolicyNumber: 'MPXS3006812',
        DealNumber: '5421001',
        ReviewedBy: null,
        ReviewedOn: null
      }
    ];

    this.configureGrid();
    
  }

  /**
   * NgOnDestroy
   * @ignore
  */
  ngOnDestroy(): void {

  }

  changeReport(event) {
    console.log(event);
    this.selectedReport = event.options[0]._value;
  }

  private configureGrid(): void {
    this.initialRowDataLoad$ = [];
    this.premiumGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: 'autoHeight',
      columnDefs: this.createColDefs(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      enableColResize: true,
      paginationPageSize: 20,
      enableBrowserTooltips:true,
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent
      },
      onGridReady: () => {
        this.premiumGridOptions.api.setRowData([]);
        this.premiumGridOptions.api.sizeColumnsToFit();
        // this.gridOptions.api.softRefreshView();
      },
      onRowClicked: (event) => {
      },
      onFilterChanged: (event) => {
        //Api call to get the filter data
        //console.log(event);

      },
      onSortChanged: (event) => {

      },
      onFilterModified: function (event) {
        //console.log(event);
      }
    };

  }

  createColDefs(): any[] {
    return this.columnDefs = [
        { field: "ReviewedBy", headerName: "Reviewed By", width: 155, tooltipField: "ReviewedBy" },
        { field: "ReviewedOn", headerName: "Reviewed On", width: 125, tooltipField: "ReviewedOn" },
        { field: "DealNumber", headerName: "Deal Number", width: 120, tooltipField: "DealNumber" },
        { field: "PolicyNumber", headerName: "Policy Number", width: 156, tooltipField: "PolicyNumber" },
        { field: "IssuedOn", headerName: "Issued On", tooltipField: "IssuedOn" },
        { field: "CreatedBy", headerName: "Created By", width: 150, tooltipField: "CreatedBy" },
        { field: "PremiumName", headerName: "Premium Name", tooltipField: "PremiumName" },
        { field: "Change_Premium_From_e2", headerName: "E2 Premium", width: 135, tooltipField: "Change_Premium_From_e2" },
        { field: "FormPremiumAmount", headerName: "Form Premium", width: 134, tooltipField: "FormPremiumAmount" },
        { field: "DocumentNumber", headerName: "Form Number", width: 160, tooltipField: "DocumentNumber" },
        { field: "ActionType", headerName: "Action Type", width: 125, tooltipField: "ActionType" },
      ];
  }



}
