import { JobHealth } from './job-health';
import { formatDate } from "@angular/common";

export class PartnerHealth {
  partner: string;
  partnerId: string;
  jobs: JobHealth[] = [];
  latestJob: JobHealth;
  lastProblemJob: JobHealth;
  oldestJob: JobHealth;
  latestJobDate: string = 'NA';
  lastProblemJobDate: string = 'NA';
  jobIdArray: string[] = [];

  constructor(objectData: any) {
    this.partner = objectData.partner || "";
    this.partnerId = objectData.partnerId || "";
    objectData.jobs.forEach(job => {
      // The first job is excluded from the main array
      if (!this.latestJob) {
        this.latestJob = new JobHealth(job);
        this.latestJobDate = this.formatJobDate(this.latestJob.jobDate);
        this.jobIdArray.push(this.latestJob.jobId);
      } else {
        this.jobs.push(new JobHealth(job));
        this.jobIdArray.push(this.jobs[(this.jobs.length - 1)].jobId);
      }
    });
    this.setLastProblemJobDate();
    this.oldestJob = this.jobs[this.jobs.length - 1];   
  }

  setLastProblemJobDate(): void {
    // "In progress" jobs should be ignored/exempted
    // Check latest job first
    if (!this.latestJob.inProgress() && this.latestJob.jobHealthStatus != "success") {
      this.lastProblemJob = this.latestJob;
      this.lastProblemJobDate = this.formatJobDate(this.latestJob.jobDate);
    } else {
      for (let j = 0; j < this.jobs.length; j++) {
        if (!this.jobs[j].inProgress() && this.jobs[j].jobHealthStatus != "success") {
          this.lastProblemJob = this.jobs[j];
          this.lastProblemJobDate = this.formatJobDate(this.jobs[j].jobDate);
          break;
        }
      }
    }
    
  }

  formatJobDate(dateValue: any): string {
    if (!dateValue || isNaN(Date.parse(dateValue))) {
      return 'NA';
    }
    let date = new Date(Date.parse(dateValue));
    return formatDate(date, 'M/d/yyyy', 'en-US');
  }
}
