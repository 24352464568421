
export class TestCase {
  public Id: string;
  public DocumentType: string;
  public IsActive: boolean;
  public Name: string;
  public Description: string;
  public TestGroups: string[] = [];  // TestGroup Id's
  public LOBs: string[] = [];
  public PolicyEffectiveDate: number;
  public WritingCompany: string;
  public State: string;
  public Coverages: string[] = [];
  public CPM: string;
  public ExpectedResults: TestExpectedResults[];

  public FastKey: string;
  public CreationDate: number;
  public CreatedByName: string;
  public ModifiedDate: number;
  public ModifiedByName: string;
  public IsSelected: boolean; //used internally for sorting
  public CreatedOn: Date; //used internally for CSV report parsing only
}

export class TestExpectedResults {
  public QuoteOption: string;
  public LOB: string;
  public OptionalForms: string[] = [];
  public MandatoryForms: string[] = [];
}
