

<div class="modal-content" style="width:100%; height:935px;">
  <div class="modal-header" style="height: 60px; align-items: center;">
    <h4 class="modal-title">GhostDraft Extension</h4>
    <button type="button" class="btn secondary_btn" (click)="activeModal.close()">Close</button>
  </div>
  <iframe id="ext_iframe" title="Policy Issuance"
          style="width:100%; height:100%;">
  </iframe>
  <div class="modal-footer" style="height:10px; align-content:center;">
  </div>
</div>


