//Angular Imports 
import { Component, HostBinding, Input, EventEmitter, Output } from '@angular/core';

//Third Party Imports 
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

//Internal Imports 
import {
  ApplicationInsightsBaseComponent, AppInsightsService, DateFilterComponent, BooleanFilterComponent,
  DateTimePipe, SharedService, EnvironmentService, AuthorizationService
} from '../../../framework';
import { AdminForm, RuleSetup } from '../../models';
//import { ExecutionService, TestingService } from '../../services';


import 'rxjs';
import { saveAs } from 'file-saver';

//import { RuleEngineResult, SingleFormResult, RuleEngineLog, TestExecution, TestGroup, TestRun } from '../../models';



/**
 * Forms Component
*/
@Component({
  selector: 'app-form-page-rules-tab',
  templateUrl: './form-page-rules-tab.component.html',
  styleUrls: ['./form-page-rules-tab.component.scss']
})

export class FormPageRulesTabComponent extends ApplicationInsightsBaseComponent {

  @HostBinding('style.width') width: string;

  @Input() currentForm: AdminForm;

  @Output() saveForm = new EventEmitter<AdminForm>();

  @Input() lobList: string[];

  public currentRuleSetup: RuleSetup;


  /** 
   * Constructor
   * @ignore
  */
  constructor(private _appInsightsService: AppInsightsService,
              private authService: AuthorizationService) {
    super(_appInsightsService);
    this.width = "100%";
  }




  ngOnInit(): void {
    // this.currentRuleSetup = this.currentForm.RuleSetups.find(x => x.Name === "Default");
    
  }

  ngOnDestroy(): void {

  }

  ngOnChanges(): void {
    this.currentRuleSetup = this.currentForm.RuleSetups[0];
    console.log("Rules-Tab: ngOnChanges: FormNumber = " + this.currentForm.InternalFormNumber);
  }

  // Event raised from form-page-rule-setup-list, which passes the concatinated list of LOBs as the name
  onRuleSetupChanged(event: RuleSetup) {
    this.currentRuleSetup = event;
    console.log(this.currentRuleSetup);
  }

  onSaveRuleSetup() {
    console.log(this.currentRuleSetup);
    this.currentForm.LastModified = this.currentRuleSetup.Name;
    this.saveForm.emit(this.currentForm);
  }
  

}
