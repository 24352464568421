import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Clipboard } from "@angular/cdk/clipboard";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ColDef } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { prettyPrintJson } from "pretty-print-json";
import { isNullOrUndefined } from "util";
import { QueueLog } from "../../models/queueLog";
import { QueueService } from "../../services/queue.service";

// TODO: implement real behavior and restore CPM/CCM view and clipboard functionality
@Component({
  selector: "app-queueDetails",
  templateUrl: "./queueDetails.component.html",
  styleUrls: ["./queueDetails.component.scss"],
})
export class QueueDetailComponent implements OnInit {
  @Input() log: QueueLog;

  isLoading: boolean = true;
  isLoadingModel: boolean = false;
  modelLoadError: boolean = false;
  isReady: boolean = false;
  columnDefs: ColDef[];
  pagination: boolean = true;
  paginationAutoPageSize: boolean = true;
  prettyModels: any[] = [];
  modelsAsJSON: string[] = [];
  jsonString: any;
  jsonStringcopy: any;
  jsonLogString: any;

  constructor(
    public activeModal: NgbActiveModal,
    private _modal: NgbModal,
    private toastr: ToastrService,
    private queueService: QueueService,
    private modalService: NgbModal,
    private clipboard: Clipboard
  ) {}

  ngOnInit(): void {
    this.queueService
      .getQueueMessage(
        this.log.Environment,
        this.log.DealNumber,
        this.log.CallingSystem
      )
      .subscribe((res) => {
        let message: any;
        if (!isNullOrUndefined(res)) {
          this.jsonStringcopy = res.Data;

          message = res;
        } else {
          message = "No message data available";
        }
        this.jsonString = message;
        // JSON
        if (!library) var library: any = {};

        library.json = {
          replacer: function (match, pIndent, pKey, pVal, pEnd) {
            var key = "<span class=json-key>";
            var val = "<span class=json-value>";
            var str = "<span class=json-string>";
            var r = pIndent || "";
            if (pKey) r = r + key + pKey.replace(/[": ]/g, "") + "</span>: ";
            if (pVal) r = r + (pVal[0] == '"' ? str : val) + pVal + "</span>";
            return r + (pEnd || "");
          },
          prettyPrint: function (obj) {
            var jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/gm;
            return JSON.stringify(obj, null, 3)
              .replace(/&/g, "&amp;")
              .replace(/\\"/g, "&quot;")
              .replace(/</g, "&lt;")
              .replace(/>/g, "&gt;")
              .replace(jsonLine, library.json.replacer);
          },
        };
        //this.jsonStringcopy = { ...message };
        this.jsonString = {...JSON.parse(this.jsonStringcopy)};
        // this.jsonString = library.json.prettyPrint(this.jsonString);
        // document.getElementById("JSONtestLogCPM").innerHTML = this.jsonString;
        document.getElementById("JSONtestLogCPM").innerHTML = library.json.prettyPrint(this.jsonString);

        console.log(this.jsonString);
      });
    var logString = JSON.stringify(this.log);
    this.jsonLogString = { ...JSON.parse(logString) };
  }

  displayModelData(modelData: any) {
    this.prettyModels = [];
    this.modelsAsJSON = [];
    modelData.forEach((model) => {
      this.prettyModels.push(prettyPrintJson.toHtml(model));
      this.modelsAsJSON.push(JSON.stringify(model));
    });
  }

  resetStatus(transactionId: string): void {
    console.log("transactionId to reset: ", transactionId);
  }

  copyCPM() {
    this.clipboard.copy(this.jsonStringcopy);
  }
}
