<div class="modal-header">
  <h4 class="modal-title">Product Info</h4>
</div>

<div class="modal-body col-12">
  <!-- LOBs -->
  <div class="row">
    <div id="lob-grid-container" class="col-12" style="padding-bottom: 10px">
      <div style="padding-bottom: 5px">
        <b>View LOBs and Associated Products</b>
      </div>
      <ag-grid-angular
        class="ag-theme-balham fast_panel_table"
        [gridOptions]="lobGridOptions"
        style="height: 200px; width: 100% !important"
      >
      </ag-grid-angular>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="mat_btn_hov_act"
    mat-raised-button
    (click)="activeModal.close()"
  >
    Cancel
  </button>
</div>
