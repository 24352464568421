<div class="component-container" style="height: calc(100vh - 64px);">
  <div class="component-heading-container">
    <div class="col-md-12">
      <div class="row" style="padding-left: 10px">
        <h3 class="component-heading-text">PDF Comparison</h3>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="dropzone"
             fileDragDrop
             (filesChangeEmiter)="onFileChangeA($event)">
          <div class="text-wrapper">
            <div class="centered">
              <input matInput
                     class="file-input"
                     type="file"
                     name="Document A"
                     id="documentbtnA"
                     #uploadA
                     accept=".pdf"
                     (change)="onFileChangeA($event)"
                     multiple />
              <button type="button"
                      _ngcontent-fca-c383=""
                      mat-stroked-button=""
                      class="textLink btn-teal"
                      (click)="uploadA.click()">
                <mat-icon style="height: 25px; padding-top: 2px"
                          class="tw-icon-3xl"
                          fontSet="fa"
                          fontIcon="fa-file-pdf"></mat-icon>
                Select your file
              </button>
              <label for="file"><span class="DropFileHere">or Drop it here!</span></label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="dropzone2"
             fileDragDrop
             (filesChangeEmiter)="onFileChangeB($event)">
          <div class="text-wrapper">
            <div class="centered">
              <input matInput
                     type="file"
                     class="file-input"
                     name="Document B"
                     id="documentbtnB"
                     #uploadB
                     title=""
                     accept=".pdf"
                     (change)="onFileChangeB($event)"
                     multiple />
              <button type="button"
                      _ngcontent-fca-c383=""
                      mat-stroked-button=""
                      class="btn-teal textLink"
                      (click)="uploadB.click()">
                <mat-icon style="height: 25px; padding-top: 2px"
                          class="tw-icon-3xl"
                          fontSet="fa"
                          fontIcon="fa-file-pdf"></mat-icon>

                Select your file
              </button>
              <label for="file"><span class="DropFileHere">or Drop it here!</span></label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-5"></div>
      <div class="col-md-2">
        <div class="funcButton" id="compareButton" type="button">
          <button type="button"
                  _ngcontent-jvt-c197=""
                  mat-stroked-button=""
                  class="btn-teal mat-focus-indicator mat-raised-button mat-button-base"
                  (click)="getPdfFile()">
            <mat-icon class="tw-flex-none"
                      svgIcon="mat:compare_arrows"></mat-icon>
            Compare
          </button>
        </div>
      </div>
      <div class="col-md-5"></div>
    </div>

    <div class="results" *ngIf="this.datalocalURL != null" >
      <label class="result">Result</label>
      <iframe class="heatmap" [src]="datalocalURL" type="application/pdf"></iframe>
    </div>
  </div>
</div>
