//Angular Imports
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";

//Third Party Imports
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { AgGridModule } from "ag-grid-angular";

//Internal Imports
import {
  FrameworkModule,
  DateFilterComponent,
  BooleanFilterComponent,
} from "../framework";
import { CPMElementsRoutingModule } from "./cpmElements.routing";
import { CPMService } from "./services";
import {
  CPMElementsComponent,
  AddCPMElementComponent,
  AddCPMFieldComponent,
} from "./components";
import { MyDatePickerModule } from "mydatepicker";
import { MatButtonModule } from "@angular/material/button";

/**
  Rules Module
*/
@NgModule({
  declarations: [
    AddCPMFieldComponent,
    AddCPMElementComponent,
    CPMElementsComponent,
  ],
  imports: [
    MyDatePickerModule,
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    MatButtonModule,
    FrameworkModule,
    CPMElementsRoutingModule,
    AgGridModule.withComponents([DateFilterComponent, BooleanFilterComponent]),
  ],
  providers: [CPMService],
})
export class CPMElementsModule {}
