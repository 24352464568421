//Angular Imports 
import { Component, HostBinding, Input, Output, EventEmitter } from '@angular/core';

//Third Party Imports 
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

//Internal Imports 
import {
  ApplicationInsightsBaseComponent, AppInsightsService, DateFilterComponent, BooleanFilterComponent,
  DateTimePipe, SharedService, EnvironmentService
} from '../../../framework';
import { CPMElement, CPMField } from "../../../framework/models";

import { ElementExpression } from '../../models';
import { FormPageService } from '../../services';


import 'rxjs';
import { saveAs } from 'file-saver';

//import { RuleEngineResult, SingleFormResult, RuleEngineLog, TestExecution, TestGroup, TestRun } from '../../models';

declare var Blockly: any;
import { blocklyToolbox } from '../../configs';
//import * as Blockly from 'blockly';

/**
 * Forms Component
*/
@Component({
  selector: 'app-form-page-expression',
  templateUrl: './form-page-expression.component.html',
  styleUrls: ['./form-page-expression.component.scss']
})

export class FormPageExpressionComponent extends ApplicationInsightsBaseComponent {

  @HostBinding('style.width') width: string;

  @Input() public currentExpression: ElementExpression;

  @Output() deleteExpression = new EventEmitter<ElementExpression>();

  @Output() saveExpression = new EventEmitter<ElementExpression>();

  public elementList: CPMElement[];
  public selectedElementName: string;
  public selectedElement: CPMElement;

  public operatorList = [{ Name: "in" },  { Name: "=" }, { Name: ">" }, { Name: ">=" }, { Name: "<" }, { Name: "<=" }, {Name: "<>"}, {Name: "CountEqual"},{Name: "CountNotEqual"}, {Name: "all except"}];
  public selectedOperator: string;

  public valueList: any[];
  public includedList: any[];
  public mandatoryList: any[];
  public isNegativeCondition: boolean = false;
  //public selectedValueList any[];


  /** 
   * Constructor
   * @ignore
  */
  constructor(private _appInsightsService: AppInsightsService, private sharedService: SharedService, private formPageService: FormPageService) {
    super(_appInsightsService);
    this.width = "100%";

    //this.includedList = [];
    //this.excludedList = [];
  }

  ngOnInit(): void {

    this.sharedService.getAllCpmElements().subscribe(el => {
      this.elementList = el;

      
      this.onSelectElement();
    });


   // if (this.currentExpression.IncludedList.find(x => x === "ZZ")) {
      //this.selectedOperator = "all except";
    //} else {
      this.selectedOperator = this.currentExpression.Operator;
    //}

    this.includedList = _.cloneDeep(this.currentExpression.IncludedList);
    this.mandatoryList = _.cloneDeep(this.currentExpression.MandatoryList);
  }

  ngAfterViewInit(): void {
    
  }

  ngOnDestroy(): void {

  }

  ngOnChanges(): void {
   
  }

  populateElementValueList(): void {
    //this.sharedService.getMasterDataFromCpmElement(this.selectedElement).subscribe(el => {
    //  this.valueList = [];
    //  for (var i = 0; i < el.CPMElementList.length; i++) {
    //    this.valueList.push({ Name: el.CPMElementList[i].Name + ' - ' + el.CPMElementList[i].Description, Code: el.CPMElementList[i].Name})
    //  }
    //});
  }

  onSelectElement(elementName:string = ""): void {

    if(elementName == ""){
        if (this.currentExpression.ElementName === "WritingCompany") {
          elementName = "Writing Company";
        } else {
          elementName = this.currentExpression.ElementName;
        }
      }

    this.selectedElement = this.elementList.find(x => x.CPMDataElementName === elementName);
    this.selectedElementName = this.selectedElement.CPMDataElementName;


    this.valueList = [];
    if(this.selectedElement.CPMFieldValues != null){
      for (var i = 0; i < this.selectedElement.CPMFieldValues.length; i++) {
        let cpmField: CPMField = this.selectedElement.CPMFieldValues[i];
        this.valueList.push({ Name: cpmField.Code + ' - ' + cpmField.Description, Code: cpmField.Code })
      }
    }
  }

  onElementChanged(event): void {
    this.onSelectElement(event.CPMDataElementName);

    this.includedList = [];
    this.mandatoryList = [];
    this.currentExpression.IncludedList = [];
    this.currentExpression.MandatoryList = [];
    this.currentExpression.ElementName = this.selectedElementName;
    this.saveExpression.emit(this.currentExpression);
  }

  onIncludeValues(event): void {
    this.currentExpression.IncludedList = this.includedList;
    this.saveExpression.emit(this.currentExpression);
  }

  onMandatoryValues(event): void {
   this.currentExpression.MandatoryList = this.mandatoryList;
   this.saveExpression.emit(this.currentExpression);
  }

  onSelectOperator(event): void {
    if (['in', '=', '<>', 'all except'].indexOf(this.currentExpression.Operator) != -1 && [">", ">=", "<", "<=", "CountEqual", "CountNotEqual"].indexOf(this.selectedOperator) != -1) {
      console.log('hmm');
      this.includedList = [];
      this.mandatoryList = [];
      this.currentExpression.IncludedList = [];
      this.currentExpression.MandatoryList = [];
      if (['<>', 'all except'].indexOf(this.currentExpression.Operator) != -1) {
        this.isNegativeCondition = true;
      }
      else {
        this.isNegativeCondition = false;
      }
    }
    else if (['in', '=', '<>', 'all except'].indexOf(this.selectedOperator) != -1 && [">", ">=", "<", "<=", "CountEqual", "CountNotEqual"].indexOf(this.currentExpression.Operator) != -1) {
      this.includedList = [];
      this.mandatoryList = [];
      this.currentExpression.IncludedList = [];
      this.currentExpression.MandatoryList = [];
      if (['<>', 'all except'].indexOf(this.selectedOperator) != -1) {
        this.isNegativeCondition = true;
      }
      else {
        this.isNegativeCondition = false;
      }
    }
    this.currentExpression.Operator = this.selectedOperator;
    this.saveExpression.emit(this.currentExpression);
  }

  onDeleteExpression(): void {
    if (confirm("Delete this expression?")) {
      this.deleteExpression.emit(this.currentExpression);
    }
    
  }

}
