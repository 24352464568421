<div class="component-container">
  <div class="component-heading-container">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-2">
          <h3 class="component-heading-text">Generate Form Report</h3>
        </div>
        <div class="col-md-10">
          <div class="row">
            <div class="btn_wrapper" style="margin-left: auto; margin-right: 0">
              <button
                class="btn primary_btn btnlign"
                type="button"
                (click)="runQuery('Rules')"
                *ngIf="selectedLOB != 'All'"
              >
                Get Report for Deployed Forms
              </button>
              <button
                class="btn primary_btn btnlign"
                type="button"
                (click)="runQuery('Admin')"
              >
                Get Report for Work In Progress Forms
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="component-content-view">
    <div class="container-fluid">
      <div class="col-md-12">
        <label class="label_heading" style="margin-top: 17px">Select A Line Of Business To Begin:</label>
        <div class="selectdiv select_wrapinline"
             style="width: 400px !important; margin-top: 10px; max-width: 100%">
          <label>
            <ng-select id="lineOfBusiness"
                       class="custom-select"
                       [items]="lineOfBusinessList"
                       bindLabel="Description"
                       bindValue="Name"
                       [(ngModel)]="selectedLOB"
                       [clearable]="false">
              <ng-template ng-option-tmp let-item="item">
                <div title="{{ item.Description }}">{{ item.Description }}</div>
              </ng-template>
            </ng-select>
          </label>
        </div>
        <mat-slide-toggle style="float: right" (change)="toggle($event)">
          {{ this.toggleText }}
        </mat-slide-toggle>
        <mat-slide-toggle style="float: right" (change)="toggleExclude($event)">
         'ZZ' Conditions Only
        </mat-slide-toggle>
        <div class="row" style="display: inline-block">
          <div id="execute-rules-content-heading-container" class="col-12">
            <div class="heading-container fast_admin">
              <label class="label_heading">Report Query</label>
            </div>
            <button (click)="addExpression()"
                    class="mat_btn_hov_act"
                    mat-raised-button
                    style="margin-top: 10px">
              Add Condition
            </button>
            <div class="instructions">
              <div class="row" style="position: center">
                <b style="width: 50%; margin-left: 250px">Instructions:</b>
              </div>
              <div class="row">
                <div id="execute-rules-content-heading-container"
                     class="col-12">
                  <p>
                    -Select a Line of Business from the drop-down<br />
                    -Select a CPM Element<br />
                    -Select one or more element values to find matching forms<br />
                    and/or one or more mandatory element values*<br />
                    -To add extra conditions select 'Add Condition'**<br />
                    -Click 'Get Report for Deployed Forms' to find matching<br />
                    deployed forms, or 'Get Report for Work in Progress Forms'
                    to find<br />
                    forms in the Document Attachment Workbench that match
                    conditions<br />
                    <br />
                    *Matching forms will have rules that match any values<br />
                    inside one condition<br />
                    **Matching forms must match on ALL conditions
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 60%" *ngFor="let expression of expressionList">
            <app-report-expression [currentExpression]="expression"
                                   (deleteExpression)="onDeleteExpression($event)"></app-report-expression>
          </div>
        </div>
        <div>
          <b style="color: red" *ngIf="displayError">No forms matched the query, no report was generated</b>
        </div>
      </div>
    </div>
  </div>
</div>
