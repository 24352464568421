<ng-template #updateJobsModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Job(s)</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismissModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="container-fluid" [formGroup]="updateForm">

      <div class="row" *ngIf="updateContext.action == 'requestAction'">
        <div class="col-md-6">
          <mat-form-field id="mdo-update-job-status-field">
            <mat-label>Requested Action:</mat-label>
            <mat-select required formControlName="requestedAction" required>
              <mat-option class="mdo-mat-option" *ngFor="let act of actionOptions" [value]="act.value" [disabled]="act.disabled">{{act.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="showAbortConfirmation">
        <div class="col-md-6 alert confirmation-box">
          <input type="checkbox" formControlName="confirmAction" />&nbsp; I confirm that I want to abort the selected job(s).
        </div>
      </div>

      <div class="row">

        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12">
              <mat-form-field id="update-job-notes-field">
                <mat-label>Note:</mat-label>
                <textarea matInput required formControlName="note"></textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="row" *ngIf="!inProgress">
            <div class="col-md-12">
              <button id="update-job-btn" mat-raised-button class="mdo-standard-button" (click)="performUpdate()">Perform update</button>
            </div>
          </div>
          <div class="row" *ngIf="inProgress">
            <div class="col-md-5">
              <span>Performing update...</span>
            </div>
            <div class="col-md-1">
              <mat-progress-spinner mode="indeterminate" diameter="20"></mat-progress-spinner>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="alert mdo-alert-danger" *ngIf="errors.length">
            <ul>
              <li *ngFor="let err of errors">{{err}}</li>
            </ul>
          </div>
        </div>

      </div>

      <div class="row">
        <div class="col-md-12">
          <h6 id="update-job-grid-label">Selected jobs(s):</h6>
          <p class="alert mdo-alert-danger" *ngIf="hasUpdateFailures">
            {{selectedCount}} sent; {{failedCount}} failed.
            Please see error message column below for details.
          </p>
          <ag-grid-angular id="selectedJobsGrid"
                           style="width: 90%; height:300px;"
                           class="ag-theme-balham mdo-standard-grid"
                           (gridReady)="onGridReady($event)"
                           [rowData]="updateContext.jobs"
                           [columnDefs]="columnDefs"
                           [pagination]="pagination">
          </ag-grid-angular>
        </div>
      </div>

    </div>
  </div>
</ng-template>

