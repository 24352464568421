import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MdoRoutingModule } from './mdo-routing.module';
import { MdoLayoutComponent } from './components/mdo-layout/mdo-layout.component';

import { PopoverService } from '../../@vex/components/popover/popover.service';
import { LayoutModule } from '../../@vex/layout/layout.module';
import { SidenavModule } from '../../@vex/layout/sidenav/sidenav.module';
import { ToolbarModule } from '../../@vex/layout/toolbar/toolbar.module';
import { ConfigPanelModule } from '../../@vex/components/config-panel/config-panel.module';
import { SidebarModule } from '../../@vex/components/sidebar/sidebar.module';
import { QuickpanelModule } from '../../@vex/layout/quickpanel/quickpanel.module';


@NgModule({
 
  declarations: [
    MdoLayoutComponent
  ],
  imports: [
    CommonModule,
    MdoRoutingModule,
    LayoutModule,
    SidenavModule,
    ToolbarModule,
    ConfigPanelModule,
    SidebarModule,
    QuickpanelModule
  ],
  providers: [PopoverService]
})
export class MdoModule { }
