//Angular Imports
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";

//Third Party Imports
import {
  IAfterGuiAttachedParams,
  IDoesFilterPassParams,
  IFilterParams,
  RowNode,
} from "ag-grid-community";
import { IFilterAngularComp } from "ag-grid-angular";

/**
 * Date Filter Component
 */
@Component({
  selector: "app-date-picker-filter",
  template: `
  <div class="ag-filter-body-wrapper">  
    <div class="ag-filter-body" style="padding: 3px; width: 210px; height: 180px; overflow-y: hidden"> 
      <div>
      <label style="font-size: 14px;margin-bottom: 0.3rem !important;">From :</label>       
        <mat-form-field style="width: 100%">
              <input
                matInput
                [ngxMatDatetimePicker]="pickerFrom"
                placeholder="From date & time"
                [(ngModel)]="myDatePickerFrom"
                [min] = "fromMinDate"
                [max] = "myDatePickerTo"
                (dateChange)="onDateChange()"
                readonly
              />
  
              <mat-datepicker-toggle
                matSuffix
                [for]="pickerFrom"
              ></mat-datepicker-toggle>
  
              <ngx-mat-datetime-picker
                #pickerFrom
                [enableMeridian]="true"
              ></ngx-mat-datetime-picker>
          </mat-form-field>
      </div>
      <div>
        <label style="font-size: 14px;margin-bottom: 0.3rem !important;">To :</label>     
        <mat-form-field style="width: 100%">
              <input
                matInput
                [ngxMatDatetimePicker]="pickerTo"
                placeholder="To date & time"
                [(ngModel)]="myDatePickerTo"
                [min]="myDatePickerFrom"
                [max] = "toMaxDate"
                (dateChange)="onDateChange()"
                readonly 
              />
  
              <mat-datepicker-toggle
                matSuffix
                [for]="pickerTo"
              ></mat-datepicker-toggle>
  
              <ngx-mat-datetime-picker
                #pickerTo
                [enableMeridian]="true"
              ></ngx-mat-datetime-picker>
          </mat-form-field> 
      </div>
    </div>
  </div>`,
  styles: ["span {margin:10px}"],
})
export class DatePickerFilterComponent
  implements IFilterAngularComp, OnInit, OnDestroy {
  // Private Variables
  /**
   * Params
   */
  private _params: IFilterParams;

  /**
   * ValueGetter
   */
  private _valueGetter: (rowNode: RowNode) => any;

  // Public Variables
  /**
   * True or False  
   */
  public value: string;

  // Constructor
  /**
   * @ignore
   * */
  constructor(private cd: ChangeDetectorRef) { }

  // Angular Lifecycles
  /**
   * @ignore
   * */
  ngOnInit(): void {

  }

  /**
   * @ignore
   * */
  ngOnDestroy(): void { }

  // Public Methods
  /**
   * AG Init
   * @param params Grid filter params
   */
  public dateValue: { [key: string]: string } = {};

  public myDatePickerFrom: Date = new Date();
  public myDatePickerTo: Date = new Date();
  public fromMinDate: Date = new Date();
  public toMaxDate: Date = new Date();
  public isFilterApplied = false;

  agInit(params: IFilterParams): void {
    this._params = params;
    this._valueGetter = params.valueGetter.apply;
    this.myDatePickerFrom = params['value']['fromDate'];
    this.myDatePickerTo = params['value']['toDate'];
    this.fromMinDate = params['value']['fromMinDate'];
    this.toMaxDate = params['value']['toMaxDate'];
  }

  /**
   * Is Filter Active (Method called by the grid to check if the filter is active)
   * */
  isFilterActive(): boolean {
    if (this.isFilterApplied)
      return true;
  }

  /**
   * Does Filter Pass (Method called by the grid to check if the data passes the filter)
   * @param params Grid Params
   */
  doesFilterPass(params: IDoesFilterPassParams): boolean {
    return true;
  }

  /**
   * Get Model
   * */
  getModel(): any {
    return { 'values': this.dateValue, 'type' : 'dateRange' };
  }

  /**
   * Set Model
   * @param model Model
   */
  setModel(model: any): void {
    this.dateValue = model ? model.values : {};
  }

  onDateChange() {
    this.isFilterApplied = true;
    this.dateValue = { 'from': this.myDatePickerFrom.toISOString(), 'to': this.myDatePickerTo.toISOString() }
    this._params.filterChangedCallback(); 
  }
}
