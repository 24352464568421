//Angular Imports 
import { Component, HostBinding, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common'

//Third Party Imports 
import { GridOptions } from 'ag-grid-community';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';

//Internal Imports 
import {
  ApplicationInsightsBaseComponent, AppInsightsService, SharedService, DateFilterComponent,
  BooleanFilterComponent, DateTimeUtcPipe, AuthorizationService, SelectableItem
} from '../../../framework';
import { ProductsService } from '../../services/products.service';



/**
 * Forms Component
*/
@Component({
  selector: 'add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})

export class AddProductComponent extends ApplicationInsightsBaseComponent {

  //Public Variables
  /**
   * Host binding for width 
  */
  @HostBinding('style.width') width: string;

  @Input() public Action: string;

  @Input() public CurrentLOBs: string[];



  public formsGridOptions: GridOptions;
  lobSelectableItems: SelectableItem[];
  public newProductName: string = "";

  initialRowDataLoad$;

  /** 
   * Constructor
   * @ignore
  */
  constructor(private _appInsightsService: AppInsightsService,
    private toastr: ToastrService,
    private productsService: ProductsService,
    private _modal: NgbModal,
    public activeModal: NgbActiveModal,
    private sharedService: SharedService,
    private authService: AuthorizationService,
    private dateTimePipe: DateTimeUtcPipe) {
    super(_appInsightsService);
    this.width = "100%";
  }

  get FormsWritePermission(): boolean {
    return this.authService.FormsWrite();
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
  */
  ngOnInit(): void {

    this.productsService.getLobSelectableItemList().subscribe(res => {
      let items: SelectableItem[] = _.cloneDeep(res);

      // foreach lob on the form, add it to the lobSelectableItems list, if we are just viewing codes add all LOBs to list
      if (this.Action == 'New') {
        this.lobSelectableItems = items;
      }
      else {
        this.lobSelectableItems = items;
        for (var i = 0; i < this.lobSelectableItems.length; i++) {
          for (var j = 0; j < this.CurrentLOBs.length; j++) {
            if (this.lobSelectableItems[i].VisibleDescription.startsWith(this.CurrentLOBs[j])) {
              this.lobSelectableItems[i].Selected = true;
            }
          }
        }
      }


      // this.lobSelectableItems = items;
      this.sortSelectableItems(this.lobSelectableItems);

      if (this.formsGridOptions.api) {
        this.formsGridOptions.api.setRowData(this.lobSelectableItems);
        this.formsGridOptions.columnApi.autoSizeAllColumns();
      }

    },
      error => { this.toastr.error('Error occurred while fetching LOB list.', 'Form Page'); }
    );

    this.configureGrid();

  }

  private sortSelectableItems(items: SelectableItem[]) {
    items.sort((a, b) => {
      if (a.Selected == b.Selected) {
        if (a.VisibleDescription < b.VisibleDescription) {
          return -1;
        } else {
          return 1;
        }
      }
      else if (a.Selected) {
        return -1;
      }
      else {
        return 1;
      }

    });
  }

  saveSetup(): void {
    
    var selectedItems: string[] = [];
    for (var i = 0; i < this.lobSelectableItems.length; i++) {
      if (this.lobSelectableItems[i].Selected) {
        selectedItems.push(this.lobSelectableItems[i].VisibleDescription);
      }
    }
    if (selectedItems.length == 0) {
      this.toastr.error("Please select at least one LOB", "Validation Error");
    }
    else if ((this.newProductName == "" || this.newProductName == null) && this.Action == "New") {
      this.toastr.error("Please enter a product name", "Validation Error");
    }
    else {
      if (this.Action == "New") {
        this.activeModal.close({ Name: this.newProductName, LOBList: selectedItems });
      }
      else {
        this.activeModal.close({ LOBList: selectedItems });
      }
      
    }
  }

  /**
   * NgDestroy
   * @ignore
  */
  ngOnDestroy(): void {
  }


  

  //Private Methods
  /**
   * configure grid
   */
  private configureGrid(): void {
    this.initialRowDataLoad$ = [];
    this.formsGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: 'autoHeight',
      columnDefs: this.createColumDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      enableColResize: true,
      paginationPageSize: 20,
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent
      },
      onGridReady: () => {
        this.formsGridOptions.api.setRowData([]);
        this.formsGridOptions.api.sizeColumnsToFit();
        // this.gridOptions.api.softRefreshView();
      },
      onRowClicked: (event) => {
        this.onRowSelected(event);
        this.formsGridOptions.api.refreshCells();
      },
      onFilterChanged: (event) => {
        //Api call to get the filter data
        //console.log(event);

      },
      onSortChanged: (event) => {

      },
      onFilterModified: function (event) {
        //console.log(event);
      }
    };

  }

  /**
  * configure forms grid columns
  */
  private createColumDef(): any[] {
    return [
      {
        headerName: '', field: 'Selected', maxWidth: 40, suppressFilter: true, cellRenderer: params => {
          return `<input type='checkbox' ${params.value == true ? 'checked' : ''} />`;
        }
      },
      { headerName: 'LOB', resizable:true, field: 'VisibleDescription', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
    ]

  }

  private onRowSelected(event: any): void {

    if (event.node.selected) {
      if (event.data.Selected) {
        event.data.Selected = false;
      } else {
        event.data.Selected = true;
      }
    }

  }



}
