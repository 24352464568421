//Angular Imports 
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

//Internal Imports
import { PrintOrderComponent, AddPrintOrderComponent } from './components';

/**
  Form Routes
*/
export const routes: Routes = [
  {
    path: '', component: PrintOrderComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})

export class PrintOrderRoutingModule {
}
