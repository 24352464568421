
<div class="modal-header">
  <h4 class="modal-title">Selected Log Error</h4>
</div>

<div class="modal-body col-12">
  <div class="row">
    {{this.errorText}}
  </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn secondary_btn" (click)="activeModal.close()">Close</button>
</div>

