<div class="container-fluid">
  <div class="row align-items-center justify-content-center loading-row" *ngIf="isLoading">
    <div class="col-md-4">
      <div>
        <mat-progress-spinner mode="indeterminate" diameter="60" class="mx-auto">
        </mat-progress-spinner>
      </div>
    </div>
  </div>

  <div class="row align-items-start" *ngIf="!isLoading && !isReady">
    <p id="transaction-load-error-alert" class="col-md-12 alert mdo-alert-danger error-bar">There was an error with retrieving the transaction details.  Click <a class="mdo-alert-danger-link" href="" (click)="retryTransactionDetails(idBundle.transactionId)">here</a> to re-request the details.</p>
  </div>

  <div class="row align-items-start" *ngIf="!isLoading && isReady">
    <div class="col-md-12">
      <br />
      <mat-accordion class="detail-blocks" multi>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header class="mdo-mat-panel-header">
            <mat-panel-title>
              <h5>Transaction Information</h5>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">

            <div class="col-md-3">
              <mat-list>
                <mat-list-item>
                  <p matLine class="detail-label">Type</p>
                  <p matLine>{{details.productType}}</p>
                </mat-list-item>
                <mat-list-item>
                  <p matLine class="detail-label">{{details.productType}} Number</p>
                  <p matLine>{{details.productTypeId}}</p>
                </mat-list-item>
                <mat-list-item>
                  <app-update-transaction-menu id="detail-action-btn"
                                               [singleTransaction]="true"
                                               [selectedTransactionCount]="1"
                                               [context]="'detail'"
                                               (triggerUpdateModal)="openUpdateModal($event)">
                  </app-update-transaction-menu>
                </mat-list-item>
              </mat-list>
            </div>

            <div class="col-md-4">
              <mat-list>
                <mat-list-item>
                  <p matLine class="detail-label">Transaction ID</p>
                  <p matLine>{{details.transactionId}}</p>
                </mat-list-item>
                <mat-list-item>
                  <p matLine class="detail-label">Job ID</p>
                  <p matLine>{{details.jobId}}</p>
                </mat-list-item>
              </mat-list>
            </div>

            <div class="col-md-3">
              <mat-list>
                <mat-list-item>
                  <p matLine class="detail-label">Partner ID</p>
                  <p matLine>{{details.partner}}</p>
                </mat-list-item>
                <mat-list-item>
                  <p matLine class="detail-label">Status</p>
                  <p matLine>{{details.status}}</p>
                </mat-list-item>
              </mat-list>
            </div>

          </div>

        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true" (opened)="resizeGrid('logging')">
          <mat-expansion-panel-header class="mdo-mat-panel-header">
            <mat-panel-title>
              <h5>Log Data</h5>
            </mat-panel-title>
          </mat-expansion-panel-header>

            <ag-grid-angular id="logGrid"
                              enableCellTextSelection="true"
                              style="width: 95%; height: 300px;"
                              class="ag-theme-balham mdo-standard-grid mdo-panel-content-top-margin"
                              [frameworkComponents]="frameworkComponents"
                              [rowData]="details.logging"
                              [columnDefs]="columnDefs"
                              [pagination]="pagination"
                              [paginationAutoPageSize]="paginationAutoPageSize"
                              [gridOptions]="loggingGridOptions">
            </ag-grid-angular>
           
        </mat-expansion-panel>

        <mat-expansion-panel (opened)="resizeGrid('actionHistory')">
          <mat-expansion-panel-header class="mdo-mat-panel-header">
            <mat-panel-title>
              <h5>
                {{details.productType}} History
              </h5>
            </mat-panel-title>
          </mat-expansion-panel-header>

            <ag-grid-angular id="actionHistoryGrid"
                              enableCellTextSelection="true"
                              style="width: 95%; height: 300px;"
                              class="ag-theme-balham mdo-standard-grid mdo-panel-content-top-margin"
                              (gridReady)="onActionHistoryGridReady($event)"
                              [frameworkComponents]="frameworkComponents"
                              [rowData]="details.actionHistory"
                              [columnDefs]="actionColumnDefs"
                              [pagination]="pagination"
                              [paginationAutoPageSize]="paginationAutoPageSize"
                              [gridOptions]="actionHistoryGridOptions">
            </ag-grid-angular>
              
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header class="mdo-mat-panel-header">
            <mat-panel-title>
              <h5>Generated {{details.getModelType()
                }}
              </h5>
            </mat-panel-title>
            <mat-panel-description><h6>{{details.getModelAvailablity()}}</h6></mat-panel-description>
          </mat-expansion-panel-header>

          <div id="model-loading-block">
            <div *ngIf="!details.entityModelAvailable">
              <p>The {{details.getModelType()}} was not generated for this transaction.</p>
            </div>
            <div *ngIf="details.entityModelAvailable">
              <div *ngIf="details.isModelSet()">
                <div *ngFor="let prettyModel of prettyModels; let idx = index">
                  <p>
                    <button [ngClass]="'copy-clipboard-btn'" class="mdo-soft-button" [cdkCopyToClipboard]="modelsAsJSON[idx]">
                      <span>Copy </span><span *ngIf="prettyModels.length > 1">message {{idx+1}}</span><span> to clipboard</span>
                      &nbsp; <i class="fa fa-paste cell-clipboard-icon"></i>
                    </button>
                  </p>

                  <pre [ngClass]="'modelDisplayBlock'" class="model-display" [innerHTML]="prettyModel"></pre>
                </div>
              </div>

              <div *ngIf="!details.isModelSet()">

                <div *ngIf="isLoadingModel" class="row retrieving-row">
                  <div class="col-md-2">
                    <span>Retrieving {{details.getModelType()}}...</span>
                  </div>
                  <div class="col-md-1">
                    <mat-progress-spinner mode="indeterminate" diameter="20"></mat-progress-spinner>
                  </div>
                </div>

                <div *ngIf="!isLoadingModel">
                  <button id="retrieve-model-btn" class="mdo-soft-button" (click)="retrieveModel()">Retrieve {{details.getModelType()}}</button>

                  <p id="model-retrieval-error-alert" class="alert mdo-alert-danger" *ngIf="modelLoadError">There was an error with retrieving the {{details.getModelType()}}.  Click the retrieval button to try again.</p>

                </div>

              </div>
            </div>

          </div>

        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>

<app-update-transactions #updateModal (triggerDetailUpdate)="refreshDetailsFromUpdate($event)"></app-update-transactions>
