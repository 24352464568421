<div class="row">
  <div class="col-md-3">
    <h2 class="dash-item-title">Health Meter</h2>
  </div>
  <div class="col-md-1">
    <div *ngIf="isLoading">
      <mat-progress-spinner mode="indeterminate" diameter="30"></mat-progress-spinner>
    </div>
  </div>
</div>

<div *ngIf="!isLoading">
  <p class="dash-item-age">Last updated: {{lastUpdateDate}} (<a href="" class="mdo-standard-link" (click)="refreshHealthMeter($event)">click to refresh</a>)</p>
</div>

<div *ngIf="showError">
  <p class="alert mdo-alert-danger">Could not load health data, please try again by clicking the refresh link.</p>
</div>

<div *ngIf="!isLoading && healthData.length">


  <table id="healthTable" cellpadding="20" cellspacing="20">
    <thead>
      <tr>
        <th>Partner</th>
        <th>Last Job Run</th>
        <th>Recent Job Health</th>
        <th>Last Problem Job</th>
        <th>History</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let p of healthData">
        <td>{{p.partnerId}} - {{p.partner}}</td>
        <td>
          <div *ngIf="p.latestJob">
            <a href="" (click)="executeJobFilter( $event, p.partnerId, p.latestJob.jobId, p.latestJob.jobDate, p.latestJob.jobHealthStatus)">
              <i [ngClass]="p.latestJob.jobClass"
                 title="{{p.latestJob.completionNote}}">
              </i>
            </a>
            &nbsp;
            {{p.latestJobDate}}
          </div>
        </td>
        <td>
          <span *ngFor="let j of p.jobs" class="job-container">
            <a href="" (click)="executeJobFilter( $event, p.partnerId, j.jobId, j.jobDate, j.jobHealthStatus)">
              <i [ngClass]="j.jobClass"
                 title="{{j.completionNote}}">
              </i>
            </a>
          </span>
        </td>
        <td>
          <div *ngIf="p.lastProblemJob">
            <a href="" (click)="executeJobFilter( $event, p.partnerId, p.lastProblemJob.jobId, p.lastProblemJob.jobDate, p.lastProblemJob.jobHealthStatus)">
              <i [ngClass]="p.lastProblemJob.jobClass"
                 title="{{p.lastProblemJob.completionNote}}">
              </i>
            </a>
            &nbsp;
            {{p.lastProblemJobDate}}
          </div>
          <div *ngIf="!p.lastProblemJob">&nbsp;N/A</div>
        </td>
        <td><a href="" (click)="executePartnerFilter( $event, p.partnerId, p.oldestJob, p.jobIdArray )">View All</a></td>
      </tr>
    </tbody>
  </table>

</div>

