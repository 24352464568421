import { Injectable } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { UserProfileService, EnvironmentService, ApplicationRole } from '../../../../framework';


@Injectable()
export class ReportService {

  constructor(private environmentService: EnvironmentService, private domSanitizer: DomSanitizer) { }

  getCurrentEnvironment(): string {
    if (!this.isNonProdEnvironment()) {  
      return "PROD";
    }
    return this.environmentService.environmentDetails.Environment;
  }

  isDevelopmentEnvironment(): boolean {
    let env = this.environmentService.environmentDetails.Environment;
    return (["USDEVLOCAL", "LOCAL", "DEV1", "DEV2", "QA1", "QA2"].indexOf(env) > -1)
  }

  isNonProdEnvironment(): boolean {
    let env = this.environmentService.environmentDetails.Environment;
    return (["USDEVLOCAL", "LOCAL", "DEV1", "DEV2", "QA1", "QA2", "UAT1", "UAT2", "FIT"].indexOf(env) > -1)
  }

  generateSafeResourceUrl(urlString): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(urlString);
  }




}

