//Angular Imports 
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

//Third Party Imports
import * as moment from 'moment';
import { retry } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { DateTime } from 'luxon';

//Internal Imports
import { SharedService, EnvironmentService, DISPLAY_ENVIRONMENTS, UserProfileService } from '../../../framework';
import { PackageService } from '../../services/package.service';
import { DeployPackage } from '../../../deploy';
import { RuleEngineForm } from '../../../form-page/models/ruleEngineForm.model';
import { AdminPublishPackageForms } from '../../models/publishAdminPackageForms.model';
import { AdminPackageForms } from '../../models/adminPackageForms.model';
import { error } from 'console';



/**
 * Add/Edit Rule detail Component
*/
@Component({
  selector: 'app-publish-save',
  templateUrl: './admin-publish-save.component.html',
  styleUrls: ['./admin-publish-save.component.css']
})
export class AdminPublishSaveComponent implements OnInit {

  formGroup: UntypedFormGroup;
  index: number;
  scriptNeeded: boolean = false;
  public selectedFormsRules: RuleEngineForm[];
  public selectedLob: string;
  public currentPublishPackageForms: AdminPublishPackageForms;
  environmentList: any[];
  /**
   * Constructor
   * @ignore
  */
  constructor(public activeModal: NgbActiveModal, private fb: UntypedFormBuilder,
    private packageService: PackageService, private toastr: ToastrService,
    private envService: EnvironmentService, private userProfileService: UserProfileService
  ) {
    let environment = this.envService.environmentDetails.Environment;
    this.environmentList = DISPLAY_ENVIRONMENTS[environment];
  }

  //Angular Lifecycles
  /**
   * On Init
   * @ignore
  */
  ngOnInit() {
    this.currentPublishPackageForms = new AdminPublishPackageForms();
    this.createFormBuilder();
    this.fillFormBuilder();
  }

  public onCheckScript() {
    this.scriptNeeded = !this.scriptNeeded;
    console.log(this.scriptNeeded);

  }

  // Public Methods
  /**
   * To save rule details
   * */
  public publish(): void {
    this.currentPublishPackageForms.PackageMetaDataViewModel = new DeployPackage();
    this.currentPublishPackageForms.PackageMetaDataViewModel.LinkedForms = [];
    Object.keys(this.formGroup.controls).forEach(key => {
      this.formGroup.get(key).markAsDirty();
    });
    if (this.formGroup.valid) {
      this.currentPublishPackageForms.AdminPackageFormsViewModels = [];

      _.forEach(this.selectedFormsRules, (formRule) => {
        let packageForm = new AdminPackageForms();
        packageForm.RuleEngineForm = formRule;
        packageForm.LOB = this.selectedLob;
        this.currentPublishPackageForms.PackageMetaDataViewModel.LinkedForms.push(packageForm.RuleEngineForm.AdminFormId);
        this.currentPublishPackageForms.AdminPackageFormsViewModels.push(packageForm);
      });
      this.packageService.validatePackageName(this.formGroup.controls["packageName"].value, this.selectedLob).subscribe(res => {
        if (res) {
          this.currentPublishPackageForms.PackageMetaDataViewModel.CreatedDate = this.formGroup.controls["createDate"].value;
          this.currentPublishPackageForms.PackageMetaDataViewModel.CreatedBy = this.formGroup.controls["publishBy"].value;
          this.currentPublishPackageForms.PackageMetaDataViewModel.LOB = this.selectedLob;
          this.currentPublishPackageForms.PackageMetaDataViewModel.ScriptNeeded = this.scriptNeeded;
          this.currentPublishPackageForms.PackageMetaDataViewModel.Note = this.formGroup.controls["packageComment"].value;
          this.currentPublishPackageForms.PackageMetaDataViewModel.PackageName = this.formGroup.controls["packageName"].value;
          this.currentPublishPackageForms.PackageMetaDataViewModel.Release = this.formGroup.controls["releaseTag"].value;
          this.currentPublishPackageForms.PackageMetaDataViewModel.EnvDeploymentStates = {};
          for (let env of this.environmentList) {
            this.currentPublishPackageForms.PackageMetaDataViewModel.EnvDeploymentStates[env.Value] = { IsDeployed: false, ToBeDeployed: false }
          }
          this.packageService.addAdminPackageForms(this.currentPublishPackageForms).subscribe(result => {
            this.activeModal.close(result);
            this.toastr.success('Publish Data Saved Successfully', 'Package');
          }, error => { this.toastr.error('Error occurred while adding data.', 'Package'); });
        }
        else {
          this.toastr.error('Package Name for LOB ' + this.selectedLob + ' already exists', 'Error Creating Package')
        }
      },
        error => { this.toastr.error('Error occurred when validating package names', 'Error Creating Package') });
      
    }
  }
  


  // Private methods
  /**
   * To create form with form builder
   * */
  private createFormBuilder(): void {
    this.formGroup = this.fb.group({
      packageName: [{ value: '' }, [Validators.required]],
      packageComment: [{ value: '' }, [Validators.required]],
      publishBy: [{ value: '' }, [Validators.required]],
      releaseTag: [{ value: '' }, [Validators.required]],
      createDate: [{ value: '' }]
    });
  }

  /**
   * Set initial values of form elements
   * */
  private fillFormBuilder(): void {
    let todayDate = new Date();
    let date = moment({ year: todayDate.getFullYear(), month: todayDate.getMonth(), day: todayDate.getDate() });
    let unixValue = moment().unix();
    this.formGroup.patchValue({
      packageName: "",
      packageComment: "",
      publishBy: this.userProfileService.UserProfile.UserName,
      releaseTag: "",
      createDate: unixValue,
    });
  }

}
