//Angular Imports 
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

//Third Party Imports
import { Observable, Subscriber } from "rxjs";
import { map } from "rxjs/operators";

//Internal Imports
import { AdminForm } from "../../form-page";
import { RuleEngineForm } from "../../form-page/models/ruleEngineForm.model";

/**
  * Policy Forms Provider
*/
@Injectable()
export class ReportFormService {

  private masterData: any;
  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient) {
  }

getAdminFormsByLOB(lobName: string): Observable<AdminForm[]> {
    let url = `api/AdminForm/Lob/` + lobName;
    return this.http.get<AdminForm[]>(url).pipe(
      map(res => res)
    );
  }
getRuleEngineFormsByLOB(lob: string): Observable<RuleEngineForm[]> {
    let url = `api/AdminForm/RuleEngineForms/` + lob;
    return this.http.get<RuleEngineForm[]>(url).pipe(
      map(res => res));
  }

  getAllAdminForms(): Observable<AdminForm[]> {
    let url = `api/AdminForm/AllForms`;
    return this.http.get<AdminForm[]>(url).pipe(
      map(res=> res));
  }
}
