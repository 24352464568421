//Angular Imports 
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

//Third Party Imports
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

//Internal Imports 
import { AuthorizationService, DateFilterComponent, DateTimeUtcPipe, UserProfileService } from '../../../../../framework';
import 'rxjs';
import { TestHarnessService } from '../../services/testHarness.service';
import { TestHarnessTestGroupComponent } from '../testHarness-testGroup/testHarness-testGroup.component';
import { TestHarnessTestGroup } from '../../models/testHarness-testGroup.model';
import { TestHarnessTestCase } from '../../models/testHarness-testCase.model';
import { TestHarnessTestCaseComponent } from '../testHarness-testCase/testHarness-testCase.component';
import { saveAs } from "file-saver";
import { forEach } from 'lodash';


/**
 * Forms Component
*/
@Component({
  selector: 'app-testHarness-manageTests',
  templateUrl: './testHarness-manageTests.component.html',
  styleUrls: ['./testHarness-manageTests.component.scss']
})

export class TestHarnessManageComponent {

  testGroupGridOptions: GridOptions;
  testCaseGridOptions: GridOptions
  testGroups: TestHarnessTestGroup[] = [];
  testCases: TestHarnessTestCase[] = [];

  /** 
   * Constructor
   * @ignore
  */
  constructor(private harnessService: TestHarnessService,
    private _modal: NgbModal,
    private toastr: ToastrService,
    private dateTimePipe: DateTimeUtcPipe,
    private authService: AuthorizationService) {

  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
  */
  ngOnInit(): void {
    this.configureTestGroupGrid();
    this.configureTestCaseGrid();

  }

  /**
   * NgOnDestroy
   * @ignore
  */
  ngOnDestroy(): void {

  }

  

  private onResultsRowClick(event: any): void {
    //if (event.event.target !== undefined) {
      //this.selectedResult = event.data;
    //}
  }

  private onTestGroupRowClicked(e) {
    if (e.event.target !== undefined) {
      let data = e.data;
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "Edit":
          return this.editTestGroup(data);
        case "Delete":
          return this.deleteTestGroup(data);
      }
      this.highlightTestCases(data);
    }
  }

  exportInfoToCSV() {
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = ["TestGroups", "Name", "Description", "CreationDate"];
    let temp: TestHarnessTestCase[] = this.testCases;

    temp.forEach(x => {
      x.CreationDate = new Date(
        x.CreationDate * 1000
      );
      for (var i = 0; i < x.TestGroups.length; i++) {
        let testGroup = this.testGroups.filter(tg => x.TestGroups[i] == tg.id);
        if (testGroup.length > 0) {
          x.TestGroups[i] = testGroup[0].Name;
        }
      }
    })

    let csv = temp.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    csv.unshift(header.join(','));
    let csvFullString = csv.join('\r\n');

    //console.log(csv)

    var file = new File([csvFullString], "FastReport.csv", { type: "text/plain;charset=utf-8" });
    saveAs(file);
  }

  highlightTestCases(data: TestHarnessTestGroup): void {
    for (var i = 0; i < this.testCases.length; i++) {
      if (this.testCases[i].TestGroups.indexOf(data.id) != -1) {
        this.testCases[i].IsSelected = true;
      }
      else {
        this.testCases[i].IsSelected = false;
      }
    }

    this.testCases.sort((a, b) => {
      if (a.IsSelected == b.IsSelected) {
        return 0;
      }
      else if (a.IsSelected) {
        return -1;
      }
      else {
        return 1;
      }
    });

    this.testCaseGridOptions.api.setRowData(this.testCases);
    this.testCaseGridOptions.api.forEachNode((node) => {
      if (node.data.IsSelected) {
        node.setSelected(true);
      }
    });


  }

  private onTestCaseRowClicked(e) {
    if (e.event.target !== undefined) {
      let data = e.data;
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "Edit":
          return this.editTestCase(data);
        case "Delete":
          return this.deleteTestCase(data);
      }
    }
  }

  createTestGroup(): void {
    const modalRef = this._modal.open(TestHarnessTestGroupComponent, { backdrop: 'static' });
    modalRef.componentInstance.actionType = "Add";

    modalRef.result.then((result: TestHarnessTestGroup) => {
      if (result) {
        this.harnessService.saveTestGroup(result).subscribe(res => {
          this.testGroups.push(result);
          this.setTestGroupRowData();
        });
            
        }
    });
  }

  editTestGroup(testGroup: any): void {
    const modalRef = this._modal.open(TestHarnessTestGroupComponent, { windowClass: 'test-group-detail-modal' });
    modalRef.componentInstance.testGroup = testGroup;
    modalRef.componentInstance.actionType = "Edit";
    modalRef.result.then((result: TestHarnessTestGroup) => {
      if (result) {
        this.harnessService.updateTestGroup(result).subscribe(res => {
          let index = _.findIndex(this.testGroups, x => x.id == result.id);
          this.testGroups[index] = result;
          this.testGroupGridOptions.api.setRowData(this.testGroups);
        })
      }
    });
  }

  deleteTestGroup(testGroup: TestHarnessTestGroup): void {
    this.harnessService.deleteTestGroup(testGroup.id).subscribe(res => {
      this.testGroups.splice(this.testGroups.indexOf(testGroup), 1);
      this.setTestGroupRowData();
    })
    
  }

  createTestCase(): void {
    const modalRef = this._modal.open(TestHarnessTestCaseComponent, { windowClass: 'test-case-detail-modal' });
    modalRef.componentInstance.actionType = "Add";
    var tempTest = new TestHarnessTestCase();
    tempTest.CPM = "";
    tempTest.Forms = [];
    modalRef.componentInstance.testCase = tempTest;

    modalRef.result.then((result: TestHarnessTestCase) => {
      if (result) {
        console.log(result);
        this.harnessService.saveTestCase(result).subscribe(res => {
          this.testCases.push(res);
          this.setTestCaseRowData();
        });
        
      }
    });
    //const modalRef = this._modal.open(TestCaseDetail, { backdrop: 'static', size: 'lg' });
    //const modalRef = this._modal.open(TestCaseDetail, { windowClass: 'test-case-detail-modal' });
    //modalRef.componentInstance.logItem = this.selectedResult;
    //modalRef.componentInstance.actionType = "Add";
    
  }

  editTestCase(testCase: any): void {
    const modalRef = this._modal.open(TestHarnessTestCaseComponent, { windowClass: 'test-case-detail-modal' });
    modalRef.componentInstance.testCase = testCase;
    modalRef.componentInstance.actionType = "Edit";
    modalRef.result.then((result: TestHarnessTestCase) => {
      if (result) {
        this.harnessService.updateTestCase(result).subscribe(res => {
          let index = _.findIndex(this.testCases, x => x.id == result.id);
          this.testCases[index] = res;
          this.testCaseGridOptions.api.setRowData(this.testCases);
        });
        }
    });
  }

  deleteTestCase(testCase: TestHarnessTestCase): void {
    this.harnessService.deleteTestCase(testCase.id).subscribe(res => {
      this.testCases.splice(this.testCases.indexOf(testCase), 1);
      this.setTestCaseRowData();
    })
    
  }

  openTestCase(action: string): void {
    
  }

  private configureTestGroupGrid(): void {
    //this.initialRowDataLoad$ = [];
    this.testGroupGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: 'normal',
      columnDefs: this.createTestGroupColumDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      paginationPageSize: 8,
      rowHeight: 30,
      enableColResize: true,
      onGridReady: () => {
        this.testGroupGridOptions.api.setRowData([]);
        //this.resultsGridOptions.api.setRowData(this.allResults);
        this.testGroupGridOptions.api.sizeColumnsToFit();
        this.harnessService.getTestGroups().subscribe(res => {
          this.testGroups = res;
          console.log(this.testGroups);
          this.testGroupGridOptions.api.setRowData(this.testGroups);
        });
        //this.resultsGridOptions.columnApi.autoSizeAllColumns();
      },
      onRowClicked: (event) => {
        this.onTestGroupRowClicked(event);
      },
      onFilterChanged: (event) => {
      },
      onSortChanged: (event) => {
      },
      onFilterModified: function (event) {
        console.log(event);
      }
    };

  }

  private createTestGroupColumDef(): any[] {
    return [
      { headerName: 'Name', field: 'Name', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'Description', field: 'Description', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'Release Tag', field: 'Release'},
      {
        headerName: "Actions",
        suppressMenu: true,
        suppressSorting: true,
        //hide: !this.FormsWritePermission,  // Hide column if user does not have write permission
        cellRenderer: params => {
          if (this.authService.Administrator()) {
            return `
          <img src="/assets/images/edit_icon.png" data-action-type="Edit" class="cursor_pointer mrgrgt10" title="Edit">
          <img src="/assets/images/x_icon.png" data-action-type="Delete" class="cursor_pointer mrgrgt10" title="Delete" >
          `
          }
          else {
            return ` <img src="/assets/images/edit_icon.png" data-action-type="Edit" class="cursor_pointer mrgrgt10" title="Edit">`;
          }
        }
      }
    ]
  }

  private configureTestCaseGrid(): void {
    //this.initialRowDataLoad$ = [];
    this.testCaseGridOptions = <GridOptions>{
      rowSelection: "multiple",
      domLayout: 'normal',
      columnDefs: this.createTestCaseColumDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      paginationPageSize: 10,
      rowHeight: 30,
      enableColResize: true,
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent
      },
      onGridReady: () => {
        this.testCaseGridOptions.api.setRowData([]);
        //this.resultsGridOptions.api.setRowData(this.allResults);
        this.testCaseGridOptions.api.sizeColumnsToFit();
        this.harnessService.getTestCases().subscribe(res => {
          this.testCases = res;
          console.log(this.testCases);
          this.testCaseGridOptions.api.setRowData(this.testCases);
         // this.exportInfoToCSV();
        });
        //this.resultsGridOptions.columnApi.autoSizeAllColumns();
      },
      onRowClicked: (event) => {
        this.onTestCaseRowClicked(event);
      },
      onFilterChanged: (event) => {
      },
      onSortChanged: (event) => {
      },
      onFilterModified: function (event) {
        console.log(event);
      }
    };

  }

  private createTestCaseColumDef(): any[] {
    return [
      { headerName: 'Name', filter: 'agTextColumnFilter', field: 'Name', resizable:true, comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'Description', filter: 'agTextColumnFilter', field: 'Description', resizable: true, comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      //{ headerName: 'TestGroups', field: 'TestGroups', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'Creation Date', field: 'CreationDate', filter: 'dateFilterComponent',
          cellRenderer: params => {
          return this.dateTimePipe.transform(params.value);
        }},
      {
        headerName: "Actions",
        suppressMenu: true,
        suppressSorting: true,
        //hide: !this.FormsWritePermission,  // Hide column if user does not have write permission
        cellRenderer: params => {
          if (this.authService.Administrator()) {
            return `
          <img src="/assets/images/edit_icon.png" data-action-type="Edit" class="cursor_pointer mrgrgt10" title="Edit">
          <img src="/assets/images/x_icon.png" data-action-type="Delete" class="cursor_pointer mrgrgt10" title="Delete" >
          `
          }
          else {
            return ` <img src="/assets/images/edit_icon.png" data-action-type="Edit" class="cursor_pointer mrgrgt10" title="Edit">`;
          }
        }
      }
    ]
  }

    private setTestGroupRowData() {
        this.testGroupGridOptions.api.setRowData(this.testGroups);
    }
    private setTestCaseRowData() {
        this.testCaseGridOptions.api.setRowData(this.testCases);
    }

}
