import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-checkbox-group-controls',
  template: `
    <div>
      <a class="check-all mdo-standard-link" href="" (click)="toggleBoxGroup( $event, checkboxArray, true)">Check all</a>
      |
      <a class="uncheck-all mdo-standard-link" href="" (click)="toggleBoxGroup( $event, checkboxArray, false)">Uncheck all</a>      
    </div>
  `
})

export class CheckboxGroupControlsComponent {
  @Input() checkboxArray: any;
  @Input() checkedProperty: string = "selected";

  toggleBoxGroup(event: any, optionsArray: any[], toggleOn: boolean) {
    event.preventDefault();
    event.stopPropagation(); // To prevent accordion toggle
    optionsArray.forEach((option: any) => {
      option[this.checkedProperty] = toggleOn;
    });
  }

}
