import { UserInformation } from "./user-setting-info";

export enum ApplicationRole {
    Reader,
    User,
    DevPublisher,
    TestPublisher,
    ProdPublisher,
    Administrator,
    GhostPortalDev,
    GhostPortalReader,
    MarkelUser,
  ICUser,
    MINTUser,
  ICAdministrator,
  MDataReader,
  MDataEdit,
  MDataAdmin,
  MDocReader,
  MDocEdit,
  MDocAdmin,
  MPLUser
}

export class UserProfile {
  public AccountName: string;
  public UserName: string;
  public Roles: ApplicationRole[] = [];
  public RoleString: string;
  public UserInformation: UserInformation;

  constructor(accountName: string, userName: string, roles: string[]) {
    this.AccountName = accountName;
    this.UserName = userName;

    this.AddRoles(roles);
  }


  public AddRoles(roles: string[]) {
    this.RoleString = roles.toString();

    roles.forEach((element) => {
      switch (element) {
        case "Reader": {
          if (this.Roles.indexOf(ApplicationRole.Reader) == -1) {
            this.Roles.push(ApplicationRole.Reader)
          }
          break;
        }
        case "User": {
          if (this.Roles.indexOf(ApplicationRole.User) == -1) {
            this.Roles.push(ApplicationRole.User)
          }
          break;
        }
        case "DevPublisher": {
          if (this.Roles.indexOf(ApplicationRole.DevPublisher) == -1) {
            this.Roles.push(ApplicationRole.DevPublisher)
          }
          break;
        }
        case "TestPublisher": {
          if (this.Roles.indexOf(ApplicationRole.TestPublisher) == -1) {
            this.Roles.push(ApplicationRole.TestPublisher)
          }
          break;
        }
        case "ProdPublisher": {
          if (this.Roles.indexOf(ApplicationRole.ProdPublisher) == -1) {
            this.Roles.push(ApplicationRole.ProdPublisher)
          }
          break;
        }
        case "Administrator": {
          if (this.Roles.indexOf(ApplicationRole.Administrator) == -1) {
            this.Roles.push(ApplicationRole.Administrator)
          }
          break;
        }
        case "MarkelUser": {
          if (this.Roles.indexOf(ApplicationRole.MarkelUser) == -1) {
            this.Roles.push(ApplicationRole.MarkelUser)
          }
         break;
        }
        case "GhostportalUser": {
          if (this.Roles.indexOf(ApplicationRole.GhostPortalReader) == -1) {
            this.Roles.push(ApplicationRole.GhostPortalReader)
          }
         break;
        }
        case "ICUser": {
          if (this.Roles.indexOf(ApplicationRole.ICUser) == -1) {
            this.Roles.push(ApplicationRole.ICUser)
          }
         break;
        }
        case "MINTUser": {
          if (this.Roles.indexOf(ApplicationRole.MINTUser) == -1) {
            this.Roles.push(ApplicationRole.MINTUser)
          }
          break;
        }
        case "ICAdministrator": {
          if (this.Roles.indexOf(ApplicationRole.ICAdministrator) == -1) {
            this.Roles.push(ApplicationRole.ICAdministrator)
          }
         break;
        }
        case "MDataOrchestratorAdmin": {
          if (this.Roles.indexOf(ApplicationRole.MDataAdmin) == -1) {
            this.Roles.push(ApplicationRole.MDataAdmin)
          }
          break;
        }
        case "MDataOrchestratorEdit": {
          if (this.Roles.indexOf(ApplicationRole.MDataEdit) == -1) {
            this.Roles.push(ApplicationRole.MDataEdit)
          }
          break;
        }
        case "MDataOrchestratorRead": {
          if (this.Roles.indexOf(ApplicationRole.MDataReader) == -1) {
            this.Roles.push(ApplicationRole.MDataReader)
          }
          break;
        }
        case "MDocumentOrchestratorAdmin": {
          if (this.Roles.indexOf(ApplicationRole.MDocAdmin) == -1) {
            this.Roles.push(ApplicationRole.MDocAdmin)
          }
          break;
        }
        case "MDocumentOrchestratorEdit": {
          if (this.Roles.indexOf(ApplicationRole.MDocEdit) == -1) {
            this.Roles.push(ApplicationRole.MDocEdit)
          }
          break;
        }
        case "MDocumentOrchestratorRead": {
          if (this.Roles.indexOf(ApplicationRole.MDocReader) == -1) {
            this.Roles.push(ApplicationRole.MDocReader)
          }
          break;
        }
        case "MPLUser": {
          if (this.Roles.indexOf(ApplicationRole.MPLUser) == -1) {
            this.Roles.push(ApplicationRole.MPLUser)
          }
          break;
        }

      }
    })
  }
}
