import { CPMField } from "./cpm-Field.model";
import { DateTime } from "luxon";
import { RuleTypeEnum } from "../enums";

export class CPMElement {
  /**
 * CPMData Element Name
 */

  public Id: string;

  public CPMDataElementName: string;

  public CPMExpression: string;

  public CreationDate: number;

  public CreatedByName: string;

  public ModifiedDate: number;

  public ModifiedByName: string;

  public ComplexType: boolean;

  public RuleType: RuleTypeEnum

  public CPMFieldValues: CPMField[]

  public CPMExpressions: any[];
}

export class CPMPaths {
  public pathType: string;
  public pathVersion: number;
  public path: string;
}
