//Internal Imports
import { BaseRepoItem } from '../../framework';
import { UserNote } from '../../user-notes/models'

/**
 * Print order Model 
 * */
export class PrintOrderMasterViewModel extends BaseRepoItem {

  /**
   * PrintOrderId
   */
  public PrintOrderId: string;

  /**
   * LineOfBusiness
   */
  public LineOfBusiness : string;

   /**
   * FormType
   */
  public FormType: string;

  /**
   * Filing
   */
  public PrintOrder: number;

  /**
 * Description
 */
  public Description: number;

  /**
   * FastKey
   */
  public FastKey: string;  
}
