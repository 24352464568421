//Angular Imports
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

//Third Party Imports
import { AgGridModule } from "ag-grid-angular";
import { NgSelectModule } from "@ng-select/ng-select";

//Internal Imports
import { FrameworkModule } from "../framework";
import { PackageRoutingModule } from "./package.routing";
import { PackageService } from "./services/package.service";
import { StyledComponent } from "./components/admin-package-component/styledComponent";
import { AdminPackageComponent } from "./components/admin-package-component";
import { AdminPublishSaveComponent } from "./components/admin-publish-save";
import { MatButtonModule } from "@angular/material/button";

/**
  Package Module
*/
@NgModule({
  declarations: [
    AdminPackageComponent,
    AdminPublishSaveComponent,
    StyledComponent,
  ],
  imports: [
    PackageRoutingModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatButtonModule,
    FrameworkModule,
    AgGridModule.withComponents([]),
  ],
  providers: [PackageService],
})
export class PackageModule {}
