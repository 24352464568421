<div>
  <div class="modal-header">
    <h4 class="modal-title">Add/Remove Forms From List</h4>
  </div>

  <div class="row">
    <div id="test-group-grid-container"
         class="col-12"
         style="padding-bottom: 10px">
      <ag-grid-angular class="ag-theme-balham fast_panel_table"
                       [gridOptions]="selectedFormsGridOptions"
                       style="height: 400px; padding: 5px">
      </ag-grid-angular>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button"
            mat-raised-button
            class="mat_btn_hov_act mrgrgt10"
            (click)="this.renew()">
      Renew Policy
    </button>
    <button type="button"
            mat-raised-button
            style="background-color: var(--color-teal); color: #fff"
            class="mat_btn_hov_act"
            (click)="close()">
      Close
    </button>
  </div>
</div>
