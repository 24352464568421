//Angular Imports 
import { Component, OnInit, forwardRef, Output, Input, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

//Third Party Imports
import * as moment from "moment";

/**
  Date Picker Component
*/
@Component({
  selector: 'date-picker',
  template: `

    <my-date-picker   placeholder="mm/dd/yyyy"  [disabled]="setDisabled" name="dp" [(ngModel)]="model"  (ngModelChange)="onChange($event)"></my-date-picker>
`,
  styles: [`
    .container-icon-right {
      position: relative;
    }`, `
    .container-icon-right .icon-input {
      position: absolute;
      font-size: 15px;
      cursor: pointer;
      height: 37px;
      width: 100%;
      text-align: right;
      padding: 12px 0px;
      padding-left:88%;
      top:0px;
    }
  `],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatePickerComponent),
    multi: true
  }
  ]
})

export class DatePickerComponent implements OnInit, ControlValueAccessor {


  //Private Variables
  /**
   * @ignore
  */
  private _onChange: any;

  /**
   * @ignore
  */
  private _onTouched: any;

  //Public Variables
  /**
   * set Disabled
  */
  public setDisabled: boolean;

  /**
   * @ignore
  */
  public model: any;

  /**
   * Output event for date change 
  */
  @Output() dateChange: EventEmitter<number>;

  /**
   * explicitDisabled
  */
  @Input('explicitDisabled')
  set setAsDisabled(value: boolean) {
    this.setDisabled = value;
  }

  /**
   * Constructor
   * @ignore
  */
  constructor() {
    this.dateChange = new EventEmitter<number>();
  }

  //Angular Lifcycles
  /**
   * NgOnInit
   * @ignore
  */
  ngOnInit(): void {
  }

  /**
   * NgDestroy
   * @ignore
  */
  ngOnDestroy(): void {
  }


  //ControlValueAccessor Implementation methods start
  /**
   * WriteValue
   * @ignore
  */
  writeValue(value: any): void {
    if (value == null || value == "") return null;
    let date = moment.unix(value).utc(false);
    let dateValue = moment(value).toDate();
    this.model = { date: { year: date.get('year'), month: date.get('month') + 1, day: date.get('date') } };

    // this.model = dateValue;
  }

  /**
   * RegisterOnChange
   * @ignore
  */
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  /**
   * RegisterOnTouched
   * @ignore
  */
  registerOnTouched(fn: any): void {
    this._onTouched = fn;

  }


  //end

  /**
   * OnChange
   * @param {Date} event new date  
   * @returns {void} 
  */
  onChange(event: any): void {
    if (event == null) {
      this._onChange(null);
      this.dateChange.emit(null);
      return;
    };
  
    let date = moment.utc({ year: event.date.year, month: event.date.month - 1, day: event.date.day });
    
    let unixValue = date.unix();
    this._onChange(unixValue);
    this.dateChange.emit(unixValue);
  }
}
