<div class="add_copy_rule">
  <div class="modal-header">
    <h4 class="modal-title">Deployment Confirmation</h4>
  </div>

  <div class="modal-body">
    <!-- Form with read-only fields -->
    <div class="row">
      <form [formGroup]="formGroup">
        <div class="col-md-12 fast_admin mrgbtm10">
          <label>Package Name:</label>
          <input
            id="txtpackageName"
            placeholder="Package Name"
            disabled
            formControlName="packageName"
            class="vex-input"
            readonly
          />
        </div>

        <div class="col-md-12 mrgbtm10">
          <label>Environments Deploy to:</label>
          <input
            id="txtPublishComment"
            placeholder="Package Comment"
            disabled
            formControlName="environmentsDeployTo"
            class="vex-input"
            readonly
          />
        </div>

        <div class="col-md-12 mrgbtm10">
          <label>Deployed By:</label>
          <input
            id="txtPublishedBy"
            placeholder="Deploy By"
            disabled
            formControlName="deployedBy"
            class="vex-input"
          />
          <div
            *ngIf="
              formGroup.controls.deployedBy.invalid &&
              formGroup.controls.deployedBy.dirty
            "
          >
            <span
              [hidden]="!formGroup.controls['deployedBy'].errors.required"
              class="error_msg"
            >
              <i class="error_icon"></i>Deployed By is required.
            </span>
          </div>
        </div>

        <div class="col-md-12 mrgbtm10">
          <label>Deploy Date:</label>
          <date-picker
            [explicitDisabled]="true"
            name="txtdeployDate"
            id="txtEffectiveDate"
            formControlName="deployDate"
          ></date-picker>
          <div
            *ngIf="
              formGroup.controls.deployDate &&
              formGroup.controls.deployDate.dirty &&
              formGroup.controls.deployDate.errors
            "
          >
            <span
              [hidden]="!formGroup.controls['deployDate'].errors?.required"
              class="error_msg"
            >
              <i class="error_icon"></i>Deploy Date is required
            </span>
          </div>
        </div>
      </form>
    </div>

    <!-- Test Groups grid -->
    <div class="row">
      <div
        id="test-group-grid-container"
        class="col-12"
        style="padding-bottom: 10px"
      >
        <div style="padding-bottom: 5px">
          <b>Test Groups to run after deployment (select at least one)</b>
        </div>
        <ag-grid-angular
          class="ag-theme-balham fast_panel_table"
          [gridOptions]="testGroupGridOptions"
          style="height: 125px"
        >
        </ag-grid-angular>
      </div>
    </div>

    <!-- Testing environment -->
    <div class="row">
      <div class="col-md-12 mrgbtm10 fast_admin">
        <label class="label_heading">Environment to test:</label>
        <div class="selectdiv select_wrapinline" style="width: 100%">
          <label>
            <ng-select
              id="env"
              class="custom-select"
              style="width: 100%"
              [items]="envList"
              bindLabel="Name"
              bindValue="Name"
              [(ngModel)]="selectedEnv"
            ></ng-select>
          </label>
        </div>
      </div>
    </div>

    <!-- Production warning -->
    <div class="row">
      <div *ngIf="productionDeployment">
        <div>WARNING: You are about to deploy forms to Production.</div>
        <ul>
          <li>
            Be sure all forms have been validated in the test environmeht.
          </li>
          <li>
            If these forms contain rules based on new Cpm Elements, make sure
            the Copy Reference Table script is being run.
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="mat_btn_hov_act mrgrgt10"
      mat-raised-button
      (click)="activeModal.close()"
    >
      Cancel
    </button>
    <button
      type="button"
      mat-raised-button
      class="btn-teal"
      (click)="deployPackage()"
    >
      Continue
    </button>
  </div>
</div>
