import { Component, OnInit } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-download-staging-file-cell',
  template: `
    <span>Download</span>
    <app-download-item-cell [downloadContext]="'stagingFile'" [itemId]="(params.data.Id + '|^|' + params.data.Filename) " [componentParent]="params.context.componentParent"></app-download-item-cell>
  `
})
export class DownloadStagingFileCellComponent implements AgRendererComponent {

  params: any;

  constructor() { }

  // Called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  // Called whenever the user gets the cell to refresh
  refresh(params: ICellRendererParams): boolean {
    return true;
  }

}
