import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ColDef } from 'ag-grid-community';
import { ImportQueueService } from "../../services/import-queue.service";
import { UpdateContext } from "../../models/update-context";
import { StagingFileUpdatePayload } from "../../models/staging-file-update-payload";
import { UpdateFilesResponse } from '../../models/update-file-response';
import { UserProfileService } from "../../../framework";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-update-staging-files',
  templateUrl: './update-staging-files.component.html',
  styleUrls: ['./update-staging-files.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UpdateStagingFilesComponent implements OnInit {

  private columnApi: any;
  updateContext: UpdateContext;
  actionOptions: any[] = [
    { label: "Abort", value: "Abort", modelRequired: false },
    { label: "Queue for Processing", value: "Queue for Processing", modelRequired: false }
  ];
  columnDefs: ColDef[];
  pagination: boolean = false;
  errors: string[] = [];
  inProgress: boolean = false;
  hasUpdateFailures: boolean = false;
  selectedCount: number;
  failedCount: number;

  updateForm = new FormGroup({
    requestedAction: new FormControl<string>('', [Validators.required]),
    note: new FormControl<string>('', [Validators.required, Validators.maxLength(1000)])
  });

  @Output() triggerDetailUpdate = new EventEmitter();
  @Output() triggerQueueUpdate = new EventEmitter();

  @ViewChild('updateStagingFilesModal') updateStagingFilesModal: any;

  constructor(private queueService: ImportQueueService, private modalService: NgbModal, private userProfileService: UserProfileService, private snackBar: MatSnackBar) {
    this.columnDefs = [
      { field: 'fileId', headerName: "File ID", sortable: true, filter: true, resizable: true, width: 100 },
      { field: 'filename', headerName: "Filename", sortable: true, filter: true, resizable: true, width: 350 },
      { field: 'status', headerName: "Current Status", sortable: true, filter: true, resizable: true, width: 150 },
      { field: 'partner', headerName: "Partner", sortable: true, filter: true, resizable: true, width: 120 },
      { field: 'product', headerName: "Product", sortable: true, filter: true, resizable: true },
      { field: 'errorMessage', headerName: "Error Message", sortable: true, filter: true, resizable: true, width: 400, hide: true },
    ];
  }

  ngOnInit(): void {
  }

  onGridReady(params: any): void {
    this.columnApi = params.columnApi;
  }

  dismissModal(): void {
    this.hasUpdateFailures = false;
    this.modalService.dismissAll();
  }

  openModal(updateContext: UpdateContext): void {
    this.updateContext = updateContext;
    this.performReset();
    this.modalService.open(this.updateStagingFilesModal, { backdrop: 'static', size: 'xl' });
  }

  performReset() {
    this.inProgress = false;
    this.errors = [];
    this.updateForm.controls.requestedAction.reset('');
    this.updateForm.controls.note.reset('');
  }

  performUpdate(): void {
    this.inProgress = true;
    this.errors = [];
    let hasErrors = ((this.updateContext.action == "requestAction" && this.updateForm.controls.requestedAction.errors) || this.updateForm.controls['note'].errors);

    if (hasErrors) {

      if (this.updateContext.action == "requestAction" && this.updateForm.controls.requestedAction.errors && this.updateForm.controls.requestedAction.errors["required"] == true) {
        this.errors.push("You must select an action.");
      }
      if (this.updateForm.controls.note.errors && this.updateForm.controls.note.errors["required"] == true) {
        this.errors.push("You must enter a note.");
      }
      if (this.updateForm.controls.note.errors && this.updateForm.controls.note.errors["maxlength"]) {
        this.errors.push("Your note cannot be longer than " + this.updateForm.controls.note.errors["maxlength"].requiredLength + " characters.");
      }
      this.inProgress = false;

    } else {

      let updatePayload: StagingFileUpdatePayload = {
        fileIds: [],
        notes: this.updateForm.controls.note.value,
        user: this.userProfileService.UserName
      }

      if (this.updateContext.action == "requestAction") {
        updatePayload.action = this.updateForm.controls.requestedAction.value;
      }

      this.updateContext.stagingFiles.forEach((item: any) => {
        //Because a StagingFile entity has the id typed as a string, do a conversion:
        updatePayload.fileIds.push(parseInt(item.fileId));
      });

      this.queueService.updateStagingFiles(updatePayload, this.updateContext.context)
        .subscribe(
          // Handle success response
          updateResponse => {
            //handle update submission error(s)
            let responseDict = new Map<string, UpdateFilesResponse>();
            updateResponse.body.UpdateFileResponse.forEach((response: UpdateFilesResponse) => {
              responseDict.set(response.FileId, response);
            });

            let failedFiles: any[] = [];
            this.updateContext.stagingFiles.forEach((stagingFile: any) => {
              const match = responseDict.get(stagingFile.fileId.toString());
              if (match !== undefined && match.RequestStatus == "Failed Submission") {
                failedFiles.push({
                  ...stagingFile,
                  errorMessage: match.Reason
                })
              }
            });

            if (failedFiles.length !== 0) {

              this.inProgress = false;
              this.hasUpdateFailures = true;
              this.selectedCount = updateResponse.body.UpdateFileResponse.length;
              this.failedCount = failedFiles.length;
              this.updateContext.stagingFiles = failedFiles;
              this.columnApi.setColumnVisible('errorMessage', true);

            } else {

              if (this.updateContext.context == 'detail') {
                this.triggerDetailUpdate.emit(updatePayload);
              } else {
                this.triggerQueueUpdate.emit();
              }
              this.snackBar.open("Update successful", "Close", { duration: 1000, panelClass: ['mdo-snack-msg'] });

              this.inProgress = false;
              this.modalService.dismissAll();
            }
          },
          // Handle an error response
          error => {
            this.errors.push("The update attempt failed.  Please try again.");
            this.inProgress = false;
          }
        );

    }

  }

}
