<div class="modal-header">
  <h4 class="modal-title">Log Details</h4>
</div>
<div class="modal-body col-12">
  <mat-tab-group class="vex-tabs">
    <mat-tab label="Transaction details">
      <div class="p-6">
        <div class="row">
          <div class="col-md-5">
            <mat-list>
              <mat-list-item>
                <p matLine class="detail-label gen-log-label">
                  Writing Company
                </p>
                <p matLine class="gen-log-details">
                  {{ this.testLog.WritingCompany }}
                </p>
              </mat-list-item>
              <mat-list-item>
                <p matLine class="detail-label gen-log-label">State</p>
                <p matLine class="gen-log-details">{{ this.testLog.State }}</p>
              </mat-list-item>
              <mat-list-item>
                <p matLine class="detail-label gen-log-label">Effective Date</p>
                <p matLine class="gen-log-details">
                  {{ this.policyEffectiveString }}
                </p>
              </mat-list-item>
              <mat-list-item>
                <p matLine class="detail-label gen-log-label">
                  Lines of Business
                </p>
                <p matLine class="gen-log-details">{{ this.lobResults }}</p>
              </mat-list-item>
              <mat-list-item style="overflow: auto; height: 100px">
                <p matLine class="detail-label gen-log-label">Coverages</p>
                <p
                  matLine
                  class="gen-log-details"
                  style="white-space: initial; height: 75px; overflow: auto"
                >
                  {{ this.coverageResults }}
                </p>
              </mat-list-item>
            </mat-list>
          </div>
          <div class="col-md-7" style="margin-top: 15px">
            <label class="label_heading" style="padding-left: 28px">
              <div class="log-label">Attachment Results:</div></label>
            <div
              class="selectdiv select_wrapinline"
              style="padding-left: 8px; margin-bottom: 20px"
            >
              <label>
                <ng-select
                  id="selectedCoverage"
                  class="custom-select"
                  [items]="this.testLog.ExpectedResults"
                  bindLabel="LOB"
                  bindValue="LOB"
                  (change)="onLOBChange($event)"
                  [(ngModel)]="selectedLOB"
                  [clearable]="false"
                  style="width: 50%"
                >
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{ item.LOB }}">{{ item.LOB }}</div>
                  </ng-template>
                </ng-select>
              </label>
            </div>
            <div class="row" style="padding-bottom: 20px; margin-left: 16px">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6" [hidden]="mandatoryFormCount === 0">
                    <div style="padding-bottom: 10px">
                      <div class="log-label">Mandatory Forms - {{ this.mandatoryFormCount }}</div>
                    </div>
                    <div
                      style="
                        display: block;
                        /* border-radius: 5px; */
                        padding-left: 14px;
                        width: 70%;
                        border-right: 1px solid #c0c0c07d;
                        overflow: auto;
                        height: 250px;
                        --blink-scrollbar-width: 6px;
                      "
                    >
                      <div
                        class="row"
                        *ngFor="let item of this.mandatoryFormResults"
                      >
                        <div
                          class="col-12"
                          id="execute-rules-content-heading-container"
                        >
                          <label class="label_heading log-label-form"
                            >&#x2022; {{ item }}</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6" [hidden]="optionalFormCount === 0">
                    <div style="padding-bottom: 10px">
                      <div class="log-label">Optional Forms - {{ this.optionalFormCount }}</div>
                    </div>
                    <div
                      style="
                        display: block;
                        /* border-radius: 5px; */
                        padding-left: 14px;
                        width: 70%;
                        border-right: 1px solid #c0c0c07d;
                        overflow: auto;
                        height: 250px;
                        --blink-scrollbar-width: 6px;
                      "
                    >
                      <div
                        class="row"
                        *ngFor="let item of this.optionalFormResults"
                      >
                        <div
                          class="col-12"
                          id="execute-rules-content-heading-container"
                        >
                          <label class="label_heading log-label-form"
                            >&#x2022; {{ item }}</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="CPM">
      <pre class="custom-xml" *ngIf="cpmIsXML" style="color: black !important">{{
        testLog.CPM | xml
      }}</pre>
      <pre class="custom-xml" *ngIf="!cpmIsXML" id="JSONtestLogCPM">{{logItem.CPM}}</pre>
    </mat-tab>
  </mat-tab-group>
</div>


<div class="modal-footer">
  <button
    type="button"
    mat-raised-button
    class="mat_btn_hov_act"
    (click)="this.openTestSuite()"
    style="margin-right: 20px"
    color="primary"
  >
    Open Test Suite With log
  </button>
  <button
    type="button"
    mat-raised-button
    class="mat_btn_hov_act"
    (click)="this.copyErrorText()"
    style="margin-right: 20px"
    color="primary"
  >
    Copy CPM to Clipboard
  </button>
  <button
    type="button"
    mat-raised-button
    class="mat_btn_hov_act"
    (click)="activeModal.close()"
    color="navy"
  >
    Close
  </button>
</div>
