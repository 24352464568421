<div class="add_copy_rule">
  <div class="modal-header">
    <h4 class="modal-title">{{ action }} Package Confirmation</h4>
  </div>
  <form [formGroup]="formGroup">
    <div class="modal-body">
      <div class="container">
        <div class="row">
          <div class="col-md-12 fast_admin mrgbtm10">
            <label>Package Name:</label>
            <input
              id="txtpackageName"
              placeholder="Source Package Name"
              disabled
              formControlName="packageName"
              class="vex-input"
              readonly
            />
          </div>

          <div class="col-md-12 fast_admin mrgbtm10">
            <label>Package Name:</label>
            <input
              id="txtTargetpackageName"
              placeholder="Target Package Name"
              formControlName="targetPackageName"
              class="vex-input"
            />
          </div>
          <div class="col-md-12 fast_admin mrgbtm10">
            <label>Release Tag:</label>
            <input
              id="txtReleaseTag"
              placeholder="Release Tag"
              formControlName="releaseTag"
              class="vex-input"
            />
          </div>

          <div class="col-md-12 mrgbtm10">
            <label>Copied By:</label>
            <input
              id="txtPublishedBy"
              placeholder="Deploy By"
              disabled
              formControlName="copiedBy"
              class="vex-input"
            />
            <div
              *ngIf="
                formGroup.controls.copiedBy.invalid &&
                formGroup.controls.copiedBy.dirty
              "
            >
              <span
                [hidden]="!formGroup.controls['copiedBy'].errors.required"
                class="error_msg"
              >
                <i class="error_icon"></i>Copied By is required.
              </span>
            </div>
          </div>

          <div class="col-md-12 mrgbtm10">
            <label>Copied Date:</label>
            <date-picker
              [explicitDisabled]="true"
              name="txtcopyDate"
              id="txtcopyDate"
              formControlName="copyDate"
            ></date-picker>
            <div
              *ngIf="
                formGroup.controls.copyDate &&
                formGroup.controls.copyDate.dirty &&
                formGroup.controls.copyDate.errors
              "
            >
              <span
                [hidden]="!formGroup.controls['copyDate'].errors?.required"
                class="error_msg"
              >
                <i class="error_icon"></i>Copy Date is required
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="modal-footer">
    <button
      type="button"
      class="mat_btn_hov_act mrgrgt10"
      mat-raised-button
      (click)="activeModal.close()"
    >
      Cancel
    </button>
    <button
      type="button"
      mat-raised-button
      class="btn-teal"
      (click)="copyPackage()"
    >
      Continue
    </button>
  </div>
</div>
