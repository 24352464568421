import { Component, EventEmitter, Output } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular'
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-entity-log-detail-trigger',
  template: `
    <span>
      <a class="entity-log-detail-link mdo-standard-link" href="" (click)="showEntityLogDetail($event)">{{ idBundle.data["transactionId"] }}</a>
    </span>
    <app-copy-cell-value [cellValue]="idBundle.data['transactionId']"></app-copy-cell-value>
  `
})
export class EntityLogDetailTriggerComponent implements AgRendererComponent {

  idBundle: any = {};
  params: any;

  constructor() { }

  // Called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.idBundle = this.setIdBundle(params);
    this.params = params;
  }

  // Called whenever the user gets the cell to refresh
  refresh(params: ICellRendererParams): boolean {
    this.idBundle = this.setIdBundle(params);
    return true;
  }

  setIdBundle(params: ICellRendererParams): any {
    return {
      bundleLabel: "Log",
      bundleValue: params.data.transactionId,
      context: "EntityLog",
      data: params.data
    }
  }

  showEntityLogDetail(event: any) {
    event.preventDefault();
    // To prevent the row selection event from taking place and inadvertently toggling the selection checkbox
    event.stopPropagation();
    // Calls parent component method to then call addTab in masterviewcomponent
    this.params.context.componentParent.addEntityLogDetailTab(
      this.idBundle
    );
  }

}

