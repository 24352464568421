//Angular Imports
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

//RxJS Imports
import { Observable, of } from 'rxjs';

//Internal Imports
import { AuthenticationService } from '../services';

/**
  * Authentication Resolver
  * Resolves the request for fetching bearer token
*/
@Injectable()

export class AuthenticationResolver implements Resolve<string> {

  /**
   * Constructor
   * @ignore
  */
  constructor(private _authenticationService: AuthenticationService) { }

  /**
   * Resolves the request fetching bearer token
   * @returns {Observable<string>} token Observable 
  */
  resolve(): Observable<string> {

    //Check if already authenticated else get token 
    if (this._authenticationService.token) {
      return of(this._authenticationService.token);
        }
    else {
      return this._authenticationService.authenticate();            
        }
    }
}
