import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-chart-display',
  templateUrl: './chart-display.component.html',
  styleUrls: ['./chart-display.component.scss']
})
export class ChartDisplayComponent {

  @Input() isLoadingChartingData: boolean;
  @Input() showChartingError: boolean;
  @Input() chart: any;

  @Output() retryChartData = new EventEmitter();

  constructor() { }

  fixChartData(): void {
    event.preventDefault();
    event.stopPropagation();
    this.retryChartData.emit();
  }

}
