import { Component, ViewChild, OnInit } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { filter, map, startWith } from 'rxjs';
import { SidebarComponent } from '../../../../@vex/components/sidebar/sidebar.component';
import { ConfigService } from '../../../../@vex/config/config.service';
import { LayoutService } from '../../../../@vex/services/layout.service';
import { checkRouterChildsData } from '../../../../@vex/utils/check-router-childs-data';

import { UserProfileService, UserProfile, SharedService } from '../../../framework';


@Component({
  selector: 'app-data-management-layout',
  templateUrl: './layout.component.html'
})
export class LayoutComponent implements OnInit {

  environmentContext: string = "";

  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isFooterVisible$ = false;
  isDesktop$ = this.layoutService.isDesktop$;

  toolbarShadowEnabled$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.toolbarShadowEnabled))
  );

  @ViewChild('configpanel', { static: true }) configpanel: SidebarComponent;


  public userProfile: UserProfile;


  constructor(
    public userProfileService: UserProfileService,
    public sharedService: SharedService,
    private router: Router,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private route: ActivatedRoute
  ) {
    this.userProfile = userProfileService.UserProfile;
  }

  ngOnInit(): void {
    /*
     Hide the preloader div on the index.html pages on init. If you don't do this, the UI of the home component
     will be hidden on page reload / hot reload
    */
    this.sharedService.hidePreloader();

  }

}
