import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DuplicateService {

  constructor(private http: HttpClient) { }

  getJwt(env: string) {
    //var gurl = `https://localhost:64655/api/v2/document/login?UserName=Specialty&Key=BD5065AF-2A62-4002-B08F-0EB91221FDF1`;
    var gurl = `https://mkl-mgdapi-${env.toLowerCase()}.azurewebsites.net/api/v2/document/login?UserName=Specialty&Key=BD5065AF-2A62-4002-B08F-0EB91221FDF1`;
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    return this.http.get(gurl, { headers, responseType: 'text' }).pipe(map(res => res));
  }

  checkDuplicate(env: string, token: string): Observable<any> {
    var gurl = `https://mkl-mgdapi-${env}.azurewebsites.net/api/v2/cache/info`;
      if (env.toLowerCase() == "local") {
        gurl = `https://localhost:63161/api/v2/cache/duplicate`;
    }
    let params = new HttpParams().set('env', env);
    let headers = new HttpHeaders().append('Authorization', `Bearer ${token}`);

    return this.http.get<any>(gurl, { headers, params }).pipe(
      map(res => res)
    );
  }
}
