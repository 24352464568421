import { RuleSet } from "./ruleSet.model";


export class Folder {
  public id: string;
  public FastKey: string;
  public FolderName: string;
  public System: string;
  public RuleSet: RuleSet;
}
