export class FilterData {
  startDate: Date;
  endDate: Date;
  status: string[];
  partner: string[];
  productType: string[];
  jobId: string;
  productTypeId: string;
  filename: string;

  constructor( objectData?: any ) {
    this.startDate = objectData.startDate || "";
    this.endDate = this.adjustEndDate(objectData.endDate);
    this.status = objectData.status || [];
    this.partner = objectData.partner || [];
    this.productType = objectData.productType || [];
    this.jobId = objectData.jobId || "";
    this.productTypeId = objectData.productTypeId || "";
    this.filename = objectData.filename || "";
  }

  adjustEndDate(endDate: Date): any {
    let adjustedDate;
    if (endDate) {
      adjustedDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59, 999);
    } else {
      adjustedDate = '';
    } 
    return adjustedDate;
  }
}
