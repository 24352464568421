<div class="tw-card tw-overflow-auto tw-flex tw-flex-col md:tw-flex-row sm:tw-mr-6 m-menu">
  <div class="tw-p-gutter tw-flex-auto tw-max-w-[455px]">
    <h3 class="body-2 tw-m-0 tw-text-white" style="font-size: 1.5rem">
      Document Attachment
    </h3>
    <div class="tw-mt-6 tw-grid tw-grid-cols-2 tw-gap-x-4 tw-gap-y-4">
      <a (click)="close()"
         *ngFor="let attach of attachPages"
         [routerLink]="attach.route"
         class="tw-text-white tw-body-1 tw-underline hover:tw-text-primary tw-trans-ease-out">
        <!-- <mat-icon
          [svgIcon]="attach.icon"
          class="vex-sidenav-item__icon"
        ></mat-icon> -->
        <img src="./../../../assets/icons/{{ attach.icon }}" width="22px" />

        {{ attach.label }}
      </a>
    </div>
  </div>

  <div class="tw-p-gutter tw-flex-auto tw-max-w-[455px]">
    <h3 class="body-2 tw-m-0 tw-text-white" style="font-size: 1.5rem">
      Document Generation
    </h3>

    <div class="tw-mt-6 tw-grid tw-grid-cols-2 tw-gap-x-4 tw-gap-y-4">
      <a (click)="close()"
         *ngFor="let gen of genPages"
         [routerLink]="gen.route"
         class="tw-text-white tw-body-1 tw-underline hover:tw-text-primary tw-trans-ease-out">
        <!-- <mat-icon
          [svgIcon]="gen.icon"
          class="vex-sidenav-item__icon"
        ></mat-icon> -->
        <img src="./../../../assets/icons/{{ gen.icon }}" width="22px" />

        {{ gen.label }}
      </a>
    </div>
  </div>
  <div class="tw-p-gutter tw-flex-auto tw-max-w-[455px]">
    <h3 class="body-2 tw-m-0 tw-text-white" style="font-size: 1.5rem">
      Data Management / Logs
    </h3>

    <div class="tw-mt-6 tw-grid tw-grid-cols-2 tw-gap-x-4 tw-gap-y-4">
      <a (click)="close()"
         *ngFor="let mData of mDataPages"
         [routerLink]="mData.route"
         class="tw-text-white tw-body-1 tw-underline hover:tw-text-primary tw-trans-ease-out">
        <!-- <mat-icon
          [svgIcon]="mData.icon"
          class="vex-sidenav-item__icon"
        ></mat-icon> -->
        <img src="./../../../assets/icons/{{ mData.icon }}" width="22px" />

        {{ mData.label }}
      </a>
    </div>
  </div>
