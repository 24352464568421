import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { OdenService } from '../../services/oden.service';
import { MultiChoiceOption } from '../../models/multi-choice-option';
import { SearchFilterData } from '../../models/search-filter-data';

@Component({
  selector: 'app-log-search-filter',
  templateUrl: './log-search-filter.component.html',
  styleUrls: ['./log-search-filter.component.scss']
})
export class LogSearchFilterComponent implements OnInit {

  initialEndDate: any = new Date();
  initialStartDate: any = new Date(this.initialEndDate.getFullYear(), this.initialEndDate.getMonth(), (this.initialEndDate.getDate() - 14), 0, 0, 0, 0);

  @Output() triggerSearch = new EventEmitter();
  @Output() toggleSearchFilterError = new EventEmitter();

  isReady: boolean = false;
  microserviceList: MultiChoiceOption[] = [];
  fullMicroserviceValueList: string[] = [];

  filterInputs = new FormGroup({
    startDate: new FormControl<string>(''),
    endDate: new FormControl<string>(''),
    policyNumber: new FormControl<string>(''),
  });

  constructor(private odenService: OdenService) { }

  ngOnInit(): void {
    this.getSearchFilterOptions();
    this.filterInputs.controls.startDate.setValue(this.initialStartDate);
    this.filterInputs.controls.endDate.setValue(this.initialEndDate);
  }

  getSearchFilterOptions(): void {
    this.odenService.getSearchFilterOptions()
      .subscribe({
        next: filterOptionsResponse => {
          this.populateMicroservicesOptions(filterOptionsResponse.microservices);
          this.isReady = true;
        },
        error: error => {
          this.toggleSearchFilterError.emit(true);
          this.isReady = false;
        }
      });
  }

  populateMicroservicesOptions(msOptions: any): void {
    this.microserviceList = [];
    msOptions.forEach((ms: any) => {
      this.microserviceList.push({ label: ms.label, value: ms.value, selected: false, });
      // Populate full value list in case no checkboxes are selected
      this.fullMicroserviceValueList.push(ms.value);
    });
  }

  triggerFilter(): void {
    let searchFilterData: SearchFilterData = this.configureFilterParams();
    this.odenService.changeSearchFilterData(searchFilterData); 
  }

  configureFilterParams(): SearchFilterData {
    let selectedMicroserviceValueList: string[] = [];
    let startDate = this.filterInputs.controls.startDate.value;
    let endDate = this.filterInputs.controls.endDate.value;

    this.microserviceList.forEach(ms => {
      if (ms.selected) {
        selectedMicroserviceValueList.push(ms.value);
      }
    });

    let searchFilterData: SearchFilterData = new SearchFilterData({
      startDateTime: startDate,
      endDateTime: endDate,
      policyNumber: this.filterInputs.controls.policyNumber.value,
      microservices: selectedMicroserviceValueList.length ? selectedMicroserviceValueList : this.fullMicroserviceValueList  
    });

    return searchFilterData;

  }

  retryOptionsRetrieval(): void {
    this.toggleSearchFilterError.emit(false);
    this.getSearchFilterOptions();
  } 


}
