import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationResolver } from '../framework';
import { AuthGuard } from '../framework/guards/auth.guard';

// Submodules
import { InsuredIdentityModule } from './submodules/insured-identity/insured-identity.module';
import { OdenModule } from './submodules/oden/oden.module';
import { AMSModule } from './submodules/ams/ams.module'; 

import { LayoutComponent } from './components/layout/layout.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent
    , resolve: {
      token: AuthenticationResolver
    },
    children: [
      {
        path: 'insured-identity', canActivate: [AuthGuard],
        loadChildren: () => InsuredIdentityModule
      },
      {
        path: 'oden', canActivate: [AuthGuard],
        loadChildren: () => OdenModule
      },
      {
        path: 'ams', canActivate: [AuthGuard],
        loadChildren: () => AMSModule
      },
      {
        path: '**',
        redirectTo: 'insured-identity', 
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthenticationResolver]
})
export class DataManagementRoutingModule { }
