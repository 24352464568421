<div class="modal-header">
  <h4 class="modal-title">{{ actionType }} Form</h4>
</div>
<form [formGroup]="formGroup">
  <div class="modal-body col-12">
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-12 mrgbtm10">
          <label style="font-weight: bold">CPM data element name</label>
          <input
            id="txtExternalFormNumber"
            style="width: 100%"
            formControlName="cPMDataElementName"
            class="vex-input"
          />
          <div
            *ngIf="
              formGroup.controls.cPMDataElementName &&
              formGroup.controls.cPMDataElementName.dirty &&
              formGroup.controls.cPMDataElementName.errors
            "
          >
            <span class="error_msg">
              <i class="error_icon"></i>CPM data element name is required
            </span>
          </div>
        </div>
        <div class="col-md-12 mrgbtm10">
          <label style="font-weight: bold">CPM data expression</label>
          <textarea
            id="txtNoteText"
            style="width: 100%"
            rows="4"
            formControlName="cPMExpression"
          ></textarea>
          <!--<input id="txtExternalFormNumber"   style="width:100%" formControlName="cPMExpression" >-->
          <div
            *ngIf="
              formGroup.controls.cPMExpression &&
              formGroup.controls.cPMExpression.dirty &&
              formGroup.controls.cPMExpression.errors
            "
          >
            <span class="error_msg">
              <i class="error_icon"></i>CPM data expression is required
            </span>
          </div>
        </div>
        <div class="col-md-12 mrgbtm10">
          <label style="font-weight: bold">
            <input
              id="chkIsComplex"
              formControlName="complexType"
              type="checkbox"
              style="display: inline-block; margin-right: 5px"
            />Complex Expression?
          </label>
        </div>
        <div class="col-md-12 mrgbtm10">
          <label style="font-weight: bold">
            <input
              id="chkIsComplex"
              formControlName="ruleType"
              type="checkbox"
              style="display: inline-block; margin-right: 5px"
            />Derived Rule Type?
          </label>
        </div>
      </div>
    </div>
  </div>
</form>
<div class="modal-footer">
  <button
    type="button"
    class="mat_btn_hov_act mrgrgt10"
    mat-raised-button
    (click)="activeModal.close()"
  >
    Cancel
  </button>
  <button
    type="button"
    mat-raised-button
    class="btn-teal"
    (click)="saveCpmElement()"
  >
    Save
  </button>
</div>
