/**
 * Properties of class BaseRepoItem
 * */
export class BaseRepoItem {
  /**
  * Id
  */
  public Id: string;

  /**
  * Effective date of the repo item
  */
  public EffectiveDate: number;

  /**
  * Expiration date of the repo item
   */
  public ExpirationDate: number;

  /**
   * Submission expiration date of the item, 90 days after exp date
   **/

  public SubmissionExpirationDate: number;

  /**
  * Version of rule/form
  */
  public Version: string;

  /**
  * This value indicates if repo item is mandatory 
  */
  public IsMandatory: boolean;

  /**
  * This flag indicates if the current record is active
  */
  public IsActive: boolean;

  /**
  * The renew effective date 
  */
  public RenewEffectiveDate: number;

  /**
  * Thew renew expiration date
  */
  public RenewExpirationDate: number;

  /**
  * The created date of record
  */
  public CreationDate: number;

  /**
  * Indicates who created the record
  **/
  public CreatedByName: string;

  /**
  * Indicates modified date
  **/
  public ModifiedDate: number;

  /**
  * Indicates the modified by name
  */
  public ModifiedByName: string;

}
