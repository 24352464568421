import { Component, Input } from '@angular/core';
import { Clipboard } from "@angular/cdk/clipboard";


@Component({
  selector: 'app-copy-cell-value',
  templateUrl: './copy-cell-value.component.html',
  styleUrls: ['./copy-cell-value.component.scss']
})
export class CopyCellValueComponent {

  @Input() cellValue: any;

  constructor(private clipboardService: Clipboard ) { }

  copyCellValueToClipboard(val: any) {
    event.preventDefault();
    // To prevent the row selection event from taking place and inadvertently toggling the selection checkbox
    event.stopPropagation();
    this.clipboardService.copy(val);
  }

}
