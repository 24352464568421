import { Component, Input, Output, ViewChild, EventEmitter, OnInit } from '@angular/core';
import { ColDef, SelectionChangedEvent, RowSelectedEvent } from 'ag-grid-community';
import { GridOptions } from "ag-grid-community";
import { FilterData } from "../../models/filter-data";
import { ImportQueueService } from '../../services/import-queue.service';
import { StagingFileSummary } from '../../models/staging-file-summary';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StagingFileDetailTriggerComponent } from '../staging-file-detail-trigger/staging-file-detail-trigger.component';
import { FilenameCellComponent } from '../filename-cell/filename-cell.component';
import { DownloadStagingFileCellComponent } from '../download-staging-file-cell/download-staging-file-cell.component';
import { UpdateContext } from '../../models/update-context';

@Component({
  selector: 'app-staging-file-queue',
  templateUrl: './staging-file-queue.component.html',
  styleUrls: ['./staging-file-queue.component.scss']
})
export class StagingFileQueueComponent implements OnInit {
  @Input() initialStartDate: any;
  @Input() initialEndDate: any;

  @Output() addStagingFileTab = new EventEmitter();

  @ViewChild('stagingFileFilterOptions') stagingFileFilterOptions: any;
  @ViewChild("updateStagingFilesModal") updateStagingFilesModal: any;
  @ViewChild("uploadFilesModal") uploadFilesModal: any;

  isFetchingData: boolean = false;
  showStagingFilterError: boolean = false;
  showStagingTableError: boolean = false;
  downloadColumnVisible: boolean = false;
  errorStatuses: string[] = [];

  private gridApi: any;
  gridOptions: any;
  frameworkComponents: any;
  columnDefs: ColDef[];
  pagination: boolean = true;
  paginationAutoPageSize: boolean = true;
  idBundle: any = {};
  rowSelection: string = "multiple";
  rowMultiSelectWithClick: boolean = true;
  selectedFileCount: number = 0;
  isAuthorizedToUpload: boolean = false;

  rowData: StagingFileSummary[] = [];

  constructor(private queueService: ImportQueueService, private snackBar: MatSnackBar) {

    this.gridOptions = <GridOptions>{
      context: { componentParent: this }
    }

    this.columnDefs = [
      {
        field: 'Id',
        headerName: "ID",
        sortable: true,
        filter: true,
        resizable: true,
        width: 100,
        cellRenderer: 'stagingFileDetailTriggerRenderer',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true
      },
      { field: 'Filename', headerName: "Filename", sortable: true, filter: true, resizable: true, width: 330, cellRenderer: 'filenameRenderer' },
      {
        colId: 'downloadColumn',
        field: 'Id',
        headerName: "File",
        cellRenderer: 'fileDownloadRenderer',
        width: 150
      },
      { field: 'Status', headerName: "Status", sortable: true, filter: true, resizable: true, width: 100 },
      { field: 'Partner', headerName: "Partner", sortable: true, filter: true, resizable: true, width: 100 },
      { field: 'Product', headerName: "Product", sortable: true, filter: true, resizable: true, width: 175 },
      { field: 'Message', headerName: "Message", sortable: true, filter: true, resizable: true, width: 150 },
     
      {
        field: 'Timestamp',
        headerName: "Timestamp",
        sortable: true,
        comparator: this.queueService.dateComparator,
        sort: 'asc',
        filter: true,
        resizable: true,
        width: 250,
        cellRenderer: params => {
          return this.queueService.formatTimestamp(params.value);
        }
      }


    ];

    
    this.frameworkComponents = {
      stagingFileDetailTriggerRenderer: StagingFileDetailTriggerComponent,
      filenameRenderer: FilenameCellComponent,
      fileDownloadRenderer: DownloadStagingFileCellComponent
    };
    

  } // end of constructor

  ngOnInit(): void {
    this.isAuthorizedToUpload = this.queueService.isAuthorized('uploadJobFiles'); // Can share this authorization
    this.errorStatuses = ["Failed"];
    if (this.queueService.isInitialQueueLoad) {
      let initialFilter = new FilterData({
        startDate: this.initialStartDate,
        endDate: this.initialEndDate,
        status: this.errorStatuses
      });
      //Introduce slight delay so health meter API call gets to act first
      let _this = this;
      setTimeout(() => {
        _this.getStagingFileData(initialFilter);
      }, 15);
    } else {
      this.getStagingFileData(new FilterData());
    }
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
    if (this.isFetchingData) {
      this.toggleStagingGridLoadStatus(true);
    }
  }

  retryStagingFilterOptions() {
    event.preventDefault();
    this.stagingFileFilterOptions.reInit();
  }

  toggleStagingGridLoadStatus(loading: boolean): void {
    if (this.gridApi) {
      loading ? this.gridApi.showLoadingOverlay() : this.gridApi.hideOverlay();
    }
  }

  getStagingFileData(filterData: FilterData) {
    this.selectedFileCount = 0;
    this.isFetchingData = true;
    this.showStagingTableError = false;
    this.toggleStagingGridLoadStatus(true);
    this.queueService.getStagingFileData(filterData)
      .subscribe(
        // Handle successful retrieval of the job records
        (response: any) => {
          this.isFetchingData = false;
          this.rowData = response.body.records;
          this.toggleStagingGridLoadStatus(false);
        },
        // Handle an error response
        error => {
          this.showStagingTableError = true;
          this.isFetchingData = false;
          this.toggleStagingGridLoadStatus(false);
        }
      )
  }

  toggleStagingFilterErrorState(filterError: boolean): void {
    this.showStagingFilterError = filterError;
  }

  onFileSelectionChanged(event: SelectionChangedEvent) {
    let sCount = event.api.getSelectedNodes().length;
    this.selectedFileCount = sCount ? sCount : 0;
  }

  addStagingFileDetailTab(idBundle: any) {
    this.addStagingFileTab.emit(idBundle);
  }


  downloadSelectedStagingFile(compoundFileId: string) {
    let idArray = compoundFileId.split("|^|");

    this.queueService.getStagingFiles(idArray[0])
      .subscribe(
        // Handle successful retrieval of the job .zip file
        (res: any) => {
          let filename = "fileId-" + idArray[0] + "-" + idArray[1] + ".zip";
          let success = this.queueService.generateDownloadedZipFile(filename, res.body);
          if (success) {
            this.snackBar.open("Download complete", "Close", { duration: 1000, panelClass: ['mdo-snack-msg'] });
          } else {
            this.snackBar.open("Download failed - please try again", "Close", { duration: 3000, panelClass: ['mdo-snack-msg-error'] });
          }

        },
        // Handle error response
        error => {
          this.snackBar.open("Download failed - please try again", "Close", { duration: 3000, panelClass: ['mdo-snack-msg-error'] });
        }
      )
  }

  openStagedFileUpdateModal(settings: any) {
    let updateContext: UpdateContext = {
      context: settings.context,
      action: settings.action,
      transactions: [],
      jobs: [],
      stagingFiles: []
    };
    let selections: any = this.gridApi.getSelectedNodes();
    selections.forEach((node: any) => {
      updateContext.stagingFiles.push({
        fileId: node.data.Id,
        filename: node.data.Filename,
        status: node.data.Status,
        partner: node.data.Partner,
        product: node.data.Product
      });
    });
    this.updateStagingFilesModal.openModal(updateContext);
  }

  refreshFromCache() {
    this.toggleStagingGridLoadStatus(true);
    this.rowData = this.queueService.returnCachedStagingFileRecords();
    this.gridApi.refreshCells();
    this.toggleStagingGridLoadStatus(false);
  }

  openFileUploadModal() {
    this.uploadFilesModal.openModal("Staging");
  }

}
