
/**
 * CPM Form
 * */
export class CPMElement {

  public Name: string;

  public Value: string;

}
