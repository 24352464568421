//Angular Imports
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatRadioModule } from "@angular/material/radio";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatNativeDateModule } from "@angular/material/core";
import { MatCheckboxModule } from "@angular/material/checkbox";

//Third Party Imports
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { AgGridModule } from "ag-grid-angular";
import { MyDatePickerModule } from "mydatepicker";
//Internal Imports
import { FormsListComponent } from "./components/formsListComponent";
import { FormsListRoutingModule } from "./formsList.routing";
import { FormsListService } from "./services";
import {
  FrameworkModule,
  DateFilterComponent,
  BooleanFilterComponent,
} from "../../framework";
import { UserNotesModule } from "../../user-notes/user-notes.module";
import { CustomPinnedRowRenderer } from "./components/formsListComponent/formsList.component.pinnedCellRenderer";
//import { LogsModule } from '../../logs/logs.module';
import { Extension } from "./components/extension";
import { Transactions } from "./components/transactions";
import { SelectedFormsComponent } from "./components/selectedForms/selectedForms.component";
import { AgGridDatePickerComponent } from "./components/transactions/edit-date.component";
import { AdjustFormsGridComponent } from "./components/adjustFormsGrid/adjustFormsGrid.component";
import { MatMenuModule } from "@angular/material/menu";

/**
  Forms Module
*/
@NgModule({
  declarations: [
    FormsListComponent,
    CustomPinnedRowRenderer,
    Extension,
    Transactions,
    SelectedFormsComponent,
    AdjustFormsGridComponent,
    AgGridDatePickerComponent,
  ],
  imports: [
    MyDatePickerModule,
    MatMenuModule,
    CommonModule,
    FormsListRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatRadioModule,
    NgSelectModule,
    FrameworkModule,
    UserNotesModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatCheckboxModule,
    AgGridModule.withComponents([
      DateFilterComponent,
      BooleanFilterComponent,
      CustomPinnedRowRenderer,
    ]),
  ],
  providers: [FormsListService],
})
export class FormsListModule {}
