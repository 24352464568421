<div class="modal-header">
  <h4 class="modal-title">{{ actionType }} Test Group</h4>
</div>

<div class="modal-body col-12">
  <!-- Name -->
  <div class="row">
    <div class="col-md-12 mrgbtm10">
      <label style="font-weight: bold">Name</label>
      <input
        id="txtName"
        style="width: 100%"
        required
        [(ngModel)]="testGroup.Name"
        #txtName="ngModel"
      />

      <div
        *ngIf="
          txtName.invalid && (txtName.dirty || txtName.touched || formSubmitted)
        "
      >
        <span *ngIf="txtName.errors.required" class="error_msg">
          <i class="error_icon"></i>Name is required
        </span>
      </div>
    </div>
  </div>

  <!-- Description -->
  <div class="row">
    <div class="col-md-12 mrgbtm10">
      <label style="font-weight: bold">Description</label>
      <textarea
        id="txtDescription"
        rows="2"
        style="width: 100%"
        [(ngModel)]="testGroup.Description"
        #txtDescription="ngModel"
      ></textarea>
    </div>
  </div>

  <!-- Type -->
  <div class="row">
    <div class="col-md-12 mrgbtm10">
      <label style="font-weight: bold">Release Tag</label>
      <textarea
        id="txtDescription"
        rows="2"
        style="width: 100%"
        [(ngModel)]="testGroup.Release"
        #txtDescription="ngModel"
      ></textarea>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn secondary_btn"
      (click)="activeModal.close()"
    >
      Cancel
    </button>
    <button type="button" class="btn primary_btn" (click)="saveTestGroup()">
      Save
    </button>
  </div>
</div>
