
export class ExportForm {
  public InternalFormNumber: string;
  public ExternalFormNumber: string;
  public FormName: string;
  public LOB: string;
  public EffectiveDate: string;
  public ExpirationDate: string;
  public SubmissionExpirationDate: string;
  public FormType: string;
  public WritingCompanies: string;
  public States: string;
  public IndustryTemplates: string;
}
