//Angular Imports 
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

//Internal Imports
import { OdenComponent } from './components/oden-component';
import { SplashComponent } from '../splash';

/**
  Form Routes
*/
export const routes: Routes = [
  {
    path: '', component: OdenComponent,
    children: [
      {
        path: '**',
        redirectTo: 'data',
        pathMatch: 'full'
      }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})

export class OdenRoutingModule {
}
