import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ColDef } from 'ag-grid-community';
import { ImportQueueService } from "../../services/import-queue.service";
import { UpdateContext } from "../../models/update-context";
import { JobUpdatePayload } from '../../models/job-update-payload';
import { UpdateJobsResponse } from '../../models/update-job-response';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserProfileService } from "../../../framework";
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-update-jobs',
  templateUrl: './update-jobs.component.html',
  styleUrls: ['./update-jobs.component.scss']
})
export class UpdateJobsComponent implements OnInit {

  private columnApi: any;
  updateContext: UpdateContext;
  actionOptions: any[] = [];
  columnDefs: ColDef[];
  pagination: boolean = false;
  errors: string[] = [];
  inProgress: boolean = false;
  hasUpdateFailures: boolean = false;
  selectedCount: number;
  failedCount: number;
  showAbortConfirmation: boolean = false;

  subs: Subscription[] = [];

  updateForm = new FormGroup({
    requestedAction: new FormControl<string>('', [Validators.required]),
    note: new FormControl<string>('', [Validators.required, Validators.maxLength(1000)]),
    confirmAction: new FormControl<boolean>(false)
  });

  @Output() triggerDetailUpdate = new EventEmitter();
  @Output() triggerQueueUpdate = new EventEmitter();

  @ViewChild('updateJobsModal') updateJobsModal: any;


  constructor(private queueService: ImportQueueService, private modalService: NgbModal, private userProfileService: UserProfileService, private snackBar: MatSnackBar) {
    this.columnDefs = [
      { field: 'jobId', headerName: "Job ID", sortable: true, filter: true, resizable: true, width: 400 },
      { field: 'status', headerName: "Current Status", sortable: true, filter: true, resizable: true },
      { field: 'partner', headerName: "Partner", sortable: true, filter: true, resizable: true },
      { field: 'product', headerName: "Product", sortable: true, filter: true, resizable: true },
      { field: 'errorMessage', headerName: "Error Message", sortable: true, filter: true, resizable: true, width: 400, hide: true },
    ];
  }

  ngOnInit(): void {
    this.startListeners();
  }

  startListeners(): void {
    let requestedActionChangeSub = this.updateForm.get("requestedAction").valueChanges.subscribe(val => {
      this.showAbortConfirmation = val == "Abort";
    });
    this.subs.push(requestedActionChangeSub);
  }

  ngOnDestroy(): void {
    this.subs.forEach((s: Subscription) => {
      s.unsubscribe();
    });
  }

  onGridReady(params: any): void {
    this.columnApi = params.columnApi;
  }

  dismissModal(): void {
    this.hasUpdateFailures = false;
    this.modalService.dismissAll();
  }

  openModal(updateContext: UpdateContext): void {
    this.updateContext = updateContext;
    this.performReset(updateContext);
    this.modalService.open(this.updateJobsModal, { backdrop: 'static', size: 'xl' });
  }

  performReset(updateContext: UpdateContext) {
    // Rebuild the action options and reset other state values
    this.actionOptions = [];
    this.actionOptions.push(
      { label: "Abort", value: "Abort", disabled: false, modelRequired: false }
    )

    
    // Certain actions are only allowed when a single job is selected
    if (updateContext.jobs.length == 1) {
      this.actionOptions.push(
        { label: "Resume", value: "Resume", disabled: false, modelRequired: false }
      )
    } else {
      this.actionOptions.push(
        { label: "Resume (single job only)", value: "Resume", disabled: true, modelRequired: false }
      )
    }
    

    this.inProgress = false;
    this.errors = [];
    this.updateForm.controls.requestedAction.reset('');
    this.updateForm.controls.note.reset('');
  }

  performUpdate(): void {
    
    this.inProgress = true;
    this.errors = [];
    let hasErrors = ((this.updateContext.action == "requestAction" && this.updateForm.controls.requestedAction.errors) || this.updateForm.controls['note'].errors);

    if (hasErrors) {

      if (this.updateContext.action == "requestAction" && this.updateForm.controls.requestedAction.errors && this.updateForm.controls.requestedAction.errors["required"] == true) {
        this.errors.push("You must select an action.");
      }
      if (this.updateForm.controls.note.errors && this.updateForm.controls.note.errors["required"] == true) {
        this.errors.push("You must enter a note.");
      }
      if (this.updateForm.controls.note.errors && this.updateForm.controls.note.errors["maxlength"]) {
        this.errors.push("Your note cannot be longer than " + this.updateForm.controls.note.errors["maxlength"].requiredLength + " characters.");
      }
      this.inProgress = false;

    } else if (this.updateForm.controls.requestedAction.value == "Abort" && this.showAbortConfirmation && this.updateForm.controls.confirmAction.value == false) {

      this.errors.push("Please confirm your intention to abort the selected job(s) in order to proceed.");
      this.inProgress = false;

    } else {

      let jobUpdatePayload: JobUpdatePayload = {
        jobIds: [],
        notes: this.updateForm.controls.note.value,
        user: this.userProfileService.UserName
      }

      if (this.updateContext.action == "requestAction") {
        jobUpdatePayload.action = this.updateForm.controls.requestedAction.value;
      }

      this.updateContext.jobs.forEach((item: any) => {
        jobUpdatePayload.jobIds.push(item.jobId);
      });

      this.queueService.updateJobs(jobUpdatePayload, this.updateContext.context)
        .subscribe(
          // Handle success response
          updateResponse => {

            //handle update submission error(s)
            let responseDict = new Map<string, UpdateJobsResponse>();
            updateResponse.body.UpdateJobsResponse.forEach((response: UpdateJobsResponse) => {
              responseDict.set(response.JobId, response);
            });

            let failedJobs: any[] = [];
            this.updateContext.jobs.forEach((job: any) => {
              const match = responseDict.get(job.jobId);
              if (match !== undefined && match.RequestStatus == "Failed Submission") {
                failedJobs.push({
                  ...job,
                  errorMessage: match.Reason
                })
              }
            });

            if (failedJobs.length !== 0) {

              this.inProgress = false;
              this.hasUpdateFailures = true;
              this.selectedCount = updateResponse.body.UpdateJobsResponse.length;
              this.failedCount = failedJobs.length;
              this.updateContext.jobs = failedJobs;
              this.columnApi.setColumnVisible('errorMessage', true);

            } else {

              if (this.updateContext.context == 'detail') {
                this.triggerDetailUpdate.emit(jobUpdatePayload);
              } else {
                this.triggerQueueUpdate.emit();
              }
              this.snackBar.open("Update successful", "Close", { duration: 1000, panelClass: ['mdo-snack-msg'] });
              this.inProgress = false;
              this.modalService.dismissAll();
            }
          },
          // Handle an error response
          error => {
            this.errors.push("The update attempt failed.  Please try again.");
            this.inProgress = false;
          }
        );

    }

  }


}
