// Angular Imports 
import { Component, Input } from '@angular/core';

// Third Party imports 
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

/**
 * Confirmation Modal Popup
 * */
@Component({
  selector: 'confirmation-modal',
  template: `
            <div class="modal-header">
              <h4 class="modal-title">{{dialog?.title}}</h4>
              <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <h5>{{dialog?.content}}</h5>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary custom-primary" (click)="activeModal.close(true)">{{dialog?.ok}}</button>
              <button type="button" class="btn btn-secondary"  *ngIf="dialog.cancel" (click)="activeModal.dismiss()">{{dialog?.cancel}}</button>
            </div>
`
})
export class ConfirmationModalComponent {
  // Public Variables
  /**
   * dialog
   **/
  private _dialog: any;

  /**
   * setter for dialog object
   * */
  @Input('dialog')
  set dialog(value: any) {
    this._dialog = value;
  }
  /**
   * getter for dialog object
   * */
  get dialog() {
    return this._dialog;
  }

  /**
   * @ignore
   */
  constructor(public activeModal: NgbActiveModal) {
    this._dialog = {};
  }

  // Angular Lifecycles
  /**
   * @ignore
   * */
  ngOnInit(): void {

  }

  /**
   * @ignore
   * */
  ngOnDestroy(): void{

  }
}
