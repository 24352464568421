
//Angular Imports 
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

//Third Party Imports
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { DeployPackage } from "../models/deployPackage.model";
import { RuleEngineForm } from "../../form-page/models/ruleEngineForm.model";
import { TestGroup } from "../../diagnostics/testSuites/execution";

/**
  * Forms Rules Mapping  Provider
*/
@Injectable()
export class DeployService {


  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient) {
  }

  //TODO: Change any to model when model is set 
  /**
   * Get All packages 
   */
  getPackages(): Observable<any[]> {
    let url = `api/PackageForms/packages`;
    return this.http.get<any[]>(url).pipe(
      map(res => res)
    );
  }

  /**
  * Add Deploy package forms in database
  * @param deployPackages
  */
  addDeployPackages(deployPackages: DeployPackage[]): Observable<DeployPackage[]> {
    let url = `api/PackageForms/deploy`;
    return this.http.post<DeployPackage[]>(url, deployPackages).pipe(
      map(res => res)
    );
  }

  adminAddDeployPackages(deployPackages: DeployPackage[]): Observable<DeployPackage[]> {
    let url = `api/PackageForms/adminDeploy`;
    return this.http.post<DeployPackage[]>(url, deployPackages).pipe(
      map(res => res)
    );
  }

  /**
 * Copy Deploy package forms in database
 * @param copyPackages
 */
  copyDeployPackages(packageToBeCopied: DeployPackage): Observable<DeployPackage> {
    let url = `api/PackageForms/copypackage`;
    return this.http.post<DeployPackage>(url, packageToBeCopied).pipe(
      map(res => res)
    );
  }

  updateDeployPackages(updatePackage: DeployPackage): Observable<DeployPackage> {
    let url = `api/PackageForms/updatepackage`;
    return this.http.post<DeployPackage>(url, updatePackage).pipe(
      map(res => res)
    );
  }

  adminCopyDeployPackages(packageToBeCopied: DeployPackage): Observable<DeployPackage> {
    let url = `api/PackageForms/admincopypackage`;
    return this.http.post<DeployPackage>(url, packageToBeCopied).pipe(
      map(res => res)
    );
  }

  /**
   * Check if the package needs to be deployed to any environment
   * @param packageData 
   */
  checkPackageDirty(packageData: DeployPackage): boolean {
    let isDirty = false;

    for (let env in packageData.EnvDeploymentStates) {
      if (packageData.EnvDeploymentStates[env]['ToBeDeployed']) {
        isDirty = true;
      }
    }

    return isDirty;
  }


  adminGetPackageForms(packageId: string, lob: string): Observable<RuleEngineForm[]> {
    let url = `api/PackageForms/AdminGetPackageForms?packageId=` + packageId + `&lob=` + lob;
    return this.http.get<RuleEngineForm[]>(url).pipe(
      map(res => res)
    );
  }

  getLOBTests(lob: string): Observable<TestGroup[]> {
    let url = `api/TestGroup/GetByLob?lob=` + lob;
    return this.http.get<TestGroup[]>(url).pipe(
      map(res => res)
    );
  }

}
