import { Component } from "@angular/core";
// import { ICellEditorAngularComp } from 'ag-grid-angular/main';
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common";
import { ICellEditorAngularComp } from "ag-grid-angular";

@Component({
  selector: "date-editor-cell",
  template: `
    <input type="date" name="newDate" [(ngModel)]="selectedDate" />
  `,
})
export class AgGridDatePickerComponent implements ICellEditorAngularComp {
  private params: any;

  public selectedDateString: string;
  public selectedDate: Date;


  agInit(params: any): void {
    this.params = params;
  }

  getValue(): any {
    // this.selectedDate = {
    //   date: { year: date.year, month: date.month + 1, day: "02" },
    // };
    //Build date string for extension format: yyyy-MM-DDT00:00:00
    var builder = new Date(this.selectedDate+"T00:00-0800");
    this.selectedDateString = "" + builder.getFullYear().toString() + "-";
    if (builder.getMonth() +1 < 10) {
      this.selectedDateString += "0" + (builder.getMonth()+1) + "-";
    }
    else {
      this.selectedDateString += (builder.getMonth() + 1) + "-";
    }
    if (builder.getDate() < 10) {
      this.selectedDateString += "0" + (builder.getDate());
    }
    else {
      this.selectedDateString += (builder.getDate());
    }
    this.selectedDateString += "T00:00:00";
    console.log(this.selectedDateString);
    return this.selectedDateString;
  }

  isPopup(): boolean {
    return true;
  }

  onDateSelect(date: NgbDate) {
    
    this.params.api.stopEditing();
  }
}
