<div class="add_copy_rule" id="packageReportContainer">
  <div class="modal-header">
    <h4 class="modal-title">Update CPM Data of Tests</h4>
  </div>

  <div class="row" style="margin: 0">
    <label style="font-weight:bold; margin-left:10px; margin-top:5px;">Policy Effective Date:</label>
    <input type="date" name="effDate" [(ngModel)]="selectedDate" style="margin-left:10px; border-radius:5px" />
  </div>

  <div class="modal-footer">
    <button type="button"
            class="mat_btn_hov_act mrgrgt10"
            mat-raised-button
            (click)="activeModal.close()">
      Cancel
    </button>
    <button type="button"
            mat-raised-button
            class="btn-teal"
            (click)="this.updateTests()">
      Continue
    </button>
  </div>
</div>
