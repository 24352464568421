//Internal Imports
import { PolicyFormFillin } from "./policyFormFillin.model";
import { BaseRepoItem } from '../../../framework';
import { UserNote } from '../../../user-notes/models'

/**
 * Policy Form MOdel 
 * */
export class PolicyForm extends BaseRepoItem {

  /**
   * FormId
   */
  public FormId: string;

  /**
   * LineOfBusiness
   */
  public LineOfBusiness: string[];

  /**
  * Policy Form Fill Ins
  */
  public FormFillIns: PolicyFormFillin[];

  /**
   * Condition
   */
  public Condition: string;

  /**
   * Filing
   */
  public Filing: string;

  /**
   * MultipleAttachmentIndicatorSpecified
   */
  public MultipleAttachmentIndicatorSpecified: boolean;

  /**
   * MultipleAttachmentIndicator
   */
  public MultipleAttachmentIndicator: number;

  /**
   * MultipleLOBIndicator
   */
  public MultiLOBIndicator: boolean;

  /**
   * MultipleAttachmentIndicator
   */
  public ManuscriptContent: string;

  /**
   * ManuscriptIndicatorSpecified
   */
  public ManuscriptIndicatorSpecified: boolean;

  /**
   * ManuscriptIndicator
   */
  public ManuscriptIndicator: number;

  /**
   * AttachmentReason
   */
  public AttachmentReason: string;

  /**
   * SpecimenURL
   */
  public SpecimenURL: string

  /**
   * SortOrderSpecified
   */
  public SortOrderSpecified: boolean;

  /**
   * SortOrder
   */
  public SortOrder?: number;

  /**
   * FormType
   */
  public FormType: string;

  /**
   * ExternalFormNumber
   */
  public ExternalFormNumber: string;

  /**
   * Edition
   */
  public Edition: string;

  /**
   * FormName
   */
  public FormName: string;

  public DocumentName: string;

  /**
   * InternalFormNumber
   */
  public InternalFormNumber: string;

  /**
   * FastKey
   */
  public FastKey: string;

  /**
  * Indicates Is Selected
  */
  public IsSelected: boolean;

  /*
   * Indicates to copy rules from existing form.
   */
  public IsCopyRules: boolean;

  /*
  * Indicates to copy rules from existing form.
  */
  public ExistingFormId: string;

  /**
  * New Note
  */
  public Note: UserNote;

  /**
 * Indicates Is frequently used
 */
  public IsFrequentlyUsed: boolean;

  public PackageName: string;
}
