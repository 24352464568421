//Angular Imports 
import { PipeTransform, Pipe } from '@angular/core';

//Third Party Imports
import * as moment from "moment";
import { Moment } from 'moment';


/**
  * Converts TimeSpan to Date 
*/
@Pipe({ name: 'dateTimePipe' })
export class DateTimePipe implements PipeTransform {

  /**
   * Constructor
   * @ignore
  */
  constructor() { }

  /**
   * Transform input to required output
   * For information on formats refer to moment documentation
   * @param {any} value timestamp value
   * @param {string} returnType specifies the whether to return string or moment
   * @param {string} format specifies the output format if it is string 
   * @returns {string | Moment} output value in format or moment object, default returns string in MM/DD/YYYY
  */
  transform(value: any, returnType: string = "string", format: string = "MM/DD/YYYY"): string | Moment {
    if (!value) return null;

    let returnValue: any = moment.unix(value);

    if (returnType == "moment")
      return returnValue;

    returnValue = returnValue.format(format);

    return returnValue;
  }
}
