//Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiagnosticsRoutingModule } from './diagnostics.routing';
import { HeaderComponent, SidebarComponent, DiagnosticsMenuComponent, MenuItemComponent, DiagnosticsComponent } from './components';
import { SidebarContentViewer } from './directives';
import { DGMaterialComponentModule } from './dgMaterial.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { LayoutModule } from '../../@vex/layout/layout.module';
import { SidenavModule } from '../../@vex/layout/sidenav/sidenav.module';
import { ToolbarModule } from '../../@vex/layout/toolbar/toolbar.module';
import { ConfigPanelModule } from '../../@vex/components/config-panel/config-panel.module';
import { SidebarModule } from '../../@vex/components/sidebar/sidebar.module';
import { QuickpanelModule } from '../../@vex/layout/quickpanel/quickpanel.module';
import { MatIconModule } from '@angular/material/icon';

//Internal Imports

/**
  Main Layout Module
*/
@NgModule({
  declarations: [
    DiagnosticsComponent,
    HeaderComponent,
    SidebarComponent,
    DiagnosticsMenuComponent,
    MenuItemComponent,
    SidebarContentViewer
  ],
  imports: [
    CommonModule,
    DiagnosticsRoutingModule,
    MatExpansionModule,
    LayoutModule,
    SidenavModule,
    ToolbarModule,
    ConfigPanelModule,
    SidebarModule,
    QuickpanelModule,
    MatIconModule
  ],
  providers: []
})
export class DiagnosticsModule { }
