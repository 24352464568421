//Angular Imports 
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';


//Internal Imports
import { ProductsComponent } from './components/products.component';

/**
  Form Routes
*/
export const routes: Routes = [
  {
    path: '', component: ProductsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})

export class ProductsRoutingModule {
}
