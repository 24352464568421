<div (mouseenter)="collapseOpenSidenav()"
     (mouseleave)="collapseCloseSidenav()"
     [class.collapsed]="collapsed"
     [class.open]="collapsed && collapsedOpen$ | async"
     class="sidenav tw-flex tw-flex-col">
  <div class="vex-sidenav-toolbar__container">
    <div class="sidenav-toolbar tw-flex-none tw-flex tw-items-center">
      <img [src]="imageUrl$ | async" alt="Logo" class="tw-w-10 tw-select-none tw-flex-none" />
      <h2 class="vex-sidenav-toolbar__headline tw-flex-auto">{{ title$ | async }}</h2>
      <button (click)="toggleCollapse()"
              *ngIf="showCollapsePin$ | async"
              class="tw-w-8 tw-h-8 tw--mr-2 tw-leading-none tw-flex-none tw-hidden lg:tw-block"
              mat-icon-button
              type="button" style="bottom:3px;">
        <mat-icon *ngIf="!collapsed"
                  class="text-[0.875rem] w-[0.875rem] h-[0.875rem]"
                  svgIcon="mat:radio_button_checked"></mat-icon>
        <mat-icon *ngIf="collapsed"
                  class="text-[0.875rem] w-[0.875rem] h-[0.875rem]"
                  svgIcon="mat:radio_button_unchecked"></mat-icon>
      </button>
    </div>

    <!--<div *ngIf="searchVisible$ | async" class="vex-sidenav-search__container">
    <div class="vex-sidenav-search tw-relative"
         (click)="openSearch()"
         matRipple
         matRippleColor="var(--sidenav-item-ripple-color)">
      <mat-icon class="tw-flex-none vex-sidenav-search__icon" svgIcon="mat:search"></mat-icon>
      <div class="tw-flex-auto vex-sidenav-search__placeholder">Quick Search</div>
      <div class="tw-flex-none vex-sidenav-search__keybind">Ctrl K</div>
    </div>
  </div>-->

    <div class="vex-sidenav-toolbar__divider"></div>
  </div>

  <vex-scrollbar class="tw-flex-1 tw-flex tw-flex-col">
    <div class="tw-pt-6 tw-flex-1">
      <vex-sidenav-item *ngFor="let item of items; trackBy: trackByRoute"
                        [item]="item"
                        [level]="0"></vex-sidenav-item>
    </div>

    <div class="vex-sidenav-footer tw-flex tw-items-center tw-justify-center tw-opacity-20 tw-px-6 tw-py-6 tw-select-none tw-flex-none">
      <img [src]="imageUrl$ | async" alt="Logo" class="tw-w-12 tw-select-none" />
    </div>
  </vex-scrollbar>

  <!--<div *ngIf="userVisible$ | async"
       class="vex-sidenav-user__container tw-flex-none">
    <div #userProfileMenuOriginRef
         (click)="openProfileMenu(userProfileMenuOriginRef)"
         [class.vex-sidenav-user--open]="userMenuOpen$ | async"
         class="vex-sidenav-user tw-flex tw-items-center"
         matRipple
         matRippleColor="var(--sidenav-item-ripple-color)">
      <img alt="User Avatar" class="vex-sidenav-user__image tw-flex-none" src="assets/img/avatars/1.jpg"/>
      <div class="vex-sidenav-user__content tw-flex-auto">
        <div class="vex-sidenav-user__title">David Smith</div>
        <div class="vex-sidenav-user__subtitle">Product Manager</div>
      </div>
      <mat-icon class="vex-sidenav-user__dropdown-icon tw-flex-none" svgIcon="mat:arrow_drop_down"></mat-icon>
    </div>
  </div>-->
</div>
