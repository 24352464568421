<div class="modal-header">
  <h4 class="modal-title">New Rule Setup</h4>
</div>

<div class="modal-body col-12">
  <!-- LOBs -->
  <div class="row">
    <div id="lob-grid-container" class="col-12" style="padding-bottom: 10px">
      <div style="padding-bottom: 5px">
        <b *ngIf="Action != 'View'"
          >Select Lines of Business for new Rule Setup</b
        ><b *ngIf="Action == 'View'">View LOB Codes and Descriptions </b>
      </div>
      <ag-grid-angular
        class="ag-theme-balham fast_panel_table"
        [gridOptions]="lobGridOptions"
        style="height: 200px; width: 100% !important"
      >
      </ag-grid-angular>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="mat_btn_hov_act mrgrgt10"
    mat-raised-button
    (click)="activeModal.close()"
  >
    Cancel
  </button>
  <button
    type="button"
    mat-raised-button
    class="btn-teal"
    (click)="addRuleSetup()"
    *ngIf="Action != 'View'"
  >
    Save
  </button>
</div>
