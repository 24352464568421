<div class="add_copy_rule" id="packageReportContainer">
  <div class="modal-header">
    <h4 class="modal-title">Deployment Confirmation</h4>
  </div>

  <div class="row" style="margin: 0">
    <div id="test-group-grid-container" class="col-md-12" style="padding: 0">
      <div style="margin: 10px"><b>Packages</b></div>
      <ag-grid-angular
        class="ag-theme-balham fast_panel_table"
        [gridOptions]="packageReportGridOptions"
        style="height: calc(100vh - 200px); padding: 5px"
      >
      </ag-grid-angular>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="mat_btn_hov_act mrgrgt10"
      mat-raised-button
      (click)="activeModal.close()"
    >
      Cancel
    </button>
    <button
      type="button"
      mat-raised-button
      class="btn-teal"
      (click)="this.createReport()"
    >
      Continue
    </button>
  </div>
</div>
