//Angular Imports 
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

//Third Party Imports 
import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridModule } from 'ag-grid-angular';

//Internal Imports 
import { FrameworkModule, BooleanFilterComponent } from '../../framework';
import { MGDReportsComponent } from './components/MGDReports.component';
import { MGDReportsRoutingModule } from './MGDReports.routing';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule, MatListOption, MatSelectionList } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';

/**
  Forms Module
*/
@NgModule({
  declarations: [
    MGDReportsComponent
  ],
  imports: [
    MatCardModule,
    MatDividerModule,
    MatTabsModule,
    MatListModule,
    MGDReportsRoutingModule,
    CommonModule,
    FormsModule,
    NgSelectModule,
    FrameworkModule,
    AgGridModule.withComponents([])
  ],
  providers: [

  ]
})
export class MGDReportsModule { }
