//Angular Imports 
import { Component, HostBinding } from '@angular/core';

//Third Party Imports 
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


//Internal Imports 

import { AppInsightsService, EnvironmentService, DateTimeUtcPipe, SharedService, ApplicationInsightsBaseComponent, CPMElement } from '../../../framework';
import { ConvertService } from '../services/convert.service';
import { tap } from 'rxjs';
import { PDFConvertDocs } from '../models/pdfConvertDocs';


/**
 * Forms Component
*/
@Component({
  selector: 'app-convert',
  templateUrl: './convert.component.html',
  styleUrls: ['./convert.component.scss'],
  providers: [ConvertService]
})

export class ConvertComponent {

  @HostBinding('style.width') width: string;

  initialRowDataLoad$;
  http: any;
  datalocalURL: string;
  public pdfs: FormData;
  public pdfDocStrings: PDFConvertDocs = new PDFConvertDocs();
  public fileeeee: File[] = [];
  public form: FormData;


  /** 
   * Constructor
   * @ignore
  */
  constructor(private toastr: ToastrService,
    private _modal: NgbModal,
    private convertService: ConvertService,
    private sharedService: SharedService,
    private dateTimePipe: DateTimeUtcPipe,
    private environmentService: EnvironmentService
  ) {
    this.width = "100%";
  }
  showPDF() {
    const pdfUrl = 'http://example.com/myfile.pdf';

  }
  showPDfboolean = false;

  getWordFile(): void {
    this.convertService.convertDocs(this.fileeeee).subscribe(res => {
      const byteCharacters = atob(res);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const pdfBlob = new Blob([byteArray]);
     // this.datalocalURL = window.URL.createObjectURL(pdfBlob);
     // document.querySelector("iframe").src = this.datalocalURL;
      this.downloadForm(pdfBlob);
    })
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
  */
  ngOnInit(): void {

  }

  /**
   * NgOnDestroy
   * @ignore
  */
  ngOnDestroy(): void {

  }

  downloadForm(res: Blob){
    {
      var fileName = "Documents.zip";

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(res, fileName);
      } else {
        var downloadURL = window.URL.createObjectURL(res);
        var link = document.createElement("a");
        link.href = downloadURL;
        link.download = fileName;
        link.click();
      }

      /** 
      for (var file = 0; file < this.fileeeee.length; file++) {
        var fileName = this.fileeeee[file].name;
        if (fileName.endsWith(".docx")) {
          fileName = fileName.replace(".docx", ".pdf");
        }
        else if (fileName.endsWith(".doc")) {
          fileName = fileName.replace(".doc", ".pdf");
        }
        else if (fileName.endsWith(".pdf")) {
          fileName = fileName.replace(".pdf", ".docx");
        }
        const byteCharacters = atob(res);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        let blob: Blob;
        if (fileName.endsWith(".docx")) {
          blob = new Blob([byteArray], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
        }
        else if (fileName.endsWith(".doc")) {
          blob = new Blob([byteArray], {
            type: "application/msword",
          });
        }
        else if (fileName.endsWith(".pdf")) {
          blob = new Blob([byteArray], {
            type: "application/pdf",
          });
        }
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          var downloadURL = window.URL.createObjectURL(blob);
          var link = document.createElement("a");
          link.href = downloadURL;
          link.download = fileName;
          link.click();
        }
      }
      **/
    }
    (err) => {
      console.log(err);
      this.toastr.error(err.error, "Ghostdraft Generation Error");
    }
    
  }

  onFileChangeA(event: any) {
    for (var file of event.target.files) {
      console.log(file);
      var fileName = String(file.name);
      if (fileName.endsWith(".docx") || fileName.endsWith(".doc") || fileName.endsWith(".pdf")) {
        this.fileeeee.push(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
        };
      }
      else {
        this.toastr.error("Invalid File Extension", "Error Uploading File", { positionClass: 'toast-bottom-right' });
      }
    }
  }
}
