//Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplashRoutingModule } from './splash.routing';
import { SplashComponent } from './components/splash.component';
import { FormsModule } from '@angular/forms';
import { LayoutModule } from '../../@vex/layout/layout.module';
import { SidenavModule } from '../../@vex/layout/sidenav/sidenav.module';
import { ToolbarModule } from '../../@vex/layout/toolbar/toolbar.module';
import { FooterModule } from '../../@vex/layout/footer/footer.module';
import { ConfigPanelModule } from '../../@vex/components/config-panel/config-panel.module';
import { SidebarModule } from '../../@vex/components/sidebar/sidebar.module';
import { QuickpanelModule } from '../../@vex/layout/quickpanel/quickpanel.module';
import { PopoverService } from '../../@vex/components/popover/popover.service';
import { MatIconModule } from '@angular/material/icon';

//Internal Imports


/**
  Main Layout Module
*/
@NgModule({
  declarations: [
    SplashComponent
  ],
  imports: [
    CommonModule,
    SplashRoutingModule,
    FormsModule,
    LayoutModule,
    SidenavModule,
    ToolbarModule,
    FooterModule,
    ConfigPanelModule,
    SidebarModule,
    QuickpanelModule,
    MatIconModule
  ],
  providers: [PopoverService]
})
export class SplashModule { }
