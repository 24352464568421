import { DateTime } from "luxon";

export class ETaskRequest {
  public SourceSystem: string;

  public LineOfBusiness: string;

  public CoverageNames: string;

  public RegionalContact: string;

  public DealType: string;

  public DealNumber: string;

  public DealStatus: string;

  public Description: string;

  public ExpirationDate: string;

  public InceptionDate: string;

  public PolicyNumber: string;

  public UnderwritingTeam: string;

  public UniqueNumber: string;

  public TaskType: "Import Locations";

  public Broker: BrokerInfo;

  public Insured: InsuredInfo;


}

export class BrokerInfo {

  public BrokerCity: string;

  public BrokerContact: string;

  public BrokerCountry: string;

  public BrokerName: string;

  public BrokerState: string;

  public BrokerStreet: string;

  public BrokerStreet2: string;

  public BrokerType: string;

  public BrokerZip: string;

  public BrokerContactEmail: string;

  public AMSCode: string;

}

export class InsuredInfo {
  public Auditable: string;

  public AuditFrequency: string;
}

