import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { catchError, map } from 'rxjs/operators';
import { SkippableInterceptorHeader } from "../../../../framework/enums/skippable-interceptor-header.enum";
import { SearchFilterData } from "../models/search-filter-data";

@Injectable()
export class OdenService {
  formatTimestamp(Timestmp: number): string {
    // Convert the Unix timestamp to a Date object
    const date = new Date(Timestmp);
    // Format the date and time in the user's locale
    return date.toLocaleString();
  }

  private searchFilterData = new BehaviorSubject<SearchFilterData>(null);
  currentSearchFilterData = this.searchFilterData.asObservable();

  stateData: any = {
    microserviceOptions: [],
    logMetrics: []
  }

  constructor(private http: HttpClient) { }

  handleHTTPError(error: HttpErrorResponse) {
    return throwError(() => error);
  }

  generateRequestHeaders(mockHeaderValue?: string): HttpHeaders {
    let headers = new HttpHeaders();
    // Because HttpHeaders is immutable, any action to add or alter a header returns a new instance that has to be reassigned to the variable
    headers = headers.set(SkippableInterceptorHeader.ShowLoaderSkipHeader, "true");
    if (mockHeaderValue) {
      headers = headers.set("x-mock-response", mockHeaderValue);
    }
    return headers;
  }

  getSearchFilterOptions(): Observable<any> {
    // Only perform an HTTP call if we don't already have the microservice options
    if (Object.keys(this.stateData.microserviceOptions).length == 0) {
      let getUrl = 'api/logmetrics/get-search-filter-options';

      return this.http.get<any>(getUrl, { observe: "response", headers: this.generateRequestHeaders() })
        .pipe(
          map(res => {
            // Handle a success response
            this.stateData.microserviceOptions = res.body.microservices;
            return res.body;
          }),
          // Handle an error response
          catchError(this.handleHTTPError)
        );
    } else {
      // Return an observable of the cached filter option data
      return of(this.stateData.microserviceOptions);
    }

  }

  retrieveLogMetrics(data: any): Observable<any> {
    let postUrl = 'api/logmetrics/retrieve-log-metrics';

    return this.http.post<any>(postUrl, data, { observe: "response", headers: this.generateRequestHeaders() })
      .pipe(
        map(res => {
          // Handle a success response
          this.stateData.logMetrics = res.body.LogMetrics;
          return res.body;
        }),
        // Handle an error response
        catchError(this.handleHTTPError)
      );   
  }

  changeSearchFilterData(data: SearchFilterData) {
    this.searchFilterData.next(data);
  }

}
