import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";


//Third Party Imports
import * as _ from 'lodash';

//Internal Imports
import { SkippableInterceptorHeader } from "../../framework/enums/skippable-interceptor-header.enum";
import { EnvironmentService } from "../../framework/services/environment.service";
import { EXECUTION_ENGINE_LIST } from "../../report/configs";

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  //Private Variables
  /**
   * List of tokens based on environment
   */
  private _tokenList: any;

  /**
   * List of environments and urls
  */
  private _envList: any;

  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient,
    private envService: EnvironmentService) {
    this._envList = EXECUTION_ENGINE_LIST;
    this._tokenList = {};
  }

  /**
   * Get token
   * @param selectedEnv Environment
   * */
  getToken(selectedEnv: string): Observable<any> {

    let index = _.findIndex(this._envList, { Name: selectedEnv });
    let environment = this._envList[index];
    if (this._tokenList && this._tokenList[environment.Name]) {
      return of(this._tokenList[environment.Name]);
    }
    else {
      //Set the headers
      let headers = new HttpHeaders().set(SkippableInterceptorHeader.RequestSkipHeader, 'true')
        .set(SkippableInterceptorHeader.JWTSkipHeader, 'true');

      let envDetails = this.envService.environmentDetails
      //let url = `${environment.URL}/api/Token?apiKey=rB<aO-xS^a<HW]-=g&clientURL=${envDetails.ORIGIN}`;

      let url = '';

      if (selectedEnv === 'PROD') {
        url = `${environment.URL}/api/Token?apiKey=xYrHk8@xj3=pFe&clientURL=${envDetails.ORIGIN}`;
      }
      else {
        url = `${environment.URL}/api/Token?apiKey=rB<aO-xS^a<HW]-=g&clientURL=${envDetails.ORIGIN}`;
      }

      return this.http.get<any>(url, { headers: headers }).pipe(
        map(res => {
          this._tokenList[environment.Name] = <string>res.token;
          return res;
        }));
    }

  }

  RunReport(collectionName: string, query: string, db: string, selectedEnv: string): Observable<any[]> {

    if (collectionName === null || collectionName === "") {
      collectionName = this.GetCollectionName(query);
    }

    if (db === "Admin") {
      let url = "api/Report?collectionName=" + collectionName + "&query=" + query;
      return this.http.get<any[]>(url).pipe(
        map(res => res)
      );
    } else {
      // Execute against the rules database
      //this.getT();
      let index = _.findIndex(this._envList, { Name: selectedEnv });
      let environment = this._envList[index];

      //Set the headers
      let headers = new HttpHeaders().set(SkippableInterceptorHeader.RequestSkipHeader, 'true')
        .set(SkippableInterceptorHeader.JWTSkipHeader, 'true')
        .set("Authorization", `Bearer ${this._tokenList[environment.Name]}`);
        //.set("Content-Type", "text/xml");

      let url = `${environment.URL}/api/Report?collectionName=` + collectionName + "&query=" + query;
      return this.http.get<any[]>(url, { headers: headers }).pipe(
        map(res => res)
      );
    }
    
  }

  private GetCollectionName(query: string): string {
    //let fromIndex = query.toUpperCase().indexOf("FROM");
    //let startIndex = fromIndex + 5;
    //let endIndex = query.substring(startIndex, query.length);

    let words: string[] = query.split(" ");
    for (var i = 0; i < words.length; i++) {
      if (words[i].toUpperCase() === "FROM") {
        return words[i + 1];
      }
    }

    return "";
  }

}
