<div class="container-fluid outer-box">

  <div class="row">
    <div class="col-md-3">
      <h4 class="filter-title">Filter Options</h4>
    </div>
    <div class="col-md-9">
      <div id="search-result-msg" *ngIf="searchResultMsg">{{searchResultMsg}}</div>
      <div id="search-error-alert" class="alert dm-alert-danger" *ngIf="showFilterError">
        There was an error with populating the filter options.  Click <a class="dm-alert-danger-link" href="" (click)="retryFilterOptions($event)">here</a> to re-request the filter options.
      </div>
      <div id="search-error-alert" class="alert dm-alert-danger" *ngIf="showSearchError">
        There was an error with performing the search.  Please try again.
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3 search-column">
      <app-log-search-filter #searchFilterOptions (toggleSearchFilterError)="toggleFilterError($event)"></app-log-search-filter>
    </div>
    <div class="col-md-9">
      <ag-grid-angular [hidden]="showSearchError" id="searchResultsGridGrid"
                       style="width: 100%; height: 400px;"
                       class="ag-theme-balham mdo-standard-grid"
                       [rowData]="rowData"
                       [columnDefs]="columnDefs"
                       (gridReady)="onGridReady($event)"
                       [frameworkComponents]="frameworkComponents"
                       [gridOptions]="gridOptions"
                       [pagination]="pagination"
                       [paginationAutoPageSize]="paginationAutoPageSize">

      </ag-grid-angular>
    </div>
  </div>


</div>

