import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ImportQueueService } from '../../services/import-queue.service';

@Component({
  selector: 'app-update-staging-file-menu',
  templateUrl: './update-staging-file-menu.component.html'
})
export class UpdateStagingFileMenuComponent implements OnInit {
  @Input() singleFile: boolean = false;
  @Input() selectedFileCount: number = 1;
  @Input() context: string = "stagingFile";

  @Output() triggerStagingFileUpdateModal = new EventEmitter();

  constructor(public queueService: ImportQueueService) { }

  ngOnInit(): void {
  }

  isAuthorized(): boolean {
    return this.queueService.isAuthorized('requestAction');
  }

  performAction(action: string) {
    this.triggerStagingFileUpdateModal.emit({ context: this.context, action: action });
  }

}
