import { Component } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
  selector: "floating-cell",
  template: `<span style="color:#ff4e00 !important; font-weight: bold;">{{
    params.value
  }}</span>`,
})
export class StyledComponent implements ICellRendererAngularComp {
  public params: any;
  public style: string;

  agInit(params: any): void {
    this.params = params;
    this.style = this.params.style;
  }

  refresh(): boolean {
    return false;
  }
}
