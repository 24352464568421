import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-download-item-cell',
  templateUrl: './download-item-cell.component.html',
  styleUrls: ['./download-item-cell.component.scss']
})
export class DownloadItemCellComponent {
  @Input() downloadContext: string;
  @Input() itemId: string;
  @Input() componentParent: any;
  
  constructor() { }

  downloadItem(downloadContext: string, itemId: string) {
    event.preventDefault();
    // To prevent the row selection event from taking place and inadvertently toggling the selection checkbox
    event.stopPropagation();
    if (downloadContext == "job") {
      this.componentParent.downloadSelectedJobFiles(
        this.itemId
      );
    } else if (downloadContext == "stagingFile") {
      this.componentParent.downloadSelectedStagingFile(
        this.itemId
      );
    }
  }

}
