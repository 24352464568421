//Angular Imports
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
} from "@angular/core";

//Third Party Imports
import { GridOptions } from "ag-grid-community";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { Clipboard } from "@angular/cdk/clipboard";

/**
 * Forms Component
 */
@Component({
  selector: "testCaseError",
  templateUrl: "testCaseError.component.html",
  styleUrls: ["testCaseError.component.scss"],
})
export class TestCaseError {
  @Input() errorText: string;

  constructor(
    public activeModal: NgbActiveModal,
    private _modal: NgbModal,
    private toastr: ToastrService,
    private clipboard: Clipboard
  ) {}

  ngOnInit(): void {
    console.log(this.errorText);
  }

  /**
   * NgOnDestroy
   * @ignore
   */
  ngOnDestroy(): void {}

  public copyErrorText() {
    this.clipboard.copy(this.errorText);
  }
}
