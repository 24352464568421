<!-- Dashboard component -->
<div class="container-fluid outer-box">
  <div class="row">
    <div class="col-md-3">
      <mat-selection-list #dashMenu [multiple]="false">
        <mat-list-option *ngFor="let item of dashboardItems" [value]="item['dashId']" [selected]="item['default']">
          <p [ngClass]="{'dashboard-list-indent': item['subitem'] == true}">{{ item.name }}</p>
        </mat-list-option>
      </mat-selection-list>
    </div>
    <div class="col-md-9">
      <div [ngClass]="{'dash-item-block': true, 'dash-item-hidden' : dashMenu.selectedOptions.selected[0]?.value != 'healthMeter'}">
        <app-health-meter></app-health-meter>
      </div>
      <div [ngClass]="{'dash-item-block': true, 'dash-item-hidden' : dashMenu.selectedOptions.selected[0]?.value != 'partnerProductAudit'}">
        <app-partner-product-audit #partnerProductAudit></app-partner-product-audit>
      </div>
      <div [ngClass]="{'dash-item-block': true, 'dash-item-hidden' : dashMenu.selectedOptions.selected[0]?.value != 'partnerStats'}">
        <app-partner-statistics #partnerStats></app-partner-statistics>
      </div>
      <div [ngClass]="{'dash-item-block': true, 'dash-item-hidden' : !isVisible(dashMenu.selectedOptions, 'policyTransactionsPerMonth')}">
        <div class="chart-container">
          <app-chart-display [showChartingError]="showChartingError"
                             [isLoadingChartingData]="isLoadingChartingData"
                             [chart]="charts['policyTransactionsPerMonth']"
                             (retryChartData)="retryCharts()">
          </app-chart-display>
        </div>
      </div>
      <div [ngClass]="{'dash-item-block': true, 'dash-item-hidden' : !isVisible(dashMenu.selectedOptions, 'claimTransactionsPerMonth')}">
        <div class="chart-container">
          <app-chart-display [showChartingError]="showChartingError"
                             [isLoadingChartingData]="isLoadingChartingData"
                             [chart]="charts['claimTransactionsPerMonth']"
                             (retryChartData)="retryCharts()">
          </app-chart-display>
        </div>
      </div>
      <div [ngClass]="{'dash-item-block': true, 'dash-item-hidden' : !isVisible(dashMenu.selectedOptions, 'premiumPartner')}">
        <div class="chart-container">
          <app-chart-display [showChartingError]="showChartingError"
                             [isLoadingChartingData]="isLoadingChartingData"
                             [chart]="charts['premiumPerPartnerYTD']"
                             (retryChartData)="retryCharts()">
          </app-chart-display>
        </div>
      </div>
      <div [ngClass]="{'dash-item-block': true, 'dash-item-hidden' : !isVisible(dashMenu.selectedOptions, 'amountPaidPartner')}">
        <div class="chart-container">
          <app-chart-display [showChartingError]="showChartingError"
                             [isLoadingChartingData]="isLoadingChartingData"
                             [chart]="charts['amountPaidPartner']"
                             (retryChartData)="retryCharts()">
          </app-chart-display>
        </div>
      </div>
    </div>
   
  </div>
</div>


