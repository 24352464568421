<!-- Display note field embedded in another form -->
<div *ngIf="!popupView">
  <form [formGroup]="formGroup">
    <label style="font-weight: bold; margin-right: 22px">Note:</label>
    <textarea
      id="txtNoteText"
      style="width: 80%"
      rows="4"
      formControlName="noteText"
      pattern="^[(a-zA-Z0-9&.') /\\-]+$"
    ></textarea>
  </form>
</div>

<!-- Display note field in a popup -->
<div *ngIf="popupView">
  <div class="modal-header">
    <h4 class="modal-title">Add Note</h4>
  </div>
  <form [formGroup]="formGroup">
    <div class="modal-body col-12">
      <div class="row">
        <label style="font-weight: bold; margin-right: 22px">Note:</label>
        <textarea
          id="txtNoteText"
          style="width: 80%"
          rows="4"
          formControlName="noteText"
          pattern="^[(a-zA-Z0-9&.') /\\-]+$"
        ></textarea>
      </div>
    </div>
  </form>
  <div class="modal-footer">
    <button
      type="button"
      class="btn secondary_btn"
      (click)="saveNote(linkedForms, linkedRules, category)"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn primary_btn"
      (click)="saveNote(linkedForms, linkedRules, category)"
    >
      Save
    </button>
  </div>
</div>
