import { formatDate } from "@angular/common";
export class PartnerProductAudit {
  id: string;
  fullname: string;
  partner: string;
  product: string;
  pauseStatus: string;
  lastUpdatedRaw: string;
  lastUpdated: string;
  lastUpdatedBy: string;
  history: any[] = [];

  needsConfirmation: boolean = false;
  isProcessing: boolean = false;
  updateErrorMsg: string = "";

  constructor(objectData?: any) {
    if (objectData) {
      this.id = objectData.partnerProductID || "";
      this.partner = objectData.jP_PartnerId || "";
      this.product = objectData.productId || "";
      this.fullname = (this.partner && this.product) ? (this.partner + " - " + this.product) : "";
    }  
  }

  addHistory(objectData: any): void {
    this.history.push(
      {
        pauseStatus: objectData.pausedJobFlag == 1 ? "Paused" : "Active",
        lastUpdatedRaw: objectData.updatedDateTime ? objectData.updatedDateTime : "0",
        lastUpdated: objectData.updatedDateTime ? this.formatTimestamp(objectData.updatedDateTime) : "Initial entry",
        lastUpdatedBy: objectData.updatedBy || ""
      }
    )
  }

  updateStatus(username: string): void {
    let updatedOn = new Date();
    // Move previous values into new history record
    this.history.unshift(
      {
        pauseStatus: this.pauseStatus,
        lastUpdatedRaw: "", // Not relevant 
        lastUpdated: this.lastUpdated,  
        lastUpdatedBy: this.lastUpdatedBy
      }
    );

    // Update current status
    this.pauseStatus = this.pauseStatus == "Active" ? "Paused" : "Active";
    this.lastUpdatedRaw = "";
    this.lastUpdated = formatDate(new Date(), 'MM/dd/yyyy h:mm:ss a', 'en-US') + " UTC"
    this.lastUpdatedBy = username;
  }

  organizeHistory(): void {
  
    this.history.sort((a, b) => {

      if (a.lastUpdatedRaw < b.lastUpdatedRaw) {
        return 1;
      }
      if (a.lastUpdatedRaw > b.lastUpdatedRaw) {
        return -1;
      }
      return 0;
    });

    this.pauseStatus = this.history[0].pauseStatus;
    this.lastUpdatedRaw = this.history[0].lastUpdatedRaw;
    this.lastUpdated = this.history[0].lastUpdated;
    this.lastUpdatedBy = this.history[0].lastUpdatedBy;
    this.history.shift();
  }

  formatTimestamp(dateValue: any): string {
    if (!dateValue) {
      return 'NA';
    }
    if (isNaN(Date.parse(dateValue))) {
      return (dateValue + " UTC");
    }
    //Remove any Z at the end of the date string so it won't be converted to local time.
    let staticDate = dateValue.replace(/Z$/, "");
    let date = new Date(Date.parse(staticDate));
    return (formatDate(date, 'MM/dd/yyyy h:mm:ss a', 'en-US') + " UTC");
  }

}
