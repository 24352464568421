//Angular Imports
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

//Third Party Imports
import { AgGridModule } from "ag-grid-angular";
import { NgSelectModule } from "@ng-select/ng-select";

//Internal Imports
import { FrameworkModule, BooleanFilterComponent } from "../../framework";

import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatButtonModule } from "@angular/material/button";
import { MigrationComponent } from "./components/migration.component";
import { MigrationRoutingModule } from "./migration.routing";
import { MigrationService } from "./services/migration.service";
import { MatTabsModule } from "@angular/material/tabs";
import { MatAccordion, MatExpansionModule } from "@angular/material/expansion";

/**
  Forms Module
*/
@NgModule({
  declarations: [MigrationComponent],
  imports: [
    MigrationRoutingModule,
    CommonModule,
    FormsModule,
    NgSelectModule,
    FrameworkModule,
    MatButtonModule,
    AgGridModule.withComponents([BooleanFilterComponent]),
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatExpansionModule
  ],
  providers: [MigrationService],
})
export class MigrationModule { }
