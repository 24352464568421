import { DateTime } from "luxon";

export class TransactionResponse {
  public TransactionId: string;

  public PolicyNumber: string;

  public PolicyEffectiveDate: string;

  public PolicyExpirationDate: string;

  public CreatedOn: DateTime;

  public NamedInsured: string;

  public DealNumber: string;

  public InsuredDBA: string;

  public InsuredAddress: string;

  public InsuredAddress2P: string;

  public InsuredAddress3P: string;

  public InsuredCity: string;

  public InsuredState: string;

  public InsuredZip: string;

  public InsuredCountry: string;

  public ProducerName: string;

  public PolicyState: string;

  public AuthorizedRepName: string;

  public AuthorizedRepSurname: string;

  public AuthorizedRepTitle: string;

  public WritingCompanyCode: string;

  public WritingCompanyName: string;

  public Forms: string[];

  public UserName: string;

  public IssuedOn: DateTime;

  public IsIssued: boolean;

  public IsRenewal: boolean = false;
  public rowLabel: string = "Renew";
}
