export class SearchFilterData {
  startDateTime: Date;
  endDateTime: Date;
  policyNumber?: string;
  microservices: string[];

  constructor(objectData?: any) {
    this.startDateTime = objectData.startDateTime || "";
    this.endDateTime = this.adjustEndDate(objectData.endDateTime);
    this.policyNumber = objectData.policyNumber || null;
    this.microservices = objectData.microservices;
  }

  
  adjustEndDate(endDate: Date): any {
    let adjustedDate;
    if (endDate) {
      adjustedDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59, 999);
    } else {
      adjustedDate = '';
    }
    return adjustedDate;
  }
  


}
