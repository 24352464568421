/**
  Types of Skippable Interceptor Header Enums
*/
export enum SkippableInterceptorHeader {
  AppInsightsSkipHeader = 'X-SkipAppInsightsInterceptor',
  RequestSkipHeader = 'X-SkipRequestInterceptor',
  JWTSkipHeader = 'X-SkipJWTInterceptor',
  IfModifiedSkipHeader = 'X-IfModifiedSkipHeader',
  ShowLoaderSkipHeader = 'X-ShowLoaderSkipHeader',
}
