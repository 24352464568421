import { CPMElement } from './cpmElement.model';
import { CPMForm } from './cpmForm.model';
import { Product } from './product.model';

export class ExtensionRequest {
  public CPMXml: string;

  public Products: Product[];

  public CallBackUrl: string;

  public UserName: string;

  public CPMForms: CPMForm[];

  public CPMElements: CPMElement[];

  public CallingSystem: string = "DocPortal";
}
