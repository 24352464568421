import { Routes, RouterModule } from '@angular/router';
import { NgModule, Component } from '@angular/core';
import { EnvironmentResolver } from './framework';
import { AuthGuard } from './framework/guards/auth.guard';
import { MsalGuard } from './msal/msal-guard.service';
import { AppComponent } from './app.component';
import { SplashComponent, SplashModule } from './splash';
import { GhostPortalModule } from './ghostPortal';
import { MainLayoutModule } from './main-layout';
import { MdoModule } from './mdo/mdo.module';
import { MdocumentModule } from './mdocument/mdocument.module';
import { DiagnosticsModule } from './diagnostics';
import { SplashItemsModule } from './splash/splash-items/splash-items.module';
import { OdenModule } from './oden';
import { DataManagementModule } from './data-management/data-management.module';

//import { LoginComponent } from './framework/components/login/login.component';

export const routes: Routes = [
  {
    path: '', canActivate: [MsalGuard],
    component: AppComponent,
    resolve: {
      environment: EnvironmentResolver
    },
    children: [
      {
        path: 'fast', canActivate: [MsalGuard, AuthGuard],
        loadChildren: () => MainLayoutModule
      },
      {
        path: 'ghostPortal', canActivate: [MsalGuard],
        loadChildren: () => GhostPortalModule
      },
      {
        path: 'diagnostics', canActivate: [MsalGuard],
        loadChildren: () => DiagnosticsModule
      },
      {
        path: 'mdo', canActivate: [AuthGuard],
        loadChildren: () => MdoModule
      },
      {
        path: 'mdocument', canActivate: [AuthGuard],
        loadChildren: () => MdocumentModule
      },
      {
        path: 'oden', canActivate: [AuthGuard],
        loadChildren: () => OdenModule
      },
      {
        path: 'data-management', canActivate: [AuthGuard],
        loadChildren: () => DataManagementModule
      },   
      {
        path: 'splash',
        canActivate: [AuthGuard],
        component: SplashComponent,
        children: [
          {
            path: '',
            redirectTo: 'main',
            pathMatch: 'full'
          },
          {
            path: 'main',
            loadChildren: () => import('../../src/app/splash/splash-items/splash-items.module').then(m => m.SplashItemsModule)
          },
          {
            path: 'search',
            loadChildren: () => import('../../src/app/splash/splash-smart-docs-ai/splash-smart-docs-ai.module').then(m => m.SplashSmartDocsAiModule)
          }
        ]
      },
      { path: '**', redirectTo: 'splash/main' }
    ]
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [
    EnvironmentResolver
  ]
})

export class AppRoutingModule {
}
