<div class="component-container">
  <div class="component-heading-container">
    <div class="col-md-12">
      <div class="row" style="padding-left: 10px">
        <h3 class="component-heading-text">Import Forms from CSV</h3>
      </div>
    </div>
  </div>
  <div class="container-fluid" style="padding: 10px">
    <div id="policy-form-container" class="row" style="margin: 15px">
      <!--<hr style="height:3px;background-color:lightgrey;width:100%" *ngIf="logSelected" />-->
      <div class="col-md-12">
        <div class="heading-container fast_admin"
             id="policy-form-heading-container">
        </div>
        <div>
          <button type="button"
                  mat-raised-button
                  class="btn-teal"
                  style="margin-left: 20px"
                  >
            Add Row
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12" style="overflow-x:auto">
        <mat-table [dataSource]="columnData">

        </mat-table>
      </div>
    </div>
  </div>
</div>
