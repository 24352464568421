export class ReportInstance {
  url: string;
  sanitizedUrl: any;
  title: string;

  constructor(url: string, title?: string) {
    this.url = url;
    this.title = title || "";
  }
}
