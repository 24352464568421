<div class="component-container">
  <!--
    <div class="component-heading-container">
      <h3 class="component-heading-text">Execute Rules</h3>
      <div class="btn_wrapper">
        <button type="button" class="btn primary_btn btnlign" type="button" (click)="searchLogs()">Search Logs</button>
        <button type="button" class="btn primary_btn btnlign" type="button" (click)="runTests()">Run Tests</button>
        <button type="button" class="btn primary_btn btnlign" type="button" [disabled]="!policyXML || policyXML == ''" (click)="openFormView()">FormView</button>
        <button type="button" class="btn primary_btn btnlign" type="button" [disabled]="!policyXML || policyXML == ''" (click)="getForms()">Get Forms</button>
        <button type="button" class="btn primary_btn btnlign" type="button" [disabled]="!policyXML || policyXML == ''" (click)="runQuery('Admin')">Admin DB Report</button>
        <button type="button" class="btn primary_btn btnlign" type="button" [disabled]="!policyXML || policyXML == ''" (click)="runQuery('Rules')">Rules DB Report</button>
      </div>
    </div>
  -->
  <div class="component-heading-container">
    <div class="row">
      <div class="col-md-2">
        <h3 class="component-heading-text">{{ activeSection }}</h3>
        <br />
        <a
          mat-button
          href="https://confluence.markelcorp.com/pages/viewpage.action?pageId=145463027"
          target="_blank"
          >How to Use Test Suite</a
        >
      </div>
      <div class="col-md-10">
        <div class="row">
          <div
            class="btn_wrapper"
            style="
              margin-left: auto;
              margin-right: 0;
              padding-bottom: 16px;
              margin-top: 5px;
            "
          >
            <button
              type="button"
              id="test_execution_section_btn"
              class="mat_btn_hov_act"
              mat-raised-button
              (click)="loadUnitTest()"
            >
              Unit Test
            </button>
            <button
              type="button"
              id="test_execution_section_btn"
              class="mat_btn_hov_act mrgrgt10 mrglt10"
              mat-raised-button
              (click)="loadRunTestGroups()"
            >
              Run Test Groups
            </button>
            <button
              type="button"
              id="test_mainenance_section_btn"
              class="mat_btn_hov_act mrgrgt10"
              mat-raised-button
              (click)="loadTestMaintenance()"
            >
              Test Maintenance
            </button>
            <button
              type="button"
              id="reporting_section_btn"
              class="mat_btn_hov_act"
              mat-raised-button
              (click)="loadReporting()"
            >
              Reporting
            </button>
          </div>
        </div>
        <div class="row">
          <div class="btn_wrapper" style="margin-left: auto; margin-right: 0">
            <button
              type="button"
              style="margin-right: 5px"
              *ngIf="activeSection === 'Unit Test'"
              mat-raised-button
              class="btn-teal"
              (click)="searchLogs()"
            >
              Search Logs
            </button>
            <button
              type="button"
              style="margin-right: 5px"
              *ngIf="activeSection === 'Run Test Groups'"
              mat-raised-button
              class="btn-teal"
              (click)="runTests()"
            >
              Run Selected Test Group
            </button>
            <button
              type="button"
              style="margin-right: 5px"
              *ngIf="activeSection === 'Run Test Groups' && showTestCases"
              mat-raised-button
              class="btn-teal"
              (click)="runTestCase()"
            >
              Run Selected Test Case
            </button>
            <button
              type="button"
              style="margin-right: 5px"
              *ngIf="activeSection === 'Unit Test' && isFAST"
              mat-raised-button
              class="btn-teal"
              [disabled]="!policyXML || policyXML == ''"
              (click)="getForms()"
            >
              Get Forms
            </button>
            <button
              type="button"
              style="margin-right: 5px"
              *ngIf="activeSection === 'Reporting'"
              mat-raised-button
              class="btn-teal"
              [disabled]="!reportQuery || policyXML == ''"
              (click)="runQuery('Admin')"
            >
              Admin DB Report
            </button>
            <button
              type="button"
              style="margin-right: 5px"
              *ngIf="activeSection === 'Reporting'"
              mat-raised-button
              class="btn-teal"
              [disabled]="!reportQuery || policyXML == ''"
              (click)="runQuery('Rules')"
            >
              Rules DB Report
            </button>

            <button
              type="button"
              style="margin-right: 5px"
              *ngIf="activeSection === 'Run Test Groups'"
              mat-raised-button
              class="btn-teal"
              (click)="clearTestOutput()"
            >
              Clear Test Output
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="component-content-view" *ngIf="activeSection === 'Unit Test'">
    <div class="container-fluid">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12" id="lob-content-container">
            <div class="heading-container fast_admin">
              <div class="row">
                <div class="col-md-12">
                  <p>
                    Select System:
                    <mat-button-toggle-group
                      name="callingSystem"
                      aria-label="Calling System"
                    >
                      <mat-button-toggle
                        value="FAST"
                        (change)="onSelectSystem('FAST')"
                        checked="true"
                        >Document Attachment</mat-button-toggle
                      >
                      <mat-button-toggle
                        value="GDF"
                        (change)="onSelectSystem('GDF')"
                        >Document Generation</mat-button-toggle
                      >
                    </mat-button-toggle-group>
                  </p>
                </div>
                <div class="col-md-12" *ngIf="isFAST">
                  <label class="label_heading">Environment:</label>
                  <div class="selectdiv select_wrapinline">
                    <label>
                      <ng-select
                        id="env"
                        class="custom-select"
                        style="width: 100%"
                        [items]="envList"
                        bindLabel="Name"
                        bindValue="Name"
                        [(ngModel)]="selectedEnv"
                      ></ng-select>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="!isFAST">
          <div id="execute-rules-content-heading-container" class="col-md-12">
            <label class="label_heading"
              >Deal Number: {{ this.dealNumber }}</label
            >
          </div>
        </div>
        <div class="row" *ngIf="!isFAST">
          <div id="execute-rules-content-heading-container" class="col-md-12">
            <label class="label_heading"
              >Action Type: {{ this.actionType }}</label
            >
          </div>
        </div>
        <div class="row" *ngIf="!isFAST">
          <div id="execute-rules-content-heading-container" class="col-md-12">
            <label class="label_heading"
              >Time Stamp: {{ this.timeStamp }}</label
            >
          </div>
        </div>

        <div class="row">
          <div
            id="execute-rules-content-heading-container"
            style="margin-top: 10px"
            class="col-md-12"
          >
            <div class="heading-container fast_admin">
              <label class="label_heading">Policy XML</label>
            </div>
          </div>
          <div id="execute-rules-content-container" class="col-md-12">
            <textarea
              id="policy-xml"
              class="form-control"
              [(ngModel)]="policyXML"
              rows="4"
            ></textarea>
          </div>
        </div>

        <div class="row" *ngIf="!isFAST">
          <div
            id="execute-rules-content-heading-container"
            style="margin-top: 10px"
            class="col-md-10"
          >
            <div class="heading-container fast_admin">
              <label class="label_heading">Forms List</label>
            </div>
          </div>
          <div id="execute-rules-content-container" class="row">
            <div class="col-md-2">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>InternalFormNumber</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let form of this.policyFormList">
                    <td>{{ form }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="isFAST">
          <div class="col-md-12" id="forms-content-heading-container">
            <div
              class="heading-container fast_admin"
              id="policy-form-heading-container"
              style="margin-top: 10px"
            >
              <label class="label_heading">Final Results</label>
            </div>
          </div>
          <div
            id="forms-content-container"
            class="col-md-12"
            style="overflow: visible; min-height: 100px"
          >
            <ag-grid-angular
              class="ag-theme-balham fast_panel_table"
              [gridOptions]="unitTestResultsGridOptions"
            >
            </ag-grid-angular>
          </div>
        </div>

        <div
          class="row"
          *ngIf="isFAST"
          style="margin-bottom: 20px; margin-top: 20px"
        >
          <div class="col-md-12" id="forms-content-heading-container">
            <div
              class="heading-container fast_admin"
              id="policy-form-heading-container"
            >
              <label class="label_heading"
                >Detailed Results - Policy Effective Date:
                {{ this.policyEffectiveDate }}</label
              >
            </div>
          </div>
          <div
            id="forms-content-container"
            class="col-md-12"
            style="overflow: visible; min-height: 100px"
          >
            <ag-grid-angular
              class="ag-theme-balham fast_panel_table"
              [gridOptions]="executeRulesGridOptions"
            >
            </ag-grid-angular>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="component-content-view"
    *ngIf="activeSection === 'Run Test Groups'"
  >
    <div class="container-fluid">
      <div class="col-md-12">
        <div class="row">
          <div id="lob-content-container">
            <div class="heading-container fast_admin">
              <div class="row">
                <div class="col-md-12">
                  <label class="label_heading">Environment:</label>
                  <div class="selectdiv select_wrapinline">
                    <label>
                      <ng-select
                        id="env"
                        class="custom-select"
                        style="width: 100%"
                        [items]="envList"
                        bindLabel="Name"
                        bindValue="Name"
                        [(ngModel)]="selectedEnv"
                      ></ng-select>
                    </label>
                  </div>

                  <label
                    class="label_heading"
                    style="padding-left: 30px; padding-right: 30px"
                    >Test Groups:</label
                  >
                  <div class="selectdiv select_wrapinline">
                    <label>
                      <ng-select
                        id="testGroups"
                        class="custom-select"
                        [items]="testGroups"
                        bindLabel="Name"
                        [(ngModel)]="selectedTestGroup"
                        (change)="showTestCasesHandler()"
                      ></ng-select>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="padding-top: 10px">
          <!-- Display test output -->
          <div class="row" *ngIf="showTestCases">
            <div class="heading-container fast_admin">
              <label class="label_heading">Test Cases</label>
            </div>
            <div
              id="test-case-container"
              style="padding-left: 0; padding-right: 0"
              class="col-md-12"
            >
              <ag-grid-angular
                class="ag-theme-balham fast_panel_table"
                [gridOptions]="testCasesGridOptions"
                style="height: 300px"
              >
              </ag-grid-angular>
            </div>
          </div>

          <div class="row" style="margin-top: 20px">
            <div
              class="col-md-12"
              id="test-results-heading-container"
              style="padding-left: 0; padding-right: 0"
            >
              <div class="heading-container fast_admin">
                <label class="label_heading">Test Run Results</label>
              </div>
            </div>
            <div
              id="test-results-container"
              class="col-md-12"
              style="overflow: visible; min-height: 100px"
              style="padding-left: 0; padding-right: 0"
            >
              <ag-grid-angular
                class="ag-theme-balham fast_panel_table"
                [gridOptions]="testResultsGridOptions"
              >
              </ag-grid-angular>
            </div>
          </div>
          <!--Test Output was here-->
        </div>
      </div>
    </div>
  </div>

  <app-test-maintenance
    *ngIf="activeSection === 'Test Maintenance'"
  ></app-test-maintenance>

  <div class="component-content-view" *ngIf="activeSection === 'Reporting'">
    <div class="container-fluid">
      <div class="col-md-12">
        <div class="row">
          <div id="execute-rules-content-heading-container" class="col-md-12">
            <div class="heading-container fast_admin">
              <label class="label_heading">Report Query</label>
            </div>
          </div>
          <div id="execute-rules-content-container" class="col-md-12">
            <textarea
              id="report-query"
              class="form-control"
              [(ngModel)]="reportQuery"
              rows="10"
              style="margin-bottom: 20px"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
