//Angular Imports
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";

//Third Party Imports
import {
  IAfterGuiAttachedParams,
  IDoesFilterPassParams,
  IFilterParams,
  RowNode,
} from "ag-grid-community";
import { IFilterAngularComp } from "ag-grid-angular";

/**
 * Date Filter Component
 */
@Component({
  selector: "app-checkbox-filter",
  template: `
  <div class="ag-filter-body-wrapper">  
    <div class="ag-filter-body" style="padding: 3px;" *ngFor="let option of options"> 
      <label>
        <input type="checkbox" 
        [checked]="isChecked(option)" 
        (change)="onCheckboxChange(option, $event.target.checked)" />
        {{ option }}
      </label>
    </div>
  </div>`,
  styles: ["span {margin:10px}"],
})
export class CheckboxFilterComponent
  implements IFilterAngularComp, OnInit, OnDestroy
{
  // Private Variables
  /**
   * Params
   */
  private _params: IFilterParams;

  /**
   * ValueGetter
   */
  private _valueGetter: (rowNode: RowNode) => any;

  // Public Variables
  /**
   * True or False  
   */
  public value: string;

  // Constructor
  /**
   * @ignore
   * */
  constructor(private cd : ChangeDetectorRef) {}

  // Angular Lifecycles
  /**
   * @ignore
   * */
  ngOnInit(): void {

  }

  /**
   * @ignore
   * */
  ngOnDestroy(): void {}

  // Public Methods
  /**
   * AG Init
   * @param params Grid filter params
   */
  public options: string[] =  [];
  public selectedValues: { [key: string]: boolean } = {};

  agInit(params: IFilterParams): void {
    this._params = params;
    this._valueGetter = params.valueGetter.apply;
    this.options = params['values'];
  }

  isChecked(option: string): boolean {
    return this.selectedValues[option] || false;
  }

  /**
   * Is Filter Active (Method called by the grid to check if the filter is active)
   * */
  isFilterActive(): boolean {
    return Object.values(this.selectedValues).some(value => value);
  }

  /**
   * Does Filter Pass (Method called by the grid to check if the data passes the filter)
   * @param params Grid Params
   */
  doesFilterPass(params: IDoesFilterPassParams): boolean {
    // const value = this._valueGetter(params.node);
    // return this.selectedValues[value];
    return true;
  }

  /**
   * Get Model
   * */
  getModel(): any {
    return { values: this.selectedValues, 'type' : 'exact' };
  }

  /**
   * Set Model
   * @param model Model
   */
  setModel(model: any): void {
    this.selectedValues = model ? model.values : {};
  }

  onCheckboxChange(option: string, checked: boolean): void {
    this.selectedValues = { ...this.selectedValues, [option]: checked };
    this._params.filterChangedCallback();
  }
}
