//Angular Imports 
import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';

//Third Party Imports
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

//Internal Imports 
import { UserProfileService } from '../../../../../framework';
import { TestingService } from '../../services';
import { RuleEngineResult, RuleEngineLog, TestExpectedResults, TestCaseResult } from '../../models';
import { coverageCpmElementId, lobCpmElementId } from '../../configs/execution-engine.config';

import 'rxjs';
import {TestCase} from '../../models';
import { TestCaseExpectedResult } from '../test-case-expected-result';


/**
 * Forms Component
*/
@Component({
  selector: 'failed-test-details',
  templateUrl: './failed-test-details.component.html',
  styleUrls: ['./failed-test-details.component.scss']
})

export class FailedTestDetails {

  @Input() testCaseName: string;
  @Input() executionResults: TestCaseResult[];

  public missingOptional: string[] = [];
  public missingMandatory: string[] = [];
  public extraOptional: string[] = [];
  public extraMandatory: string[] = [];



  constructor(public activeModal: NgbActiveModal,
    private testingService: TestingService,
    private userProfileService: UserProfileService,
    private _modal: NgbModal,
    private toastr: ToastrService) {
  }

  ngOnInit(): void {
    console.log(this.testCaseName);
    console.log(this.executionResults);
    for (var i = 0; i < this.executionResults.length; i++) {
      for (var a = 0; a < this.executionResults[i].MissingMandatory.length; a++) {
        this.missingMandatory.push(this.executionResults[i].MissingMandatory[a] + ` (${this.executionResults[i].LOB})`);
      }
      for (var b = 0; b < this.executionResults[i].ExtraMandatory.length; b++) {
        this.extraMandatory.push(this.executionResults[i].ExtraMandatory[b] + ` (${this.executionResults[i].LOB})`);
      }
      for (var c = 0; c < this.executionResults[i].MissingOptional.length; c++) {
        this.missingOptional.push(this.executionResults[i].MissingOptional[c] + ` (${this.executionResults[i].LOB})`);
      }
      for (var d = 0; d < this.executionResults[i].ExtraOptional.length; d++) {
        this.extraOptional.push(this.executionResults[i].ExtraOptional[d] + ` (${this.executionResults[i].LOB})`);
      }
    }
  }

  /**
   * NgOnDestroy
   * @ignore
  */
  ngOnDestroy(): void {

  }

}
