<div #originRef
     (click)="showPopover(originRef)"
     [class.bg-hover]="dropdownOpen"
     class="tw-flex tw-items-center tw-rounded tw-cursor-pointer tw-relative trans-ease-out tw-select-none tw-py-1 tw-pr-1 tw-pl-3 hover:bg-hover"
     matRipple>
  <div class="body-1 tw-font-medium tw-leading-snug ltr:tw-mr-3 rtl:tw-ml-3">{{userName}}</div>
  <div class="tw-rounded-full tw-h-9 tw-w-9 tw-flex tw-items-center tw-justify-center text-icon-orange bg-primary/10">
    <mat-icon svgIcon="mat:person"></mat-icon>
  </div>
</div>
