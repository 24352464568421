<div class="modal-header">
  <h4 class="modal-title">{{ actionType }} Test Group</h4>
</div>

<div class="modal-body col-12">
  <!-- Name -->
  <div class="row">
    <div class="col-md-12 mrgbtm10">
      <label style="font-weight: bold">Name</label>
      <input
        id="txtName"
        class="vex-input"
        style="width: 100%"
        required
        [(ngModel)]="testGroup.Name"
        #txtName="ngModel"
      />

      <div
        *ngIf="
          txtName.invalid && (txtName.dirty || txtName.touched || formSubmitted)
        "
      >
        <span *ngIf="txtName.errors.required" class="error_msg">
          <i class="error_icon"></i>Name is required
        </span>
      </div>
    </div>
  </div>

  <!-- Description -->
  <div class="row">
    <div class="col-md-12 mrgbtm10">
      <label style="font-weight: bold">Description</label>
      <textarea
        id="txtDescription"
        class="vex-input"
        rows="2"
        style="width: 100%; height: 60px"
        [(ngModel)]="testGroup.Description"
        #txtDescription="ngModel"
      ></textarea>
    </div>
  </div>

  <!-- Type -->
  <div class="row">
    <div class="col-md-12 mrgbtm10 fast_admin">
      <label style="font-weight: bold" class="label_heading"
        >Test Group Type:</label
      >
      <div class="selectdiv select_wrapinline" style="width: 100%">
        <label>
          <ng-select
            id="groupType"
            class="custom-select"
            style="width: 100%"
            [items]="typeList"
            bindLabel="Name"
            bindValue="Name"
            required
            [(ngModel)]="selectedType"
            #groupType="ngModel"
          ></ng-select>
        </label>
      </div>

      <div
        *ngIf="
          groupType.invalid &&
          (groupType.dirty || groupType.touched || formSubmitted)
        "
      >
        <span *ngIf="groupType.errors.required" class="error_msg">
          <i class="error_icon"></i>Test Group Type is required
        </span>
      </div>
    </div>
  </div>

  <!-- LOBs -->
  <div class="row">
    <div id="lob-grid-container" class="col-12" style="padding-bottom: 10px">
      <div style="padding-bottom: 5px"><b>Lines of Business</b></div>
      <ag-grid-angular
        class="ag-theme-balham fast_panel_table"
        [gridOptions]="lobGridOptions"
        style="height: 200px"
      >
      </ag-grid-angular>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button
    type="button"
    mat-raised-button
    class="btn-teal mrgrgt10"
    (click)="activeModal.close()"
  >
    Cancel
  </button>
  <button
    type="button"
    class="mat_btn_hov_act"
    mat-raised-button
    (click)="saveTestGroup()"
  >
    Save
  </button>
</div>
