//Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//Internal Imports
import { MainLayoutRoutingModule } from './main-layout.routing';
import { HeaderComponent, SidebarComponent, MenuComponent, MenuItemComponent, MainLayoutComponent } from './components';
import { SidebarContentViewer } from './directives';
import { MatExpansionModule } from '@angular/material/expansion';
import { PopoverService } from '../../@vex/components/popover/popover.service';
import { LayoutModule } from '../../@vex/layout/layout.module';
import { SidenavModule } from '../../@vex/layout/sidenav/sidenav.module';
import { ToolbarModule } from '../../@vex/layout/toolbar/toolbar.module';
import { ConfigPanelModule } from '../../@vex/components/config-panel/config-panel.module';
import { SidebarModule } from '../../@vex/components/sidebar/sidebar.module';
import { QuickpanelModule } from '../../@vex/layout/quickpanel/quickpanel.module';
import { MatIconModule } from '@angular/material/icon';

/**
  Main Layout Module
*/
@NgModule({
  declarations: [
    MainLayoutComponent,
    HeaderComponent,
    SidebarComponent,
    MenuComponent,
    MenuItemComponent,
    SidebarContentViewer
  ],
  imports: [
    CommonModule,
    MainLayoutRoutingModule,
    MatExpansionModule,
    LayoutModule,
    SidenavModule,
    ToolbarModule,
    ConfigPanelModule,
    SidebarModule,
    QuickpanelModule,
    MatIconModule
  ],
  providers: [PopoverService]
})
export class MainLayoutModule { }
