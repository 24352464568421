<div class="row" style="padding-bottom: 20px">
  <!-- Read=Only version-->
  <div class="col-md-12" *ngIf="actionType === 'View'">
    <div class="row" style="margin-bottom: 10px" *ngIf="attributeSetIsMaster">
      <div class="col-6 form-att-label">External Form Number:</div>
      <div class="col-6" style="text-align: left; font-size: 15px">
        {{ attributeSet.ExternalFormNumber }}
      </div>
    </div>

    <div class="row" style="margin-bottom: 10px" *ngIf="attributeSetIsMaster">
      <div class="col-6 form-att-label">Internal Form Number:</div>
      <div class="col-6" style="text-align: left; font-size: 15px">
        {{ attributeSet.InternalFormNumber }}
      </div>
    </div>

    <div class="row" style="margin-bottom: 10px">
      <div class="col-6 form-att-label">Form Name:</div>
      <div class="col-6" style="text-align: left; font-size: 15px">
        {{ attributeSet.FormName }}
      </div>
    </div>

    <div class="spanWarning">
      <span *ngIf="attributeSet.FormType == 'Application' || attributeSet.FormType == 'Attaching_Application'" class="error_msg">
        <i class="error_icon"></i>Attaches to emails in e2
      </span>
    </div>
    <div style="position:relative; left:30%;">
      <span *ngIf="attributeSet.FormType == 'Application'" class="error_msg">
        <i class="error_icon"></i>Does not attach to policies
      </span>
    </div>

    <div class="row" style="margin-bottom: 10px">
      <div class="col-6 form-att-label">Form Type:</div>
      <div class="col-6" style="text-align: left; font-size: 15px">
        {{ attributeSet.FormType }}
      </div>
    </div>

    <div class="row" style="margin-bottom: 10px" *ngIf="attributeSetIsMaster">
      <div class="col-6 form-att-label">Previous Version:</div>
      <div class="col-6" style="text-align: left; font-size: 15px">
        {{ attributeSet.PriorInternalFormNumber }}
      </div>
    </div>

    <div class="row" style="margin-bottom: 10px">
      <div class="col-6 form-att-label">Effective Date:</div>
      <div class="col-6" style="text-align: left; font-size: 15px">
        {{ attributeSet.EffectiveDate | dateTimeUtcPipe }}
      </div>
    </div>

    <div class="row" style="margin-bottom: 10px">
      <div class="col-6 form-att-label">Expiration Date:</div>
      <div class="col-6" style="text-align: left; font-size: 15px">
        {{ attributeSet.ExpirationDate | dateTimeUtcPipe }}
      </div>
    </div>

    <div class="row" style="margin-bottom: 10px">
      <div class="col-6 form-att-label">Submission Exp Date:</div>
      <div class="col-6" style="text-align: left; font-size: 15px">
        {{ attributeSet.SubmissionExpirationDate | dateTimeUtcPipe }}
      </div>
    </div>

    <div class="row" style="margin-bottom: 10px" *ngIf="!attributeSetIsMaster">
      <div class="col-6 form-att-label">Sort Order:</div>
      <div class="col-6" style="text-align: left; font-size: 15px">
        {{ attributeSet.SortOrder }}
      </div>
    </div>

    <div class="row" style="margin-bottom: 10px">
      <div class="col-6 form-att-label">Multiple Attachment:</div>
      <div class="col-6" style="text-align: left; font-size: 15px">
        {{ attributeSet.MultipleAttachment }}
      </div>
    </div>

    <div class="row" style="margin-bottom: 10px" *ngIf="!attributeSetIsMaster">
      <div class="col-6 form-att-label">Frequently Used:</div>
      <div class="col-6" style="text-align: left; font-size: 15px">
        {{ attributeSet.FrequentlyUsed }}
      </div>
    </div>

    <div class="row"
         style="margin-bottom: 10px"
         *ngIf="!attributeSetIsMaster && isMPL">
      <div class="col-6 form-att-label">Policy Level Form:</div>
      <div class="col-6" style="text-align: left; font-size: 15px">
        {{ attributeSet.PolicyLevel }}
      </div>
    </div>

    <div class="row" style="margin-bottom: 10px" *ngIf="attributeSetIsMaster">
      <div class="col-6 form-att-label">Multiple LOBs:</div>
      <div class="col-6" style="text-align: left; font-size: 15px">
        {{ attributeSet.MultiLOBIndicator }}
      </div>
    </div>

    <div class="row"
         style="margin-bottom: 10px"
         *ngIf="!attributeSetIsMaster && isMPL">
      <div class="col-6 form-att-label">Display Only:</div>
      <div class="col-6" style="text-align: left; font-size: 15px">
        {{ displayString }}
      </div>
    </div>
  </div>

  <!-- Editable version-->
  <div class="col-md-12" *ngIf="actionType === 'Edit'">
    <div class="col-md-12 mrgbtm10" *ngIf="attributeSetIsMaster">
      <label class="form-page-att-label" style="font-weight: bold"
        >External Form Number</label
      >
      <input
        id="externalFormNumber"
        class="form-page-att-input"
        style="width: 100%"
        required
        [(ngModel)]="attributeSet.ExternalFormNumber"
        #externalFormNumber="ngModel"
        (keyup)="onExternalFormNumberChange()"
      />

      <div
        *ngIf="
          externalFormNumber.invalid &&
          (externalFormNumber.dirty ||
            externalFormNumber.touched ||
            formSubmitted)
        "
      >
        <span *ngIf="externalFormNumber.errors.required" class="error_msg">
          <i class="error_icon"></i>External Form Number is required.
        </span>
      </div>
    </div>
    
    <div class="col-md-12 mrgbtm10" *ngIf="attributeSetIsMaster">
      <label class="form-page-att-label" style="font-weight: bold"
        >Internal Form Number</label
      >
      <input
        id="internalFormNumber"
        class="form-page-att-input"
        style="width: 100%"
        required
        [(ngModel)]="attributeSet.InternalFormNumber"
        #internalFormNumber="ngModel"
      />

      <div
        *ngIf="
          internalFormNumber.invalid &&
          (internalFormNumber.dirty ||
            internalFormNumber.touched ||
            formSubmitted)
        "
      >
        <span *ngIf="internalFormNumber.errors.required" class="error_msg">
          <i class="error_icon"></i>Internal Form Number is required.
        </span>
      </div>
    </div>

    <div class="col-md-12 mrgbtm10">
      <label class="form-page-att-label" style="font-weight: bold"
        >Form Name</label
      >
      <input
        id="formName"
        class="form-page-att-input"
        style="width: 100%"
        required
        [(ngModel)]="attributeSet.FormName"
        #formName="ngModel"
      />

      <div
        *ngIf="
          formName.invalid &&
          (formName.dirty || formName.touched || formSubmitted)
        "
      >
        <span *ngIf="formName.errors.required" class="error_msg">
          <i class="error_icon"></i>Form Name is required.
        </span>
      </div>
    </div>

    <div class="row" style="margin-left: 1px">

      <div class="spanWarningEdit">
        <span *ngIf="attributeSet.FormType == 'Application' || attributeSet.FormType == 'Attaching_Application'" class="error_msg">
          <i class="error_icon"></i>Attaches to emails in e2
        </span>
      </div>
      <div style="position:relative; right:11%; padding-top:30px">
        <span *ngIf="attributeSet.FormType == 'Application'" class="error_msg">
          <i class="error_icon"></i>Does not attach to policies
        </span>
      </div>

      <div class="col-md-12 mrgbtm10 fast_admin">
        <label class="form-page-att-label" style="font-weight: bold">Form Type</label>
        <div class="selectdiv select_wrapinline" style="width: 100%">
          <label>
            <ng-select id="formType"
                       class="custom-select"
                       style="width: 100%"
                       [items]="formTypeList"
                       bindLabel="Description"
                       bindValue="Description"
                       [(ngModel)]="selectedFormType"
                       (change)="onChangeFormType($event)"
                       required
                       #formType="ngModel">
            </ng-select>
          </label>

          <div *ngIf="
              formType.invalid &&
              (formType.dirty || formType.touched || formSubmitted)
            ">
            <span *ngIf="formType.errors.required" class="error_msg">
              <i class="error_icon"></i>Form Type is required.
            </span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="col-md-12"
      *ngIf="attributeSetIsMaster"
      style="margin-bottom: 25px"
    >
      <label class="form-page-att-label" style="font-weight: bold"
        >Previous Version</label
      >
      <input
        id="previousVersion"
        class="form-page-att-input"
        style="width: 100%"
        required
        [(ngModel)]="attributeSet.PriorInternalFormNumber"
        #formName="ngModel"
      />

      <div
        *ngIf="
          formName.invalid &&
          (formName.dirty || formName.touched || formSubmitted)
        "
      >
        <span *ngIf="formName.errors.required" class="error_msg">
          <i class="error_icon"></i>Previous Version is required.
        </span>
      </div>
    </div>

    <div class="col-md-12 mrgbtm10">
      <label class="form-page-att-label" style="font-weight: bold"
        >Effective Date</label
      >
      <date-picker
        name="effectiveDate"
        id="effectiveDate"
        style="width: 100%"
        [(ngModel)]="attributeSet.EffectiveDate"
        (dateChange)="onChangeEffectiveDate()"
      ></date-picker>
    </div>

    <div class="col-md-12 mrgbtm10">
      <label class="form-page-att-label" style="font-weight: bold"
        >Expiration Date</label
      >
      <date-picker
        name="expirationDate"
        id="expirationDate"
        style="width: 100%"
        [(ngModel)]="attributeSet.ExpirationDate"
        (dateChange)="onChangeExpirationDate()"
      ></date-picker>

      <div *ngIf="expirationDateError">
        <span class="error_msg">
          <i class="error_icon"></i>{{ expirationDateError }}
        </span>
      </div>
    </div>

    <div class="col-md-12 mrgbtm10">
      <label class="form-page-att-label" style="font-weight: bold"
        >Submission Expiration Date</label
      >
      <date-picker
        name="submissionExpirationDate"
        id="submissionExpirationDate"
        style="width: 100%"
        [(ngModel)]="attributeSet.SubmissionExpirationDate"
        (dateChange)="onChangeSubmissionExpirationDate()"
      ></date-picker>

      <div *ngIf="submissionExpirationDateError">
        <span class="error_msg">
          <i class="error_icon"></i>{{ submissionExpirationDateError }}
        </span>
      </div>
    </div>

    <div class="col-md-12 mrgbtm10" *ngIf="!attributeSetIsMaster">
      <label class="form-page-att-label" style="font-weight: bold"
        >Sort Order</label
      >
      <input
        id="sortOrder"
        class="form-page-att-input"
        style="width: 100%"
        required
        [(ngModel)]="attributeSet.SortOrder"
        #txtName="ngModel"
        (keyup)="onSortOrderChange()"
      />
    </div>

    <div
      class="col-md-12 mrgbtm10"
      *ngIf="sortOrderChanges"
      style="vertical-align: top"
    >
      <span class="error_msg">
        <i class="error_icon"></i>Attention: Leave Sort Order blank if you plan
        to use the value from the Print Order page<br />
      </span>
    </div>

    <div class="col-md-12 mrgbtm10" style="clear: both" *ngIf="!isMPL">
      <label>
        <input
          id="multipleAttachment"
          type="checkbox"
          style="display: inline-block; margin-right: 5px"
          [(ngModel)]="attributeSet.MultipleAttachmentIndicator"
        />Multiple Attachment
      </label>
    </div>

    <div class="col-md-12 mrgbtm10" *ngIf="!attributeSetIsMaster && !isMPL">
      <label>
        <input
          id="frequentlyUsed"
          type="checkbox"
          style="display: inline-block; margin-right: 5px"
          [(ngModel)]="attributeSet.FrequentlyUsed"
        />Frequently Used
      </label>
    </div>

    <div class="col-md-12 mrgbtm10" *ngIf="!attributeSetIsMaster && isMPL">
      <label>
        <input
          id="policylevel"
          type="checkbox"
          style="display: inline-block; margin-right: 5px"
          [(ngModel)]="attributeSet.PolicyLevel"
        />Policy Level
      </label>
    </div>

    <div class="col-md-12 mrgbtm10" *ngIf="attributeSetIsMaster">
      <label>
        <input
          id="multiLOB"
          type="checkbox"
          style="display: inline-block; margin-right: 5px"
          [(ngModel)]="attributeSet.MultiLOBIndicator"
        />Multiple LOBs
      </label>
    </div>

    <div
      class="row"
      *ngIf="!attributeSetIsMaster && isMPL"
      style="margin-left: 14px"
    >
      <!-- <div class="col-md-12 mrgbtm10 fast_admin">
      <label style="font-weight:bold" class="label_heading">Display Only</label>
      <div class="selectdiv select_wrapinline" style="width: 100%">
        <label>
          <ng-select id="displayOnly"
                     class="custom-select"
                     style="width: 100%"
                     [multiple]="true"
                     [items]="ActionReasonList"
                     bindLabel=""
                     bindValue=""
                     [(ngModel)]="attributeSet.DisplayOnly"
                     (change)="onSelectActionReason($event)"
                     required
                     #formType="ngModel">
          </ng-select>
        </label>
      </div>
    </div>-->
      <label style="font-weight: bold" class="label_heading"
        >Display Only</label
      >
      <ng-select
        id="displayOnly"
        class="custom-select"
        style=""
        [multiple]="true"
        [items]="ActionReasonList"
        bindLabel=""
        bindValue=""
        [(ngModel)]="attributeSet.DisplayOnly"
        (change)="onSelectActionReason($event)"
        required
        #formType="ngModel"
      >
      </ng-select>
    </div>
  </div>
</div>
