<div class="component-content-view">
  <div class="container-fluid">
    <div id="test-maintenance-container" class="row">

      <div class="col-12" id="test-group-heading-container">
        <div class="heading-container fast_admin">
          <label class="label_heading">Test Groups</label>
          <span class="policy-form-btn" id="test-group-add-btn" (click)="createTestGroup()">
            <i class="fas fa-plus" style="float: right;  margin-top:20px"></i>
          </span>
        </div>
      </div>


      <div id="test-group-container" class="col-12">
        <ag-grid-angular class="ag-theme-balham fast_panel_table"
                         [gridOptions]="testGroupGridOptions" style="height: 300px">
        </ag-grid-angular>
      </div>

      <div class="col-12" id="test-case-heading-container">
        <div class="heading-container fast_admin">
          <label class="label_heading">Test Cases</label>
          <span class="policy-form-btn" id="test-case-add-btn" (click)="createTestCase()">
            <i class="fas fa-plus" style="float: right;  margin-top:20px"></i>
          </span>
        </div>
      </div>
      <div id="test-case-container" class="col-12">
        <ag-grid-angular class="ag-theme-balham fast_panel_table"
                         [gridOptions]="testCaseGridOptions" style="height: 400px">
        </ag-grid-angular>
      </div>

    </div>
  </div>

</div>
