//Angular Imports
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

//RxJS imports
import { Observable } from 'rxjs';

//Internal Imports
import { AppInsightsService } from '../services';
import { SkippableInterceptorHeader } from '../enums';

/**
  * Intercepts and logs all HTTP requests in app insights
*/
@Injectable()

export class AppInsightsInterceptor implements HttpInterceptor {

  /**
   * Constructor
   * @ignore
  */
  constructor(private appInsightsService: AppInsightsService) { }

  /**
   * Intercepts all the HTTP requests and logs the request in app insights
   * @param {HttpRequest} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>} Http Event Observable 
  */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let headers = request.headers;
   
    //Log the request in appInsights
    //check if the interceptor needs to be skipped using the header
    if (!headers.get(SkippableInterceptorHeader.AppInsightsSkipHeader)) {
      this.appInsightsService.logEvent("Request", { url: request.url, body: request.serializeBody() });
    }
    else {
      //delete the header if set
      request = request.clone({
        headers: headers.delete(SkippableInterceptorHeader.AppInsightsSkipHeader),
        url: request.url
      });
    }
      
    //Pass to next interceptor
    return next.handle(request);
  }
}
