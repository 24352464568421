<div class="footer tw-flex">
  <div class="tw-flex-auto tw-flex tw-items-center container">
    <div
      class="tw-font-medium ltr:tw-ml-4 rtl:tw-mr-4 tw-hidden sm:tw-block"
      style="font: 16px var(--font); position: relative; left: 250px"
    >
      <a
        href="https://markel.atlassian.net/wiki/spaces/DOCSOL/pages/25690359/Insight+Hub+Roles"
        target="_blank"
      >
        Need to authenticate a new user? Click here to go to Confluence and
        learn how!
      </a>
    </div>
  </div>
</div>
