//Angular Imports 
import { Injectable } from '@angular/core';

//rxjs Imports 
import { Observable, Subject } from 'rxjs';

//Internal Imports
import { MainLayoutMessage } from '../models';

/**
  * Provides methods to send message to Menu  
*/
@Injectable({ providedIn: 'root' })

export class MainLayoutService {

  //Private Variables
  /**
   * Message Subject for communication between components
  */
  private subject;

  /**
   * Constructor
   * @ignore
  */
  constructor() {
    this.subject = new Subject<MainLayoutMessage>();
  };

  //Public Methods
  /**
   * Send Message
   * @param {MainLayoutMessage} message
   * @returns {void} 
  */
  sendMainLayoutMessage(message: MainLayoutMessage): void {
    this.subject.next(message);
  }

  /**
   * Get Message 
   * @returns {Observable<MainLayoutMessage>} Message Observable
  */
  getMainLayoutMessage(): Observable<MainLayoutMessage> {
    return this.subject.asObservable();
  }
}
