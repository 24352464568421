//Angular Imports
import { Component } from '@angular/core';

//Custom Imports 
import { AppInsightsService } from '../services';


/**
  App Insights Base Component
*/
@Component({
  template: 'app-insights'
})

export class ApplicationInsightsBaseComponent {


  /**
   * Constructor
   * @ignore
  */
  constructor(private appInsightsService: AppInsightsService) {
    this.logNavigation();
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
  */
  ngOnInit(): void {
  }

  /**
   * NgDestroy
   * @ignore
  */
  ngOnDestroy(): void {
  }


  /**
   * Log page navigation
   * @returns {void} 
  */
  logNavigation(): void {
    this.appInsightsService.logPageView();
  }

  /**
   * Log Exceptions
   * @param {Error} exception
   * @param {AI.SeverityLevel} severity level
   * @param {string} handled at
   * @param {any} properties
   * @param {any} measurements
   * @returns {void} 
  */
  logException(exception: Error, severityLevel: AI.SeverityLevel, handledAt: string, properties: any, measurements: any): void {
    this.appInsightsService.logException(exception, severityLevel, handledAt, properties, measurements);
  }
} 
