import { RuleEngineForm } from "../../form-page/models/ruleEngineForm.model";
import { DeployPackage } from "./deployPackage.model";

export class FormDiscrepancies {

  public laterPackage: DeployPackage;

  public sameForms: RuleEngineForm[];

}
