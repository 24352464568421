import {MsalConfig} from "./msal-config";
import {APP_INITIALIZER, Injectable, ModuleWithProviders, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import { MsalService, MSAL_CONFIG} from "./msal.service";
import {MsalGuard} from "./msal-guard.service";
import {BroadcastService} from "./broadcast.service";
import { initializeApp1 } from "../app.module";
import { AppInitService } from "../init.service";


Injectable()
export class WindowWrapper extends Window {

}
@NgModule({
  imports: [CommonModule],
    declarations: [

    ],
  providers: [MsalGuard, BroadcastService,
    { provide: APP_INITIALIZER, useFactory: initializeApp1, deps: [AppInitService], multi: true }  ],
})
export class MsalModule {
  static forRoot(config: MsalConfig): ModuleWithProviders<MsalModule> {
    return {
      ngModule: MsalModule,
      providers: [
        { provide: MSAL_CONFIG, useValue: config }, MsalService, { provide: WindowWrapper, useValue: window }
      ]
    }
  }

}

