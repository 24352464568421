

/**
 * Policy Form MOdel 
 * */
export class PolicyFormExport {
  public Mandatory: boolean;
  public InternalFormNumber: string;
  public EffectiveDate: string;
  public ExpirationDate: string;
  public SubmissionExpirationDate: string;
  public FormType: string;
  public PriorInternalFormNumber: string;
  public FrequentlyUsed: boolean;
  public MultipleLOBs: boolean;
  public FormName: string;
  public SortOrder: string;
 
}
