<div class="modal-header">
  <h4 class="modal-title">{{ actionType }} CPM Field</h4>
</div>
<form [formGroup]="formGroup">
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 mrgbtm10">
        <label style="font-weight: bold">Code</label>

        <input
          id="txtCode"
          class="vex-input"
          style="width: 100%"
          formControlName="code"
        />
        <div
          *ngIf="
            formGroup.controls.code &&
            formGroup.controls.code.dirty &&
            formGroup.controls.code.errors
          "
        >
          <span class="error_msg">
            <i class="error_icon"></i>Code is required
          </span>
        </div>
      </div>
      <div class="col-md-12 mrgbtm10">
        <label style="font-weight: bold">Description</label>

        <input
          id="txtDesc"
          class="vex-input"
          style="width: 100%"
          formControlName="description"
        />
        <div
          *ngIf="
            formGroup.controls.description &&
            formGroup.controls.description.dirty &&
            formGroup.controls.description.errors
          "
        >
          <span class="error_msg">
            <i class="error_icon"></i>Description is required
          </span>
        </div>
      </div>
      <div class="col-md-12 mrgbtm10">
        <label style="font-weight: bold">E2Description</label>
        <input
          id="txtE2Desc"
          class="vex-input"
          style="width: 100%"
          formControlName="e2Description"
        />
      </div>
    </div>
  </div>
</form>
<div class="modal-footer">
  <button
    type="button"
    class="mat_btn_hov_act mrgrgt10"
    mat-raised-button
    (click)="activeModal.close()"
  >
    Cancel
  </button>
  <button
    type="button"
    mat-raised-button
    class="btn-teal"
    (click)="saveCpmField()"
  >
    Save
  </button>
</div>
