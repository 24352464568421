import * as vkbeautify from 'vkbeautify';
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'xml'
})
export class XmlPipe implements PipeTransform {

  constructor() { }
  transform(value: string): string {
    if (value)
      return vkbeautify.xml(value);
  }
}
