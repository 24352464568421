import { Observable, of, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { SkippableInterceptorHeader } from "../../framework/enums/skippable-interceptor-header.enum";
import { UserProfileService, EnvironmentService, ApplicationRole } from '../../framework';

import { catchError, map } from 'rxjs/operators';
import { formatDate } from "@angular/common";
import { FilterData } from "../models/filter-data";
import { Authorization } from '../models/authorization';

@Injectable()
export class QueueService {

  queueData: any;
  sourceSystem: any[] = [];
  requestStatus: any[] = [];
  stateData: any = {
    authorizations: {
      resetRequest: new Authorization([ApplicationRole.MDocEdit, ApplicationRole.MDocAdmin])
    }
  }

  constructor(private http: HttpClient, private userProfileService: UserProfileService, private environmentService: EnvironmentService) { }

  getProblemStatuses(): string[] {
    return ["ERROR"];
  }

  getInitialStartDate(): any {
    return new Date();
  }

  getInitialEndDate(): any {
    let startDate = this.getInitialEndDate();
    return new Date( startDate.getFullYear(), startDate.getMonth(), (startDate.getDate() - 7), 0, 0, 0, 0 );
  }

  isAuthorized(context: string): boolean {
    if (this.isDevelopmentEnvironment()) {
      return true;
    }

    if (!this.stateData.authorizations[context]) {
      console.log("Error reported: authorization configuration for '" + context + "' context does not exist.");
      return false;
    }
    let authorization = this.stateData.authorizations[context];
    if (authorization.checked) {
      return authorization.isAuthorized;
    } else {
      authorization.setAuthorization(this.userProfileService.UserProfile.Roles);
      return authorization.isAuthorized;
    }

  }

  isDevelopmentEnvironment(): boolean {
    let env = this.environmentService.environmentDetails.Environment;
    return (["USDEVLOCAL", "LOCAL", "DEV1", "DEV2", "QA1", "QA2"].indexOf(env) > -1)
  }

  
  formatFilterRequestDate(dateValue: any, context: string ): string {
    let formattedDate = dateValue.getFullYear() + "-" + (dateValue.getMonth() + 1).toString().padStart(2, '0') + "-" + dateValue.getDate().toString().padStart(2, 0);
    if (context == 'start') {
      formattedDate += " 00:00:00.000";
    } else if (context == 'end') {
      formattedDate += " 23:59:59.999";
    }
    return formattedDate;
  }

  formatTimestamp(dateValue: any): string {
    if (!dateValue) {
      return 'NA';
    }
    if (isNaN(Date.parse(dateValue))) {
      return (dateValue);
    }
    let staticDate = dateValue.replace(/Z$/, "");
    let date = new Date(Date.parse(staticDate));
    return (formatDate(date, 'MM/dd/yyyy h:mm:ss a', 'en-US') );
  }

  handleHTTPError(error: HttpErrorResponse) {
    return throwError(error);
  }

  generateRequestHeaders(mockHeaderValue?: string): HttpHeaders {
    let headers = new HttpHeaders();
    // Because HttpHeaders is immutable, any action to add or alter a header returns a new instance that has to be reassigned to the variable
    headers = headers.set(SkippableInterceptorHeader.ShowLoaderSkipHeader, "true");
    if (mockHeaderValue) {
      headers = headers.set("x-mock-response", mockHeaderValue);
    }
    return headers;
  }

  filterQueueData(params: any): void {
    this.queueData = this.getSummaryData(params)
  }

  getSummaryData(filterData: FilterData): Observable<any> {

    var urlPath: string = "api/mdocument/summary";
    var settings: any = {
      body: filterData.outputValues(),
      observe: 'body',
      responseType: 'json'
    };
    let fullset: any[] = [];

    return this.http.post<any>(urlPath, settings.body, { observe: "response", headers: this.generateRequestHeaders() }).pipe(
      map(res => {
        fullset = res.body.results;
        return fullset;
      }),
      // Handle a error response
      catchError(this.handleHTTPError)
    );

  }
  getSourceSystem(): Observable<any> {
    let getUrl = 'api/mdocument/sourcesystem';
    return this.http.get<any>(getUrl, { observe: "response", headers: this.generateRequestHeaders() })
      .pipe(
        map(res => {
          this.sourceSystem = res.body.SourceSystems;
          return this.sourceSystem;
        }
        ),
        catchError(this.handleHTTPError)
      );
  }
  getStatus(): Observable<any> {
    let getUrl = 'api/mdocument/requeststatus';
    return this.http.get<any>(getUrl, { observe: "response", headers: this.generateRequestHeaders() })
      .pipe(
        map(res => {
          this.requestStatus = res.body.RequestStatuses;
          return this.requestStatus;
        }
        ),
        catchError(this.handleHTTPError)
      );
  }

  getTransactionDetails(uuid: any): Observable<any> {
    let getUrl = 'api/mdocument/detailsummary/' + uuid;
    let logData: any[] = [];
    return this.http.get<any>(getUrl, { observe: "response", headers: this.generateRequestHeaders() })
      .pipe(
        map(res => {
          res.body.orchestrationRequestLogs.forEach(log => { logData.push(log) })
          return {
            logs: logData,
            responsePayload: JSON.parse(res.body.responsePayload)
          }
        }
        ),
        catchError(this.handleHTTPError)
      )
  }

  resetStatus(request: any): Observable<any> {

    let postUrl: string;
    if (this.isAuthorized('resetRequest')) {
      postUrl = 'api/mdocument/resets';
    } else {
      // In the unlikely event someone has hacked the UI to allow them to execute this method, this will block their effort.
      postUrl = '';
    }

    var settings: any = {
      body: {
        requestIds:
          request
      },
      observe: 'body',
      responseType: 'json'
    };
    return this.http.post<any>(postUrl, settings.body, { observe: "response", headers: this.generateRequestHeaders() })
      .pipe(
        map(res => {
          return res.body.OrchestrationResetResponse;
        }
        ),
        catchError(this.handleHTTPError)
      );
  }
  getEnvironment(): Observable<any> {
    let getUrl = 'api/mdocument/environment';
    return this.http.get<any>(getUrl, { observe: "response", headers: this.generateRequestHeaders() })
      .pipe(
        map(res => {
          return res.body;
        }
        ),
        catchError(this.handleHTTPError)
      );
  }

  getRequestPayload(uuid: any): Observable<any> {
    let getUrl = 'api/mdocument/requestpayload/' + uuid;
    return this.http.get<any>(getUrl, { observe: "response", headers: this.generateRequestHeaders() })
      .pipe(
        map(res => {
          return JSON.parse(res.body.requestPayload);
        }
        ),
        catchError(this.handleHTTPError)
      )
  }
}

