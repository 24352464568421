//Angular Imports
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

//Third Party Imports
import { AgGridModule } from "ag-grid-angular";
import { NgSelectModule } from "@ng-select/ng-select";

//Internal Imports
import { FrameworkModule, BooleanFilterComponent } from "../../framework";

import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { ImageRightComponent } from "./components/imageRight.component";
import { ImageRightRoutingModule } from "./imageRight.routing";
import { FormPageModule } from "../../form-page/form-page.module";
import { NgbModule, NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import { MatButtonModule } from "@angular/material/button";

/**
  Forms Module
*/
@NgModule({
  declarations: [ImageRightComponent],
  imports: [
    ImageRightRoutingModule,
    CommonModule,
    FormsModule,
    NgSelectModule,
    FrameworkModule,
    NgbModule,
    AgGridModule.withComponents([BooleanFilterComponent]),
    MatButtonToggleModule,
    MatSlideToggleModule,
    FormPageModule,
    MatButtonModule,
  ],
  providers: [],
})
export class ImageRightModule {}
