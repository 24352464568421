<ng-template #uploadFilesModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{uploadTitle}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismissModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="row align-items-center justify-content-center loading-row" *ngIf="isLoading">
      <div class="col-md-4">
        <div>
          <mat-progress-spinner mode="indeterminate" diameter="60" class="mx-auto">
          </mat-progress-spinner>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="!isLoading && !isReady">
      <p id="upload-data-error-alert" class="col-md-12 alert mdo-alert-danger error-bar">There was an error with retrieving the data needed to upload a file.  Click <a class="mdo-alert-danger-link" href="" (click)="retryPopulateOptions(uploadContext)">here</a> to retry.</p>
    </div>

    <div class="container-fluid" [formGroup]="uploadJobFileForm" *ngIf="!isLoading && isReady && (uploadContext == 'Job' || uploadContext == 'Staging')">

      <div class="row">
        <div class="col-md-4">
          <h6 class="job-step-label">Step 1 - Select Destination</h6>
        </div>
        <div class="col-md-6">
          <mat-form-field id="mdo-upload-files-destination">
            <mat-label>Destination:</mat-label>
            <mat-select required formControlName="destination" required>
              <mat-option class="mdo-mat-option" *ngFor="let dest of currentDestinationOptions" [value]="dest">{{dest}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>


      <div class="row">
        <div class="col-md-4">
          <h6 class="job-step-label">Step 2 - Select Partner</h6>
        </div>
        <div class="col-md-6">
          <mat-form-field id="mdo-upload-files-job-partner">
            <mat-label>Partner:</mat-label>
            <mat-select required formControlName="partner" required>
              <mat-option class="mdo-mat-option" *ngFor="let partner of uploadOptions.partners" [value]="partner">{{partner}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <h6 class="job-step-label">Step 3 - Select Product</h6>
        </div>
        <div class="col-md-6">
          <mat-form-field id="mdo-upload-files-job-product">
            <mat-label>Product:</mat-label>
            <mat-select required formControlName="product" required [disabled]="!uploadJobFileForm.controls.partner.value">
              <mat-option class="mdo-mat-option" *ngFor="let product of currentProductOptions" [value]="product.productId">{{product.productName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <h6 class="job-step-label">Step 4 - Select File</h6>
        </div>
        <div class="col-md-6">
          <button id="select-btn" mat-raised-button class="mdo-standard-button" (click)="clearFileSelectionData(); fileUploadInput.click()" [disabled]="!uploadJobFileForm.controls.product.value || uploadInProgress">Select File</button>
          <input hidden #fileUploadInput type="file" (change)="onFileSelected($event)" />
        </div>
      </div>

      <div class="row" id="selected-file-container" *ngIf="selectedFilename.length">
        <div class="col-md-4">
          <h6 class="job-step-label">Step 4 - Upload File</h6>
        </div>
        <div class="col-md-6">

          <strong>{{selectedFilename}}</strong>
          <div class="row">
            <div class="col-md-12" id="upload-container" *ngIf="selectedFilename.length && !uploadInProgress && !uploadSuccess">
              <button id="upload-btn" mat-raised-button class="mdo-standard-button" (click)="performUpload()" [disabled]="!readyToUpload">Click To Upload</button>
            </div>

            <div class="col-md-1" *ngIf="uploadInProgress" style="margin-top:10px;">
              <mat-progress-spinner mode="indeterminate" diameter="20" class="mx-auto">
              </mat-progress-spinner>
            </div>
          </div>

          <div id="upload-success-msg" *ngIf="uploadSuccess" class="alert mdo-alert-success">
            {{uploadSuccessMsg}}
          </div>

        </div>
      </div>

      <div class="row" *ngIf="showErrors()">

        <div id="validation-errors" class="col-md-12 alert mdo-alert-danger upload-error-container" *ngIf="validationErrors.length">
          <p>Please fix the following issue(s):</p>
          <ul>
            <li *ngFor="let err of validationErrors">{{err}}</li>
          </ul>
        </div>

        <div id="upload-error" class="col-md-12 alert mdo-alert-danger upload-error-container" *ngIf="uploadErrorMsgs.length">
          <p *ngIf="uploadErrorMsgs.length == 1">{{uploadErrorMsgs[0]}}</p>
          <div *ngIf="uploadErrorMsgs.length > 1">
            <p>The following errors occurred:</p>
            <ul>
              <li *ngFor="let msg of uploadErrorMsgs">{{msg}}</li>
            </ul>
          </div>
        </div>

      </div>

      <div class="row" *ngIf="uploadJobFileForm.controls.product.value">
        <div id="job-file-instructions" class="col-md-12 alert requirements-info-block">
          <p>File requirements:</p>
          <ul>
            <li>The file must have one of the following file extensions: {{extensionList}}.</li>
            <li *ngIf="currentAllowedFilenames.length">
              The name of the file you select must begin with one of the following prefixes:
              <ul>
                <li *ngFor="let filename of currentAllowedFilenames">{{filename}}</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

    </div>
  </div>
</ng-template>

