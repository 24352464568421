//Angular Imports 
import { Component, HostBinding } from '@angular/core';

//Third Party Imports
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

//Internal Imports 


//import 'rxjs/Rx';
import { AppInsightsService, EnvironmentService, DateTimeUtcPipe, SharedService, ApplicationInsightsBaseComponent } from '../../../framework'; 
import { DiagnosticsMainService } from '../services/diagnostic-main.services';
import { RuleEngineLog } from '../../testSuites/execution/models/rule-engine-log.model';
import { QueueLog } from '../../../ghostPortal/queue/models/queueLog';
import { forkJoin, Observable, of } from 'rxjs';
import { TestHarnessLog } from '../../testSuites/testHarness/models/testHarness-log.model';
import { catchError, map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { DiagnosticLog, TestHarnessLogWithEnv, IssuanceLog } from '../models/diagnosticLog';
import { QueueError } from '../../../ghostPortal/queue/components/queueError/queueError.component';
import { TestCaseError } from '../../testSuites/testHarness/components/testCaseError/testCaseError.component';
import { QueueDetailComponent } from '../../../ghostPortal/queue/components/queueDetails/queueDetails.component';
import { AttachLogDetail } from './attach-log-detail/attach-log-detail.component';
import { GenLogDetail } from './gen-log-detail';
import { EXECUTION_ENGINE_LIST } from '../../configs/execution-engine.config';
import { NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment-timezone';


/**
 * Forms Component
*/
@Component({
  selector: 'app-diagnostics-main',
  templateUrl: './diagnostics-main.component.html',
  styleUrls: ['./diagnostics-main.component.scss'],
  providers: [DiagnosticsMainService]
})

export class DiagnosticsMainComponent extends ApplicationInsightsBaseComponent {

  @HostBinding('style.width') width: string;

  initialRowDataLoad$;

  // Env config and system selection
  envList: any[];
  selectedEnv: string;
  public callingSystem: string;
  public dealNumber: string;
  public attachLogs: RuleEngineLog[] = [];
  public queueLogs: QueueLog[] = [];
  public genLogs: TestHarnessLogWithEnv[] = [];
  public issueLogs: IssuanceLog[] = [];
  public combinedLogs: DiagnosticLog[] = [];
  public recentSearched: string[] = [];
  //Result grids
  resultsGridOptions: GridOptions;


  /** 
   * Constructor
   * @ignore
  */
  constructor(private _appInsightsService: AppInsightsService,
    private toastr: ToastrService,
    private _modal: NgbModal,
    private sharedService: SharedService,
    private dateTimePipe: DateTimeUtcPipe,
    private diagnosticsService: DiagnosticsMainService,
    private environmentService: EnvironmentService,
    private router: Router  ) {
    super(_appInsightsService);
    this.width = "100%";
    this.envList = EXECUTION_ENGINE_LIST;
    this.selectedEnv = this.envList[0].Name;
    router.events.subscribe((event => {
      if (event instanceof NavigationEnd) {
        if (localStorage.getItem('recentSearches') !== null) {
          this.recentSearched = JSON.parse(localStorage.getItem('recentSearches'));
        }
      }
    }));
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
  */
  ngOnInit(): void {
    //Initialize grid and set default system to e2
    this.configureResultsGrid();
    this.callingSystem = "N/A";
  }

  /**
   * NgOnDestroy
   * @ignore
  */
  ngOnDestroy(): void {
    window.removeEventListener('resize', this.resizeGrid);
  }
  //On window resize resizes grid to avoid weird ui issues 
  private resizeGrid = () => { this.resultsGridOptions.api.sizeColumnsToFit(); }


  async generateAttachLogs() {
    //Doc attachment log request, already searches across all envs
    var response = await this.diagnosticsService.getLogsByDealNumber(this.dealNumber).toPromise();
    this.sharedService.hideLoader();
    if (response) {
      //console.log(response);
      this.attachLogs = response;
    }
  }

  generateQueueLogs() {
    // search by deal number across all environments through DEV2 Queue api
    let logs = this.diagnosticsService.getQueueStatus(this.dealNumber).pipe(catchError(error => of(error)));
    return logs;
  }
  generateIssuanceLogs() {
    let issueDev1 = this.diagnosticsService.searchExtensionLogs("DEV1", this.dealNumber).pipe(catchError(error => of(error)));
    let issueDev2 = this.diagnosticsService.searchExtensionLogs("DEV2", this.dealNumber).pipe(catchError(error => of(error)));
    let issueQa1 = this.diagnosticsService.searchExtensionLogs("QA1", this.dealNumber).pipe(catchError(error => of(error)));
    let issueQa2 = this.diagnosticsService.searchExtensionLogs("QA2", this.dealNumber).pipe(catchError(error => of(error)));
    let issueUAT1 = this.diagnosticsService.searchExtensionLogs("UAT1", this.dealNumber).pipe(catchError(error => of(error)));
    let issueUAT2 = this.diagnosticsService.searchExtensionLogs("UAT2", this.dealNumber).pipe(catchError(error => of(error)));
    let issueFit = this.diagnosticsService.searchExtensionLogs("FIT", this.dealNumber).pipe(catchError(error => of(error)));
    let issueMirror = this.diagnosticsService.searchExtensionLogs("MIRROR", this.dealNumber).pipe(catchError(error => of(error)));
    let issueProd = this.diagnosticsService.searchExtensionLogs("PROD", this.dealNumber).pipe(catchError(error => of(error)));
    
    return forkJoin(issueDev1, issueDev2, issueQa1, issueQa2, issueUAT1, issueUAT2, issueMirror, issueFit, issueProd);
  }

  generateGenTokens() {
    let dev1token = "";
    let dev2token = "";
    let qa1token = "";
    let qa2token = "";
    let uat1token = "";
    let uat2token = "";
    let fittoken = "";
    let mirrortoken = "";
    let prodtoken = "";



    //Forkjoin results return in request order so we can be confident the right jwt token is being assigned based on index
    return forkJoin(this.diagnosticsService.getJwt("DEV1").pipe(catchError(error => of(error))),
      this.diagnosticsService.getJwt("DEV2").pipe(catchError(error => of(error))),
      this.diagnosticsService.getJwt("QA1").pipe(catchError(error => of(error))),
      this.diagnosticsService.getJwt("QA2").pipe(catchError(error => of(error))),
      this.diagnosticsService.getJwt("UAT1").pipe(catchError(error => of(error))),
      this.diagnosticsService.getJwt("UAT2").pipe(catchError(error => of(error))),
      this.diagnosticsService.getJwt("FIT").pipe(catchError(error => of(error))),
      this.diagnosticsService.getJwt("MIRROR").pipe(catchError(error => of(error))),
      this.diagnosticsService.getJwt("PROD").pipe(catchError(error => of(error))));

  }

  generateGenLogs(dev1token: string, dev2token: string, qa1token: string, qa2token: string, uat1token: string, uat2token: string, fittoken: string, mirrortoken: string, prodtoken:string) {
    let genDEV1 = this.diagnosticsService.searchLogs(this.dealNumber, "DEV1", dev1token).pipe(catchError(error => of(error)));
    let genDEV2 = this.diagnosticsService.searchLogs(this.dealNumber, "DEV2", dev2token).pipe(catchError(error => of(error)));
    let genQA1 = this.diagnosticsService.searchLogs(this.dealNumber, "QA1", qa1token).pipe(catchError(error => of(error)));
    let genQA2 = this.diagnosticsService.searchLogs(this.dealNumber, "QA2",  qa2token).pipe(catchError(error => of(error)));
    let genUAT1 = this.diagnosticsService.searchLogs(this.dealNumber, "UAT1", uat1token).pipe(catchError(error => of(error)));
    let genUAT2 = this.diagnosticsService.searchLogs(this.dealNumber, "UAT2",uat2token).pipe(catchError(error => of(error)));
    let genFIT = this.diagnosticsService.searchLogs(this.dealNumber, "FIT", fittoken).pipe(catchError(error => of(error)));
    let genMIRROR = this.diagnosticsService.searchLogs(this.dealNumber, "MIRROR",  mirrortoken).pipe(catchError(error => of(error)));
    let genPROD = this.diagnosticsService.searchLogs(this.dealNumber, "PROD", prodtoken).pipe(catchError(error => of(error)));
    return forkJoin(genDEV1, genDEV2, genQA1, genQA2, genUAT1, genUAT2, genFIT, genMIRROR, genPROD);

  }

  async searchLogs() {
    //Using submission number from input, search all document systems for logs containing submission number and populate in grid
    //Initialize values for auth tokens for GDF

    this.resultsGridOptions.api.showLoadingOverlay();
    if (!this.recentSearched.includes(this.dealNumber)) {
      this.recentSearched.push(this.dealNumber);
      localStorage.setItem('recentSearches', JSON.stringify(this.recentSearched));
    }
    

    let dev1token = "";
    let dev2token = "";
    let qa1token = "";
    let qa2token = "";
    let uat1token = "";
    let uat2token = "";
    let fittoken = "";
    let mirrortoken = "";
    let prodtoken = "";
    this.combinedLogs = [];
    this.attachLogs = [];
    this.genLogs = [];
    this.queueLogs = [];
    this.issueLogs = [];
    if (this.dealNumber == null || this.dealNumber == "") {
      this.toastr.error("Enter a policy number to search logs", "Data Error");
    }
    else {
      this.sharedService.globalTestRunStatus = "Log Search In Progress";
      //FAST logs already search across all systems, so place inside async function and perform call
      await this.generateAttachLogs();
      //console.log(this.attachLogs);
      //Get document queue logs, need to populate global list inside subscribe because it is synchorous with function execution
      let results = this.generateQueueLogs().subscribe(res => {
        for (var i = 0; i < res.length; i++) {
            let log: QueueLog = res[i];
            this.queueLogs.push(log);
        }
        console.log(this.queueLogs);

        //For GDF we get JWT tokens for all environments first, can guarantee order based on ForkJoin order so set tokens accordingly
        this.generateGenTokens().subscribe(tokens => {
          dev1token = tokens[0];
          dev2token = tokens[1];
          qa1token = tokens[2];
          qa2token = tokens[3];
          uat1token = tokens[4];
          uat2token = tokens[5];
          fittoken = tokens[6];
          mirrortoken = tokens[7];
          prodtoken = tokens[8];
          //Using tokens, generate GDF logs, need nested subscription so grid does not generate until all data is populated from service calls
          this.generateGenLogs(dev1token, dev2token, qa1token, qa2token, uat1token, uat2token, fittoken, mirrortoken, prodtoken).subscribe(logs => {
            logs[0] = logs[0].map(item => {
              return { ...item, Environment: "DEV1" };
            });
            logs[1] = logs[1].map(item => {
              return { ...item, Environment: "DEV2" };
            });
            logs[2] = logs[2].map(item => {
              return { ...item, Environment: "QA1" };
            });
            logs[3] = logs[3].map(item => {
              return { ...item, Environment: "QA2" };
            });
            logs[4] = logs[4].map(item => {
              return { ...item, Environment: "UAT1" };
            });
            logs[5] = logs[5].map(item => {
              return { ...item, Environment: "UAT2" };
            });
            logs[6] = logs[6].map(item => {
              return { ...item, Environment: "FIT" };
            });
            logs[7] = logs[7].map(item => {
              return { ...item, Environment: "MIRROR" };
            });
            logs[8] = logs[8].map(item => {
              return { ...item, Environment: "PROD" };
            });
            this.genLogs = logs.flat(1);
            //console.log(this.genLogs);
            this.generateIssuanceLogs().subscribe(ext => {
              //set Issuance Logs
              for (var i = 0; i < ext.length; i++) {
                let arr = ext[i];
                if (Array.isArray(arr)) {
                  for (var j = 0; j < arr.length; j++) {
                    let log: IssuanceLog = arr[j];
                    this.issueLogs.push(log);
                  }
                }
                
              }
              console.log(this.issueLogs);

              //Combine results into shared model for grid
              let attachIndex = 0;
              this.attachLogs.forEach(log => {
                let diagAttachLog: DiagnosticLog = new DiagnosticLog();
                diagAttachLog.InternalID = attachIndex.toString();
                log.PolicyNumber = attachIndex.toString();
                attachIndex++;
                diagAttachLog.Timestamp = log.Timestamp;
                diagAttachLog.Environment = log.Environment;
                diagAttachLog.ActionType = log.ActionType;
                diagAttachLog.SourceSystem = "Document Attachment";
                diagAttachLog.HasError = "N/A";
                diagAttachLog.CallingSystem = log.CallingSystem;
                if (diagAttachLog.CallingSystem == '20') {
                  diagAttachLog.CallingSystem = 'e2';
                }
                this.combinedLogs.push(diagAttachLog);
              });
              let queueIndex = 0;
              this.queueLogs.forEach(log => {
                let diagQueueLog: DiagnosticLog = new DiagnosticLog();
                diagQueueLog.InternalID = queueIndex.toString();
                log.PartitionKey = queueIndex.toString();
                queueIndex++;
                diagQueueLog.Timestamp = log.Timestamp.toLocaleString();
                diagQueueLog.Environment = log.Environment;
                diagQueueLog.ActionType = "N/A";
                diagQueueLog.SourceSystem = "Document Queue";
                diagQueueLog.CallingSystem = log.CallingSystem;
                if (!isNullOrUndefined(log.Error)) {
                  diagQueueLog.HasError = "View Error";
                  diagQueueLog.Error = log.Error;
                }
                else {
                  diagQueueLog.HasError = "No";
                }
                this.combinedLogs.push(diagQueueLog);
              });
              this.genLogs.forEach(log => {
                console.log(log);
                if (!isNullOrUndefined(log.TimeStamp)) {
                  let diagGenLog: DiagnosticLog = new DiagnosticLog();
                  diagGenLog.Timestamp = log.TimeStamp;
                  let tempTimeStamp = new Date(diagGenLog.Timestamp + "Z");
                  diagGenLog.Timestamp = tempTimeStamp.toString();

                  diagGenLog.Environment = log.Environment;
                  diagGenLog.ActionType = log.ActionType;
                  diagGenLog.CallingSystem = log.CallingSystem;
                  diagGenLog.SourceSystem = "Document Generation";
                  if (log.hasError) {
                    diagGenLog.HasError = "View Error";
                    diagGenLog.Error = log.ErrorMessage;
                  }
                  else {
                    diagGenLog.HasError = "No";
                  }
                  this.combinedLogs.push(diagGenLog);
                }

              });
              this.issueLogs.forEach(log => {
                if (!isNullOrUndefined(log)) {
                  if (log.Level == "Error") {
                    let diagIssueLog: DiagnosticLog = new DiagnosticLog();
                    diagIssueLog.Timestamp = log.Date;
                    diagIssueLog.Environment = log.HostName;
                    diagIssueLog.ActionType = "N/A";
                    diagIssueLog.SourceSystem = "Policy Issuance";
                    if (log.Level == "Error") {
                      diagIssueLog.HasError = "View Error";
                      diagIssueLog.Error = log.Message;
                    }
                    else {
                      diagIssueLog.HasError = "No";
                    }
                    this.combinedLogs.push(diagIssueLog);
                  }
                }
              });
              this.sharedService.globalTestRunStatus = "Log Search Completed";
              this.toastr.success("Log Search Has Been Completed", "Completed");
              this.resultsGridOptions.api.setRowData(this.combinedLogs);
            });


          });
        });

      });


      


    }
  }

  private configureResultsGrid(): void {
    //this.initialRowDataLoad$ = [];
    this.resultsGridOptions = <GridOptions>{
      overlayLoadingTemplate:
        '<div class="loadingx" style="margin: 7em"><span class="ag-overlay-loading-center " style="font-size: 18px; z-index: 100000"> Loading Logs ...</span></div> ',
      rowSelection: "single",
      domLayout: 'normal',
      columnDefs: this.createResultsColumnDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      paginationPageSize: 20,
      rowHeight: 30,
      enableColResize: true,
      onGridReady: () => {
        this.resultsGridOptions.api.setRowData([]);
        //this.resultsGridOptions.api.setRowData(this.allResults);
        this.resultsGridOptions.api.sizeColumnsToFit();
        window.addEventListener('resize', this.resizeGrid);
        if (!isNullOrUndefined(window.history.state.policyNumber)) {
          this.dealNumber = window.history.state.policyNumber;
          this.searchLogs();
        }
        //this.resultsGridOptions.columnApi.autoSizeAllColumns();
      },
      onRowClicked: (event) => {
        this.onResultsRowClick(event);
      },
      onFilterChanged: (event) => {
      },
      onSortChanged: (event) => {
      },
      onFilterModified: function (event) {
        console.log(event);
      }
    };
  }

  onResultsRowClick(e) {
    //If 'View Error' hyperlink was clicked display error of log in popup, adjust view based on document system
    //If preview button was clicked, identify system and display log details for selected row and system
    if (e.event.target !== undefined) {
      let data = e.data;
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "Error":
          if (data.System == "Document Queue") {
            const modalRef = this._modal.open(QueueError, { windowClass: 'xl' });
            modalRef.componentInstance.errorText = data.Error;
            break;
          }
          else {
            const modalRef = this._modal.open(TestCaseError, { windowClass: 'xl' });
            modalRef.componentInstance.errorText = data.Error;
            break;
          }
        case "Preview":
          if (data.System == "Document Queue") {
            let selectedLog = this.queueLogs.find(x => x.PartitionKey == e.data.InternalID);
            const modalRefDetail = this._modal.open(QueueDetailComponent, { size: 'xl' });
            console.log(selectedLog);
            modalRefDetail.componentInstance.log = selectedLog;
          }
          else if (data.System == "Document Attachment") {
            let selectedLog = this.attachLogs.find(x => x.PolicyNumber == e.data.InternalID);
            const modalRef = this._modal.open(AttachLogDetail, { windowClass: 'test-case-detail-modal' });
            console.log(selectedLog);
            modalRef.componentInstance.logItem = selectedLog;
            modalRef.componentInstance.actionType = "Read";
            modalRef.result.then(res => {
              if (!isNullOrUndefined(res) && res == 'Test') {
                this.router.navigate(['/diagnostics/docAttachTesting'], {
                  state: { log: selectedLog }
                });
              }
            });
          }
          else if (data.System == "Document Generation") {
            let selectedLog = this.genLogs.find(x => x.InternalID == e.data.InternalID);
            const modalRef = this._modal.open(GenLogDetail, { windowClass: 'test-case-detail-modal' });
            console.log(selectedLog);
            modalRef.componentInstance.testLog = selectedLog;
            modalRef.result.then(res => {
              if (!isNullOrUndefined(res) && res == 'Test') {
                this.router.navigate(['/diagnostics/docGenTesting'], {
                  state: { log: selectedLog }
                });
              }
            });
          }
          
         
      }

    }
  }

  createResultsColumnDef():any[] {
    return [
      {
        headerName: 'Timestamp', field: 'Timestamp', sort: 'desc', sortable:true, filter: 'dateFilterComponent', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          let newDateA = new Date(valueA).getTime();
          let newDateB = new Date(valueB).getTime();
          return newDateA - newDateB;
        },
        cellRenderer: (data) => {
          return data.value ? (new Date(data.value)).toLocaleString() : '';
        }
      },
      { headerName: 'System', field: 'System', filter: 'agTextColumnFilter', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'Environment', field: 'Environment', filter: 'agTextColumnFilter', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'Action Type', field: 'ActionType', filter: 'agTextColumnFilter', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'Calling System', field: 'CallingSystem', filter: 'agTextColumnFilter', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      {
        headerName: 'Has Error',
        field: 'HasError',
        suppressSorting: true,
        filter: 'agTextColumnFilter',
        cellRenderer: function (params) {
          if (params.data.HasError == "View Error") {
            return `<span>
            <a class="transaction-detail-link" data-action-type="Error">${params.data.HasError}</a>
          </span>`
          }
          else {
            return params.data.HasError;
          }
         
        }
      },
      {
        headerName: "Actions",
        width: 70,
        suppressMenu: true,
        suppressSorting: true,
        filter: 'booleanFilterComponent',
        cellRenderer: params => {
          if (params.data.System == "Policy Issuance") {
            return ``;
          }
          else {
            return `<img src="/assets/images/preview_icon.png" data-action-type="Preview" class="cursor_pointer" title="Preview">`;
          }
        }
      }
    ]
  }

  //Set submission label appropriate for each system
  onSelectCallingSystem(callingSystem: string) {
    this.callingSystem = callingSystem;
    document.getElementById("logIdLabel").innerHTML = `${this.GetLogIdBySystem(callingSystem)}: `;
  }

  private GetLogIdBySystem(callingSystem: string) {
    let logIdName = "";
    switch (callingSystem) {
      case "e2":
        logIdName = "Deal Number";
        break;
      case "MOL1":
        logIdName = "Submission Number";
        break;
      case "MPOL":
        logIdName = "Submission Number";
        break;
      case "Claims":
        logIdName = "Submission Number:";
        break;
      case "DocPortal":
        logIdName = "Policy Number";
        break;
      case "Magic":
        logIdName = "Policy Number";
        break;
      case "Maverick":
        logIdName = "Policy Number";
        break;
    }
    return logIdName;
  }

  loadRecentSearch(event: string): void {
    this.dealNumber = event;
    this.searchLogs();
  }



}
