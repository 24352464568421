//Angular Imports
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

//Internal Imports 
import { CPMElementsComponent } from './components';

/**
  Rules Routes
*/
export const routes: Routes = [
  {
    path: '', component: CPMElementsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class CPMElementsRoutingModule {
}
