<div class="component-container">
  <div class="component-heading-container">
    <h3 class="component-heading-text">View Oden Transaction Data</h3>
  </div>
  <div class="component-content-view">
    <div class="container-fluid">
      <div id="policy-form-container" class="row">
        <div class="col-md-12">
          <div
            class="heading-container fast_admin"
            id="policy-form-heading-container"
          >
          </div>
        </div>
        <div id="policy-form-content-container" class="col-md-12 mrgbtm10">
          <ag-grid-angular
            class="ag-theme-balham fast_panel_table"
            [gridOptions]="formsGridOptions"
          >
          </ag-grid-angular>
        </div>
      </div>
    </div>
  </div>
</div>
