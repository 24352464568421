//Angular Imports 
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

//Internal Imports
import { EmailComponent } from './components/email.component';

/**
  Form Routes
*/
export const routes: Routes = [
  {
    path: '', component: EmailComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})

export class EmailRoutingModule {
}
