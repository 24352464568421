//Angular Imports
import { Injectable } from "@angular/core";

//Internal Imports
import { UserProfile, ApplicationRole, EnvironmentSpecifics } from '../models';
import { EnvironmentService } from './environment.service';
import { UserProfileService } from './user-profile.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  //public profile: UserProfile;
  private environment: EnvironmentSpecifics;


  constructor(private profileService: UserProfileService, private environmentService: EnvironmentService) {
    this.environment = this.environmentService.environmentDetails;
  }

  private get isDevEnvironment(): boolean {
    return this.environment.ISDEVELOPEMENT;
  }

  private get isTestEnvironment(): boolean {
    return this.environment.ISTEST;
  }

  private get isProdEnvironment(): boolean {
    return this.environment.ISPRODUCTION;
  }

  private get isCurrentEnvironmentPublisher(): boolean {
    if (this.isDevEnvironment) {
      if (this.hasRole(ApplicationRole.DevPublisher)) { return true; }
    }
    else if (this.isTestEnvironment) {
      if (this.hasRole(ApplicationRole.TestPublisher)) { return true; }
    }
    else if (this.isProdEnvironment) {
      if (this.hasRole(ApplicationRole.ProdPublisher)) { return true; }
    }
    else {
      throw "Invalid environment setting.";
    }

    return false;
  }

  private hasRole(role: ApplicationRole): boolean {
    // TODO:  test code - REMOVE
    //return true;

    if (this.profileService.UserProfile) {
      //console.log("In AuthorizationService hasRole:", this.profileService.UserProfile.Roles);
      if (this.profileService.UserProfile.Roles.indexOf(role) >= 0) {
        return true;
      }
      else {
        return false;
      }
    } else {
      return false;
    }

  }

  //Checks if an IC user has a fast role
  public HasFastRole() {
    //User has both IC roles, fail
    if (this.profileService.UserProfile.Roles.length == 2 && this.hasRole(ApplicationRole.ICUser) && this.hasRole(ApplicationRole.ICAdministrator)) {
      return false
    }
    //User has an ic role and a fast role, pass
    else if (this.isIC() && this.profileService.UserProfile.Roles.length > 1) {
      return true;
    }
    //User has an mint role and a fast role, pass
    else if (this.isMINT() && this.profileService.UserProfile.Roles.length > 1) {
      return true;
    }
    //User is not a markel user and has at least one role
    else if (!this.MarkelUser() && this.profileService.UserProfile.Roles.length > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  private isLoggedIn(): boolean {
    if (this.profileService.LoggedIn) {
      return true;
    }
    else {
      return false;
    }
  }

  public RulesRead(): boolean {

    return this.isLoggedIn();
  }

  public RulesWrite(): boolean {
    if (this.hasRole(ApplicationRole.User) || this.hasRole(ApplicationRole.Administrator)) {
      return true;
    }
    else {
      return false;
    }
  }

  public FormsRead(): boolean {

    return this.isLoggedIn();
  }

  public FormsWrite(): boolean {
    if (this.hasRole(ApplicationRole.User) || this.hasRole(ApplicationRole.Administrator)) {
      return true;
    }
    else {
      return false;
    }
  }

  public RuleTypesRead(): boolean {

    return this.isLoggedIn();
  }

  public MPLUserOnly(): boolean {
    if (this.hasRole(ApplicationRole.MPLUser) && this.profileService.UserProfile.Roles.length == 1) {
      return true;
    }
    else {
      return false;
    }
  }

  public PackageCreate(): boolean {
    // MATS-8520 - Modify this permission so anyone with the User role can create a package.

    if (this.hasRole(ApplicationRole.User) || this.hasRole(ApplicationRole.Administrator) || this.hasRole(ApplicationRole.MPLUser)) {
      return true;
    }
    else {
      return false;
    }
  }

  public PackageDeployAny(): boolean {
    if (this.hasRole(ApplicationRole.User) || this.hasRole(ApplicationRole.Administrator) ||
      this.hasRole(ApplicationRole.ProdPublisher) || this.hasRole(ApplicationRole.TestPublisher) || this.hasRole(ApplicationRole.MPLUser)) {
      return true;
    }
    else {
      return false;
    }
  }

  public PackageDeployProd(): boolean {
    if (this.hasRole(ApplicationRole.Administrator) || this.hasRole(ApplicationRole.ProdPublisher)) {
      return true;
    }
    else {
      return false;
    }
  }

  public Tester(): boolean {
    if (this.hasRole(ApplicationRole.Administrator) || this.hasRole(ApplicationRole.TestPublisher)) {
      return true;
    }
    else {
      return false;
    }
  }

  public CanImport(): boolean {
    if (this.hasRole(ApplicationRole.DevPublisher) || this.hasRole(ApplicationRole.TestPublisher) || this.hasRole(ApplicationRole.ProdPublisher)) {
      return true;
    }
    else {
      return false;
    }
  }

  //TODO: Change restriction to ghostportal devs only when roles are added
  public MarkelUser(): boolean {
    if (this.hasRole(ApplicationRole.MarkelUser)) {
      return true;
    }
    else {
      return false;
    }
  }
  public ghostPortalReader(): boolean {
    if (this.hasRole(ApplicationRole.GhostPortalReader)) {
      return true;
    }
    else {
      return false;
    }
  }
  //If user only has ghostportalreader access restrict them from all other routes except ghostPortal/forms
  public ghostPortalReaderOnly(): boolean {
    if (this.hasRole(ApplicationRole.GhostPortalReader) && this.profileService.UserProfile.Roles.length == 1) {
      return true;
    }
    else {
      return false;
    }
  }

  public readerOnly(): boolean {
    if (this.hasRole(ApplicationRole.Reader) && this.profileService.UserProfile.Roles.length == 1) {
      return true;
    }
    else {
      return false;
    }
  }

  public roleTest(): boolean {
    if (this.hasRole(ApplicationRole.User)) {
      return true;
    }
    else {
      return false;
    }
  }
  public isIC(): boolean {
    if (this.hasRole(ApplicationRole.ICUser) || this.hasRole(ApplicationRole.ICAdministrator)) {
      return true;
    }
    else {
      return false;
    }
  }
  public isMINT(): boolean {
    if (this.hasRole(ApplicationRole.MINTUser)) {
      return true;
    }
    else {
      return false;
    }
  }

  public ICAdministrator(): boolean {
    if (this.hasRole(ApplicationRole.ICAdministrator)) {
      return true;
    }
    else {
      return false;
    }
  }

  public Administrator(): boolean {
    if (this.hasRole(ApplicationRole.Administrator)) {
      return true;
    }
    else {
      return false;
    }
  }
}
