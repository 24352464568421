<div class="component-container" style="padding-bottom: 0 !important;">
  <div class="component-heading-container">
    <div class="col-md-12">
      <div class="row" style="padding-left: 10px">
        <h3 class="component-heading-text">Email Settings</h3>
      </div>
    </div>
  </div>
  <div class="container-fluid" style="padding: 10px">
    <div id="policy-form-container" class="row" style="margin: 5px">
      <!--<hr style="height:3px;background-color:lightgrey;width:100%" *ngIf="logSelected" />-->
      <div class="col-md-12">
        <div class="heading-container fast_admin" id="policy-form-heading-container">
          <label class="label_heading">System<span style="color : red">*</span> :</label>
          <div class="selectdiv select_wrapinline" style="width: 15%">
            <label>
              <ng-select class="custom-select" [items]="systems" (change)="onFilterChange(true)" [disabled]="this.isProd"
                [(ngModel)]="selectedSystem">
              </ng-select>
            </label>
          </div>
          <div [hidden]="!selectedSystem">
            <label class="label_heading" style="margin-left: 10px;">LOB:</label>
            <div class="selectdiv select_wrapinline" style="width: 20%">
              <label>
                <ng-select class="custom-select" [items]="lobs" (change)="onFilterChange()" [(ngModel)]="selectedLob" >
                </ng-select>
              </label>
            </div>
            </div>
            
            <label class="label_heading" style="margin-left: 10px;" [hidden]="!selectedSystem">Template Name:</label>
            <input type="text" [hidden]="!selectedSystem" style="padding: 14px 0 0 10px;
                        width: 15%;
                        border: 0;
                        border-bottom: 1px solid #999;
                        border-radius: 0;
                        font-size: 14px;
                        margin-left: 5px;
                        color: #616365;
                        background: transparent;" [formControl]="templateControl">
            <button type="button" mat-raised-button class="btn-teal" [disabled]="!selectedSystem"
              style="margin-right: 5px; float: right;" (click)="addRow()">
              Add Row
            </button>
          <!-- <label class="label_heading">Template Name:</label>
          <div class="selectdiv select_wrapinline" style="width: 15%">
            <label>
              <ng-select class="custom-select" [items]="systems" (change)="onSystemChange($event)"
                [(ngModel)]="selectedSystem">
              </ng-select>
            </label>
          </div> -->
          
            <!-- <button type="button" mat-raised-button class="mat_btn_hov_act" style="margin-right: 5px; float: right;" (click)="deleteRow()">
              Delete
            </button>
            <button type="button" mat-raised-button class="btn-teal" style="margin-right: 5px; float: right;" (click)="editRow1()">
              Edit
            </button>
            <button type="button" mat-raised-button class="btn-teal" style="margin-right: 5px; float: right;" (click)="addRow()">
              Add Row
            </button> -->
         
        </div>
       
      </div>
    </div>
    <div class="row">
      <div class="col-md-12" style="overflow-x:auto;height: calc(100vh - 195px);--blink-scrollbar-width: 7px;">
        <table id="email-table" mat-table [dataSource]="this.tableSource"
          style="margin-left: 0px !important; box-shadow: 0 0 3px #000; table-layout: fixed;">
          <ng-container [matColumnDef]="col.key" *ngFor="let col of tableSchema">
            <th mat-header-cell *matHeaderCellDef style="padding: 5px; width: 200px " [hidden]="col.label == 'No.'">
              <div class="ellipsis" [matTooltip]="col.label" matTooltipClass="tooltip-1">
                {{ col.label }}
              </div>
            </th>
            <td mat-cell *matCellDef="let element" style="padding-right: 0px; padding-left: 8px"
              [hidden]="col.label == 'No.'">
              <div [ngSwitch]="col.type" *ngIf="!element.isEdit">
                <ng-container *ngSwitchCase="'isSelected'">
                  <mat-checkbox (change)="element.isSelected = $event.checked"></mat-checkbox>
                </ng-container>
                <div class="btn-edit" *ngSwitchCase="'isEdit'">
                  <button type="button" mat-raised-button class="btn-teal"
                    (click)="element.isEdit = !element.isEdit" style="margin: 0 0 0 10px;">
                    Edit
                  </button>
                  <button type="button" mat-raised-button class="mat_btn_hov_act" (click)="removeRow(element.id)"
                    style="margin: 0 0 0 10px;">
                    Delete
                  </button>
                </div>
                <mat-checkbox [(ngModel)]="element[col.key]" *ngSwitchCase="'checkbox'">
                </mat-checkbox>
                <div [matTooltip]="element[col.key]" matTooltipClass="tooltip-1" *ngSwitchDefault class="ellipsis">
                  {{ element[col.key] }}
                </div>
              </div>

              <div [ngSwitch]="col.type" *ngIf="element.isEdit">
                <div class="btn-edit" *ngSwitchCase="'isEdit'">
                  <button type="button" mat-raised-button class="btn-teal"
                    (click)="element.isEdit = false; editRow(element)" [disabled]="disableSubmit(element.id)"
                    style="margin: 0 0 0 10px">
                    Done
                  </button>
                </div>
                <mat-checkbox [(ngModel)]="element[col.key]" *ngSwitchCase="'checkbox'">
                </mat-checkbox>
              

                <div class="selectdiv select_wrapinline" style="width: 100%" *ngSwitchCase="'select'">
                  <label>
                    <ng-select class="custom-select" [items]="lobs" [(ngModel)]="element[col.key]"
                      (change)="inputHandler($event, element.id, col.key)"
                      style="border: 0;border-bottom: 1px solid #999;border-radius: 0;font-size: 14px;padding: 9px 5px !important;">
                    </ng-select>
                  </label>
                </div>

                <mat-form-field class="form-input" *ngSwitchDefault style="width: 100%">
                  <mat-label>{{ col.label }}</mat-label>
                  <input matInput [required]="col.required" [pattern]="col.pattern" [type]="col.type"
                    [(ngModel)]="element[col.key]" (change)="inputHandler($event, element.id, col.key)" />
                </mat-form-field>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
