<div>
  <div class="modal-header">
    <h4 class="modal-title">Selected Forms</h4>
  </div>

  <div class="row">
    <div
      id="test-group-grid-container"
      class="col-12"
      style="padding-bottom: 10px"
    >
      <ag-grid-angular
        class="ag-theme-balham fast_panel_table"
        [gridOptions]="selectedFormsGridOptions"
        style="height: 400px; padding: 5px"
      >
      </ag-grid-angular>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      mat-raised-button
      class="mat_btn_hov_act mrgrgt10"
      (click)="this.removeSelected()"
    >
      Remove Selected Forms
    </button>
    <button
      type="button"
      mat-raised-button
      style="background-color: var(--color-teal); color: #fff"
      class="mat_btn_hov_act"
      (click)="activeModal.close(this.formsList)"
    >
      Close
    </button>
  </div>
</div>
