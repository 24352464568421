//Angular Imports
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatTabsModule } from "@angular/material/tabs";
//Third Party Imports
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { AgGridModule } from "ag-grid-angular";
import { MyDatePickerModule } from "mydatepicker";
import {
  FrameworkModule,
  DateFilterComponent,
  BooleanFilterComponent,
} from "../../framework";
import { UserNotesModule } from "../../user-notes/user-notes.module";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { DeployUtilityComponent } from "./components/deployUtility.component";
import { DeployUtilityService } from "./services/deployUtility.service";
import { DeployUtilityRoutingModule } from "./deployUtility.routing";
import { ErrorLog } from "./components/errorLog/errorLog.component";
import { DeployTestComponent } from "./components/deployTest/deployTest.component";
import { EditLog } from "./components/editLog/editLog.component";
import { TestHarnessService } from "../../diagnostics/testSuites/testHarness/services/testHarness.service";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { DuplicateComponent } from "./components/duplicate/duplicate.component";

/**
  Forms Module
*/
@NgModule({
  declarations: [
    DeployUtilityComponent,
    ErrorLog,
    EditLog,
    DeployTestComponent,
    DuplicateComponent
  ],
  imports: [
    MyDatePickerModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    NgSelectModule,
    FrameworkModule,
    UserNotesModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatFormFieldModule,
    DeployUtilityRoutingModule,
    AgGridModule.withComponents([DateFilterComponent, BooleanFilterComponent]),
  ],
  providers: [DeployUtilityService, TestHarnessService],
})
export class DeployUtilityModule { }
