export class FilterData {
  fromDate: string;
  toDate: string;
  requestStatus: string[] = [];
  sourceSystem: string[] = [];
  transactionId: string;

  constructor(objectData?: any) {
    this.fromDate = objectData.fromDate || "";
    this.toDate = objectData.toDate || "";
    this.requestStatus = objectData.requestStatus || [];
    this.sourceSystem = objectData.sourceSystem || [];
    this.transactionId = objectData.transactionId || "";
  }

  outputValues(): any {
    return {
      fromDate: this.fromDate,
      toDate: this.toDate,
      requestStatus: this.requestStatus.length > 0 ? this.requestStatus : null,
      sourceSystem: this.sourceSystem.length > 0 ? this.sourceSystem : null,
      transactionId: this.transactionId ? this.transactionId : null
    }
  }

}
