//Angular Imports 
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

//Third Party Imports
import { Observable, Subscriber } from "rxjs";
import { map } from "rxjs/operators";
import { EnvironmentService } from "../../../framework";
import { DeployHistoryLog, DocGenPackage } from "../models";


/**
  * Policy Forms Provider
*/
@Injectable()
export class DeployUtilityService {

  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient,
    private environmentService: EnvironmentService  ) {
  }

  getPackages(env: string, token: string): Observable<any> {
    var gurl = `https://mkl-mgdapi-${env.toLowerCase()}.azurewebsites.net/api/v2/deploy/packages/${env}`;
    if (env == "PROD" || env == "PROD-A" || env == "PROD-B") {
      gurl = `https://mkl-mgdapi-prod.azurewebsites.net/api/v2/deploy/packages/${env}`;
    }
    //var gurl = `https://localhost:64655/api/v2/deploy/packages/${env}`;
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', `Bearer ${token}`);
    return this.http.post<any>(gurl, { headers }).pipe(map(res => res));
  }

  getMappings(env: string, token: string): Observable<any> {
    var gurl = `https://mkl-mgdapi-${env.toLowerCase()}.azurewebsites.net/api/v2/deploy/mappings/${env}`;
    if (env == "PROD" || env == "PROD-A" || env == "PROD-B") {
      gurl = `https://mkl-mgdapi-prod.azurewebsites.net/api/v2/deploy/mappings/${env}`;
    }
    //var gurl = `https://localhost:64655/api/v2/deploy/mappings/${env}`;
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', `Bearer ${token}`);
    return this.http.post<any>(gurl, { headers }).pipe(map(res => res));
  }

  uploadPackages(env: string, token: string, packages: FormData, note: string, jira: string, folder: string, release: string) {
    var gurl = "";
    //gurl = `http://localhost:63161/api/v2/deploy/uploadPackage`;
    
    if (env == "PROD" || env == "PROD-A" || env == "PROD-B") {
      gurl = `https://mkl-mgdapi-prod.azurewebsites.net/api/v2/deploy/uploadPackage`;
    }
    else {
      gurl = `https://mkl-mgdapi-uat1.azurewebsites.net/api/v2/deploy/uploadPackage`;
      gurl = `https://mkl-mgdapi-uat2.azurewebsites.net/api/v2/deploy/uploadPackage`;
    }
    let params = new HttpParams().set('note', note).set('jira', jira).set('env', env).set('release', release).set('folder', folder);
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', `Bearer ${token}`);
    return this.http.post<any>(gurl, packages, { headers,params }).pipe(map(res => res));

  }

  checkDuplicate(env: string, token: string): Observable<any> {
    var gurl = "";
    //gurl = `http://localhost:63161/api/v2/cache/duplicate`;

    if (env == "PROD") {
      gurl = `https://mkl-mgdapi-prod.azurewebsites.net/api/v2/cache/duplicate`;
    }
    else {
      gurl = `https://mkl-mgdapi-uat1.azurewebsites.net/api/v2/cache/duplicate`;
      //gurl = `https://mkl-mgdapi-uat2.azurewebsites.net/api/v2/cache/duplicate`;
    }
    let params = new HttpParams().set('env', env);
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', `Bearer ${token}`);
    return this.http.get<any>(gurl, { headers, params }).pipe(map(res => res));

  }

  deletePackages(env: string, token: string, packages: FormData) {
    //var gurl = `https://localhost:64655/api/v2/deploy/deletePackages/${env}`;
    var gurl = "";
    if (env == "PROD" || env == "PROD-A" || env == "PROD-B") {
      gurl = `https://mkl-mgdapi-prod.azurewebsites.net/api/v2/deploy/deletePackages/PROD`;
    }
    else {
      gurl = `https://mkl-mgdapi-uat1.azurewebsites.net/api/v2/deploy/deletePackages/${env}`;
    }
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', `Bearer ${token}`);
    return this.http.post<any>(gurl, packages, { headers }).pipe(map(res => res));
  }

  uploadMappings(env: string, token: string, mappings: FormData, note: string, jira: string, folder: string, release: string) {
    var gurl = "";
   // gurl = `https://localhost:64655/api/v2/deploy/uploadMapping`;
    
    if (env == "PROD" || env == "PROD-A" || env == "PROD-B") {
      gurl = `https://mkl-mgdapi-prod.azurewebsites.net/api/v2/deploy/uploadMapping`;
      //gurl = `https://mkl-mgdapi-uat2.azurewebsites.net/api/v2/deploy/uploadMapping`;
    }
    else {
      gurl = `https://mkl-mgdapi-uat1.azurewebsites.net/api/v2/deploy/uploadMapping`;
      //gurl = `https://mkl-mgdapi-uat2.azurewebsites.net/api/v2/deploy/uploadMapping`;
    }
    let params = new HttpParams().set('note', note).set('jira', jira).set('env', env).set('release', release).set('folder', folder);
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', `Bearer ${token}`);
    return this.http.post<any>(gurl, mappings, { headers,params }).pipe(map(res => res));

  }

  deleteMappings(env: string, token: string, mappings: FormData) {
    //var gurl = `http://localhost:63161/api/v2/deploy/deleteMappings/${env}`;
    var gurl = "";
    if (env == "PROD" || env == "PROD-A" || env == "PROD-B") {
      gurl = `https://mkl-mgdapi-prod.azurewebsites.net/api/v2/deploy/deleteMappings/PROD`;
    }
    else {
      gurl = `https://mkl-mgdapi-uat1.azurewebsites.net/api/v2/deploy/deleteMappings/${env}`;
    }
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', `Bearer ${token}`);
    return this.http.post<any>(gurl, mappings, { headers }).pipe(map(res => res));
  }

  getDeployHistory(token: string) {
    //var gurl = `https://localhost:64655/api/v2/logrequest/getDeployLogs`;
    var gurl = `https://mkl-mgdapi-qa2.azurewebsites.net/api/v2/logrequest/getDeployLogs`;
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', `Bearer ${token}`);
    return this.http.get<DeployHistoryLog[]>(gurl, { headers }).pipe(map(res => res));
  }

  updateDeployLog(deployLog: DeployHistoryLog, token: string) {
    //var gurl = `https://localhost:64655/api/v2/logrequest/getDeployLogs`;
    var gurl = `https://mkl-mgdapi-qa2.azurewebsites.net/api/v2/logrequest/getDeployLogs`;
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', `Bearer ${token}`);
    return this.http.post<DeployHistoryLog>(gurl, deployLog, { headers }).pipe(map(res => res));
  }

  getJwt(env: string) {
    //var gurl = `https://localhost:64655/api/v2/document/login?UserName=Specialty&Key=BD5065AF-2A62-4002-B08F-0EB91221FDF1`;
    var gurl = `https://mkl-mgdapi-${env.toLowerCase()}.azurewebsites.net/api/v2/document/login?UserName=Specialty&Key=BD5065AF-2A62-4002-B08F-0EB91221FDF1`;
    if (env == "PROD-A" || env == "PROD-B") {
      gurl = "https://mkl-mgdapi-prod.azurewebsites.net/api/v2/document/login?UserName=Specialty&Key=BD5065AF-2A62-4002-B08F-0EB91221FDF1";
    }
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    return this.http.get(gurl, { headers, responseType: 'text' }).pipe(map(res => res));
  }

  getReleases(): Observable<any[]> {
    let url = `api/Configs/GetReleases`;
    return this.http.get<any[]>(url).pipe(
      map(res => res)
    );
  }

  addRelease(release: string): Observable<any> {
    let url = `api/Configs/AddRelease/${release}`;
    return this.http.get<any>(url).pipe(
      map(res => res)
    );
  }

  deleteRelease(release: string): Observable<any> {
    let url = `api/Configs/DeleteRelease/${release}`;
    return this.http.get<any>(url).pipe(
      map(res => res)
    );
  }

  runSanity(id: string, env: string): Observable<any[]> {
    let url = `api/TestGroup/RunMGDGroup/${id}/${env}`;
    return this.http.get<any[]>(url).pipe(map(res => res));
  }

}

