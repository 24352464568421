import { CPMField } from "../../framework";

export class FilterConfig {
  public ElementName: string;
  public ElementValues: CPMField[];
  public SelectedElementValue: string;
}

export class ElementDescriptions {
  public id: string;
  public Code: string;
  public Description: string;
  public FullDescription: string;
}
