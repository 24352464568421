import { ETaskRequest } from "../../ghostPortal/formsList/models/etask.request.model";

export class OrchRequest {
  //General properties for most logs
  public messageID: string;
  public originatingSystem: string;
  public messageDate: string;
  public product: string;
  public multipleInstructions: boolean;
  public action: OrchAction[] = [];
  public etask?: ETaskRequest;
}

export class OrchAction {
  public actionInstructionType: string;
  public InstructionSubtype: string;
  public environment: string;
}
