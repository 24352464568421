import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModuleTitleComponent } from './components/module-title/module-title.component';

@NgModule({
  declarations: [
    ModuleTitleComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ModuleTitleComponent
  ]
})
export class ModuleTitleModule { }
