<div class="component-container">
  <div class="component-heading-container">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-4">
          <h3 class="component-heading-text">Get Document Logs</h3>
          <br />
        </div>
        <div class="col-md-8">
          <div class="row">
            <label
              class="label_heading"
              id="logIdLabel"
              style="margin-top: 0px !important"
              >Policy Number:</label
            >
            <label>
              <input
                id="txtDealNumber"
                class="wrapper"
                [(ngModel)]="dealNumber"
                style="width: 100%"
              />
            </label>
            <div
              class="btn_wrapper"
              class="mrglt10"
              style="left: 10px; float: none !important"
            >
              <button
                class="btn primary_btn btnlign"
                type="button"
                (click)="searchLogs()"
              >
                Search Logs
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="component-content-view">
    <div id="summary-grid-container" class="col-12" style="padding-top: 10px">
      <ag-grid-angular
        class="ag-theme-balham fast_panel_table"
        [gridOptions]="resultsGridOptions"
        style="height: 400px"
      >
      </ag-grid-angular>
    </div>
    <div
      class="col-md-12"
      style="
        width: 400px;
        border: 1px solid black;
        border-radius: 5px;
        background-color: lightyellow;
        margin-left: 15px;
      "
    >
      <div class="border_box">
        <h3
          contenteditable="false"
          style="text-align: center; white-space: nowrap"
        >
          Recent Searches
        </h3>
        <ul style="overflow-y: auto">
          <li
            *ngFor="let search of recentSearched"
            (click)="loadRecentSearch(search)"
          >
            {{ search }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
