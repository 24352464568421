import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ImportQueueService } from '../../services/import-queue.service';

@Component({
  selector: 'app-update-transaction-menu',
  templateUrl: './update-transaction-menu.component.html'
})
export class UpdateTransactionMenuComponent implements OnInit {

  
  @Input() singleTransaction: boolean = false;
  @Input() selectedTransactionCount: number = 1;
  @Input() context: string = "detail";

  @Output() triggerUpdateModal = new EventEmitter();
  constructor( public queueService: ImportQueueService ) { }

  ngOnInit(): void {}

  isAuthorized(): boolean {
    return this.queueService.isAuthorized('requestAction');
  }

  performAction( action: string ) {
    this.triggerUpdateModal.emit( { context: this.context, action: action } );
  }

}
