//Angular Imports 
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

//Third Party Imports
import { Observable, of, Subscriber } from "rxjs";
import { map } from "rxjs/operators";
import { SelectableItem } from "../../framework";
import { ProductConfigWrapper } from "../models/productDefinition";

/**
  * Policy Forms Provider
*/
@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient) {
  }



  getProducts(): Observable<any[]> {
    let url = `api/Configs/Products/GetProducts`;
    return this.http.get<any[]>(url).pipe(
      map(res => res));
  }

  updateProducts(products: ProductConfigWrapper): Observable<any> {
    let url = `api/Configs/Products/UpdateProducts`;
    return this.http.post<any>(url, products).pipe(map(res => res));
  }

  getLobSelectableItemList(): Observable<SelectableItem[]> {
      let lobCpmElementId = "6e7e1116-8717-4a23-907e-d50d0f5b71a2"
      let url = `api/MasterData/SelectableItem/${lobCpmElementId}`;

      return this.http.get<any>(url).pipe(map(res => {
        return res;
      })
      );
  }



}
