<div class="reports-container">
  <mat-accordion #report>
    <mat-expansion-panel *ngFor="let report of framedReports; index as r">
      <mat-expansion-panel-header class="mdo-mat-panel-header">
        <mat-panel-title>
          <span class="report-name">{{report.name}}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="container-fluid outer-box">
        <div class="row">
          <div class="col-md-12">
            <iframe [(src)]="report.url" class="report-{{r}} report-frame"></iframe>
          </div>
        </div>
      </div>

    </mat-expansion-panel>

  </mat-accordion>
</div>


