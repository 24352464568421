import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ImportQueueService } from "../../services/import-queue.service";
import { ColDef, GridApi, GridOptions } from 'ag-grid-community';
import { Job } from "../../models/job";
import { LogMessageCellComponent } from "../log-message-cell/log-message-cell.component";
import { UpdateContext } from "../../models/update-context";
import { JobUpdatePayload } from '../../models/job-update-payload';

@Component({
  selector: 'app-job-detail-tab',
  templateUrl: './job-detail-tab.component.html',
  styleUrls: ['./job-detail-tab.component.scss']
})
export class JobDetailTabComponent implements OnInit {
  @Input() idBundle: any;
  @Output() updateGrid = new EventEmitter();
  @ViewChild("updateJobModal") updateJobModal: any;

  private actionHistoryGridApi: GridApi;
  frameworkComponents: any;
  isLoading: boolean = true;
  isReady: boolean = false;
  showDownloadMsg: boolean = false;
  wasDownloadSuccessful: boolean = true;
  details: Job = new Job();
  processLogsColumnDefs: ColDef[];
  actionHistoryColumnDefs: ColDef[];
  pagination: boolean = true;
  paginationAutoPageSize: boolean = true;
  processLogsGridOptions: GridOptions;
  actionHistoryGridOptions: GridOptions;

  constructor(private queueService: ImportQueueService) {
    let self = this;
    this.processLogsGridOptions = {
      onFirstDataRendered: function () {
        self.processLogsGridOptions.api.sizeColumnsToFit();
      }
    };

    this.actionHistoryGridOptions = {
      onFirstDataRendered: function () {
        self.actionHistoryGridOptions.api.sizeColumnsToFit();
      }
    };

    this.processLogsColumnDefs = [
      {
        field: 'message',
        headerName: "Message",
        sortable: true,
        filter: true,
        resizable: true,
        width: 500,
        cellRenderer: 'logMessageCell'
      },
      { field: 'stepNumber', headerName: "Step Number", sortable: true, filter: true, resizable: true },
      { field: 'processingStep', headerName: "Processing Step", sortable: true, filter: true, resizable: true, width: 250 },
      { field: 'status', headerName: "Status", sortable: true, filter: true, resizable: true },
      { field: 'failureType', headerName: "Failure Type", sortable: true, filter: true, resizable: true },
      {
        field: 'startTime',
        headerName: "Start Time",
        cellRenderer: params => {
          return this.queueService.formatTimestamp(params.value);
        }
      }
    ];

    this.actionHistoryColumnDefs = [
      { field: 'status', headerName: "Status", sortable: true, filter: true, resizable: true },
      {
        field: "date",
        headerName: "Date",
        sortable: true,
        sort: "desc",
        filter: true,
        resizable: true,
        cellRenderer: params => {
          return this.queueService.formatTimestamp(params.value);
        }
      },
      { field: "user", headerName: "User", sortable: true, filter: true, resizable: true },
      { field: "type", headerName: "Type", sortable: true, filter: true, resizable: true },
      { field: "note", headerName: "Note", sortable: true, filter: true, resizable: true, width: 400 }    
    ];

    // Assigns the cell renderers to the matching components
    this.frameworkComponents = {
      logMessageCell: LogMessageCellComponent 
    };
  }

  ngOnInit(): void {
    this.getJobDetails(this.idBundle.jobId);
  }

  onActionHistoryGridReady(params: any): void {
    this.actionHistoryGridApi = params.api;
  }

  getJobDetails(jobId: string): void {
    this.isLoading = true;
    this.queueService.getJobDetails(jobId)
      .subscribe(
        // Handle successful retrieval of the transaction details
        (job: Job) => {
          this.isReady = true;
          this.details = job;
          this.isLoading = false;
        },
        // Handle error response
        error => {
          this.isReady = false;
          this.isLoading = false;
        }
      )
  }

  
  // In case the column sizing did not occur because the grid was not visible / panel was closed.
  resizeGrid(targetGrid: string) {
    if (targetGrid == 'processLogs') {
      if (this.actionHistoryGridOptions.api) {
        this.processLogsGridOptions.api.sizeColumnsToFit();
      }
    } else {
      if (this.processLogsGridOptions.api) {
        this.actionHistoryGridOptions.api.sizeColumnsToFit();
      }
    }
  }



  retryJobDetails(jobId: string): void {
    event.preventDefault();
    this.getJobDetails(jobId);
  }

  downloadFiles(event: any): void {
    event.preventDefault();
    this.showDownloadMsg = false;
    this.queueService.getJobFiles(this.details.jobId)
      .subscribe(
        // Handle successful retrieval of the job .zip file
        (res: any) => {
          let filename = "job-" + this.details.jobId + ".zip";
          let success = this.queueService.generateDownloadedZipFile(filename, res.body);
          if (success) {
            this.wasDownloadSuccessful = true;
          } else {
            this.wasDownloadSuccessful = false;
          }
          this.showDownloadMsg = true;
        },
        // Handle error response
        error => {
          this.wasDownloadSuccessful = false;
          this.showDownloadMsg = true;
        }
      )
  }

  openUpdateModal(settings: any): void {
    let updateContext: UpdateContext = {
      context: settings.context,
      action: settings.action,
      transactions: [],
      stagingFiles: [],
      jobs: [
        {
          jobId: this.details.jobId,       
          status: this.details.status,
          partner: this.details.partner,
          product: this.details.product
        }
      ]
    };
    this.updateJobModal.openModal(updateContext);
  }

  refreshDetailsFromUpdate(jobUpdatePayload: JobUpdatePayload) {
    // You have to create a new array object to assign to actionHistory, or the grid refresh won't do anything
    let updatedActionHistory = [...this.details.actionHistory];
    updatedActionHistory.push(this.queueService.addJobActionHistory(jobUpdatePayload, this.details.jobId));
    this.details.actionHistory = updatedActionHistory;
    this.actionHistoryGridApi.refreshCells();
   
    if (jobUpdatePayload.action) {
      let cleanStatus = this.details.status.replace(/\s?\([A-Za-z\s]*\)/, "");
      this.details.status = cleanStatus + " (" + jobUpdatePayload.action + ")";
      // Even though not currently visible, the grid needs to be updated with the action as well.
      this.updateGrid.emit();
    }

  }

}
