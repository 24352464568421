import { Component, OnInit, Input } from '@angular/core';
import { EnvironmentService } from '../../../../../framework';
@Component({
  selector: 'app-module-title',
  templateUrl: './module-title.component.html',
  styleUrls: ['./module-title.component.scss']
})
export class ModuleTitleComponent implements OnInit {
  @Input() moduleTitle: string;

  environmentContext: string = "";

  constructor( private environmentService: EnvironmentService) { }

  ngOnInit(): void {
    if (this.environmentService.environmentDetails.Environment != 'PROD') {
      if (this.moduleTitle) {
        this.environmentContext = this.moduleTitle + " - " + this.environmentService.environmentDetails.Environment;
      } else {
        this.environmentContext = this.environmentService.environmentDetails.Environment;
      }
      
    }
  }

}
