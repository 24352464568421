<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Transactions For User - {{ this.currentUser }}</h4>
  </div>
  <div id="summary-grid-container" class="col-12">
    <ag-grid-angular
      class="ag-theme-balham fast_panel_table"
      [gridOptions]="transactionsGridOptions"
      style="height: 400px; padding-top: 5px"
      [defaultColDef]="defaultColDef"
      (cellValueChanged)="onCellValueChanged($event)"
      (rowValueChanged)="onRowValueChanged($event)"
      (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      mat-raised-button
      class="btn-teal"
      (click)="selectTransaction()"
      style="margin-right: 20px"
    >
      Select Highlighted
    </button>
    <button
      type="button"
      mat-raised-button
      class="mat_btn_hov_act"
      (click)="activeModal.close()"
    >
      Close
    </button>
  </div>
</div>
