<div class="component-container queue-container" style="padding-bottom: 2px !important;">
  <div class="component-heading-container">
    <div class="col-md-12" style="padding-left: 0 !important ;">
      <div class="row" style="padding-left: 10px;">
        <h3 class="component-heading-text">Queue</h3>
      </div>
    </div>
  </div>
  <div class="row" style="align-items: end;">
    <label class="label_heading" style="margin: 4px 5px 8px 36px"
      >Environment:</label
    >
    <div class="selectdiv select_wrapinline" style="width: 10%">
      <label>
        <ng-select
          class="custom-select"
          [items]="envList"
          bindLabel="Name"
          bindValue="Name"
          (change)="onEnvChange($event)"
          [(ngModel)]="selectedEnv"
          [clearable]="false"
          style="padding: 0 2px 2px 3px !important; font-size: 14px"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.Name }}">{{ item.Name }}</div>
          </ng-template>
        </ng-select>
      </label>
    </div>

    <button type="button" 
      mat-raised-button 
      class="btn-teal" 
      (click)="generateReport()"
      style="margin-left: auto; margin-top: 5px">
      Export Data
    </button>
  </div>
  <div class="row mrglt10" style="padding-top: 5px; display: inline-flex">
    <div class="col-md-12" style="padding-left: 0 !important;">
      <ag-grid-angular class="ghostPortal ag-theme-balham box-shadow" enableCellTextSelection="true"
        [gridOptions]="queueGridOptions" [rowModelType]="rowModelType" [cacheOverflowSize]="cacheOverflowSize"
        [maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests"
        [infiniteInitialRowCount]="infiniteInitialRowCount" [pagination]=true
        style="margin-top: 5px; width: 100%;height: calc(100vh - 180px) !important;">
      </ag-grid-angular>
    </div>
  </div>
</div>
