//Angular Imports
import { Inject, Injectable, InjectionToken } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

//RxJS Imports
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";

//Internal Imports 
import { EnvironmentSpecifics } from '../models';
import { SkippableInterceptorHeader } from "../enums";


/**
  * Provides call to get the environment.json 
*/
@Injectable({
  providedIn: 'root'
})

export class EnvironmentService {

  //Private Variables
  /**
   * Stores the environment details
  */
  private _environmentDetails: EnvironmentSpecifics;

  //Public Variables
  /**
   * Setter for environment details
  */
  set environmentDetails(value: EnvironmentSpecifics) {
    this._environmentDetails = value;
  }

  /**
   * Getter for environment details
  */
  get environmentDetails(): EnvironmentSpecifics {
    return this._environmentDetails;
  }


  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient) {
  }

  //Public Methods
  /**
   * Loads Environment
   * @returns {Observable<EnvironmentSpecifics>} Environment Details Observable 
  */
  loadEnvironment(): Observable<EnvironmentSpecifics> {
    //Set the headers
    let headers = new HttpHeaders().set(SkippableInterceptorHeader.RequestSkipHeader, 'true')
      .set(SkippableInterceptorHeader.AppInsightsSkipHeader, 'true')
      .set(SkippableInterceptorHeader.JWTSkipHeader, 'true')
      .set(SkippableInterceptorHeader.IfModifiedSkipHeader, 'true');

    return this.http.get<EnvironmentSpecifics>('appsettings.json', { headers: headers }).pipe(
      map(res => {
        this._environmentDetails = <EnvironmentSpecifics>res;
        return this._environmentDetails;
      }));
  }

}
