<div class="add_copy_rule">
  <div class="modal-header">
    <h4 class="modal-title">Publish</h4>
  </div>
  <form [formGroup]="formGroup">
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 fast_admin mrgbtm10">
                  <label>Package Name:</label>
                  <input
                    id="txtpackageNamet"
                    placeholder="Package Name"
                    formControlName="packageName"
                  />
                  <div
                    *ngIf="
                      formGroup.controls.packageName.invalid &&
                      formGroup.controls.packageName.dirty
                    "
                  >
                    <span
                      [hidden]="
                        !formGroup.controls['packageName'].errors.required
                      "
                      class="error_msg"
                    >
                      <i class="error_icon"></i>Package Name is required.
                    </span>
                  </div>
                </div>

                <div class="col-md-12 mrgbtm10">
                  <label>Package Comment:</label>
                  <input
                    id="txtPublishComment"
                    placeholder="Package Comment"
                    formControlName="packageComment"
                  />
                  <div
                    *ngIf="
                      formGroup.controls.packageComment.invalid &&
                      formGroup.controls.packageComment.dirty
                    "
                  >
                    <span
                      [hidden]="
                        !formGroup.controls['packageComment'].errors.required
                      "
                      class="error_msg"
                    >
                      <i class="error_icon"></i>Package Comment is required.
                    </span>
                  </div>
                </div>

                <div class="col-md-12 mrgbtm10">
                  <label>Published By:</label>
                  <input
                    id="txtPublishedBy"
                    placeholder="Publish By"
                    disabled
                    formControlName="publishBy"
                  />
                  <div
                    *ngIf="
                      formGroup.controls.publishBy.invalid &&
                      formGroup.controls.publishBy.dirty
                    "
                  >
                    <span
                      [hidden]="
                        !formGroup.controls['publishBy'].errors.required
                      "
                      class="error_msg"
                    >
                      <i class="error_icon"></i>Publish By is required.
                    </span>
                  </div>
                </div>

                <div class="col-md-12 mrgbtm10">
                  <label>Release Tag:</label>
                  <input
                    id="txtReleaseTag"
                    placeholder="Release Tag"
                    formControlName="releaseTag"
                  />
                  <div
                    *ngIf="
                      formGroup.controls.releaseTag.invalid &&
                      formGroup.controls.releaseTag.dirty
                    "
                  >
                    <span
                      [hidden]="
                        !formGroup.controls['releaseTag'].errors.required
                      "
                      class="error_msg"
                    >
                      <i class="error_icon"></i>Release Tag is required.
                    </span>
                  </div>
                </div>

                <div class="col-md-12 mrgbtm10">
                  <label>Create Date:</label>
                  <date-picker
                    [explicitDisabled]="true"
                    name="txtcreateDate"
                    id="txtEffectiveDate"
                    formControlName="createDate"
                  ></date-picker>
                  <div
                    *ngIf="
                      formGroup.controls.createDate &&
                      formGroup.controls.createDate.dirty &&
                      formGroup.controls.createDate.errors
                    "
                  >
                    <span
                      [hidden]="
                        !formGroup.controls['createDate'].errors?.required
                      "
                      class="error_msg"
                    >
                      <i class="error_icon"></i>Created Date is required
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="modal-footer">
    <button
      type="button"
      class="btn secondary_btn"
      (click)="activeModal.close()"
    >
      Cancel
    </button>
    <button type="button" class="btn primary_btn" (click)="publish()">
      Publish
    </button>
  </div>
</div>
