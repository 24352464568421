import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

// Data management shared modules
import { ModuleTitleModule } from '../../shared-modules/module-title/module-title.module'
import { EmbeddedReportsModule } from '../../shared-modules/embedded-reports/embedded-reports.module'

// Module-specific components
import { AMSRoutingModule } from './ams-routing.module';
import { MainViewComponent } from './components/main-view/main-view.component';

@NgModule({
  declarations: [
    MainViewComponent
  ],
  imports: [
    CommonModule,
    AMSRoutingModule,
    NgbModule,
    ModuleTitleModule,
    EmbeddedReportsModule
  ],
  providers: []
})
export class AMSModule { }
