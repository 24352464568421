<mat-tab-group
  animationDuration="0ms"
  id="mDocMasterView"
  [selectedIndex]="activeTab"
  (selectedIndexChange)="changeActiveTab($event)"
>
  <mat-tab label="Queue">
    <div class="container-fluid outer-box">
      <div class="row">
        <div class="col-md-3">
          <h5 class="queue-title">Filter Options</h5>
        </div>
        <div class="col-md-9">
          <p class="alert alert-info">
            <span
              >Orchestration Request Logs
              <span *ngIf="environment">- {{ environment }}</span></span
            >
            <button
              id="summary-reset-button"
              mat-raised-button
              class="portal-button"
              [disabled]="!canPerformReset()"
              (click)="resetTransactions($event)"
            >
              Reset {{ selectedCount }} transaction(s)
            </button>
          </p>
          <p
            id="filter-option-error-alert"
            class="alert alert-danger"
            *ngIf="showFilterError"
          >
            There was an error with populating the filter options. Click
            <a class="alert-link" href="" (click)="retryFilterOptions($event)"
              >here</a
            >
            to re-request the filter options.
          </p>
        </div>
      </div>

      <div class="row">
        <div id="filter-column" class="col-md-3">
          <app-request-filter
            #filterOptions
            (toggleLoad)="toggleGridLoadStatus($event)"
            (refreshGrid)="getSummaryData($event)"
            (toggleFilterError)="toggleFilterErrorState($event)"
          >
          </app-request-filter>
        </div>

        <div id="grid-column" class="col-md-9">
          <ag-grid-angular
            id="masterViewGrid"
            [hidden]="showTableError"
            style="width: 100%; height: 700px"
            class="ag-theme-balham"
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            (gridReady)="onGridReady($event)"
            [frameworkComponents]="frameworkComponents"
            [gridOptions]="gridOptions"
            [rowSelection]="rowSelection"
            [pagination]="pagination"
            [paginationAutoPageSize]="paginationAutoPageSize"
            (selectionChanged)="onSelectionChanged($event)"
          >
          </ag-grid-angular>
          <p
            id="table-retrieval-error-alert"
            class="alert alert-danger"
            [hidden]="!showTableError"
          >
            There was an error with retrieving the transaction data. Click the
            "Get Matching Queue Data" button to retry.
          </p>
        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab *ngFor="let detailTab of detailTabs; let index = index">
    <ng-template mat-tab-label>
      {{ detailTab.id }}: {{ detailTab.uuid }} &nbsp;
      <a href="" (click)="closeTab(index)">
        <i class="fa fa-times-circle close-tab-icon" title="Close"></i>
      </a>
    </ng-template>
    <app-detail-tab [rowData]="detailTab"></app-detail-tab>
  </mat-tab>
</mat-tab-group>
