import { CPMElement } from "./cpmElement.model";
import { CPMForm } from "./cpmForm.model";

/**
 * CPM Form
 * */
export class CPMUploadRequest {

  public cpmElements: CPMElement[];

  public renewalForms: CPMForm[];

}
