//Angular Imports 
import { Injectable } from "@angular/core";

//Third Party Imports
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

/**
  Date Picker Formatter to convert the NGBDateStruct to the required format 
*/
@Injectable()
export class DatePickerFormatter extends NgbDateParserFormatter {

  /**
   * Constructor
   * @ignore
  */
  constructor() {
    super();
  };

  /**
   * Convert the NgbDateStruct to string in the given format 
   * @param {NgbDateStruct} date date in NGBDateStruct
   * @param {string} momentFormat return format
   * @returns {string} returns date in the specified format
  */
  format(date: NgbDateStruct, momentFormat: string = "MM/DD/YYYY"): string {
    if (date === null) {
      return '';
    }

    //convert to moment 
    let d = moment({
      year: date.year,
      month: date.month,
      date: date.day
    });

    //If date is valid then return in specified format else return empty
    return d.isValid() ? d.format(momentFormat) : '';
  }

  /**
   * Convert the string in given format to NgbDateStruct
   * @param {string} value date value
   * @param {string} momentFormat format input date parameter
   * @returns {NgbDateStruct} the date in NGBDateStruct
  */
  parse(value: string, momentFormat: string = "MM/DD/YYYY"): NgbDateStruct {
    if (!value) {
      return null;
    }

    let d;

    //convert to moment
    if (momentFormat) {
      d = moment(value, momentFormat);
    }
    else {
      d = moment(value);
    }

    //If date is valid then construct the NGBDateStruct else return null
    return d && d.isValid() ? {
      year: d.get('year'),
      month: d.get('month'),
      day: d.get('date')
    } : null;
  }
}

