//Angular Imports 
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

//Third Party Imports
import { Observable, Subscriber } from "rxjs";
import { map } from "rxjs/operators";


/**
  * Policy Forms Provider
*/
@Injectable()
export class MGDReportsService {

  private masterData: any;
  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient) {
  }

  getEmailSettings(): Observable<string> {
    let url = 'api/Configs/GetReportUrl';
    return this.http.get<string>(url).pipe(map(res => {
      return res;
    }));
  }


}
