import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular'
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-staging-file-detail-trigger',
  template: `
    <span>
      <a class="mdo-standard-link" href="" (click)="showStagingFileDetail($event)">{{ idBundle["bundleValue"] }}</a>
    </span>
  `
})
export class StagingFileDetailTriggerComponent implements AgRendererComponent {

  idBundle: any = {};
  params: any;

  constructor() { }

  // Called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.idBundle = this.setIdBundle(params);
    this.params = params;
  }

  // Called whenever the user gets the cell to refresh
  refresh(params: ICellRendererParams): boolean {
    this.idBundle = this.setIdBundle(params);
    return true;
  }

  setIdBundle(params: ICellRendererParams): any {
    return {
      bundleLabel: "Staging File",
      bundleValue: params.data.Id,
      context: "StagingFile",
      data: params.data   
    }
  }

  showStagingFileDetail(event: any) {
    event.preventDefault();
    // To prevent the row selection event from taking place and inadvertently toggling the selection checkbox
    event.stopPropagation();
    // Calls parent component method to then call addTab in masterviewcomponent
    this.params.context.componentParent.addStagingFileDetailTab(
      this.idBundle
    );
  }

}
