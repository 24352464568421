import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AgGridModule } from "ag-grid-angular";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MdocumentOrchestrationQueueRoutingModule } from './mdocument-orchestration-queue-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialComponentModule } from "./material.module";
import { QueueService } from "./services/queue.service";
import { MasterViewComponent } from './components/master-view/master-view.component';
import { RequestFilterComponent } from './components/request-filter/request-filter.component';
import { DetailTriggerComponent } from './components/detail-trigger/detail-trigger.component';
import { CopyCellValueComponent } from './components/copy-cell-value/copy-cell-value.component';
import { DetailTabComponent } from './components/detail-tab/detail-tab.component';
import { CheckboxGroupControlsComponent } from './components/checkbox-group-controls/checkbox-group-controls.component';



@NgModule({
    providers: [
        QueueService
    ],
    declarations: [
        MasterViewComponent,
        RequestFilterComponent,
        DetailTriggerComponent,
        CopyCellValueComponent,
        DetailTabComponent,
        CheckboxGroupControlsComponent
    ],
    imports: [
        CommonModule,
        MdocumentOrchestrationQueueRoutingModule,
        AgGridModule,
        NgbModule,
        MaterialComponentModule,
        FormsModule,
        ReactiveFormsModule
    ]
})
export class MdocumentOrchestrationQueueModule { }
