<div class="container-fluid outer-box">
  <div class="row">

    <div class="col-md-3">
      <h4 class="queue-title">Staging Filter Options</h4>
    </div>

    <div class="col-md-9">

      <p class="alert mdo-standard-info-block" *ngIf="!showStagingFilterError">
        <span>Use the column headers to sort and filter the data in the table.</span>

        
        <button mat-raised-button class="mdo-standard-button staging-file-upload-btn" (click)="openFileUploadModal()" [disabled]="!isAuthorizedToUpload">
          Upload
        </button>
 
      <app-update-staging-file-menu id="staging-file-action-btn"
                            [singleFile]="false"
                            [selectedFileCount]="selectedFileCount"
                            [context]="'summary'"
                            (triggerStagingFileUpdateModal)="openStagedFileUpdateModal($event)">
      </app-update-staging-file-menu>

      </p>
      <p id="job-filter-option-error-alert" class="alert mdo-alert-danger" *ngIf="showStagingFilterError">
        There was an error with populating the filter options.  Click <a class="mdo-alert-danger-link" href="" (click)="retryStagingFilterOptions($event)">here</a> to re-request the filter options.
      </p>
    </div>

  </div>

  <div class="row">
    <div id="staging-file-filter-column" class="col-md-3">
      <app-staging-file-request-filter #stagingFileFilterOptions
                                       [errorStatuses]="errorStatuses"
                                       [initialStartDate]="initialStartDate"
                                       [initialEndDate]="initialEndDate"
                                       (toggleStagingGridLoad)="toggleStagingGridLoadStatus($event)"
                                       (refreshStagingGrid)="getStagingFileData($event)"
                                       (toggleStagingFilterError)="toggleStagingFilterErrorState($event)">

      </app-staging-file-request-filter>
    </div>


    <div id="job-grid-column" class="col-md-9">
      
      <ag-grid-angular [hidden]="showStagingTableError" id="stagingFileGrid"
                 style="width: 100%; height: 700px;"
                 class="ag-theme-balham mdo-standard-grid"
                 [rowData]="rowData"
                 [columnDefs]="columnDefs"
                 (gridReady)="onGridReady($event)"
                 [frameworkComponents]="frameworkComponents"
                 [gridOptions]="gridOptions"
                 [rowSelection]="rowSelection"
                 [rowMultiSelectWithClick]="rowMultiSelectWithClick"
                 [pagination]="pagination"
                 [paginationAutoPageSize]="paginationAutoPageSize"
                 (selectionChanged)="onFileSelectionChanged($event)">

      </ag-grid-angular>
  
      <p id="staging-table-retrieval-error-alert" class="alert mdo-alert-danger" [hidden]="!showStagingTableError">
        There was an error with retrieving the staging file data.  Click the "Get Matching Queue Data" button to retry.
      </p>
    </div>
  </div>

</div>

<app-update-staging-files #updateStagingFilesModal (triggerQueueUpdate)="refreshFromCache()"></app-update-staging-files>
  
<app-upload-files #uploadFilesModal></app-upload-files>

