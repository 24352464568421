//Angular Imports 
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

//Third Party Imports
import { Observable, Subscriber } from "rxjs";
import { map } from "rxjs/operators";
import { AdminForm } from "../../form-page/models/adminForm.model";

//Internal Imports
import { PolicyForm } from "../models";

/**
  * Policy Forms Provider
*/
@Injectable({
  providedIn: 'root'
})
export class OdenService {
  
  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient) {
  }


  
  /**
  * Get form by internal form number
  * @param policyForm
  */


}
