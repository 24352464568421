import { Component, EventEmitter,Input, Output, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ImportQueueService } from "../../services/import-queue.service";
import { FilterChoice } from "../../models/filter-choice";
import { FilterData } from "../../models/filter-data";
import { PartnerHealthFilter } from '../../models/partner-health-filter';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-request-filter',
  templateUrl: './request-filter.component.html',
  styleUrls: ['./request-filter.component.scss']
})

export class RequestFilterComponent implements OnInit {
  @Input() errorStatuses: string[] = [];
  @Input() initialStartDate: any;
  @Input() initialEndDate: any;

  isReady: boolean = false;
  idFilterOnly: boolean = false;

  statusList: FilterChoice[] = [];
  productTypeList: FilterChoice[] = [];
  partnerList: FilterChoice[] = [];
  
  filterInputs = new UntypedFormGroup({
    startDate: new UntypedFormControl(),
    endDate: new UntypedFormControl(),
    jobId: new UntypedFormControl(''),
    productTypeId: new UntypedFormControl('')
  });

  filterContext: string = 'Transaction';

  subs: Subscription[] = [];

  @Output() refreshGrid = new EventEmitter();
  @Output() toggleFilterError = new EventEmitter();

  @ViewChild('filterSections') filterSections: MatAccordion;
  @ViewChild('dateSection') dateSection: MatExpansionPanel;
  @ViewChild('idSection') idSection: MatExpansionPanel;
  @ViewChild('statusSection') statusSection: MatExpansionPanel;
  @ViewChild('productTypeSection') productTypeSection: MatExpansionPanel;
  @ViewChild('partnerSection') partnerSection: MatExpansionPanel;

  constructor( private queueService: ImportQueueService ) {}

  ngOnInit(): void {
    this.startListeners();
    this.filterInputs.controls.startDate.setValue(this.initialStartDate);
    this.filterInputs.controls.endDate.setValue(this.initialEndDate);
    this.populateFilterOptions();
  }

  startListeners(): void {
    let healthSub = this.queueService.partnerHealthFilterListener().subscribe((partnerHealthFilter: PartnerHealthFilter) => {
      this.processPartnerHealthFilter(partnerHealthFilter);
    });
    this.subs.push(healthSub);
  }

  ngOnDestroy(): void {
    this.subs.forEach((s: Subscription) => {
      s.unsubscribe();
    });
  }

  processPartnerHealthFilter(partnerHealthFilter: PartnerHealthFilter): void {
    
    if (partnerHealthFilter.active) {

      // Reset filter options to match filter instructions
      this.productTypeList.forEach((productTypeOption: FilterChoice) => {
        productTypeOption.selected = false;
      });
      this.partnerList.forEach((partnerOption: FilterChoice) => {
        partnerOption.selected = partnerOption.value == partnerHealthFilter.partner;
      });
      this.statusList.forEach((statusOption: FilterChoice) => {
        statusOption.selected = partnerHealthFilter.status.indexOf(statusOption.value) > -1;
      });
      this.filterInputs.controls.productTypeId.setValue("");
      this.filterInputs.controls.jobId.setValue(partnerHealthFilter.jobId);
      this.filterInputs.controls.startDate.setValue(partnerHealthFilter.startDate);
      this.filterInputs.controls.endDate.setValue(partnerHealthFilter.endDate);

      // Expand/hide filter sections based on filter instructions
      this.productTypeSection.expanded = false;
      this.dateSection.expanded = true;
      this.partnerSection.expanded = true;
      this.idSection.expanded = partnerHealthFilter.jobId != '';
      this.statusSection.expanded = partnerHealthFilter.status.length > 0;
      
      // Execute the filter
      this.executeFilter();

    }

  }

  setFilterOptions(filterOptions: any): void {
    this.statusList = [];
    this.partnerList = [];
    this.productTypeList = [];
    filterOptions.statuses.forEach((status: string) => {
      this.statusList.push({ label: status, value: status, selected: (this.errorStatuses.indexOf(status) > -1) })
    });

    filterOptions.partners.forEach((partner: string) => {
      this.partnerList.push({ label: partner, value: partner, selected: false });
    });

    filterOptions.productTypes.forEach((productType: string) => {
      this.productTypeList.push({ label: productType, value: productType, selected: false })
    });

  }

  populateFilterOptions(): void {
    this.queueService.getFilterOptions( this.filterContext )
      .subscribe(
        // Handle successful response with filter option data
        filterOptionsResponse => {
          this.setFilterOptions(filterOptionsResponse);
          this.isReady = true;
        },
        // Handle an error response
        error => {
          this.toggleFilterError.emit(true);
          this.isReady = false;
        }
      );
  }


  reInit(): void {
    this.queueService.getFilterOptions( this.filterContext )
      .subscribe(
        // Handle successful response
        filterOptionsResponse => {
          this.setFilterOptions(filterOptionsResponse);
          this.toggleFilterError.emit(false);
          this.isReady = true;
        },
        // Handle an error response
        error => {
          this.toggleFilterError.emit(true);
          this.isReady = false;
        }
      );
  }

  executeFilter(): void {
    let filterData: FilterData = this.configureFilterParams();
    this.refreshGrid.emit(filterData);
  }

  configureFilterParams(): FilterData {
    let statusArray: string[] = [];
    let productTypeArray: string[] = [];
    let partnerArray: string[] = [];

    let rawStartDate = this.filterInputs.controls.startDate.value;
    let rawEndDate = this.filterInputs.controls.endDate.value;

    this.statusList.forEach(status => {
      if (status.selected) {
        statusArray.push(status.value);
      }
    });
    this.productTypeList.forEach(type => {
      if (type.selected) {
        productTypeArray.push(type.value);
      }
    });
    this.partnerList.forEach(partner => {
      if (partner.selected) {
        partnerArray.push(partner.value);
      }
    });
    let filterData = new FilterData({
      startDate: rawStartDate,
      endDate: rawEndDate,
      status: statusArray,
      partner: partnerArray,
      productType: productTypeArray,
      jobId: this.filterInputs.controls.jobId.value,
      productTypeId: this.filterInputs.controls.productTypeId.value
    });

    return filterData;
  }

}
