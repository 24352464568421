//Angular Imports 
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

//Third Party Imports
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import * as _ from 'lodash';

//Internal Imports
import { SkippableInterceptorHeader } from "../../../../framework/enums";
import { EnvironmentService, SharedService } from "../../../../framework/services";
import { EXECUTION_ENGINE_LIST } from '../configs';
import {
  RuleEngineResult,  RuleResult,
  RuleEngineLog, TestExecution
} from '../models';
import { TestCase } from '../models/test-case.model';

/**
  * Execution  Provider
*/
@Injectable({
  providedIn: 'root'
})
export class ExecutionService {

  //Private Variables
  /**
   * List of tokens based on environment
   */
  private _tokenList: any;

  /**
   * List of environments and urls
  */
  private _envList: any;


  public ruleEngineResults: RuleEngineResult[];

  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient,
    private envService: EnvironmentService,
    private sharedService: SharedService)
  {
    this._envList = EXECUTION_ENGINE_LIST;
    this._tokenList = {};
    this.ruleEngineResults = [];
  }
  

  /**
   * Get token
   * @param selectedEnv Environment
   * */
  getToken(selectedEnv: string): Observable<any> {

    let index = _.findIndex(this._envList, { Name: selectedEnv });
    let environment = this._envList[index];
    if (this._tokenList && this._tokenList[environment.Name]) {
      return of(this._tokenList[environment.Name]);
    }
    else {
      //Set the headers
      let headers = new HttpHeaders().set(SkippableInterceptorHeader.RequestSkipHeader, 'true')
        .set(SkippableInterceptorHeader.JWTSkipHeader, 'true');

      let envDetails = this.envService.environmentDetails
      //let url = `${environment.URL}/api/Token?apiKey=rB<aO-xS^a<HW]-=g&clientURL=${envDetails.ORIGIN}`;

      let url = '';

      if (selectedEnv === 'PROD') {
        url = `${environment.URL}/api/Token?apiKey=xYrHk8@xj3=pFe&clientURL=${envDetails.ORIGIN}`;
      }
      else {
        url = `${environment.URL}/api/Token?apiKey=rB<aO-xS^a<HW]-=g&clientURL=${envDetails.ORIGIN}`;
      }

      return this.http.get<any>(url, { headers: headers }).pipe(
        map(res => {
          this._tokenList[environment.Name] = <string>res.token;
          return res;
        }));
    }
    
  }

  getTestCases(testGroup: string): Observable<TestCase[]> {
    let url = `api/TestCase?testGroup=` + testGroup;
    return this.http.get<TestCase[]>(url).pipe(
      map(res => res)
    );
  }

  updateTestCase(testCase: TestCase): Observable<TestCase> {
    let url = `api/TestCase`;
    return this.http.put<TestCase>(url, testCase).pipe(
      map(res => res)
    );
  }

  getLogsByDealNumber(dealNumber: string): Observable<RuleEngineLog[]> {
    let url = `api/RuleEngineLog?dealNumber=` + dealNumber;
    return this.http.get<RuleEngineLog[]>(url).pipe(
      map(res => res)
    );
  }

  /**
   * fetch the forms list
   * @param selectedEnv Environment
   * @param policyXML PolicyMessage
   */
  getForms(selectedEnv: string, policyXML: string): any {

    let index = _.findIndex(this._envList, { Name: selectedEnv });
    let environment = this._envList[index];

    //Set the headers
    let headers = new HttpHeaders().set(SkippableInterceptorHeader.RequestSkipHeader, 'true')
      .set(SkippableInterceptorHeader.JWTSkipHeader, 'true')
      .set("Authorization", `Bearer ${this._tokenList[environment.Name]}`)
      .set("Content-Type", "text/xml");

    let url = `${environment.URL}/api/forms/GetFormsClassic`
    return this.http.post<any>(url, policyXML ,{ headers: headers }).pipe(
      map(res => {
        return res;
      }));
  }

  getFormsPrototype(policyXML: string, selectedEnv: string): any {

    let index = _.findIndex(this._envList, { Name: selectedEnv });
    let environment = this._envList[index];

    //Set the headers
    let headers = new HttpHeaders().set(SkippableInterceptorHeader.RequestSkipHeader, 'true')
      .set(SkippableInterceptorHeader.JWTSkipHeader, 'true')

    let url = `${environment.URL}/api/v2/Forms/GetForms`
    return this.http.post<any>(url, policyXML ,{ headers: headers }).pipe(
      map(res => {
        return res;
      }));
  }

 /**
   * Call the TestMode api to get a full set of troubleshooting data from rule execution
   * @param selectedEnv Environment
   * @param policyXML PolicyMessage
   */
  getFormsTestMode(selectedEnv: string, policyXML: string): any {

    let index = _.findIndex(this._envList, { Name: selectedEnv });
    let environment = this._envList[index];

    //policyXML = "'" + policyXML + "'";
    let cpm = { "CpmString": policyXML };

    //Set the headers
    let headers = new HttpHeaders().set(SkippableInterceptorHeader.RequestSkipHeader, 'true')
      .set(SkippableInterceptorHeader.JWTSkipHeader, 'true')
      .set("Authorization", `Bearer ${this._tokenList[environment.Name]}`)
      .set("Content-Type", "application/json");
      //.set("Content-Type", "text/xml");
    //console.log(headers);
    let url = `${environment.URL}/api/v2/forms/GetFormsTestModeClassic`
    //return this.http.post<any>(url, policyXML, { headers: headers }).pipe(
    return this.http.post<any>(url, cpm, { headers: headers }).pipe(
      map(res => {
        this.ruleEngineResults = this.ruleEngineResults.concat(res);
        //console.log(res);
        return res;
      }));
  }

  executeTests(selectedEnv: string, testCases: TestCase[]): Observable<TestExecution[]> {

    let index = _.findIndex(this._envList, { Name: selectedEnv });
    let environment = this._envList[index];

    //Set the headers
    let headers = new HttpHeaders().set(SkippableInterceptorHeader.RequestSkipHeader, 'true')
      .set(SkippableInterceptorHeader.JWTSkipHeader, 'true')
      .set(SkippableInterceptorHeader.ShowLoaderSkipHeader, 'true')
      .set("Authorization", `Bearer ${this._tokenList[environment.Name]}`)
      .set("Content-Type", "application/json");

    let url = `${environment.URL}/api/TestExecution/ExecuteTests`;

    // Need to increment the call tracker here since it's not being incremented by
    // the showLoader function since we are not showing the loader for this call.  If
    // it's not incremented it will get out of sync and the loader will not go away
    // after a future call.
    //this.sharedService.incrementCallTracker();

    return this.http.post<any>(url, testCases, { headers: headers }).pipe(
      map(res => res)
    );

  }

  addToGlobalTestResults(items: RuleEngineResult[]): void {
    let globalResults: RuleEngineResult[] = this.sharedService.globalTestResults;
    let index = 0;

    // Remove any existing results for this scenario.
    while (index !== -1) {
      index = globalResults.findIndex(x => x.Scenario == items[0].Scenario);
      if (index !== -1) {
        globalResults.splice(index, 1);
      }
    }

    // Add new results
    for (let r of items) {
      this.sharedService.globalTestResults.push(r);
    }
  }

  

}


