import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular'
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-footer-cell',
  template: `
    <span [ngStyle]="style">{{params.value}}</span>
  `
})
export class FooterCellComponent implements AgRendererComponent {

  params: any;
  style: string;

  constructor() { }

  // Called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.style = this.params.style;
  }

  // Called whenever the user gets the cell to refresh
  refresh(params: ICellRendererParams): boolean {
    return true;
  }

}
