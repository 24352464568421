// Angular modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Third-party modules
import { AgGridModule } from "ag-grid-angular";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AngularMaterialModule } from "./angular-material.module";

// Data management shared modules
import { ModuleTitleModule } from '../../shared-modules/module-title/module-title.module'

// Module-specific services
import { OdenService } from './services/oden.service';

// Module-specific components
import { OdenRoutingModule } from './oden-routing.module';
import { MainViewComponent } from './components/main-view/main-view.component';
import { LogSearchComponent } from './components/log-search/log-search.component';
import { LogSearchFilterComponent } from './components/log-search-filter/log-search-filter.component';
import { CheckboxGroupControlsComponent } from './components/checkbox-group-controls/checkbox-group-controls.component';

@NgModule({
  declarations: [
    MainViewComponent,
    LogSearchComponent,
    LogSearchFilterComponent,
    CheckboxGroupControlsComponent
  ],
  imports: [
    CommonModule,
    OdenRoutingModule,
    NgbModule,
    ModuleTitleModule,
    FormsModule,
    ReactiveFormsModule,
    AgGridModule,
    AngularMaterialModule
  ],
  providers: [
    OdenService
  ]
})
export class OdenModule { }
