//Angular Imports
import { Injectable } from '@angular/core'
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpEventType, HttpErrorResponse } from '@angular/common/http';

//RxJS imports
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

//Internal Imports
import { AppInsightsService } from "../services";

/**
  * Intercepts and all HTTP responses and logs in app insights 
*/
@Injectable()

export class AppInsightsResponseInterceptor implements HttpInterceptor {

  /**
   * Constructor
   * @ignore
  */
  constructor(private appInsightsService: AppInsightsService) { }

  /**
   * Intercepts all the HTTP requests and logs the response in app insights
   * @param {HttpRequest} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>} Http Event Observable 
  */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(
        tap(event => {
          if (event.type === HttpEventType.Response) {
            
            //Check if the return can be logged and log 
            if (this.appInsightsService.instrumentationKey) {
              this.appInsightsService.logEvent("Response", { url: request.url, body: event.body });
            }

          }

        },
          (error: HttpErrorResponse) => {

            //Check if the error can be logged and log
            if (this.appInsightsService.instrumentationKey) {
              this.appInsightsService.logException(new Error("FAST-" + error.message), AI.SeverityLevel.Error);
            }
            
          })
      );
  }
}
