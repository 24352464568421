//Angular Imports 
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

//Third Party Imports
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

//Internal Imports 
import { EnvironmentService } from '../../../../framework/services';
import { forEach } from 'lodash';
import { FormsListService } from '../../services';


@Component({
  providers: [FormsListService],
  selector: 'extension',
  templateUrl: './extension.component.html',
  styleUrls: ['./extension.component.scss']
})

export class Extension {

  @Input() public transactionId: string;


  /**
   * Constructor
   * @ignore
  */
  constructor(public activeModal: NgbActiveModal,
    private environmentService: EnvironmentService,
    private formsService: FormsListService,
    private _modal: NgbModal,
    private toastr: ToastrService) {

  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
  */
  ngOnInit(): void {
    document.getElementById("ext_iframe").setAttribute("src", `${this.environmentService.environmentDetails.GDX_ORIGIN}/?transactionId=${this.transactionId}`);
  }

  /**
   * NgDestroy
   * @ignore
  */
  ngOnDestroy(): void {
  }

}
