

export class AttributeSet {

  // Form numbers only exist in this data structure in the front-end, they
  // are not part of the structure in the API.  This is done to make it easier
  // to work with the form-page-attributes-container component, but there is no
  // need to store the form numbers here, only at the form level.
  //CR 4/24/20 - Adding MultipleLOBIndicator to this, also only exists at form level and can be handled the same way as the for numbers
  public InternalFormNumber: string;
  public ExternalFormNumber: string;
  public MultiLOBIndicator: boolean;
  public PreviousVersion: string;
  public PriorInternalFormNumber: string;

  public Scope: string;
  public FormName: string;
  public MultipleAttachment: boolean;
  public FormType: string;
  public SortOrder: string;
  public FrequentlyUsed: boolean;
  public EffectiveDate: number;
  public ExpirationDate: number;
  public SubmissionExpirationDate: number;
  //MPL only
  public DisplayOnly: string[];
  public PolicyLevel: boolean;
}
