<div style="padding: 10px">
  <!-- Top edge -->
  <div
    class="row"
    [ngStyle]="{ 'border-color': enclosureColor }"
    class="rule-container-border"
  >
    <div
      style="
        height: 45px;
        width: 100%;

        padding: 10px;
      "
    >
      <!-- Comment button -->
      <div
        style="display: inline-block; padding: 4px 5px 4px 5px"
        *ngIf="!hasComment"
      >
        <button
          type="button"
          mat-raised-button
          class="btn-teal"
          (click)="onAddComment()"
        >
          Comment
        </button>
      </div>

      <!-- Dates button -->
      <div
        style="display: inline-block; padding: 4px 5px 4px 5px"
        *ngIf="!hasDates"
      >
        <button
          type="button"
          mat-raised-button
          class="btn-teal"
          (click)="onAddDates()"
        >
          Dates
        </button>
      </div>

      <!-- Add Expression dropdown -->
      <div ngbDropdown class="d-inline-block padding: 4px 5px 4px 5px">
        <button
          type="button"
          class="btn btn-teal"
          id="dropdownBasic1"
          ngbDropdownToggle
        >
          Expression
        </button>
        <div
          ngbDropdownMenu
          aria-labelledby="dropdownBasic1"
          style="
            max-height: calc(100vh - 100px);
            height: 250px;
            overflow: scroll;
          "
        >
          <button
            *ngFor="let element of elementList"
            class="dropdown-item"
            (click)="onAddExpression(element.CPMDataElementName)"
          >
            {{ element.CPMDataElementName }}
          </button>
        </div>
      </div>

      <div style="display: inline-block; padding: 4px 5px 4px 5px">
        <b>{{ ruleName }} Rule</b>
      </div>

      <!-- Delete button -->
      <div
        style="display: inline-block; padding: 4px 5px 4px 5px; float: right"
      >
        <button
          type="button"
          mat-raised-button
          class="btn-teal"
          (click)="onDeleteRule()"
        >
          Delete
        </button>
      </div>
      <!-- Clone button -->
      <div
        style="display: inline-block; padding: 4px 5px 4px 5px; float: right"
      >
        <button
          type="button"
          mat-raised-button
          class="btn-teal"
          (click)="onCloneRule()"
        >
          Clone
        </button>
      </div>
    </div>

    <!-- Left edge -->

    <div style="flex: auto; padding: 10px">
      <!-- Comment -->
      <div *ngIf="hasComment">
        <div
          class="row rule_info_row rounded-lg"
          style="padding-top: 5px; padding-bottom: 5px"
        >
          <label style="float: left; padding-right: 10px">Comment: </label>
          <textarea
            id="txtComment"
            placeholder="Enter comment for this rule"
            rows="1"
            style="min-width: 500px"
            [(ngModel)]="currentRule.Comment"
          ></textarea>
        </div>
      </div>

      <!-- Dates -->
      <div *ngIf="hasDates">
        <div class="row rule_info_row rounded-lg">
          <div class="col-md-4" style="display: inline-block">
            <label>Effective: </label>
            <date-picker
              id="effectiveDate"
              [(ngModel)]="currentRule.EffectiveDate"
            ></date-picker>
          </div>

          <div class="col-md-4" style="display: inline-block">
            <label>Expiration: </label>
            <date-picker
              id="expirationDateDate"
              [(ngModel)]="currentRule.ExpirationDate"
            ></date-picker>
          </div>
        </div>
      </div>

      <!-- Expressions -->
      <div *ngFor="let expression of currentRule.Expressions">
        <app-form-page-expression
          [currentExpression]="expression"
          (deleteExpression)="onDeleteExpression($event)"
          (saveExpression)="onSaveExpression($event)"
        ></app-form-page-expression>
      </div>
    </div>
  </div>
</div>
