//Angular Imports
import { Component, HostBinding } from "@angular/core";
//Third Party Imports
import { GridOptions } from "ag-grid-community";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";
import { saveAs } from "file-saver";

//Internal Imports
import {
  ApplicationInsightsBaseComponent,
  AppInsightsService,
  SharedService,
  DateFilterComponent,
  BooleanFilterComponent,
  DateTimeUtcPipe,
  AuthorizationService,
  UserProfileService,
} from "../../../framework";
import { DeployUtilityService } from "../services/deployUtility.service";
import { DeployHistoryLog, DocGenMapping, DocGenPackage } from "../models";
import { ErrorLog } from "./errorLog/errorLog.component";
import { DuplicateComponent } from "./duplicate/duplicate.component";
import { DeployTestComponent } from "./deployTest/deployTest.component";
import { EditLog } from "./editLog/editLog.component";
import { TestHarnessResults } from "../../../diagnostics/testSuites/testHarness/models/testHarness-testResult.model";
import { UntypedFormControl } from "@angular/forms";

/**
  Main Layout Component
*/
@Component({
  providers: [DuplicateComponent],
  selector: "deployUtility",
  templateUrl: "./deployUtility.component.html",
  styleUrls: ["./deployUtility.component.scss"],
})
export class DeployUtilityComponent extends ApplicationInsightsBaseComponent {
  @HostBinding("style.width") width: string;
  public packageGridOptions: GridOptions;
  public mappingGridOptions: GridOptions;
  public historyGridOptions: GridOptions;
  public envList: any[];
  public releaseList: any[];
  public selectedEnv: any;
  public selectedRelease: string = "";
  public additionalEnvs: string[] = [];
  public packageList: DocGenPackage[] = [];
  public mappingList: DocGenMapping[] = [];
  public packageFiles: FormData;
  public mappingFiles: FormData;

  public deployHistory: DeployHistoryLog[] = [];
  public results: TestHarnessResults[] = [];

  public packageExists: boolean = false;
  public mappingExists: boolean = false;

  public jira: string = "";
  public note: string = "";
  public folder: string = "";
  public newRelease: string = "";
  public error: string = "";
  public hasError: boolean = false;

  public isAdmin: boolean = false;
  public environmentCtrl: UntypedFormControl;

  initialRowDataLoad$;

  /**
   * Constructor
   * @ignore
   */
  constructor(
    private _appInsightsService: AppInsightsService,
    private toastr: ToastrService,
    private _modal: NgbModal,
    private sharedService: SharedService,
    private authService: AuthorizationService,
    private deployService: DeployUtilityService,
    private userProfileService: UserProfileService,
    private dateTimePipe: DateTimeUtcPipe,
    private duplicateComponent: DuplicateComponent
  ) {
    super(_appInsightsService);
    this.width = "100%";
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
   */
  ngOnInit(): void {
    if (this.authService.Administrator()) {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
    this.configureHistoryGrid();
    this.deployService.getJwt("QA1").subscribe((t) => {
      this.deployService.getDeployHistory(t).subscribe((res) => {
        this.deployHistory = res;
        console.log(res);
        this.historyGridOptions.api.setRowData(this.deployHistory);
      });
    });
    this.envList = [
      { Name: "DEV1", ID: 0 },
      { Name: "DEV2", ID: 1 },
      { Name: "QA1", ID: 2 },
      { Name: "QA2", ID: 3 },
      { Name: "UAT1", ID: 4 },
      { Name: "UAT2", ID: 5 },
      { Name: "PXUAT", ID: 8 },
      { Name: "MIRROR", ID: 6 },
      { Name: "FIT", ID: 7 },
    ];
    if (this.authService.PackageDeployProd()) {
      this.envList.push({ Name: "PROD", ID: 9 });
      this.envList.push({ Name: "PROD-A", ID: 10 });
      this.envList.push({ Name: "PROD-B", ID: 11 });
    }
    this.configurePackageGrid();
    this.configureMappingGrid();

    this.deployService.getReleases().subscribe((res) => {
      this.releaseList = res;
      this.releaseList.sort(function (a, b) {
        return a.Name.localeCompare(b.Name);
      });
    });
  }

  /**
   * NgDestroy
   * @ignore
   */
  ngOnDestroy(): void { }

  onEnvChange(selectedValue) {
    this.mappingList = [];
    this.historyGridOptions.api.getFilterInstance("Environment", (instance) => {
      instance.setModel({ type: "contains", filter: [selectedValue.Name] });
      this.historyGridOptions.api.onFilterChanged();
    });

    this.deployService.getJwt(selectedValue.Name).subscribe((t) => {
      this.deployService.getPackages(selectedValue.Name, t).subscribe((res) => {
        this.packageList = res;

        this.packageGridOptions.api.setRowData(this.packageList);
      });
      this.deployService.getMappings(selectedValue.Name, t).subscribe((res) => {
        this.mappingList = res;

        this.mappingGridOptions.api.setRowData(this.mappingList);
      });
    });
  }
  changeAdditionalEnvs(selectedValue) {
    console.log(this.additionalEnvs);
  }

  private configureHistoryGrid(): void {
    this.initialRowDataLoad$ = [];
    this.historyGridOptions = <GridOptions>{
      rowSelection: "multiple",
      domLayout: "autoHeight",
      columnDefs: this.createHistoryColumnDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      rowHeight: 40,
      enableColResize: true,
      paginationPageSize: 20,
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent,
      },
      onGridReady: () => {
        this.historyGridOptions.api.setRowData([]);
        this.historyGridOptions.api.sizeColumnsToFit();
        // this.gridOptions.api.softRefreshView();
      },
      onRowClicked: (event) => {
        this.onHistoryRowClick(event);
      },
      onFilterChanged: (event) => {
        //Api call to get the filter data
        console.log(event);
      },
      onSortChanged: (event) => { },
      onFilterModified: function (event) {
        console.log(event);
      },
    };
  }

  onHistoryRowClick(event) {
    if (event.event.target !== undefined) {
      let data = event.data;
      let actionType = event.event.target.getAttribute("data-action-type");
      console.log(data);
      switch (actionType) {
        case "Preview":
          const modalRef = this._modal.open(ErrorLog, { windowClass: "xl" });
          modalRef.componentInstance.errorText = data.Error;
        case "Edit":
          const modalRefEdit = this._modal.open(EditLog, { windowClass: "lg" });
          modalRefEdit.componentInstance.Row = data;
          modalRefEdit.result.then((result) => {
            if (result != "Cancel") {
              this.deployService.getJwt("FIT").subscribe((t) => {
                this.deployService
                  .updateDeployLog(result, t)
                  .subscribe((res) => {
                    console.log(res);
                  });
              });
            }
          });
      }
    }
  }

  private configurePackageGrid(): void {
    this.initialRowDataLoad$ = [];
    this.packageGridOptions = <GridOptions>{
      rowSelection: "multiple",
      domLayout: "autoHeight",
      columnDefs: this.createColumnDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      enableColResize: true,
      paginationPageSize: 10,
      rowHeight: 40,
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent,
      },
      onGridReady: () => {
        this.packageGridOptions.api.setRowData([]);
        this.packageGridOptions.api.sizeColumnsToFit();
        // this.gridOptions.api.softRefreshView();
      },
      onRowClicked: (event) => {
        this.onRowClicked(event);
      },
      onFilterChanged: (event) => {
        //Api call to get the filter data
        console.log(event);
      },
      onSortChanged: (event) => { },
      onFilterModified: function (event) {
        console.log(event);
      },
    };
  }

  private configureMappingGrid(): void {
    this.initialRowDataLoad$ = [];
    this.mappingGridOptions = <GridOptions>{
      rowSelection: "multiple",
      domLayout: "autoHeight",
      columnDefs: this.createMappingColumnDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      enableColResize: true,
      paginationPageSize: 10,
      rowHeight: 40,
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent,
      },
      onGridReady: () => {
        this.mappingGridOptions.api.setRowData([]);
        this.mappingGridOptions.api.sizeColumnsToFit();
        // this.gridOptions.api.softRefreshView();
      },
      onRowClicked: (event) => {
        this.onMappingRowClicked(event);
      },
      onFilterChanged: (event) => {
        //Api call to get the filter data
        console.log(event);
      },
      onSortChanged: (event) => { },
      onFilterModified: function (event) {
        console.log(event);
      },
    };
  }

  private onRowClicked(e) { }

  private onMappingRowClicked(e) { }

  resetFilters() {
    this.historyGridOptions.api.setFilterModel(null);
  }

  onFileChange(event: any) {
    this.packageExists = false;
    this.mappingExists = false;
    this.packageFiles = new FormData();
    this.mappingFiles = new FormData();
    for (var file of event.target.files) {
      console.log(file);
      var fileName = String(file.name);
      if (fileName.endsWith(".gdsp")) {
        this.packageFiles.append(
          this.userProfileService.UserName,
          file,
          fileName
        );
        this.packageExists = true;
      } else if (fileName.endsWith(".gdx")) {
        this.mappingFiles.append(
          this.userProfileService.UserName,
          file,
          fileName
        );
        this.mappingExists = true;
      } else {
        this.toastr.error("Invalid File Extension", "Error Uploading File", {
          positionClass: "toast-bottom-right",
        });
      }
    }
  }

  onUploadClick() {
    //const modalRef = this._modal.open(DeployTestComponent, { backdrop: 'static' });
    if (this.selectedRelease == null || this.selectedRelease == "") {
      this.toastr.error(
        "Release Must be Selected for Content Upload",
        "Error Uploading Content",
        { positionClass: "toast-bottom-right" }
      );
    } else if (this.folder == null || this.folder == "") {
      this.toastr.error(
        "Folder Name Must be Entered for Content Upload",
        "Error Uploading Content",
        { positionClass: "toast-bottom-right" }
      );
    } else {
      if (this.jira == "" || this.note == "") {
        if (
          confirm(
            `You did not input one or more of the following: JIRA, NOTE, are you sure you wish to deploy?`
          )
        ) {
          this.deployContent(this.selectedEnv, true);
          if (this.additionalEnvs.length > 0) {
            for (var i = 0; i < this.additionalEnvs.length; i++) {
              if (this.additionalEnvs[i] != this.selectedEnv) {
                this.deployContent(this.additionalEnvs[i], false);
              }
            }
          }
        }
      } else {
        this.deployContent(this.selectedEnv, true);
        if (this.additionalEnvs.length > 0) {
          for (var i = 0; i < this.additionalEnvs.length; i++) {
            if (this.additionalEnvs[i] != this.selectedEnv) {
              this.deployContent(this.additionalEnvs[i], false);
            }
          }
        }
      }
    }
  }

  onAddReleaseClick() {
    if (this.newRelease == "" || this.newRelease == null) {
      this.toastr.error(`Release Info is Blank`, `Validation Error`, {
        positionClass: "toast-bottom-right",
      });
    } else {
      this.deployService.addRelease(this.newRelease).subscribe(
        (res) => {
          this.toastr.success(
            `Successfully Added Release, Refresh to View Change`,
            `Complete`,
            { positionClass: "toast-bottom-right" }
          );
        },
        (error) => {
          this.toastr.error(`Error While Adding New Release`, "Error");
        }
      );
    }
  }

  onDeleteRelease() {
    if (this.selectedRelease != "") {
      if (confirm(`Delete the selected release?`)) {
        this.deployService
          .deleteRelease(this.selectedRelease)
          .subscribe((res) => {
            console.log(res);
            this.toastr.success(
              `Successfully Deleted Release, Refresh to View Change`,
              `Complete`,
              { positionClass: "toast-bottom-right" }
            );
          });
      }
    }
  }

  exportHistory() {
    const header = [
      "Timestamp",
      "Environment",
      "Item",
      "Name",
      "Deployed By",
      "Note",
      "JIRA",
      "Release",
      "Folder",
    ];
    let csv: string[] = [];
    csv.push(header.join(","));
    this.deployHistory = this.deployHistory.sort((a, b) => {
      return (
        +new Date(b.Timestamp.toString()) - +new Date(a.Timestamp.toString())
      );
    });
    for (var log of this.deployHistory) {
      let timestamp = log.Timestamp.toString().slice(0, 19);
      let item = "";
      if (log.IsPackage) {
        item = "Package";
      } else {
        item = "Mapping";
      }
      let deployedBy = log.DeployedBy.replace(",", "_");
      csv.push(
        timestamp +
        "," +
        log.Environment +
        "," +
        item +
        "," +
        log.Name +
        "," +
        deployedBy +
        "," +
        log.Note +
        "," +
        log.Jira +
        "," +
        log.Release +
        "," +
        log.Folder
      );
    }
    let csvArray = csv.join("\r\n");
    var blob = new Blob([csvArray], { type: "text/csv" });
    saveAs(blob, "history.csv");
  }

  deployContent(env: string, additional: boolean) {
    this.results = [];
    this.hasError = false;
    if (this.packageExists) {
      this.deployService.getJwt(env).subscribe((t) => {
        this.deployService
          .uploadPackages(
            env,
            t,
            this.packageFiles,
            this.note,
            this.jira,
            this.folder,
            this.selectedRelease
          )
          .subscribe(
            (res) => {
              console.log(res);
              if (res != "Success") {
                this.error =
                  "There was a problem with deploying one or more packages to " +
                  env +
                  " refresh to view error from logs";
                this.hasError = true;
              } else {
                this.toastr.success(
                  `Successfully uploaded package(s) to ${env}`,
                  "Upload Complete",
                  { positionClass: "toast-bottom-right" }
                );
                //call duplicate check component 
                //this.duplicateComponent.checkDuplicates(env)
              }
              if (this.mappingExists && !this.hasError) {
                this.deployService.getJwt(env).subscribe((t) => {
                  this.deployService
                    .uploadMappings(
                      env,
                      t,
                      this.mappingFiles,
                      this.note,
                      this.jira,
                      this.folder,
                      this.selectedRelease
                    )
                    .subscribe(
                      (res) => {
                        console.log(res);
                        if (res != "Success") {
                          this.error =
                            "There was a problem with deploying one or more mappings to " +
                            env +
                            " refresh to view error from logs";
                          this.hasError = true;
                        } else {
                          this.toastr.success(
                            `Successfully uploaded mapping(s) to ${env}`,
                            "Upload Complete",
                            { positionClass: "toast-bottom-right" }
                          );
                          this.runSanityAfterDeploy();
                        }
                      },
                      (error) => {
                        this.error = error.message;
                        this.hasError = true;
                      }
                    );
                });
              } else {
                this.runSanityAfterDeploy();
              }
            },
            (error) => {
              this.error = error.message;
              this.hasError = true;
            }
          );
      });
    }
    else if (this.mappingExists && !this.hasError) {
      this.deployService.getJwt(env).subscribe((t) => {
        this.deployService
          .uploadMappings(
            env,
            t,
            this.mappingFiles,
            this.note,
            this.jira,
            this.folder,
            this.selectedRelease
          )
          .subscribe(
            (res) => {
              console.log(res);
              if (res != "Success") {
                this.error =
                  "There was a problem with deploying one or more mappings to " +
                  env +
                  " refresh to view error from logs";
                this.hasError = true;
              } else {
                this.toastr.success(
                  `Successfully uploaded mapping(s) to ${env}`,
                  "Upload Complete",
                  { positionClass: "toast-bottom-right" }
                );
                this.runSanityAfterDeploy();
              }
            },
            (error) => {
              this.error = error.message;
              this.hasError = true;
            }
          );
      });
    }
  }

  runSanityAfterDeploy() {
    this.toastr.info(
      "Running Sanity Test...",
      "Please wait...",
      { positionClass: "toast-bottom-right" }
    );
    this.deployService.runSanity('f87ef7e1-0ab5-4fd1-bb37-58b734754961', this.selectedEnv).subscribe(res => {
      this.results = res;
      for (var i = 0; i < this.results.length; i++) {
        if (this.results[i].Error != null && this.results[i].Error != "") {
          this.toastr.error(
            `Please review below error message`,
            "Sanity test failed",
            { positionClass: "toast-bottom-right" }
          );
          this.error = "Sanity Tests Failed: Error when running test case " + this.results[i].TestCaseName + "\n" + this.results[i].Error;
          this.hasError = true;
        }
      }
      if (!this.hasError) {
        this.toastr.success(
          `${this.selectedEnv} passed all sanity tests`,
          "Deployment Complete",
          { positionClass: "toast-bottom-right" }
        );
        this.error = "Sanity tests have completed successfully";
        this.hasError = true;
      }
    });
  }

  async onDeleteClick() {
    let selectedPackages = this.packageGridOptions.api
      .getSelectedNodes()
      .map((node) => node.data);
    let selectedMappings = this.mappingGridOptions.api
      .getSelectedNodes()
      .map((node) => node.data);
    var pFiles = new FormData();
    var bFiles = new FormData();
    //To simplify server side processing we are mocking up the data format of the upload calls even though we only really need the name
    if (selectedPackages.length > 0 && selectedMappings.length > 0) {
      if (selectedPackages.length == selectedMappings.length) {
        var match = false;
        for (var i = 0; i < selectedPackages.length; i++) {
          if (
            selectedMappings.some(
              (m) => m.MappingId == selectedPackages[i].Mapping.MappingId
            )
          ) {
            match = true;
          } else {
            match = false;
          }
        }
        if (match) {
          if (
            confirm(
              `Are you sure you want to delete the following items?\n${selectedPackages.map(
                (i) => i.Name
              )}`
            )
          ) {
            for (var pack of selectedPackages) {
              pFiles.append(pack.PackageId, new Blob(), pack.Name);
            }
            for (var map of selectedMappings) {
              bFiles.append(map.MappingId, new Blob(), map.Name);
            }
            await this.deployService.getJwt(this.selectedEnv).subscribe((t) => {
              this.deployService
                .deletePackages(this.selectedEnv, t, pFiles)
                .subscribe((res) => {
                  this.toastr.success(
                    "Successfully deleted the selected package(s)",
                    "Success",
                    { positionClass: "toast-bottom-right" }
                  );
                });
            });
            console.log("next");
            await this.deployService.getJwt(this.selectedEnv).subscribe((t) => {
              this.deployService
                .deleteMappings(this.selectedEnv, t, bFiles)
                .subscribe((res) => {
                  this.toastr.success(
                    "Successfully deleted the selected mapping(s)",
                    "Success",
                    { positionClass: "toast-bottom-right" }
                  );
                });
            });
          }
        } else {
          this.toastr.error(
            "Package(s) and Mapping(s) to delete must have the same name and version or functionality will break",
            "Error",
            { positionClass: "toast-bottom-right" }
          );
        }
      } else {
        this.toastr.error(
          "Cannot delete more packages than mappings or vice versa",
          "Error",
          { positionClass: "toast-bottom-right" }
        );
      }
    } else {
      this.toastr.error(
        "Please select one or more packages and associated mappings",
        "Error",
        { positionClass: "toast-bottom-right" }
      );
    }
  }

  private createColumnDef(): any[] {
    let result: any[] = [
      {
        headerName: "",
        checkboxSelection: true,
        field: "RowSelect",
        suppressMenu: true,
        suppressSorting: true,
        width: 30,
        filter: "booleanFilterComponent",
      },
      {
        headerName: "Package Name",
        field: "Name",
        sort: "desc",
        width: 200,
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
      {
        headerName: "Version",
        field: "Version",
        width: 70,
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
    ];

    return result;
  }

  private createMappingColumnDef(): any[] {
    let result: any[] = [
      {
        headerName: "",
        checkboxSelection: true,
        field: "RowSelect",
        suppressMenu: true,
        suppressSorting: true,
        width: 30,
        filter: "booleanFilterComponent",
      },
      {
        headerName: "Mapping Name",
        field: "Name",
        sort: "desc",
        width: 260,
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
    ];

    return result;
  }

  private createHistoryColumnDef(): any[] {
    let result: any[] = [
      {
        headerName: "Deploy Date",
        field: "Timestamp",
        resizable: true,
        width: 160,
        filter: "dateFilterComponent",
        sortable: true,
        sort: "desc",
        cellRenderer: (data) => {
          return data.value ? new Date(data.value).toLocaleString() : "";
        },
      },
      {
        headerName: "Env",
        field: "Environment",
        width: 60,
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
      {
        headerName: "Item",
        field: "IsPackage",
        width: 70,
        filter: "booleanFilterComponent",
        cellRenderer: (params) => {
          return params.value ? "Package" : "Mapping";
        },
      },
      {
        headerName: "Name",
        field: "Name",
        filter: "agTextColumnFilter",
        resizable: true,
        width: 200,
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
      {
        headerName: "Deployed By",
        field: "DeployedBy",
        resizable: true,
        width: 130,
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
      {
        headerName: "Note",
        filter: "agTextColumnFilter",
        field: "Note",
        resizable: true,
        width: 200,
      },
      {
        headerName: "JIRA",
        filter: "agTextColumnFilter",
        field: "Jira",
        resizable: true,
        width: 90,
      },
      {
        headerName: "Release",
        filter: "agTextColumnFilter",
        field: "Release",
        width: 90,
      },
      {
        headerName: "Folder",
        filter: "agTextColumnFilter",
        field: "Folder",
        resizable: true,
        width: 200,
      },
      {
        headerName: "Error",
        width: 70,
        suppressMenu: true,
        suppressSorting: true,
        cellRenderer: function (params) {
          console.log(params.data);
          if (params.data.Error != "Success" && params.data.Error != null) {
            return `<img src="/assets/images/View_Doc.png" data-action-type="Preview" class="cursor_pointer" title="Preview">`;
          }
          return "";
        },
      },
      {
        headerName: "Actions",
        width: 70,
        suppressMenu: true,
        suppressSorting: true,
        filter: "booleanFilterComponent",
        cellRenderer: (params) => {
          return `<img src="/assets/images/edit_icon.png" data-action-type="Edit" class="cursor_pointer" title="Edit">`;
        },
      },
    ];

    return result;
  }
}
