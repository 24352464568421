import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ImportQueueService } from "../../services/import-queue.service";

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  framedReports: any[];
  reportCatalog: any;

  constructor(private domSanitizer: DomSanitizer, private queueService: ImportQueueService ) { }

  ngOnInit(): void {

    // NOTE: the "rs:Command=Render&rs:embed=true" query params need to be added to the report URL to avoid seeing X-Frame-Options errors
    this.reportCatalog = {
      nonProd: {
        batchTransaction: {
          name: "Batch Transaction Reconciliation",
          url: "https://va1-psplsql223.markelcorp.markelna.com/ReportServer/Pages/ReportViewer.aspx?%2fMSC+Application+Reporting%2fMDO%2fPROD+Batch+Transaction+Reconciliation&rs:Command=Render&rs:embed=true"
        },
        claimDocumentCount: {
          name: "Claim Document Count and Error Detail",
          url: "https://va1-psplsql223.markelcorp.markelna.com/ReportServer/Pages/ReportViewer.aspx?%2fMSC+Application+Reporting%2fMDO%2fPROD+Claim+Document+Count+and+Error+Detail&rs:Command=Render&rs:embed=true"
        },
        jobStatus: {
          name: "Job Status Report",
          url: "https://va1-psplsql223.markelcorp.markelna.com/Reports/report/MSC%20Application%20Reporting/MDO/PROD%20Job%20Status%20Report?rs:Command=Render&rs:embed=true"
        }
      },
      prod: {
        batchTransaction: {
          name: "Batch Transaction Reconciliation",
          url: "https://va1-psplsql223.markelcorp.markelna.com/ReportServer/Pages/ReportViewer.aspx?%2fMSC+Application+Reporting%2fMDO%2fPROD+Batch+Transaction+Reconciliation&rs:Command=Render&rs:embed=true"
        },
        claimDocumentCount: {
          name: "Claim Document Count and Error Detail",
          url: "https://va1-psplsql223.markelcorp.markelna.com/ReportServer/Pages/ReportViewer.aspx?%2fMSC+Application+Reporting%2fMDO%2fPROD+Claim+Document+Count+and+Error+Detail&rs:Command=Render&rs:embed=true"
        },
        jobStatus: {
          name: "Job Status Report",
          url: "https://va1-psplsql223.markelcorp.markelna.com/Reports/report/MSC%20Application%20Reporting/MDO/PROD%20Job%20Status%20Report?rs:Command=Render&rs:embed=true"
        }
      }
    };

    let env = this.queueService.isNonProdEnvironment() ? "nonProd" : "prod"; 

    this.framedReports = [
      {
        name: this.reportCatalog[env].batchTransaction.name,
        url: this.generateSafeResourceUrl( this.reportCatalog[env].batchTransaction.url )
      },
      {
        name: this.reportCatalog[env].claimDocumentCount.name,
        url: this.generateSafeResourceUrl(this.reportCatalog[env].claimDocumentCount.url)
      },
      {
        name: this.reportCatalog[env].jobStatus.name,
        url: this.generateSafeResourceUrl(this.reportCatalog[env].jobStatus.url)
      }
    ]
  }

  generateSafeResourceUrl(urlString): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(urlString);
  }
}
