import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ImportQueueService } from "../../services/import-queue.service";
import { ColDef, GridApi, GridOptions } from 'ag-grid-community';
import { Transaction } from "../../models/transaction";
import { prettyPrintJson } from "pretty-print-json";
import { LogMessageCellComponent } from "../log-message-cell/log-message-cell.component";
import { JobIdCellComponent } from "../job-id-cell/job-id-cell.component";
import { TransactionIdCellComponent } from "../transaction-id-cell/transaction-id-cell.component";
import { UpdateContext } from "../../models/update-context";
import { UpdatePayload } from '../../models/update-payload';

@Component({
  selector: 'app-detail-tab',
  templateUrl: './detail-tab.component.html',
  styleUrls: ['./detail-tab.component.scss']
})
export class DetailTabComponent implements OnInit {

  @Input() idBundle: any;
  private actionsHistoryGridApi: GridApi;
  frameworkComponents: any;
  isLoading: boolean = true;
  isLoadingModel: boolean = false;
  modelLoadError: boolean = false;
  isReady: boolean = false;
  details: Transaction = new Transaction();
  columnDefs: ColDef[];
  actionColumnDefs: ColDef[];
  pagination: boolean = true;
  paginationAutoPageSize: boolean = true;
  prettyModels: any[] = [];
  modelsAsJSON: string[] = [];
  actionHistoryGridOptions: GridOptions;
  loggingGridOptions: GridOptions;

  @Output() updateGrid = new EventEmitter();

  @ViewChild("updateModal") updateModal: any;

  constructor(private queueService: ImportQueueService) {
    let self = this;
    this.actionHistoryGridOptions = this.actionHistoryGridOptions = {
      onFirstDataRendered: function () {
        self.actionHistoryGridOptions.api.sizeColumnsToFit();
      }
    };

    this.loggingGridOptions = {
      onFirstDataRendered: function () {
        self.actionHistoryGridOptions.api.sizeColumnsToFit();
      }
    };

    this.columnDefs = [
      {
        field: 'message',
        headerName: "Message",
        sortable: true,
        filter: true,
        resizable: true,
        width: 500,
        cellRenderer: 'logMessageCell'
      },
      { field: 'stepNumber', headerName: "Step Number", sortable: true, filter: true, resizable: true },
      { field: 'processingStep', headerName: "Processing Step", sortable: true, filter: true, resizable: true, width: 250  },
      { field: 'status', headerName: "Status", sortable: true, filter: true, resizable: true },
      { field: 'failureType', headerName: "Failure Type", sortable: true, filter: true, resizable: true },
      {
        field: 'starttime',
        headerName: "Start Time",
        cellRenderer: params => {
          return this.queueService.formatTimestamp(params.value);
        }
      }
    ];

    this.actionColumnDefs = [
      {
        field: "status", headerName: "Status", sortable: true, filter: true, resizable: true
      },
      {
        field: "date",
        headerName: "Date",
        sortable: true,
        sort: "desc",
        filter: true,
        resizable: true,
        cellRenderer: params => {
          return this.queueService.formatTimestamp(params.value);
        }
      },
      { field: "user", headerName: "User", sortable: true, filter: true, resizable: true },
      { field: "type", headerName: "Type", sortable: true, filter: true, resizable: true },
      {
        field: "jobId",
        headerName: "Job ID",
        sortable: true,
        filter: true,
        resizable: true,
        width: 300,
        cellRenderer: 'jobIdCell'
      },
      {
        field: "transactionId",
        headerName: "Transaction ID",
        sortable: true,
        filter: true,
        resizable: true,
        width: 300,
        cellRenderer: 'transactionIdCell'
      },
      { field: "note", headerName: "Note/Reason/Error", sortable: true, filter: true, resizable: true, width: 400}
    ];

    // Assigns the cell renderers to the matching components
    this.frameworkComponents = {
      jobIdCell: JobIdCellComponent,
      transactionIdCell: TransactionIdCellComponent,
      logMessageCell: LogMessageCellComponent
    };

  }

  ngOnInit(): void {
    this.getTransactionDetails( this.idBundle.transactionId );
  }

  onActionHistoryGridReady(params: any): void {
    this.actionsHistoryGridApi = params.api;
  }

  // In case the column sizing did not occur because the grid was not visible / panel was closed.
  resizeGrid(targetGrid: string) {
    if (targetGrid == 'actionHistory') {
      if (this.actionHistoryGridOptions.api) {
        this.actionHistoryGridOptions.api.sizeColumnsToFit();
      }     
    } else {
      if (this.actionHistoryGridOptions.api) {
        this.loggingGridOptions.api.sizeColumnsToFit();
      }
    }
  }
  
  getTransactionDetails(transactionId: string): void {
    this.isLoading = true;
    this.queueService.getTransactionDetails(transactionId)
      .subscribe(
        // Handle successful retrieval of the transaction details
        (transaction: Transaction) => {
          this.isReady = true;
          this.details = transaction;
          // A cached transaction may have the model
          if (transaction.isModelSet()) {
            this.displayModelData(transaction.model);
          }
          this.isLoading = false;
        },
        // Handle error response
        error => {
          this.isReady = false;
          this.isLoading = false;
        }
      )
  }

  displayModelData(modelData: any) {
    this.prettyModels = [];
    this.modelsAsJSON = [];
    modelData.forEach(model => {
      this.prettyModels.push(prettyPrintJson.toHtml(model.content));
      this.modelsAsJSON.push(JSON.stringify(model.content));
    });
  }

  retrieveModel() {
    this.isLoadingModel = true;
    this.modelLoadError = false;
    this.queueService.getTransactionModel(this.idBundle.transactionId)
      .subscribe(
        // Handle successful retrieval
        (model: any) => {
          this.details.setModel(model);
          this.displayModelData(model);
          this.isLoadingModel = false;
        },
        // Handle error response
        error => {
          this.modelLoadError = true;
          this.isLoadingModel = false;
        }
      )

  }

  retryTransactionDetails(transactionId: string): void {
    event.preventDefault();
    this.getTransactionDetails(transactionId);
  }

  openUpdateModal(settings: any): void {
    let updateContext: UpdateContext = {
      context: settings.context,
      action: settings.action,
      jobs: [],
      stagingFiles: [],
      transactions: [
        {
          productType: this.details.productType,
          productTypeId: this.details.productTypeId,
          status: this.details.status,
          transactionId: this.details.transactionId
        }
      ]
    };
    this.updateModal.openModal( updateContext );
  }

  refreshDetailsFromUpdate(updatePayload: UpdatePayload) {
    // You have to create a new array object to assign to actionHistory, or the grid refresh won't do anything
    let updatedActionsHistory = [...this.details.actionHistory];
    updatedActionsHistory.push( this.queueService.addActionHistory(updatePayload, this.details.jobId) );    
    this.details.actionHistory = updatedActionsHistory;
    this.actionsHistoryGridApi.refreshCells();

    if (updatePayload.action) {
      let cleanStatus = this.details.status.replace(/\s?\([A-Za-z\s]*\)/, "");
      this.details.status = cleanStatus + " (" + updatePayload.action + ")";
      // Even though not currently visible, the grid needs to be updated with the action as well.
      this.updateGrid.emit();
    }
    
  }

}
