<div class="component-container">
  <div class="component-heading-container">
    <div class="col-md-12">
      <div class="row" style="padding-left: 10px">
        <h3 class="component-heading-text">Deploy Utility</h3>
      </div>
    </div>
  </div>
  <div class="container" style="padding: 10px">
    <div id="policy-form-container" class="row">
      <div
        class="heading-container fast_admin col-md-6"
        id="policy-form-heading-container"
      >
        <label class="label_heading mrglt10" style="font-size: 15px"
          >Environment:</label
        >
        <div class="selectdiv select_wrapinline" style="width: 35%">
          <label>
            <ng-select
              class="custom-select"
              [items]="envList"
              bindLabel="Name"
              bindValue="Name"
              (change)="onEnvChange($event)"
              [(ngModel)]="selectedEnv"
            >
              <ng-template ng-option-tmp let-item="item">
                <div title="{{ item.Name }}">{{ item.Name }}</div>
              </ng-template>
            </ng-select>
          </label>
        </div>
      </div>
      <div
        class="heading-container fast_admin col-md-6"
        id="policy-form-heading-container"
      >
        <label class="label_heading mrglt10" style="font-size: 15px"
          >Additional Deploy Environments:</label
        >
        <div class="selectdiv select_wrapinline" style="width: 35%">
          <label>
            <ng-select
              class="custom-select"
              [items]="envList"
              bindLabel="Name"
              bindValue="Name"
              (change)="changeAdditionalEnvs($event)"
              [(ngModel)]="additionalEnvs"
              [multiple]="true"
            >
              <ng-template ng-option-tmp let-item="item">
                <div title="{{ item.Name }}">{{ item.Name }}</div>
              </ng-template>
            </ng-select>
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div style="display: flex" class="row" style="margin-top: 5px">
      <div class="col-md-6">
        <ag-grid-angular
          class="ghostPortal ag-theme-balham"
          [gridOptions]="packageGridOptions"
        >
        </ag-grid-angular>
      </div>
      <div style="padding-left: 25px" class="col-md-6">
        <ag-grid-angular
          class="ghostPortal ag-theme-balham"
          [gridOptions]="mappingGridOptions"
        >
        </ag-grid-angular>
      </div>
      <div
        style="
          display: block;
          box-shadow: inset 0 0 20px #ccc;
          border-radius: 10px;
          padding: 20px;
          margin-top: 10px;
          margin-left: 10px;
          overflow: auto;
          height: 250px;
          width: 25%;
        "
        *ngIf="hasError"
      >
        <div class="row" style="position: center">
          <div
            class="row"
            style="overflow: auto; margin-left: 20px; height: 30%"
          >
            {{ this.error }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container" style="margin-top: 15px">
    <div class="row">
      <div class="col-md-3" style="margin: auto">
        <input
          type="file"
          name="upload"
          id="upload"
          style="border: none; margin: auto"
          (change)="onFileChange($event)"
          multiple
        />
      </div>
      <div class="col-md-6">
        <div
          style="
            display: inline-grid;
            margin-right: 5px;
            font-size: 15px;
            font-weight: bold;
          "
        >
          <label>JIRA:</label>
          <label style="margin-top: 14px">NOTE:</label>
          <label style="margin-top: 12px">FOLDER:</label>
        </div>
        <div style="display: inline-grid">
          <input
            placeholder="JIRA"
            style="
              background-color: var(--color-off-white);
              height: 35px;
              border: none;
              border-bottom: 1px solid gray;
              width: 225px;
            "
            [(ngModel)]="jira"
          />
          <input
            placeholder="NOTE"
            style="
              background-color: var(--color-off-white);
              height: 35px;
              border: none;
              border-bottom: 1px solid gray;
              width: 225px;
              margin-top: 5px;
            "
            [(ngModel)]="note"
          />
          <input
            placeholder="FOLDER NAME"
            style="
              background-color: var(--color-off-white);
              height: 35px;
              border: none;
              border-bottom: 1px solid gray;
              width: 225px;
              margin-top: 5px;
            "
            [(ngModel)]="folder"
          />
        </div>
      </div>
      <div class="col-md-3"></div>
    </div>
  </div>
  <div class="container">
    <div class="row" style="margin-top: 20px">
      <div class="col-md-3">
        <div
          class="heading-container fast_admin"
          id="policy-form-heading-container"
          style="width: 100%"
        >
          <label class="label_heading" style="font-size: 15px">Release:</label>
          <div class="selectdiv select_wrapinline">
            <label>
              <ng-select
                class="custom-select"
                [items]="releaseList"
                bindLabel="Name"
                bindValue="Name"
                [(ngModel)]="selectedRelease"
              >
                <ng-template ng-option-tmp let-item="item">
                  <div title="{{ item.Name }}">{{ item.Name }}</div>
                </ng-template>
              </ng-select>
            </label>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <button
          type="button"
          mat-raised-button
          class="btn-clear-red"
          style="width: 100%"
          (click)="onDeleteRelease()"
          *ngIf="this.isAdmin"
        >
          Delete Selected Release
        </button>
      </div>
      <div class="col-md-3">
        <input
          placeholder="Add New Release"
          style="
            background-color: var(--color-off-white);
            height: 35px;
            width: 100%;
            border: none;
            border-bottom: 1px solid gray;
          "
          [(ngModel)]="newRelease"
          *ngIf="this.isAdmin"
        />
      </div>
      <div class="col-md-3">
        <button
          type="button"
          mat-raised-button
          class="btn-teal"
          style="width: 100%"
          (click)="onAddReleaseClick()"
          *ngIf="this.isAdmin"
        >
          Add Release
        </button>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row" style="margin-top: 20px">
      <div class="col-md-3">
        <button
          type="button"
          mat-raised-button
          class="btn-teal"
          style="width: 100%"
          (click)="onUploadClick()"
               *ngIf="this.isAdmin"
        >
          Upload Files
        </button>
      </div>
      <div class="col-md-3">
        <button
          type="button"
          mat-raised-button
          class="btn-clear-red"
          style="width: 100%"
          (click)="onDeleteClick()"
        >
          Delete Selected Items
        </button>
      </div>
      <div class="col-md-3">
        <button
          type="button"
          mat-raised-button
          class="mat_btn_hov_act"
          style="width: 100%"
          (click)="resetFilters()"
        >
          Reset Grid Filters
        </button>
      </div>
      <div class="col-md-3">
        <button
          type="button"
          mat-raised-button
          class="mat_btn_hov_act"
          style="width: 100%"
          (click)="exportHistory()"
        >
          Export Deploy History
        </button>
      </div>
    </div>
  </div>
  <div class="mrglt10" style="padding-top: 15px">
    <ag-grid-angular
      class="ghostPortal ag-theme-balham"
      enableCellTextSelection="true"
      [gridOptions]="historyGridOptions"
      style="margin-top: 5px; width: 100%"
    >
    </ag-grid-angular>
  </div>
</div>
