//Angular Imports
import { Injectable } from "@angular/core";

//Third Party Imports
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from "moment";


/**
  Date Picker Adapter to convert date to NGBDateStruct and vice versa 
*/
@Injectable()
export class DatePickerAdapter extends NgbDateAdapter<number> {

  /**
   * Constructor
   * @ignore
  */
  constructor() {
    super();
  };

  /**
   * Convert the date to NgbDateStruct
   * @param {number} value 
   * @returns {NgbDateStruct} the date in NGBDateStruct
  */
  fromModel(value: number): NgbDateStruct {
    if (value == null) return null;
    let date = moment.unix(value);
    return { year: date.get('year'), month: date.get('month'), day: date.get('date') };
  }

  /**
   * Convert the NgbDateStruct to Date
   * @param {NgbDateStruct} value date in NGBDateStruct
   * @returns {Date} date 
  */
  toModel(value: NgbDateStruct): number {
    if (value == null) return null;
    let date = moment({ year:value.year, month:value.month - 1, day:value.day }); 
    return date.unix();
  }
}
