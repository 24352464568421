//Angular Imports
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

//Third Party Imports
import { AgGridModule } from "ag-grid-angular";
import { NgSelectModule } from "@ng-select/ng-select";

//Internal Imports
import { FrameworkModule, BooleanFilterComponent } from "../framework";
import { LogSearch } from "../logs/components/log-search";
import { TestHarnessLogs } from "../logs/components/testHarness-logs";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatButtonModule } from "@angular/material/button";

/**
  Main Layout Module
*/
@NgModule({
  declarations: [LogSearch, TestHarnessLogs],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    FrameworkModule,
    MatButtonModule,
    AgGridModule.withComponents([BooleanFilterComponent]),
    MatButtonToggleModule,
  ],
  providers: [],
})
export class LogsModule {}
