<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Log Details</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="row align-items-start">
      <div class="col-md-12" style="padding-left: 0;">
        <mat-tab-group class="vex-tabs" multi>
          <mat-tab label="Transaction Information">
            <div class="row">
              <div class="col-md-6">
                <mat-list>
                  <mat-list-item>
                    <p matLine class="detail-label gen-log-label">
                      Calling System
                    </p>
                    <p matLine class="gen-log-details">
                      {{ this.log.CallingSystem }}
                    </p>
                  </mat-list-item>
                  <mat-list-item>
                    <p matLine class="detail-label gen-log-label">
                      Deal Number
                    </p>
                    <p matLine class="gen-log-details">
                      {{ this.log.DealNumber }}
                    </p>
                  </mat-list-item>
                  <mat-list-item>
                    <p matLine class="detail-label gen-log-label">
                      Transaction ID
                    </p>
                    <p matLine class="gen-log-details">
                      {{ this.log.TransactionId }}
                    </p>
                  </mat-list-item>
                </mat-list>
              </div>
              <div class="col-md-6">
                <mat-list>
                  <mat-list-item>
                    <p matLine class="detail-label gen-log-label">Status</p>
                    <p matLine class="gen-log-details">{{ this.log.Status }}</p>
                  </mat-list-item>
                  <mat-list-item>
                    <p matLine class="detail-label gen-log-label">Timestamp</p>
                    <p matLine class="gen-log-details">
                      {{ this.log.Timestamp }}
                    </p>
                  </mat-list-item>
                </mat-list>
              </div>
            </div>
          </mat-tab>
          
          <mat-tab label="Raw Request Details">
            <pre class="custom-xml">{{ this.jsonString | jsonPipe }}</pre>
            <pre id="JSONtestLogCPM"></pre>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    mat-raised-button
    class="mat_btn_hov_act"
    (click)="this.copyCPM()"
    style="margin-right: 20px"
    color="primary"
  >
    Copy Details to Clipboard
  </button>
  <button
    type="button"
    mat-raised-button
    class="mat_btn_hov_act"
    (click)="activeModal.close()"
  >
    Close
  </button>
</div>
