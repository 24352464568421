/**
  List of Environments to display on the grid for each environment 
*/
export const DISPLAY_ENVIRONMENTS: any = {
  "LOCAL": [
    { Name: "DEV1", Value: "DEV1", IsDev: "true", IsTest: "false", IsProd: "false"},
    { Name: "DEV2", Value: "DEV2", IsDev: "true", IsTest: "false", IsProd: "false" },
    { Name: "QA1", Value: "QA1", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "QA2", Value: "QA2", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "UAT1", Value: "UAT1", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "UAT2", Value: "UAT2", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "PXUAT", Value: "PXUAT", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "FIT", Value: "FIT", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "MIRROR", Value: "MIRROR", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "PROD", Value: "PROD", IsDev: "true", IsTest: "false", IsProd: "true" }
  ],
  "DEV1": [
    { Name: "DEV1", Value: "DEV1", IsDev: "true", IsTest: "false", IsProd: "false"},
    { Name: "DEV2", Value: "DEV2", IsDev: "true", IsTest: "false", IsProd: "false" },
    { Name: "QA1", Value: "QA1", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "QA2", Value: "QA2", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "UAT1", Value: "UAT1", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "UAT2", Value: "UAT2", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "PXUAT", Value: "PXUAT", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "FIT", Value: "FIT", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "MIRROR", Value: "MIRROR", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "PROD", Value: "PROD", IsDev: "true", IsTest: "false", IsProd: "true" }
  ],
  "DEV2": [
    { Name: "DEV1", Value: "DEV1", IsDev: "true", IsTest: "false", IsProd: "false"},
    { Name: "DEV2", Value: "DEV2", IsDev: "true", IsTest: "false", IsProd: "false" },
    { Name: "QA1", Value: "QA1", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "QA2", Value: "QA2", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "UAT1", Value: "UAT1", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "UAT2", Value: "UAT2", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "PXUAT", Value: "PXUAT", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "FIT", Value: "FIT", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "MIRROR", Value: "MIRROR", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "PROD", Value: "PROD", IsDev: "true", IsTest: "false", IsProd: "true" }
  ],
  "QA1": [
    { Name: "DEV1", Value: "DEV1", IsDev: "true", IsTest: "false", IsProd: "false"},
    { Name: "DEV2", Value: "DEV2", IsDev: "true", IsTest: "false", IsProd: "false" },
    { Name: "QA1", Value: "QA1", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "QA2", Value: "QA2", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "UAT1", Value: "UAT1", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "UAT2", Value: "UAT2", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "PXUAT", Value: "PXUAT", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "FIT", Value: "FIT", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "MIRROR", Value: "MIRROR", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "PROD", Value: "PROD", IsDev: "true", IsTest: "false", IsProd: "true" }
  ],
  "QA2": [
    { Name: "DEV1", Value: "DEV1", IsDev: "true", IsTest: "false", IsProd: "false"},
    { Name: "DEV2", Value: "DEV2", IsDev: "true", IsTest: "false", IsProd: "false" },
    { Name: "QA1", Value: "QA1", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "QA2", Value: "QA2", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "UAT1", Value: "UAT1", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "UAT2", Value: "UAT2", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "PXUAT", Value: "PXUAT", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "FIT", Value: "FIT", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "MIRROR", Value: "MIRROR", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "PROD", Value: "PROD", IsDev: "true", IsTest: "false", IsProd: "true" }
  ],
  "UAT1": [
    { Name: "DEV1", Value: "DEV1", IsDev: "true", IsTest: "false", IsProd: "false"},
    { Name: "DEV2", Value: "DEV2", IsDev: "true", IsTest: "false", IsProd: "false" },
    { Name: "QA1", Value: "QA1", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "QA2", Value: "QA2", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "UAT1", Value: "UAT1", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "UAT2", Value: "UAT2", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "PXUAT", Value: "PXUAT", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "FIT", Value: "FIT", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "MIRROR", Value: "MIRROR", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "PROD", Value: "PROD", IsDev: "true", IsTest: "false", IsProd: "true" }
  ],
  "UAT2": [
    { Name: "DEV1", Value: "DEV1", IsDev: "true", IsTest: "false", IsProd: "false"},
    { Name: "DEV2", Value: "DEV2", IsDev: "true", IsTest: "false", IsProd: "false" },
    { Name: "QA1", Value: "QA1", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "QA2", Value: "QA2", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "UAT1", Value: "UAT1", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "UAT2", Value: "UAT2", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "PXUAT", Value: "PXUAT", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "FIT", Value: "FIT", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "MIRROR", Value: "MIRROR", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "PROD", Value: "PROD", IsDev: "true", IsTest: "false", IsProd: "true" }
  ],
  "FIT": [
    { Name: "DEV1", Value: "DEV1", IsDev: "true", IsTest: "false", IsProd: "false"},
    { Name: "DEV2", Value: "DEV2", IsDev: "true", IsTest: "false", IsProd: "false" },
    { Name: "QA1", Value: "QA1", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "QA2", Value: "QA2", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "UAT1", Value: "UAT1", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "UAT2", Value: "UAT2", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "PXUAT", Value: "PXUAT", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "FIT", Value: "FIT", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "MIRROR", Value: "MIRROR", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "PROD", Value: "PROD", IsDev: "true", IsTest: "false", IsProd: "true" }
  ],
  "MIRROR": [
    { Name: "DEV1", Value: "DEV1", IsDev: "true", IsTest: "false", IsProd: "false"},
    { Name: "DEV2", Value: "DEV2", IsDev: "true", IsTest: "false", IsProd: "false" },
    { Name: "QA1", Value: "QA1", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "QA2", Value: "QA2", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "UAT1", Value: "UAT1", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "UAT2", Value: "UAT2", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "PXUAT", Value: "PXUAT", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "FIT", Value: "FIT", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "MIRROR", Value: "MIRROR", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "PROD", Value: "PROD", IsDev: "true", IsTest: "false", IsProd: "true" }
  ],
  "PROD": [
    { Name: "DEV1", Value: "DEV1", IsDev: "true", IsTest: "false", IsProd: "false"},
    { Name: "DEV2", Value: "DEV2", IsDev: "true", IsTest: "false", IsProd: "false" },
    { Name: "QA1", Value: "QA1", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "QA2", Value: "QA2", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "UAT1", Value: "UAT1", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "UAT2", Value: "UAT2", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "PXUAT", Value: "PXUAT", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "FIT", Value: "FIT", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "MIRROR", Value: "MIRROR", IsDev: "true", IsTest: "true", IsProd: "false" },
    { Name: "PROD", Value: "PROD", IsDev: "true", IsTest: "false", IsProd: "true" }
  ]
}


