<div class="modal-header">
  <h4 class="modal-title">{{ actionType }} Test Case</h4>
</div>

<div class="modal-body col-12">
  <!-- Fields -->
  <div class="row" style="padding-bottom: 20px">
    <div class="col-md-5">
      <div class="col-md-12 mrgbtm10">
        <label style="font-weight: bold">Name</label>
        <textarea
          id="txtName"
          style="width: 100%"
          rows="2"
          [(ngModel)]="testCase.Name"
        ></textarea>
      </div>
      <div class="row" style="padding-bottom: 20px">
        <!-- Left Side -->
        <div class="col-md-12 mrgbtm10">
          <div
            id="test-group-grid-container"
            class="col-6"
            style="padding-bottom: 10px; width: 100%"
          >
            <div style="padding-bottom: 5px"><b>Test Groups</b></div>
            <ag-grid-angular
              class="ag-theme-balham fast_panel_table"
              [gridOptions]="testGroupGridOptions"
              style="height: 125px"
            >
            </ag-grid-angular>
          </div>
        </div>
        <div class="row">
          <div id="lob-grid-container" class="col-12" style="padding-top: 10px">
            <div style="padding-bottom: 5px"><b>Lines of Business</b></div>
            <ag-grid-angular
              class="ag-theme-balham fast_panel_table"
              [gridOptions]="lobGridOptions"
              style="height: 125px"
            >
            </ag-grid-angular>
          </div>
        </div>
        <div class="row">
          <div
            id="coverage-grid-container"
            class="col-12"
            style="padding-bottom: 10px"
          >
            <div style="padding-bottom: 5px"><b>Coverages</b></div>
            <ag-grid-angular
              class="ag-theme-balham fast_panel_table"
              [gridOptions]="coverageGridOptions"
              style="height: 125px"
            >
            </ag-grid-angular>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="col-md-12 mrgbtm10">
        <label style="font-weight: bold">Description</label>
        <textarea
          id="txtDescription"
          rows="3"
          style="width: 100%"
          [(ngModel)]="testCase.Description"
        ></textarea>
      </div>
      <div class="col-md-6" disabled>
        <div class="col-md-12 mrgbtm10">
          <label style="font-weight: bold">Company</label>
          <input
            id="txtWritingCompany"
            style="width: 100%"
            [(ngModel)]="testCaseWritingCompany"
          />
        </div>
        <div class="col-md-12 mrgbtm10">
          <label style="font-weight: bold">State</label>
          <input
            id="txtState"
            style="width: 100%"
            [(ngModel)]="testCaseState"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-12 mrgbtm10">
          <label style="font-weight: bold">Policy Effective Date</label>
          <date-picker
            name="txtEffectiveDate"
            id="txtEffectiveDate"
            style="width: 100%"
            [(ngModel)]="testCasePolicyEffectiveDate"
          ></date-picker>
        </div>
      </div>
    </div>
  </div>
  <!-- Grids and Expected Results -->

  <!-- CPM -->
  <div class="row">
    <div class="col-md-12 mrgbtm10">
      <label style="font-weight: bold">CPM</label>
      <textarea
        id="txtCpm"
        rows="3"
        style="width: 100%"
        required
        [(ngModel)]="this.testCaseCPM"
        #txtCpm="ngModel"
      ></textarea>
    </div>
  </div>
  <!-- Forms List -->
  <div class="row"></div>
  <div class="row">
    <label style="font-weight: bold">Forms</label>
    <div class="col-md-12">
      <button type="button" class="btn_gdf" (click)="importCpmForms()">
        Import Forms From CPM
      </button>
      <ag-grid-angular
        class="ag-theme-balham fast_panel_table"
        [gridOptions]="selectedTestCaseFormsGridOptions"
        style="
          width: 25%;
          float: left;
          margin-bottom: 50px;
          margin-right: 100px;
        "
      >
      </ag-grid-angular>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn secondary_btn" (click)="activeModal.close()">
    Cancel
  </button>
  <button type="button" class="btn primary_btn" (click)="saveTestCase()">
    Save
  </button>
</div>
