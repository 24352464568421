<button id="update-action-btn" mat-raised-button class="mdo-standard-button" [matMenuTriggerFor]="updateActionMenu" [disabled]="!isAuthorized()">
  <span *ngIf="singleTransaction">
    Update <i class="fa fa-fw fa-caret-down"></i>
  </span>
  <span *ngIf="!singleTransaction">
    Update {{selectedTransactionCount}} Transaction(s) <i class="fa fa-fw fa-caret-down"></i>
  </span>
</button>
<mat-menu class="mdo-standard-button-dropdown" #updateActionMenu="matMenu">
  <button mat-menu-item (click)="performAction( 'note' )">Add note</button>
  <button mat-menu-item (click)="performAction( 'requestAction' )">Request action</button>
</mat-menu>
