//Angular Imports 
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';


//Internal Imports
import { DeployComponent } from './components';

/**
  Deploy Routes
*/
export const deployRoutes: Routes = [
  {
    path: '', component: DeployComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(deployRoutes)],
  exports: [RouterModule],
  providers: []
})

export class DeployRoutingModule {
}
