<div class="modal-header">
  <h4 class="modal-title">{{ actionType }} Test Case</h4>
</div>

<div class="modal-body container-fluid">
  <!-- Fields -->
  <div class="row" style="padding-bottom: 20px">
    <div class="col-md-3">
      <div class="mrgbtm10">
        <label style="font-weight: bold">Name</label>
        <textarea
          id="txtName"
          style="width: 100%; height: 110px"
          rows="2"
          required
          class="vex-input"
          [(ngModel)]="testCase.Name"
          #txtName="ngModel"
        ></textarea>

        <div
          *ngIf="
            txtName.invalid &&
            (txtName.dirty || txtName.touched || formSubmitted)
          "
        >
          <span *ngIf="txtName.errors.required" class="error_msg">
            <i class="error_icon"></i>Name is required
          </span>
        </div>
      </div>
    </div>

    <div class="col-md-3">
      <div class="mrgbtm10">
        <label style="font-weight: bold">Description</label>
        <textarea
          id="txtDescription"
          rows="3"
          class="vex-input"
          style="width: 100%; height: 110px"
          [(ngModel)]="testCase.Description"
        ></textarea>
      </div>
    </div>

    <div class="col-md-3" disabled>
      <div class="mrgbtm10">
        <label style="font-weight: bold">Company</label>
        <input
          id="txtWritingCompany"
          class="vex-input"
          style="width: 100%"
          [(ngModel)]="testCase.WritingCompany"
        />
      </div>
      <div class="mrgbtm10">
        <label style="font-weight: bold">State</label>
        <input
          id="txtState"
          class="vex-input"
          style="width: 100%"
          [(ngModel)]="testCase.State"
        />
      </div>
    </div>

    <div class="col-md-3">
      <div class="mrgbtm10">
        <label style="font-weight: bold">Policy Effective Date</label>
        <date-picker
          name="txtEffectiveDate"
          id="txtEffectiveDate"
          style="width: 100%"
          [(ngModel)]="testCase.PolicyEffectiveDate"
        ></date-picker>
      </div>
      <div class="mrgbtm10">
        <label>
          <input
            id="chkIsActive"
            type="checkbox"
            style="display: inline-block; margin-right: 5px"
            [(ngModel)]="testCase.IsActive"
          />Is Active
        </label>
      </div>
    </div>
  </div>

  <!-- Grids and Expected Results -->
  <div class="row" style="padding-bottom: 20px">
    <!-- Left Side -->
    <div class="col-md-6">
      <div
        id="test-group-grid-container"
        class="col-12"
        style="padding-bottom: 10px"
      >
        <div style="padding-bottom: 5px"><b>Test Groups</b></div>
        <ag-grid-angular
          class="ag-theme-balham fast_panel_table"
          [gridOptions]="testGroupGridOptions"
          style="height: 125px"
        >
        </ag-grid-angular>
      </div>

      <div id="lob-grid-container" class="col-12" style="padding-bottom: 10px">
        <div style="padding-bottom: 5px"><b>Lines of Business</b></div>
        <ag-grid-angular
          class="ag-theme-balham fast_panel_table"
          [gridOptions]="lobGridOptions"
          style="height: 125px"
        >
        </ag-grid-angular>
      </div>

      <div
        id="coverage-grid-container"
        class="col-12"
        style="padding-bottom: 10px"
      >
        <div style="padding-bottom: 5px"><b>Coverages</b></div>
        <ag-grid-angular
          class="ag-theme-balham fast_panel_table"
          [gridOptions]="coverageGridOptions"
          style="height: 125px"
        >
        </ag-grid-angular>
      </div>
    </div>

    <!-- Right Side - Expected Results-->
    <div class="col-md-6">
      <app-test-case-expected-result
        [actionType]="actionType"
        [testCase]="testCase"
      ></app-test-case-expected-result>
    </div>
  </div>

  <!-- CPM -->
  <div class="row">
    <div class="col-md-12 mrgbtm10">
      <label style="font-weight: bold">CPM</label>
      <textarea
        id="txtCpm"
        rows="3"
        style="width: 100%; color: navy !important; font-size: 14px"
        required
        [(ngModel)]="testCase.CPM"
        #txtCpm="ngModel"
      ></textarea>

      <div
        *ngIf="
          txtCpm.invalid && (txtCpm.dirty || txtCpm.touched || formSubmitted)
        "
      >
        <span *ngIf="txtCpm.errors.required" class="error_msg">
          <i class="error_icon"></i>CPM is required
        </span>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="mat_btn_hov_act mrgrgt10"
    mat-raised-button
    (click)="activeModal.close()"
  >
    Cancel
  </button>
  <button
    type="button"
    mat-raised-button
    class="btn-teal"
    (click)="saveTestCase()"
    *ngIf="this.actionType != 'Read'"
  >
    Save
  </button>
</div>
