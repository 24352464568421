//Angular Imports 
import { Component, HostBinding } from '@angular/core';

//Third Party Imports 
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

//Internal Imports 


import 'rxjs';
import { AppInsightsService, EnvironmentService, DateTimeUtcPipe, SharedService, ApplicationInsightsBaseComponent, CPMElement } from '../../../framework';
import { ImageRightService } from '../services/imageRight.service';
import { Rule } from '../models/rule.model';
import { ElementExpression } from '../models/elementExpression.model';
import { RuleSet } from '../models/ruleSet.model';
import { RuleSetup } from '../models/ruleSetup.model';
import { FormNumber } from '../../rules/models';
import { Folder } from '../models/folder.model';
import { EXECUTION_ENGINE_LIST } from '../../../report/configs';


/**
 * Forms Component
*/
@Component({
  selector: 'app-ImageRight-settings',
  templateUrl: './imageRight.component.html',
  styleUrls: ['./imageRight.component.scss'],
  providers: [ImageRightService]
})

export class ImageRightComponent extends ApplicationInsightsBaseComponent {

  @HostBinding('style.width') width: string;

  initialRowDataLoad$;

  // Used to populate environment list on Unit Test and Run Test Groups tabs
  envList: any[];

  // Holds the environment name selected from the environment list on the Unit Test or Run Test Groups tab
  selectedEnv: string;

  public systemList: string[] = ['e2', 'Magic','Maverick'];

  selectedSystem: string;

  public folderList: Folder[];

  public systemFolderList: Folder[];

  public selectedFolder: Folder;

  public newFolderName: string;

  currentRuleSetup: RuleSetup = new RuleSetup();

  public systemIsSelected: boolean = false;


  /** 
   * Constructor
   * @ignore
  */
  constructor(private _appInsightsService: AppInsightsService,
    private toastr: ToastrService,
    private _modal: NgbModal,
    private imageRightService: ImageRightService,
    private sharedService: SharedService,
    private dateTimePipe: DateTimeUtcPipe,
    private environmentService: EnvironmentService) {
    super(_appInsightsService);
    this.width = "100%";
    this.envList = EXECUTION_ENGINE_LIST;
    this.selectedEnv = this.envList[0].Name;
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
  */
  ngOnInit(): void {
    this.currentRuleSetup.RuleSet = new RuleSet();
    this.currentRuleSetup.RuleSet.Rules = [];
    this.imageRightService.getAllFolders().subscribe(res => {
      this.folderList = res;
    },
      error => { this.toastr.error('Error occurred while loading forms list.', 'Form Page'); }
    );
  }

  /**
   * NgOnDestroy
   * @ignore
  */
  ngOnDestroy(): void {

  }

  onSelectSystem(event) {
    this.systemIsSelected = true;
    this.systemFolderList = this.folderList.filter(x => x.System == this.selectedSystem);
  }

  onSelectFolder(event) {
    // load new form into page
    console.log(this.selectedFolder);

  }

  saveFolder(event) {
    //If ID "" then add new folder
    if (this.selectedFolder.id == "") {
      this.imageRightService.addFolder(this.selectedFolder).subscribe(res => {
        console.log(res);
        this.selectedFolder.id = res;
      });
    }
    else {
      this.imageRightService.upsertFolder(this.selectedFolder).subscribe(res => {
        console.log(res);
      });
    }
    
  }

  deleteFolder(event) {
    if (confirm("Are you sure you want to delete the selected folder?")) {
      this.imageRightService.deleteFolder(this.selectedFolder.id).subscribe(res => {
        console.log(res);
        this.selectedFolder = null;
      });
    }
  }

  addFolder(event) {
    var newFolder = new Folder();
    newFolder.id = "";
    newFolder.FastKey = "FastKey";
    newFolder.FolderName = this.newFolderName;
    newFolder.RuleSet = new RuleSet();
    newFolder.System = this.selectedSystem;
    this.folderList.push(newFolder);
    this.selectedFolder = this.folderList[this.folderList.length - 1];
  }


  onAddRule(template: string): void {
    let newRule: Rule = new Rule();
    newRule.Expressions = [];

    if (template === 'basic_remove') {
      newRule.Action = "Remove";

      let expression: ElementExpression = new ElementExpression();
      expression.ElementName = "Coverage";
      expression.Operator = "in";
      expression.IncludedList = [];
      expression.MandatoryList = [];
      newRule.Expressions.push(expression);

    } else if (template === 'basic_attach') {
      newRule.Action = "Attach";

      let expression: ElementExpression = new ElementExpression();
      expression.ElementName = "Writing Company";
      expression.Operator = "in";
      expression.IncludedList = [];
      expression.MandatoryList = [];
      newRule.Expressions.push(expression);

    }

    this.selectedFolder.RuleSet.Rules.push(newRule);
  }

  onSaveRule(event) {

  }
  onDeleteRule(event) {
    let index = this.selectedFolder.RuleSet.Rules.indexOf(event);
    if (index >= 0) {
      this.selectedFolder.RuleSet.Rules.splice(index, 1);
    }
  }


}
