//Angular Imports 
import { Component, OnInit, Input, ViewChild } from '@angular/core';

//Third Party Imports 
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

//Internal Imports 
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms'
import { CPMService } from '../../services';
import { CPMElement } from '../../../framework/models/cpm-element.model';
import { CPMField } from '../../../framework/models/cpm-Field.model';
import { SharedService, ConfirmationModalComponent, UserProfileService } from '../../../framework';

import { error } from 'util';
import { DateTime } from 'luxon';

/**
 * Add Form Component
*/
@Component({
  selector: 'app-add-cpm-element',
  templateUrl: './add-cpm-element.component.html',
  styleUrls: ['./add-cpm-element.component.scss']
})

export class AddCPMElementComponent implements OnInit {

  /**
* formGroup
*/
  formGroup: UntypedFormGroup;

  /**
* formGroup
*/
  public readonly: boolean = false;


  /**
* current CPMElement
*/
  currentCPMElement: CPMElement;


  /**
* actionType
*/
  actionType: string;




  /**
 * Constructor
 * @ignore
*/
  constructor(public activeModal: NgbActiveModal, private _modal: NgbModal,
    private fb: UntypedFormBuilder, private cpmService: CPMService,
    private sharedService: SharedService, private toastr: ToastrService,
    private userProfileService: UserProfileService) {
  }


  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
  */
  ngOnInit() {

    this.createFormBuilder();
    this.sharedService.getMasterData().subscribe(el => {

      //this.createFormBuilder();
      this.addControlInFormGroup();
      this.fillFormBuilder();
    }, error => { this.toastr.error('Error occurred while fetching master data.', 'CPM'); });
    ;
  }


  /**
   * NgDestroy
   * @ignore
  */
  ngOnDestroy(): void {
  }


  /**
   * Create form for builder
   **/
  private createFormBuilder(): void {
    if (this.actionType == "Add" || this.actionType == "Edit") {
      this.formGroup = this.fb.group({
        cPMDataElementName: [{ value: '', disabled: this.readonly }, [Validators.required]],
        cPMExpression: [{ value: '', disabled: this.readonly }, [Validators.required]],
        complexType: [{ value: '', disabled: this.readonly }],
        ruleType: [{ value: '', disabled: this.readonly }]
      });
    }
  }

  /**
   * Fill Form Builder
   * */
  private fillFormBuilder(): void {
    if (this.actionType == "Add") {
      this.currentCPMElement = new CPMElement();
      this.currentCPMElement.ComplexType = false;
      this.currentCPMElement.RuleType = 0;
    }
    else {
      this.formGroup.patchValue({
        cPMDataElementName: this.currentCPMElement.CPMDataElementName,
        cPMExpression: this.currentCPMElement.CPMExpression,
        complexType: this.currentCPMElement.ComplexType,
        ruleType: this.currentCPMElement.RuleType == 1 ? true : false
      })
    }
  }


  /**
   *Add LOB list as checkbox control in form group
   * */
  addControlInFormGroup() {
    if (this.formGroup == undefined) {
      this.createFormBuilder();
    }
  }


  /**
  * Save Rule Type
  **/
  saveCpmElement() {

    this.addControlInFormGroup();

    if (this.formGroup.valid) {
      if (this.actionType == "Add") {
        this.currentCPMElement.CreatedByName = this.userProfileService.UserName;
      }
      else {
        this.currentCPMElement.ModifiedByName = this.userProfileService.UserName;
      }
      this.currentCPMElement.CPMDataElementName = this.formGroup.controls["cPMDataElementName"].value;
      this.currentCPMElement.CPMExpression = this.formGroup.controls["cPMExpression"].value;
      this.currentCPMElement.CreatedByName = this.userProfileService.UserName;
      this.currentCPMElement.ComplexType = this.formGroup.controls["complexType"].value == true ? true : false;
      this.currentCPMElement.RuleType = this.formGroup.controls["ruleType"].value == true ? 1 : 0;

      this.cpmService.updateCPMElement(this.currentCPMElement).subscribe(result => {

        this.activeModal.close(result);
        this.toastr.success('CPM Element Data Saved Successfully', 'CPM');
      }, error => { this.toastr.error('Error occurred while updating CPM Element form.', 'CPM'); });

    }
  }
}

