<div class="row">
  <div class="col-md-9">
    <h2 class="dash-item-title">Partner-Product Status</h2>
  </div>
  <div class="col-md-3">
    <div *ngIf="isLoading">
      <mat-progress-spinner mode="indeterminate" diameter="30"></mat-progress-spinner>
    </div>
  </div>
</div>

<div *ngIf="!isLoading && showLoadError">
  <p class="alert mdo-alert-danger">Could not load partner-product data, please <a class="mdo-alert-danger-link" href="" (click)="retryDataRetrieval()">click here</a> to try again.</p>
</div>

<div *ngIf="!isLoading && !showLoadError">

  <div class="row pp-header-row">
    <div class="col-md-4 pp-col-header pp-first-col-header">
      <p>Partner - Product</p>
    </div>
    <div class="col-md-3 pp-col-header pp-interior-col-header">
      <p>Last Updated</p>
    </div>
    <div class="col-md-2 pp-col-header pp-interior-col-header">
      <p>Last Updated By</p>
    </div>
    <div class="col-md-3 pp-col-header pp-final-col-header">
      <p>Status</p>
    </div>
  </div>

  <mat-accordion>

    <mat-expansion-panel *ngFor="let pp of partnerProductArray">
      <mat-expansion-panel-header class="mdo-mat-panel-header">
        <mat-panel-title>
          <div class="row pp-current-row">
            <div class="col-md-4">
              {{pp.fullname}}
            </div>
            <div class="col-md-3">
              {{pp.lastUpdated}}
            </div>
            <div class="col-md-2">
              {{pp.lastUpdatedBy}}
            </div>
            <div class="col-md-3">
              <div *ngIf="!pp.needsConfirmation && !pp.isProcessing">
                <span *ngIf="!updatesAuthorized">{{pp.pauseStatus}}</span>
                <span *ngIf="updatesAuthorized"><a href="" (click)="initiateChange(pp.id)">{{pp.pauseStatus}}</a>&nbsp;&nbsp;</span>
                <span *ngIf="pp.updateErrorMsg" class="mdo-alert-danger pp-update-error">{{pp.updateErrorMsg}}</span>
              </div>
              <div *ngIf="pp.needsConfirmation && !pp.isProcessing">
                <a href="" (click)="confirmChange(pp.id)">Confirm</a> | <a href="" (click)="cancelChange(pp.id)">Cancel</a>
              </div>
              <div *ngIf="!pp.needsConfirmation && pp.isProcessing">
                <mat-progress-spinner mode="indeterminate" diameter="20"></mat-progress-spinner>
              </div>

            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <p class="pp-history-title">History</p>


      <div class="pp-history" *ngIf="pp.history.length">
        <div class="row" *ngFor="let hist of pp.history">
          <div class="col-md-4">
            &nbsp;
          </div>
          <div class="col-md-3 pp-history-col-2">
            {{hist.lastUpdated}}
          </div>
          <div class="col-md-2 pp-history-col-3">
            {{hist.lastUpdatedBy}}
          </div>
          <div class="col-md-3 pp-history-col-4">
            {{hist.pauseStatus}}
          </div>
        </div>
      </div>

      <div *ngIf="!pp.history.length">
        <div class="col-md-3">
          &nbsp;
        </div>
        <div class="col-md-9">
          <p>No previous status records.</p>
        </div>
      </div>

    </mat-expansion-panel>

  </mat-accordion>

</div>
