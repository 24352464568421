//Angular Imports 
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { MigrationLogs } from "../../models/migrationLogs";

//Third Party Imports


/**
  * Policy Forms Provider
*/
@Injectable()
export class MigrationService {

  private masterData: any;
  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient) {
  }

  getMigrationLogs(): Observable<MigrationLogs> {
    let url = `api/RuleEngineLog/MigrationStats`;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get<MigrationLogs>(url).pipe(
      map(res => res)
    );
  }

}
