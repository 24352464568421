//Angular Imports
import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, UntypedFormBuilder, Validators } from "@angular/forms";

import { ToastrService } from "ngx-toastr";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { GridOptions } from "ag-grid-community";
import { saveAs } from "file-saver";

//Internal Imports
import { DeployPackage, SelectablePackage } from "../../models";
import { DeployService } from "../../services";
import {
  UserProfileService,
  SelectableItem,
  SharedService,
  DateTimePipe,
} from "../../../framework";
import {
  TestingService,
  ExecutionService,
} from "../../../diagnostics/testSuites/execution/services";

/**
 * Deploy Confirmation Component
 */
@Component({
  selector: "package-report",
  templateUrl: "./package-report.component.html",
  styleUrls: ["./package-report.component.scss"],
})
export class PackageReportComponent implements OnInit {
  @Input() packageList: DeployPackage[];

  productionDeployment: boolean = false;

  packageReportGridOptions: GridOptions;
  testGroupSelectableItems: SelectablePackage[];

  /**
   * Constructor
   * @ignore
   */
  constructor(
    public activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private deployService: DeployService,
    private toastr: ToastrService,
    private userProfileService: UserProfileService,
    private dateTimePipe: DateTimePipe,
    private testingService: TestingService,
    private executionService: ExecutionService,
    private sharedService: SharedService
  ) {
    this.testGroupSelectableItems = [];
  }

  //Angular Lifecycles
  /**
   * On Init
   * @ignore
   */
  ngOnInit() {
    this.configurePackageReportGrid();
  }
  setRowData() {
    if (this.packageList != null) {
      for (var i = 0; i < this.packageList.length; i++) {
        const selectableItem = new SelectablePackage();
        selectableItem.Selected = false;
        selectableItem.PackageName = this.packageList[i].PackageName;
        selectableItem.LOB = this.packageList[i].LOB;
        selectableItem.Release = this.packageList[i].Release;
        selectableItem.CreatedDate = this.packageList[i].CreatedDate;
        selectableItem.CreatedBy = this.packageList[i].CreatedBy;
        console.log(this.packageList[i]);
        if (this.packageList[i].EnvDeploymentStates) {
          if (this.packageList[i].EnvDeploymentStates.QA1) {
            if (this.packageList[i].EnvDeploymentStates.QA1.IsDeployed) {
              selectableItem.DeployedToQA1 = this.deployedAt(
                this.packageList[i],
                "QA1"
              );
            } else {
              selectableItem.DeployedToQA1 = "FALSE";
            }
          }
          if (this.packageList[i].EnvDeploymentStates.QA2) {
            if (this.packageList[i].EnvDeploymentStates.QA2.IsDeployed) {
              selectableItem.DeployedToQA2 = this.deployedAt(
                this.packageList[i],
                "QA2"
              );
            } else {
              selectableItem.DeployedToQA2 = "FALSE";
            }
          }
          if (this.packageList[i].EnvDeploymentStates.UAT1) {
            if (this.packageList[i].EnvDeploymentStates.UAT1.IsDeployed) {
              selectableItem.DeployedToUAT1 = this.deployedAt(
                this.packageList[i],
                "UAT1"
              );
            } else {
              selectableItem.DeployedToUAT1 = "FALSE";
            }
          }
          if (this.packageList[i].EnvDeploymentStates.UAT2) {
            if (this.packageList[i].EnvDeploymentStates.UAT2.IsDeployed) {
              selectableItem.DeployedToUAT2 = this.deployedAt(
                this.packageList[i],
                "UAT2"
              );
            } else {
              selectableItem.DeployedToUAT2 = "FALSE";
            }
          }
          if (this.packageList[i].EnvDeploymentStates.FIT) {
            if (this.packageList[i].EnvDeploymentStates.FIT.IsDeployed) {
              selectableItem.DeployedToFIT = this.deployedAt(
                this.packageList[i],
                "FIT"
              );
            } else {
              selectableItem.DeployedToFIT = "FALSE";
            }
          }
          if (this.packageList[i].EnvDeploymentStates.PROD) {
            if (this.packageList[i].EnvDeploymentStates.PROD.IsDeployed) {
              selectableItem.DeployedToPROD = this.deployedAt(
                this.packageList[i],
                "PROD"
              );
            } else {
              selectableItem.DeployedToPROD = "FALSE";
            }
          }
        }
        this.testGroupSelectableItems.push(selectableItem);
      }
      this.packageReportGridOptions.api.setRowData(
        this.testGroupSelectableItems
      );
    }
  }

  createReport() {
    let gridSelectedItems = this.packageReportGridOptions.api.getSelectedRows();
    let selectedItems: any[] = [];
    for (var i = 0; i < gridSelectedItems.length; i++) {
      selectedItems.push(gridSelectedItems[i]);
    }
    for (var j = 0; j < selectedItems.length; j++) {
      selectedItems[j].CreatedDate = new Date(
        selectedItems[j].CreatedDate * 1000
      );
    }
    const replacer = (key, value) => (value === null ? "" : value);
    const header = [
      "CreatedDate",
      "PackageName",
      "LOB",
      "Release",
      "CreatedBy",
      "DeployedToQA1",
      "DeployedToQA2",
      "DeployedToUAT1",
      "DeployedToUAT2",
      "DeployedToFIT",
      "DeployedToPROD",
    ];
    let csv = selectedItems.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    csv.unshift(header.join(","));
    let csvArray = csv.join("\r\n");
    var blob = new Blob([csvArray], { type: "text/csv" });
    saveAs(blob, "report.csv");
    this.activeModal.close();
  }

  private configurePackageReportGrid(): void {
    //this.initialRowDataLoad$ = [];
    this.packageReportGridOptions = <GridOptions>{
      rowSelection: "multiple",
      domLayout: "normal",
      columnDefs: this.createTestGroupColumnDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: false,
      //paginationPageSize: 10,
      rowHeight: 40,
      enableColResize: true,
      onGridReady: () => {
        console.log("Ready?");
        this.packageReportGridOptions.api.sizeColumnsToFit();
        this.setRowData();
      },
      onRowClicked: (event) => {
        this.onRowSelected(event);
      },
      onFilterChanged: (event) => {},
      onSortChanged: (event) => {},
      onFilterModified: function (event) {
        //console.log(event);
      },
    };
  }

  private createTestGroupColumnDef(): any[] {
    return [
      {
        headerName: "",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        field: "Selected",
        suppressMenu: true,
        suppressSorting: true,
        cellStyle: {
          display: "flex",
          "justify-content": "center",
          "align-items": "center",
        },
        width: 45,
        filter: "booleanFilterComponent",
        cellRenderer: (params) => {
          return ``;
        },
      },
      {
        headerName: "Package Name",
        field: "PackageName",
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          return `<span> ${params.value} </span>`;
        },
      },
      {
        headerName: "LOB",
        field: "LOB",
        width: 100,
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Release Tag",
        field: "Release",
        width: 100,
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Published By",
        field: "CreatedBy",
        width: 100,
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Created Date",
        field: "CreatedDate",
        filter: "dateFilterComponent",
        width: 150,
        sort: "desc",
        cellRenderer: (params) => {
          return this.dateTimePipe.transform(
            params.value,
            "string",
            "MM/DD/YYYY h:mm A"
          );
        },
      },
    ];
  }

  private onRowSelected(event: any): void {
    //event.data.Selected = event.node.selected;

    if (event.node.selected) {
      event.data.Selected = true;
      this.packageReportGridOptions.api.refreshCells();
    } else {
      event.data.Selected = false;
      this.packageReportGridOptions.api.refreshCells();
    }
  }

  private deployedAt(packageData: DeployPackage, environment: string): string {
    let deployedAt: string;
    _.forEach(packageData.DeploymentHistories, (env) => {
      if (env.Target == environment) {
        deployedAt = "" + this.dateTimePipe.transform(env.DeployedDate);
      }
    });
    return deployedAt;
  }
}
