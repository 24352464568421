//Angular Imports
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

//Third Party Imports
import { GridOptions } from "ag-grid-community";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

//Internal Imports
import { AuthorizationService } from "../../../../../framework";
import { TestingService } from "../../services";
import { TestCase, TestGroup } from "../../models";

import "rxjs";
import { TestGroupDetail } from "../../components/test-group-detail";
import { TestCaseDetail } from "../../components/test-case-detail";
import { saveAs } from "file-saver";
import { BulkUpdateTests } from "../bulk-update-tests/bulk-update-tests.component";

/**
 * Forms Component
 */
@Component({
  selector: "app-test-maintenance",
  templateUrl: "./test-maintenance.component.html",
  styleUrls: ["./test-maintenance.component.scss"],
})
export class TestMaintenance {
  testGroupGridOptions: GridOptions;
  testCaseGridOptions: GridOptions;
  testGroups: TestGroup[];
  testCases: TestCase[];
  selectedTestCases: TestCase[];

  /**
   * Constructor
   * @ignore
   */
  constructor(
    private testingService: TestingService,
    private _modal: NgbModal,
    private toastr: ToastrService,
    private authService: AuthorizationService
  ) {}

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
   */
  ngOnInit(): void {
    this.configureTestCaseGrid();
    this.configureTestGroupGrid();
  }

  /**
   * NgOnDestroy
   * @ignore
   */
  ngOnDestroy(): void {}

  private onTestGroupRowClicked(e) {
    if (e.event.target !== undefined) {
      let data = e.data;
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "Edit":
          return this.editTestGroup(data);
        case "Delete":
          return this.deleteTestGroup(data);
      }
      this.highlightTestCases(data);
    }
  }

  exportInfoToCSV() {
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = ["TestGroups", "Name", "Description", "CreatedOn"];
    let temp: TestCase[] = this.testCases;

    for (var tc = 0; tc < temp.length; tc++) {
      console.log(temp[tc])
      temp[tc].CreatedOn = new Date(temp[tc].CreationDate * 1000);
      for (var i = 0; i < temp[tc].TestGroups.length; i++) {
        let testGroup = this.testGroups.filter(tg => temp[tc].TestGroups[i] == tg.Id);
        if (testGroup.length > 0) {
          temp[tc].TestGroups[i] = testGroup[0].Name;
        }
      }
    }


    let csv = temp.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    csv.unshift(header.join(','));
    let csvFullString = csv.join('\r\n');

    //console.log(csv)

    var file = new File([csvFullString], "FastReport.csv", { type: "text/plain;charset=utf-8" });
    saveAs(file);
  }

  highlightTestCases(data: TestGroup): void {
    for (var i = 0; i < this.testCases.length; i++) {
      if (this.testCases[i].TestGroups.indexOf(data.Id) != -1) {
        this.testCases[i].IsSelected = true;
      } else {
        this.testCases[i].IsSelected = false;
      }
    }

    this.testCases.sort((a, b) => {
      if (a.IsSelected == b.IsSelected) {
        return 0;
      } else if (a.IsSelected) {
        return -1;
      } else {
        return 1;
      }
    });

    this.testCaseGridOptions.api.setRowData(this.testCases);
    this.testCaseGridOptions.api.forEachNode((node) => {
      if (node.data.IsSelected) {
        node.setSelected(true);
      }
    });
  }

  createTestGroup(): void {
    const modalRef = this._modal.open(TestGroupDetail, { backdrop: "static" });
    modalRef.componentInstance.actionType = "Add";

    modalRef.result.then((result: TestGroup) => {
      if (result) {
        this.testGroups.push(result);
        this.setTestGroupRowData();
      }
    });
  }

  updateTests(): void {
    this.selectedTestCases = [];
    for (var i = 0; i < this.testCases.length; i++) {
      if (this.testCases[i].IsSelected) {
        this.selectedTestCases.push(this.testCases[i]);
      }
    }

    const modalRef = this._modal.open(BulkUpdateTests, {
      backdrop: "static",
    });
    console.log(this.selectedTestCases);
    modalRef.componentInstance.testCases = this.selectedTestCases;
  }

  editTestGroup(testGroup: TestGroup): void {
    this.testingService.getTestGroupById(testGroup.Id).subscribe((res) => {
      const modalRef = this._modal.open(TestGroupDetail, {
        backdrop: "static",
      });
      modalRef.componentInstance.testGroup = res;
      modalRef.componentInstance.actionType = "Edit";

      modalRef.result.then((result: TestGroup) => {
        if (result) {
          let index = this.testGroups.findIndex((x) => x.Id === result.Id);
          if (index >= 0) {
            this.testGroups.splice(index, 1);
            this.testGroups.push(result);
          }
          this.setTestGroupRowData();
        }
      });
    });
  }

  deleteTestGroup(testGroup: TestGroup): void {
    if (confirm("Delete the test group?")) {
      this.testingService.deleteTestGroup(testGroup.Id).subscribe((res) => {
        if (res) {
          let index = this.testGroups.findIndex((x) => x.Id === testGroup.Id);
          if (index >= 0) {
            this.testGroups.splice(index, 1);
          }
          this.setTestGroupRowData();
          this.toastr.success("Test Group successfully deleted.");
        } else {
          this.toastr.error("Error deleting Test Group.");
        }
      });
    }
  }

  private setTestGroupRowData() {
    if (this.testGroups) {
      this.testGroups.sort((a: any, b: any) => {
        var nameA = a.Name.toLowerCase();
        var nameB = b.Name.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      this.testGroupGridOptions.api.setRowData(this.testGroups);
    }
  }

  private onTestCaseRowClicked(e) {
    if (e.event.target !== undefined) {
      let data = e.data;
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "Edit":
          return this.editTestCase(data);
        case "Delete":
          return this.deleteTestCase(data);
      }
    }
  }

  // This functionality is disabled (add button has been removed) because we should not create test cases this
  // way, they should be created from log items.
  createTestCase(): void {
    const modalRef = this._modal.open(TestCaseDetail, {
      windowClass: "test-case-detail-modal",
    });
    modalRef.componentInstance.actionType = "Add";

    modalRef.result.then((result: TestCase) => {
      if (result) {
      }
    });
  }

  editTestCase(testCase: TestCase): void {
    this.testingService.getTestCaseById(testCase.Id).subscribe((res) => {
      const modalRef = this._modal.open(TestCaseDetail, {
        windowClass: "test-case-detail-modal",
      });
      modalRef.componentInstance.testCase = res;
      modalRef.componentInstance.actionType = "Edit";

      modalRef.result.then((result: TestCase) => {
        if (result) {
          let index = this.testCases.findIndex((x) => x.Id === result.Id);
          if (index >= 0) {
            this.testCases.splice(index, 1);
            this.testCases.push(result);
          }
          this.setTestCaseRowData;
        }
      });
    });
  }

  deleteTestCase(testCase: TestCase): void {
    this.testingService.deleteTestCase(testCase.Id).subscribe((res) => {
      if (res) {
        let index = this.testCases.findIndex((x) => x.Id === testCase.Id);
        if (index >= 0) {
          this.testCases.splice(index, 1);
        }
        this.setTestCaseRowData();
        this.toastr.success("Test Case successfully deleted.");
      } else {
        this.toastr.error("Error deleting Test Case.");
      }
    });
  }

  private setTestCaseRowData() {
    if (this.testCases) {
      this.testCases.sort((a: any, b: any) => {
        var nameA = a.Name.toLowerCase();
        var nameB = b.Name.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      this.testCaseGridOptions.api.setRowData(this.testCases);
    }
  }

  private configureTestGroupGrid(): void {
    //this.initialRowDataLoad$ = [];
    this.testGroupGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: "normal",
      columnDefs: this.createTestGroupColumDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      paginationPageSize: 8,
      rowHeight: 30,
      enableColResize: true,
      onGridReady: () => {
        this.testingService.getTestGroups().subscribe((res) => {
          this.testGroups = res;
          this.setTestGroupRowData();
        });
        //this.resultsGridOptions.api.setRowData(this.allResults);
        this.testGroupGridOptions.api.sizeColumnsToFit();
        //this.resultsGridOptions.columnApi.autoSizeAllColumns();
      },
      onRowClicked: (event) => {
        this.onTestGroupRowClicked(event);
      },
      onFilterChanged: (event) => {},
      onSortChanged: (event) => {},
      onFilterModified: function (event) {
        //console.log(event);
      },
    };
  }

  private createTestGroupColumDef(): any[] {
    return [
      {
        headerName: "Name",
        field: "Name",
        resizable: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
      {
        headerName: "Description",
        field: "Description",
        resizable: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
      {
        headerName: "TestGroup Type",
        field: "TestGroupType",
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
      {
        headerName: "LOB",
        field: "LOBs",
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
      {
        headerName: "Actions",
        suppressMenu: true,
        suppressSorting: true,
        width: 80,
        cellStyle: { display: "flex", "justify-content": "center" },
        //hide: !this.FormsWritePermission,  // Hide column if user does not have write permission
        cellRenderer: (params) => {
          if (this.authService.Administrator()) {
            return `
          <img src="/assets/images/edit_icon.png" data-action-type="Edit" class="cursor_pointer mrgrgt10" title="Edit">
          <img src="/assets/images/x_icon.png" data-action-type="Delete" class="cursor_pointer mrgrgt10" title="Delete" >
          `;
          } else {
            return ` <img src="/assets/images/edit_icon.png" data-action-type="Edit" class="cursor_pointer mrgrgt10" title="Edit">`;
          }
        },
      },
    ];
  }

  private configureTestCaseGrid(): void {
    //this.initialRowDataLoad$ = [];
    this.testCaseGridOptions = <GridOptions>{
      rowSelection: "multiple",
      domLayout: "normal",
      columnDefs: this.createTestCaseColumDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      paginationPageSize: 10,
      rowHeight: 30,
      enableColResize: true,
      onGridReady: () => {
        this.testingService.getAllTestCaseSummary().subscribe((res) => {
          this.testCases = res;
          this.setTestCaseRowData();
        //  this.exportInfoToCSV();
        });
        //this.resultsGridOptions.api.setRowData(this.allResults);
        this.testCaseGridOptions.api.sizeColumnsToFit();

        //this.resultsGridOptions.columnApi.autoSizeAllColumns();
      },
      onRowClicked: (event) => {
        this.onTestCaseRowClicked(event);
      },
      onFilterChanged: (event) => {},
      onSortChanged: (event) => {},
      onFilterModified: function (event) {
        //console.log(event);
      },
    };
  }

  private createTestCaseColumDef(): any[] {
    return [
      {
        headerName: "Name",
        field: "Name",
        resizable: true,
        filter: "agTextColumnFilter",
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
      {
        headerName: "Description",
        resizable: true,
        filter: "agTextColumnFilter",
        field: "Description",
      },
      //{ headerName: 'TestGroups', field: 'TestGroups', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      {
        headerName: "LOB",
        field: "LOBs",
        filter: "agTextColumnFilter",
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
      {
        headerName: "Is Active",
        suppressSorting: true,
        field: "IsActive",
        filter: "booleanFilterComponent",
        cellRenderer: (params) => {
          return `<input type='checkbox' ${
            params.value ? "checked" : ""
          } disabled/>`;
        },
      },
      {
        headerName: "Actions",
        suppressMenu: true,
        suppressSorting: true,
        width: 80,
        cellStyle: { display: "flex", "justify-content": "center" },
        //hide: !this.FormsWritePermission,  // Hide column if user does not have write permission
        cellRenderer: (params) => {
          if (this.authService.Administrator()) {
            return `
          <img src="/assets/images/edit_icon.png" data-action-type="Edit" class="cursor_pointer mrgrgt10" title="Edit">
          <img src="/assets/images/x_icon.png" data-action-type="Delete" class="cursor_pointer mrgrgt10" title="Delete" >
          `;
          } else {
            return ` <img src="/assets/images/edit_icon.png" data-action-type="Edit" class="cursor_pointer mrgrgt10" title="Edit">`;
          }
        },
      },
    ];
  }
}
