<p id="job-request-button">
  <button id="matching-queue-data-btn" mat-raised-button class="mdo-standard-button" (click)="executeFilter()">Get Matching Queue Data</button>
</p>

<mat-accordion #filterSections class="filter-options" multi>

  <mat-expansion-panel #dateSection [expanded]="true">
    <mat-expansion-panel-header class="mdo-mat-panel-header">
      <mat-panel-title>
        Date Range
      </mat-panel-title>
      <mat-panel-description>(Required)</mat-panel-description>
    </mat-expansion-panel-header>

    <mat-form-field appearance="fill" class="mdo-panel-content-top-margin">
      <mat-label>Enter a date range</mat-label>

      <mat-date-range-input [formGroup]="filterInputs" [rangePicker]="picker">
        <input id="start-date-input" matStartDate formControlName="startDate" placeholder="Start date">
        <input id="end-date-input" matEndDate formControlName="endDate" placeholder="End date">
      </mat-date-range-input>

      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

  </mat-expansion-panel>

  <mat-expansion-panel #idSection [disabled]="!isReady" [formGroup]="filterInputs">
    <mat-expansion-panel-header class="mdo-mat-panel-header">
      <mat-panel-title>
        Identifiers
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-form-field class="id-text-field" appearance="fill" class="mdo-panel-content-top-margin">
      <mat-label>Job ID:</mat-label>
      <input matInput id="jobId-input" formControlName="jobId">
    </mat-form-field>

  </mat-expansion-panel>

  <mat-expansion-panel #statusSection [expanded]="isReady" [disabled]="!isReady">
    <mat-expansion-panel-header class="mdo-mat-panel-header">
      <mat-panel-title>
        Job Status
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ul class="mdo-panel-content-top-margin">
      <li *ngFor="let status of statusList" class="mdo-checkbox-set">
        <mat-checkbox [ngClass]="'status_checkbox'" color="primary" [(ngModel)]="status.selected" >{{status.label}}</mat-checkbox>
      </li>
    </ul>
    <app-checkbox-group-controls [checkboxArray]="statusList" class="checkbox-controls"></app-checkbox-group-controls>

  </mat-expansion-panel>

  <mat-expansion-panel #productTypeSection [expanded]="isReady" [disabled]="!isReady" *ngIf="showProductTypes">
    <mat-expansion-panel-header class="mdo-mat-panel-header">
      <mat-panel-title>
        Product Type
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ul class="mdo-panel-content-top-margin">
      <li *ngFor="let productType of productTypeList" class="mdo-checkbox-set">
        <mat-checkbox [ngClass]="'product_checkbox'" [(ngModel)]="productType.selected">{{productType.label}}</mat-checkbox>
      </li>
    </ul>
    <app-checkbox-group-controls [checkboxArray]="productTypeList" class="checkbox-controls"></app-checkbox-group-controls>

  </mat-expansion-panel>

  <mat-expansion-panel #partnerSection [disabled]="!isReady">
    <mat-expansion-panel-header class="mdo-mat-panel-header">
      <mat-panel-title>
        Partner
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ul class="mdo-panel-content-top-margin">
      <li *ngFor="let partner of partnerList" class="mdo-checkbox-set">
        <mat-checkbox [ngClass]="'partner_checkbox'" color="primary" [(ngModel)]="partner.selected">{{partner.label}}</mat-checkbox>
      </li>
    </ul>
    <app-checkbox-group-controls [checkboxArray]="partnerList" class="checkbox-controls"></app-checkbox-group-controls>

  </mat-expansion-panel>

</mat-accordion>
