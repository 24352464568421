
import { map } from 'rxjs/operators';
//Angular Imports 
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

//Third Party Imports
import { Observable, Subscriber } from "rxjs";
import { TestHarnessTestCase } from "../models/testHarness-testCase.model";
import { TestHarnessTestGroup } from "../models/testHarness-testGroup.model";
import { TestHarnessCallback } from '../models/testHarness-testCallback';


/**
  * Policy Forms Provider
*/
@Injectable()
export class TestHarnessService {

  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient) {
  }


  getPackages(metadata: string, env: string, token: string): Observable<any> {
    var gurl = `https://mkl-mgdapi-${env}.azurewebsites.net/api/v2/document/packages`;
    if (env == "LOCAL") {
      gurl = `https://localhost:63161/api/v2/document/packages`;
    }
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', `Bearer ${token}`);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post<any>(gurl, metadata, { headers }).pipe(map(res => res));
  }

  getDocumentInfo(metadata: string, env: string, token: string): Observable<any> {
    var gurl = `https://mkl-mgdapi-${env}.azurewebsites.net/api/v2/document/documentInfo`;
    if (env == "LOCAL") {
      gurl = `http://localhost:63161/api/v2/document/documentInfo`;
    }
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', `Bearer ${token}`);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post<any>(gurl, metadata, { headers }).pipe(map(res => res));
  }

  exportForms(testData: string, isPreview: boolean, env: string, token: string): Observable<any> {
    //If preview populate mock CPM data and call preview
    if (isPreview) {
      //var gurl = 'https://localhost:63161/api/v2/document/preview';
      var gurl = `https://mkl-mgdapi-${env}.azurewebsites.net/api/v2/document/preview`;
      if (env.toLowerCase() == "local") {
        gurl = `https://localhost:63161/api/v2/document/preview`;
      }
      var headers = new HttpHeaders();
      headers = headers.append('Accept', 'application/pdf');
      headers = headers.append('Authorization', `Bearer ${token}`);
      headers = headers.append('Content-Type', 'application/json');
     // var data = `{"Documents":["${formNames}"],"Data":"${testData}","CallingSystem":"GhostPortal","Environment":"${env}","TransactionId":null,"DocumentDomain":null,"WithData":false,"IsDraft":true}`;
      return this.http.post<any>(gurl, testData, { headers }).pipe(map(res => res));
    }
    //Format CPM data before sending to API
    var gurl = `https://mkl-mgdapi-${env}.azurewebsites.net/api/v2/document/mapandassemble`;
    if (env.toLowerCase() == "local") {
      gurl = `https://localhost:63161/api/v2/document/mapandassemble`;
    }
    //var gurl = 'https://localhost:63161/api/v2/document/mapandassemble';
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/pdf');
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    return this.http.post<any>(gurl, testData, { headers }).pipe(map(res => res));
  }

  documentInfo(data: string, env: string, token: string): Observable<any> {
    //Format CPM data before sending to API
    var gurl = `https://mkl-mgdapi-${env}.azurewebsites.net/api/v2/document/info`;
    if (env.toLowerCase() == "local") {
      gurl = `http://localhost:63161/api/v2/document/info`;
    }
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/pdf');
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    return this.http.post<any>(gurl, data, { headers }).pipe(map(res => res));
  }

  getCache(env: string, token: string): Observable<any> {
    var gurl = `https://mkl-mgdapi-${env}.azurewebsites.net/api/v2/cache/info`;
    if (env.toLowerCase() == "local") {
      gurl = `https://localhost:63161/api/v2/cache/info`;
    }
    var headers = new HttpHeaders();
    headers = headers.append('Authorization', `Bearer ${token}`);
    return this.http.get<any>(gurl, { headers }).pipe(map(res => res));
  }

  checkExistingForms(data: string, env: string): Observable<any> {
    var gurl = `https://mkl-mgdapi-${env}.azurewebsites.net/api/v1/cache/templates/check-existing`;
    if (env.toLowerCase() == "local") {
      gurl = `https://localhost:63161/api/v1/cache/templates/check-existing`;
    }
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/pdf');
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post<any>(gurl, data, { headers }).pipe(map(res => res));
  }

  regeneratePolicy(transaction: string, env: string, token: string): Observable<any> {
    env = env.toLowerCase();
    transaction = transaction.toUpperCase();
    var gurl = `http://mgdextension-${env}.test.mkltestna.com/api/policy-pdf/${transaction}`;
   // var gurl = `http://localhost:53023/api/policy-pdf/${transaction}`;
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/pdf');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    return this.http.get(gurl, {headers, responseType:'blob'}).pipe(map(res => res));
  }

  searchLogs(dealNumber: string, env: string, callingSystem: string, token: string): Observable<any> {
      var gurl = `https://mkl-mgdapi-${env}.azurewebsites.net/api/v2//logrequest/getLogs?dealNumber=${dealNumber}&isPortal=1&callingSystem=${callingSystem}`;
      if (env.toLowerCase() == 'local') {
        gurl = `http://localhost:63161/api/v2/logrequest/getLogs?dealNumber=${dealNumber}&isPortal=1&callingSystem=${callingSystem}`;
      }
      var headers = new HttpHeaders();
      headers = headers.append('Accept', 'application/json');
      headers = headers.append('Authorization', `Bearer ${token}`);
      return this.http.get(gurl, {headers, responseType:'json'}).pipe(map(res => res));
  }

  getJwt(env: string) {
    var gurl = `https://mkl-mgdapi-${env.toLowerCase()}.azurewebsites.net/api/v2/document/login?UserName=Specialty&Key=BD5065AF-2A62-4002-B08F-0EB91221FDF1`;
    if (env.toLowerCase() == "local") {
      gurl = `http://localhost:63161/api/v2/document/login?UserName=Specialty&Key=BD5065AF-2A62-4002-B08F-0EB91221FDF1`;
    }
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    return this.http.get(gurl, { headers, responseType: 'text' }).pipe(map(res => res));
  }

  saveTestCase(testCase: TestHarnessTestCase): Observable<any> {
    let url = `api/TestCase/MGD`;
    return this.http.post<any>(url, testCase).pipe(
      map(res => res)
    );
  }

  saveTestGroup(testGroup: TestHarnessTestGroup): Observable<any> {
      let url = `api/TestGroup/MGD`;
      return this.http.post<any>(url, testGroup).pipe(
        map(res => res)
      );
  }

  updateTestCase(testCase: TestHarnessTestCase): Observable<any> {
    let url = `api/TestCase/MGD`;
    return this.http.put<any>(url, testCase).pipe(
      map(res => res)
    );
  }

  updateTestGroup(testGroup: TestHarnessTestGroup): Observable<any> {
    let url = `api/TestGroup/MGD`;
    return this.http.put<any>(url, testGroup).pipe(
      map(res => res)
    );
  }

  deleteTestGroup(id: string): Observable<any> {
    let url = `api/TestGroup/MGD/${id}`;
    return this.http.delete<any>(url).pipe(
      map(res => res)
    );
  }

  deleteTestCase(id: string): Observable<any> {
    let url = `api/TestCase/MGD/${id}`;
    return this.http.delete<any>(url).pipe(
      map(res => res)
    );
  }

  getTestCases() {
    let url = `api/TestCase/MGD`;
    return this.http.get<any[]>(url).pipe(
      map(res => res)
    );
  }
  getTestGroups(): Observable<any[]> {
    let url = `api/TestGroup/MGD`;
    return this.http.get<any[]>(url).pipe(
      map(res => res)
    );
  }

  

  runTestGroup(id: string, env: string): Observable<TestHarnessCallback> { 
    let url = `api/TestGroup/RunMGDGroup/${id}/${env}`;
    return this.http.get<TestHarnessCallback>(url).pipe(map(res => res));
  }

  runSelectedTests(tests: TestHarnessTestCase[], env: string): Observable<TestHarnessCallback> {
    console.log(tests);
    let url = `api/TestGroup/RunMGDTests/${env}`;
    return this.http.post<TestHarnessCallback>(url, tests).pipe(map(res => res));
  }

}

