import { RuleSetup } from ".";
import { RuleSet } from "./ruleSet.model";

export class RuleEngineForm {
  public DocumentType: string;
  public id: string;
  public PackageId: string;
  public AdminFormId: string;

  public InternalFormNumber: string;
  public ExternalFormNumber: string;
  public MultiLOBIndicator: boolean;
  public LOB: string;

  public FormName: string;
  public PreviousVersion: string;
  public PriorInternalFormNumber: string;
  public MultipleAttachment: boolean;
  public FormType: string;
  public SortOrder: string;
  public FrequentlyUsed: boolean;
  public DisplayOnly: string[] = [];
  public PolicyLevel: boolean;
  public EffectiveDate: number;
  public ExpirationDate: number;
  public SubmissionExpirationDate: number;

  public RuleSet: RuleSet;

  public CreationDate: number;
  public CreatedByName: string;
  public ModifiedDate: number;
  public ModifiedByName: string;
  public RiskLevels: string[] = [];
  //Internal used by forms list page
  public Mandatory: boolean;
  public DefaultSelect: boolean;
  //Internal Used by Report page
  public WritingCompanies: string;
  public States: string;
  public IndustryTemplates: string;
  public ImportFromMOL: boolean;
  public MolId: string;
  public MolDeptId: string;
  public MolNumber: string;
  public MolEditionDate: string;
  public MolEffectiveDate: string;

}
