//Angular Imports
import {
  Component,
  HostBinding,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";

//Third Party Imports
import { GridOptions } from "ag-grid-community";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

//Internal Imports
import {
  ApplicationInsightsBaseComponent,
  AppInsightsService,
  DateFilterComponent,
  BooleanFilterComponent,
  DateTimePipe,
  SharedService,
  EnvironmentService,
  SelectableItem,
} from "../../../framework";
import { RuleSetup, AdminForm, RuleSet } from "../../models";
import { FormPageService } from "../../services";

import "rxjs";
import { saveAs } from "file-saver";

//import { RuleEngineResult, SingleFormResult, RuleEngineLog, TestExecution, TestGroup, TestRun } from '../../models';

/**
 * Forms Component
 */
@Component({
  selector: "app-form-page-product-def",
  templateUrl: "./form-page-product-def.html",
  styleUrls: ["./form-page-product-def.scss"],
})
export class FormPageProductDefinition extends ApplicationInsightsBaseComponent {
  @HostBinding("style.width") width: string;

  // Used to populate the Line of Business grid
  lobGridOptions: GridOptions;
  lobSelectableItems: SelectableItem[];

  constructor(
    public activeModal: NgbActiveModal,
    private _appInsightsService: AppInsightsService,
    private toastr: ToastrService,
    private formPageService: FormPageService
  ) {
    super(_appInsightsService);
    this.width = "100%";
    this.lobSelectableItems = [];
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
   */
  ngOnInit(): void {
    this.configureLobGrid();
    this.createSelectableItemLists();
  }

  /**
   * NgOnDestroy
   * @ignore
   */
  ngOnDestroy(): void {}

  ngOnChanges(): void {}

  private createSelectableItemLists(): void {
    // Load LOBs
    this.formPageService.getProducts().subscribe(
      (res) => {
        // Need to clone this because service is returning a cached object and we don't want to modify it
        let items: SelectableItem[] = [];
        for (var i = 0; i < res.length; i++) {
          for (var j = 0; j < res[i].Coverages.length; j++) {
            console.log(res[i]);
            var item = new SelectableItem();
            item.Code = res[i].Name;
            item.VisibleDescription =
              res[i].Coverages[j].ID + ": " + res[i].Coverages[j].Name;
            items.push(item);
          }
        }
        this.lobSelectableItems = items;

        // this.lobSelectableItems = items;
        this.sortSelectableItems(this.lobSelectableItems);

        if (this.lobGridOptions.api) {
          this.lobGridOptions.api.setRowData(this.lobSelectableItems);
          this.lobGridOptions.columnApi.autoSizeAllColumns();
        }
      },
      (error) => {
        this.toastr.error(
          "Error occurred while fetching LOB list.",
          "Form Page"
        );
      }
    );
  }

  private sortSelectableItems(items: SelectableItem[]) {
    items.sort((a, b) => {
      if (a.Selected == b.Selected) {
        if (a.Code < b.Code) {
          return -1;
        } else {
          return 1;
        }
      } else if (a.Selected) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  private configureLobGrid(): void {
    //this.initialRowDataLoad$ = [];
    this.lobGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: "normal",
      columnDefs: this.createLobColumnDef(),
      enableFilter: true,
      enableSorting: true,
      rowHeight: 30,
      enableColResize: true,
      onGridReady: () => {
        this.lobGridOptions.api.setRowData(this.lobSelectableItems);
        this.lobGridOptions.api.sizeColumnsToFit();
        this.lobGridOptions.columnApi.autoSizeAllColumns();
      },
      onRowClicked: (event) => {
        this.lobGridOptions.api.refreshCells();
      },
      onFilterChanged: (event) => {},
      onSortChanged: (event) => {},
      onFilterModified: function (event) {
        //console.log(event);
      },
    };
  }

  /**
   * configure forms grid columns
   */
  private createLobColumnDef(): any[] {
    return [
      {
        headerName: "Product",
        field: "Code",
        width: 200,
        sortable: true,
        resizable: true,
        filter: "agTextColumnFilter",
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
      {
        headerName: "Coverage",
        field: "VisibleDescription",
        resizable: true,
        sortable: true,
        filter: "agTextColumnFilter",
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
    ];
  }
}
