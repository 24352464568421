import { Component, OnInit } from '@angular/core';
import { ImportQueueService } from "../../services/import-queue.service";
import { UserProfileService } from "../../../framework";
import { PartnerProductAudit } from "../../models/partner-product-audit";

@Component({
  selector: 'app-partner-product-audit',
  templateUrl: './partner-product-audit.component.html',
  styleUrls: ['./partner-product-audit.component.scss']
})
export class PartnerProductAuditComponent implements OnInit {

  isLoading: boolean = true;
  showLoadError: boolean = false;
  ppMap: any = {};
  partnerProductArray: any[] = [];
  updatesAuthorized: boolean = false;

  constructor(private queueService: ImportQueueService, private userProfileService: UserProfileService) { }

  ngOnInit(): void {
    this.updatesAuthorized = this.queueService.isAuthorized('uploadJobFiles');
    this.getPartnerProductData();
  }

  initiateChange(id: number): void {
    event.preventDefault();
    event.stopPropagation(); //To not open the panel
    this.ppMap[id].needsConfirmation = true;
    this.ppMap[id].updateErrorMsg = ""; // Clear any previous error
  }

  cancelChange(id: number): void {
    event.preventDefault();
    event.stopPropagation(); 
    this.ppMap[id].needsConfirmation = false;
  }

  confirmChange(id: number): void {
    event.preventDefault();
    event.stopPropagation();
    this.ppMap[id].isProcessing = true;
    this.ppMap[id].needsConfirmation = false;
    this.ppMap[id].updateErrorMsg = "";
    this.queueService.updatePartnerProduct(this.ppMap[id], this.userProfileService.UserName)
      .subscribe(
        // Handle successful retrieval 
        (response: any) => {
          this.ppMap[id].isProcessing = false;
        },
        // Handle an error response
        error => {
          this.ppMap[id].updateErrorMsg = "Update failed"
          this.ppMap[id].isProcessing = false; 
        }); 
  }


  getPartnerProductData(): void {
    this.queueService.getPartnerProductAuditData()
      .subscribe(
        // Handle successful retrieval 
        (response: any) => {
          this.parseAuditData(response);
          this.isLoading = false;
        },
        // Handle an error response
        error => {
          this.isLoading = false;
          this.showLoadError = true;
        });    
  }

  parseAuditData(response: any): void {
    this.ppMap = {};
    for (let r in response) {
      if (this.ppMap[response[r].partnerProductID] === undefined) {
        this.ppMap[response[r].partnerProductID] = new PartnerProductAudit(response[r]);
      }
      this.ppMap[response[r].partnerProductID].addHistory(response[r]);
    }

    for (let item in this.ppMap) {
      this.ppMap[item].organizeHistory();
      this.partnerProductArray.push(this.ppMap[item]);
    }

    this.partnerProductArray.sort((a, b) => {

      if (a.fullname < b.fullname) {
        return -1;
      }
      if (a.fullname > b.fullname) {
        return 1;
      }

      return 0;

    });
  }

  retryDataRetrieval(): void {
    event.preventDefault();
    this.isLoading = true;
    this.showLoadError = false;
    this.getPartnerProductData();
  }

}
