//Angular Imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

//Third Party Imports
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridModule } from "ag-grid-angular";

//Internal Imports
import { DisplayNotesComponent, AddNoteComponent } from './components';
import { NoteService } from './services';
import { FrameworkModule, DateFilterComponent, BooleanFilterComponent } from '../framework';

/**
  Forms Module
*/
@NgModule({
    declarations: [DisplayNotesComponent, AddNoteComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NgbModule,
        NgSelectModule,
        FrameworkModule,
        AgGridModule.withComponents([DateFilterComponent, BooleanFilterComponent])
    ],
    exports: [DisplayNotesComponent, AddNoteComponent]
})
export class UserNotesModule { }
