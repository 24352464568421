import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular'
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-detail-trigger',
  template: `
    <span>
      <a href="" (click)="showDetail($event)">{{ rowData["uuid"] }}</a>
    </span>
    <app-copy-cell-value [cellValue]="rowData['uuid']"></app-copy-cell-value>
  `
})

/*
 This component extends a component class provided by AG-Grid designed to render content to display
 in the cells of a particular column.
*/
export class DetailTriggerComponent implements AgRendererComponent {

  rowData: any = {};
  params: any;

  /*
   This method is required based on the AgRendererComponent interface.  It gets called once before
   the renderer is used.
  */
  agInit(params: ICellRendererParams): void {
    this.setRowData(params)
    this.params = params;
  }

  /*
   This method is required based on the AgRendererComponent interface.  It gets gets called whenever
   the user gets the cell to refresh.
  */
  refresh(params: ICellRendererParams): boolean {
    // Set values again
    this.setRowData(params)
    return true;
  }

  setRowData(params: ICellRendererParams) {
    this.rowData = {
      id: params.data.orchestrationRequestId,
      uuid: params.data.orchestrationUUID,
      status: params.data.requestStatus,
      processDate: params.data.lastupdated,
      sourceSystem: params.data.SourceSystem
    }
  }

  showDetail(event: any) {
    // Prevents the hyperlink from doing normal hyperlink navigation
    event.preventDefault()  
    // To prevent the row selection event from taking place and inadvertently toggling the selection checkbox
    event.stopPropagation();

    this.params.context.componentParent.addTab(
      this.rowData
    );
  }

}
