import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable} from 'rxjs';
import { map, take, first } from 'rxjs/operators';

import { AuthorizationService } from '../services';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
    private authService: AuthorizationService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    console.log("CanActivate - Next Path: " + next.routeConfig.path);
    if (state.url.indexOf('fast') != -1 && this.authService.ghostPortalReaderOnly()) {
        window.alert("You do not have permission to view this page.");
        this.router.navigate(['/splash']);
        return false;
    }
    switch (next.routeConfig.path) {
      case "testHarness": {
        if (!this.authService.MarkelUser()) {
          return true;
        }
        else {
          window.alert("You do not have permission to view this page.");
          this.router.navigate(['/forms']);
          return false;
        }
      }
      case "deployUtility": {
        if (this.authService.PackageDeployAny()) {
          return true;
        }
        else {
          window.alert("You do not have permission to view this page.");
          this.router.navigate(['/forms']);
          return false;
        }
      }
      case "queue": {
        if (this.authService.PackageDeployAny()) {
          return true;
        }
        else {
          window.alert("You do not have permission to view this page.");
          this.router.navigate(['/forms']);
          return false;
        }
      }
      case "imageRight": {
        if (this.authService.PackageDeployAny()) {
          return true;
        }
        else {
          window.alert("You do not have permission to view this page.");
          this.router.navigate(['/forms']);
          return false;
        }
      }
      case "fast": {
        if (this.authService.MarkelUser() || (!this.authService.HasFastRole())) {
          window.alert("You do not have permission to view this page.");
          this.router.navigate(['/splash']);
          return false;
        }
        else {
          return true;
        }
      }
      case "forms": {
        return true;
      }
      case "rules": {
        return true;
      }
      case "email": {
        return true;
      }
      case "mapping": {
        return true;
      }
      case "package": {
        if (this.authService.PackageCreate()) {
          return true;
        }
        else {
          window.alert("You do not have permission to view this page.");
          this.router.navigate(['/splash']);
          return false;
        }
      }
      case "deploy": {
        return true;
      }
      case "execution": {
        return true;
      }
      case "printOrder": {
          return true;
      }
      case "cpm": {
          return true;
      }
      case "formBuilder": {
          return true;
      }
      case "ruleOperations": {
        return true;
      }
      case "mdo": {
        return true;
      }
      case "import-queue": {
        return true;
      }
      case "data-management": {
        return true;
      }
      case "insured-identity": {
        return true;
      }
      case "ams": {
        return true;
      }
      case "mdocument": {
        return true;
      }
      case "orchestration-queue": {
        return true;
      }
      case "diagnostics": {
        return true;
      }
      case "main": {
        return true;
      }
      case "compare": {
        return true;
      }
      case "convert": {
        return true;
      }
      case "reports": {
        return true;
      }
      case "oden": {
        return true;
      }
      case "data": {
        return true;
      }
      case "default": {
        return true;
      }
      case "documentMigration": {
        return true;
      }
      case "splash": {
        if (this.authService.isIC() && !this.authService.HasFastRole()) {
          this.router.navigate(['/ghostPortal/forms']);
          return false;
        }
        return true;
      }
      default: {
        return false;
      }
    }

  }
}
