//Angular Imports 
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

//Third Party Imports
import { Observable, Subscriber } from "rxjs";
import { map } from "rxjs/operators";
import { FormNumber } from "../models/formNumber.model";
import { PortalRule } from "../models/portalRule";
import { SystemRules } from "../models/systemRules";


/**
  * Policy Forms Provider
*/
@Injectable()
export class RulesService {

  private masterData: any;
  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient) {
  }

  getAllFormNumbers(): Observable<FormNumber[]> {
      let url = 'api/AdminForm/FormNumbers';
      return this.http.get<FormNumber[]>(url).pipe(map(res => {
        return res;
      }));
  }

  getPortalRules(): Observable<SystemRules[]> {
    let url = 'api/Configs/GetPortalRules';
    return this.http.get<SystemRules[]>(url).pipe(map(res => {
      return res;
    }));
  }

  updatePortalRule(rule: SystemRules): Observable<any> {
    let url = 'api/Configs/UpdatePortalRule';
    return this.http.post<SystemRules>(url, rule).pipe(map(res => {
      return res;
    }));
  }

}
