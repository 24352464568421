<div class="component-container" style="margin-bottom: 0">
  <div class="component-heading-container">
    <h3 class="component-heading-text">Add/Edit Product definition</h3>
  </div>
  <div class="modal-body col-12" style="padding: 10px 0 0 10px">
    <div
      class="col-md-12 mrgbtm10"
      style="padding: 0"
      *ngIf="this.Action == 'New'"
    >
      <label style="padding-right: 10px"><b>Product Name: </b></label>
      <input
        id="txtPublishComment"
        placeholder="Name"
        [(ngModel)]="newProductName"
      />
    </div>
    <!-- LOBs -->
    <div class="row">
      <div id="lob-grid-container" class="col-12" style="padding-bottom: 10px">
        <div style="padding-bottom: 5px"><b>Assign LOBs to Product </b></div>
        <ag-grid-angular
          class="ag-theme-balham fast_panel_table"
          [gridOptions]="formsGridOptions"
        >
        </ag-grid-angular>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="mat_btn_hov_act mrgrgt10"
      mat-raised-button
      (click)="activeModal.close()"
    >
      Cancel
    </button>
    <button
      type="button"
      mat-raised-button
      class="btn-teal"
      (click)="saveSetup()"
    >
      Save
    </button>
  </div>
</div>
