//Angular Imports 
import { Component, OnInit, Input } from '@angular/core';

//Third Party Imports
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

//Internal Imports 
import { TestingService } from '../../services';
import { RuleEngineResult, RuleEngineLog, TestCase } from '../../models';
import { coverageCpmElementId, lobCpmElementId } from '../../configs/execution-engine.config';
import { SharedService } from '../../../../../framework';
import { PolicyFormsService, PolicyForm } from '../../../../../policy-forms';

import 'rxjs';
import { FormTestCase } from '../../models';
import { DatePipe } from '@angular/common';
import * as xml2js from "xml2js";


/**
 * Forms Component
*/
@Component({
  selector: 'app-bulk-update-tests',
  templateUrl: './bulk-update-tests.component.html',
  styleUrls: ['./bulk-update-tests.component.scss']
})

export class BulkUpdateTests {

  @Input() public testCases: TestCase[];

  public selectedDateString: string;
  public selectedDate: Date;


  /** 
   * Constructor
   * @ignore
  */
  constructor(public activeModal: NgbActiveModal,
    private testingService: TestingService,
    private _modal: NgbModal,
    private toastr: ToastrService,
    private sharedService: SharedService,
    private policyFormsService: PolicyFormsService,
    private datePipe: DatePipe) {

  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
  */
  ngOnInit(): void {
    // TODO: PB - refactor this to pull only the LOB data

  }

  /**
   * NgOnDestroy
   * @ignore
  */
  ngOnDestroy(): void {

  }

  updateTests(): void {
    if (this.selectedDate != null) {
      try {
        this.selectedDateString = this.datePipe.transform(this.selectedDate, 'yyyy-MM-dd' + 'T:00:00:00');
        var unixDate = Math.floor(new Date(this.selectedDate).getTime() / 1000);

        console.log(this.selectedDateString);
        for (var i = 0; i < this.testCases.length; i++) {
          var tempCase = new TestCase();
          console.log(this.testCases[i]);
          this.testingService.getTestCaseById(this.testCases[i].Id).subscribe(res => {
            console.log(res);
            tempCase = res;
            console.log(tempCase);
            if (tempCase.CPM.startsWith("{")) {
              let jsonObject = JSON.parse(tempCase.CPM);


              if (jsonObject.QuoteMessage == null) {
                // Modify the PolicyEffectiveDate value
                jsonObject.PolicyMessage.Policy.PolicyEffectiveDate = this.selectedDateString;
                // Stringify the object back to a JSON string
                let updatedJsonString = JSON.stringify(jsonObject, null, 4);
                tempCase.CPM = updatedJsonString;
                tempCase.PolicyEffectiveDate = unixDate;
              }
              else {
                jsonObject.QuoteMessage.QuoteOptions.QuoteOption[0].Policy.PolicyEffectiveDate = this.selectedDateString;
                let updatedJsonString = JSON.stringify(jsonObject, null, 4);
                tempCase.CPM = updatedJsonString;
                tempCase.PolicyEffectiveDate = unixDate;
              }
            }
            else {
              var tempDateString = this.selectedDateString
              var parser = new xml2js.Parser();
              var builder = new xml2js.Builder();
              parser.parseString(tempCase.CPM, function (err, result) {

                if (result.QuoteMessage != null) {
                  result.QuoteMessage.Quote[0].QuoteOptions[0].QuoteOption[0]
                    .Policy[0].PolicyEffectiveDate = this.selectedDateString;
                } else {
                  result.PolicyMessage.Policy[0].PolicyEffectiveDate = tempDateString;
                }
                const updatedXML = builder.buildObject(result);

                // Update tempCase.CPM with the new XML string
                tempCase.CPM = updatedXML;
                tempCase.PolicyEffectiveDate = unixDate;
              });
            }
            console.log(tempCase);
            this.testingService.updateTestCase(tempCase).subscribe(res => {
              console.log(res);
            });
          })


        }
        this.toastr.success("Tests Updated Successfully", "Completed");
        this.activeModal.close();
      }
      catch (e) {
        this.toastr.error(e, "Error Updating Tests");
      }
    }
    

  }


}
