//Angular Imports 
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

//Third Party Imports
import { Observable, Subscriber } from "rxjs";
import { map } from "rxjs/operators";

//Internal Imports
import { CPMElement } from "../../framework/models/cpm-element.model";
import { Dictionary, List } from "lodash";

/**
  * Rules Provider
*/
@Injectable()
export class CPMService {

  constructor(private http: HttpClient) {
  }


  /**
   * Method to Get CPM Element master data
   * 
   */
  getAllCpmElements(): Observable<CPMElement[]> {

    let url = `api/CPM/CpmElements`;
    return this.http.get<any>(url).pipe(map(res => {
      return res;
    })
    );
  }

  addCPMElement(ruleType: CPMElement): Observable<CPMElement> {
    return this.http.post<CPMElement>("api/CPM", ruleType).pipe(
      map(res => res));
  }

  updateCPMElement(ruleType: CPMElement): Observable<CPMElement> {
    return this.http.put<CPMElement>("api/CPM", ruleType).pipe(
      map(res => res));
  }
}
