import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AgGridModule } from "ag-grid-angular";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MdoImportQueueRoutingModule } from './mdo-import-queue-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AgChartsAngularModule } from 'ag-charts-angular';

import { MaterialComponentModule } from "./material.module";

import { MasterViewComponent } from './components/master-view/master-view.component';
import { RequestFilterComponent } from './components/request-filter/request-filter.component';
import { DetailTriggerComponent } from './components/detail-trigger/detail-trigger.component';
import { JobIdCellComponent } from './components/job-id-cell/job-id-cell.component';
import { TransactionIdCellComponent } from './components/transaction-id-cell/transaction-id-cell.component';
import { LogMessageCellComponent } from './components/log-message-cell/log-message-cell.component';
import { CopyCellValueComponent } from './components/copy-cell-value/copy-cell-value.component';

import { DetailTabComponent } from './components/detail-tab/detail-tab.component';

import { ImportQueueService } from "./services/import-queue.service";
import { MockDataService } from "./services/mock-data.service";

import { UpdateTransactionsComponent } from './components/update-transactions/update-transactions.component';
import { UpdateTransactionMenuComponent } from './components/update-transaction-menu/update-transaction-menu.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HealthMeterComponent } from './components/health-meter/health-meter.component';
import { ChartDisplayComponent } from './components/chart-display/chart-display.component';
import { PartnerStatisticsComponent } from './components/partner-statistics/partner-statistics.component';
import { FooterCellComponent } from './components/footer-cell/footer-cell.component';
import { ReportsComponent } from './components/reports/reports.component';
import { CheckboxGroupControlsComponent } from './components/checkbox-group-controls/checkbox-group-controls.component';
import { JobQueueComponent } from './components/job-queue/job-queue.component';
import { JobRequestFilterComponent } from './components/job-request-filter/job-request-filter.component';
import { DownloadItemCellComponent } from './components/download-item-cell/download-item-cell.component';
import { DownloadJobCellComponent } from './components/download-job-cell/download-job-cell.component';
import { UpdateJobsMenuComponent } from './components/update-jobs-menu/update-jobs-menu.component';
import { JobDetailTabComponent } from './components/job-detail-tab/job-detail-tab.component';
import { JobDetailTriggerComponent } from './components/job-detail-trigger/job-detail-trigger.component';
import { UpdateJobsComponent } from './components/update-jobs/update-jobs.component';
import { UploadFilesComponent } from './components/upload-files/upload-files.component';
import { EntityLogSearchComponent } from './components/entity-log-search/entity-log-search.component';
import { EntityLogDetailTriggerComponent } from './components/entity-log-detail-trigger/entity-log-detail-trigger.component';
import { EntityLogDetailTabComponent } from './components/entity-log-detail-tab/entity-log-detail-tab.component';
import { StagingFileQueueComponent } from './components/staging-file-queue/staging-file-queue.component';
import { StagingFileRequestFilterComponent } from './components/staging-file-request-filter/staging-file-request-filter.component';
import { StagingFileDetailTriggerComponent } from './components/staging-file-detail-trigger/staging-file-detail-trigger.component';
import { FilenameCellComponent } from './components/filename-cell/filename-cell.component';
import { StagingFileDetailTabComponent } from './components/staging-file-detail-tab/staging-file-detail-tab.component';
import { UpdateStagingFileMenuComponent } from './components/update-staging-file-menu/update-staging-file-menu.component';
import { UpdateStagingFilesComponent } from './components/update-staging-files/update-staging-files.component';
import { DownloadStagingFileCellComponent } from './components/download-staging-file-cell/download-staging-file-cell.component';
import { PartnerProductAuditComponent } from './components/partner-product-audit/partner-product-audit.component';






@NgModule({
    providers: [
        ImportQueueService,
        MockDataService
    ],
    declarations: [
        MasterViewComponent,
        RequestFilterComponent,
        DetailTriggerComponent,
        DetailTabComponent,
        JobIdCellComponent,
        TransactionIdCellComponent,
        LogMessageCellComponent,
        CopyCellValueComponent,
        UpdateTransactionsComponent,
        UpdateTransactionMenuComponent,
        DashboardComponent,
        HealthMeterComponent,
        ChartDisplayComponent,
        PartnerStatisticsComponent,
        FooterCellComponent,
        ReportsComponent,
        CheckboxGroupControlsComponent,
        JobQueueComponent,
        JobRequestFilterComponent,
        DownloadItemCellComponent,
        DownloadJobCellComponent,
        UpdateJobsMenuComponent,
        JobDetailTabComponent,
        JobDetailTriggerComponent,
        UpdateJobsComponent,
        UploadFilesComponent,
        EntityLogSearchComponent,
        EntityLogDetailTriggerComponent,
        EntityLogDetailTabComponent,
        StagingFileQueueComponent,
        StagingFileRequestFilterComponent,
        StagingFileDetailTriggerComponent,
        FilenameCellComponent,
        StagingFileDetailTabComponent,
        UpdateStagingFileMenuComponent,
        UpdateStagingFilesComponent,
        DownloadStagingFileCellComponent,
        PartnerProductAuditComponent
    ],
    imports: [
        CommonModule,
        MdoImportQueueRoutingModule,
        AgGridModule,
        AgChartsAngularModule,
        NgbModule,
        MaterialComponentModule,
        FormsModule,
        ReactiveFormsModule
    ]
})
export class MdoImportQueueModule { }
