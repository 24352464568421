
export class SelectablePackage {
  public PackageId: string;
  public CreatedDate: number;
  public CreatedBy: string;
  public PackageName: string;
  public LOB: string;
  public Release: string;
  public Selected: boolean;
  public DeployedToQA1: string;
  public DeployedToUAT1: string;
  public DeployedToQA2: string;
  public DeployedToUAT2: string;
  public DeployedToFIT: string;
  public DeployedToPROD: string;
}
