//Angular Imports 
import { Component, HostBinding, Input } from '@angular/core';

//Third Party Imports 
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

//Internal Imports 
import {
  ApplicationInsightsBaseComponent, AppInsightsService, DateFilterComponent, BooleanFilterComponent,
  DateTimeUtcPipe, SharedService, EnvironmentService
} from '../../../framework';
import { FormHistory, AdminForm } from '../../models';
import { DeployService } from '../../../deploy/services';
import { FormPageService } from '../../services';
//import { ExecutionService, TestingService } from '../../services';


import 'rxjs';
import { saveAs } from 'file-saver';

//import { RuleEngineResult, SingleFormResult, RuleEngineLog, TestExecution, TestGroup, TestRun } from '../../models';



/**
 * Forms Component
*/
@Component({
  selector: 'app-form-page-history-tab',
  templateUrl: './form-page-history-tab.component.html',
  styleUrls: ['./form-page-history-tab.component.scss'],
  providers: [DeployService]
})

export class FormPageHistoryTabComponent extends ApplicationInsightsBaseComponent {

  @HostBinding('style.width') width: string;

  @Input() formHistory: FormHistory;

  @Input() public currentForm: AdminForm;

  formHistoryGridOptions: GridOptions;

  formsGridOptions: GridOptions;

  /** 
   * Constructor
   * @ignore
  */
  constructor(private _appInsightsService: AppInsightsService, private dateTimePipe: DateTimeUtcPipe, private deployService: DeployService, private formPageService: FormPageService) {
    super(_appInsightsService);
    this.width = "100%";
  }


  ngOnInit(): void {
    // this.currentRuleSetup = this.currentForm.RuleSetups.find(x => x.Name === "Default");
    this.configureGrid();
    this.configureFormsGrid();

    //console.log("FormHistory: " + this.formHistory);
  }

  ngOnDestroy(): void {

  }

  ngOnChanges(): void {
    //console.log("FormHistory: " + this.formHistory);
    /*if (this.formHistoryGridOptions) {
      if (this.formHistory) {
        this.formHistoryGridOptions.api.setRowData(this.formHistory.DeploymentHistory);
      } else {
        this.formHistoryGridOptions.api.setRowData([]);
      }
    }   */
    this.formPageService.getFormHistory(this.currentForm.id).subscribe(res => {
          this.formHistory.DeploymentHistory = res;
          this.formHistoryGridOptions.api.setRowData(this.formHistory.DeploymentHistory);
        });
  }

  private onRowClicked(e: any): void {

    this.deployService.adminGetPackageForms(e.data.PackageId, e.data.LOB).subscribe(result => {
      console.log(result);
      if (this.formsGridOptions) {
        //Reset all the filters
        this.formsGridOptions.api.setFilterModel(null);

        //Reset all the sorts 
        this.formsGridOptions.columnApi.resetColumnState();

        //Set the grid row data 
        this.formsGridOptions.api.setRowData(result);

      }
    });

  }

  private configureGrid(): void {
    this.formHistoryGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: 'autoHeight',
      columnDefs: this.createColumDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      enableColResize: true,
      paginationPageSize: 20,
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent
      },
      onGridReady: () => {
        this.formHistoryGridOptions.api.setRowData(this.formHistory.DeploymentHistory);
        this.formHistoryGridOptions.api.sizeColumnsToFit();
        // this.gridOptions.api.softRefreshView();
      },
      onRowClicked: (event) => {
        this.onRowClicked(event);
      },
      onFilterChanged: (event) => {
        //Api call to get the filter data
        //console.log(event);

      },
      onSortChanged: (event) => {

      },
      onFilterModified: function (event) {
        //console.log(event);
      }
    };

  }

  private configureFormsGrid(): void {
    this.formsGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: 'autoHeight',
      columnDefs: this.createFormsColumDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      enableColResize: true,
      paginationPageSize: 20,
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent
      },
      onGridReady: () => {
        this.formsGridOptions.api.setRowData([]);
        this.formsGridOptions.api.sizeColumnsToFit();
        // this.gridOptions.api.softRefreshView();
      },
      onRowClicked: (event) => {
        //this.onRowClicked(event);
      },
      onFilterChanged: (event) => {
        //Api call to get the filter data
        //console.log(event);

      },
      onSortChanged: (event) => {

      },
      onFilterModified: function (event) {
        //console.log(event);
      }
    };

  }


  /**
   * Configure Columns
   */
  private createColumDef(): any[] {
    let result: any[] = [
      {
        headerName: 'Deploy Date', field: 'DeployDate', filter: 'dateFilterComponent', sortable: true, cellRenderer: params => {
          return this.dateTimePipe.transform(params.value);
        }
      },
      { headerName: 'Package Name', field: 'PackageName', filter: 'agTextColumnFilter', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'LOB', field: 'LOB', filter: 'agTextColumnFilter', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'Package Description', field: 'PackageDescription', filter: 'agTextColumnFilter', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'Release Tag', field: 'ReleaseTag', filter: 'agTextColumnFilter', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'Environment', field: 'Environment', filter: 'agTextColumnFilter', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'Published By', field: 'PublishedBy', filter: 'agTextColumnFilter', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'Deployed By', field: 'DeployedBy', filter: 'agTextColumnFilter', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } }
    ]

    return result;
  }

  private createFormsColumDef(): any[] {
    let columns = [
      {
        headerName: 'Form Number', field: 'InternalFormNumber', filter: 'agTextColumnFilter'
      },
      { headerName: 'Form Name', field: 'FormName', filter: 'agTextColumnFilter' },
      { headerName: 'LOB', field: 'LOB', filter: 'agTextColumnFilter' },
      {
        headerName: 'Effective Date', field: 'EffectiveDate', sortable: true,filter: 'dateFilterComponent', sort: 'desc', cellRenderer: params => {
          return this.dateTimePipe.transform(params.value, "string", "MM/DD/YYYY");}
          },
      {
        headerName: 'Expiration Date', field: 'ExpirationDate', sortable: true,filter: 'dateFilterComponent', sort: 'desc', cellRenderer: params => {
          return this.dateTimePipe.transform(params.value, "string", "MM/DD/YYYY");}
          },
      {
        headerName: 'Submission Expiration Date', field: 'SubmissionExpirationDate', sortable: true,filter: 'dateFilterComponent', sort: 'desc', cellRenderer: params => {
          return this.dateTimePipe.transform(params.value, "string", "MM/DD/YYYY");}
          },
      {
        headerName: 'Modified Date', field: 'ModifiedDate', filter: 'dateFilterComponent', sortable: true,sort: 'desc', cellRenderer: params => {
          return this.dateTimePipe.transform(params.value, "string", "MM/DD/YYYY h:mm A");
        }
      },
      { headerName: 'Modified By', field: 'ModifiedByName', filter: 'agTextColumnFilter' },
    ]

    return columns;
  }
}
