import { Component, OnInit, Input } from '@angular/core';
import { QueueService } from "../../services/queue.service";
import { ColDef } from 'ag-grid-community';
import { prettyPrintJson } from "pretty-print-json";
import * as moment from 'moment';

@Component({
  selector: 'app-detail-tab',
  templateUrl: './detail-tab.component.html',
  styleUrls: ['./detail-tab.component.scss']
})
export class DetailTabComponent implements OnInit {

  @Input() rowData: any;
  isLoading: boolean = true;
  isReady: boolean = false;
  details: any = {};
  basicDetails: any = {};
  columnDefs: ColDef[];
  pagination: true;
  paginationAutoPageSize: 10;
  prettyResponseJSON: any = "";
  rawResponse: string = "";
  isSuccess: boolean = false;
  resetMessages: any;
  isFailure: boolean = false;
  requestPayload: any;
  showTableError: boolean = false;
  downloadStatus: string = "";
  callbackResponseStatus: string = "Unavailable";

  constructor(private queueService: QueueService) {
    this.columnDefs = [
      {
        field: 'lastUpdated', headerName: "Last Updated", sortable: true, filter: true, resizable: true,
        cellRenderer: params => {
          return this.queueService.formatTimestamp(params.value)
        }
      },
      {
        field: 'dateCreated', headerName: "Date Created", sortable: true, filter: true,
        cellRenderer: params => {
          return this.queueService.formatTimestamp(params.value)
        }
      },
      { field: 'message', headerName: "Message", sortable: true, filter: true, resizable: true },
      { field: 'type', headerName: "Type", sortable: true, filter: true, resizable: true },
      { field: 'entityId', headerName: "Entity ID", sortable: true, filter: true },
      { field: 'entityType', headerName: "Entity Type", sortable: true, filter: true, resizable: true },
      { field: 'httpResponseId', headerName: "HttpResponse ID", sortable: true, filter: true, resizable: true },
      { field: 'logId', headerName: "Log ID", sortable: true, filter: true, resizable: true },
      { field: 'errorLogId', headerName: "ErrorLog ID", sortable: true, filter: true, resizable: true }
    ];
  }

  ngOnInit(): void {
    this.basicDetails = {
      status: this.rowData["status"],
      id: this.rowData["id"],
      uuid: this.rowData["uuid"],
      requestPayloadAvailable: this.rowData["status"] == "Success" ? "Unavailable" : "Available"
    };
    this.getTransactionDetails(this.rowData["uuid"]);
  }

  getTransactionDetails(uuid: string) {
    this.queueService.getTransactionDetails( uuid ).subscribe(
      res => {
        this.details = res;
        this.callbackResponseStatus = this.details.responsePayload.toString().length ? "Available" : "Unavailable";
        if (this.callbackResponseStatus == "Available") {
          this.prepareResponsePayloadForDisplay(this.details.responsePayload);
        }
        this.isReady = true;
        this.isLoading = false;
      },
      error => {
        this.isReady = false;
        this.showTableError = true;
        this.isLoading = false;
      }
    );
  }

  retryTransactionDetails( uuid: string): void {
    event.preventDefault();
    this.getTransactionDetails( uuid );
  }

  prepareResponsePayloadForDisplay(jsonData: any) {
    // Uses the pretty-print-json NPM module/library to create a nice-readable view of JSON content
    this.prettyResponseJSON = prettyPrintJson.toHtml(jsonData);
    this.rawResponse = JSON.stringify(jsonData);
  }

  resetStatus(recordId: number): void {
    let request = [];
    request.push(recordId);
    this.queueService.resetStatus(request).subscribe(
      res => {
        this.resetMessages = res
        this.isSuccess = true
      },
      error => {
        this.isFailure = true;
      }
    );

  }

  dateFormatter(params) {
    return moment(params.value).format('MM/DD/YYYY HH:mm');
  }

  downloadpayload(uuid: any): void {
    let fileName = "requestpayload-" + uuid + ".json";
    this.downloadStatus = "inProgress";
    this.queueService.getRequestPayload(uuid).subscribe(
      response => {
        this.requestPayload = response;
        this.requestPayload = JSON.stringify(this.requestPayload, null, 2);
        let blobObj = new Blob([this.requestPayload], { type: "application/json" });
        let a = document.createElement('a');
        a.download = fileName;
        let URL = window.URL.createObjectURL(blobObj);
        a.href = URL;
        a.click();
        window.URL.revokeObjectURL(URL);
        this.downloadStatus = "success";
      },
      error => {
        this.downloadStatus = "error";
      }
    );
  }

  isAuthorized(): boolean {
    return this.queueService.isAuthorized('resetRequest');
  }

}
