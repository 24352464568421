
<div class="component-content-view">
  <div class="row" style="width: 100%">
    <div class="col-md-12">
      <div>
        <label style="font-weight:bold" class="label_heading float_none">Notes History:</label>
        <ag-grid-angular class="ag-theme-balham fast_panel_table"
                         [gridOptions]="formHistoryGridOptions">
        </ag-grid-angular>
      </div>
    </div>
  </div>
</div>



