import { PartnerProductAudit } from "./partner-product-audit";

export class PartnerProductUpdatePayload {
  PartnerProductID: number;
  Key: string;
  Value: string;
  UpdatedBy: string;

  constructor(pp: PartnerProductAudit) {
    this.PartnerProductID = parseInt(pp.id);
  }
}
