<div class="container-fluid">
  <div class="row align-items-center justify-content-center loading-row" *ngIf="isLoading">
    <div class="col-md-4">
      <div>
        <mat-progress-spinner mode="indeterminate" diameter="60" class="mx-auto">
        </mat-progress-spinner>
      </div>
    </div>
  </div>

  <div class="row align-items-start" *ngIf="!isLoading && !isReady">
    <p id="job-load-error-alert" class="col-md-12 alert mdo-alert-danger error-bar">There was an error with retrieving the job details.  Click <a class="mdo-alert-danger-link" href="" (click)="retryJobDetails(idBundle.jobId)">here</a> to re-request the details.</p>
  </div>

  <div class="row align-items-start" *ngIf="!isLoading && isReady">
    <div class="col-md-12">
      <br />
      <mat-accordion class="detail-blocks" multi>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header class="mdo-mat-panel-header">
            <mat-panel-title>
              <h5>Job Information</h5>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">

            <div class="col-md-4">
              <mat-list>
                <mat-list-item>
                  <p matLine class="detail-label">Job ID</p>
                  <p matLine>{{details.jobId}}</p>
                </mat-list-item>
                <mat-list-item>
                  <p matLine class="detail-label">Partner</p>
                  <p matLine>{{details.partner}}</p>
                </mat-list-item>
                <mat-list-item>
                  <app-update-jobs-menu id="detail-action-btn"
                                        [singleJob]="true"
                                        [selectedJobCount]="1"
                                        [context]="'detail'"
                                        (triggerJobUpdateModal)="openUpdateModal($event)">
                  </app-update-jobs-menu>
                </mat-list-item>
              </mat-list>
            </div>

            <div class="col-md-3">
              <mat-list>
                <mat-list-item>
                  <p matLine class="detail-label">Product</p>
                  <p matLine>{{details.product}}</p>
                </mat-list-item>
                <mat-list-item>
                  <p matLine class="detail-label">Product Type</p>
                  <p matLine>{{details.productType}}</p>
                </mat-list-item>
                <mat-list-item>
                  <a href="" class="mdo-standard-link" (click)="downloadFiles($event)">Download File(s)</a>
                </mat-list-item>
              </mat-list>
            </div>

            <div class="col-md-3">
              <mat-list>
                <mat-list-item>
                  <p matLine class="detail-label">Start Time</p>
                  <p matLine>{{details.startTime}}</p>
                </mat-list-item>
                <mat-list-item>
                  <p matLine class="detail-label">End Time</p>
                  <p matLine>{{details.endTime}}</p>
                </mat-list-item>
              </mat-list>
            </div>

            <div class="col-md-2">
              <mat-list>
                <mat-list-item>
                  <p matLine class="detail-label">Status</p>
                  <p matLine>{{details.status}}</p>
                </mat-list-item>
              </mat-list>
            </div>

          </div>

          <div class="msg-container">
            <p class="alert mdo-alert-success" *ngIf="showDownloadMsg && wasDownloadSuccessful">
              Download succeeded.
            </p>
            <p class="alert mdo-alert-danger" *ngIf="showDownloadMsg && !wasDownloadSuccessful">
              Download failed - please try again.
            </p>
          </div>

        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true" (opened)="resizeGrid('processLogs')">
          <mat-expansion-panel-header class="mdo-mat-panel-header">
            <mat-panel-title>
              <h5>History</h5>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ag-grid-angular id="processLogsGrid"
                           enableCellTextSelection="true"
                           style="width: 95%; height: 300px;"
                           class="ag-theme-balham mdo-standard-grid mdo-panel-content-top-margin"
                           [frameworkComponents]="frameworkComponents"
                           [rowData]="details.processLogs"
                           [columnDefs]="processLogsColumnDefs"
                           [pagination]="pagination"
                           [paginationAutoPageSize]="paginationAutoPageSize"
                           [gridOptions]="processLogsGridOptions">
          </ag-grid-angular>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true" (opened)="resizeGrid('actionHistory')">
          <mat-expansion-panel-header class="mdo-mat-panel-header">
            <mat-panel-title>
              <h5>
                Notes
              </h5>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ag-grid-angular id="actionHistoryGrid"
                           enableCellTextSelection="true"
                           style="width: 95%; height: 300px;"
                           class="ag-theme-balham mdo-standard-grid mdo-panel-content-top-margin"
                           (gridReady)="onActionHistoryGridReady($event)"
                           [frameworkComponents]="frameworkComponents"
                           [rowData]="details.actionHistory"
                           [columnDefs]="actionHistoryColumnDefs"
                           [pagination]="pagination"
                           [paginationAutoPageSize]="paginationAutoPageSize"
                           [gridOptions]="actionHistoryGridOptions">
          </ag-grid-angular>
        </mat-expansion-panel>

      </mat-accordion>
    </div>
  </div>
</div>

<app-update-jobs #updateJobModal (triggerDetailUpdate)="refreshDetailsFromUpdate($event)"></app-update-jobs>

