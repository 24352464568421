//Angular Imports 
import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';

//Third Party Imports
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { DeployHistoryLog } from '../../models';

/**
 * Forms Component
*/
@Component({
  selector: 'editLog',
  templateUrl: 'editLog.component.html',
  styleUrls: ['editLog.component.scss']
})

export class EditLog {

  @Input() Row: DeployHistoryLog;



  constructor(public activeModal: NgbActiveModal,
    private _modal: NgbModal,
    private toastr: ToastrService) {
  }

  ngOnInit(): void {
    
  }

  updateLog(): void {
    this.activeModal.close(this.Row);
  }

  /**
   * NgOnDestroy
   * @ignore
  */
  ngOnDestroy(): void {

  }

}
