<div class="row">
  <div class="col-md-5">
    <h2 class="dash-item-title">Partner Statistics</h2>
  </div>
  <div class="col-md-7">
    <div *ngIf="isLoading">
      <mat-progress-spinner mode="indeterminate" diameter="30"></mat-progress-spinner>
    </div>
  </div>
</div>

<div *ngIf="!isLoading && hasError">
  <p class="alert mdo-alert-danger">Could not load statistical data, please <a class="mdo-alert-danger-link" href="" (click)="fixStatisticsData()">click here</a> to try again.</p>
</div>

<div *ngIf="!isLoading && !hasError">
  <ag-grid-angular id="statisticsGrid"
                   style="width: 80%; height: 350px;"
                   class="ag-theme-balham mdo-standard-grid"
                   [rowData]="partnerData"
                   [columnDefs]="columnDefs"
                   [gridOptions]="gridOptions"
                   (gridReady)="onGridReady($event)"
                   [pagination]="pagination"
                   [pinnedBottomRowData]="partnerTotals">
  </ag-grid-angular>
</div>
