//Angular Imports 
import { Component, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common'

//Third Party Imports 
import { GridOptions } from 'ag-grid-community';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';

//Internal Imports 
import {
  ApplicationInsightsBaseComponent, AppInsightsService, SharedService, DateFilterComponent,
  BooleanFilterComponent, DateTimeUtcPipe, AuthorizationService
} from '../../../framework';

import { AdminForm } from '../../../form-page';
import { RuleEngineForm } from '../../../form-page/models/ruleEngineForm.model';
import { PolicyFormExport } from '../../models/policyFormExport.model';
import { OdenService } from '../../services';
import { OdenData } from '../../../framework/models/oden-data.model';


/**
 * Forms Component
*/
@Component({
  selector: 'oden',
  templateUrl: './oden.component.html',
  styleUrls: ['./oden.component.scss']
})

export class OdenComponent extends ApplicationInsightsBaseComponent {

  //Public Variables
  /**
   * Host binding for width 
  */
  @HostBinding('style.width') width: string;
  public formsGridOptions: GridOptions;
  public odenData: OdenData[] = [];
  public selectedLOB: string;
  initialRowDataLoad$;

  /** 
   * Constructor
   * @ignore
  */
  constructor(private _appInsightsService: AppInsightsService,
    private toastr: ToastrService,
    private odenService: OdenService,
    private _modal: NgbModal,
    private sharedService: SharedService,
    private authService: AuthorizationService,
    private dateTimePipe: DateTimeUtcPipe) {
    super(_appInsightsService);
    this.width = "100%";
  }

  get FormsWritePermission(): boolean {
    return this.authService.FormsWrite();
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
  */
  ngOnInit(): void {

    this.configureGrid();

  }

  /**
   * NgDestroy
   * @ignore
  */
  ngOnDestroy(): void {
  }


  //Private Methods
  /**
   * configure grid
   */
  private configureGrid(): void {
    this.initialRowDataLoad$ = [];
    this.formsGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: 'autoHeight',
      columnDefs: this.createColumDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      enableColResize: true,
      paginationPageSize: 20,
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent
      },
      onGridReady: () => {
        this.formsGridOptions.api.setRowData([]);
        this.formsGridOptions.api.sizeColumnsToFit();
        this.getOdenData();
      },
      onRowClicked: (event) => {
      },
      onFilterChanged: (event) => {
        //Api call to get the filter data
        //console.log(event);

      },
      onSortChanged: (event) => {

      },
      onFilterModified: function (event) {
        //console.log(event);
      }
    };

  }

  /**
   * Configure Columns
   */
  private createColumDef(): any[] {
    let result: any[] = [
      {
        headerName: 'Timestamp', field: 'timestamp', sortable: true, filter: 'dateFilterComponent', cellRenderer: params => {
          return this.dateTimePipe.transform(params.value);
        }
      },
      { headerName: 'Formatted Message', width: 300, field: 'formatted_message', filter: 'agTextColumnFilter', sortable: true, resizable: true, comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      {
        headerName: 'Logger Name', field: 'logger_name', filter: 'agTextColumnFilter', sortable: true, resizable: true, comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); }
      },
      {
        headerName: 'Level String', field: 'level_string', resizable: true, sortable: true, filter: 'agTextColumnFilter'
      },
      {
        headerName: 'Thread Name', resizable: true, filter: 'agTextColumnFilter', sortable: true, field: 'thread_name'
      },
      {
        headerName: 'Reference Flag', resizable: true, sortable: true, width: 60, field: 'reference_flag'
      },
      {
        headerName: 'Arg0', resizable: true, filter: 'agTextColumnFilter', sortable: true, field: 'arg0'
      },
      {
        headerName: 'Arg1', resizable: true, filter: 'agTextColumnFilter', sortable: true, field: 'arg1'
      },
      {
        headerName: 'Arg2', resizable: true, filter: 'agTextColumnFilter', sortable: true, field: 'arg2'
      },
      {
        headerName: 'Arg3', resizable: true, filter: 'agTextColumnFilter', sortable: true, field: 'arg3'
      },
      {
        headerName: 'Caller Filename', resizable: true, filter: 'agTextColumnFilter', sortable: true, field: 'caller_filename'
      },
      {
        headerName: 'Caller Class', resizable: true, filter: 'agTextColumnFilter', sortable: true, field: 'caller_class'
      },
      {
        headerName: 'Caller Method', resizable: true, filter: 'agTextColumnFilter', sortable: true, field: 'caller_method'
      },
    ]


    return result;
  }

  public getOdenData(): void {
    this.sharedService.getOdenLogs().subscribe(res => {
      this.odenData = res;
      console.log(this.odenData);
      this.formsGridOptions.api.setRowData(this.odenData);
    });
  }



}
