//Angular Imports 
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//Third Party Imports 
import { AgGridModule } from 'ag-grid-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { ClipboardModule } from '@angular/cdk/clipboard';

//Internal Imports 
import { FrameworkModule, BooleanFilterComponent } from '../../framework';

import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { QueueRoutingModule } from './queue.routing';
import { QueueComponent } from './components/queue.component';
import { QueueError } from './components/queueError/queueError.component';
import { GPMaterialComponentModule } from '../gpMaterial.module';
import { QueueDetailComponent } from './components/queueDetails/queueDetails.component';
import { QueueService } from './services/queue.service';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';

/**
  Forms Module
*/
@NgModule({
    declarations: [
        QueueComponent,
        QueueError,
        QueueDetailComponent
    ],
    imports: [
        QueueRoutingModule,
        CommonModule,
        FormsModule,
        NgSelectModule,
        FrameworkModule,
        AgGridModule.withComponents([BooleanFilterComponent]),
        MatButtonToggleModule,
        MatSlideToggleModule,
        ClipboardModule,
        GPMaterialComponentModule,
      ReactiveFormsModule,
      NgxMatDatetimePickerModule,
      NgxMatTimepickerModule,
      NgxMatNativeDateModule
    ],
    providers: [
        QueueService
    ]
})
export class QueueModule { }
