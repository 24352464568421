//Angular Imports 
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//Internal Imports 
import { FrameworkModule } from '../framework';
import { ImportRoutingModule } from './import.routing';
import { ImportService } from './services/import.service';
import { ImportComponent } from './components';
import { MatTableModule } from '@angular/material/table';

/**
  Forms Module
*/
@NgModule({
    declarations: [
        ImportComponent
    ],
    imports: [
        ImportRoutingModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
      FrameworkModule,
      MatTableModule
    ],
    providers: [
        ImportService
    ]
})
export class ImportModule { }
