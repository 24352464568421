//Angular Imports
import {
  Component,
  HostBinding,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";

//Third Party Imports
import { GridOptions } from "ag-grid-community";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

//Internal Imports
import { SelectableItem } from "../../../framework/models/selectable-item.model";
import {
  ApplicationInsightsBaseComponent,
  AppInsightsService,
  DateFilterComponent,
  BooleanFilterComponent,
  DateTimePipe,
  SharedService,
  EnvironmentService,
  AuthorizationService,
} from "../../../framework";
import { FormPageAttributesContainerComponent } from "../../components/form-page-attributes-container/form-page-attributes-container.component";
import { AdminForm, AttributeSet } from "../../models";
import { FormPageService } from "../../services";
//import { lobCpmElementId } from '../../configs';

import "rxjs";
import { saveAs } from "file-saver";
import { TestingService } from "../../../diagnostics/testSuites/execution/services/testing.service";

//import { RuleEngineResult, SingleFormResult, RuleEngineLog, TestExecution, TestGroup, TestRun } from '../../models';

/**
 * Forms Component
 */
@Component({
  selector: "app-form-page-attributes-tab",
  templateUrl: "./form-page-attributes-tab.component.html",
  styleUrls: ["./form-page-attributes-tab.component.scss"],
})
export class FormPageAttributesTabComponent extends ApplicationInsightsBaseComponent {
  private readonly masterAttributeSetScope: string = "Master";

  @HostBinding("style.width") width: string;

  // The form currently populating the page
  @Input() public currentForm: AdminForm;
  // Used to track the current form and determine if the actual form has changed in the OnChanges event
  private currentFormNumber: string;

  @Input() public isNewForm: boolean;

  // Used to pass save event up to page-container when Save button is clicked
  @Output() saveForm = new EventEmitter<AdminForm>();

  // Used to pass discard event up to page-container when Discard button is clicked
  @Output() discardChanges = new EventEmitter();

  // Child component holding the master form attribute set
  @ViewChild("masterAttributeSetComponent", { static: false })
  private masterAttributeSetComponent: FormPageAttributesContainerComponent;

  // Child component holding the lob attribute set
  @ViewChild("lobAttributeSetComponent", { static: false })
  private lobAttributeSetComponent: FormPageAttributesContainerComponent;

  // The AttributeSet for the Master Form level, passed into the master-view
  public masterAttributeSet: AttributeSet;

  // The AttributeSet for the currently selected LOB, passed into the lob-view
  public currentLobAttributeSet: AttributeSet;

  public masterActionType: string;
  public lobActionType: string;

  // The list of LOB's on the current form.
  public lobList: string[];
  public selectedLob: any;

  public formTypeList: any[];

  // Used to populate the Line of Business grid
  lobGridOptions: GridOptions;
  lobSelectableItems: SelectableItem[];

  /**
   * Constructor
   * @ignore
   */
  constructor(
    private _appInsightsService: AppInsightsService,
    private toastr: ToastrService,
    private sharedService: SharedService,
    private formPageService: FormPageService,
    private authService: AuthorizationService,
    private testingService: TestingService
  ) {
    super(_appInsightsService);
    this.width = "100%";
    this.lobList = [];
  }

  get FormsWritePermission(): boolean {
    return this.authService.FormsWrite();
  }

  ngOnInit(): void {
    this.configureLobGrid();

    this.masterActionType = "View";
    this.lobActionType = "View";

    this.sharedService.getMasterData().subscribe(
      (el) => {
        this.formTypeList = el.FormType;
      },
      (error) => {
        this.toastr.error(
          "Error occurred while fetching master data.",
          "Form Page"
        );
      }
    );
  }

  ngOnChanges(): void {
    if (this.currentForm) {
      // OnChanges gets called whenever form data changes, but we only want to perform
      // these steps when the actual form changes.

      if (this.isNewForm) {
        // This is a new form so go into edit mode
        this.masterActionType = "Edit";
      }

      if (this.currentForm.InternalFormNumber !== this.currentFormNumber) {
        this.currentFormNumber = this.currentForm.InternalFormNumber;

        this.masterAttributeSet = _.cloneDeep(
          this.currentForm.AttributeSets.find(
            (x) => x.Scope === this.masterAttributeSetScope
          )
        );
        this.masterAttributeSet.InternalFormNumber =
          this.currentForm.InternalFormNumber;
        this.masterAttributeSet.ExternalFormNumber =
          this.currentForm.ExternalFormNumber;
        this.masterAttributeSet.MultiLOBIndicator =
          this.currentForm.MultiLOBIndicator;
        this.masterAttributeSet.PriorInternalFormNumber =
          this.currentForm.PriorInternalFormNumber;

        // Populate the editable and non-editable LOB list for the selected form.
        this.createSelectableItemLists();
        this.populateLobList();

        // Clear the selected LOB and clear the currentLobAttributeSet
        this.selectedLob = "";
        this.currentLobAttributeSet = new AttributeSet();
      }
    }
  }

  ngOnDestroy(): void {}

  // Populate the list of LOBs that are on the current form
  // Used for the read-only LOB list in Master panel, and LOB dropdown in LOB-specific panel
  populateLobList(): void {
    this.sharedService.getMasterData().subscribe(
      (el) => {
        let masterLobList: any[] = el.LineOfBusinessList;
        this.lobList = [];
        for (const item of masterLobList) {
          if (this.currentForm.LOB.find((x) => x === item.Name)) {
            this.lobList.push(item.Name + " - " + item.Description);
          }
        }

        this.lobList = this.lobList.sort((a, b) => {
          if (a == b) {
            return 0;
          } else if (a < b) {
            return -1;
          } else return 1;
        });
      },
      (error) => {
        this.toastr.error(
          "Error occurred while fetching LOB list from master data.",
          "Policy Form"
        );
      }
    );
  }

  onEditMaster() {
    this.masterActionType = "Edit";
  }

  onSaveMaster() {
    try {
      // Call validation function on child component to validate the master attributes section before saving
      let isValid = this.masterAttributeSetComponent.validateAttributes(
        this.isNewForm
      );

      if (isValid) {
        this.currentForm.LastModified = "";
        // Copy masterAttributeSet updates into the currentForm object, then save the form
        let original: AttributeSet = this.currentForm.AttributeSets.find(
          (x) => x.Scope === this.masterAttributeSetScope
        );
        this.currentForm.InternalFormNumber =
          this.masterAttributeSet.InternalFormNumber;
        this.currentForm.ExternalFormNumber =
          this.masterAttributeSet.ExternalFormNumber;
        this.currentForm.PriorInternalFormNumber =
          this.masterAttributeSet.PriorInternalFormNumber;
        //CR Replicate changed dates and sort order to all LOB's
        if (original.EffectiveDate != this.masterAttributeSet.EffectiveDate) {
          for (const item of this.currentForm.AttributeSets) {
            item.EffectiveDate = this.masterAttributeSet.EffectiveDate;
          }
        }
        if (original.ExpirationDate != this.masterAttributeSet.ExpirationDate) {
          for (const item of this.currentForm.AttributeSets) {
            item.ExpirationDate = this.masterAttributeSet.ExpirationDate;
          }
        }
        if (
          original.SubmissionExpirationDate !=
          this.masterAttributeSet.SubmissionExpirationDate
        ) {
          for (const item of this.currentForm.AttributeSets) {
            item.SubmissionExpirationDate =
              this.masterAttributeSet.SubmissionExpirationDate;
          }
        }
        if (original.SortOrder != this.masterAttributeSet.SortOrder) {
          for (const item of this.currentForm.AttributeSets) {
            item.SortOrder = this.masterAttributeSet.SortOrder;
          }
        }
        if (original.FormType != this.masterAttributeSet.FormType) {
          for (const item of this.currentForm.AttributeSets) {
            item.FormType = this.masterAttributeSet.FormType;
          }
        }
        if (original.FormName != this.masterAttributeSet.FormName) {
          for (const item of this.currentForm.AttributeSets) {
            item.FormName = this.masterAttributeSet.FormName;
          }
        }
        original.FormName = this.masterAttributeSet.FormName;
        original.FormType = this.masterAttributeSet.FormType;
        original.EffectiveDate = this.masterAttributeSet.EffectiveDate;
        original.ExpirationDate = this.masterAttributeSet.ExpirationDate;
        original.SubmissionExpirationDate =
          this.masterAttributeSet.SubmissionExpirationDate;
        original.SortOrder = this.masterAttributeSet.SortOrder;
        original.MultipleAttachment =
          this.masterAttributeSet.MultipleAttachment;
        original.FrequentlyUsed = this.masterAttributeSet.FrequentlyUsed;

        // Update the form based on LOBs that have been added or removed
        this.formPageService.updateFormWithLobSelections(
          this.currentForm,
          this.lobSelectableItems,
          this.isNewForm
        );

        // Update the LOB list used for read-only mode on master panel and for dropdown on LOB panel
        this.populateLobList();
        this.sortSelectableItems(this.lobSelectableItems);
        this.lobGridOptions.api.setRowData(this.lobSelectableItems);

        this.masterActionType = "View";
        // Fire event up to page-container to save the form
        this.currentForm.LastModified = "Master";
        this.saveForm.emit(this.currentForm);
      }
    } catch (e) {
      this.toastr.error("Error saving the form.  " + e.message);
    }
  }

  onDiscardMaster() {
    this.masterActionType = "View";

    // Discard changes in masterAttributeSet and repopulate it from unchanged currentForm data
    this.masterAttributeSet = _.cloneDeep(
      this.currentForm.AttributeSets.find(
        (x) => x.Scope === this.masterAttributeSetScope
      )
    );
    this.masterAttributeSet.InternalFormNumber =
      this.currentForm.InternalFormNumber;
    this.masterAttributeSet.ExternalFormNumber =
      this.currentForm.ExternalFormNumber;
    this.masterAttributeSet.MultiLOBIndicator =
      this.currentForm.MultiLOBIndicator;
    this.masterAttributeSet.PriorInternalFormNumber =
      this.currentForm.PriorInternalFormNumber;

    // Reload lob SelectedItems list from the currentForm LOB list
    this.createSelectableItemLists();

    this.populateLobList();

    this.discardChanges.emit();
  }

  onSelectLob() {
    this.currentLobAttributeSet = _.cloneDeep(this.getcurrentLobAttributeSet());
  }

  getLobCodeFromDisplayString(item: string): string {
    let index = item.indexOf(" ");
    return item.substring(0, index);
  }

  getcurrentLobAttributeSet(): AttributeSet {
    return this.currentForm.AttributeSets.find(
      (x) => x.Scope === this.getLobCodeFromDisplayString(this.selectedLob)
    );
  }

  onEditLOB() {
    this.lobActionType = "Edit";
  }

  onSaveLOB() {
    try {
      // Validate the lob attributes section before saving
      let isValid = this.lobAttributeSetComponent.validateAttributes(
        this.isNewForm
      );

      if (isValid) {
        // Copy  updates into the currentForm object, then save the form
        let original: AttributeSet = this.getcurrentLobAttributeSet();
        original.FormName = this.currentLobAttributeSet.FormName;
        original.FormType = this.currentLobAttributeSet.FormType;
        original.EffectiveDate = this.currentLobAttributeSet.EffectiveDate;
        original.ExpirationDate = this.currentLobAttributeSet.ExpirationDate;
        original.SubmissionExpirationDate =
          this.currentLobAttributeSet.SubmissionExpirationDate;
        original.SortOrder = this.currentLobAttributeSet.SortOrder;
        original.MultipleAttachment =
          this.currentLobAttributeSet.MultipleAttachment;
        original.FrequentlyUsed = this.currentLobAttributeSet.FrequentlyUsed;
        original.DisplayOnly = this.currentLobAttributeSet.DisplayOnly;
        original.PolicyLevel = this.currentLobAttributeSet.PolicyLevel;

        this.lobActionType = "View";

        // Fire event up to page-container to save the form
        this.currentForm.LastModified = this.selectedLob;
        this.saveForm.emit(this.currentForm);
      }
    } catch (e) {
      this.toastr.error("Error saving the form.");
    }
  }

  onDiscardLOB() {
    this.lobActionType = "View";
    // Discard changes in currentLobAttributeSet and repopulate it from unchanged currentForm data
    this.currentLobAttributeSet = _.cloneDeep(this.getcurrentLobAttributeSet());

    this.discardChanges.emit();
  }

  private createSelectableItemLists(): void {
    // Load LOBs
    if (this.currentForm) {
      this.formPageService.getLobSelectableItemList().subscribe(
        (res) => {
          // Need to clone this because service is returning a cached object and we don't want to modify it
          let items: SelectableItem[] = _.cloneDeep(res);

          // foreach lob on the form, mark the corrosponding SelectedItem to Selected
          for (var lob of this.currentForm.LOB) {
            var item = items.find((x) => x.Code === lob);
            if (item) {
              item.Selected = true;
            }
          }

          this.lobSelectableItems = items;
          this.sortSelectableItems(this.lobSelectableItems);

          if (this.lobGridOptions.api) {
            this.lobGridOptions.api.setRowData(this.lobSelectableItems);
            this.lobGridOptions.columnApi.autoSizeAllColumns();
          }
        },
        (error) => {
          this.toastr.error(
            "Error occurred while fetching LOB list.",
            "Form Page"
          );
        }
      );
    }
  }

  private sortSelectableItems(items: SelectableItem[]) {
    items.sort((a, b) => {
      if (a.Selected == b.Selected) {
        if (a.VisibleDescription < b.VisibleDescription) {
          return -1;
        } else {
          return 1;
        }
      } else if (a.Selected) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  private configureLobGrid(): void {
    //this.initialRowDataLoad$ = [];
    this.lobGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: "normal",
      columnDefs: this.createLobColumnDef(),
      enableFilter: true,
      enableSorting: true,
      rowHeight: 30,
      enableColResize: true,
      onGridReady: () => {
        this.lobGridOptions.api.setRowData(this.lobSelectableItems);
        //this.summaryGridOptions.api.sizeColumnsToFit();
        this.lobGridOptions.columnApi.autoSizeAllColumns();
      },
      onRowClicked: (event) => {
        this.onRowSelected(event);
        this.lobGridOptions.api.refreshCells();
      },
      onFilterChanged: (event) => {},
      onSortChanged: (event) => {},
      onFilterModified: function (event) {
        //console.log(event);
      },
    };
  }

  /**
   * configure forms grid columns
   */
  private createLobColumnDef(): any[] {
    return [
      {
        headerName: "",
        field: "Selected",
        maxWidth: 30,
        suppressFilter: true,
        cellRenderer: (params) => {
          return `<input type='checkbox' ${
            params.value == true ? "checked" : ""
          } />`;
        },
      },
      {
        headerName: "",
        field: "VisibleDescription",
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
    ];
  }

  private onRowSelected(event: any): void {
    if (this.masterActionType == "Edit") {
      if (event.node.selected) {
        if (event.data.Selected) {
          event.data.Selected = false;
        } else {
          event.data.Selected = true;
        }
      }
      var x = 0;
      for (var i = 0; i < this.lobSelectableItems.length; i++) {
        if (this.lobSelectableItems[i].Selected == true) {
          x++;
        }
      }
      if (x > 1) {
        this.currentForm.MultiLOBIndicator = true;
        this.masterAttributeSet.MultiLOBIndicator = true;
      } else {
        this.currentForm.MultiLOBIndicator = false;
        this.masterAttributeSet.MultiLOBIndicator = false;
      }
    }
  }
}
