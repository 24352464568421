//Angular Imports
import {
  Component,
  HostBinding,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";

//Third Party Imports
import { GridOptions } from "ag-grid-community";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

//Internal Imports
import {
  ApplicationInsightsBaseComponent,
  AppInsightsService,
  DateFilterComponent,
  BooleanFilterComponent,
  DateTimePipe,
  SharedService,
  EnvironmentService,
} from "../../../framework";
import { Rule, ElementExpression } from "../../models";
//import { ExecutionService, TestingService } from '../../services';

import "rxjs";
import { saveAs } from "file-saver";

//import { RuleEngineResult, SingleFormResult, RuleEngineLog, TestExecution, TestGroup, TestRun } from '../../models';

declare var Blockly: any;
import { blocklyToolbox } from "../../configs";
//import * as Blockly from 'blockly';

/**
 * Forms Component
 */
@Component({
  selector: "app-form-page-rule",
  templateUrl: "./form-page-rule.component.html",
  styleUrls: ["./form-page-rule.component.scss"],
})
export class FormPageRuleComponent extends ApplicationInsightsBaseComponent {
  @HostBinding("style.width") width: string;

  @Input() public currentRule: Rule;

  @Output() deleteRule = new EventEmitter<Rule>();

  @Output() cloneRule = new EventEmitter<Rule>();

  @Output() saveRule = new EventEmitter<Rule>();

  enclosureColor: string;
  //Define border bottom/top here so it doesn't get overwritten
  enclosureBorder: string = "lightgray";
  hasComment: boolean = false;
  hasDates: boolean = false;

  ruleName: string = "";

  elementList: any[];

  /**
   * Constructor
   * @ignore
   */
  constructor(
    private _appInsightsService: AppInsightsService,
    private sharedService: SharedService
  ) {
    super(_appInsightsService);
    this.width = "100%";
  }

  ngOnInit(): void {
    if (this.currentRule.Action === "Attach") {
      this.enclosureColor = "mediumseagreen";
      this.ruleName = "Attach";
    } else {
      this.enclosureColor = "lightcoral";
      this.ruleName = "Remove";
    }

    if (this.currentRule.Comment) {
      this.hasComment = true;
    }

    if (
      this.currentRule.EffectiveDate &&
      this.currentRule.EffectiveDate !== 0
    ) {
      this.hasDates = true;
    }

    // Populate list of CPM elements used to create new expressions, this data is modified through the CPM Elements page in Workbench
    this.sharedService.getAllCpmElements().subscribe((el) => {
      this.elementList = el;
      this.elementList.sort((a, b) =>
        a.CPMDataElementName < b.CPMDataElementName
          ? -1
          : a.CPMDataElementName > b.CPMDataElementName
          ? 1
          : 0
      );
      console.log(this.elementList);
    });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  ngOnChanges(): void {}

  //onCheckBox(event:any): void {
  //this.currentRule.IsMandatory = event.target.checked;
  //}

  onAddComment(): void {
    this.currentRule.Comment = "";
    this.hasComment = true;
  }
  //When adding dates automatically set effective date
  onAddDates(): void {
    this.hasDates = true;
    this.currentRule.EffectiveDate = -2208988800;
  }

  onAddExpression(elementName: string): void {
    let expression: ElementExpression = new ElementExpression();
    expression.ElementName = elementName;
    expression.Operator = "in";
    expression.IncludedList = [];
    expression.MandatoryList = [];
    this.currentRule.Expressions.push(expression);
    this.saveRule.emit(this.currentRule);
  }

  onDeleteExpression(event: ElementExpression) {
    let index = this.currentRule.Expressions.indexOf(event);
    if (index >= 0) {
      this.currentRule.Expressions.splice(index, 1);
    }
    this.saveRule.emit(this.currentRule);
  }

  onDeleteRule(): void {
    if (confirm("Delete this rule?")) {
      this.deleteRule.emit(this.currentRule);
    }
  }

  onCloneRule(): void {
    if (confirm("Clone this rule?")) {
      this.cloneRule.emit(this.currentRule);
    }
  }

  onSaveExpression(expression: any): void {
    this.currentRule.Expressions[
      this.currentRule.Expressions.indexOf(expression)
    ] = expression;
    this.saveRule.emit(this.currentRule);
  }
}
