//Angular Imports 
import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';

//Third Party Imports
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

//Internal Imports 
import { SelectableItem } from '../../../../../framework/models/selectable-item.model';
import { UserProfileService } from '../../../../../framework';
import { TestingService } from '../../services';
import { RuleEngineResult, RuleEngineLog, TestExpectedResults } from '../../models';
import { coverageCpmElementId, lobCpmElementId } from '../../configs/execution-engine.config';

import 'rxjs';
import { TestCase } from '../../models';
import { TestCaseExpectedResult } from '../test-case-expected-result';


/**
 * Forms Component
*/
@Component({
  selector: 'app-test-case-detail',
  templateUrl: './test-case-detail.component.html',
  styleUrls: ['./test-case-detail.component.scss']
})

export class TestCaseDetail {

  // Child component holding expected results for the test case
  @ViewChild(TestCaseExpectedResult, {static:false})
  private expectedResultsComponent: TestCaseExpectedResult;

  // Specifies the action type the screen was opened for (Add, Edit)
  @Input() public actionType: string;

  // The log item used to create a new test case, only appliccable for Add action type
  @Input() public logItem: RuleEngineLog;

  // The test case passed into the component when editing an existing component
  @Input() public testCase: TestCase;

  // Used to populate the Test Group grid
  testGroupGridOptions: GridOptions;
  testGroupSelectableItems: SelectableItem[];

  // Used to populate the Line of Business grid
  lobGridOptions: GridOptions;
  lobSelectableItems: SelectableItem[];

  // Used to populate the Coverage grid
  coverageGridOptions: GridOptions;
  coverageSelectableItems: SelectableItem[];

  // Policy Effective Date of the CPM associated with the test case
  public policyEffectiveDate: string;

  // Set to true when user clicks the Save button so validation messages will show
  public formSubmitted: boolean

  /** 
   * Constructor
   * @ignore
  */
  constructor(public activeModal: NgbActiveModal,
    private testingService: TestingService,
    private userProfileService: UserProfileService,
    private _modal: NgbModal,
    private toastr: ToastrService) {

    this.lobSelectableItems = [];
    this.coverageSelectableItems = [];
    this.testGroupSelectableItems = [];
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
  */
  ngOnInit(): void {
    this.configureTestGroupGrid();
    this.configureLobGrid();
    this.configureCoverageGrid();

    if (this.actionType === "Add") {
      this.testCase = new TestCase();
      this.testCase.IsActive = true;
      if (this.logItem) {
        this.populateCpmData();
        this.testCase.CPM = this.logItem.CPM;
        this.createExpectedResults();
      } else {
        this.testCase.TestGroups = [];
        this.testCase.Coverages = [];
        this.testCase.LOBs = [];
        this.createSelectableItemLists();
      }
    } else if (this.actionType === "Edit") {
      this.createSelectableItemLists();
    }
    else if (this.actionType === "Read") {
      this.createSelectableItemLists();
    }
    else {
      this.toastr.error("Invalid actionType: " + this.actionType);
    }
  }

  /**
   * NgOnDestroy
   * @ignore
  */
  ngOnDestroy(): void {

  }

  public saveTestCase() {
    this.formSubmitted = true;

    if (this.validateForm()) {
      if (this.actionType == "Add") {
        // Add a new TestCase
        this.testCase.DocumentType = "TestCase";
        this.testCase.CreatedByName = this.userProfileService.UserName;
        this.testCase.Id = "00000000-0000-0000-0000-000000000000";
        this.testCase.CreationDate = moment().unix();
        if (!this.testCase.Name) {
          this.testCase.Name = "";
        }
        this.saveSelectableItems();

        this.testingService.addTestCase(this.testCase).subscribe(res => {
          this.testCase = res;

          // Close the popup after saving
          this.activeModal.close(this.testCase);
          this.toastr.success("Test Case saved successfully.");
        },
          err => {
            this.toastr.error("Error saving Test Case.");
          });
      } else {
        // Update an existing TestCase
        this.testCase.ModifiedDate = moment().unix();
        this.testCase.ModifiedByName = this.userProfileService.UserName;
        this.saveSelectableItems();

        this.testingService.updateTestCase(this.testCase).subscribe(res => {
          this.testCase = res;

          // Close the popup after saving
          this.activeModal.close(this.testCase);
          this.toastr.success("Test Case saved successfully.");
        },
          err => {
            this.toastr.error("Error saving Test Case.");
          });

      }
    } else {
      this.toastr.error("Fix validation errors.");
    }

  }

  private validateForm(): boolean {
    if (!this.testCase.Name) {
      return false;
    }
    if (!this.testCase.CPM) {
      return false;
    }
    return true;
  }

  private saveSelectableItems() {
    let testGroups: string[] = [];
    for (var testGroup of this.testGroupSelectableItems) {
      if (testGroup.Selected) {
        testGroups.push(testGroup.Code);
      }
    }
    this.testCase.TestGroups = testGroups;

    let coverages: string[] = [];
    for (var coverage of this.coverageSelectableItems) {
      if (coverage.Selected) {
        coverages.push(coverage.Code);
      }
    }
    this.testCase.Coverages = coverages;

    let lobs: string[] = [];
    for (var lob of this.lobSelectableItems) {
      if (lob.Selected) {
        lobs.push(lob.Code);
      }
    }
    this.testCase.LOBs = lobs;

    // Save the expected results currently selected and displayed in the child component back
    // to the testCase
    this.expectedResultsComponent.saveExpectedResult();
  }

  private createExpectedResults() {
    let expectedResults: TestExpectedResults[] = [];

    if (this.logItem && this.logItem.RuleEngineResults) {
      let ruleEngineResults: RuleEngineResult[] = JSON.parse(this.logItem.RuleEngineResults);
      if (ruleEngineResults && ruleEngineResults.length) {
        for (var result of ruleEngineResults) {
          let newResult: TestExpectedResults = new TestExpectedResults();
          newResult.QuoteOption = result.QuoteOption;
          newResult.LOB = result.LOB;
          expectedResults.push(newResult);

          for (var form of result.FinalResults) {
            if (form.AttachmentReason === "Mandatory") {
              newResult.MandatoryForms.push(form.InternalFormNumber);
            } else {
              newResult.OptionalForms.push(form.InternalFormNumber);
            }
          } // end each form

        }
      }
    }

    // PB 11/26/19 - Changing this to "N/A" and saving it that way is causing a mismatch between the QuoteOption in the
    // TestCase and in the RuleEngineResults when executing the test case.  If we want to display N/A, we need to make
    // sure it is saved as empty.
    //for (var expectedResult of expectedResults) {
    //  if (!expectedResult.QuoteOption) {
    //    expectedResult.QuoteOption = "N/A";
    //  }
    //}
    this.testCase.ExpectedResults = expectedResults;
  }

  private populateCpmData(): void {
    this.testingService.getCpmDataForTestCase(this.logItem.CPM).subscribe(res => {
      this.testCase.LOBs = res.ElementValues.LineOfBusiness;
      this.testCase.Coverages = res.ElementValues.Coverage;
      this.testCase.WritingCompany = res.ElementValues.WritingCompany[0];
      this.testCase.State = res.ElementValues.State[0];
      this.testCase.PolicyEffectiveDate = res.EffectiveDate;

      this.createSelectableItemLists();
    });
  }

  private createSelectableItemLists(): void {
    // Load LOBs
    this.testingService.getSelectableCpmElementList(lobCpmElementId).subscribe(res => {
      // foreach lob in test case, set selected item to selected
      for (var lob of this.testCase.LOBs) {
        var item = res.find(x => x.Code === lob);
        if (item) {
          item.Selected = true;
        }
      }

      this.lobSelectableItems = res;
      this.sortSelectableItems(this.lobSelectableItems);
      this.lobGridOptions.api.setRowData(this.lobSelectableItems);
      this.lobGridOptions.columnApi.autoSizeAllColumns();
    });

    // Load Coverages
    this.testingService.getSelectableCpmElementList(coverageCpmElementId).subscribe(res => {
      // foreach coverage in test case, set selected item to selected
      for (var coverage of this.testCase.Coverages) {
        var item = res.find(x => x.Code === coverage);
        if (item) {
          item.Selected = true;
        }
      }
      this.coverageSelectableItems = res;
      this.sortSelectableItems(this.coverageSelectableItems);
      this.coverageGridOptions.api.setRowData(this.coverageSelectableItems);
      this.coverageGridOptions.columnApi.autoSizeAllColumns();
    });

    // Load TestGroups
    this.testingService.getTestGroups().subscribe(res => {
      for (var testGroup of res) {
        let item: SelectableItem = new SelectableItem();
        item.Code = testGroup.Id;
        item.VisibleDescription = testGroup.Name;
        if (this.testCase.TestGroups.find(x => x === testGroup.Id)) {
          item.Selected = true;
        }
        this.testGroupSelectableItems.push(item);
      }
      this.sortSelectableItems(this.testGroupSelectableItems);
      this.testGroupGridOptions.api.setRowData(this.testGroupSelectableItems);
      this.testGroupGridOptions.columnApi.autoSizeAllColumns();
    });
  }

  private sortSelectableItems(items: SelectableItem[]) {
    items.sort((a, b) => {
      if (a.Selected == b.Selected) {
        return 0;
      }
      else if (a.Selected) {
        return -1;
      }
      else
        return 1;
    });
  }

  private configureTestGroupGrid(): void {
    //this.initialRowDataLoad$ = [];
    this.testGroupGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: 'normal',
      columnDefs: this.createTestGroupColumnDef(),
      enableFilter: true,
      enableSorting: true,
      rowHeight: 30,
      enableColResize: true,
      onGridReady: () => {
        this.testGroupGridOptions.api.setRowData(this.testGroupSelectableItems);
        //this.summaryGridOptions.api.sizeColumnsToFit();
        this.testGroupGridOptions.columnApi.autoSizeAllColumns();
      },
      onRowClicked: (event) => {
        this.onRowSelected(event);
        this.testGroupGridOptions.api.refreshCells();
      },
      onFilterChanged: (event) => {
      },
      onSortChanged: (event) => {
      },
      onFilterModified: function (event) {
        //console.log(event);
      }
    };
  }

  private onRowSelected(event: any): void {
    //event.data.Selected = event.node.selected;

    if (event.node.selected) {
      if (event.data.Selected) {
        event.data.Selected = false;
      } else {
        event.data.Selected = true;
      }
    }
  }

  /**
  * configure forms grid columns
  */
  private createTestGroupColumnDef(): any[] {
    return [
      {
        headerName: '', field: 'Selected', maxWidth: 40, suppressFilter: true, cellRenderer: params => {
          //return `<input type='checkbox' ${params.value == true ? 'checked' : ''} />`;
          return `<input type='checkbox' data-action-type="Edit"  ${params.value ? 'checked' : ''} />`;
        }
      },
      { headerName: 'Test Group', field: 'VisibleDescription', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },

    ]
  }

  private configureLobGrid(): void {
    //this.initialRowDataLoad$ = [];
    this.lobGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: 'normal',
      columnDefs: this.createLobColumnDef(),
      enableFilter: true,
      enableSorting: true,
      rowHeight: 30,
      enableColResize: true,
      onGridReady: () => {
        this.lobGridOptions.api.setRowData(this.lobSelectableItems);
        //this.summaryGridOptions.api.sizeColumnsToFit();
        this.lobGridOptions.columnApi.autoSizeAllColumns();
      },
      onRowClicked: (event) => {
        this.onRowSelected(event);
        this.lobGridOptions.api.refreshCells();
      },
      onFilterChanged: (event) => {
      },
      onSortChanged: (event) => {
      },
      onFilterModified: function (event) {
        //console.log(event);
      }
    };
  }

  /**
  * configure forms grid columns
  */
  private createLobColumnDef(): any[] {
    return [
      {
        headerName: '', field: 'Selected', maxWidth: 40, suppressFilter: true, cellRenderer: params => {
          return `<input type='checkbox' ${params.value == true ? 'checked' : ''} disabled />`;
        }
      },
      { headerName: 'LOB', editable:false, field: 'VisibleDescription', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },

    ]
  }

  private configureCoverageGrid(): void {
    //this.initialRowDataLoad$ = [];
    this.coverageGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: 'normal',
      columnDefs: this.createCoverageColumnDef(),
      enableFilter: true,
      enableSorting: true,
      rowHeight: 30,
      enableColResize: true,
      onGridReady: () => {
        this.coverageGridOptions.api.setRowData(this.coverageSelectableItems);
        //this.summaryGridOptions.api.sizeColumnsToFit();
        this.coverageGridOptions.columnApi.autoSizeAllColumns();
      },
      onRowClicked: (event) => {
        this.onRowSelected(event);
        this.coverageGridOptions.api.refreshCells();
      },
      onFilterChanged: (event) => {
      },
      onSortChanged: (event) => {
      },
      onFilterModified: function (event) {
        //console.log(event);
      }
    };
  }

  /**
  * configure forms grid columns
  */
  private createCoverageColumnDef(): any[] {
    return [
      {
        headerName: '', field: 'Selected', maxWidth: 40, suppressFilter: true, cellRenderer: params => {
          return `<input type='checkbox' ${params.value == true ? 'checked' : ''} disabled />`;
        }
      },
      { headerName: 'Coverage', field: 'VisibleDescription', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },

    ]
  }


}
