import { Component, EventEmitter, Output } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular'
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-job-detail-trigger',
  template: `
    <span>
      <a class="job-detail-link mdo-standard-link" href="" (click)="showJobDetail($event)">{{ idBundle["jobId"] }}</a>
    </span>
    <app-copy-cell-value [cellValue]="idBundle['jobId']"></app-copy-cell-value>
  `
})
export class JobDetailTriggerComponent implements AgRendererComponent {

  idBundle: any = {};
  params: any;

  constructor() { }

  // Called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.idBundle = this.setIdBundle(params);
    this.params = params;
  }

  // Called whenever the user gets the cell to refresh
  refresh(params: ICellRendererParams): boolean {
    this.idBundle = this.setIdBundle(params);
    return true;
  }

  setIdBundle(params: ICellRendererParams): any {
    return {
      jobId: params.data.JobId,
      bundleLabel: "Job",
      bundleValue: params.data.JobId,
      context: "Job"
    }
  }

  showJobDetail(event: any) {
    event.preventDefault();
    // To prevent the row selection event from taking place and inadvertently toggling the selection checkbox
    event.stopPropagation();
    // Calls parent component method to then call addTab in masterviewcomponent
    this.params.context.componentParent.addJobDetailTab(
      this.idBundle
    );
  }

}
