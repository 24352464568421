//Angular Imports 
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { GridOptions } from 'ag-grid-community';
import { BooleanFilterComponent, DateFilterComponent, DateTimePipe, DateTimeUtcPipe, UserProfileService } from '../../../../framework';
import { RuleEngineForm } from '../../../../form-page/models/ruleEngineForm.model';

//Internal Imports


/**
 * Deploy Confirmation Component
*/
@Component({
  selector: 'selectedForms',
  templateUrl: './selectedForms.component.html',
  styleUrls: ['./selectedForms.component.scss']
})
export class SelectedFormsComponent implements OnInit {


  @Input() formsList: RuleEngineForm[];

  productionDeployment: boolean = false;

  selectedFormsGridOptions: GridOptions;


  /**
   * Constructor
   * @ignore
  */
  constructor(public activeModal: NgbActiveModal, private fb: UntypedFormBuilder,
    private toastr: ToastrService, private userProfileService: UserProfileService, private dateTimePipe: DateTimeUtcPipe) {

  }

  //Angular Lifecycles
  /**
   * On Init
   * @ignore
  */
  ngOnInit() {

    this.configureSelectedFormsGrid();

  }
  setRowData() {
    this.selectedFormsGridOptions.api.setRowData(this.formsList);
  }


  private configureSelectedFormsGrid(): void {
    //this.initialRowDataLoad$ = [];
    this.selectedFormsGridOptions = <GridOptions>{
      rowSelection: "multiple",
      domLayout: 'normal',
      columnDefs: this.createColumnDefs(),
      enableFilter: true,
      enableSorting: true,
      pagination: false,
      //paginationPageSize: 10,
      rowHeight: 30,
      enableColResize: true,
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent,
      },
      onGridReady: () => {

        console.log("Ready?");
        this.selectedFormsGridOptions.api.sizeColumnsToFit();
        this.setRowData();

      },
      onRowClicked: (event) => {
        this.onRowSelected(event);
      },
      onFilterChanged: (event) => {
      },
      onSortChanged: (event) => {
      },
      onFilterModified: function (event) {
        //console.log(event);
      }
    };

  }

  private createColumnDefs(): any[] {
    return [
      { headerName: '', checkboxSelection: true, headerCheckboxSelection: true, field: 'RowSelect', suppressMenu: true, suppressSorting: true, width: 20, filter: 'booleanFilterComponent' },
      {
        headerName: 'Internal Form Number', field: 'InternalFormNumber', width: 140, filter: 'agTextColumnFilter',
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); }
      },
      { headerName: 'Form Name', field: 'FormName', width: 270, filter: 'agTextColumnFilter', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      {
        headerName: 'Effective Date', field: 'EffectiveDate', width: 80, filter: 'dateFilterComponent', cellRenderer: params => {
          return this.dateTimePipe.transform(params.value);
        }
      },
      {
        headerName: 'Expiration Date', field: 'ExpirationDate', width: 100, filter: 'dateFilterComponent', cellRenderer: params => {
          return this.dateTimePipe.transform(params.value);
        }
      }

    ]
  }

  private onRowSelected(event: any): void {
    //event.data.Selected = event.node.selected;

    if (event.node.selected) {
      if (event.data.Selected) {
        event.data.Selected = false;
      } else {
        event.data.Selected = true;
      }
      this.selectedFormsGridOptions.api.refreshCells();
    }
  }

   removeSelected(): void {
    var selectedForms: RuleEngineForm[] = [];
    this.selectedFormsGridOptions.api.forEachNode(node => {
      if (!node.isSelected()) {
        selectedForms.push(node.data);
      }
    });
    this.formsList = selectedForms;
    this.selectedFormsGridOptions.api.setRowData(this.formsList);
    

  }

}
