import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ImportQueueService } from "../../services/import-queue.service";
import { ColDef, GridApi, GridOptions } from 'ag-grid-community';
import { StagingFile } from '../../models/staging-file';
import { LogMessageCellComponent } from '../log-message-cell/log-message-cell.component';
import { UpdateContext } from '../../models/update-context';
import { StagingFileUpdatePayload } from '../../models/staging-file-update-payload';

@Component({
  selector: 'app-staging-file-detail-tab',
  templateUrl: './staging-file-detail-tab.component.html',
  styleUrls: ['./staging-file-detail-tab.component.scss']
})
export class StagingFileDetailTabComponent implements OnInit {
  @Input() idBundle: any;
  @Output() updateGrid = new EventEmitter();

  @ViewChild("updateStagingFileModal") updateStagingFileModal: any;

  private notesGridApi: GridApi;
  frameworkComponents: any;
  isLoading: boolean = true;
  isReady: boolean = false;
  showDownloadMsg: boolean = false;
  wasDownloadSuccessful: boolean = true;
  details: StagingFile = new StagingFile();
  notesColumnDefs: ColDef[];
  actionHistoryColumnDefs: ColDef[];
  pagination: boolean = true;
  paginationAutoPageSize: boolean = true;
  notesGridOptions: GridOptions;
  
  constructor(private queueService: ImportQueueService) {
    let self = this;
    this.notesGridOptions = {
      onFirstDataRendered: function () {
        self.notesGridOptions.api.sizeColumnsToFit();
      }
    }

    this.notesColumnDefs = [
      {
        field: 'date',
        headerName: "Timestamp",
        cellRenderer: params => {
          return this.queueService.formatTimestamp(params.value);
        },
        sortable: true,
        comparator: this.queueService.dateComparator,
        sort: 'desc',
        filter: true,
        resizable: true,
        width: 150
      },
      { field: 'type', headerName: "Type", sortable: true, filter: true, resizable: true, width: 100 },
      { field: 'status', headerName: "Status", sortable: true, filter: true, resizable: true, width: 100 },
      { field: 'user', headerName: "User", sortable: true, filter: true, resizable: true, width: 150 },
      { field: 'message', headerName: "Note", sortable: true, filter: true, resizable: true, cellRenderer: 'logMessageCell' },
    ];

    // Assigns the cell renderers to the matching components
    this.frameworkComponents = {
      logMessageCell: LogMessageCellComponent
    };
  }

  

  ngOnInit(): void {
    this.getStagingFileDetails(this.idBundle);
  }

  onActionHistoryGridReady(params: any): void {
    this.notesGridApi = params.api;
  }

  getStagingFileDetails(idBundle: object): void {
    this.isLoading = true;
    this.queueService.getStagingFileDetails(idBundle["data"]["Id"], idBundle["data"]["Filename"] )
      .subscribe(
        // Handle successful retrieval of the staging file details
        (stagingFile: StagingFile) => {
          this.isReady = true;
          this.details = stagingFile;
          this.isLoading = false;
        },
        // Handle error response
        error => {
          this.isReady = false;
          this.isLoading = false;
        }
      )
  }

  // In case the column sizing did not occur because the grid was not visible / panel was closed.
  resizeGrid() {
    if (this.notesGridOptions.api) {
      this.notesGridOptions.api.sizeColumnsToFit();
    }   
  }

  retryStagingFileDetails(idBundle: object): void {
    event.preventDefault();
    this.getStagingFileDetails(idBundle);
  }

  downloadFiles(event: any): void {
    event.preventDefault();
    this.showDownloadMsg = false;
    this.queueService.getStagingFiles(this.details.id)
      .subscribe(
        // Handle successful retrieval of the job .zip file
        (res: any) => {
          let filename = "fileId-" + this.details.id + "-" + this.details.filename + ".zip";
          let success = this.queueService.generateDownloadedZipFile(filename, res.body);
          if (success) {
            this.wasDownloadSuccessful = true;
          } else {
            this.wasDownloadSuccessful = false;
          }
          this.showDownloadMsg = true;
        },
        // Handle error response
        error => {
          this.wasDownloadSuccessful = false;
          this.showDownloadMsg = true;
        }
      )
  }

  openUpdateModal(settings: any): void {
    let updateContext: UpdateContext = {
      context: settings.context,
      action: settings.action,
      transactions: [],
      jobs: [],
      stagingFiles: [
        {
          fileId: this.details.id,
          filename: this.details.filename,
          status: this.details.status,
          partner: this.details.partner,
          product: this.details.product
        }
      ]
    };
    this.updateStagingFileModal.openModal(updateContext);
  }

  refreshDetailsFromUpdate(stagingFileUpdatePayload: StagingFileUpdatePayload) {
    // You have to create a new array object to assign to notes, or the grid refresh won't do anything
    let updatedNotes = [...this.details.notes];
    updatedNotes.push(this.queueService.addStagingFileNoteHistory(stagingFileUpdatePayload, this.details.id));
    this.details.notes = updatedNotes;
    this.notesGridApi.refreshCells();
   
    if (stagingFileUpdatePayload.action) {
      let cleanStatus = this.details.status.replace(/\s?\([A-Za-z\s]*\)/, "");
      this.details.status = cleanStatus + " (" + stagingFileUpdatePayload.action + ")";
      // Even though not currently visible, the grid needs to be updated with the action as well.
      this.updateGrid.emit();
    }

  }


  

}
