//Angular Imports
import { Injectable } from "@angular/core";

//Third Party Imports
import { AppInsights } from 'applicationinsights-js';


/**
  * Provides all app insights related functionality
*/
@Injectable({
  providedIn: 'root'
})

export class AppInsightsService {

  //Private Variables
  /**
   * @ignore
  */
  private _config: Microsoft.ApplicationInsights.IConfig;

  //Public Variables 
  /**
   * Fetch the instrumentation key 
  */
  get instrumentationKey(): string {
    let key = this._config && this._config.instrumentationKey ? this._config.instrumentationKey : null;
    return key;
  }

  /**
   * Constructor
   * @ignore
  */
  constructor() {

  }


  //Public Methods
  /**
   * Init
   * Set the instrumentation key and initializes app insights
   * @param {string} instrumentationKey
   * @returns {void} 
  */
  init(instrumentationKey:string): void {
    this._config = {
      instrumentationKey: instrumentationKey
    };
    if (!AppInsights.config) {
      AppInsights.downloadAndSetup(this._config);
    }
  }

  /**
   * Log PageView 
   * @param {string} name
   * @param {string} url
   * @param {any} properties
   * @param {any} measurements
   * @param {number} duration
   * @returns {void}
  */
  logPageView(name?: string, url?: string, properties?: any, measurements?: any, duration?: number): void {
    AppInsights.trackPageView(name, url, properties, measurements, duration);
  }

  /**
   * Log Event
   * @param {string} name
   * @param {any} properties
   * @param {any} measurements
   * @returns {void} 
  */
  logEvent(name: string, properties?: any, measurements?: any) {
    AppInsights.trackEvent(name, properties, measurements);
  }

  /**
   * Log Exceptions
   * @param {Error} exception
   * @param {AI.SeverityLevel} severityLevel
   * @param {string} handledAt
   * @param {any} properties
   * @param {any} measurements
   * @returns {void} 
  */
  logException(exception: Error, severityLevel?: AI.SeverityLevel, handledAt?: string, properties?: any, measurements?: any): void {
    AppInsights.trackException(exception, handledAt, properties, measurements, severityLevel);
  }

  /**
   * Log Trace
   * @param {string} message
   * @param {AI.SeverityLevel} severityLevel
   * @param {any} properties
   * @returns {void} 
  */
  logTrace(message: string, severityLevel?: AI.SeverityLevel, properties?: any): void {
    AppInsights.trackTrace(message, properties, severityLevel);
  }

}
