<button id="update-staging-file-action-btn" mat-raised-button class="mdo-standard-button" [matMenuTriggerFor]="updateStagingFileActionMenu" [disabled]="!isAuthorized()">
  <span *ngIf="singleFile">
    Update <i class="fa fa-fw fa-caret-down"></i>
  </span>
  <span *ngIf="!singleFile">
    Update {{selectedFileCount}} File(s) <i class="fa fa-fw fa-caret-down"></i>
  </span>
</button>
<mat-menu class="mdo-standard-button-dropdown" #updateStagingFileActionMenu="matMenu">
  <button mat-menu-item (click)="performAction( 'note' )">Add note</button>
  <button mat-menu-item (click)="performAction( 'requestAction' )">Request action</button>
</mat-menu>

