//Angular Imports 
import { HttpClient, HttpContext, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { saveAs } from "file-saver";
import { map, Observable } from "rxjs";
import { BYPASS_URL_APPEND } from "../../../framework";
import { PDFCompareDocs } from "../models/pdfCompareDocs";
import { head } from "lodash";




/**
  * Policy Forms Provider
*/
@Injectable()
export class CompareService {
  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient) {
  }

  uploadPdfFiles(pdf1: File, pdf2: File): void {

    let formData: FormData = new FormData();
    formData.append('files', pdf1);
    formData.append('files', pdf2);


    const req = new HttpRequest('POST', 'localhost:4200/#/ghostPortal/compare', formData);

  }

  //calls api that does the comparision
  compareDocs(formData: FormData): Observable<any> {

    var cleanUrl = 'api/pdfcompare'
    var headers = new HttpHeaders();
    //headers = headers.append("Content-Type", "application/pdf")
    console.log(formData);
    return this.http.post<any>(cleanUrl, formData, { headers, responseType: 'blob' as 'json', observe: 'response', context: new HttpContext().set(BYPASS_URL_APPEND, false) }).pipe(map(res => res));

  }
  /*clearCache() {
    var url = `https://mkl-doc-queue-dev2.azurewebsites.net/api/cache/clear`;
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'text/plain');
    return this.http.get(url, { headers, responseType: 'text' }).pipe(map(res => res));
  }*/






}
