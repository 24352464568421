//Angular Imports 
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { UserNote } from "../models";

/**
 * Provide functions to save and retrieve notes.
*/
@Injectable({
  providedIn: 'root'
})

export class NoteService {

  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient) {

  }

  /**
   * Add note in database
   * @param userNote
   */
  addNote(userNote: UserNote): Observable<UserNote> {
    let url = `api/Note`;
    return this.http.post<UserNote>(url, userNote).pipe(
      map(res => res)
    );
  }

  /**
   * Get Notes for a specific form
   * @param formId
   */
  getNotesByFormId(formId: string): Observable<UserNote[]> {
    let url = `api/Note/GetByFormId?formId=` + formId;
    return this.http.get<UserNote[]>(url).pipe(
      map(res => res)
    );
  }

  /**
   * Get Notes for a specific rule
   * @param ruleId
   */
  getNotesByRuleId(ruleId: string): Observable<UserNote[]> {
    let url = `api/Note/GetByRuleId?ruleId=` + ruleId;
    return this.http.get<UserNote[]>(url).pipe(
      map(res => res)
    );
  }
}
