//Angular Imports
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

//RxJS imports
import { Observable } from 'rxjs';

//Internal Imports
import { AuthenticationService } from '../services';
import { SkippableInterceptorHeader } from '../enums';

/**
  * Intercepts and adds JWT token to all HTTP requests
*/
@Injectable()

export class JWTInterceptor implements HttpInterceptor {

  /**
   * Constructor
   * @ignore
  */
  constructor(private authenticationService: AuthenticationService) { }

  /**
   * Intercepts all the HTTP requests and appends the JWT token 
   * @param {HttpRequest} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>} Http Event Observable
  */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    let headers = request.headers;
    
    //Add bearer token to all the API calls except skippable ones

    if (request.url.indexOf("mgd") == -1 && request.url.indexOf('extension') == -1 && request.url.indexOf('mna') == -1 && request.url.indexOf("orchestrator") == -1) { // if not GhostDraft, don't change headers
      if (!headers || !headers.get(SkippableInterceptorHeader.JWTSkipHeader)) {
        request = request.clone({
          setHeaders: {
            "Authorization": `Bearer ${this.authenticationService.token}`
          },
          url: request.url
        });
      }
      else {
        //delete the header if set
        request = request.clone({
          headers: headers.delete(SkippableInterceptorHeader.JWTSkipHeader),
          url: request.url
        });
      }
    }
    

    //Pass to next interceptor
    return next.handle(request);
  }
}
